import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Assets
import { ModalContent } from '../generalModalStyling';

// Components
import Modal from '../NewModal';
import InputWrapper from '../../wrappers/InputWrapper';
import Input from '../../inputs/ControlledInput';
import Label from '../../display/Label';
import Typeahead from '../../inputs/Typeahead';
import HideShow from '../../function/HideShow';
import PhoneInput from '../../inputs/PhoneInput';
import Date from '../../inputs/Date';
import Address from '../../inputs/Address'

// API & Utils
import userAPI from '../../../api/customer/userAPI';
import statesOfUSA from '../../../utils/statesOfUSA';

// Redux
import { setNotification } from '../../../js/actions/notifications';

const StateZip = styled.div`
  display: ${props => props.statehidden ? 'none' : 'flex'};
  justify-content: space-between;
  width: 305px;
  & .state {
    width: 90%;
    margin-right: 15px;
  }
  & .zip {
    width: 90%;
  }
  .modal-input.basic-single {
    width: 175px;
  }

  .u-margin-top-small {
    margin-top: 8px;
  }
`

const stateStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E',
    width: '92%',
    height: '39px',
    backgroundColor: '#fff',
    '&:placeholder': {
      color: '#A1A1A1'
    },
    '&:hover': {
      border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E'
    },
  }),
  menuList: base => ({
    ...base,
    height: 'auto',
    maxHeight: 80
  }),
  menu: base => ({
    ...base,
    height: 'auto',
    maxHeight: 80
  })
};

class UpdateCustomerModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      date_of_birth: '',
      phone: '',
      email: '',
      service_address: '',
      service_address2: '',
      service_address_city: '',
      service_address_state: '',
      display_service_address_state: [],
      service_address_zip: ''
		}

		this.myRefs = {
      phone: React.createRef(),
      email: React.createRef(),
      date_of_birth: React.createRef(),
      service_address_state: React.createRef(),
      service_address_zip: React.createRef(),
    }
  }
  
  componentDidMount() {
    let { date_of_birth, formatted_phone, email, service_address, service_address2, service_address_city, service_address_state, service_address_zip } = this.props.customer;
    
    let customer = {
      phone: formatted_phone,
      email,
      service_address,
      service_address2,
      service_address_city,
      service_address_state,
      service_address_zip,
      display_service_address_state: [statesOfUSA.filter( state => state.value === service_address_state)[0]]
    };
   
    if(date_of_birth) {
      customer.date_of_birth = date_of_birth;
    }

    this.setState(customer);
  }

  handleChange = (event) => {
    let { target } = event,
        { value, name } = target;
    if (name === 'email') {
      let formatted = value.toLowerCase();
      value = formatted;
    }
    this.setState({
      [name]: value
    });
  };

  updateUser = async () => {
    let customer = Object.assign({}, this.props.customer, this.state);

    if (customer.business_name) {
      delete customer.date_of_birth;
    }
    if (!customer.email || !customer.formatted_phone ) {
      this.props.setNotification({message: 'Email and Phone are required', type: 'error'});
      return
    }
    delete customer.display_service_address_state;

    let response = await userAPI.updateCustomer(customer, this.props.auth_token)
    if (response.err) {
      this.props.setNotification({message: response.err.response.data.detail, type: 'error'});
      return
    }
    this.props.refresh(response);
    this.props.setNotification({message: 'Customer updated successfully.'})
    this.props.closeModal();
  }

	onStateChange = (event) => {
	  if(event.value) {
      this.setState({
        service_address_state: event.value,
        display_service_address_state: event
      })
    }
	}

	render() {

    let { service_address, service_address2, service_address_city, display_service_address_state, service_address_zip } = this.state;

		return (
      <Modal context="update-customer" title="Update Customer" onClose={this.props.closeModal} errors={this.state.errors} submit={this.updateUser}>
        <ModalContent>
          <InputWrapper>
            <Label label="Email"/>
            <Input className="modal-input no-border"
                type="email"
                placeholder="Enter Email Address"
                name="email"
                data-test-id="email"
                required
                onChange={this.handleChange}
                value={this.state.email}
                ref={this.myRefs.email}/>
          </InputWrapper>
          <HideShow show={!this.props.customer.is_business}>
            <InputWrapper>
              <Label label="Birthday" or/>
              <Date className="modal-input no-border"
                  name="date_of_birth"
                  data-test-id="date_of_birth"
                  onChange={this.handleChange}
                  ref={this.myRefs.date_of_birth}
                  defaultValue={this.state.date_of_birth}/>
            </InputWrapper>
          </HideShow>
          <InputWrapper>
            <Label label="Phone"/>
            <PhoneInput className="modal-input no-border"
                placeholder="Enter Phone Number"
                name="phone"
                required
                data-test-id="phone"
                onChange={this.handleChange}
                ref={this.myRefs.phone}
                value={this.state.phone}/>
          </InputWrapper>
          <InputWrapper>
            <Address
              statehidden
              onChange={this.handleChange}
              service_address={service_address}
              service_address2={service_address2}
              service_address_city={service_address_city}
              service_address_state={display_service_address_state}
              service_address_zip={service_address_zip}
              onStateChange={this.onStateChange}
              ref={this.myRefs.address}/>
            <StateZip {...this.props}>
              <Typeahead className="modal-input state no-border"
                    custom
                    placeholder="State"
                    options={statesOfUSA}
                    name="service_address_state"
                    onChange={this.onStateChange}
                    required
                    styles={stateStyles}
                    value={this.state.display_service_address_state}
                    ref={this.myRefs.service_address_state}/>
              <Input className="modal-input zip no-border u-margin-top-small"
                    placeholder="Zip Code"
                    name="service_address_zip"
                    data-test-id="service_address_zip-2"
                    onChange={this.handleChange}
                    autoComplete="postal-code"
                    maxLength="5"
                    type="zip"
                    required
                    value={this.state.service_address_zip}
                    ref={this.myRefs.service_address_zip}/>
            </StateZip>
          </InputWrapper>
        </ModalContent>
      </Modal>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: notification => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCustomerModal);
