import React from 'react';
import Modal from '../NewModal';
import { ModalContent } from '../generalModalStyling';

//Components
import InputWrapper from '../../wrappers/InputWrapper';
import Label from '../../display/Label';
import ErrorHandle from '../../function/ErrorHandle';

//Assets
import ModalErrorWrapper from '../styling/ModalErrorWrapper';

export default function DeleteCustomerModal(props) {
  return(
    <Modal context="delete-customer" title="Delete Customer" modalname="showDeleteCustomerModal" onClose={props.onClose} errors={props.errors} submit={props.deleteCustomer} yesNo>
      <ModalContent>
        <ModalErrorWrapper>
          <ErrorHandle data={props.error}/>
        </ModalErrorWrapper>
        <InputWrapper>
          <Label label="Are you sure you want to delete this customer?"/>
        </InputWrapper>
      </ModalContent>
    </Modal>
  )
}