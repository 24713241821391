import React, {Component} from 'react';
import { connect } from "react-redux";

import styled from 'styled-components';
import globalUtils from '../../utils/globalUtils';
import customerAuthAPI from '../../api/auth/customerAuthAPI';

//Components
import Background from '../../components/mobile/MobileBackground';
import Input from '../../components/inputs/Input';

//Redux
import { setNotification } from '../../js/actions/notifications';
import withLoginRedirect from '../public/LoginRedirect/withLoginRedirect';


const PageContainer = styled.div`
  padding-top: 175px;
  .input-container {
    height: 495px;
    width: 335px;
    border-radius: 17px;
    background-color: #F1F4F7;
    margin: 5px auto 0 auto;
    @media only screen and (min-width: 768px) {
      width: 550px;
    }
  }
`

const Container = styled.div`
  margin-top: 200px;
  justify-content: center;
  margin: 0 auto;
  .input-wrapper {
    text-align: center;
    .has-error {
      margin-top: 0px;
      margin-left: 55px;
      margin-bottom: 5px;
    }
  }
  .success {
    color: green;
    text-align: center;
  }
  input {
    margin-bottom: 10px;
    width: 300px;
    height: 45px;
    background: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    color: #000000;
    font-size: 16px;
    text-align: left;
    text-indent: 10px;
    border-radius: 6px;
    ::placeholder {
      color: #B4B4B4;
      text-align: left;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  .email {
    width: 300px;
  }
  .label {
    color: #fff;
    margin-bottom: 8px;
    text-align: center;
  }
  .header {
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
  }
`

const Button = styled.div `
  text-align: center;
  height: 40px;
  width: 150px;
  background-color: #00D973;
  color: #fff;
  line-height: 2.2;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  margin: 0 auto;
  cursor: pointer;
`

class CustomerReset extends Component {
  constructor(props){
    super(props);
    const values = globalUtils.getQueryString(this.props.location.search.slice(1))
    this.state = {
      username: values.username,
      code: values.code
    };

    this.myRefs = {
      email: React.createRef(),
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  resetPin = async (event) => {
    if (!globalUtils.checkValues(this.myRefs))  return;
    if (!this.state.email) return this.props.setNotification({message: 'Email is required', type: 'error', mobile: true});

    if (!event.detail || event.detail === 1){
      let response = await customerAuthAPI.sendResetEmail({email: this.state.email});
      if (response.err) {
        return this.props.setNotification({message: response.err.response.data.detail, type: 'error', mobile: true});
      }
      this.props.setNotification({message: 'Email sent. Please check your email.', mobile: true});
    }
  }

  render() {
    return (
      <Background fixed>
        <PageContainer>
          <Container>
            <div className="header">Reset PIN</div>
            <div className="label">We will email you a reset link</div>
            <Input name="email"
                placeholder="Email"
                onChange={this.handleChange}
                value={this.state.email}
                required
                ref={this.myRefs.email}
                type="email"
                autoFocus/>
          </Container>
          <Button data-test-id="customer-reset-submit" onClick={this.resetPin}>Submit</Button>
        </PageContainer>
      </Background>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withLoginRedirect(CustomerReset))
