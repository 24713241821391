import { LOGOUT, HARD_LOGOUT } from '../constants/index';
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_USER_ID = "SET_USER_ID";
export const SET_SUB_ID = "SET_SUB_ID";
export const STORE_CUSTOMER_AUTH = "STORE_CUSTOMER_AUTH";
export const LOGIN_CUSTOMER = "LOGIN_CUSTOMER";
export const LOGIN_MERCHANT = "LOGIN_MERCHANT";
export const STORE_MERCHANT_ID = "STORE_MERCHANT_ID";
export const STORE_CUSTOMER_USERNAME = "STORE_CUSTOMER_USERNAME";
export const IS_MERCHANT = "IS_MERCHANT";

export const setUserInfo = user_info => ({ type: SET_USER_INFO, payload: user_info });
export const setPaymentMethod = payment_method => ({ type: SET_PAYMENT_METHOD, payload: payment_method});
export const setUserId = user_id => ({ type: SET_USER_ID, payload: user_id});
export const storeSubId = sub_id => ({ type: SET_SUB_ID, payload: sub_id});
export const storeCustomerAuth = auth_token => ({ type: STORE_CUSTOMER_AUTH, payload: auth_token});
export const storeCustomerUsername = username => ({ type: STORE_CUSTOMER_USERNAME, payload: username});
export const loginCustomer = user_pin => ({ type: LOGIN_CUSTOMER, payload: user_pin});
export const loginMerchant = auth_data => ({ type: LOGIN_MERCHANT, payload: auth_data});
export const storeMerchantId = id => ({ type: STORE_MERCHANT_ID, payload: id });
export const logout = auth_data => ({ type: LOGOUT, payload: auth_data});
export const isMerchant = is_merchant => ({ type: IS_MERCHANT, payload: is_merchant});
export const hardLogout = redirectUrl => ({ type: HARD_LOGOUT, payload: redirectUrl})