import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

//API & Utils
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';
import globalUtils from '../../../utils/globalUtils';

//Components
import CreditCardInfo from '../../../components/cards/CreditCardInfo';
import BottomButton from '../../../components/buttons/mobile/BottomButton';
import MerchantLogo from '../../../components/logos/MerchantLogo';

//Assets
import receipt_image from '../../../assets/images/new-receipt.png';

//Redux
import { storeCustomerUsername } from '../../../js/actions/auth';
import { storeMerchantName } from '../../../js/actions/merchants';
import { storeFromReceipt } from '../../../js/actions/invoices';

const WhiteWrapper = styled.div`
  top: 70px;
  background-color: #FFFFFF;
  height: 100%;
  width: 100vw;
  z-index: 3;
  position: fixed;
`;

const Container = styled.div`
  position: relative;
  height: calc(100vh - 70px);
  width: 100vw;
  background-image: url(${receipt_image});
  background-size: 260px;
  background-position: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
  @media (min-device-height: 667px) {
    background-position-y: 5px;
    background-size: 320px;
  }
  @media (min-device-height: 668px) {
    background-size: 320px;
  }
  @media (min-device-height: 812px) {
    background-size: 350px;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 only */
      background-size: 210px 300px;
    }

    @media (min-device-height: 667px) {
      background-position-y: 5px;
      background-size: 255px;
    }

    @media (min-device-height: 812px) {
      background-size: 265px;
    }

    @media (min-device-height: 896px) {
      background-size: 320px;
    }
  }


  @media (min-device-height: 668px) {
    background-position-y: 39px;
  }
  @media only screen and (min-width: 1024px) {
    background-size: 450px;
    background-position: center -9px;
  }

  img.close-icon {
    height: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 2;
    @media only screen and (min-width: 1024px) {
      height: 35px;
    }
  }
`;

const ReceiptContents = styled.div`
    position: relative;
    width: 300px;
    height: 468px;
    margin: auto;
    padding-top: 105px;
    @media only screen and (min-height: 668px) {
      padding-top: 135px;
      height: 497px;
    }
    @media (min-device-height: 668px) {
      padding-top: 139px;
      height: 497px;
    }
    @media only screen and (min-width: 1024px) {
      padding-top: 167px;
      height: 696px;
      width: 422px;
    }
  .heading-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    @supports (-webkit-overflow-scrolling: touch) {
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 only */
        margin-top: -35px;
      }

      @media screen and (min-device-height: 736px)
      and (max-device-height: 812px) {
        margin-top: -10px;
      }
    }
  }
  .header {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 3px;
    @supports (-webkit-overflow-scrolling: touch) {
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 only */
        margin-bottom: 0;
        font-size: 18px;
      }
    }
    @media only screen and (min-width: 1024px) {
      top: 165px;
      left: 180px;
    }
  }
  .logo-box {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 only */
      margin-top: 7px;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 only */
        margin-top: -2px;
      }

      @media screen and (min-device-height: 736px)
      and (max-device-height: 812px) {
        margin-top: 0px;
      }
    }
    @media only screen and (min-width: 1024px) {
      margin-top: 15px;
    }
  }
  img {
    @media only screen and (min-width: 1024px) {
      margin-top: 50px;
    }
  }
  .header {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
  }
  .sub-header {
    color: #BBC2D6;;
    font-size: 12px;
    font-weight: 300;
    @media only screen and (min-width: 1024px) {
      margin-top: 5px;
    }
  }
  .receipt-desc {
    padding: 0 22px;

    @media only screen and (min-width: 1024px) {
      height: 155px;
      margin-top: 40px;
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
      height: 40px;
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 only */
        margin-bottom: 0;
      }
    }
    .amount-date {
      display: flex;
      justify-content: space-between;
      width: 218px;
      padding: 0 5px;
      height: 40px;
      margin: auto;
      @supports (-webkit-overflow-scrolling: touch) {
        @media screen and (device-aspect-ratio: 40/71) {
          /* iphone 5 only */
          width: 190px;
        }
      }
    }
    .ad-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .title {
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 300;
      margin-bottom: 2px;
    }
    .receipt-text {
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .mw-75 {
      max-width: 75%;
    }
    .payment-container {
      background-color: white;
      margin: 0 auto;
      width: 90%;
      height: 50px;
      margin-top: 25px;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 only */
        margin-top: 0;
        width: 87%;
      }
      @supports (-webkit-overflow-scrolling: touch) {
        @media screen and (device-aspect-ratio: 40/71) {
          /* iphone 5 only */
          display: none;
        }

        @media screen and (min-device-height: 736px)
        and (max-device-height: 812px) {
          margin-top: 5px;
          width: 85%;
        }
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
`;

class Receipt extends Component {
  constructor(props){
    super(props);

    this.state = {
      invoice_detail: {
        merchant: {},
        transactions: [{
          visible_method: '',
          type: ''
        }]
      }
    };
    const values = globalUtils.getQueryString(this.props.location.search.slice(1))

    this.receiptId = this.props.match.params.id;
    this.props.storeFromReceipt(this.props.match.params.id)
    this.props.storeCustomerUsername(values.username);
    this.props.storeMerchantName(values.merchant_name)
  }

  componentDidMount = () => {
    if (this.props.auth_token){
      this.getReceiptDetail();
    }
  }

  getReceiptDetail = async () => {
    if (this.props.auth_token) {
      let invoice_detail = await userInvoiceAPI.getCustomerInvoice(this.receiptId, this.props.auth_token);
      this.setState({invoice_detail, loaded: true});
    }
  }

  returnPaymentMethod = () => {
    if (this.state.loaded) {
      return this.state.invoice_detail.transactions[this.state.invoice_detail.transactions.length - 1].visible_method;
    } else {
      return;
    }
  }

  routeToDashboard = () => {
    this.props.history.push('/customer/dashboard');
  }

  renderLogo = () => {
    if (this.state.invoice_detail.merchant && this.state.invoice_detail.merchant.logo_url) {
      return (
        <MerchantLogo showMerchantName={true} logo={this.state.invoice_detail.merchant.logo_url} name={this.state.invoice_detail.merchant.name} fullSize isReceipt={true} height = "90px" width="200px"/>
      )
    } else {
      return <MerchantLogo showMerchantName={true} src="" fullSize isReceipt={true} height = "90px" width="200px"/>
    }
  }

  renderDate = () => {
    if(this.state.invoice_detail.scheduled_on) {
      return globalUtils.formatDate(this.state.invoice_detail.scheduled_on)
    } else {
      if(this.state.invoice_detail.paid_date) {
        return globalUtils.formatDate(this.state.invoice_detail.paid_date)
      }
    }
  }

  renderMessage = () => {
    if (this.state.loaded) {
      let { transactions } = this.state.invoice_detail
      let currentTransaction = transactions[transactions.length - 1];
      if(currentTransaction.type === 'refund') {
        return <div className="sub-header">Your payment has been refunded</div>
      } else {
        return <div className="sub-header">Your payment has been {this.state.invoice_detail.scheduled_on ? 'scheduled' : 'submitted'}</div>
      }
    } else {
      return(<div className="sub-header">Loading...</div>);
    }
  }

  renderReceipt = () => {
    if(this.state.loaded) {
      return(
        <Container>
          <ReceiptContents>
            <div className="heading-box">
              <div className="header">Thank you!</div>
              {this.renderMessage()}
            </div>
            <div className="logo-box">
              {this.renderLogo()}
            </div>
            <div className="receipt-desc">
              <div className="desc">
                <div className="title">DESCRIPTION</div>
                <div className="receipt-text mw-75">{this.state.invoice_detail.description || ''}</div>
              </div>
              <div className="amount-date">
                <div className="ad-container">
                  <div className="title">AMOUNT</div>
                  <div className="receipt-text">{globalUtils.convertToDollars(this.state.invoice_detail.amount_paid) || ''}</div>
                </div>
                <div className="ad-container">
                  <div className="title">DATE</div>
                  <div className="receipt-text">{ this.renderDate() }</div>
                </div>
              </div>
              <div className="payment-container">
                <CreditCardInfo payment={this.returnPaymentMethod()}/>
              </div>
            </div>
          </ReceiptContents>
        </Container>
      )
    }
  }

  render() {
    return (
      <WhiteWrapper>
        {this.renderReceipt()}
        <ButtonWrapper>
          <Link to="/customer/dashboard" data-test-id="receipt-link-to-dashboard"><BottomButton>Done</BottomButton></Link>
        </ButtonWrapper>
      </WhiteWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    from_receipt: state.invoices.from_receipt,
    auth_token: state.auth.auth_token,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    storeCustomerUsername: (username) => {dispatch(storeCustomerUsername(username))},
    storeMerchantName: (merchant_name) => {dispatch(storeMerchantName(merchant_name))},
    storeFromReceipt: (receipt) => {dispatch(storeFromReceipt(receipt))}
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Receipt)
