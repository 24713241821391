import React, {Component} from 'react';
import styled from 'styled-components';
import Typeahead from '../inputs/Typeahead';
import statesOfUSA from '../../utils/statesOfUSA';

const Container = styled.div`
  padding: 0px;
  .modal-input {
    width: 188px;
  }
  .css-1rtrksz {
    padding: 0px;
    margin: -6px 5px;
  }
`

const dropdownStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E',
    width: '92%',
    height: '39px',
    backgroundColor: '#fff',
    '&:placeholder': {
      color: '#A1A1A1'
    },
    '&:hover': {
      border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E'
    },
  }),
  menuList: base => ({
    ...base,
    height: 'auto',
    maxHeight: 140
  }),
  menu: base => ({
    ...base,
    height: 'auto',
    maxHeight: 140
  })
};

export default class States extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  onChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  }


  render() {
    return (
      <Container className="states-wrapper">
        <Typeahead
          custom
          placeholder={this.props.placeholder}
          options={statesOfUSA}
          styles={dropdownStyles}
          onChange={this.onChange}
          name={this.props.name}
          required
        />
      </Container>
    )
  }
}
