import React, {Component} from 'react';
import styled from 'styled-components';
import CurrencyInput from './CurrencyInput';
import Date from './Date';

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  width: 290px;
  .currency-input,
  .phone-input {
    width: 48%;
    border-top: none;
    border-bottom: 1px solid #A4A4A4;;
    border-left: none;
    border-right: none;
    text-indent: 5px;
    &:focus {
      border: 2px solid #64ABF5;
      outline: none;
    }
    &.phone-input {
      margin-left: 5px;
      width: 100%;
      &:focus {
        border: 2px solid #64ABF5;
        outline: none;
      }
    }
  }
`

const ErrorContainer = styled.div`
  color: #FD7272;
  text-align: left;
  margin-top: 5px;
`

export default class DualInput extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  checkForErrors = (e) => {
    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    }
    if (this.props.required && e.target.value) {
      this.setState({error: ''})
    }
  }


  render() {
    return (
      <>
        <InputWrapper {...this.props} {...this.state} className="wrapper-test">
          <CurrencyInput
                  name={this.props.name1}
                  onChange={this.props.onChange}
                  data-test-id={this.props.name1}
                  placeholder="Enter Amount"
                  onBlur={(e) => this.checkForErrors(e)}/>
          <Date placeholder="mm/dd/yy"
                  className="phone-input"
                  options={{date: true, datePattern: ['m', 'd', 'Y']}}
                  name={this.props.name2}
                  data-test-id={this.props.name2}
                  onChange={this.props.onChange}
                  onBlur={(e) => this.checkForErrors(e)}/>
        </InputWrapper>
        <ErrorContainer className="has-error">{this.state.error}</ErrorContainer>
      </>
    )
  }
}
