import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'users';

class UserInfoAPI {
  getSelf = (auth_token) => {
    return apiUtils.get(url + '/user_get_self', auth_token);
  };

  getUserInfo = (data) => {
    return apiUtils.post(url, data);
  };

  updatePassword = (data, auth_token) => {
    let passwordUrl = apiUtils.baseUrl + 'users/change_password';
    return apiUtils.patch(passwordUrl, data, auth_token);
  };

  getTokenFromUserName = (data) => {
    return apiUtils.post(url + '/get_token_from_username', data)
  };
}

export default new UserInfoAPI();
