import React, {Component} from 'react';
import styled from 'styled-components';
import ModalButtonCombo from '../buttons/ModalButtonCombo';
import alert_icon from '../../assets/icons/alert_icon.png';

const Alert = styled.img`
  height: 50px;
  width: auto;
`

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(59,59,59,.6);
  position: fixed;
  top: 0;
  z-index: 9;
  @media (min-device-width: 1024px) {
    width: 390px;
  }
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 282px;
    border-radius: 10px;
    background-color: #F1F4F7;
    text-align: center;
    padding: 20px;
    .title {
      font-size: 20px;
      color: #292929;
    }
    .modal-text {
      font-size: 12px;
      margin-top: 10px;
    }
  }
`

export default class NewMobileModal extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  renderIcon = () => {
    if (this.props.alert) {
      return (
        <Alert src={alert_icon}/>
      )
    }
  }


  render() {
    return (
      <Container>
        <div className="modal">
          {this.renderIcon()}
          <div className="title">{this.props.header}</div>
          <div className="modal-text">{this.props.text}</div>
          {this.props.children}
          <ModalButtonCombo yesNo={this.props.yesNo} onClose={ () => this.props.onClose(this.props.modalname) } submit={this.props.submit} mobile/>
        </div>
      </Container>
    )
  }
}
