import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'sms_templates';
let previewUrl = url + '/preview'
class messageTemplatesAPI {

  getList = (auth_token, user_pin, callback) => {
    return apiUtils.get(url, auth_token, user_pin, callback);
  };

  getMessageDetail = (auth_token, user_pin, callback, id) => {
    return apiUtils.get(previewUrl + '?id=' + id.id, auth_token, user_pin, callback, id);
  }
}

export default new messageTemplatesAPI();
