import React, {Component} from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled.div`
  color: #fff;
  background-color: #FD7272;
  box-shadow: inset 0 1px 0 0 rgba(212,217,192,0.52), inset 0 0 10px 0 rgba(103,131,96,0.36), 2px 3px 4px 1px #487EBC;
  height: 49px;
  width: ${props => props.small ? '180px' : '228px'};
  text-align: center;
  line-height: 2;
  font-size: 22px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
`

export default class MobileButton extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <ButtonWrapper>
        <Button {...this.props}>{this.props.children}</Button>
      </ButtonWrapper>
    )
  }
}
