import React from 'react';
import styled from 'styled-components';
import chevron_icon from '../../../assets/icons/chevron_left_blue.png';

const ChevronIcon = styled.img`
  height: 25px;
  width: auto;
  position: absolute;
  top: 18px;
  left: 10px;
  cursor: pointer;
`

const Page = styled.div`
  position: absolute;
  color: #64ABF5;
  font-size: 26px;
  top: 16px;
  left: 35px;
  width: 252px;
`

export default function MobileBreadcrumb(props) {
  return (
    <div className="breadcrumb-title-wrapper" >
      <ChevronIcon src={chevron_icon} onClick={props.close}/>
      <Page>{props.page}</Page>
    </div>
  )
}
