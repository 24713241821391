import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';

import Dropzone from '../../../components/fields/Dropzone';
import WhiteContainer from '../../../components/wrappers/WhiteContainer';
import Breadcrumb from '../../../components/display/BreadcrumbTitle';

const DropzoneContainer = styled.div`
  padding: 50px;
  text-align: center;
  min-height: 580px;
  height: auto;
`

class UploadCustomer extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Breadcrumb page="Customer Upload" path="/admin/customers"/>
        <WhiteContainer custom>
          <DropzoneContainer>
            <Dropzone customer="true" token={this.props.auth_token}/>
          </DropzoneContainer>
        </WhiteContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
    };
};

export default connect(
  mapStateToProps,
)(UploadCustomer)
