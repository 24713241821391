import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames'

// Assets
import { color_toggle_disabled } from '../../styles/colors';
const DoubleToggleWrapper = styled.div`
  .toggle-btn {
    width: ${props => props.width ? props.width + 'px' : '152px'};
    height: 29px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    /* margin: 7px 0 7px 30px; */
    
    &--disabled {
      border-color: #E5E5E5;

    }
  }
  .option {
    cursor: pointer;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9CAAB6;
    font-size: 14px;
    border: 1px solid;
    border-color: #6C768C;

    &-left {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: none;
    }

    &-right {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: none;
    }
  }

  .active {
    background-color: #4C5760;
    color: #FFFFFF;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    background-color: ${color_toggle_disabled};
    color: white;
    border: 1px solid #E5E5E5;
  }
`;

function DoubleToggle(props) {
  let { option_one, option_two, option_one_disabled, option_two_disabled, option_one_active, option_two_active, toggle } = props;
  return(
    <DoubleToggleWrapper width={props.width}>
      <div className={classNames("toggle-btn", {"toggle-btn--disabled": option_one_disabled && option_two_disabled})}>
        <div className={classNames('option option-left', {'active': option_one_active}, {"disabled": option_one_disabled})} onClick={toggle}>{option_one}</div>
        <div className={classNames('option option-right', {'active': option_two_active}, {"disabled": option_two_disabled})} onClick={toggle}>{option_two}</div>
      </div>
    </DoubleToggleWrapper>
  )
}

export default DoubleToggle;