import React, {Component} from 'react';
import styled from 'styled-components';

const Button = styled.div`
  width: ${props => props.small ? '125px' : '160px'};
  height: ${props => props.small ? '35px' : '60px'};
  line-height: ${props => props.small ? '1' : '2.5'};
  color: ${props => props.primary ? '#D3D3D3' : 'white'};
  background-color: ${props => props.primary ? 'white' : '#D3D3D3'};
  border: 1px solid black;
  padding: 8px;
  border: 2px solid #D3D3D3;
  box-shadow: inset 0 1px 0 0 rgba(212,217,192,0.52);
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
`

export default class DisabledButton extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <Button {...this.props}>{this.props.children}</Button>
    )
  }
}
