import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import classNames from 'classnames';

// Components
import Button from '../../../components/buttons/NewButton';
import Input from '../../../components/inputs/ControlledInput';
import CurrencyInput from '../../../components/inputs/CurrencyInput';
import DayInput from '../../../components/inputs/DayInput';
import Typeahead from '../../../components/inputs/Typeahead';
import HideShow from '../../../components/function/HideShow';
import DropdownIndicator from '../../../components/display/DropdownIndicator';

// Data
import options from './automation_options';

//API & Utils
import automationAPI from '../../../api/automation/automationAPI';
import globalUtils from '../../../utils/globalUtils';

// Assets
import addButton from '../../../assets/icons/add_clear.png';
import rmvButton from '../../../assets/icons/cancel-red.png';

// Redux
import {setNotification} from '../../../js/actions/notifications';

const WizardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const SideBar = styled.div`
  background-color: #4E8DDC;
  height: auto;
  min-height: 617px;
  width: 340px;
  padding: 50px 10px;
  .descriptions {
    height: 500px;
    .step-desc {
      height: 25%;
      font-size: 16px;
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .step-title {
        font-weight: 600;
        margin-bottom: 3px;
      }
      .step-sub {
        width: 225px;
        font-weight: 400;
      }
      .inactive {
        opacity: 0.47;
      }
    }
  }
  .step-box {
    transition: all 5s, ease-out;
    height: 39px;
    width: 39px;
    border: 1px solid #01D3D8;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -28px;
    color: #01D3D8;
    font-weight: 600;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    &--active {
      background-color: #01D3D8;
      border: none;
      color: #FFFFFF;
    }
  }
`;

const Content = styled.main`
  background-color: #F1F4F7;
  width: 100%;
  height: auto;
  min-height: 617px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  z-index: 2;
  .css-15k3avv {
    width: 286px;
  }
  .wizard-header {
    width: 100%;
    height: 139px;
    padding-top: 20px;
    text-align: center;
    .title {
      color: #9CAAB6;
      font-size: 27px;
      font-weight: 500;
      letter-spacing: -0.12px;
      line-height: 33px;
      text-align: center;
    }
    .below-title {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .subtitle {
        float: left;
        color: #9CAAB6;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: -0.09px;
        line-height: 25px;
        text-align: left;
      }
    }
  }
  .subtext {
    margin-top: 2px;
    color: #969696;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    position: relative;
  }
  .main {
    height: auto;
    min-height: 444px;
    padding: 0 15px 50px 15px;
    .step-instr {
      margin-top: 20px;
      height: 39px;
      width: 479px;
      color: #9CAAB6;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -0.09px;
      line-height: 25px;
    }
    .space {
      margin-top: 30px;
    }
    .pull {
      margin-bottom: -20px;
    }
    .full-input input {
      margin: 0;
      height: 39px;
      width: 270px;
      border: 1px solid #9E9E9E;
      border-radius: 3px;
      background-color: #FFFFFF;
      text-indent: 8px;
    }
    .short input{
      position: relative;
      top: 20px;
      width: 187px;
      /* margin-right: 20px; */
    }
    .email input {
      width: 285px;
      margin-top: 20px;
    }
    .typeahead {
      height: 39px;
      width: 286px;
      position: relative;
    }
    .conditions {
      margin-top: -20px;
      .icon {
        width: auto;
        cursor: pointer;
      }
      .add {
        height: 28px;
        position: relative;
        top: 10px;
        left: 17px;
      }
      .remove {
        height: 14px;
        position: relative;
        top: 33px;
        right: 7px;
      }
      .row {
        display: flex;
        justify-content: flex-start;
        position: relative;
        .css-10nd86i {
          width: auto;
        }
        .css-xd6be3 {
          padding-left: 0;
        }
        .value-box input {
          margin-top: 20px;
          height: 39px;
          width: 108px;
          border: 1px solid #9E9E9E;
          border-radius: 3px;
          background-color: #FFFFFF;
          text-align: right;
          padding-right: 10px;
          margin-right: 10px;
        }
        .value-text {
          margin-top: 20px;
          margin-left: -10px;
          width: 30px;
          height: 39px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .action-readable {

      color: #4E8DDC;
      font-size: 19px;
      font-weight: 500;
      line-height: 23px;
    }
    .condition-preview {
      margin-top: 30px;
      position: relative;
      left: 15px;
      .line {
        color: #4E8DDC;
        font-size: 19px;
        font-weight: 500;
        margin: 0;
      }
    }
  }

`;

// const Circle = styled.div`
//   height: 20px;
//   width: 20px;
//   display: flex;
//   justify-content: center;
//   border: 2px solid #D8D8D8;
//   font-size: 12px;
//   font-weight: 600px;
//   border-radius: 10px;
//   color: #D8D8D8;
//   position: absolute;
//   right: -15px;
//   top: 50%;
//   transform: translateY(-50%);
//   padding-top: 1px;
// `;


const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  right: 30px;
  height: 10%;
  width: 360px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const SwitchContainer = styled.div`
  width: 209px;
  height: 39px;
  border: 1px solid #4C5760;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin: 20px 10px 0 0;
  .option {
    cursor: pointer;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9CAAB6;
    font-size: 14px;
  }
  .active {
    background-color: #4C5760;
    color: #FFFFFF;
  }
`;

const DateSelector = styled.div`
height: 100px;
display: flex;
align-items: center;
  .days {
    display: flex;
    .day-box {
      cursor: pointer;
      border-radius: 2px;
      background-color: #D8D8D8;
      height: 35px;
      width: 35px;
      color: #AFAFAF;
      font-size: 23px;
      font-weight: 500;
      line-height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .selected {
      background-color: #01D3D8;
      color: #FFFFFF
    }
  }
  .at-box {
    height: 39px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9CAAB6;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 25px;
    margin-left: -10px;
  }
  .time-selection {
    display: flex;
    .hour-minute {
      position: relative;
      height: 40px;
      width: 40px;
      border: 1px solid #D9D9D9;
      border-radius: 3px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #969696;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-right: 10px;
      .triangle-button {
        width: 39px;
        height: 20px;
        position: absolute;
        left: -1px;
        cursor: pointer;
        .triangle {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 13px;
          height: 9px;
          background-color: #D8D8D8;
          clip-path: polygon(50% 0, 0 100%, 100% 100%);
        }
        .up {
          top: 6px;
        }
        .down {
          top: 5px;
          transform: rotate(180deg) translateX(50%);
        }
      }
      .up {
        top: -20px;
      }
      .down {
        bottom: -20px;
      }
    }
    .hour-separator {
      padding: 3px 0;
      position: relative;
      right: 5px;
      height: 39px;
      width: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }
`;

const Square = styled.div`
  height: 5px;
  width: 5px;
  background-color: #969696;
`;

const TypeWrapper = styled.div`
  .css-og2hfr {
    width: 98px;
  }
  .css-6enyqs {
    width: 98px;
  }
  .css-kn9uyr {
    width: 98px;
  }
  .css-j53s9h {
    width: 98px;
  }
`;

const DayInputWrapper = styled.div`
  margin-right: 10px;
`;

const objectDropdown = {
  control: (base, state) => ({
    ...base,
    width: 270,
    height: 39,
    backgroundColor: state.isDisabled ? 'hsla(0, 0%, 90%, 0.35);' : '#FFFFFF',
    marginRight: 15,
    borderRadius: 3,
    margin: '0',
    border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E',
    '&:placeholder': {
      color: '#A1A1A1'
    },
    '&:hover': {
      border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E'
    }
  }),
  indicatorSeparator: () => ({
    color: '#FFFFFF'
  }),
  valueContainer: base => ({
    ...base,
    width: 286
  })
}

const conditionDropdown = {
  control: (base, state) => ({
    ...base,
    paddingLeft: 2,
    width: 187,
    height: 39,
    backgroundColor: state.isDisabled ? 'hsla(0, 0%, 90%, 0.35);' : '#FFFFFF',
    borderRadius: 3,
    margin: '20px 10px 0 0',
    border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E',
    '&:placeholder': {
      color: '#A1A1A1'
    },
    '&:hover': {
      border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E'
    }
  }),
  indicatorSeparator: () => ({
    color: '#FFFFFF'
  }),
  valueContainer: base => ({
    ...base,
    width: 286
  }),
  menuList: base => ({
    ...base,
    height: 'auto',
    maxHeight: 100
  }),
  menu: base => ({
    ...base,
    height: 'auto',
    maxHeight: 100
  })
}

class AutomationWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      label: '',
      object_name: '',
      display_object_name: undefined,
      conditions: [{type: null, field_name: null, display_field_name: null}],
      actions: [{action_type: null}],
      scheduled_days: [],
      send_hour: 8,
      send_minute: 0,
      am_pm: 'AM',
      step: 1
    }
    this.myRefs = {
      to_email: React.createRef()
    }
  }

  componentDidMount() {
    let { trigger } = this.props
    if(trigger) {
      let display_object_name = options.objects.filter( object => object.value === trigger.object_name );
      let scheduled_days = this.formatDays(trigger.scheduled_days);
      let send_time = trigger.send_time.split(':')
      let send_hour;
      let am_pm;
      if(+send_time[0] > 12) {
        send_hour = +send_time[0] - 12;
        am_pm = 'PM';
      } else if(+send_time[0] === 12) {
        send_hour = +send_time[0];
        am_pm = 'PM'
      } else {
        send_hour = +send_time[0];
        am_pm = 'AM';
      }
      let send_minute = +send_time[1];
      let conditions = trigger.conditions.map( condition => {
        let { field_name } = condition;
          condition.display_type = options.type.filter(condType => condType.value === condition.type);
          condition.display_field_name = options.fields[trigger.object_name].filter(field => field.value === field_name);
          if(field_name !== 'due_date' && field_name !== 'paid_date' && field_name !== 'payment_plan') {
            condition.display_operator = options.operators[field_name].filter(operator => operator.value === condition.operator);
          }
          if(field_name === 'state') {
            condition.display_value = options.values['state'].filter( val => val.value === condition.value);
          }
          if(field_name === 'due_date' || field_name === 'payment_plan') {
            condition.toggle = true;
            if(field_name === 'due_date') {
              if(condition.value < 0) {
                condition.operator = "past_due";
                condition.value *= -1;
              } else {
                condition.operator = 'upcoming'
              }
            }
          }
          if(field_name === 'paid_date') {
            condition.operator = 'date'
          }
          return condition;
      });
      let currentAction = options.actions.filter( action => action.value === trigger.action_type )
      let actions = []
      if(trigger.actions && Array.isArray(trigger.actions)) {
        trigger.actions.forEach( action => actions.push(action))
      } else {
        let recipients = 'customers';
        let display_recipients = [{value: 'customers', label: 'Customers'}]
        if(trigger.to_email) {
          recipients = 'email';
          display_recipients = [{value: 'email', label: 'Email Address'}]
        }
        actions.push({
          action_type: trigger.action_type,
          display_action_type: currentAction,
          template: trigger.template.id,
          display_template: [{value: trigger.template.id, label: trigger.template.name}],
          recipients,
          display_recipients
        })
      }

      this.setState({
        label: trigger.label,
        object_name: trigger.object_name,
        display_object_name,
        actions,
        to_email: trigger.to_email ? trigger.to_email : undefined,
        scheduled_days,
        send_hour,
        send_minute,
        conditions,
        am_pm
      })
    }
  }

  formatDays = days => {
    let dayArr = JSON.parse(days)
    let send_days = [];
    if(dayArr && dayArr.length) {
      for(let i = 0; i < dayArr.length; i++) {
        let currentDay = options.days.filter( day => day.val === dayArr[i]);
        send_days.push(currentDay[0].value)
      }
    } else send_days.push('None selected')
    return send_days;
  }

  handleInput = evt => {
    this.setState({[evt.target.name]: evt.target.value})
  }

  handleObjectChange = (event) => {
    if(event.value) {
      this.setState({
        object_name: event.value,
        display_object_name: [event]
      })
    }
  }

  renderStepOne = () => {
    if(this.state.step === 1) {
      return(
        <>
          <div className="wizard-header">
            <div className="title">{this.props.trigger ? 'Edit this Automation' : 'Create a new Automation'}</div>
          </div>
          <div className="main">
            <div className="step-instr">What do you want to name this event?</div>
            <div className="full-input">
              <Input name="label" onChange={this.handleInput} value={this.state.label} placeholder="Event Name"/>
            </div>
            <div className="step-instr">Select the object this event is based on:</div>
            <div className="typeahead">
              <Typeahead custom
                  options={options.objects}
                  placeholder="Object"
                  onChange={this.handleObjectChange}
                  name="object-selector"
                  styles={objectDropdown}
                  components={{DropdownIndicator}}
                  value={this.state.display_object_name}
                  unsearchable={true}/>
              {/* <Circle>i</Circle> */}
            </div>
          </div>
        </>
      )
    }
  }

  handleConditionChange = (index, event, name, numsOnly) => {
    let conditions = this.state.conditions.slice();
    if(event.value) {
      let toggle = false;
      if(name === 'field_name') {
        if(event.value === this.state.conditions[index].field_name) {
          return;
        } else {
          conditions[index].display_operator = null;
          conditions[index].operator = null;
          conditions[index].value = '';
          conditions[index].display_value = null;
          if(event.value === 'due_date' || event.value === 'payment_plan') {
            toggle = true;
            if(event.value === 'due_date') {
              conditions[index].operator = 'upcoming';
              conditions[index].display_operator = {display: 'in'}
              conditions[index].value = null;
            } else conditions[index].value = 'yes';
          } else if(event.value === 'paid_date') {
            conditions[index].operator = 'date'
            conditions[index].display_operator = {display: 'ago'}
          } else if(event.value === 'payment_plan') {
            conditions[index].operator = 'is_plan';
          }
        }
      } else if(name === 'type') {
        if(this.state.conditions[index].type === event.value) {
          return;
        }
      }
      conditions[index][name] = event.value;
      conditions[index][`display_${name}`] = [event];
      conditions[index].toggle = toggle;
      if(event.value === 'due_date') {
        conditions[index].operator = 'upcoming';
      } else if(event.value === 'payment_plan') {
        conditions[index].value = 'yes'
        conditions[index].display_operator = {display: 'is'}
      }
    } else if(event.target) {
      if(numsOnly) {
        let val = event.target.value;
        conditions[index]["value"] = val.replace(/[^0-9]/g,'');
      } else {
        conditions[index]["value"] = event.target.value;
      }
    }
    this.setState({conditions})
  }

  handleToggle = (index, value) => {
    let conditions = this.state.conditions.slice();
    if(this.state.conditions[index].field_name === 'due_date') {
      let currentOperator = options.operators['due_date'].filter( operator => operator.value === value )
      conditions[index].operator = value;
      conditions[index].display_operator = currentOperator;
    }
    if(this.state.conditions[index].field_name === 'payment_plan') {
      let currentOperator = options.operators['payment_plan'].filter( operator => operator.value === value )
      conditions[index].value = value
      conditions[index].operator = "is_plan"
      conditions[index].display_operator = currentOperator
    }
    this.setState({conditions})
  }

  addCondition = () => {
    let conditions = this.state.conditions.slice();
    conditions.push({type: 'filter', field_name: null, display_field_name: null});
    this.setState({conditions})
  }

  removeCondition = i => {
    let conditions = this.state.conditions.filter( (condition, index) => index !== i );
    this.setState({conditions})
  }

  renderStepTwo = () => {
    if(this.state.step === 2) {
      let Conditions = this.state.conditions.map( (condition, index) => {
        let { field_name, toggle, operator } = this.state.conditions[index];
        return(
          <div className="row" key={index}>
            <HideShow show={this.state.conditions.length > 1}>
              <img src={rmvButton} alt="remove condition" className="icon remove" onClick={ () => this.removeCondition(index)}/>
            </HideShow>
            <TypeWrapper>
              <Typeahead custom
                  onChange={ e => this.handleConditionChange(index, e, 'type')}
                  placeholder="Type"
                  name="type"
                  options={options.types}
                  styles={conditionDropdown}
                  components={{DropdownIndicator}}
                  value={this.state.conditions[index].display_type}
                  unsearchable={true}/>
            </TypeWrapper>
            <Typeahead custom
                onChange={ e => this.handleConditionChange(index, e, 'field_name')}
                placeholder="Field"
                name="field"
                options={options.fields['UserInvoice']}
                styles={conditionDropdown}
                components={{DropdownIndicator}}
                value={this.state.conditions[index].display_field_name}
                unsearchable={true}/>
            <HideShow show={field_name && field_name !== 'due_date'}>
              <Typeahead custom
                  onChange={ e => this.handleConditionChange(index, e, 'operator')}
                  placeholder="Operator"
                  name="operator"
                  options={options.operators[field_name]}
                  styles={conditionDropdown}
                  components={{DropdownIndicator}}
                  value={this.state.conditions[index].display_operator}
                  unsearchable={true}/>
            </HideShow>
            <HideShow show={field_name && field_name === 'due_date'}>
              <SwitchContainer>
                <div className={classNames('option ', {'active': operator === 'upcoming'})} onClick={() => this.handleToggle(index, 'upcoming')}>Upcoming</div>
                <div className={classNames('option ', {'active': operator === 'past_due'})} onClick={() => this.handleToggle(index, 'past_due')}>Past Due</div>
              </SwitchContainer>
              <div className="value-text">{ operator === 'upcoming' ? 'in' : 'by' }</div>
              <DayInputWrapper>
                <DayInput name="value-1" value={this.state.conditions[index].value} onChange={ e => this.handleConditionChange(index, e, undefined, true)}/>
              </DayInputWrapper>
            </HideShow>
            <HideShow show={field_name && toggle && field_name === 'payment_plan'}>
              <SwitchContainer>
                <div className={classNames('option ', {'active': this.state.conditions[index].value === 'yes'})} onClick={() => this.handleToggle(index, 'yes')}>Yes</div>
                <div className={classNames('option ', {'active': this.state.conditions[index].value === 'no'})} onClick={() => this.handleToggle(index, 'no')}>No</div>
              </SwitchContainer>
            </HideShow>
            <HideShow show={field_name && (field_name === 'amount' || field_name === 'amount_paid' || field_name === 'subtotal' || field_name === 'balance' || field_name === 'minimum_amount_due')}>
              <div className="value-box">
                <CurrencyInput name="value" value={this.state.conditions[index].value} onChange={ e => this.handleConditionChange(index, e)} placeholder="Amount"/>
              </div>
            </HideShow>
            <HideShow show={field_name && field_name === 'paid_date'}>
            <DayInputWrapper>
              <DayInput name="value-2" value={this.state.conditions[index].value} onChange={ e => this.handleConditionChange(index, e, undefined, true)}/>
            </DayInputWrapper>
              <div className="value-text">ago</div>
            </HideShow>
            <HideShow show={field_name && field_name === 'description'}>
              <div className="full-input short">
                <Input name="value" onChange={ e => this.handleConditionChange(index, e) } value={this.state.conditions[index].value} placeholder="value"/>
              </div>
            </HideShow>
            <HideShow show={field_name && field_name === 'days_late'}>
            <DayInputWrapper>
              <DayInput name="value-3" value={this.state.conditions[index].value} onChange={ e => this.handleConditionChange(index, e, undefined, true)}/>
            </DayInputWrapper>
            </HideShow>
            <HideShow show={field_name && field_name === 'state'}>
              <Typeahead custom
                    onChange={ e => this.handleConditionChange(index, e, 'value')}
                    name="status"
                    placeholder="Status"
                    options={options.values['state']}
                    styles={conditionDropdown}
                    components={{DropdownIndicator}}
                    value={this.state.conditions[index].display_value}
                    unsearchable={true}/>
              </HideShow>
          </div>
        )
      })

      return(
        <>
          <div className="wizard-header">
            <div className="title">{this.props.trigger ? 'Edit' : 'Create'} Conditions</div>
            <div className="below-title">
              <div className="subtitle">What criteria is necessary for this event to happen?</div>
              <div className="subtext">ex: Send text if customers have a past due balance.</div>
            </div>
          </div>
          <div className="main">
            <div className="step-instr space">Conditions</div>
            <div className="conditions">
              { Conditions }
              <HideShow show={this.state.conditions.length > 1 || (this.state.conditions.length === 1 && this.state.conditions[0].value) || this.state.conditions.length === 0}>
                <img src={addButton} alt="add condition" className="icon add" onClick={this.addCondition}/>
              </HideShow>
            </div>
          </div>
        </>
      )
    }
  }

  handleActionChange = (index, event, name) => {
    let actions = this.state.actions.slice();
    if(event.value) {
      if(name === 'action_type') {
        actions[index].display_template = null;
        actions[index].template = null;
        actions[index].display_recipients = null;
        actions[index].recipients = null;
      }
      actions[index][name] = event.value;
      actions[index][`display_${name}`] = [event];
    } else if(event.target) {
      actions[index][event.target.name] = event.target.value;
    }
    if(name === 'recipients' && event.value !== 'email') {
      this.setState({actions, to_email: undefined})
    } else this.setState({actions})
  }

  addAction = () => {
    let actions = this.state.actions.slice();
    actions.push({action_type: null});
    this.setState({actions})
  }

  removeAction = i => {
    let actions = this.state.actions.filter( (action, index) => index !== i );
    this.setState({actions})
  }

  toggleDay = day => {
    let scheduled_days = this.state.scheduled_days.slice();
    if(!scheduled_days.includes(day)) {
      scheduled_days.push(day);
    } else {
      scheduled_days = scheduled_days.filter( days => days !== day )
    }
    this.setState({scheduled_days})
  }

  renderMinute = () => {
    if(this.state.send_minute < 10) {
      return '0' + this.state.send_minute;
    } else return this.state.send_minute;
  }

  togglePM = () => {
    let { am_pm, send_hour } = this.state;
    if(am_pm === 'PM') {
      if(send_hour >= 8 && send_hour !== 12) {
        this.setState({am_pm: 'AM'})
      } else {
        this.setState({send_hour: 11, am_pm: 'AM'})
      }
    }

    if(am_pm === 'AM') {
      if(send_hour === 8) {
        this.setState({am_pm: 'PM'})
      } else if(send_hour === 9) {
        this.setState({am_pm: 'PM', send_minute: 0})
      } else {
        this.setState({send_hour: 12, am_pm: 'PM'})
      }
    }
  }

  adjustTime = (time, direction) => {
    let { am_pm, send_hour, send_minute } = this.state;
    if(time === 'hour') {
      if(direction === 'up') {
        if( (am_pm === 'PM' && send_hour < 9 ) || (am_pm === 'AM' && send_hour < 11) ) {
          this.setState({send_hour: send_hour + 1})
        } else if(am_pm === 'PM' && send_hour === 12) {
          this.setState({send_hour: 1})
        } else if(am_pm === 'AM' && send_hour === 11) {
          this.setState({send_hour: 12, am_pm: 'PM'})
        }
      } else if(direction === 'down') {
        if(am_pm === 'PM') {
          if(send_hour > 1 && send_hour < 12) {
            this.setState({send_hour: send_hour - 1})
          } else if(send_hour === 1) {
            this.setState({send_hour: 12})
          } else if(send_hour === 12) {
            this.setState({send_hour: 11, am_pm: 'AM'})
          }
        } else if(am_pm === 'AM') {
          if(send_hour > 8) {
            this.setState({send_hour: send_hour - 1})
          }
        }
      }
    }

    if(time === 'minute') {
      if(direction === 'up') {
        if(send_minute < 55 && ( (am_pm === 'PM' && (send_hour < 9 || send_hour === 12)) || am_pm === 'AM') ) {
          this.setState({send_minute: send_minute += 5})
        } else if(send_minute === 55) {
          if( (am_pm === 'PM' && send_hour < 9) || (am_pm === 'AM' && send_hour < 11) ) {
            this.setState({send_hour: send_hour + 1, send_minute: 0})
          } else if(am_pm === 'PM' && send_hour === 12) {
            this.setState({send_hour: 1, send_minute: 0})
          }
            else if(am_pm === 'AM' && send_hour === 11) {
            this.setState({send_hour: 12, send_minute: 0, am_pm: 'PM'})
          }
        }
      } else if(direction === 'down') {
        if(send_minute > 0) {
          this.setState({send_minute: send_minute -= 5})
        } else if(send_minute === 0) {
          if(am_pm === 'PM') {
            if(send_hour > 1 && send_hour < 12) {
              this.setState({send_hour: send_hour - 1, send_minute: 55})
            } else if(send_hour === 1) {
              this.setState({send_hour: 12, send_minute: 55});
            } else if(send_hour === 12) {
              this.setState({send_hour: 11, send_minute: 55, am_pm: 'AM'})
            }
          } else if(am_pm === 'AM') {
            if(send_hour > 8) {
              this.setState({send_hour: send_hour - 1, send_minute: 55})
            }
          }
        }
      }
    }
  }

  renderStepThree = () => {
    if(this.state.step === 3) {
      let Actions = this.state.actions.map( (action, index) => {
        let { action_type } = this.state.actions[index];
        return(
          <div className="row" key={index}>
             <Typeahead custom
                onChange={ e => this.handleActionChange(index, e, 'action_type')}
                name="action"
                placeholder="Action"
                options={options.actions}
                styles={conditionDropdown}
                components={{DropdownIndicator}}
                value={this.state.actions[index].display_action_type}
                unsearchable={true}/>
              <HideShow show={action_type && action_type === 'message'}>
                <Typeahead
                  name="template"
                  onChange={ e => this.handleActionChange(index, e, 'template')}
                  placeholder="Template"
                  options={this.props.templates}
                  styles={conditionDropdown}
                  components={{DropdownIndicator}}
                  value={this.state.actions[index].display_template}
                  unsearchable={true}/>
                <Typeahead custom
                  onChange={ e => this.handleActionChange(index, e, 'recipients')}
                  placeholder="Recipients"
                  options={options.recipients}
                  styles={conditionDropdown}
                  components={{DropdownIndicator}}
                  value={this.state.actions[index].display_recipients}
                  unsearchable={true}/>
              </HideShow>
              <HideShow show={this.state.actions.length > 1}>
                <img src={rmvButton} alt="remove condition" className="icon remove" onClick={ () => this.removeAction(index)}/>
              </HideShow>
          </div>
        )
      })

      const DayButtons = options.days.map( (day, i) => {
        return(
          <div key={i} className={classNames('day-box ', {'selected': this.state.scheduled_days.includes(day.value)})} onClick={ () => this.toggleDay(day.value)}>{day.label}</div>
        )
      })

      return(
        <>
          <div className="wizard-header">
            <div className="title">{this.props.trigger ? 'Edit' : 'Set'} Actions</div>
            <div className="below-title">
              <div className="subtitle">What do you want to happen based on the conditions?</div>
              <div className="subtext">ex: When an invoice is past due, send a reminder text to customers.</div>
            </div>
          </div>
          <div className="main">
            <div className="step-instr space">Select an Action</div>
            <div className="conditions">
              {Actions}
              <HideShow show={this.state.actions[0].recipients === 'email'}>
                <div className="full-input email">
                  <Input name="to_email" onChange={this.handleInput} type="email" required value={this.state.to_email} placeholder="Email Address" ref={this.myRefs.to_email}/>
                </div>
              </HideShow>
              {/* <HideShow show={this.state.actions.length < 2 && this.state.actions[0].action_type}>
                <img src={addButton} alt="add action" className="icon add" onClick={this.addAction}/>
              </HideShow>               */}
            </div>
            <div className="step-instr">Date and Time</div>
            <div className="subtext">Choose the day(s) and time the actions should occur</div>
            <DateSelector>
              <div className="days">
                {DayButtons}
              </div>
              <div className="at-box">at</div>
              <div className="time-selection">
                <div className="hour-minute">
                  {this.state.send_hour}
                  <div className="triangle-button up" onClick={ () => this.adjustTime('hour', 'up') }><div className="triangle up"/></div>
                  <div className="triangle-button down" onClick={ () => this.adjustTime('hour', 'down') }><div className="triangle down"/></div>
                </div>
                <div className="hour-separator">
                  <Square/>
                  <Square/>
                </div>
                <div className="hour-minute">
                  {this.renderMinute()}
                  <div className="triangle-button up" onClick={ () => this.adjustTime('minute', 'up') }><div className="triangle up"/></div>
                  <div className="triangle-button down" onClick={ () => this.adjustTime('minute', 'down') }><div className="triangle down"/></div>
                </div>
                <div className="hour-minute">
                  {this.state.am_pm}
                  <div className="triangle-button up" onClick={this.togglePM}><div className="triangle up"/></div>
                  <div className="triangle-button down" onClick={this.togglePM}><div className="triangle down"/></div>
                </div>
              </div>
            </DateSelector>
          </div>
        </>
      )
    }
  }

  returnDayList = () => {
    let { scheduled_days } = this.state;
    let days = [];
    options.days.forEach( day => {
      if(scheduled_days.includes(day.value)) {
        days.push(day.full)
      }
      return;
    })
    let dayList = days.reduce( (prev, current, index) => {
      if(days.length > 1) {
        if(index !== days.length - 1) {
          if(days.length === 2) {
            return prev + current + ' and '
          } else if(days.length > 2) {
            return prev + current + ', '
          }
        } else {
          if(days.length === 2) {
            return prev + current
          } else if(days.length > 2) {
            return prev + 'and ' + current
          }
        }
      }
      return prev + current
    }, '')
    return dayList;
  }

  renderActionMessage = () => {
    let { actions, send_hour, am_pm } = this.state;
    return actions.map( (action, index) => {
      if(action.action_type === 'message') {
        return <div className="action-readable space" key={index}>Send a message containing {action.display_template[0].label} to {action.recipients === 'email' ? this.state.to_email : action.recipients} on {this.returnDayList()} at {`${send_hour}:${this.renderMinute()} ${am_pm}`} according to the following conditions:</div>
      }
      return <div></div>;
    })
  }

  renderConditions = () => {
    let { conditions, object_name } = this.state;
    let currentOperator;
    let ConditionList = conditions.map( (condition, index) => {
      if(object_name === 'UserInvoice') {
        if(condition.field_name === 'payment_plan') {
        return <p key={index}>invoice is {condition.value === 'no' ? 'not ' : ''}on a payment plan</p>
        } else if(condition.field_name === 'due_date') {
          currentOperator = options.operators['due_date'].filter( operator => operator.value === condition.operator);
          return <p className="line" key={index}>{condition.type === 'filter' ? 'include' : 'exclude'} invoices with due date {currentOperator[0].label.toLowerCase()} {currentOperator[0].display} {condition.value} {condition.value === "1" ? 'day' : 'days'}</p>
        } else if(condition.field_name === 'paid_date') {
          return <p className="line" key={index}>{condition.type === 'filter' ? 'include' : 'exclude'} invoices paid {condition.value} {condition.value === "1" ? 'day' : 'days'} ago</p>
        }
        // else if(condition.field_name === 'days_late') {
        //   return <p className="line" key={index}>{condition.type === 'filter' ? 'include' : 'exclude'} invoice is {condition.value} {condition.value === "1" ? 'day' : 'days'} late</p>
        // }
        else if(condition.field_name === 'description') {
          currentOperator = options.operators['description'].filter( operator => operator.value === condition.operator )
          return <p className="line" key={index}>{condition.type === 'filter' ? 'include' : 'exclude'} invoices with description {currentOperator[0].display} '{condition.value}'</p>
        } else {
          return <p className="line" key={index}>{condition.type === 'filter' ? 'include' : 'exclude'} invoices with status equal to {condition.value}</p>
        }
      }
      return condition;
    })
    return ConditionList;
  }

  renderStepFour = () => {
    if(this.state.step === 4) {
      return(
        <>
          <div className="wizard-header">
            <div className="title">Confirm and {this.props.trigger ? 'Save' : 'Create'}</div>
            <div className="below-title">
              <div className="subtitle">You are about to {this.props.trigger ? 'save this automation as follows:' : 'create the following automation:'}</div>
            </div>
          </div>
          <div className="main">
            {this.renderActionMessage()}
            <div className="condition-preview">
              {this.renderConditions()}
            </div>

          </div>
        </>
      )
    }
  }

  renderCancel = () => {
    if(this.state.step === 1) {
      return <Button type="wizard-secondary" onClick={this.props.toggleWizard}>Cancel</Button>
    } else {
      let buttonText = '< Back'
      return(
        <Button type="wizard-secondary" onClick={this.navBack}>{buttonText}</Button>
      )   
    }
  }

  renderConfirm = () => {
    if(this.state.step === 4) {
      if(this.props.trigger) {
        return <Button type="wizard-primary" onClick={e => this.submitAutomation(e, 'edit')}>Save</Button>
      } else return <Button type="wizard-primary" onClick={this.submitAutomation}>Create</Button>
    } else {
      let buttonText = 'Next >'
      return(
        <Button type="wizard-primary" onClick={this.navForward}>{buttonText}</Button>
      )   
    }
  }

  navForward = (e) => {
    e.stopPropagation()
    if(this.state.step === 1) {
      if(this.state.label && this.state.object_name) {
        this.setState({step: 2})
      } else {
        this.props.setNotification({message: 'Please make sure your event has a name and object', type: 'error'})
      }
    }

    if(this.state.step === 2) {
      let conditionFlag = true;
      this.state.conditions.forEach( condition => {
        let {type, field_name, operator, value} = condition;
        if (!type) {
          conditionFlag = false;
        }
        if (!field_name) {
          conditionFlag = false;
        }
        if (!operator) {
          conditionFlag = false;
        }
        if(!value) {
          conditionFlag = false;
        }
      })
      if(conditionFlag) {
        this.setState({step: 3})
      } else {
        this.props.setNotification({message: 'Please make sure each condition has a field, operator, and value', type: 'error'})
      }
    }

    if(this.state.step === 3) {
      let emptyFlag = false;
      this.state.actions.forEach( action => {
        for(let prop in action) {
          if(!action[prop]) {
            emptyFlag = true
          }
        }
      })
      if(!emptyFlag) {
        if(!globalUtils.checkValues(this.myRefs)) {
          this.props.setNotification({message: 'Please fix all form errors', type:'error'})
          return;
        } else if(this.state.actions[0].recipients === 'email' && !this.state.to_email) {
          this.props.setNotification({message: 'Please enter an email address', type: 'error'});
          return;
        }
        if(this.state.scheduled_days.length < 1) {
          this.props.setNotification({message: 'Please select at least one day', type: 'error'});
          return;
        }
        this.setState({step: 4})
      } else {
        this.props.setNotification({message: 'Please make sure all action fields have values', type: 'error'})
        emptyFlag = false;
      }
    }
  }

  navBack = () => {
    this.setState({step: this.state.step - 1})
  }

  submitAutomation = async (event, edit) => {
    event.stopPropagation();
    let { scheduled_days, actions, am_pm, send_hour, send_minute, conditions, label, object_name, to_email } = this.state;

    let send_time = '';
    if(am_pm === 'AM') {
      send_time = send_time + (send_hour < 10 ? '0' : '') + send_hour + ':' + (+send_minute < 10 ? '0' + send_minute : send_minute);
    } else {
      send_time = send_time + (send_hour < 12 ? send_hour + 12 : send_hour) + ':' + (+send_minute < 10 ? '0' + send_minute : send_minute);
    }

    let data = {
      merchant: this.props.merchant_id,
      template: actions[0].template,
      active: true,
      label,
      object_name,
      conditions: [],
      actions: [],
      send_time,
      scheduled_days
    }
    if(to_email) {
      data.to_email = to_email;
    }
    conditions.forEach( condition => {
      let operator = condition.operator;
      let value = condition.value;
      if(condition.field_name === 'due_date') {
        if(operator === 'past_due' && value > 0) {
          value *= -1;
        }
        operator = 'date';
      }
      if(condition.field_name === 'paid_date') {
        operator = 'date'
      }
      if(this.props.trigger) {
        data.conditions.push({type: condition.type, field_name: condition.field_name, operator: operator, value: value, id: condition.id})
      } else {
        data.conditions.push({type: condition.type, field_name: condition.field_name, operator: operator, value: value})
      }
    })

    actions.forEach( action => {
      data.actions.push({
        action_type: action.action_type,
        recipients: action.recipients ? action.recipients : undefined,
      })
    })
    if(edit) {
      data.id = this.props.trigger.id;
      let updatedTrigger = await automationAPI.editTrigger(data, this.props.trigger.id, this.props.auth_token)
      if (updatedTrigger.err && updatedTrigger.err.response) {
        this.props.setNotification({message: updatedTrigger.err.response.data.errors, type: 'error'})
        return
      } else {
        this.props.getTriggers();
        this.props.setNotification({message: 'Trigger successfully updated'})
      }
    } else {
      let newTrigger = await automationAPI.createTrigger(data, this.props.auth_token)
      if (newTrigger.err && newTrigger.err.response) {
        this.props.setNotification({message: newTrigger.err.response.data.errors, type: 'error'})
        return
      } else {
        this.props.getTriggers();
      }
    }
  }

  render() {
    return(
      <WizardWrapper>
        <SideBar>
          <div className="descriptions">
            <div className="step-desc">
              <span className={classNames('step-title ', {'inactive' : this.state.step !== 1})}>{this.props.trigger ? 'Edit this Event' : 'Create Automated Event'}</span>
              <span className={classNames('step-sub ', {'inactive' : this.state.step !== 1})}>Name your event and select the object.</span>
              <span className={classNames('step-box ', {'step-box--active' : this.state.step >= 1})}>1</span>
            </div>
            <div className="step-desc">
              <span className={classNames('step-title ', {'inactive' : this.state.step !== 2})}>{this.props.trigger ? 'Edit' : 'Create'} Conditions</span>
              <span className={classNames('step-sub ', {'inactive' : this.state.step !== 2})}>{this.props.trigger ? 'Edit' : 'Create'} the conditions that will trigger the automation.</span>
              <span className={classNames('step-box ', {'step-box--active' : this.state.step >= 2})}>2</span>
            </div>
            <div className="step-desc">
              <span className={classNames('step-title ', {'inactive' : this.state.step !== 3})}>{this.props.trigger ? 'Edit' : 'Set'} Actions</span>
              <span className={classNames('step-sub ', {'inactive' : this.state.step !== 3})}>{this.props.trigger ? 'Edit' : 'Create'} the actions that will happen based on the conditions created.</span>
              <span className={classNames('step-box ', {'step-box--active' : this.state.step >= 3})}>3</span>
            </div>
            <div className="step-desc">
              <span className={classNames('step-title ', {'inactive' : this.state.step !== 4})}>Confirm and {this.props.trigger ? 'Save' : 'Create'}</span>
              <span className={classNames('step-sub ', {'inactive' : this.state.step !== 4})}>Make sure your automation looks correct and save it.</span>
              <span className={classNames('step-box ', {'step-box--active' : this.state.step === 4})}>4</span>
            </div>
          </div>
        </SideBar>
        <Content>
          {this.renderStepOne()}
          {this.renderStepTwo()}
          {this.renderStepThree()}
          {this.renderStepFour()}
          <ButtonWrapper>
            {this.renderCancel()}
            {this.renderConfirm()}
          </ButtonWrapper>
        </Content>
      </WizardWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    merchant_id: state.auth.merchant_id
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomationWizard);
