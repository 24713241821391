import { PURGE } from 'redux-persist';

import {
  SET_USER_INFO,
  SET_PAYMENT_METHOD,
  SET_USER_ID,
  SET_SUB_ID,
  STORE_CUSTOMER_AUTH,
  LOGIN_CUSTOMER,
  LOGIN_MERCHANT,
  STORE_MERCHANT_ID,
  STORE_CUSTOMER_USERNAME,
  IS_MERCHANT
} from '../actions/auth';
import { LOGOUT, HARD_LOGOUT } from '../constants/index';

import history from '../../history';

const initialState = {
  auth_token: undefined,
  username: undefined,
  user_id: undefined,
  sub_id: undefined,
  user_role: undefined,
  user_info: undefined,
  merchant_name: undefined,
  merchant_logo: undefined,
  merchant_id: undefined,
  ach_info: undefined,
  is_merchant: undefined
}

const auth = (state = initialState, {type, payload}) => {
  switch (type) {
    case IS_MERCHANT:
      return Object.assign({}, state, { is_merchant: payload });
    case SET_USER_ID:
      return Object.assign({}, state, { user_id: payload });
    case SET_SUB_ID:
      return Object.assign({}, state, { sub_id: payload });
    case SET_PAYMENT_METHOD:
      return Object.assign({}, state, { payment_method: payload });
    case LOGIN_CUSTOMER:
        return Object.assign({}, state, {
        user_pin: payload
       });
    case STORE_CUSTOMER_AUTH:
        return Object.assign({}, state, {
          auth_token: payload,
          user_role: 4,
        });
    case STORE_CUSTOMER_USERNAME:
        return Object.assign({}, state, {
          username: payload,
        });
    case STORE_MERCHANT_ID:
        return Object.assign({}, state, {merchant_id: payload});
    case LOGIN_MERCHANT:
      return Object.assign({}, state, { auth_token: payload.auth_token, user_role: payload.user_role });
    case HARD_LOGOUT:
      if (window) {
        window.localStorage.clear()
      }
      if (payload) {
        history.push(payload)
      }
      return initialState
    case LOGOUT:
      let username;
      if ( state.username || (state.user_info && state.user_info.username)) {
        username = state.username || state.user_info.username;
      }
      if (window.location.href.indexOf('embedded-payment') > -1) {
        return Object.assign({}, initialState, {username});
      } else if (!state.is_merchant && window.location.href.indexOf('customer') > -1){
        history.push('/customer/login');
      } else if (window.location.href.indexOf('hosted-payment') > -1) {
        return Object.assign({}, initialState, {username});
      } else if (window.location.href.indexOf('donation') > -1) {
        return Object.assign({}, initialState, {username});
      } else {
        history.push('/login');
      }
      return Object.assign({}, initialState, {username});
    case SET_USER_INFO:
      return Object.assign({}, state, { user_info: payload });
    case PURGE:
      return {}
    default:
      return state;
  }
};

export default auth;
