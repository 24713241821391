import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  font-size: ${props => props.small ? '18px' : '20px'};
  color: ${props => props.active ? '#59637A' : '#ACB3C2'};
  margin-left: 5px;
  position: relative;
  margin-right: 20px;
  transition: all, .2s ease-out;
  &:hover {
    color: #59637A;
  }
  .tab{
    border-bottom: ${props => props.active ? '5px solid #5DC1BD' : ''};
    width: ${props => props.width ? props.width : '125px'};
    position: absolute;
    left: -10px;
    bottom: -13px;
    transition: all, .2s ease-out;
  }
`

export default function Tabs(props) {
  return (
    <Container onClick={props.onClick} {...props}>{props.name}
      <span className="tab"></span>
    </Container>
  )
}
