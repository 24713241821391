import React, {Component} from 'react';
import styled from 'styled-components';
import Initials from '../../../components/display/Initials';
import globalUtils from '../../../utils/globalUtils';
import Checkbox from '../../../components/inputs/Checkbox';

const MessageLineWrapper = styled.div`
  position: relative;
  height: 75px;
  .message-line {
    background-color: ${props => props.activeSub && props.activeSub === props.message.sub_id ? '#B9C2CB' : '#fff'};
    margin-bottom: 1px;
    box-shadow: 0px -4px 7px rbga(230, 230, 230, .5);
    position: relative;
    height: 100%;
    cursor: pointer;
    float: right;
    width: 98%;
  }
  .checkbox {
    position: absolute;
    left: -26px;
    top: 50%;
    transform: scale(1.3);
    border: 1px solid #F1F4F7;
    box-shadow: none;
  }
  .initials-box {
    position: absolute;
    top: 19px;
    left: 13px;
  }
  .name {
    position: absolute;
    top: 15px;
    left: 55px;
    font-size: 21px;
    font-weight:  ${props => !props.message.read ? 'bold' : '300'};
    color: ${props => props.activeSub && props.activeSub === props.message.sub_id ? '#fff' : '#626262'};
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-height: 700px){
      font-size: 18px;
      width: 175px;
    }
    text-transform: capitalize;
  }
  .time {
    color: ${props => props.activeSub && props.activeSub === props.message.sub_id ? '#fff' : '#868686'};
    position: absolute;
    right: 3px;
    font-size: 12px;
    top: 3px;
    @media (max-height: 700px){
      font-size: 11px;
    }
  }
  .date {
    color: ${props => props.activeSub && props.activeSub === props.message.sub_id ? '#fff' : '#868686'};;
    position: absolute;
    right: 3px;
    font-size: 12px;
    top: 17px;
    @media (max-height: 700px){
      font-size: 11px;
    }
  }
  .body {
    position: absolute;
    left: 55px;
    top: 44px;
    font-size: 18px;
    font-weight: ${props => !props.message.read ? 'bold' : '300'};
    color: ${props => props.activeSub && props.activeSub === props.message.sub_id ? '#fff' : '#868686'};
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-height: 700px){
      font-size: 16px;
    }
  }
  .unread {
    border-radius: 50px;
    background-color: #007aff;
    position: absolute;
    width: 6px;
    height: 6px;
    top: 34px;
    left: 3px;
  }
`

export default class MessageLine extends Component {
  constructor(props){
    super(props);
    this.state = {
      checked: false
    };
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    })
    if(value) {
      // alert('Value is truthy')
      this.props.getMessageDetail(this.props.message.sub_id)
      this.props.onChange(this.props.message.sub_id, value)
    } else {
      this.props.clearMessages();
    }
  }

  renderBlueDot = () => {
    if (!this.props.message.read) {
      return <div className="unread"></div>
    }
  }

  render() {
    return (
      <MessageLineWrapper {...this.props}>
        <Checkbox checked={this.props.checked || this.state.checked} data-test-id="checked" name="checked" onChange={this.onChange}/>
        <div className="message-line" data-test-id="msg-line-detail" onClick={ () => this.props.getMessageDetail(this.props.message.sub_id) }>
          {this.renderBlueDot()}
          <div className="initials-box">
            <Initials first_name={this.props.message.first_name} last_name={this.props.message.last_name} activeSub={this.props.activeSub} sub_id={this.props.message.sub_id} business_name={this.props.message.user_name} size="mini"/>
          </div>
          <div className="time">{globalUtils.formatTimeOnly(this.props.message.created_at)}</div>
          <div className="date">{globalUtils.formatSimpleDate(this.props.message.created_at)}</div>
          <div className="name">{this.props.message.user_name.toLowerCase()}</div>
          <div className="body">{this.props.message.truncated_body}</div>
        </div>
      </MessageLineWrapper>
    )
  }
}
