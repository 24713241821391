import React, {Component} from 'react';
import styled from 'styled-components';
import HideShow from '../function/HideShow';
import loading_gif from '../../assets/images/blue-spinner.png';

const LoadingContainer = styled.div`
  margin: 0 auto;
  width: 200px;
  z-index: 9;
  position: relative;
  text-align: center;
  top: 35px;
  background-color: ${props => props.noBackground ? '' : '#fff'};
`

const Text = styled.div`
  text-align: center;
  margin-top: 35px;
  font-size: 21px;
  @keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
  }
  .saving span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  .saving span:nth-child(2) {
    animation-delay: .2s;
  }
  .saving span:nth-child(3) {
    animation-delay: .4s;
  }
`

const Spinner = styled.img`
  height: 40px;
  width: auto;
  animation: spin 1.5s linear infinite;
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
`

export default class Loading extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

renderText = () => {
  if (this.props.text) {
    return (
      <Text><p className="saving">{this.props.text}<span>.</span><span>.</span><span>.</span></p></Text>
    )
  }
}

  render() {
    return (
      <HideShow show={!this.props.loaded}>
        <LoadingContainer {...this.props}>
          <Spinner src={loading_gif}/>
        </LoadingContainer>
        {this.renderText()}
      </HideShow>
    )
  }
}
