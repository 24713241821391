import React, {Component} from 'react';
import styled from 'styled-components';
import history from '../../../history';

//Assets
import qrcode from '../../../assets/images/qrcode.png';

import { PageWrapper } from '../../../components/mobile/MobileStyles';

const Container = styled.div`
  position: fixed;
  background-color: #515a71;
  height: 100%;
  width: 100%;
  text-align: center;
`

const Icon = styled.img`
  height: 400px;
  width: auto;
  margin: 0 auto;
  margin-top: 40px;
`

export default class AddCash extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  reroute = () => {
    history.push('/customer/receipts/cash/0972321')
  }

  render() {
    return (
      <PageWrapper>
        <Container>
          <Icon src={qrcode} data-test-id="add-cash-receipts" onClick={this.reroute}/>
        </Container>
      </PageWrapper>
    )
  }
}
