import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';

//Components
import Background from '../../components/mobile/MobileBackground';
import ActionButton from '../../components/buttons/ActionButton';
import Input from '../../components/inputs/Input';
import ErrorHandle from '../../components/function/ErrorHandle';

//API & Utils
import customerAuthAPI from '../../api/auth/customerAuthAPI';
import smsOptInAPI from '../../api/tcpa/SmsOptInAPI';
import globalUtils from '../../utils/globalUtils';
import history from '../../history';

const Center = styled.div`
  margin-top: 140px;
`
const Container = styled.div`
  text-align: center;
  background-color: #f1f4f7;
  height: ${state => state.success ? '275px' : '255px'};
  width: 700px;
  margin-top: ${state => state.success ? '155px' : '165px'};
  border-radius: 11px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  padding: 20px 35px;
  position: relative;
  margin: 0 auto;
  input {
    border: 1px solid #fff;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 14px;
    }
  }
  @media (max-width: 580px) {
    width: 330px;
  }
  @media (min-device-height: 668px) {
  margin-top: ${state => state.success ? '190px' : '200px'};
  }
  @media (min-device-height: 896px) {
    margin-top: 240px;
  }

  @media screen and (device-aspect-ratio: 40/71) {
    /* iphone 5 only */
    /* height: 320px; */
    width: 300px;
  }  
`

const Header = styled.div`
  color: #61a9f5;
  font-size: 24px;
  text-align: left;
  margin-bottom: 20px;
  font-weight: bold;
`

const Text = styled.div`
  color: #888;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: left;
`

const Text2 = styled.div`
  color: white;
  position: absolute;
  bottom: -30px;
  /* left: 165px; */
  cursor: pointer;
  text-align: center;
  text-decoration: underline;

  @media (max-width: 580px) {
    width: 330px;
    bottom: -23px;
    font-size: 14px;
    margin: 0 -35px;
  }
  @media screen and (device-aspect-ratio: 40/71) {
    /* iphone 5 only */
    width: 300px;
  }  
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  @media (max-width: 580px) {
    margin-top: 22px;
    justify-content: center;
  }
`

const ErrorContainer = styled.div`
text-align: center;
  div {
    color: ${state => state.success ? '#06D6A0' : '#FD7272'};
    margin: 0 0 5px 0;
  }
`

class VerifyCode extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const values = globalUtils.getQueryString(history.location.search.slice(1))
    this.subscription_id = values.subscription_id;
    this.phone = values.phone
    this.username = values.username
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  submit = async () => {
    if (!this.state.code) {
      this.setState({ success: false, errors: 'Confirmation code required' });
      return;
    }
    let response = await smsOptInAPI.verifyCode({code: this.state.code, subscription_id: this.subscription_id});
    if (response.err){
      this.setState({ success: false, errors: "Code is invalid" })
      return;
    }
    if (response && !response.err) {
      history.push('/welcome/3?username='+this.username);
    }
  }

  resendCode = async () => {
    let opt_in = await customerAuthAPI.optIn({phone: this.phone, subscription_id: this.subscription_id});
    if (opt_in.err && opt_in.err.response) {
      this.setState({errors: opt_in.err.response.data.detail})
      return
    }
    if (opt_in) {
      this.setState({ success: true, errors: "Confirmation code resent" })
    }
  }

  render() {
    return (
      <Background fixed show_logo>
        <Center>
        <Container {...this.state}>
          <ErrorContainer {...this.state}>
            <ErrorHandle data={this.state.errors}/>
          </ErrorContainer>
          <Header>Check your texts</Header>
          <Text>A confirmation code was sent to you via text message. Please enter it below:</Text>
          <Input name="code" onChange={this.handleChange} className="merchant-input" placeholder="confirmation code" type="tel"/>
          <ButtonContainer><ActionButton data-test-id="verify-code-confirm-2" onClick={this.submit} red>Confirm</ActionButton></ButtonContainer>
          <Text2 data-test-id="verify-code-resend-2" onClick={this.resendCode}>Didn't receive a code? Click here to resend</Text2>
        </Container>
        </Center>
      </Background>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    username: state.auth.username
  };
};

export default connect(
  mapStateToProps
)(VerifyCode)
