import React, {Component} from 'react';
import styled from 'styled-components';

const BoldTitle = styled.div`
  font-size: 27px;
  font-weight: 900;
  text-align: center;
  margin-bottom: ${props => props.add_card ? '10px' : '30px'};
  text-transform: capitalize;
`

export default class BoldTitleWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <BoldTitle {...this.props}>{this.props.children}</BoldTitle>
    )
  }
}
