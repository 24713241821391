import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import history from '../../history';

// Assets
import { clearFix } from '../../styles/mixins';
import addCustomer from '../../assets/icons/add_customer.png';

// Components
import AddInvoiceModal from '../modals/invoices/AddInvoiceModal';
import AddCustomerModal from '../modals/customers/AddCustomerModal';
import Button from '../buttons/NewButton';

// Redux
import {
  setInvoiceCustomer,
  toggleAddInvoice,
  toggleAddCustomer
} from '../../js/actions/modal_actions';

const GradientContainer = styled.div`
  background: linear-gradient(to bottom, #F5F5F5, #EAEAEA);
  height: 100%;
  width: 100%;
  padding-left: 253px;
  padding-top: 70px;
  position: fixed;
  z-index: -1;
  overflow: auto;
  overflow-y: ${props => props.customerDetail ? 'scroll' : null };
  overflow-x: ${props => props.customerDetail ? 'hidden' : null };

  .header {
    width: 100%;
    margin-bottom: 20px;

    & > * {
      float: right;
      margin-left: 10px;
      &:not(:last-child) {
        margin-right: 40px;
      }
    }

    ${clearFix};
  }
`

class GradientContainerWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  showAddInvoiceModal = () => {
    if(this.props.showAddInvoice) {
    return <AddInvoiceModal/>
    }
  }

  showAddCustomerModal = () => {
    if(this.props.showAddCustomer) {
    return <AddCustomerModal/>
    }
  }

  toggleInvoice = () => {
    if(history.location.pathname.substring(0,25) === "/admin/customers/profile/") {
      this.props.toggleAddInvoice(true);
    } else {
      this.props.setInvoiceCustomer(undefined);
      this.props.toggleAddInvoice(true);
    }
  }

  render() {
    return (
      <GradientContainer {...this.props}>
        <div className="header">
          <Button type="primary" onClick={ () => this.props.toggleAddCustomer(true) }><img src={addCustomer} alt="add customer" className="icon"/> New Customer</Button>
          <Button type="secondary" onClick={this.toggleInvoice}>+ New Invoice</Button>
        </div>
        {this.showAddInvoiceModal()}
        {this.showAddCustomerModal()}

        {this.props.children}
      </GradientContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    invoice_customer: state.modals.invoice_customer,
    showAddInvoice: state.modals.showAddInvoice,
    showAddCustomer: state.modals.showAddCustomer
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setInvoiceCustomer: customer => {dispatch(setInvoiceCustomer(customer))},
    toggleAddInvoice: bool => {dispatch(toggleAddInvoice(bool))},
    toggleAddCustomer: bool => {dispatch(toggleAddCustomer(bool))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradientContainerWrapper)
