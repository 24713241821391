import React, {Component} from 'react';
import styled from 'styled-components';

//Components
import Modal from '../../../components/mobile/MobileModalRounded';
import MobileWrapper from '../../../components/mobile/MobileWrapper';
import AddCard from './AddCard';
import AddBank from './AddBank';
import AddCash from './AddCash';

//Assets
import credit_card from '../../../assets/icons/card_gradient.png';
import check from '../../../assets/icons/check_gradient.png';
import blytz_cash from '../../../assets/icons/blytz-cash.png';
import HideShow from '../../../components/function/HideShow';

const Background = styled.div`
  background: linear-gradient(315.36deg, #8D97AD 0%, #545E75 100%);
  position: fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  @media only screen and (min-width: 580px) {
    display: flex;
    justify-content: center;
  }
  @media only screen and (min-width: 768px) {
    flex-direction: column;
  }
`

const WhiteWrapper = styled.div`
  background-color: #FFFFFF;
  position: relative;
  top: 70px;
  height: 100%;
`;

const IconTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  img.icon {
    height: 100px;
    width: auto;
    margin-left: 10px;
  }
  .text {
    color: #64ABF5;
    font-size: 24px;
    margin-top: 35px;
    margin-left: 10px;
    font-weight: bold;
  }
`

export default class SelectPaymentType extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  showBank = () => {
    if (this.props.ach_creds && !this.props.split) {
      return (
        <IconTextWrapper>
          <img src={check} className="icon" alt="bank account"/>
          <div className="text" data-test-id={"slct-pmt-type-bank-account-" + this.props.context} onClick={this.toggleAddBank}>Bank Account</div>
        </IconTextWrapper>
      )
    } else {
      return;
    }
  }

  showCash = () => {
    if (this.props.invoice && this.props.invoice.merchant.id === '8bca560e-7c0b-4131-a8f3-a292cac42f43') {
      return (
        <IconTextWrapper data-test-id={"slct-pmt-type-cash-" + this.props.context} onClick={this.toggleAddCash}>
          <img src={blytz_cash} className="icon" alt="cash icon"/>
          <div className="text">Cash</div>
        </IconTextWrapper>
      )
    }
  }

  showAddCard = () => {
    if(this.state.showAddCard) {
      return(
        <WhiteWrapper>
          {this.props.split ? <AddCard split toggleAddCard={this.toggleAddCard} togglePaymentTypes={this.props.togglePaymentTypes} getPaymentOptions={this.props.getPaymentOptions}/> : <AddCard toggleAddCard={this.toggleAddCard} togglePaymentTypes={this.props.togglePaymentTypes} getPaymentOptions={this.props.getPaymentOptions}/> }
        </WhiteWrapper>
      )
    }
  }

  toggleAddCard = () => {
    this.setState(prevState => {
      return {
        showAddCard: !prevState.showAddCard
      }
    })
  }

  showAddBank = () => {
    if(this.state.showAddBank) {
      return(
        <WhiteWrapper>
          <AddBank toggleAddBank={this.toggleAddBank} getPaymentOptions={this.props.getPaymentOptions} togglePaymentTypes={this.props.togglePaymentTypes}/>
        </WhiteWrapper>
      )
    }
  }

  showAddCash = () => {
    if(this.state.showAddCash) {
      return(
        <WhiteWrapper>
          <AddCash />
        </WhiteWrapper>
      )
    }
  }

  toggleAddBank = () => {
    this.setState(prevState => {
      return {
        showAddBank: !prevState.showAddBank
      }
    })
  }

  toggleAddCash = () => {
    this.setState(prevState => {
      return {
        showAddCash: !prevState.showAddCash
      }
    })
  }

  render() {
    return (
      <Background fixed>
      {this.showAddCard()}
      {this.showAddBank()}
      {this.showAddCash()}
        <HideShow show={!this.state.showAddCard && !this.state.showAddBank}>
          <MobileWrapper fixed>
            <Modal context="slct-pmt-type" close={this.props.togglePaymentTypes} title="Select Payment Type">
              <IconTextWrapper data-test-id="slct-pmt-credit-debit" onClick={this.toggleAddCard}>
                <img src={credit_card} className="icon" alt="credit card icon"/>
                <div className="text">Credit/Debit</div>
              </IconTextWrapper>
              {this.showBank()}
              {this.showCash()}
            </Modal>
          </MobileWrapper>
        </HideShow>
      </Background>
    )
  }
}
