import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

//Assets
import { ModalContent } from '../generalModalStyling';
import addIcon from '../../../assets/icons/add_icon.png';

//Components
import Modal from '../NewModal';
import LineItems from '../../fields/LineItems';
import InputWrapper from '../../wrappers/InputWrapper';
import Label from '../../display/Label';
import globalUtils from '../../../utils/globalUtils';
import DateInput from '../../inputs/Date';
import Input from '../../inputs/ControlledInput';
import CurrencyInput from '../../inputs/CurrencyInput';

//Redux
import { setNotification } from '../../../js/actions/notifications';

//API
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';

const statusOptions = [
	{ label: 'Upcoming', value:'upcoming'},
	{ label: 'Due', value:'due'},
	{ label: 'Paid', value:'paid'},
	{ label: 'Late', value:'late'},
];

const Icon = styled.img`
  position: absolute;
	top: 4px;
	left: ${props => props.left ? props.left : '124px'};
	width: 14px;
`;

// className 'no-border' and 'full-width-input' can be found in generalModalStyling.js

class EditInvoiceModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invoice_items: [],
			invoice_append_items: [],
			description: '',
			due_date: '',
			minimum_amount_due: ''
		}
		this.myRefs = {
			state: React.createRef(),
			due_date: React.createRef(),
			description: React.createRef()
		}
	}

	componentDidMount() {
		this.setState({
			state: statusOptions.filter( status => status.value === this.props.invoice.state),
			invoice_items: this.props.invoice.invoice_items,
			invoice_append_items: this.props.invoice.invoice_append_items,
			due_date: globalUtils.formatDate(this.props.invoice.due_date),
			minimum_amount_due: `${this.props.invoice.minimum_amount_due}`,
			description: this.props.invoice.description
		})
	}

	handleChange = (event) => {
		if (event.target) {
			const target = event.target;
			const value = target.type === 'checkbox' ? target.checked : target.value;
			const name = target.name;
			if(name === "minimum_amount_due") {
				this.setState({
					[name]: globalUtils.convertDollarsToCents(value)
				})
			} else {
				this.setState({
					[name]: value
				});
			}
		} else {
			this.setState({
					user: event.value,
					merchant_id: event.value
			})
		}
	};

	handleStatusChange = (event) => {
		this.setState({
			state: event.value
		})
	};

	handleLineItemsChange = (event, index) => {
		if (event.target) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      let newItems = this.state.invoice_items.slice();
      newItems[index][name] = value;
      this.setState({
        invoice_items: newItems
      });
		}
	};

	handleAppendsChange = (event, index) => {
		if (event.target) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      let newAppends = this.state.invoice_append_items.slice();
      newAppends[index][name] = value;

      this.setState({
        invoice_append_items: newAppends
      });
		}
	};

	addLineItem = () => {
    let newItems = this.state.invoice_items.slice();
    newItems.push({description: '', amount: null});
    this.setState({
      invoice_items: newItems,
    })
	}

	removeLineItem = (index) => {
    let rmvItems = this.state.invoice_items.slice();
    rmvItems.splice(index, 1);
    this.setState({
      invoice_items: rmvItems,
    })
	}

	deleteItem = async (id,type) => {
		if(type === 'line-items') {
			let deleted_item = await userInvoiceAPI.deleteLineItem(id, this.props.auth_token);
			if (deleted_item.err && deleted_item.err.response) {
				let errorText = String(deleted_item.err.response.data.errors);
				this.setState({errors: errorText});
				return;
			} else if (deleted_item.err) {
				let errorText = String(deleted_item.err);
				this.setState({errors: errorText});
				return;
			} else {
				let updatedItems = this.state.invoice_items.filter(item => item.id !== id);
				this.setState({invoice_items: updatedItems})
			}
		} else if(type === 'appends') {
			let deleted_append = await userInvoiceAPI.deleteAppend(id, this.props.auth_token);
			if (deleted_append.err && deleted_append.err.response) {
				let errorText = String(deleted_append.err.response.data.errors);
				this.setState({errors: errorText});
				return;
			} else if (deleted_append.err) {
				let errorText = String(deleted_append.err);
				this.setState({errors: errorText});
				return;
			} else {
				let updatedAppends = this.state.invoice_append_items.filter(item => item.id !== id);
				this.setState({invoice_append_items: updatedAppends})
			}
		}
	}

	addAppend = () => {
		let newAppends = this.state.invoice_append_items.slice();
    newAppends.push({description: '', amount: null});
    this.setState({
			invoice_append_items: newAppends,
    })
	}

	removeAppend = (index) => {
		let rmvAppends = this.state.invoice_append_items.slice();
		rmvAppends.splice(index, 1);
		this.setState({
			invoice_append_items: rmvAppends,
		})
	}

	updateInvoice = async () => {
		if (this.state.invoice_items.length > 0 || this.state.invoice_append_items.length > 0) {
			let bothFields = true;
			this.state.invoice_items.forEach( item => {
				if(item.amount) {
					item.amount = globalUtils.convertDollarsToCents(item.amount.toString());
				}

				if(!item.description || !item.amount) {
					bothFields = false;
				};
			});
			this.state.invoice_append_items.forEach( item => {
				if(item.amount) {
					item.amount = globalUtils.convertDollarsToCents(item.amount.toString());
				}
				if(!item.description || !item.amount ) {
					bothFields = false;
				};
			});

			if(!bothFields) {
				this.setState({errors: 'Please enter a description and amount.'})
				return;
			}
		}

		if (globalUtils.checkValues(this.myRefs) === false) {
			return;
		}

		if(!this.state.due_date) {
			this.setState({errors: "Please complete the required fields."})
			return
		}

		let newData = Object.assign({}, this.state);
		newData.state = newData.state[0].value
		newData.minimum_amount_due = globalUtils.convertDollarsToCents(newData.minimum_amount_due);
		let updatedInvoice = await userInvoiceAPI.updateInvoice(this.props.invoice.id, newData, this.props.auth_token);
		if (updatedInvoice.err && updatedInvoice.err.response) {
			let errorText = String(updatedInvoice.err.response.data.errors);
			this.setState({errors: errorText});
			return;
		} else if (updatedInvoice.err) {
			let errorText = String(updatedInvoice.err);
			this.setState({errors: errorText});
			return;
		} else {
			this.setState({success: true});
			this.props.getInvoiceDetail();
			this.props.onClose('showEditInvoiceModal');
			this.props.setNotification({message: 'Successfully updated!'});
		}
	}


	render() {
		// const currentStatus = statusOptions.filter( status => status.value === this.props.invoice.state);
		const { description, minimum_amount_due } = this.props.invoice;

		return (
			<Modal context="edit-invoice" title="Edit Invoice" modalname="showEditInvoiceModal" onClose={this.props.onClose} errors={this.state.errors} submit={this.updateInvoice}>
				<ModalContent>
					{/*<InputWrapper>
						<Label label="Status"/>
						<Typeahead options={statusOptions}
										placeholder="Choose Status"
										name="state"
										onChange={this.handleStatusChange}
										defaultValue={[{label: currentStatus[0].label, value: this.props.invoice.state}]}
										invoice_status
										required
										ref={this.myRefs.state}
										/>
					</InputWrapper>*/}
					<InputWrapper>
						<Label label="Line Items"/>
						<Icon src={addIcon} alt="add item" onClick={this.addLineItem}/>
							<LineItems
										onChange={this.handleLineItemsChange}
										addItem={this.addLineItem}
										items={this.state.invoice_items}
										removeItem={this.removeLineItem}
										type={'line-items'}
										deleteItem={this.deleteItem}
							/>
					</InputWrapper>
					<InputWrapper>
						<Label label="Taxes / Fees" optional/>
						<Icon src={addIcon} alt="add item" onClick={this.addAppend} left="137px"/>
							<LineItems
										onChange={this.handleAppendsChange}
										addItem={this.addAppend}
										items={this.state.invoice_append_items}
										removeItem={this.removeAppend}
										type={'appends'}
										deleteItem={this.deleteItem}
							/>
					</InputWrapper>
					<InputWrapper>
						<Label label="Minimum Due" optional/>
						<CurrencyInput className="no-border full-width-input"
										placeholder="Enter Minimum Due"
										name="minimum_amount_due"
										onChange={this.handleChange}
										defaultValue={globalUtils.convertToDollars(minimum_amount_due).toString()}
										// maxLength={12}
										ref={this.myRefs.minimum_amount_due}
						/>
					</InputWrapper>
					<InputWrapper>
							<Label label="Due Date"/>
							<DateInput className="no-border full-width-input"
										name="due_date"
										onChange={this.handleChange}
										defaultValue={this.state.due_date}
										required
										ref={this.myRefs.due_date}/>
					</InputWrapper>
					<InputWrapper>
					<Label label="Description" optional/>
					<Input className="no-border full-width-input"
									placeholder="Enter Description"
									name="description"
									onChange={this.handleChange}
									ref={this.myRefs.description}
									value={this.state.description}
									defaultValue={description}/>
			</InputWrapper>
				</ModalContent>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		auth_token: state.auth.auth_token
	}
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInvoiceModal);
