import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";

//Assets
import { ErrorContainer } from '../../../components/mobile/MobileStyles';
import merchant_icon from '../../../assets/icons/merchant_icon_gradient.png';

//Components
import Breadcrumb from '../../../components/mobile/display/MobileBreadcrumb';
import ErrorHandle from '../../../components/function/ErrorHandle';
import ToggleSwitch from '../../../components/inputs/ToggleSwitch';
import Modal from '../../../components/mobile/NewMobileModal';
import Footer from '../../../components/mobile/Footer';

//Redux
import { setNotification } from '../../../js/actions/notifications';

//API & Utils
import userAPI from '../../../api/customer/userAPI';
import history from '../../../history';

const BreadcrumbWrapper = styled.div`
  position: absolute;
  top: 70px;
`;

const Container = styled.div`
  background-color: #F1F4F7;
  height: 100vh;
  width: 100%;
  .content-wrapper {
    padding: 23px;
    height: 100%;
    .white-box {
      margin-top: 20px;
      height: 275px;
      width: 340px;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 #ADADAD;
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 only */
        width: 280px;
        height: 240px;
      }  
      .header-container {
        display: flex;
        .icon {
          height: 30px;
          width: auto;
        }
        .header {
          font-size: 18px;
          color: #292929;
          margin-bottom: 30px;
          margin-left: 8px;
          margin-top: 6px;
        }
      }
      .sub-text {
        font-size: 14px;
        color: gray;
        margin-top: 34px;
        margin: 0 auto;
        width: 90%;
        margin-bottom: 40px;
      }
      .inner-content {
        .line-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .text {
            font-size: 13px;
            line-height: 1.7;
          }
        }
      }
    }
  }
`;

class MerchantDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      merchant: {
        merchant_name: undefined,
        email_opt_in: undefined,
        sms_opt_in: undefined
      },
      showSmsOptOut: false
    };

    this.sub_id = this.props.match.params.id;
  }

  componentDidMount = () => {
    this.getMerchantDetail();
  }

  getMerchantDetail = async () => {
    let merchant = await userAPI.get(this.sub_id, this.props.auth_token, this.props.user_pin);
    this.setState({ merchant }, () => {
      if (merchant.sms_opt_in) {
        this.setState({ sms_opt_in: true })
      } else {
        this.setState({ sms_opt_in: false })
      }
      if (merchant.email_opt_in) {
        this.setState({ email_opt_in: true })
      } else {
        this.setState({ email_opt_in: false })
      }
      if (merchant.marketing_opt_in) {
        this.setState({ marketing_opt_in: true })
      } else {
        this.setState({ marketing_opt_in: false })
      }
    });
  }

  onChange = (event) => {
    if (event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({ [name]: value});
    }
  }

  toggleAlert = (modal, event) => {
    if (modal === 'sms' && this.state.sms_opt_in) {
      this.setState({showSmsOptOut: true})
    }
    if (modal === 'sms' && !this.state.sms_opt_in) {
      this.setState({showSmsOptIn: true})
    }
    if (modal === 'email' && this.state.email_opt_in) {
      this.setState({showEmailOptOut: true})
    }
    if (modal === 'email' && !this.state.email_opt_in) {
      this.setState({showEmailOptIn: true})
    }
    if (modal === 'marketing' && this.state.marketing_opt_in) {
      this.setState({showMarketingOptOut: true})
    }
    if (modal === 'marketing' && !this.state.marketing_opt_in) {
      this.setState({showMarketingOptIn: true})
    }
    this.onChange(event)
  }

  closeModal = (modal) => {
    if (modal === 'sms_opt_out') {
      this.setState({ showSmsOptOut: false})
    }
    if (modal === 'sms_opt_in') {
      this.setState({ showSmsOptIn: false})
    }
    if (modal === 'email_opt_out') {
      this.setState({ showEmailOptOut: false})
    }
    if (modal === 'email_opt_in') {
      this.setState({ showEmailOptIn: false})
    }
    if (modal === 'marketing_opt_out') {
      this.setState({ showMarketingOptOut: false})
    }
    if (modal === 'marketing_opt_in') {
      this.setState({ showMarketingOptIn: false})
    }
    this.getMerchantDetail();
  }


  showSmsModal = () => {
    if (this.state.showSmsOptOut) {
      return (
        <Modal context="mct-detail-1" submit={this.updatePreference} modalname="sms_opt_out" onClose={this.closeModal} alert header="Opt-out?" text="You will no longer receive your bills through text messaging. Are you sure?"/>
      )
    }
    if (this.state.showSmsOptIn) {
      return (
        <Modal context="mct-detail-2" submit={this.updatePreference} modalname="sms_opt_in" onClose={this.closeModal} alert header="Opt-in?" text={`I agree to be contacted by ${this.state.merchant.merchant_name} via text message and/or phone call regarding services provided by ${this.state.merchant.merchant_name}. I understand that I can revoke this permission at any time by contacting ${this.state.merchant.merchant_name}.`}/>
      )
    }
  }

  showEmailModal = () => {
    if (this.state.showEmailOptOut) {
      return (
        <Modal context="mct-detail-3" submit={this.updatePreference} modalname="email_opt_out" onClose={this.closeModal} alert header="Opt-out?" text="You will no longer receive your bills through email. Are you sure?"/>
      )
    }
    if (this.state.showEmailOptIn) {
      return (
        <Modal context="mct-detail-4" submit={this.updatePreference} modalname="email_opt_in" onClose={this.closeModal} alert header="Opt-in?" text={`I agree to be contacted by ${this.state.merchant.merchant_name} via email regarding services provided by ${this.state.merchant.merchant_name}. I understand that I can revoke this permission at any time by contacting ${this.state.merchant.merchant_name}.`}/>
      )
    }
  }

  showMarketingModal = () => {
    if (this.state.showMarketingOptOut) {
      return (
        <Modal context="mct-detail-5" submit={this.updatePreference} modalname="marketing_opt_out" onClose={this.closeModal} alert header="Opt-out?" text="You will no longer receive marketing messages through text and email. Are you sure?"/>
      )
    }
    if (this.state.showMarketingOptIn) {
      return (
        <Modal context="mct-detail-6" submit={this.updatePreference} modalname="marketing_opt_in" onClose={this.closeModal} alert header="Opt-in?" text={`I agree to be contacted by ${this.state.merchant.merchant_name} via email and text regarding marketing offers provided by ${this.state.merchant.merchant_name}. I understand that I can revoke this permission at any time by contacting ${this.state.merchant.merchant_name}.`}/>
      )
    }
  }

  updatePreference = async () => {
    let data = {
      sms_opt_in: this.state.sms_opt_in ? 1 : 0,
      email_opt_in: this.state.email_opt_in ? 1 : 0,
      marketing_opt_in: this.state.marketing_opt_in ? 1 : 0
    }

    let response = await userAPI.update_notifications(this.sub_id, data, this.props.auth_token, this.props.user_pin);
    if (response.err) {
      this.setState({ errors: 'Error: ' + response.err.response.data.detail });
    } else {
      this.setState({ showSmsOptOut: false, showSmsOptIn: false, showEmailOptIn: false, showEmailOptOut: false, showMarketingOptIn: false, showMarketingOptOut: false});
      this.props.setNotification({message: 'Successfully updated', mobile: true})    
    }
  }

  render() {
    return (
      <Container>
        {this.showSmsModal()}
        {this.showEmailModal()}
        {this.showMarketingModal()}
        <BreadcrumbWrapper>
          <Breadcrumb close={history.goBack} page="Opt-In Preferences"/>
        </BreadcrumbWrapper>
        <ErrorContainer>
          <ErrorHandle data={this.state.errors}/>
        </ErrorContainer>
        <div className="content-wrapper">
          <div className="white-box">
            <div className="header-container">
              <img src={merchant_icon} className="icon" alt="merchant"/>
              <div className="header">{this.state.merchant.merchant_name}</div>
            </div>
            <div className="inner-content">
              <div className="line-item">
                <div className="text">Billing through text messaging</div>
                <ToggleSwitch name="sms_opt_in" checked={this.state.sms_opt_in} onChange={this.onChange} data-test-id="merchant-detail-sms-opt-in-3" onClick={(modal, event) => this.toggleAlert('sms', event)}/>
              </div>
              <div className="line-item">
                <div className="text">Billing through email</div>
                <ToggleSwitch name="email_opt_in" checked={this.state.email_opt_in} onChange={this.onChange} data-test-id="merchant-detail-email-opt-in-3" onClick={(modal, event) => this.toggleAlert('email', event)}/>
              </div>
              <div className="line-item">
                <div className="text">Marketing through text and email</div>
                <ToggleSwitch name="marketing_opt_in" checked={this.state.marketing_opt_in} onChange={this.onChange} data-test-id="merchant-detail-marketing-opt-in-3" onClick={(modal, event) => this.toggleAlert('marketing', event)}/>
              </div>
            </div>
          </div>
        </div>
        <Footer text="Done"/>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    merchant_logo: state.merchants.merchant_logo,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id,
    user_info: state.auth.user_info
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantDetail)
