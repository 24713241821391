import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//API & Utils
import refundAPI from '../../../api/refunds/refundAPI';
// import paymentAPI from '../../../api/payments/paymentAPIs';
import globalUtils from '../../../utils/globalUtils';

//Redux
import { setNotification } from '../../../js/actions/notifications';

//Components
import CurrencyInput from '../../inputs/CurrencyInput';
import ActionButton from '../../buttons/ActionButton';
import HideShow from '../../function/HideShow';
import ErrorHandle from '../../function/ErrorHandle';

const ErrorContainer = styled.div`
  text-align: center;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(59,59,59,.5);
  position: fixed;
  z-index: 9;
  left: 233px;
  top: 0px;
`

const Modal = styled.div`
  height: auto;
  width: 465px;
  background-color: #F1F4F7;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -350px;
  border-radius: 10px;
  .modal-title {
    text-align: center;
    color: #646464;
    font-size: 33px;
    padding: 10px 0px;
    background-color: #fff;
    border-radius: 10px;
  }
  .modal-content {
    background-color: #F1F4F7;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
  }
  .modal-input-container {
    display: flex;
    justify-content: center;
    width: 40%;
    margin: 0 auto;
    ::-webkit-input-placeholder {
      text-align: center;
    }
  }
  .text-container {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .text {
    font-size: 20px;
    color: #636363;
    padding-top: 15px;
  }
  .warning-text {
    display: flex;
    justify-content: center;
    color: #FF6162;
    font-size: 16px;
    margin-top: 20px;
  }
  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;
  }
`
// Response Codes: A - Approved, B - Retry, C - Declined

// let sampleVoidRequest = {
// 	"merchid": "123456789012",
// 	"amount": "0.01",
// 	"currency": "USD",
// 	"retref": "227082251218",
// 	"authcode": "REVERS",
// 	"respcode": "00",
// 	"respproc": "FNOR",
//     "respstat": "A",
//     "resptext": "Approval", 
// }  

// let sampleRefundRequest = {
//   "amount": "59.60",
//   "resptext": "Approval",
//   "authcode": "REFUND",
//   "respcode": "00",
//   "retref": "288010185242",
//   "merchid": "000000927996",
//   "cvvresp": "M",
//   "account": "41XXXXXXXXXX4113",
//   "avsresp": "Z",
//   "respproc": "PPS",
//   "respstat": "A"
// }

class RefundModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      showError: false,
      amount: globalUtils.convertToDollars(this.props.refund.amount_paid)
    };
  }

  // componentDidMount() {
  //   this.getTransactionStatus();
  // }

//   getTransactionStatus = async () => {
//     let status = await paymentAPI.getStatus(this.props.refund.id, this.props.auth_token);
//     if (status.err && status.err.response) {
//       let errorText = String(status.err.response.data.detail);
//       this.props.setNotification({message: errorText, type: 'error'});
//       this.props.onClose(this.props.modalname)
//       return;
//     } else if (status.err) {
//       let errorText = String(status.err);
//       this.setNotification({message: errorText, type: 'error'})
//       this.props.onClose(this.props.modalname)
//       return;
//     } else {
//       if(status.settlement_status !== "Accepted") {
//         this.props.setNotification({message: 'This transaction has not been processed, please cancel it instead.', type: 'error'})
//         this.props.onClose(this.props.modalname)
//       }
//     }
//   }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if ( parseFloat(value.substring(1)).toFixed(2) > parseFloat(globalUtils.convertToDollars(this.props.refund.amount_paid).substring(1)).toFixed(2) ) {
      this.setState({[name]: value, showError: true});
    } else {
      this.setState({[name]: value, showError: false});
    }
  };

  currencyConversion = () => {
    if(this.state.amount) {
      let cents = globalUtils.convertDollarsToCents(this.state.amount);
      let dollars = globalUtils.convertToDollars(cents);
      return dollars;
    }
  }

  refundPayment = async () => {
    // 0 = Not_Known, 1 = Authorized, 2 = Declined, 3 = Voided, 4 = Error
    // Status, amount, processor_reference_id
    if (!this.state.amount) {
      this.setState({ errors: 'Please enter amount to refund.'})
      return;
    }
    if(this.state.showError) {
      return;
    }
    let refund = await refundAPI.refundPayment({transaction: this.props.refund.id, amount: globalUtils.convertDollarsToCents(this.state.amount.substring(1))}, this.props.auth_token)
    if (refund.err && refund.err.response) {
      this.setState({errors: refund.err.response.data.detail})
      return
    } else if(refund.errors) {
      this.setState({errors: refund.errors});
      return;
    } else {
      let { status, amount } = refund;
      if(status === 1) {
        this.props.onClose(this.props.modalname);
        this.props.getInvoiceDetail();
        this.setNotification({message: `${globalUtils.convertToDollars(amount)} successfully refunded`});
        return;
      } else if(status === 3) {
        this.props.onClose(this.props.modalname);
        this.setNotification({message: 'This transaction has been voided and cannot be refunded', type: 'error'})
        return;
      } else {
        this.props.onClose(this.props.modalname);
        this.props.setNotification({message: 'Unable to issue refund at this time', type: 'error'});
        return;
      }
    }
  }

  render() {
    let { onClose, modalname, refund } = this.props;
    return (
      <Container>
        <Modal context="refund">
          <div className="modal-title">Issue Refund</div>
          <div className="modal-content">
            <div className="modal-input-container">
              <CurrencyInput name="amount" placeholder={globalUtils.convertToDollars(this.props.refund.amount_paid)} className="modal-input" onChange={this.handleChange} style={{textAlign: 'center', height: '40px', fontSize: '25px'}}/>
            </div>
            <div className="text-container">
              <span className="text">Refund {this.currencyConversion()} to {refund.visible_method}</span>
            </div>
            <HideShow show={this.state.showError}>
              <span className="warning-text">You cannot refund more than the total transaction amount.</span>
            </HideShow>
            <ErrorContainer>
              <ErrorHandle data={this.state.errors}/>
            </ErrorContainer>
            <div className="button-wrapper">
              <ActionButton primary onClick={ () => onClose(modalname)}>Cancel</ActionButton>
              <ActionButton secondary onClick={this.refundPayment}>Refund</ActionButton>
            </div>
          </div>
        </Modal>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundModal);
