import React, {Component} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

//Components
import ModalButtonCombo from '../buttons/ModalButtonCombo';
import ErrorHandle from '../function/ErrorHandle';
import HideShow from '../function/HideShow';

//Assets
import close_icon from '../../assets/icons/close_icon_white.png';
import scroll from '../../assets/icons/scroll.png';
import ModalErrorWrapper from './styling/ModalErrorWrapper';
import {fadeIn, fadeOut} from '../../styles/animations';

const Container = styled.div`
  .modal {
    z-index: 999;
    height: 100%;
    width: 100%;
    background-color: rgba(59,59,59,.6);
    position: fixed;
    left: 233px;
    top: 0px;
    .css-10nd86i {
      width: 290px;
    }
    @media (max-height: 700px){
      font-size: 14px;
    }
  }
  .open {
    animation:  ${fadeIn} .5s;
    animation-fill-mode: forwards;
  }
  .close {
    animation:  ${fadeOut} .5s;
    animation-fill-mode: forwards;
  }

`

const Modal = styled.div`
  /* height: auto; */
  width: 350px;
  background-color: #F1F4F7;
  position: absolute;
  top: 22%;
  @media (max-height: 700px){
    top: 28%;
  }
  left: 50%;
  margin: -100px 0 0 -350px;
  border-radius: 10px;
  .image-wrapper {
    position: relative;
    img {
      position: absolute;
      height: 20px;
      right: 6px;
      top: 6px;
      cursor: pointer;
    }
  }
  .modal-title {
    /* position: absolute */
    text-align: center;
    color: #fff;
    font-size: 25px;
    padding: 10px 0px;
    background-color: #64ABF5;
    border-top-right-radius: 10px;
    height: 60px;
    line-height: 2;
    border-top-left-radius: 10px;
  }
  .modal-content {
    max-height: 590px;
    @media (max-height: 700px){
      max-height: 450px;
    }
    overflow: auto;
    overflow-X: hidden;
      ::-webkit-scrollbar {
      width: 0;
    }
    background-color: #fff;
    height: auto;
    width: 100%;
    border-radius: 10px;
  }
  .errors {
    padding: 40px 0 0 4px;
  }
  .tcpa {
    text-align: center;
    padding: 20px;
  }
  .modal-input-container {
    display: flex;
    justify-content: ${props => props.context === "autopay" ? 'center' : 'flex-start'};
    padding: ${props => props.context === "autopay" ? '0px' : '20px 0 0 4px'};
    margin: 0 auto;
  }
  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;
  }
`;

const ScrollArrow = styled.img`
  height: 25px;
  width: auto;
  position: absolute;
  bottom: 15px;
  right: 5px;
  transition: .2s ease-in-out;
  :hover {
    transform: translateY(2px)
  }
`;

export default class NewModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      showError: false,
      atBottom: false,
      modalContent: null,
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({mounted: true, modalContent: document.getElementsByClassName('modal-content')[0]});
  }

  componentWillUnmount() {
    this.setState({mounted: false})
  }

  renderArrow = () => {
    let { modalContent, atBottom } = this.state;
    if(modalContent) {
      if(modalContent.scrollHeight > modalContent.clientHeight) {
        if(!atBottom) {
          return <ScrollArrow src={scroll} onClick={this.scrollToBottom}/>
        }
      }
    }
  }

  handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if(bottom) {
      this.setState({atBottom: true})
    } else {
      this.setState({atBottom: false})
    }
  }

  scrollToBottom = () => {
    let bottom = document.getElementById('endOfModal');
    bottom.scrollIntoView();
  }

  handleClose = () => {
    this.setState({mounted: false})
    setTimeout( () => {
      this.props.onClose(this.props.modalname)
    }, 500)
  }

  render() {
    return (
      <Container>
        <div className={classNames("modal", {"open": this.state.mounted}, {"close": !this.state.mounted})}>
          <Modal context="new-modal" {...this.props}>
            <div className="image-wrapper">
              <img src={close_icon} onClick={this.handleClose} alt="close"/>
            </div>
            <div className="modal-title">{this.props.title}</div>
            <ModalErrorWrapper>
              <ErrorHandle data={this.props.errors}/>
            </ModalErrorWrapper>
            <div className="modal-content" onScroll={ this.handleScroll }>
              <div className={classNames("modal-input-container ", {"errors": this.props.errors}, {"tcpa": this.props.tcpa})}>
                {this.props.children || this.props.text}
              </div>
              <HideShow show={!this.props.selectCard}>
                <ModalButtonCombo startPrequal={this.props.support} id="endOfModal" noMargin={this.props.noMargin} yesNo={this.props.yesNo ? true : null} onClose={this.handleClose} submit={this.props.submit} hide_cancel={this.props.hide_cancel} show_text={this.props.show_text} text_click={this.props.text_click} processing={this.props.processing}/>
              </HideShow>
              { this.renderArrow() }
            </div>
          </Modal>
        </div>
      </Container>
    )
  }
}
