import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'merchants';
class merchantAPI {

  getReports = (auth_token, range) => {
    if (!range) {
      range = '30'
    }
    var finalUrl = url+'/reports?default_report_range='+range;
    return apiUtils.get(finalUrl, auth_token)
  };

  getSelf = (auth_token) => {
    var finalUrl = url+'/get_self'
    return apiUtils.get(finalUrl, auth_token, "")
  };

  getApplications = () => {
    let merchantUrl = apiUtils.baseUrl + "applications";
    return apiUtils.get(merchantUrl)
  }

  getApplicationDetail = (id) => {
    let detailUrl = apiUtils.baseUrl + "applications/" + id;
    return apiUtils.get(detailUrl)
  }

  createApplication = (data) => {
    let detailUrl = apiUtils.baseUrl + "applications"
    return apiUtils.post(detailUrl, data)
  }

  updateApplication = (data, id) => {
    let detailUrl = apiUtils.baseUrl + "applications/" + id;
    return apiUtils.patch(detailUrl, data)
  }

  getAchCreds = (auth_token, invoice_id) => {
    return apiUtils.get(url + '/get_ach_creds?invoice_id=' + invoice_id, auth_token)
  }

  // checkBluePayCreds = (id) => {
  //   let bluepay = apiUtils.baseUrl + "/merchants/" + id + '/get_bluepay_creds';
  //   return apiUtils.get(bluepay)
  // }
}

export default new merchantAPI();
