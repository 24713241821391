import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loading from '../../components/display/Loading'

const Table = styled.div`
  height: auto;
  width: ${props => props.width ? props.width : '100%'};
  position: relative;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  position: relative;
  width: 100%;
`

const StyledSpinner = styled(Loading)`
  background: none;
  position: fixed;
  left: calc(50% + 110px);
  top: 33%;
  transform: translate(-50%, -50%);
  width: auto;
`

const FadeIn = styled.div`
  opacity: ${({loaded}) => loaded ? 1 : 0 };
  transition: opacity 222ms ease-in;
`

class TableWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      showCustomersTable: true,
      showGroupsTable: false
    };
  }

  componentDidMount = () => {

  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: "none",
          }
        }
      },
      typography: {
        useNextVariants: true,
      }
  });

  render() {
    return (
      <React.Fragment>
        <StyledSpinner loaded={this.props.loaded} />
        <Table {...this.props}>
          <HeaderWrapper>
          </HeaderWrapper>
          <FadeIn loaded={this.props.loaded}>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  title={this.props.title}
                  data={this.props.data}
                  columns={this.props.columns}
                  options={this.props.options}
                />
              </MuiThemeProvider>
          </FadeIn>
        </Table>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token
  };
};

export default connect(
  mapStateToProps
)(TableWrapper)
