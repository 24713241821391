import React, {Component} from 'react';
import styled from 'styled-components';
import Background from '../mobile/MobileBackground';
import Input from '../inputs/ControlledInput';
import classNames from 'classnames';

const Container = styled.div`
  .title-container {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 60px;
    @media (max-width: 320px) {
      margin-top: 10px;
    }
    @media (min-height: 736px) {
      margin-top: 100px;
    }
    .title {
      font-size: 28px;
      color: #fff;
      text-align: center;
      margin-bottom: 5px;
    }
    .sub-title {
      font-size: 18px;
      color: #fff;
      text-align: center;
      font-weight: 300;
      height: 80px;
    }
  }
  .pin-container {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    .pin-input {
      background-color: transparent;
      border: 1px solid rgba(255,255,255,.7);
      width: 22px;
      border-radius: 50%;
      margin-left: 10px;
      color: transparent;
      &.ios-only {
        @supports (-webkit-overflow-scrolling: touch) {
          width: 30px;
          color: transparent;
        }
      }
    }
    .backgroundOne {
      background-color: #5a9fde;
      border: none;
      color: #5a9fde;
    }
    .backgroundTwo {
      background-color: #5cb6d8;
      border: none;
      color: #5cb6d8;
    }
    .backgroundThree {
      background-color: #65d9cf;
      border: none;
      color: #65d9cf;
    }
    .backgroundFour {
      background-color: #70f3ca;
      border: none;
      color: #70f3ca;
    }
  }
  .number-pad {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px auto 0 auto;
    text-align: center;
    color: #fff;
    }
  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(3,100px);
    grid-template-rows: repeat(4,65px);
    font-size: 40px;
    @media (max-width: 320px) {
      grid-template-rows: repeat(4,50px);
    }
    .delete {
      position: relative;
      .x {
        position: absolute;
        bottom: 20px;
        right: 40px;
        font-size: 25px;
        line-height: .8;
        background-color: #01D3D8;
        height: 23px;
        width: 26px;
        margin-top: 13px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        @media (max-width: 320px) {
          bottom: 13px;
        }
      }
      .css-triangle {
        position: absolute;
        bottom: 20px;
        right: 66px;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 11px solid transparent;
        border-right: 14px solid #01D3D8;
        @media (max-width: 320px) {
          bottom: 13px;
        }
      }
    }
    .number {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default class VerifyCode extends Component {
  constructor(props){
    super(props);
    this.state = {
      verifyOne: '',
      verifyTwo: '',
      verifyThree: '',
      verifyFour: ''
    };
  }

  setValue = (num, event) => {
    if (!this.state.verifyOne && !this.state.verifyTwo && !this.state.verifyThree && !this.state.verifyFour) {
      this.setState({ verifyOne: num});
    }
    if (this.state.verifyOne && !this.state.verifyTwo && !this.state.verifyThree && !this.state.verifyFour) {
      this.setState({ verifyTwo: num})
    }
    if (this.state.verifyOne && this.state.verifyTwo && !this.state.verifyThree && !this.state.verifyFour) {
      this.setState({ verifyThree: num})
    }
    if (this.state.verifyOne && this.state.verifyTwo && this.state.verifyThree && !this.state.verifyFour) {
      this.setState({ verifyFour: num}, () => {
        let verifyPin = '';
        if (this.state.verifyOne && this.state.verifyTwo && this.state.verifyThree && this.state.verifyFour && this.props.pincode) {
          verifyPin = '' + this.state.verifyOne + this.state.verifyTwo + this.state.verifyThree + this.state.verifyFour;
        }
        if (this.props.pincode === verifyPin) {
          if(this.props.signup()) {
            this.props.onChange(event, 'success');
          }
        }
        if (this.props.pincode !== verifyPin) {
          this.props.onChange(event, 'error');
          this.setState({ verifyOne: undefined, verifyTwo: undefined, verifyThree: undefined, verifyFour: undefined});
          window.scrollTo(0,0)
        }
      })
    }
  }

  deleteValue = () => {
    if (this.state.verifyOne && this.state.verifyTwo && this.state.verifyThree && this.state.verifyFour) {
      this.setState({ verifyFour: undefined});
    }
    if (this.state.verifyOne && this.state.verifyTwo && this.state.verifyThree && !this.state.verifyFour) {
      this.setState({ verifyThree: undefined})
    }
    if (this.state.verifyOne && this.state.verifyTwo && !this.state.verifyThree && !this.state.verifyFour) {
      this.setState({ verifyTwo: undefined})
    }
    if (this.state.verifyOne && !this.state.verifyTwo && !this.state.verifyThree && !this.state.verifyFour) {
      this.setState({ verifyOne: undefined})
    }
  }

  render() {
    return (
      <Background>
        <Container>
          <div className="title-container">
            <div className="title" key="3">Confirm</div>
            <div className="sub-title" key="4">Verify your new pin</div>
          </div>
          <div className="pin-container">
            <Input className={classNames('pin-input ios-only', {backgroundOne: this.state.verifyOne})}
                  id="one"
                  type="text"
                  maxLength={1}
                  name="verifyOne"
                  value={this.state.verifyOne}
                  disabled/>
            <Input className={classNames('pin-input ios-only', {backgroundTwo: this.state.verifyTwo})}
                  id="two"
                  type="text"
                  maxLength={1}
                  name="verifyTwo"
                  value={this.state.verifyTwo}
                  disabled/>
            <Input className={classNames('pin-input ios-only', {backgroundThree: this.state.verifyThree})}
                  id="three"
                  type="text"
                  maxLength={1}
                  name="verifyThree"
                  value={this.state.verifyThree}
                  disabled/>
            <Input className={classNames('pin-input ios-only', {backgroundFour: this.state.verifyFour})}
                  id="four"
                  type="text"
                  maxLength={1}
                  name="verifyFour"
                  value={this.state.verifyFour}
                  disabled/>
          </div>
          <div className="number-pad">
            <div className="grid-wrapper">
              <div className="number" onClick={(num) => this.setValue(1)}>1</div>
              <div className="number" onClick={(num) => this.setValue(2)}>2</div>
              <div className="number" onClick={(num) => this.setValue(3)}>3</div>
              <div className="number" onClick={(num) => this.setValue(4)}>4</div>
              <div className="number" onClick={(num) => this.setValue(5)}>5</div>
              <div className="number" onClick={(num) => this.setValue(6)}>6</div>
              <div className="number" onClick={(num) => this.setValue(7)}>7</div>
              <div className="number" onClick={(num) => this.setValue(8)}>8</div>
              <div className="number" onClick={(num) => this.setValue(9)}>9</div>
              <div className="number"></div>
              <div className="number" onClick={(num) => this.setValue('0')}>0</div>
              <div className="delete" onClick={(num) => this.deleteValue()}>
                <div className="x">x</div>
                <div className="css-triangle"></div>
              </div>
            </div>
          </div>
        </Container>
      </Background>
    )
  }
}
