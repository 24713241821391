import React from 'react';
import styled from 'styled-components';

// Assets
import { color_orange_text, color_orange_bg } from '../../styles/colors';
import { hoverZoom } from '../../styles/mixins';

const TaggWrapper = styled.div`
    display: inline-block;
  .tag {
    cursor: pointer;
    height: 30px;
    max-width: 180px;
    margin: 0 5px 10px 5px;
    border-radius: 15px;
    background-color: ${color_orange_bg};
    /* box-shadow: 0 2px 3px 0 #ADADAD; */
    padding: 8px;
    transition: all .2s ease-out;
    font-size: 14px;
    color: ${color_orange_text};
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
        
    &>* {
      position: relative;
      bottom: 2px;
    }

    &__name {
      font-weight: 600;
      float: left;
      max-width: 142px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__close {
      font-weight: 200;
      float: right;
      margin-left: 12px;
    }

    ${hoverZoom};
  }
`;

function Tag(props) {

  let removeTag = () => {
    props.removeTag(props.id)
  }

  return(
    <TaggWrapper>
      <div className="tag">
        <div className="tag__name">{props.name}</div>
        <div className="tag__close" data-test-id={"tag-close-" + props.key} onClick={removeTag}>X</div>
      </div>
    </TaggWrapper>
  )
}

export default Tag;
