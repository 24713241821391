import React, {Component} from 'react';
import styled from 'styled-components';

const MiniCard = styled.div`
  height: 150px;
  width: 150px;
  position: relative;
  background-color: #E0E6F4;
  border-radius: 7px;
  box-sizing: border-box;
  @supports (-webkit-overflow-scrolling: touch) {
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 only */
      height: 150px;
      width: 130px;
    }
  }
`

export default class MiniCardWrapper extends Component {

  render() {
    return (
      <MiniCard {...this.props}>
        {this.props.children}
      </MiniCard>
    )
  }
}
