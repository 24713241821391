import React from 'react'
import styled from 'styled-components'

const Iframe = styled.iframe`
  max-width: 95%;
`
const CardConnectIframe = (props) => {
  let ccUrl = window.location.origin === 'https://app.blytzpay.com' ? "" : ":6443"

  let src = `https://fts.cardconnect.com${ccUrl}/itoke/ajax-tokenizer.html?formatinput=true&invalidinputevent=true&tokenizewheninactive=true&inactivityto=500&usecvv=true&fullmobilekeyboard=true&css=${props.iframeCss}`
  return (
    <React.Fragment>
      <Iframe title="updatedCardConnectIframe" id="tokenframe" src={src} frameBorder="0" scrolling="no" height="200" width="449" maxWidth="95%" autoComplete="off"></Iframe>
    </React.Fragment>
  )
}

export default CardConnectIframe;