import React, {Component} from 'react';
import styled from 'styled-components';
import Select from 'react-select'

import globalUtils from '../../utils/globalUtils';

const Container = styled.span`
  .css-1sontr1 {
    width: 290px;
  }
  .css-10nd86i {
    width: 315px;
  }
  .css-1aya2g8 {
    background-color: #fff;
    border-color: ${state => state.error ? '#FF4B4C' : '#9E9E9E'};
  }
  .custom-item {
    color: #82B4EB;
    text-decoration: underline;
    text-align: center;
  }
  .has-error {
    color: #FD7272;
    text-align: left;
    margin-top: 10px;
  }
  .Select-menu-outer {
    z-index: 99;
  }
`

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '1px solid #64ABF5' : 0,
    '&:hover': {
      border: state.isFocused ? '1px solid #64ABF5' : 0
    }
  })
}

export default class Typeahead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: {
        value: ''
      },
      isClearable: true,
      isSearchable: true,
      value: this.props.value
    };
  }

  displayOptions = () => {
    let merchantArray = [];
    let newArray = [];
    if (this.props.options && this.props.options.merchants) {
      let merchants = this.props.options.merchants;
      for (let i in merchants) {
        merchantArray.push({value: merchants[i].id, label: merchants[i].name})
      }
      return merchantArray;
    } if (this.props.options && this.props.custom) {
      let data = this.props.options;
      for (let i in data) {
        newArray.push({value: data[i].value, label: data[i].label})
      }
      return newArray;
    } else {
      let data = this.props.options;
      if (this.props.frequencies && this.props.options) {
        for (let i in data) {
          newArray.push({value: data[i].value, label: data[i].label})
        }
        return newArray;
      }

      if (this.props.payment_methods && this.props.options) {
        for (let i in data) {
          newArray.push({value: data[i].id, label: data[i].format_card, payment_method: true})
        }
        return newArray;
      }

      if (this.props.invoices && this.props.options) {
        for (let i in data) {
          let invoiceDescription;
          data[i].description ? invoiceDescription = data[i].description : invoiceDescription = 'No description';
          newArray.push({value: data[i].id, label: '#' + data[i].invoice_number + ' ' + invoiceDescription + ' ' + data[i].amount, invoice: true})
        }
        return newArray;
      }

      if (this.props.hosted_payment_invoices && this.props.options) {
        for (let i in data) {
          let invoiceDescription;
          data[i].description ? invoiceDescription = data[i].description : invoiceDescription = 'No description';
          newArray.push({value: data[i].id, label: invoiceDescription + ': Due: ' + globalUtils.convertToDollars(data[i].amount) + ' on ' + globalUtils.formatDate(data[i].due_date), invoice: true})
        }
        return newArray;
      }

      if (this.props.payment_plans && this.props.options) {
        for (let i in data) {
          newArray.push({value: data[i].id, label: data[i].description})
        }
        return newArray;
      }
      if(this.props.invoice_status && this.props.options) {
        for (let i in data) {
          newArray.push({value: data[i].value, label: data[i].label})
        }
        return newArray;
      }
      if((this.props.name === 'contact_Contact_Type__c' || this.props.name === 'contact_Contact_Title__c') && this.props.options) {
        for (let i in data) {
          newArray.push({value: data[i].value, label: data[i].label})
        }
        return newArray;
      }
      if(this.props.name === 'Type' && this.props.options) {
        for (let i in data) {
          newArray.push({value: data[i].value, label: data[i].label})
        }
        return newArray;
      }
      if(this.props.name === 'id' && this.props.options){
        for (let i in data) {
          newArray.push({value: data[i].id, label: data[i].account_name})
        }
        return newArray;
      }
      if(this.props.range_dropdown && this.props.options) {
        for (let i in data) {
          newArray.push({value: data[i].value, label: data[i].label})
        }
        return newArray;
      }
      if(this.props.name === 'user_id' && this.props.options) {
        for (let i in data) {
          newArray.push({value: data[i].user, label: data[i].full_name})
        }
        return newArray;
      }
      if(this.props.name === 'template' && this.props.options) {
        data.forEach( template => newArray.push({value: template.id, label: template.name}))
        return newArray;
      }

      for (let i in data) {
        newArray.push({value: data[i].id, label: data[i].full_name})
      }
      return newArray;
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption })
    this.props.onChange(selectedOption)
  }

  checkForErrors = (e) => {
    if (this.props.required && this.state.selectedOption && (this.state.selectedOption.value === '' && !this.props.overrideValue)) {
      this.setState({error: 'This field is required'})
    }
    if (this.props.required && this.state.selectedOption && this.state.selectedOption.value) {
      this.setState({error: ''})
    }
  }

  returnDefault = () => {
    if (this.props.defaultValue) {
      return this.props.defaultValue
    } else {
      return null
    }
  }

  returnValue = () => {
    if (this.props.value) {
      return this.props.value
    } else {
      return this.state.value
    }
  }


  render() {
    const { error } = this.state;
    return (
      <Container {...this.state}>
        <Select options={this.displayOptions()}
          components={this.props.components || null}
          className="modal-input basic-single"
          onChange={this.handleChange}
          value={this.returnValue()}
          isSearchable={this.props.unsearchable ? false : true}
          isDisabled={this.props.isDisabled ? true : false}
          name={this.props.name}
          placeholder={this.props.placeholder}
          defaultValue={this.returnDefault()}
          draggable="true"
          onBlur={(e) => this.checkForErrors(e)}
          styles={this.props.styles ? this.props.styles : selectStyles}
          data-test-id={this.props.name}
          />
          <div className="has-error">{error}</div>
      </Container>
    );
  }
}
