import React, {Component} from 'react';
import styled from 'styled-components';

const Button = styled.div`
  border-radius: 10px;
  width: 238px;
  text-shadow: 1px 1px #838383;
  height: 50px;
  background: ${props => props.secondary ? '#00D973' : '#3E63FD'};
  color: #fff;
  letter-spacing: 1px;
  font-weight: 900;
  text-align: center;
  line-height: 2;
  cursor: pointer;
  font-weight: 800;
  font-size: 24px;
  // @media (max-width: 580px) {
  //   width: ${props => props.full ? '85%' : '200px'};;
  //   font-size: 18px;
  // }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${props => props.invoice? "5px 0px" : "15px 0px"};
  @media(min-device-height: 668px) {
    margin-top: 2px;
    margin: ${props => props.invoice? "8px 0px" : null};
  }
  @media(min-device-height: 812px) {
    margin-top: 15px;
    margin: ${props => props.invoice? "15px 0px" : null};
  }
  @media(min-device-height: 896px) {
    margin-top: 15px;
    margin: ${props => props.invoice? "25px 0px" : null};
  }
`

export default class ButtonWrapperComponent extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <ButtonWrapper {...this.props}>
        <Button onClick={this.props.onClick} {...this.props}>{this.props.children}</Button>
      </ButtonWrapper>
    )
  }
}
