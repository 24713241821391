import React, {Component} from 'react';
import { connect } from "react-redux";
import styled  from 'styled-components';
import Dropzone from 'react-dropzone';

//API & Utils
import importAPI from '../../api/importing/importAPI';

//Assets
import upload_icon from '../../assets/icons/upload.png';
import success_icon from '../../assets/icons/check_circle.png';
import error_icon from '../../assets/icons/alert_icon.png';
import sample_invoice from '../../assets/files/sample_invoice_upload.csv';
import sample_customer from '../../assets/files/sample_customer_upload.csv';

//Components
import HideShow from '../function/HideShow';
import ActionButton from '../buttons/ActionButton';
import history from '../../history';
import Loading from '../display/Loading';
import Download from '../nav/Download';

const DropzoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  div {
    padding: 20px;
    width: 772px !important;
    height: 469px !important;
    border-width: 3px !important;
    border-color: #497ABC !important;
    background-color: #EFEFEF;
  }
`;

const List = styled.li`
  list-style-type: none;
  font-size: 12px;
  color: #5A5A5A;
`

const BlueText = styled.span`
  color: #83ABDC;
  cursor: pointer;
  font-weight: 700;
`

const Icon = styled.img`
  height: 135px;
  width: auto;
  position: absolute;
  left: 317px;
  top: 100px;
`

const MessageIcon = styled.img`
  height: 175px;
  width: auto;
`

const MessageContainer = styled.div`
  position: relative;
`

const MessageText = styled.div`
  font-size: 18px;
  color: #4599e5;
  margin-top: 20px;
  margin-bottom: 20px;
  & span {
    color: #64D600;
  }
`

const WarningText = styled.div`
  color: #FD7272;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 40px;
`

const ErrorText = styled.div`
  color: #FD7272;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 40px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin: 0 auto;
`

const DropzoneText = styled.p`
  font-size: 30px;
  width: 50%;
  word-break: break-word;
  text-align: center;
  padding-top: 250px;
  color: #747474;
  margin: 0 auto;
`

const DownloadContainer = styled.div`
  padding-top: 10px;
`

class DropZone extends Component {
  constructor(props){
    super(props);
    this.state = {
      file: [],
      showSuccess: false,
      showError: false,
      loading: false
    };
  }

  onDrop = async (file) => {
    let importedFile = '';
    this.setState({file: file[0]});
    const reader = new FileReader();
    reader.onload = async () => {
        const fileAsBinaryString = reader.result;
        // do whatever you want with the file content
        let base64Encoded = window.btoa(fileAsBinaryString);
        let data = {file: base64Encoded, file_type: file[0].type }
        if (this.state.file && this.props.customer) {
          this.setState({ loading: true })
          importedFile = await importAPI.importCustomers(data, this.props.auth_token);
          this.reviewResponse(importedFile);
        } else if (this.state.file && this.props.invoice) {
          this.setState({ loading: true })
          data.file_type = 'invoice import'
          importedFile = await importAPI.importInvoices(data, this.props.auth_token);
          this.reviewResponse(importedFile);
        } else if (this.state.file && this.props.payment) {
          this.setState({ loading: true})
          data.file_type = 'payment import'
          importedFile = await importAPI.importPayments(data, this.props.auth_token);
          this.reviewResponse(importedFile);
        }
        };
        reader.readAsBinaryString(file[0]);
  }

  reviewResponse = (importedFile) => {
    if (importedFile && !importedFile.err) {
      this.setState({
        showSuccess: true,
        successes: importedFile["successes"],
        updated: importedFile["updated"],
        failures: importedFile["failures"],
        warnings: importedFile["warnings"],
        loading: false
      });
    }
    if (importedFile && importedFile.err) {
      this.setState({loading:false, showError: true, error: String(importedFile.err.response.data.detail)})
      return
    }
  }

  renderFile = () => {
    if (this.state.file.length > 0) {
      return this.state.file.map(file => <List key={file.name}>Uploading {file.name} - {file.size} bytes</List>)
    }
  }

  renderSuccessMessage = () => {
    if (this.state.showSuccess) {
      let failure_text = ['']
      let i = this.state.failures.errors.length
      for (i in this.state.failures.errors) {
         failure_text.push(<ErrorText>{this.state.failures.errors[i]}</ErrorText>)
      }
      let warning_text = ['']
      if (this.state.warnings.warnings){
        let j = this.state.warnings.warnings.length
        for (j in this.state.warnings.warnings) {
           warning_text.push(<WarningText>{this.state.warnings.warnings[j]}</WarningText>)
        }
      }
      return (
        <MessageContainer>
          <MessageIcon src={success_icon}/>
          <MessageText>Your file was uploaded successfully!</MessageText>
          <MessageText>{this.state.successes} created</MessageText>
          <MessageText>{this.state.updated} updated</MessageText>
          <WarningText>{this.state.warnings.count} warnings</WarningText>
          <ErrorText>{this.state.failures.count} failed</ErrorText>
          {warning_text}
          {failure_text}
          <ButtonContainer>
            <ActionButton onClick={this.showDropzone} primary>Upload Another</ActionButton>
            <ActionButton onClick={this.done}>Done</ActionButton>
          </ButtonContainer>
        </MessageContainer>
      )
    }
  }

  renderErrorMessage = () => {
    if (this.state.showError) {
      return (
        <MessageContainer>
          <MessageIcon src={error_icon}/>
          <MessageText>The following issues were encountered with <span>{this.state.file.name}</span></MessageText>
          <ErrorText>{this.state.error}</ErrorText>
          <ButtonContainer>
            <ActionButton onClick={this.showDropzone} primary>Start Over</ActionButton>
            <ActionButton onClick={this.done}>Done</ActionButton>
          </ButtonContainer>
        </MessageContainer>
      )
    }
  }

  showDropzone = () => {
    // window.URL.revokeObjectURL(this.state.file.preview);
    this.setState({showSuccess: false, showError: false, file: []});
  }

  done = () => {
    if (this.props.invoice) {
      history.push('/admin/invoices');
    } else {
      history.push('/admin/customers');
    }
  }

  render() {
    return (
      <section {...this.props}>
        <HideShow show={!this.state.showSuccess && !this.state.showError && !this.state.loading}>
          <DropzoneWrapper>
            <Dropzone onDrop={this.onDrop.bind(this)}>
              <Icon src={upload_icon}/>
              <DropzoneText>DRAG CSV FILE HERE OR <BlueText>BROWSE</BlueText></DropzoneText>
            </Dropzone>
          </DropzoneWrapper>
          <DownloadContainer>
            <HideShow show={this.props.invoice}><Download file={sample_invoice}>Download invoice template</Download></HideShow>
            <HideShow show={this.props.customer}><Download file={sample_customer}>Download customer template</Download></HideShow>
          </DownloadContainer>
          <aside>
            <ul>
              {this.renderFile()}
            </ul>
          </aside>
        </HideShow>
        <HideShow show={this.state.showSuccess && !this.state.loading}>
          {this.renderSuccessMessage()}
        </HideShow>
        <HideShow show={this.state.loading}>
          <Loading/>
        </HideShow>
        <HideShow show={this.state.showError && !this.state.loading}>
          {this.renderErrorMessage()}
        </HideShow>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token
  };
};

export default connect(
  mapStateToProps
)(DropZone)
