import React, {Component} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  height: auto;
  max-height: 83vh;
  width: 95%;
  position: relative;
  margin-bottom: 40px;
  border-spacing: 0;
  margin: 0 auto;
`

const Title = styled.div`
  color: #83ABDC;
  font-size: 28px;
  text-align: left;
  font-weight: 500;
  padding-left: 40px;
  padding-top: 20px;
`

export default class WhiteContainer extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <Container {...this.props}>
        <Title>{this.props.title}</Title>
        {this.props.children}
      </Container>
    )
  }
}
