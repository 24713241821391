import React, {Component} from 'react';
import styled from 'styled-components';
import globalUtils from '../../utils/globalUtils';

// Assets 
import {hoverZoom} from '../../styles/mixins';

const ChartCard = styled.div`
  width: ${props => props.chartData ? '520px' : '250px'};
  height: ${props => props.chartData ? '330px' : 'auto'};
  max-height: 400px;
  padding: 15px;
  // border-radius: 5px;
  background-color: white;
  padding-top: 15px;
  box-shadow: 0 2px 5px 2px gainsboro;
  position: relative;
  transition: transform, .2s ease-out;
  ${hoverZoom}
`

const ChartTitle = styled.div`
  font-size: 18px;
  text-align: left;
  margin-left: 15px;
  font-weight: 300;
  color: #909090;
`

// const ChartHeader= styled.div`
//   font-size: 21px;
//   text-align: center;
//   margin-top: 20px;
//   font-weight: 200;
//   color: #909090;
//   margin-top: 10px;
// `

const CardData = styled.div`
  text-align: left;
  margin-left: 15px;
  font-size: 28px;
  margin-top: ${props => props.chartData ? '0px' : '10px'};
  color: #5cb6d8;
  font-weight: 500;
  word-wrap: break-word;
  max-width: 100%;
  line-height: 1em;
  max-height: 2em;
`

export default class ChartCardWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: this.props.data
    };
  }

  checkProps = () => {
    if (this.props.currency) {
      return (
        <CardData>{globalUtils.convertToDollars(this.state.data)}</CardData>
      )
    } else if (this.props.chartData) {
      return;
    } else {
      return (
        <CardData>{this.state.data}</CardData>
      )
    }
  }

  render() {
    return (
      <ChartCard {...this.props}>
        <ChartTitle>{this.props.title}</ChartTitle>
        {this.checkProps()}
        {this.props.children}
      </ChartCard>
    )
  }
}
