import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import history from './history';
import { unregister} from './registerServiceWorker';
import { persistor, store } from "./js/store/index";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'

// Creates a css variable that can be used to set the height of full page height components to work accurately for both web and mobile views with toolbars at the bottom
let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
document.getElementById('root')
);
unregister();
