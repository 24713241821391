import React from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons'

const CheckboxContainer = styled.div`
  position: relative;
  max-height: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
  max-width: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
`

const CheckBoxInput = styled.input`
  height: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
  width: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
  border-radius: 4px;
`

const CheckboxSkin = styled.div`
  position: absolute;
  top: -.5px;
  left: -.5px;
  background-color: white;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  height: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
  width: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const CheckedIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: -.5px;
  left: -.5px;
  color: white;
  background-color: #3BBFB7;
  font-size: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
  border-radius: 4px;
  border: 1px solid #DEDEDE;
  height: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
  width: ${props => props.checkboxsize ? props.checkboxsize : '30px'};
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const CustomCheckbox = (props) => {
  return (
    <CheckboxContainer checkboxsize={props.checkboxsize}>
      <CheckBoxInput checkboxsize={props.checkboxsize} type="checkbox" checked={props.checked} onChange={props.onCheck}/>
      {props.checked ? <CheckedIcon checkboxsize={props.checkboxsize} className="font-awe-icon" icon={faCheck}/> : <CheckboxSkin checkboxsize={props.checkboxsize} checked={props.checked}/>}
    </CheckboxContainer>
  )
}

export default CustomCheckbox;