import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import classNames from 'classnames';

//Assets
import { clearFix, blurOnLoad } from '../../../styles/mixins';
import { color_heading_grey1, color_heading_grey2, color_orange_text, color_nav_border, color_blue_main } from '../../../styles/colors';
import pencil_icon from '../../../assets/icons/pencil_icon_gray.png';
import phoneIcon from '../../../assets/icons/detail_phone.png';
import emailIcon from '../../../assets/icons/detail_email.png';
import homeIcon from '../../../assets/icons/detail_home.png';
import cakeIcon from '../../../assets/icons/cakeIcon.png';

//API & Utils
import paymentMethodAPI from '../../../api/blytzwallet/PaymentMethod'
import paymentAPI from '../../../api/payments/paymentAPIs';
import invoiceAPI from '../../../api/invoices/userInvoiceAPI';
import userAPI from '../../../api/customer/userAPI';
import messagesAPI from '../../../api/messages/messagesAPI';
import globalUtils from '../../../utils/globalUtils';
import history from '../../../history';
import tagsAPI from '../../../api/tags/tagsAPI'
import SmsOptInAPI from '../../../api/tcpa/SmsOptInAPI';


//Components
import Table from '../../../components/tables/Table';
import Tab from '../../../components/display/Tab';
import Initials from '../../../components/display/Initials';
import Tag from '../../../components/display/Tag';
import TagModal from '../../../components/modals/tags/TagModal';
import UpdateCustomerModal from '../../../components/modals/customers/UpdateCustomerModal';
import OptInInfo from '../../../components/alerts/DetailOptIn';
import ChatBox from '../messaging/ChatBox';
import PaymentsToolbar from './PaymentsToolbar';
import TakePaymentModal from '../../../components/modals/payments/TakePaymentModal';
import AutopayModal from '../../../components/modals/payments/AutopayModal';
import DeleteCustomerModal from '../../../components/modals/customers/DeleteCustomerModal';
import PaymentPlanModal from '../../../components/modals/invoices/PaymentPlanModal';
import ThreeDotButton from '../../../components/buttons/ThreeDotButton';

//Redux
import { setNotification } from '../../../js/actions/notifications';
import { setInvoiceCustomer, toggleTakePayment } from '../../../js/actions/modal_actions';
import { storeMerchantTags } from '../../../js/actions/merchants';


const CustomerDetailWrapper = styled.div`
  height: auto;
  /* height: calc(100vh - 64px); */

  .u-margin-bottom-small {margin-bottom: 10px}
  .u-margin-bottom-medium {margin-bottom: 20px}

  .background-white {
    background-color: #FFFFFF;
  }

  .section {
    height: 100%;
    min-height: 679px;
    float: left;
    transition: all 1.1s ease-out;

    ${clearFix};

    &:not(:last-child) {
      margin: 0 20px;
    }
  }

  ${blurOnLoad};

  .main {
    width: calc((((100vw - 233px) - 100px) / 3) * 2);
    /* overflow-y: scroll; */

    &__header {
      display: flex;

      &-heading {
        font-size: 25px;
        font-weight: 500;
        text-align: left;
        color: ${color_heading_grey2};
        position: relative;
        padding: 10px 10px 10px 0;
        margin-left: 20px;
      }

      &-nav {
        display: flex;
        width: 424px;
        height: 32px;
        padding-bottom: 10px;
        border-bottom: 2px solid ${color_nav_border};
        margin-left: 34px;
        margin-top: 14px;
      }
    }

    &__content {
      .communication-box {
        width: 96%;
        margin: 10px auto;
        border: 2px solid ${color_nav_border};
        border-radius: 7px;
      }
    }
  }

  .details {
    width: calc(((100vw - 233px) - 100px) / 3);
    padding: 15px;

    .edit-icon {
      height: 18px;
      width: auto;
      position: absolute;
      cursor: pointer;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: opacity .2s ease-out;
    }

    .visible {
      opacity: 1;
    }

    &-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 205px;
      padding: 10px;
      &__heading {
        color: ${color_heading_grey1};
        font-size: 30px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;
      }
    }

    &__section {
      height: auto;
      padding: 10px;

      &-heading {
        margin: 0 0 10px 0;
        font-size: 18px;
        color: ${color_heading_grey2};
        line-height: 22px;
        font-weight: 200;
      }

      &__section-tags {
        display: inline-block;
        margin: 0 auto;
        height: 300px;
      }

      .add-btn {
        display: inline-block;
        height: 30px;
        width: 30px;
        color: ${color_orange_text};
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        border: 2px solid ${color_orange_text};
        border-radius: 50%;
        position: relative;
        top: 1px;
        margin: 0 5px 10px 5px;
        cursor: pointer;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &__contact {

      &-line {
        color: #9095A0;
        font-size: 15px;
        line-height: 19px;
        display: block;
        /* width: 100%;
        color: #787878;
        font-size: 20px;
        padding: 0px 10px;
        overflow: hidden;
        width: 90%;
        text-overflow: ellipsis; */
      }

      &-icon {
        width: 11px;
        height: auto;
        margin-right: 5px;
      }
    }
  }

  .notes {
    width: 60%;
    padding-right: 100px;

    &__container {
      height: 220px;
      width: 100%;
      padding-left: 70px;
      padding: 15px;
      display: flex;
      & > * {
        width: 60
      }
    }

    &__heading {
      font-size: 24px;
      color: #787878;
      font-weight: 300;
      margin-bottom: 10px;
      text-align: left;
    }

    &__textarea {
      padding: 5px;
      height: 100px;
      width: 100%;
      resize: none;
      border: 1px solid #C7C7C7;
    }

    &__save-btn {
      width: 75px;
      border-radius: 10px;
      border-color: ${color_blue_main};
      color: ${color_blue_main};
      background-color: white;
      border: 1px solid ${color_blue_main};
      padding: 8px;
      text-align: center;
      cursor: pointer;

      &-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
  }

  .payment-methods {
    width: 40%;
    overflow: auto;
    text-align: left;

    &__method {
      width: 100%;
      color: #787878;
      font-size: 18px;
      font-weight: 300;
      padding-bottom: 5px;
    }
  }
`;

const invoiceColumns = [
  {
    name: "full_name",
    label: "User",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "amount",
    label: "Amount",
    options: {
      filter: false,
      sort: true,
    }
 },
  {
    name: "state",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      setCellProps: (row) => {
        return {
          style: {textTransform: 'capitalize'}
        };
      }
    }
  },
  {
    name: "due_date",
    label: "Due Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (tableMeta.rowData) {
          return (
            globalUtils.formatDate(value)
          )
        }
      }
    }
  },
  {
    name: "paid_date",
    label: "Paid Date",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (tableMeta.rowData) {
          return (
            globalUtils.formatDate(value)
          )
        }
      }
    }
  },
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display: false
    }
  }
]
const paymentColumns = [
  {
    name: "user",
    label: "User",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "amount_paid",
    label: "Amount Paid",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (tableMeta.rowData) {
          return (
            globalUtils.convertToDollars(value)
          )
        }
      },
    }
 },
  {
    name: "paid_date",
    label: "Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (tableMeta.rowData) {
          return (
            globalUtils.formatDate(value)
          )
        }
      },
    }
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: false,
      sort: true,
      setCellProps: (row) => {
        return {
          style: {textTransform: 'capitalize'}
        };
      }
    }
  },
  {
    name: "visible_method",
    label: "Payment Method",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "invoice",
    label: "Invoice",
    options: {
      filter: false,
      sort: false,
      display: false
    }
  }
]

const invoiceOptions = {
  filterType: 'dropdown',
  responsive: 'stacked',
  selectableRows: false,
  search: true,
  onRowClick: (rowData, rowState) => {
    let _id = rowData[5]
    history.push({
      pathname: '/admin/invoices/detail/' + _id,
      state: 'test'
    })
  }
}

const paymentOptions = {
  filterType: 'dropdown',
  responsive: 'stacked',
  selectableRows: false,
  search: true,
  onRowClick: (rowData, rowState) => {
    let _invoice = rowData[5]
    history.push({
      pathname: '/admin/invoices/detail/' + _invoice,
      state: 'test'
    })
  },
  customToolbar: () => {
    return(
      <PaymentsToolbar/>
    )
  }
}

class CustomerDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      current_tab: 'Conversation',
      modal: null,
      recurring_payment_errors: null,
      delete_errors: null,
      customer: {
        full_name: '',
        phone: '',
        service_address: '',
        service_address2: '',
        service_address_city: '',
        service_address_state: '',
        service_address_zip: '',
      },
      showTagModal: false,
      showButton: false,
      showEditIcon: false,
      medium: '',
      templates: [],
      loaded: false
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  refreshData = () => {
    this.getUserData();
    this.getMessageDetail();
    this.getInvoiceData();
    this.getTransactionData();
    this.getTemplateData();
    this.getMerchantTags()
    this.getPaymentMethodData()
  }

  getMerchantTags = async () => {
    let merchant_tags = await tagsAPI.getMerchantTags(this.props.auth_token, this.props.merchant);
    if (merchant_tags.err && merchant_tags.err.response) {
      this.setState({errors: merchant_tags.err.response.merchant_tags.detail})
      return
    }
    this.props.storeMerchantTags(merchant_tags)
  }

  getUserData = async () => {
    let customer = await userAPI.get(this.props.match.params.id, this.props.auth_token)
    if (customer.err && customer.err.response) {
      this.setState({errors: customer.err.response.customer.detail})
      return
    }
    this.props.setInvoiceCustomer(customer)

    let medium = '';
    let { sms_opt_in, email_opt_in } = customer;
    if(sms_opt_in && email_opt_in) {
      medium = 'sms';
    } else if(!sms_opt_in && !email_opt_in) {
      medium = 'none';
    } else if(sms_opt_in) {
      medium = 'sms';
    } else if(email_opt_in) {
      medium = 'email';
    }
    let autopay = {
      autopay_enabled: customer.autopay_enabled,
      autopay_amount: customer.autopay_amount,
      autopay_day: customer.autopay_day, 
      autopay_frequency: customer.autopay_frequency,
      autopay_method: customer.autopay_method
    }
    customer['autopay'] = autopay
    this.setState({
      customer: customer, medium
    })
  };

  getTemplateData = async () => {
    let templates = await messagesAPI.getTemplates(this.props.auth_token)
    if (templates.err && templates.err.response) {
      this.setState({errors: templates.err.response.data.detail})
      return
    }
    this.setState({templates, loaded: true});
  }

  setActiveTab = (e) => {
    this.setState({current_tab: e.target.getAttribute("name")}, this.refreshData)
  }

  getMessageDetail = async () => {
    const query_string = "?sub_id=" + this.props.match.params.id;
    const data = await messagesAPI.getChat(this.props.auth_token, query_string)
    if (data.err && data.err.response ) {
      this.setState({
        activeMessage: [],
        activeSub: data.err.response.data.subscription_id,
        errors: data.err.response.data.message
      })
    } else {
      this.setState({
        activeMessage: data.messages,
        activeSub: data.subscription_id
      })
    }
    
  }

  getInvoiceData = async () => {
    let query_string = "?user=" + this.props.match.params.id
    let formatted_data = [];
    let data = await invoiceAPI.getList("", this.props.auth_token, "", query_string)

    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }
    if (data && data.length > 0) {
      formatted_data = data.map(function(row){
        row["amount"] = globalUtils.convertToDollars(row["amount"]);
        return row
      })
    }

    this.setState({
      invoices: formatted_data
    })

    let unpaid_invoices = []
    formatted_data.forEach(function(invoice) {
      if (invoice.state !== "paid" && invoice.state !== "cancelled"){
        unpaid_invoices.push(invoice)
      }
    })

    this.setState({
      unpaid_invoices: unpaid_invoices
    })
  }

  getTransactionData = async () => {
    let query_string = "?user=" + this.props.match.params.id
    let formatted_data = [];
    let data = await paymentAPI.getList(this.props.auth_token, "", query_string)
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }

    if (data && data.length > 0) {
      formatted_data = data.map(function(row){
        row["date"] = globalUtils.formatDate(row["date"])
        row["amount"] = globalUtils.convertToDollars(row["amount"]);
        return row
      })
    }


    this.setState({
      payments: formatted_data
    })
  }

  toggleMedium = () => {
    let { medium, customer } = this.state,
        { sms_opt_in, email_opt_in } = customer;

    if(sms_opt_in && email_opt_in) {
      if(medium === 'sms') {
        medium = 'email';
      } else {
        medium = 'sms';
      }

      this.setState({medium});
    }
  }

  renderConversation = () => {
    if(this.state.current_tab === 'Conversation') {
      let { customer, medium, loaded, activeMessage, activeSub, templates } = this.state,
          { sms_opt_in, email_opt_in, is_business } = customer;
      return(
        <div className="communication-box">
          <ChatBox
                customer_detail
                message={activeMessage}
                loaded={loaded}
                toggleModal={this.toggleCloseChatModal}
                getData={this.getMessageDetail}
                activeSub={activeSub}
                auth_token={this.props.auth_token}
                sms_disabled={!sms_opt_in}
                email_disabled={!email_opt_in}
                medium={medium}
                toggleMedium={this.toggleMedium}
                is_business={is_business}
                templates={templates}
                context="new-detail"
                />
        </div>
      )
    }
  }

  updateCustomer = customer => {
    this.setState({customer})
  }

  renderInvoices = () => {
    if(this.state.current_tab === 'Invoices') {
      return(
        <div>
          <Table title=""
                data={this.state.invoices}
                tableClassName="table"
                rerouteToUpload={this.rerouteToUpload}
                loaded={this.state.loaded}
                columns={invoiceColumns}
                options={invoiceOptions}>
          </Table>
        </div>
      )
    }
  }

  renderPayments = () => {
    if(this.state.current_tab === 'Payments') {
      return(
        <div>
          <Table title=""
                data={this.state.payments}
                tableClassName="table"
                rerouteToUpload={this.rerouteToUpload}
                loaded={this.state.loaded}
                columns={paymentColumns}
                options={paymentOptions}>
          </Table>
        </div>
      )
    }
  }

  renderNotes = () => {
    if(this.state.current_tab === 'Notes') {
      return(
        <div className="notes__container">
          <div className="notes">
            {/* <h2 className="notes__heading">Internal Notes</h2> */}
            <textarea className="notes__textarea" placeholder="add comment" onClick={this.showButton} name="notes" onChange={this.handleChange} value={this.state.customer.notes} onBlur={this.onBlur}/>
            {this.renderButton()}
          </div>
          <div className="payment-methods">
          <h2 className="notes__heading">Payment Methods</h2>
            {this.renderPaymentMethods()}
          </div>
      </div>
      )
    }
  }

  showTagModal = () => {
    if(this.state.showTagModal) {
      return <TagModal toggle_modal={this.toggleTagModal} user_tags={this.state.customer.tags} remove_tag={this.removeTag} sub_id={this.props.match.params.id} refresh={this.refreshData}/>
    }
  }

  toggleTagModal = (e) => {
    this.setState( prevState => {
      return {
        showTagModal: !prevState.showTagModal
      }
    })
  }

  getPaymentMethodData = async () => {
    let query_string = "?user=" + this.props.match.params.id
    let payment_methods = await paymentMethodAPI.getList(this.props.auth_token, "", query_string)
    if (payment_methods.err && payment_methods.err.response) {
      this.setNotification({message: payment_methods.err.response.payment_methods.detail, type: 'error'})
      return
    } else {
      this.setState({ payment_methods: payment_methods });
    }
  };

  renderPaymentMethods = () => {
    let array = [];
    if (this.state.payment_methods && this.state.payment_methods.length > 0) {
      for (let i in this.state.payment_methods) {
        if (this.state.payment_methods[i].card_exp_month) {
          array.push(<div className="payment-methods__method" key={this.state.payment_methods[i].id}>{this.state.payment_methods[i].format_card + " " + this.state.payment_methods[i].card_exp_month +"-"+this.state.payment_methods[i].card_exp_year}</div>)
        } else {
          array.push(<div className="payment-methods__method" key={this.state.payment_methods[i].id}>{this.state.payment_methods[i].format_card}</div>)
        }
      }
      return array;
    }
  }

  showButton = () => {
    this.setState({showButton: true})
  }

  renderButton = () => {
    if (this.state.showButton) {
      return (
        <div className="notes__save-btn-wrapper"><div className="notes__save-btn" onClick={this.updateUser} >Save</div></div>
      )
    } else {
      return;
    }
  }

  updateUser = async () => {
    this.setState({showLabel: true, showButton: false});
    if (this.state.customer.business_name) {
      delete this.state.customer.date_of_birth;
    }
    if (!this.state.customer.email || !this.state.customer.formatted_phone ) {
      this.props.setNotification({message: 'Email and Phone are required', type: 'error'});
      return
    }

    let response = await userAPI.updateCustomer(this.state.customer, this.props.auth_token)
    if (response.err) {
      this.props.setNotification({message: response.err.response.data.detail, type: 'error'});
      return
    }
    this.props.setNotification({message: 'Customer updated successfully.'})
    this.setState({
      customer: response,
    })
  }

  handleChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let new_customer = this.state.customer;

    if (name === "date_of_birth"){
      if (value.length === 11) {
        let new_val = target.value.substr(0, 6) + target.value.substr(7)
        target.value = new_val;
      }
    }

    if (name === 'email') {
      let formatted = value.toLowerCase();
      value = formatted;
    }

    if (typeof new_customer === 'undefined'){
      new_customer = {}
    }

    new_customer[name] = value

    this.setState({
      customer: new_customer
    });
  }

  returnAddress = () => {
    let { service_address, service_address2, service_address_city, service_address_state, service_address_zip } = this.state.customer;
    let serviceAddress2 = '';

    if (service_address2) {
      serviceAddress2 = service_address2;
    }

    if(service_address_city) {
      return service_address + serviceAddress2 + ', ' + service_address_city + ', ' + service_address_state + ' ' + service_address_zip
    } else {
      return service_address + serviceAddress2 + ', ' + service_address_state + ' ' + service_address_zip;
    }
  }

  removeTag = async (id) => {
    let deletedTag = await tagsAPI.deleteTagLookup(this.props.auth_token, this.props.match.params.id, {field_ids: [{field_id: id}]});

    if (deletedTag.err) {
      this.props.setNotification({message: deletedTag.err.response.data.detail, type: 'error'});
      return
    }

    this.refreshData()
  }

  renderTags = () => {
    let { tags } = this.state.customer;
    if(tags && tags.length > 0) {
      return tags.map( tag => <Tag key={`detail-tag-${tag[0]}`} name={tag[1]} id={tag[0]} removeTag={this.removeTag}/>)
    }
  }

  showEditIcon = () => {
    if(!this.state.showEditIcon) {
      this.setState({showEditIcon: true})
    }
  }

  hideEditIcon = () => {
    if(this.state.showEditIcon) {
      this.setState({showEditIcon: false})
    }
  }

  sendInvite = async () => {
    let response = await SmsOptInAPI.sendInvite({user_id: this.state.customer.id}, this.props.auth_token)
    if (response.err) {
      this.setState({opt_in_invite_failure: true});
      return
    }
    this.props.setNotification({message: 'Invite Sent', length: 5000})
  }

  deleteCustomer = async () => {
    let deletedCustomer = await userAPI.deleteCustomer(this.props.match.params.id, this.props.auth_token);
    if(deletedCustomer.err && deletedCustomer.err.response) {
      let errorText = String(deletedCustomer.err.response.data.errors);
      this.setState({delete_errors: errorText})
    } else if (deletedCustomer.err) {
      let errorText = String(deletedCustomer.err);
      this.setState({delete_errors: errorText});
    } else if (deletedCustomer["errors"] !== undefined) {
      this.setState({errors: deletedCustomer["errors"]})
    } else {
      this.closeModal()
      this.props.setNotification({message: 'Customer successfully deleted.'})
      setTimeout( () => {
        this.props.history.push('/admin/customers');
      }, 3900)
    }
  }

  openModal = modal => {
    this.setState({modal})
  }
  
  closeModal = () => {
    this.setState({modal: null})
  }

  renderModal = () => {
    switch (this.state.modal) {
      case 'takePayment':
        return (
          <TakePaymentModal
            onClose={this.closeModal}
            payment_methods={this.state.payment_methods}
            invoices={this.state.unpaid_invoices} // not found
            getInvoiceDetail={this.refreshData} 
            context="new-detail"
            />
        )
      case 'autoPay':
        return (
          <AutopayModal
            onClose={this.closeModal}
            toggleModal={this.closeModal}
            errors={this.state.recurring_payment_errors}
            payment_methods={this.state.payment_methods}
            onChange={this.onChange} // not found
            refreshData={this.refreshData}
            payment_plans={this.state.customer.plans}
            customer={this.state.customer}
            sub_id={this.props.match.params.id}
            context="new-detail"
            />
        )
      case 'deleteCustomer':
        return (
          <DeleteCustomerModal
            onClose={this.closeModal}
            errors={this.state.delete_errors}
            deleteCustomer={this.deleteCustomer}/>
        )
      case 'addPaymentPlan':
        return <PaymentPlanModal onClose={this.closeModal} customer={this.state.customer} />
      case 'updateCustomer':
        return (
          <UpdateCustomerModal 
            refresh={this.updateCustomer} 
            customer={this.state.customer} 
            closeModal={this.closeModal}/>
        )
      default:
        return null
    }
  }

  render() {
    return (
      <React.Fragment>

        <CustomerDetailWrapper>
          {this.showTagModal()}
          <div className={classNames("section main background-white", {"blurred": !this.state.loaded})}>
            <div className="main__header">
              <div className="main__header-heading">Customer Detail</div>
              <div className="main__header-nav">
                <Tab small name="Conversation" width="139px" onClick={this.setActiveTab} active={this.state.current_tab === "Conversation"}/>
                <Tab small name="Invoices" width="92px" onClick={this.setActiveTab} active={this.state.current_tab === "Invoices"}/>
                <Tab small name="Payments" width="113px" onClick={this.setActiveTab} active={this.state.current_tab === "Payments"}/>
                {/* <Tab small name="Wallet" width="85px" onClick={this.setActiveTab} active={this.state.current_tab === "Wallet"}/>
                <Tab small name="Documents" width="141px" onClick={this.setActiveTab} active={this.state.current_tab === "Documents"}/> */}
                <Tab small name="Notes" width="71px" onClick={this.setActiveTab} active={this.state.current_tab === "Notes"}/>
              </div>
            </div>
            <div className="main__content">
              {this.renderConversation()}
              {this.renderInvoices()}
              {this.renderPayments()}
              {this.renderNotes()}
            </div>
          </div>
          <div className={classNames("section details background-white", {"blurred": !this.state.loaded})}>
            <ThreeDotButton className="py-three-dot">
              <span onClick={() => this.openModal("addPaymentPlan")}>Create Payment Plan</span>
              <span onClick={this.sendInvite}>Opt-In invite</span>
              <span onClick={() => this.openModal("takePayment")}>Take Payment</span>
              <span onClick={() => this.openModal("autoPay")}>Manage Autopay</span>
              <span onClick={() => this.openModal("deleteCustomer")}>Delete Customer</span>
            </ThreeDotButton>
            <div className="details-header">
              <Initials size="large" color="grey-2" first_name={this.state.customer.first_name} last_name={this.state.customer.last_name}/>
              <h1 className="details-header__heading" onMouseEnter={this.showEditIcon} onMouseLeave={this.hideEditIcon} onClick={() => this.openModal('updateCustomer')}>
                {this.state.customer.full_name}
                <img className={classNames("edit-icon", {"visible": this.state.showEditIcon})} alt="edit customer profile" src={pencil_icon}/>
              </h1>
            </div>
            <section className="details__section opt-in">
              <h3 className="details__section-heading">OPT IN STATUS</h3>
              <OptInInfo getUserInfo={this.refreshData}/>
            </section>
            <section className="details__section contact">
              <h3 className="details__section-heading">CONTACT</h3>
              <div className="details__contact">
                <span className="details__contact-line">
                  <img src={cakeIcon} alt="birthday" className="details__contact-icon"/>
                  {this.state.customer.date_of_birth}
                </span>
                <span className="details__contact-line">
                  <img src={phoneIcon} alt="phone" className="details__contact-icon"/>
                  {this.state.customer.formatted_phone}
                </span>
                <span className="details__contact-line">
                  <img src={emailIcon} alt="phone" className="details__contact-icon"/>
                  {this.state.customer.email}
                </span>
                <span className="details__contact-line">
                  <img src={homeIcon} alt="phone" className="details__contact-icon"/>
                  {this.returnAddress()}
                </span>
              </div>
            </section>
            <section className="details__section tags">
              <h3 className="details__section-heading">TAGS</h3>
              <div className="details__section-tags">
                {this.renderTags()}
                <div className="add-btn" onClick={this.toggleTagModal}><span>+</span></div>
              </div>
            </section>
          </div>
        </CustomerDetailWrapper>

        {this.renderModal()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role,
    merchant: state.auth.merchant_id,
    showTakePayment: state.modals.showTakePayment
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))},
    setInvoiceCustomer: (customer) => {dispatch(setInvoiceCustomer(customer))},
    toggleTakePayment: (bool) => {dispatch(toggleTakePayment(bool))},
    storeMerchantTags: tags => {dispatch(storeMerchantTags(tags))}

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetail);
