import React, {Component} from 'react';
import styled from 'styled-components';

const ToggleSwitchContainer = styled.div`
  input.toggle-switch {
    position: relative;
    appearance: none;
    outline: none;
    width: ${props => props.small ? '35px' : '62px'};
    height: ${props => props.small ? '15px' : '30px'};
    background-color: #9CAAB6;
    /* border: 1px solid #D9DADC; */
    border-radius: 50px;
    /* box-shadow: inset -20px 0 0 0 #fff; */
    transition-duration: 200ms;
    margin-right: 3px;
    transition: .2s ease-in-out;
    cursor: pointer;
  }
  .label {
    font-size: 13px;
    color: white;
    position: absolute;
    top: 8px;
    transition: .21s ease-in-out;
  }
  .off {
    left: 30px;
  }
 .on {
    left: 8px;
  }

input.toggle-switch:after {
 content: "";
 position: absolute;
 top: 4px;
 left: 4px;
 width: ${props => props.small ? '14px' : '23px'};
 height: ${props => props.small ? '13px' : '22px'};
 background-color: white;
 border-radius: 50%;
 box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.2);
 transition: .2s ease-in-out;
}

input.toggle-switch:checked {
 border-color: #06D6A0;
 box-shadow: inset 62px 0 0 0 #06D6A0;
}

input.toggle-switch:checked:after {
 left: 35px;
 /* box-shadow: -2px 4px 3px rgba(0,0,0,0.05); */
}

`

export default class ToggleSwitch extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  renderText = () => {
    if(this.props.checked) {
      return <span className="label on">ON</span>
    } else {
      return <span className="label off">OFF</span>
    }
  }


  render() {
    return (
      <ToggleSwitchContainer onChange={this.props.onChange} {...this.props}>
        <input data-test-id={this.props.name} className="toggle-switch" type="checkbox" name={this.props.name} id="toggle" checked={this.props.checked} {...this.props}/>
        {this.renderText()}
      </ToggleSwitchContainer>
    )
  }
}
