import React, {Component} from 'react';
import styled from 'styled-components';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';
require('cleave.js/dist/addons/cleave-phone.i18n')

const Wrapper = styled.div`
  .has-error {
    color: #FD7272;
    text-align: left;
    margin-top: 5px;
  }
  .red-border {
    border: 1px solid #FD7272
  }
  input {
    border: ${state => state.error ? '1px solid #FD7272' : '1px solid #9E9E9E'};
  }
`

export default class PhoneInput extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  handleEnter = (event) => {
    if (event.key === "Enter" && this.props.submit) {
      this.props.submit();
    } else {
      return;
    }
  }

  checkIfNumber = (e) => {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || (e.key === 'Backspace') || (e.key === 'Delete') || (e.key === 'ArrowLeft') || (e.key === 'Tab') || (e.key === 'ArrowRight')) {
    } else {
      e.preventDefault();
    }
  }

  checkForErrors = (e) => {
    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    } else {
      if (this.props.required && e.target.value.length !== 14) {
        this.setState({error: 'Not a valid phone number'})
      }
    }
    if (this.props.required && e.target.value.length === 14) {
      this.setState({error: ''})
    }
  }


  render() {
    return (
      <Wrapper {...this.state}>
        <Cleave placeholder={this.props.placeholder}
              className={classNames(this.props.className, {'red-border' : this.state.error})}
              options={{
                phoneRegionCode: 'US',
                delimiters: ['(', ')', ' ', '-'],
                blocks:[0, 3, 0, 3, 4]
              }}
              name={this.props.name}
              data-test-id={this.props.name}
              onChange={this.props.onChange}
              value={this.props.value}
              onKeyUp={this.handleEnter}
              onKeyDown={this.checkIfNumber}
              type="tel"
              required
              autoFocus={this.props.autoFocus}
              onBlur={(e) => this.checkForErrors(e)}/>
        <div className="has-error">{this.state.error}</div>
      </Wrapper>
    )
  }
}
