import { PURGE } from 'redux-persist';

import {
  STORE_MERCHANT_LOGO,
  STORE_MERCHANT_NAME,
  STORE_MERCHANT_TAGS,
  STORE_ACH_INFO,
  STORE_INTEGRATIONS
} from '../actions/merchants';

const initialState = {
  merchant_name: undefined,
  merchant_logo: undefined,
  ach_info: undefined,
  integrations: undefined,
  merchant_tags: []
}

const merchants = (state = initialState, action) => {
  switch (action.type) {
    case STORE_INTEGRATIONS:
      return Object.assign({}, state, { integrations: action.payload });
    case STORE_ACH_INFO:
      return Object.assign({}, state, { ach_info: action.payload });
    case STORE_MERCHANT_LOGO:
        return Object.assign({}, state, {merchant_logo: action.payload});
    case STORE_MERCHANT_NAME:
        return Object.assign({}, state, {merchant_name: action.payload});
    case STORE_MERCHANT_TAGS:
        return Object.assign({}, state, {merchant_tags: action.payload});
    case PURGE:
      return {}
    default:
      return state;
  }
};

export default merchants;
