import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//Components
import WhiteContainer from '../../../components/wrappers/WhiteContainer';
import AdminPageWrapper from '../../../components/wrappers/AdminPageWrapper';
import Breadcrumb from '../../../components/display/BreadcrumbTitle';
import MessageBubble from '../../../components/messaging/MessageSentBubble';

//API & Utils
import messagesAPI from '../../../api/messages/messagesAPI';
import globalUtils from '../../../utils/globalUtils';


const ContentContainer = styled.div`
  display: flex;
  padding: 20px;
  .left-container {
    height: 600px;
    width: 692px;
    padding: 20px 20px;
    box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 2px 4px 0 rgba(234,234,234,0.5);
  }
  .inner-container {
    height: 100%;
    width: 600px;
    .top-box {
      border-bottom: 1px solid gainsboro;
      height: 100px;
      padding: 20px;
      .details {
        padding-bottom: 5px;
      }
    }
    .bottom-box {
      padding: 20px;
      margin-top: 20px;
      .message-body {
        line-height: 1.4;
        width: 100%;
        max-width: 100%;
        word-wrap: break-word;
      }
      .sms-message-body {
        float: right;
        color: #fff;
        padding: 20px;
        background-color: #1989FE;
        border-radius: 17px;
        width: 70%;
        position: relative;
        word-wrap: break-word;
        &:after {
          content: "";
          position: absolute;
          z-index: 1;
          bottom: -3px;
          right: 0px;
          width: 10px;
          height: 11px;
          background: #1989FE;
          border-bottom-left-radius: 10px;
          transform: rotate(-40deg);
        }
        .sms-date {
          color: #8E8E93;
          font-size: 14px;
          position: absolute;
          bottom: -17px;
          right: 5px;
        }
      }
    }
  }

  .right-container {
    margin-top: 200px;
    padding: 10px;
    .key {
      font-size: 22px;
      color: #64ABF5;
      font-weight: 300;
      .value {
        color: #565656;
        font-size: 18px;
        text-transform: capitalize;
      }
    }
  }
`

class MessageDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      message: {}
    };
    this.message_id = this.props.match.params.id;

  }

  componentDidMount = () => {
    this.getMessageDetail();
  }

  getMessageDetail = async () => {
    let message = await messagesAPI.getDetail(this.message_id, this.props.auth_token)
    if (message.err) {
      this.setState({errors: message.err.response.data.detail})
      return
    }
    message.medium = message.medium === 1 ? 'sms' : 'email';
    this.setState({message, loaded: true});
  }

  returnMessageBody = () => {

    if (this.state.message.body && this.state.message.medium === 'email') {
      return (
        <a data-test-id="link-to-conversations-1" href={`/admin/conversations/${this.state.message.sub_id}#${this.state.message.id}`}>
          <div className="message-body">{this.state.message.body}</div>
        </a>
      )
    } else if (this.state.message.medium === 'sms' && this.state.loaded) {
      return (
        <a data-test-id="link-to-conversations-2" href={`/admin/conversations/${this.state.message.sub_id}#${this.state.message.id}`}>
          <MessageBubble body={this.state.message.body} created_at={this.state.message.created_at}>
          </MessageBubble>
        </a>
      )
    }
  }

  render() {
    return (
      <AdminPageWrapper>
        <Breadcrumb page="Message Detail" path="/admin/conversations"/>
        <WhiteContainer>
          <ContentContainer>
            <div className="left-container">
              <div className="inner-container">
                <div className="top-box">
                  <div className="company-name"></div>
                  <div className="details">To: {this.state.message.user_name}</div>
                  <div className="details">
                    Date: {globalUtils.formatDateAndTime(this.state.message.created_at)}
                  </div>
                </div>
                <div className="bottom-box">
                  {this.returnMessageBody()}
                </div>
              </div>
            </div>
            <div className="right-container">
              <div className="key">Recipient:
                <span className="value"> {this.state.message.user_name}</span>
              </div>
              <div className="key">Format:
                <span className="value"> {this.state.message.medium}</span>
              </div>
              <div className="key">Status:
                <span className="value"> {this.state.message.status}</span>
              </div>
              <div className="key">Date/Time:
                <span className="value"> {globalUtils.formatDateAndTime(this.state.message.created_at)}</span>
              </div>
            </div>
          </ContentContainer>
        </WhiteContainer>
      </AdminPageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_role: state.auth.user_role
  };
};

export default connect(
  mapStateToProps,
)(MessageDetail)
