import React, {Component} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import Background from '../../components/display/Background';

const Container = styled.div`
  background-color: white;
  height: 490px;
  width: 845px;
  margin-top: 70px;
  border-radius: 11px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  padding: 8px 35px;
  overflow: auto;
  position: relative;
  top: 36px;
  .title {
    font-size: 24px;
    color: #64ABF5;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .document {
    line-height: 1.3;
  }
  @media (max-width: 768px) {
    width: 550px;
  }
  @media (max-width: 580px) {
    width: 345px;
  }
  @media (max-height: 667px) {
    height: 427px;
    width: 340px;
  }

  @media (min-height: 668px) {
    margin-top: 150px;
  }

  @media (min-device-height: 668px) {
    margin-top: 110px;
  }

  @media (min-device-height: 896px) {
    height: 540px;
    margin-top: 125px;
    padding: 15px 35px;
  }
`

const PrivacyText = Loadable({
  loader: () => import('../../components/stateless/PrivacyPolicyText'),
  loading() {
    return <div></div>
  }
});

class PrivacyPolicy extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Background>
        {/* <CloseButton src={close_icon} onClick={this.props.history.goBack}/> */}
        <Container>
          <div className="title">Privacy Policy</div>
          <PrivacyText/>
        </Container>
      </Background>
    )
  }
}

export default withRouter(PrivacyPolicy);
