import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'auto_groups/';
let group_detail = apiUtils.baseUrl + 'smsgroup/get_group_messages';

class groupsAPI {
  getList = (auth_token) => {
    return apiUtils.get(url + 'get_auto_groups', auth_token);
  }

  createGroup = (auth_token, data) => {
    return apiUtils.post(url + 'create_auto_group', data, auth_token);
  };

  editGroup = (auth_token, group_id, data) => {
    return apiUtils.patch(url + group_id + '/edit_auto_group', data, auth_token);
  };

  getGroupDetail = (auth_token, id) => {
    return apiUtils.get(group_detail + '?group_id=' + id, auth_token);
  }

  deleteGroup = (auth_token, id) => {
    return apiUtils.post(url + id + '/delete_auto_group', auth_token)
  }
}

export default new groupsAPI();
