import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import globalUtils from '../../../utils/globalUtils';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 20px;
`

const SubHeading = styled.p`
    font-weight: 500;
    text-decoration: underline;
    text-align: left;
`

const Disclaimer = styled.p`
    font-size: 11px;
`

const Disable = styled.p`
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 3px;
    color: #FF715B;
    margin-top: 8px;
    font-weight: bold;
`

class AutopayDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            disablePrompt: false
        }
    }

    togglePrompt = () => {
        this.setState({
            disablePrompt: !this.state.disablePrompt
        })
    }

    getDay = () => {
        if (isNaN(this.props.autopay.autopay_day) === false){
            return "the " + globalUtils.formatDay(this.props.autopay.autopay_day) + " of each month"
        } else if (this.props.autopay.autopay_frequency === "weekly"){
            let capitalizedFirstLetter = this.props.autopay.autopay_day.charAt(0).toUpperCase() + this.props.autopay.autopay_day.slice(1)
            return "every " + capitalizedFirstLetter
        } else {
            let capitalizedFirstLetter = this.props.autopay.autopay_day.charAt(0).toUpperCase() + this.props.autopay.autopay_day.slice(1)
            return "every other " + capitalizedFirstLetter
        }
    }
    
    renderDetail = () => {
        const payMethod = this.props.payment_methods.filter(method => method.id === this.props.autopay.autopay_method)[0]
        if(this.props.autopay.autopay_day === "due_date" && this.props.invoice){
            return (
                <Container>
                    <SubHeading>Next Payment:</SubHeading>
                    <p>Amount: ${(this.props.invoice.amount_due / 100).toFixed(2)}</p>
                    <p>Date: {globalUtils.formatDate(this.props.invoice.due_date)}</p>
                    <p>Payment Method: {payMethod ? payMethod.format_card : "-"}</p>
                    <Disclaimer>*Your auto-pay will automatically make a payment  with the provided payment method everytime an invoice is due.</Disclaimer>
                    <Disable onClick={this.state.disablePrompt ? this.props.disableAutopay : this.togglePrompt}>{this.state.disablePrompt ? "Yes, I really want to disable autopay" : "Disable Autopay"}</Disable>
                </Container>
            )
        } else if (this.props.autopay.autopay_day === "due_date" && !this.props.invoice) {
            return (
                <Container>
                    <SubHeading>Payment Schedule:</SubHeading>
                    <p>Amount: The amount due on the invoice</p>
                    <p>Date: On due date</p>
                    <p>Payment Method: {payMethod ? payMethod.format_card : "-"}</p>
                    <Disclaimer>*Your auto-pay will automatically make a payment  with the provided payment method everytime an invoice is due.</Disclaimer>
                    <Disable onClick={this.state.disablePrompt ? this.props.disableAutopay : this.togglePrompt}>{this.state.disablePrompt ? "Yes, I really want to disable autopay" : "Disable Autopay"}</Disable>
                </Container>
            )
        } else if (this.props.invoice) {
            return (
                <Container>
                    <SubHeading>Payment Schedule:</SubHeading>
                        <p>Amount: ${(this.props.autopay.autopay_amount / 100).toFixed(2)}</p>
                        <p>When: {this.getDay()}</p>
                        <p>Payment Method: {payMethod ? payMethod.format_card : "-"}</p>
                        <Disclaimer>*Your auto-pay will automatically make a payment of ${(this.props.autopay.autopay_amount / 100).toFixed(2)} with the provided payment method on {this.getDay()}.</Disclaimer>
                        <Disable onClick={this.state.disablePrompt ? this.props.disableAutopay : this.togglePrompt}>{this.state.disablePrompt ? "Yes, I really want to disable  autopay" : "Disable Autopay"}</Disable>
                </Container>
        )} else {
            return (
                <Container>
                    <SubHeading>Payment Schedule:</SubHeading>
                        <p>Amount: ${(this.props.autopay.autopay_amount / 100).toFixed(2)}</p>
                        <p>When: {this.getDay()}</p>
                        <p>Payment Method: {payMethod ? payMethod.format_card : "-"}</p>
                        <Disclaimer>*Your auto-pay will automatically make a payment of ${(this.props.autopay.autopay_amount / 100).toFixed(2)} with the provided payment method on {this.getDay()}.</Disclaimer>
                        <Disable onClick={this.state.disablePrompt ? this.props.disableAutopay : this.togglePrompt}>{this.state.disablePrompt ? "Yes, I really want to disable  autopay" : "Disable Autopay"}</Disable>
                </Container>
            )  
        }
    }

    render(){
        return (
            <>
                {this.renderDetail()}
            </>
        )
    }
}

AutopayDetail.propTypes = {
    autopay: PropTypes.object.isRequired,
    payment_methods: PropTypes.array.isRequired
}

export default AutopayDetail
