import React, {Component} from 'react';
import styled from 'styled-components';
import Cleave from 'cleave.js/react';

const Wrapper = styled.div`
  .has-error {
    color: #FD7272;
    text-align: left;
    margin-top: 5px;
  }
  input {
    border: ${state => state.error ? '1px solid #FD7272' : '1px solid #9E9E9E'};
  }
`

export default class ExpirationDate extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  checkForErrors = (e) => {
    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    } else {
      if (this.props.required && e.target.value.length !== 5) {
        this.setState({error: 'Not a valid date'})
      }
    }
    if (this.props.required && e.target.value.length === 5) {
      this.setState({error: ''})
    }
  }

  render() {
    return (
      <Wrapper {...this.state}>
        <Cleave placeholder="mm/yy"
              className={this.props.className}
              options={{date: true, datePattern: ['m','y']}}
              name={this.props.name}
              data-test-id={this.props.name}
              required
              onChange={this.props.onChange}
              onBlur={(e) => this.checkForErrors(e)}
              {...this.props}/>
        <div className="has-error">{this.state.error}</div>
      </Wrapper>
    )
  }
}
