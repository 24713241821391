import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Portal from '../function/Portal';
import Notification from './Notification';

const NotificationHolder = styled.div`
  /* background-color: yellow; */
  position: fixed;
  right: 0;
  top: 0;
  width: 275px;
`;

const MobileNotificationHolder = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function NotificationContainer(props) {
  const { notifications, mobileNotifications } = props;
  const Notifications = notifications.map((notification, index) => <Notification data={notification} id={notification.id} position={index} key={notification.id}/>)
  
  const Mobile_Notifications = mobileNotifications.map((notification, index) => <Notification data={notification} id={notification.id} position={index} key={notification.id}/>)

  return(
    <Portal>
      <NotificationHolder>
        { Notifications }
      </NotificationHolder>
      <MobileNotificationHolder>
        {Mobile_Notifications}
      </MobileNotificationHolder>
    </Portal>
  )
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
    mobileNotifications: state.notifications.mobileNotifications
  };
};

export default connect(
  mapStateToProps,
)(NotificationContainer);
