import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';

//Components
import Background from '../../components/mobile/MobileBackground';
import PhoneInput from '../../components/inputs/PhoneInput';
import Checkbox from '../../components/inputs/Checkbox';
import ActionButton from '../../components/buttons/ActionButton';
import HideShow from '../../components/function/HideShow';
import DisabledButton from '../../components/buttons/DisabledButton';
import TermsModal from '../../components/mobile/TermsAndConditions';
import PrivacyModal from '../../components/mobile/PrivacyPolicy';

//API & Utils
import customerAuthAPI from '../../api/auth/customerAuthAPI';
import globalUtils from '../../utils/globalUtils';
import history from '../../history';

//Redux
import { storeCustomerAuth, storeCustomerUsername, storeSubId } from '../../js/actions/auth';
import { storeMerchantLogo, storeMerchantName } from '../../js/actions/merchants';

const Container = styled.div`
  background-color: #f1f4f7;
  height: auto;
  width: 845px;
  border-radius: 11px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  width: 345px;
  margin: 0 auto;
  margin-top: 120px;
  overflow-y: scroll;
  @media (max-device-height: 667px) {
    max-height: 415px;
    padding: 20px;
    margin-top: 100px;
  }
  @media (min-device-height: 668px) {
    height: 440px;
    padding: 35px;
  }
  .logo-wrapper {
    margin-top: 20px;
  }
  input {
    border: 1px solid #fff;
    margin-bottom: 15px;
  }
  .has-error {
    color: #FD7272;
    text-align: center;
    margin-top: 5px;
  }
  .text {
    font-size: 10px;
    @media (min-device-height: 896px) {
      font-size: 13px;
    }
  }
  .clickable {
    color: #64ABF5;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: 550px;
  }
  @media (max-width: 580px) {
    width: 345px;
    // overflow: auto;
  }

  @media (min-device-height: 668px) {
    margin-top: 130px;
    padding: 15px 18px;
  }
  @media (min-device-height: 896px) {
    height: auto;
    /* padding: 15px 35px; */
  }

  @media screen and (device-aspect-ratio: 40/71) {
    /* iphone 5 only */
    height: 320px;
    width: 300px;
  }   
`

const Header = styled.div`
  text-align: center;
  font-size: 24px;
  color: #64ABF5;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  @media (max-width: 580px) {
    margin-top: 0px;
  }
  @media (min-device-height: 896px) {
    font-size: 35px;
    margin-top: 10px;
  }
`

const InputWrapper = styled.div`
  text-align: center;
  padding-top: 5px;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  @media (max-height: 895px) {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  @media (max-device-height: 895px) {
    margin-top: 7px;
    margin-bottom: 7px;
  }
`

const P1 = styled.div`
  text-align: center;
  color: #535353;
  font-size: 14px;
  margin-bottom: 10px;
  @media (max-width: 580px) {
    font-size: 12px;
  }
`

const Agreement = styled.div`
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  color: #535353;
  font-weight: 500;
  margin-bottom: 10px;
`

const CheckboxContainer = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
  color: #656565;
  @media (max-width: 580px) {
    margin-top: 20px;
  }
  .text {
    font-size: 12px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (min-device-height: 736px) {
    margin-top: 15px;
  }
  @media (min-device-height: 896px) {
    align-items: flex-end;
    height: 75px;
  }
`
const BlytzContainer = styled.div`
  text-align: center;
  padding: 10px;
  .small-text {
    font-size: 12px;
    color: white;
  }
`
class WelcomeVerify extends Component {
  constructor(props){
    super(props);
    this.state = {
      showTerms: false,
      showPrivacy: false,
      phone: undefined
    };

    this.myRefs = {
      phone: React.createRef()
    }
  }

  componentDidMount = () => {
    const values = globalUtils.getQueryString(history.location.search.slice(1));
    this.username = values.username;
    this.props.storeSubId(values.subscription_id)
    this.subscription_id = values.subscription_id;
    this.props.storeSubId(values.subscription_id);
    this.merchant_name = values.merchant_name
    this.setMerchantName(values.merchant_name);
  }

  getLogo = async () => {
    let response = await customerAuthAPI.getLogo(this.merchant_name);
    this.props.storeMerchantLogo(response.merchant_logo);
  }

  setMerchantName = (merchant_name) => {
    if (merchant_name) {
      this.props.storeMerchantName(merchant_name);
      this.getLogo();
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  submit = async (event) => {
    if (globalUtils.checkValues(this.myRefs) === false) {
      return;
    }
    if (!this.state.phone) {
      this.setState({errors: 'Phone number is required'})
      return;
    }
    if (!event.detail || +event.detail === 1){
      let opt_in = await customerAuthAPI.optIn({phone: this.state.phone, subscription_id: this.subscription_id});
      if (opt_in.err && opt_in.err.response) {
        this.setState({errors: opt_in.err.response.data.detail})
        return
      }

      if (opt_in) {
        this.props.storeCustomerAuth(opt_in.auth_token)
        this.props.storeCustomerUsername(opt_in.username)
        history.push('/welcome/2?subscription_id='+this.subscription_id+'&phone='+this.state.phone+'&username='+opt_in.username);
      }
    }
  }

  toggleTerms = () => {
    this.setState(prevState => {
      return {
        showTerms: !prevState.showTerms,
      };
    });
  }

  showTermsModal = () => {
    if (this.state.showTerms) {
      return (
        <TermsModal closeModal={this.toggleTerms}/>
      )
    }
  }

  togglePrivacy = () => {
    this.setState(prevState => {
      return {
        showPrivacy: !prevState.showPrivacy,
      };
    });
  }

  showPrivacyModal = () => {
    if (this.state.showPrivacy) {
      return (
        <PrivacyModal closeModal={this.togglePrivacy}/>
      )
    }
  }

  render() {
    return (
      <Background fixed show_logo>
        {this.showTermsModal()}
        {this.showPrivacyModal()}
        <Container>
          <Header>Welcome!</Header>
          <P1>Please verify your phone number<br/> so we know it's you.</P1>
          <InputWrapper>
            <div className="has-error">{this.state.errors}</div>
            <PhoneInput className="merchant-input" data-test-id="phone" name="phone" onChange={this.handleChange} value={this.state.phone} required ref={this.myRefs.phone} placeholder="Phone number"/>
          </InputWrapper>
          <Agreement>To begin using BlytzPay® you must agree to the
            <span className="clickable" data-test-id="welcome-verify-terms" onClick={this.toggleTerms}> terms and conditions</span> and <span className="clickable" data-test-id="welcome-verify-privacy" onClick={this.togglePrivacy}>privacy policy</span>
          </Agreement>
          <CheckboxContainer>
            <Checkbox text={`I agree to be contacted by ${this.state.merchant_name} via text message and/or phone call at the phone number provided above regarding services provided by ${this.state.merchant_name}. I understand that I can revoke this permission at any time by contacting ${this.state.merchant_name}.`} value={this.state.sms_opt_in} data-test-id="sms-opt-in-check" name="sms_opt_in" onChange={this.handleChange}/>
          </CheckboxContainer>
          <HideShow show={!this.state.sms_opt_in}>
            <ButtonContainer><DisabledButton data-test-id="welcome-verify-continue-1" onClick={this.submit}>Continue</DisabledButton></ButtonContainer>
          </HideShow>
          <HideShow show={this.state.sms_opt_in}>
            <ButtonContainer><ActionButton className="pycontinue" data-test-id="welcome-verify-continue-2" onClick={this.submit} red>Continue</ActionButton></ButtonContainer>
          </HideShow>
        </Container>
        <BlytzContainer>
          <div className="small-text">powered by BlytzPay®</div>
        </BlytzContainer>
      </Background>
    )
  }
}

const mapStateToProps = (state) => {
  return { auth_token: state.auth.auth_token };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    storeCustomerAuth: (auth_token) => {dispatch(storeCustomerAuth(auth_token))},
    storeCustomerUsername: (username) => {dispatch(storeCustomerUsername(username))},
    storeMerchantName: (merchant_name) => {dispatch(storeMerchantName(merchant_name))},
    storeMerchantLogo: (merchant_logo) => {dispatch(storeMerchantLogo(merchant_logo))},
    storeSubId: sub_id => {dispatch(storeSubId(sub_id))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeVerify)
