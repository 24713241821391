import React, {Component} from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
  height: 45px;
  width: 45px;
  overflow: hidden;
`

const AdminSidebarIcon = styled.img`
  height: auto;
  width: ${props => props.small ? '30px' : '45px'};
  margin-left: ${props => props.small ? '7px' : '0px'};
  margin-top: ${props => props.small ? '4px' : '0px'};
`

export default class AdminSideBarWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <IconContainer>
        <AdminSidebarIcon src={this.props.src} alt={this.props.alt} {...this.props}/>
      </IconContainer>
    )
  }
}
