import React, {Component} from 'react';
import styled from 'styled-components';
import { color_nav_border } from '../../styles/colors';

const InputWrapper = styled.div`
  width: 98px;
  margin: 5px 0 5px 0;
  position: relative;
  input {
    padding: 0 10px;
    border: 2px solid ${color_nav_border};
    height: 40px;
    width: 100%;
    border-radius: 5px;
  }
  `;

export default class AddCardInputWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <InputWrapper {...this.props}>{this.props.children}</InputWrapper>
    )
  }
}
