import React, {Component} from 'react';
import { connect } from 'react-redux';

//Components
import Modal from '../NewModal';
import Typeahead from '../../inputs/Typeahead';
import InputWrapper from '../../wrappers/InputWrapper';

//API & Utils
import userAPI from '../../../api/customer/userAPI';
import history from '../../../history';

//Assets
import { ModalContent } from '../generalModalStyling';

class UserSearchModal extends Component {
  constructor(props){
    super(props);
    this.state = {};

    this.myRefs = {
      sub_id: React.createRef()
    }
  }

  componentDidMount = () => {
    this.getCustomers();
  }

  onChange = (event) => {
    if (event.target) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
    } else {
				this.setState({
				sub_id: event.value
				})
		}
  };

  getCustomers = async () => {
    let customers = await userAPI.getList(this.props.auth_token)
    this.setState({ customers });
  }

  routeToUser = () => {
    if (!this.state.sub_id) {
      this.setState({ errors: 'User must be selected'})
      return;
    }
    history.push('/admin/customers/profile/'+this.state.sub_id)
  }


  render() {
    return (
      <Modal context="user-search" title="New Message" modalname="showNewMessageModal" onClose={this.props.onClose} errors={this.state.errors} addInvoice submit={this.routeToUser}>
				<ModalContent className="user-search">
					<InputWrapper>
							<Typeahead options={this.state.customers}
											name="sub_id"
											onChange={this.onChange}
											required
											ref={this.myRefs.sub_id}
                      subscription/>
					</InputWrapper>
				</ModalContent>
			</Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

export default connect(mapStateToProps)(UserSearchModal);
