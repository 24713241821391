import axios from 'axios';
import history from '../history.js';
import qs from 'qs';
import globalUtils from '../utils/globalUtils';
import data from './api';
import _ from 'lodash';
import store from '../js/store';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

let axiosConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Origin': '*',
    // 'X-CSRF-Token': 'csrftoken'
  },
};

class ApiUtils {
  callbacks = {};
  baseUrl = data["base_url"];
  rawDomain = data["raw_domain"];
  chaliceAPP = data["chaliceAPP"]

  interceptRedirect = (resp) => {
    if (resp["data"] !== undefined){
      let data = resp["data"]
      if (data["redirect"] !== undefined){
          history.push(data["redirect"]);
      }
    }
  }

  listener = () => {
    this.storeData = this.selectData(store.store.getState())
  }


  selectData = (state) => {
    return state;
  }

  userStore = store.store.subscribe(this.listener);

  buildHeaders = (auth_token) => {
    let config = axiosConfig;

    if (globalUtils.validate_credential(auth_token)){
      if (this.storeData && this.storeData.auth.is_merchant) {
        config.headers['Authorization'] = 'MerchantToken ' + auth_token
        // config.headers['merchant-token'] =  auth_token
      } else if (this.storeData && this.storeData.auth.is_merchant === false) {
        config.headers['Authorization'] = 'Token ' + auth_token
      }
    }
    return config
  }

  getCustom = (url, token) => {
    let config = _.cloneDeep(axiosConfig);
    if (token) {
      config.headers['Authorization'] = 'Token ' + token
    }

    return axios
      .get(url, config, data)
      .then(res => {
          this.interceptRedirect(res)
          return res.data;
        }).catch(err => {
        if (err.toString().indexOf("Request failed with status code 403") !== -1){
          return ({"status": 403, "errors": err.toString()})
        }
        return Promise.resolve({ err })
      });
  }

  postCustom = (url, data) => {
    let config = _.cloneDeep(axiosConfig);

    return axios
      .post(url, qs.stringify(data), config)
      .then(res => {
        this.interceptRedirect(res)
        return res.data;
      })
      .catch(err => {
        return Promise.resolve({ err });
      });
  }

  get = async (url, auth_token, callback, id) => {
    let config = await this.buildHeaders(auth_token);
    return axios
      .get(url, config, id)
      .then(res => {
          this.interceptRedirect(res)
          return res.data;
        }).catch(err => {
        if (err.toString().indexOf("Request failed with status code 403") !== -1){
          return ({"status": 403, "errors": err.toString()})
        }
        return Promise.resolve({ err })
      });
  };

  chaliceGet = async (url, auth_token, callback, id) => {
    let config = { 
      headers: {
        'merchant-token': auth_token,
        'x-api-key': "2zYHF127Kg65iPXoWF4zX1Z10OLM4JwX2sbbd3PU"
      }
    };
    return axios
      .get(url, config)
      .then(res => {
          console.log("res", res)
          // this.interceptRedirect(res)
          return res.data;
        }).catch(err => {
          console.log("error", err)
        if (err.toString().indexOf("Request failed with status code 403") !== -1){
          return ({"status": 403, "errors": err.toString()})
        }
        return Promise.resolve({ err })
      });
  };

  getNoAuth = (url, callback) => {
    return axios
      .get(url)
      .then(res => {
        this.interceptRedirect(res)
        return res.data;
      })
      .catch(err => {
        return Promise.resolve({ err })
      });
  };

  put = (url, data, auth_token) => {
    let config = this.buildHeaders(auth_token);
    return axios
      .put(url, JSON.stringify(data), config)
      .then(res => {
        this.interceptRedirect(res)
        return res.data;
      })
      .catch(err => {
        return Promise.resolve({ err })
      });
  };

  patch = (url, data, auth_token, callback) => {
    let config = this.buildHeaders(auth_token);
    return axios
      .patch(url, qs.stringify(data), config)
      .then(res => {
        this.interceptRedirect(res)
        return res.data;
      })
      .catch(err => {
        return Promise.resolve({ err });
      });
  };

  post = (url, data, auth_token, callback) => {
    let config = this.buildHeaders(auth_token);
    return axios
      .post(url, qs.stringify(data), config)
      .then(res => {
        this.interceptRedirect(res)
        return res.data;
      })
      .catch(err => {
        return Promise.resolve({ err });
      });
  };

  delete = (url, auth_token) => {
    let config = this.buildHeaders(auth_token);
    return axios
      .delete(url, config)
      .then(res => {
        this.interceptRedirect(res)
        return res.data;
      })
      .catch(err => {
        // handleError(err);
      });
  };

  onError = callback => {
    let id = Date.now();
    this.callbacks[id] = callback;
    return id;
  };
}

let apiUtils = new ApiUtils();

export default apiUtils;
