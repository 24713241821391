import React from 'react';
import styled from 'styled-components';
import checkmark from '../../assets/icons/check_circle.png';

const Image = styled.img`
  height: 175px;
  width: auto;
  @media screen and (device-aspect-ratio: 40/71) {
    /* iphone 5 */
    height: 75px;
  }
  @media only screen and (min-width: 375px) and (max-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    /* iphone 6 */
    height: 100px;
  }
`

export default function CheckMark() {
  return (
    <Image src={checkmark}/>
  )
}
