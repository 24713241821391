import React, {Component} from 'react';
import styled from 'styled-components';
import chevron from '../../assets/icons/chevron_left_grey.png';

const Container = styled.div`
  max-width: 330px;
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, .27);
  display: flex;

  img {
    height: 25px;
    width: auto;
    justify-content: flex-end;
    transform: scaleX(-1);
    margin-top: 12px;
    margin-right: 5px;
  }
  .type {
    font-size: 24px;
    color: #fff;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 35px;
  }
  .card-info {
    width: 185px;
    font-size: 18px;
    color: #fff;
    margin-right: 23px;
    margin-top: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export default class DonationCard extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    const {name_on_card} = this.props.data
    const {card_type, last4} = this.props.data.card
    return (
      <Container {...this.props} onClick={this.props.onClick}>
        <div className="type">Card</div>
        <div className="card-info">
          <div>{card_type + ' **** ' + last4}</div>
          <div>{name_on_card}</div>
        </div>
        <img src={chevron} alt="arrow"/>
      </Container>
    )
  }
}
