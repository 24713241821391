import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//Components
import ActionButton from '../../buttons/ActionButton';
import ErrorHandle from '../../function/ErrorHandle';

//API & Utils
import refundAPI from '../../../api/refunds/refundAPI';
import globalUtils from '../../../utils/globalUtils';
// import paymentAPI from '../../api/payments/paymentAPIs';

//Redux
import { setNotification } from '../../../js/actions/notifications';


const ErrorContainer = styled.div`
  text-align: center;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(59,59,59,.5);
  position: fixed;
  z-index: 9;
  left: 233px;
  top: 0px;
`

const Modal = styled.div`
  height: auto;
  width: 465px;
  background-color: #F1F4F7;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -350px;
  border-radius: 10px;
  .modal-title {
    text-align: center;
    color: #646464;
    font-size: 33px;
    padding: 10px 0px;
    background-color: #fff;
    border-radius: 10px;
  }
  .modal-content {
    background-color: #F1F4F7;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
  }
  .modal-input-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    height: 40px;
    font-size: 25px;
    ::-webkit-input-placeholder {
      text-align: center;
    }
  }
  .text-container {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .text {
    font-size: 20px;
    color: #636363;
    padding-top: 15px;
  }
  .warning-text {
    display: flex;
    justify-content: center;
    color: #FF6162;
    font-size: 16px;
    margin-top: 20px;
  }
  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
`
// Response Codes: A - Approved, B - Retry, C - Declined

// let sampleVoidRequest = {
// 	"merchid": "123456789012",
// 	"amount": "0.01",
// 	"currency": "USD",
// 	"retref": "227082251218",
// 	"authcode": "REVERS",
// 	"respcode": "00",
// 	"respproc": "FNOR",
//     "respstat": "A",
//     "resptext": "Approval", 
// }  

// let sampleRefundRequest = {
//   "amount": "59.60",
//   "resptext": "Approval",
//   "authcode": "REFUND",
//   "respcode": "00",
//   "retref": "288010185242",
//   "merchid": "000000927996",
//   "cvvresp": "M",
//   "account": "41XXXXXXXXXX4113",
//   "avsresp": "Z",
//   "respproc": "PPS",
//   "respstat": "A"
// }

class CancelTransactionModal extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }
  
  // componentDidMount() {
  //   this.getTransactionStatus();
  // }

//   getTransactionStatus = async () => {
//     let status = await paymentAPI.getStatus(this.props.refund.id, this.props.auth_token);
//     if (status.err && status.err.response) {
//       let errorText = String(status.err.response.data.detail);
//       this.props.setNotification({message: errorText, type: 'error'});
//       return;
//     } else if (status.err) {
//       let errorText = String(status.err);
//       this.setNotification({message: errorText, type: 'error'})
//       return;
//     } else {
//       if(status.settlement_status === "Accepted") {
//         this.props.setNotification({message: 'This transaction has already been processed', type: 'error'})
//         this.props.onClose(this.props.modalname)
//       }
//     }
//   }

  cancelTransaction = async () => {
    // Status, amount 
    let { refund, auth_token, modalname } = this.props;
    let cancellation = await refundAPI.cancelTransaction({transaction_id: refund.id}, auth_token)
    if (cancellation.err && cancellation.err.response) {
      this.setState({errors: cancellation.err.response.data.detail})
      return
    } else if(cancellation.errors) {
      this.setState({errors: cancellation.errors});
      return;
    } else {
      let { status } = cancellation;
      if(status === 1) {
        this.props.setNotification({message: `${globalUtils.convertToDollars(refund.amount_paid)} transaction successfully canceled`});
        this.props.getInvoiceDetail();
        this.props.onClose(modalname);
        return;
      }  else {
        this.props.setNotification({message: 'Transaction could not be canceled', type: 'error'});
        this.props.onClose(modalname);
      }
    }
  }

  render() {
    const { refund, modalname, onClose } = this.props;
    return (
      <Container>
        <Modal context="cancel-transaction">
        <div className="modal-title">Cancel Transaction</div>
        <div className="modal-content">
          <div className="modal-input-container">
            Amount: {globalUtils.convertToDollars(refund.amount_paid)}
          </div>
          <div className="text-container">
            <span className="text">Are you sure you want to cancel this transaction?</span>
          </div>
          <ErrorContainer>
            <ErrorHandle data={this.state.errors}/>
          </ErrorContainer>
          <div className="button-wrapper">
            <ActionButton primary onClick={ () => onClose(modalname) }>No</ActionButton>
            <ActionButton secondary onClick={this.cancelTransaction}>Yes</ActionButton>
          </div>
        </div>
      </Modal>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelTransactionModal);
