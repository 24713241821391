import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import history from '../../../history';

//Components
import ErrorHandle from '../../../components/function/ErrorHandle';
import Breadcrumb from '../../../components/display/BreadcrumbTitle';
import Input from '../../../components/inputs/Input';
import InputWrapper from '../../../components/wrappers/InputWrapper';
import Label from '../../../components/display/Label';
import ModalButtonCombo from '../../../components/buttons/ModalButtonCombo';
import Checkbox from '../../../components/inputs/Checkbox';

//Assets
import check_icon from '../../../assets/icons/check_gradient.png';
import { ErrorContainer, CheckboxWrapper, PageWrapper } from '../../../components/mobile/MobileStyles';

//API & Utils
import PaymentMethodAPI from '../../../api/blytzwallet/PaymentMethod';
import globalUtils from '../../../utils/globalUtils';

//Redux
import { togglePaymentTypes } from '../../../js/actions/invoices';

const AcknowledgeText = styled.div`
  position: absolute;
  top: 0;
  left: 33px;
  font-size: 13px;
  @supports (-webkit-overflow-scrolling: touch) {
    left: 40px;
  }
`

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0px 27px;
  @media(min-width: 768px) {
    justify-content: center;
  }
  img.icon {
    height: 60px;
    width: auto;
  }
  .text {
    margin-left: 7px;
    margin-top: 14px;
    color: #787878;
    font-size: 18px;
  }
`

class AddBank extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
    this.myRefs = {
      routing_number: React.createRef(),
      account_number: React.createRef(),
      confirm_account_number: React.createRef(),
      nickname: React.createRef()
    };

  }

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
        [name]: value
    });
  }

  componentDidMount = () => {
    this.account_id = this.props.ach_info.ach_id;
    this.signature = this.props.ach_info.ach_signature;
    window.BluePay.setCredentials(this.account_id, this.signature);
  }

  goBack = () => {
    history.goBack();
  }

  validateBank = (response, error) => {
    if (response && response.STATUS !== '1') {
      this.setState({errors: response.MESSAGE})
      return;
    }
    if (response && response.TRANS_ID) {
      this.setState({token: response.TRANS_ID}, () => {
        this.addBank();
      })
    }
  }

  addBank = async () => {
    let account = Object.assign({}, this.state)
    account.token = this.state.token;
    delete account.account_number;
    delete account.confirm_account_number;

    let response = await PaymentMethodAPI.addPaymentMethod(account, this.props.auth_token, this.props.user_pin);
    if (!response.err) {
      if (!this.props.from_invoice){
        this.props.getPaymentOptions()
      }
      this.props.togglePaymentTypes(false);
    }
    if (response.err && response.err.response.data.detail) {
      this.setState({
        errors: response.err.response.data.detail
      })
      window.scroll(0,0);
    }
    if (response.err && response.err.response.data.error) {
      this.setState({
        errors: response.err.response.data.error
      })
      window.scroll(0,0);
    }
  }

  encryptBankInfo = async () => {
    if (globalUtils.checkValues(this.myRefs) === false) {
      return;
    }
    if (!this.state.routing_number || !this.state.account_number || !this.state.nickname || !this.state.acknowledge) {
      this.setState({ errors: 'Please complete all required fields'});
      window.scroll(0,0);
      return;
    }
    if (this.state.account_number !== this.state.confirm_account_number) {
      this.setState({ errors: 'Account numbers do not match'});
      window.scroll(0,0);
      return;
    }

    let encrypted = {
      'ACH_ACCOUNT_TYPE': 'C',
      'ACH_ACCOUNT': this.state.account_number,
      'ACH_ROUTING': this.state.routing_number,
      'PAYMENT_TYPE': 'ACH'
    }
    window.BluePay.createToken(encrypted, this.validateBank, {debug: true})
  }

  render() {
    return (
      <PageWrapper>
        <Breadcrumb close={this.props.toggleAddBank}/>
        <ErrorContainer>
          <ErrorHandle data={this.state.errors}/>
        </ErrorContainer>
        <TopContainer>
          <img src={check_icon} className="icon" alt="add bank account"/>
          <div className="text">Bank Account</div>
        </TopContainer>
        <InputWrapper>
          <Label label="Bank Routing Number"/>
          <Input className="input"
                type="tel"
                name="routing_number"
                data-encryped-name="ACH_ROUTING"
                onChange={this.onChange.bind(this)}
                required
                ref={this.myRefs.routing_number}/>
        </InputWrapper>
        <InputWrapper>
          <Label label="Bank Account Number"/>
          <Input className="input"
                type="tel"
                name="account_number"
                data-encryped-name="ACH_ACCOUNT"
                onChange={this.onChange.bind(this)}
                required
                ref={this.myRefs.account_number}/>
        </InputWrapper>
        <InputWrapper>
          <Label label="Confirm Account Number"/>
          <Input className="input"
                type="tel"
                name="confirm_account_number"
                onChange={this.onChange.bind(this)}
                required
                ref={this.myRefs.confirm_account_number}/>
          <div className="has-error">{this.state.error}</div>
        </InputWrapper>
        <InputWrapper>
          <Label label="Account Nickname"/>
          <Input className="input"
                name="nickname"
                onChange={this.onChange.bind(this)}
                ref={this.myRefs.nickname}
                required/>
        </InputWrapper>
          <CheckboxWrapper>
            <Checkbox
              name="acknowledge"
              data-test-id="acknowledge"
              checked={this.state.acknowledge}
              onChange={this.onChange.bind(this)}/>
            <AcknowledgeText>
              I am authorized to use this payment method.
            </AcknowledgeText>
          </CheckboxWrapper>
        <ModalButtonCombo onClose={ () => this.props.togglePaymentTypes(false) } submit={this.encryptBankInfo}></ModalButtonCombo>
      </PageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ach_info: state.merchants.ach_info,
    from_invoice: state.invoices.from_invoice,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    togglePaymentTypes: (bool) => {dispatch(togglePaymentTypes(bool))}
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBank)
