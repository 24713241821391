// import React from 'react';
import styled from 'styled-components';

export const ScrollContainer = styled.div`
  position: fixed;
  margin-top: 10px;
  height: auto;

  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  input {
    height: 30px;
  }
`;

export const ErrorContainer = styled.div`
margin-top: 50px;
text-align: center;
`;

export const CheckboxWrapper = styled.div`
  margin: 10px 0 0 12px;
  padding: 0px 10px;
  position: relative;
`;

export const PageWrapper = styled.div`
  height: auto;
  input {
    font-size: 16px;
  }
  .has-error {
    color: #FD7272;
    text-align: left;
    margin-top: 5px;
  }
`;
