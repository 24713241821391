import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import globalUtils from '../../utils/globalUtils';

const AvatarContainer = styled.div`
	height: 115px;
	width: 115px;
	font-size: 50px;
	@media screen and (min-device-height: 668px) {
		height: 141px;
		width: 141px;
		font-size: 70px;
	}
	border-radius: 50%;
	border: 1px solid lightgrey;
	background-color: #FFFFFF;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #8D97AD;
	@media (max-width: 330px) {
			height: 100px;
			width: 100px;
			font-size: 40px;
	}
`;

function CustomerInitials(props) {
	if (props.business) {
		return (
			<AvatarContainer>
					{globalUtils.getFirstLetter(props.business)}
			</AvatarContainer>
		)
	} else if(props.first_name && props.last_name) {
		return(
			<AvatarContainer>
				{globalUtils.getUserInitials(props.first_name, props.last_name)}
			</AvatarContainer>
		)
	} else return (
        <AvatarContainer>
            {globalUtils.getUserInitials(props.user_info.first_name, props.user_info.last_name)}
        </AvatarContainer>
    )
}

const mapStateToProps = state => {
    return {
        user_info: state.auth.user_info
    }
}

export default connect(mapStateToProps)(CustomerInitials);
