import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';

//Components
import InputWrapper from '../../../components/wrappers/InputWrapper';
import Input from '../../../components/inputs/Input';
import Label from '../../../components/display/Label';
import HideShow from '../../../components/function/HideShow';
import Checkbox from '../../../components/inputs/Checkbox';
import ErrorHandle from '../../../components/function/ErrorHandle';
import ModalButtonCombo from '../../../components/buttons/ModalButtonCombo';
import Breadcrumb from '../../../components/mobile/display/MobileBreadcrumb';
import HandleOutsideClick from '../../../components/function/HandleOutsideClick';
import ExpirationDate from '../../../components/inputs/ExpirationDate';
import AddCardInputWrapper from '../../../components/inputs/AddCardInputWrapper';
import CardConnect from '../../../utils/CardConnect';

//Assets
import credit_card from '../../../assets/icons/card_gradient.png';
import { CheckboxWrapper } from '../../../components/mobile/MobileStyles';
import { color_nav_border } from '../../../styles/colors';

//Redux
import { togglePaymentTypes, setSplitMethod } from '../../../js/actions/invoices';

//API & Utils
import globalUtils from '../../../utils/globalUtils';
import PaymentMethodAPI from '../../../api/blytzwallet/PaymentMethod';

const Wrapper = styled.div`
  height: 100%;
  padding-top: 50px;
  font-family: system-ui;
`;

const CardInputWrapper = styled.div`
  height: 40px;
  width: 100%;
  border: 2px solid ${color_nav_border};
  font-size: 20px;
  text-indent: 5px;
  border-radius: 5px;
  z-index: 2;
`;

const ErrorContainer = styled.div`
  text-align: center;

`;

const FlexInput = styled.div`
  display: flex;
  position: relative;
  justify-content: space-around;
  width: 90%;
  margin: 0 auto;
`

const Icon = styled.img`
  height: 15px;
  width: 15px;
  position: absolute;
  top: 1px;
  left: 64px;
`

const CreditCard = styled.img`
  width: 35px;
  height: auto;
  position: absolute;
  right: 32px;
  top: 32px;
`

const TooltipWrapper = styled.div`
  width: 100%;
  position: relative;
`

const CssTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fdf5e9;
  position: absolute;
  bottom: -105px;
  right: 25px;
`

const Tooltip = styled.div`
  background-color: #fdf5e9;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  height: 160px;
  box-sizing: border-box;
  position: absolute;
  top: 166px;
  z-index: 1;
  left: 15px;

  @media (max-width: 580px) {
    width: 95%
  }
`

const Amex = styled.img`
  height: 80px;
  width: auto;
  position: absolute;
  z-index: 9;
  top: 67px;
  left: 40px;
`

const OtherCards = styled.img`
  height: 80px;
  width: auto;
  position: absolute;
  z-index: 9;
  top: 67px;
  right: 45px;
`

const CvvText = styled.div`
  text-align: left;
  padding: 10px 20px;
  margin-bottom: 20px;
`

const AcknowledgeText = styled.div`
  position: absolute;
  top: 0;
  left: 35px;
  font-size: 13px;
  @supports (-webkit-overflow-scrolling: touch) {
    left: 40px;
  }
`

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0px 27px;
  @media(min-width: 768px) {
    justify-content: center;
  }
  img.icon {
    height: 50px;
    width: auto;
  }
  .text {
    margin-left: 7px;
    margin-top: 10px;
    color: #787878;
    font-size: 18px;
  }
`

class AddCardWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      showTooltip: false,
      invoice: this.invoice,
      errors: [],
      card: {}
    };

    this.myRefs = {
      name: React.createRef(),
      nickname: React.createRef(),
      expiration: React.createRef(),
      address_zip: React.createRef(),
    }
  };

  toggleTooltip = () => {
    this.setState(prevState => {
      return {
        showTooltip: !prevState.showTooltip
      };
    });
  };

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let newCard = Object.assign({}, this.state.card);
    newCard[name] = value;
    this.setState({
      card: newCard
    });
  }

  renderIcon = () => {
    if (typeof this.state.card === "undefined"){
      return;
    }
    if (this.state.card.card_type === "visa") {
      return <CreditCard src="https://s3.amazonaws.com/blytz/assets/visa.svg"/>
    } else if (this.state.card.card_type === "mastercard") {
      return <CreditCard src="https://s3.amazonaws.com/blytz/assets/mastercard.svg"/>
    } else if (this.state.card.card_type === "amex") {
      return <CreditCard src="https://s3.amazonaws.com/blytz/assets/amexIcon.svg"/>
    } else if (this.state.card.card_type === "discover") {
      return <CreditCard src="https://s3.amazonaws.com/blytz/assets/discover.svg"/>
    }
    else {
      return;
    }
  }

  add_card = async () => {
    if(!this.state.card.token) {
      this.setState({errors: 'Please verify your card number'});
      return
    }
    if (globalUtils.checkValues(this.myRefs) === false) {
      return;
    }

    if (!this.state.card.name || !this.state.card.expiration || !this.state.card.address_zip || !this.state.card.nickname) {
      this.setState({errors: 'Please complete all required fields'});
      window.scroll(0,0);
      return;
    }

    if (!this.state.card.acknowledge) {
      this.setState({errors: 'Authorization checkbox is required'});
      return;
    }

    let response = await PaymentMethodAPI.addPaymentMethod(this.state.card, this.props.auth_token, this.props.user_pin)
    if (response.err && response.err.response.data.detail) {
      this.setState({
        errors: response.err.response.data.detail
      })
      window.scroll(0,0);
    }
    this.reroute(response);

  }


  reroute = (resp) => {
    if (resp.payment_method_id){
      if(this.props.split) {
        this.props.setSplitMethod({
          format_card: resp.format_card,
          id: resp.payment_method_id,
          type: resp.card_type,
          nickname: resp.nickname
        })
      }
      this.props.getPaymentOptions()
      this.props.togglePaymentTypes(false)
    }
  }

  setToken = (token) => {
    if (token && token.validationError) {
      this.setState({ errors: token.validationError})
      return;
    } else {
      let newCard = globalUtils.checkCardType(token.substring(1,2), this.state.card);
      newCard.token = token;
      newCard.last4 = token.slice(-4);
      this.setState({
        card: newCard,
        errors: null
      })
    }
  }


  render() {
    return (
        <Wrapper>
          <Breadcrumb close={this.props.toggleAddCard} page="Add New Card"/>
          <ErrorContainer>
            <ErrorHandle data={this.state.errors}/>
          </ErrorContainer>
          <TopContainer>
            <img src={credit_card} className="icon" alt="card icon"/>
            <div className="text">Credit/Debit</div>
          </TopContainer>
          <TooltipWrapper>
            <HideShow show={this.state.showTooltip}>
            <HandleOutsideClick show={this.toggleTooltip}context="add-card">
              <Tooltip tall>
                <CvvText>The CVV can be found on the back of Visa, Discover and Mastercard. It is generally found on the front of American Express cards.</CvvText>
                <Amex src="https://s3.amazonaws.com/blytz/assets/amex.svg"/>
                <OtherCards src="https://s3.amazonaws.com/blytz/assets/back_of_cards.svg"/>
              </Tooltip>
              <CssTriangle/>
              </HandleOutsideClick>
            </HideShow>
          </TooltipWrapper>
          <InputWrapper addCard>
            <Label label="Card Number"/>
            <CardInputWrapper>
              <CardConnect
                className="input"
                card={true}
                type="tel"
                name="card_number"
                options={{creditCard: true}}
                autoComplete="cc-number"
                required
                ref={this.myRefs.card_number}
                setToken={this.setToken}/>
              {this.renderIcon()}
            </CardInputWrapper>
          </InputWrapper>
          <InputWrapper addCard>
            <Label label="Name on Card"/>
            <Input className="input"
                  payment_method
                  name="name"
                  onChange={this.onChange.bind(this)}
                  required
                  ref={this.myRefs.name}
                  autoComplete="cc-name"/>
          </InputWrapper>
          <InputWrapper addCard>
            <Label label="Card Nickname"/>
            <Input className="input"
                  payment_method
                  name="nickname"
                  required
                  onChange={this.onChange.bind(this)}
                  ref={this.myRefs.nickname}/>
          </InputWrapper>
          <FlexInput>
            <AddCardInputWrapper>
              <Label label="Expiration" addCard/>
              <ExpirationDate
                  options={{date: true, datePattern: ['m','y']}}
                  type="tel"
                  className="input"
                  name="expiration"
                  data-test-id="expiration"
                  onChange={this.onChange.bind(this)}
                  autoComplete="cc-exp"
                  required
                  ref={this.myRefs.expiration}
              />
            </AddCardInputWrapper>
            <AddCardInputWrapper>
              <Label label="Zip" addCard/>
              <Input className="input"
                    name="address_zip"
                    type="tel"
                    onChange={this.onChange.bind(this)}
                    required
                    maxLength={5}
                    ref={this.myRefs.address_zip}
              />
            </AddCardInputWrapper>
              <AddCardInputWrapper>
              <Label label="CVV" addCard/>
              <Input className="cvv input"
                    name="card_csv"
                    type="tel"
                    onChange={this.onChange.bind(this)}
                    value={this.state.card_csv}
                    maxLength={4}
                    autoComplete="cc-csc"
                    required
                    ref={this.myRefs.card_csv}/>
              <Icon src="https://s3.amazonaws.com/blytz/assets/info.svg" data-test-id="add-card-toggle-tooltip" onClick={this.toggleTooltip} alt=""/>
            </AddCardInputWrapper>
          </FlexInput>
        <CheckboxWrapper>
          <Checkbox
            name="acknowledge"
            data-test-id="acknowledge"
            checked={this.state.acknowledge}
            onChange={this.onChange.bind(this)}/>
          <AcknowledgeText>
            I am authorized to use this payment method.
          </AcknowledgeText>
        </CheckboxWrapper>
        {/*<CheckboxWrapper>
          <Checkbox
            name="preferred"
            checked={this.state.card.preferred}
            onChange={this.onChange.bind(this)}/>
          <AcknowledgeText>
            Set as preferred payment method.
          </AcknowledgeText>
        </CheckboxWrapper>*/}
        <br/>
        <ModalButtonCombo addCard onClose={ () => this.props.togglePaymentTypes(false) } submit={this.add_card}></ModalButtonCombo>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id,
    payment_slot: state.invoices.payment_slot
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    togglePaymentTypes: (bool) => {dispatch(togglePaymentTypes(bool))},
    setSplitMethod: (payment_method) => {dispatch(setSplitMethod(payment_method))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCardWrapper)
