import React, {Component} from 'react';
import styled from 'styled-components';

const CardRow = styled.div`
  height: 75px;
  width: 100%;
  position: relative;
  display: flex;
  line-height: 3.5;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => props.active ? '#DDEFE3' : '#fff'};
  &:nth-child(even) {
    background-color: #F7F7F7;
    background-color: ${props => props.active ? '#DDEFE3' : '#F7F7F7'}
  }
`

const CardIcon = styled.img`
  position: absolute;
  height: 30px;
  width: auto;
  left: 30px;
  top: 22px;
`

const CardNumber = styled.div`
  position: absolute;
  top: 10px;
  right: 87px;
`

export default class CardRowWrapper extends Component {

  render() {
    this.color = ""
    if (this.props.active === true){
      this.color = "#64ABF5"
    }
    var style = {backgroundColor: this.color}
    return (
      <CardRow onClick={this.props.setActive} {...this.props} style={style}>
          <CardIcon src={this.props.cardType}/>
          <CardNumber>{this.props.cardNumber}</CardNumber>
      </CardRow>
    )
  }
}
