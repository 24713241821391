import React, {Component} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

// Components
import TextArea from '../../../components/fields/TextArea';
import HideShow from '../../../components/function/HideShow';
import DoubleToggle from '../../../components/buttons/DoubleToggle';

// Assets
import sms_icon from '../../../assets/icons/message_icon_gray.png';
import email_icon from '../../../assets/icons/email_icon_gray.png';
import send_icon from '../../../assets/icons/send.png';
import { color_toggle_disabled } from '../../../styles/colors';

// API & Utils
import messagesAPI from '../../../api/messages/messagesAPI';
import globalUtils from '../../../utils/globalUtils';

const Container = styled.div`
  height: auto;
  /* border-right: 1px solid gainsboro; */
  .opted-out {
    padding: 10px 30px;
  }
  .icon-container {
    display: flex;
    justify-content: flex-end;
    border-bottom: 2px solid #EEE;
    padding-bottom: 15px;
    margin: 0 auto;
    width: 93%;
    img.icon {
      height: 35px;
      width: 75px;
      cursor: pointer;
    }
  }
  .message-container {
    display: flex;
    flex-direction: column-reverse;
    /* height: ${state => state.showGrid ? '400px' : '500px'};
    border-bottom: ${state => state.showGrid ? '2px solid #EEE' : '0px'}; */
    height: 500px;
    border-bottom: 0px;
    padding-bottom: 15px;
    margin: 0 auto;
    width: 93%;
    overflow: auto;
    padding-top: 20px;
    &-customer-detail {
      height: 433px;
    }
    @supports (-moz-appearance:none) {
      overflow-y: scroll;
      transform: scaleY(1);
    }
    .messages {
      @supports (-moz-appearance:none) {
        overflow: auto;
        margin-top: auto;
      }
    }
    .no-messages {
      text-align: center;
      font-size: 22px;
      padding-bottom: 215px;
    }
    .griddle-filter {
      display: none;
    }
    .sc-gFaPwZ.icecFF {
      margin-bottom: 170px;
      .cFpHWP {
        padding: 0px;
      }
    }
    ::-webkit-scrollbar {
      width: 11px;
    }
    ::-webkit-scrollbar-thumb:vertical {
      margin: 11px;
      background-color: #E5E5EA;
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
       background-color: white;
       border: 1px solid #DDD;
       border-radius: 5px;
    }
    .sms-sender { 
      white-space: nowrap;
    }
    .sms {
      clear: both;
      font-size: 18px;
      font-size: 18px;
      line-height: 1.4;
      max-width: 60%;
      border-radius: 17px;
      padding: 13px;
      position: relative;
      word-wrap: break-word;
      margin-bottom: 30px;
      margin-top: 15px;

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: -3px;
        width: 10px;
        height: 11px;
      }
      &-message-body {
        min-height: 40px;
        float: right;
        color: #fff;
        background-color: #6C768C;
        margin-right: 40px;
        opacity: .7;

        &:after {
          right: 0;
          background: #6C768C; 
          border-bottom-left-radius: 10px;
          transform: rotate(-40deg);
        }

        img.medium-icon {
          right: -37px;
          bottom: 7px;
        }
      }

      img.medium-icon {
        height: 27px;
        width: auto;
        position: absolute;
      }

      &-message-received-body {
        min-height: 20px;
        float: left;
        color: #000;
        background-color: #E5E5EA;
        margin-right: 33px;
        margin-left: 46px;

        &:after {
          left: 0px;
          background: #E5E5EA;
          border-bottom-right-radius: 10px;
          transform: rotate(40deg);
        }

        img.medium-icon {
          left: -42px;
          bottom: 7px;
        }

      }
    }
  }
  .text-email {
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .toggle-container {
    margin: 7px 0 7px 30px;
  }

  .toggle-center {
    margin: 7px 44px;
  }

  .text-area-container {
    position: relative;
    margin-top: -10px;
    .text-area {
      margin-bottom: 10px;
      padding: 5px;
      width: 89%;
      height: 100px;
      resize: none;
      border-radius: 10px;
      border: 1px solid gainsboro;
      margin-left: 15px;
    }
    .short {
      width: 50%;
    }
    .disabled {
      background-color: ${color_toggle_disabled};
    }

    img.send-icon {
      height: 40px;
      width: auto;
      position: relative;
      cursor: pointer;
      left: 2px;
      bottom: 40px;
      @media (max-height: 700px){
        height: 33px;
      }
    }
  }
  .close {
    text-align: center;
    margin-top: 5px;
    color: #FD7272;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
  }

  .automation-box {
    display: inline-block;
    margin-top: -10px;
    position: relative;
    bottom: 10px;
    left: 16px;

    &-title {
      color: #6C768C;
      font-size: 20px;
      text-align: center;
      padding-bottom: 10px;
    }

    &-contents {
      border-radius: 10px;
      border: 1px solid gainsboro;
      width: 270px;
      height: 100px;
      overflow: auto;
      ::-webkit-scrollbar {
        width: 7px;
      }
      ::-webkit-scrollbar-thumb:vertical {
        margin: 11px;
        background-color: #6CA2DC;
        border-radius: 5px;
        -webkit-border-radius: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: white;
        border: 1px solid #DDD;
        border-radius: 5px;
      }
      .blue-text {
        padding: 10px 10px;
        padding-top: 0px;
        text-align: center;
        color: #6CA2DC;
        font-size: 15px;
      }
      .toggle {
        display: flex;
        justify-content: center;
        height: 100px;
      }
      .draggable-container {
        overflow: auto;
        .draggable-items {
          cursor: pointer;
          text-align: center;
          color: #5D5D5D;
          font-size: 18px;
          margin-bottom: 8px;
          user-select: all;
          padding: 2px;
          transition: all .2s ease-out;
          &::selection {
            color: #000;
          }
          &:hover {
            background-color: #E5E5EA;
            color: black;
          }
        }
      }

    }
  }
`

const MessageContain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: relative;
  .sms-date {
    bottom: 2px;
    right: 40px;
    text-align: right;
  }

  .sms-sender {
    bottom: 15px;
    right: 40px;
    text-align: right;
  }

  .sms-date-and-sender {
    color: #8E8E93;
    position: absolute;
    color: #8E8E93;	
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.07px;
    line-height: 13px;
    text-align: right;
    width: calc(100% - 20px);
  }
`

const ReceivedContain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;
  .sms-date {
    bottom: 2px;
    left: 40px;
    text-align: left;
  }

  .sms-sender {
    bottom: 15px;
    left: 40px;
    text-align: left;
  }

  .sms-date-and-sender {
    color: #8E8E93;
    position: absolute;
    color: #8E8E93;	
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.07px;
    line-height: 13px;
    text-align: left;
    width: calc(100% - 40px);
  }
`

const Divider = styled.hr`
  width: 95%;
  border: 1px solid #EEEEEE;
`;


export default class ChatBox extends Component {
  constructor(props){
    super(props);
    this.state = {
      showTextArea: true,
      activeMessage: null,
      automation: 'values',
      body: ''

    };

  }

  componentDidMount() {
    this.chatRefresh = window.setInterval(this.props.getData, 5000)
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { message } = nextProps;
    return { activeMessage: message, message};
  }

  componentWillUnmount() {
    if (this.chatRefresh) {
      window.clearInterval(this.chatRefresh)
    }
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  onDoubleClick = (event, value) => {
    let current_body = this.state.body;
    this.setState({ body: current_body + ' ' +  value + ' '});
  }

  returnTemplates = () => {
    let templatesArray = [];
    if(this.state.automation === 'templates' && this.props.templates.length > 0){
      for (let i in this.props.templates){
        templatesArray.push(<div key={`template-${i}`} className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, this.props.templates[i].body)}>{this.props.templates[i].name}</div>)
      }
    }
    return templatesArray
  }

  showSendButton = () => {
    if(!this.props.sms_disabled || !this.props.email_disabled) {
      return <img src={send_icon} className="send-icon" data-test-id="chatbox-send-msg" onClick={this.sendMessage} alt="send message" autoFocus/>
    }
  }

  toggleAutomation = () => {
    let automation = '';
    if(this.state.automation === 'values') {
      automation = 'templates'
    } else {
      automation = 'values'
    }
    this.setState({automation})
  }

  showTextArea = () => {
    if ( (this.state.showTextArea && this.state.activeMessage && this.state.activeMessage.length > 0) || this.props.customer_detail ) {
      return (
        <div className="text-area-container">
          <TextArea
            id="txtMessageFields"
            className={classNames("text-area", {"disabled": this.props.sms_disabled && this.props.email_disabled}, {"short": this.props.customer_detail})}
            placeholder="create message"
            name="body"
            data-test-id="body"
            onChange={this.onChange}
            value={this.state.body}
            onKeyUp={this.handleEnter}
            disabled={this.props.sms_disabled && this.props.email_disabled}/>
          {this.showSendButton()}
          <HideShow show={this.props.customer_detail}>
            <div className="automation-box">
              <div className="automation-box-title">Message Automation</div>
              <div className={classNames("automation-box-contents", {"disabled": this.props.sms_disabled && this.props.email_disabled})}>
                <HideShow show={!this.props.sms_disabled || !this.props.email_disabled}>
                  <div className="toggle-center">
                    <DoubleToggle
                      width="180"
                      option_one={'Values'}
                      option_one_active={this.state.automation === 'values'}
                      option_two={'Templates'}
                      option_two_active={this.state.automation === 'templates'}
                      toggle={this.toggleAutomation}/>
                  </div>
                  <HideShow show={this.state.automation === 'values'}>
                    <div className="blue-text">Double click to add field</div>
                  </HideShow>
                  <div className="draggable-container">
                    <HideShow show={this.state.automation === 'values' && !this.props.is_business}>
                      <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{user_first_name}')}>{'{user_first_name}'} </div>
                      <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{user_last_name}')}>{'{user_last_name}'} </div>
                      <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{user_full_name}')}>{'{user_full_name}'} </div>
                      <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{merchant_name}')}>{'{merchant_name}'} </div>
                    </HideShow>
                    <HideShow show={this.state.automation === 'values' && this.props.is_business}>
                      <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{user_business_name}')}>{'{user_business_name}'} </div>
                      <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{merchant_name}')}>{'{merchant_name}'} </div>
                    </HideShow>
                    <HideShow show={this.state.automation === "templates"}>
                      {this.returnTemplates()}
                    </HideShow>
                  </div>
                </HideShow>
              </div>
            </div>
          </HideShow>
        </div>
      )
    }
    // else {
    //   return (<div className="opted-out">Customer has opted out of receiving text and email messages.</div>)
    // }
  }


  handleEnter = (event) => {
    if(event.key === "Enter") {
      this.sendMessage(event)
    }
  }

  sendMessage = async (event) => {
    let medium = ''
    if(this.props.customer_detail) {
      medium = this.props.medium
    } else {
      if (this.state.email) {
        medium = 'email';
      } else {
        medium = 'sms'
      }
    };

    if (!event.detail || +event.detail === 1){
      let response = await messagesAPI.sendMessage({medium: medium, sub_id: this.props.activeSub, body: this.state.body}, this.props.auth_token)
      if (response.err && response.err.response) {
        this.setState({errors: response.err.response.data.detail})
        return
      }
      this.setState({ body: ''});
      this.props.getData()
    }
  }

  returnMessages = () => {
    let messages = this.state.activeMessage;
    let newArray = [];
    if (this.state.activeMessage && this.props.loaded) {
      for (let i in messages) {
        if (messages[i].type === 1) {
          newArray.push(
            <MessageContain>
              <div className="sms sms-message-body" key={messages[i].id} id={messages[i].id}>
                <img src={messages[i].medium === 1 ? sms_icon : email_icon} className="medium-icon" alt="message icon"/>
                {messages[i].body}
              </div>
              <div className="sms-sender sms-date-and-sender">{messages[i].sent_from ? messages[i].sent_from : ''}</div>
              <div className="sms-date sms-date-and-sender">{globalUtils.formatLongDateAndTime(messages[i].created_at)}</div>
            </MessageContain>
            )
        }
        if (messages[i].type === 2) {
          newArray.push(
            <ReceivedContain>
              <div className="sms sms-message-received-body" key={messages[i].id} id={messages[i].id}>
                <img src={messages[i].medium === 1 ? sms_icon : email_icon} className="medium-icon" alt="message icon"/>
                {messages[i].body}
              </div>
              <div className="sms-sender sms-date-and-sender">{messages[i].sent_from ? messages[i].sent_from : ''}</div>
              <div className="sms-date sms-date-and-sender">{globalUtils.formatLongDateAndTime(messages[i].created_at)}</div>
            </ReceivedContain>
            )
        }
      }
      return newArray;
    } else {
      if(!this.props.customer_detail) {
        return (<div className="no-messages">No Conversation Selected</div>)
      }
    }
  }

  showMessageControls = () => {
    if(this.props.customer_detail) {
      let { sms_disabled, email_disabled, medium, toggleMedium } = this.props;
      return(
        <>
          <Divider/>
          <div className="toggle-container">
            <DoubleToggle 
              option_one={'Text'}
              option_one_active={medium === 'sms'}
              option_one_disabled={sms_disabled}
              option_two={'Email'}
              option_two_active={medium === 'email'}
              option_two_disabled={email_disabled}
              toggle={toggleMedium}/>
          </div>
        </>
      )
    }
  }

  showCloseMessage = () => {
    if (this.props.close && this.props.loaded && this.state.activeMessage){
      return (<div className='close' data-test-id={"chatbox-close-convo-" + this.props.context} onClick={this.props.toggleModal}> Close conversation </div>)
    }
  }

  render() {
    return (
      <Container className="chatbox-container">
          <div className={classNames("message-container", {"message-container-customer-detail": this.props.customer_detail})}>
            <div className="messages">{this.returnMessages()}</div>
          </div>
          {this.showMessageControls()}
          {this.showTextArea()}
          {this.showCloseMessage()}
      </Container>
    )
  }
}
