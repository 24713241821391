import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";

//Components
import Background from '../../components/mobile/MobileBackground';
import ActionButton from '../../components/buttons/ActionButton';

//API & Utils
// import userAPI from '../../api/customer/userAPI';
import customerAuthAPI from '../../api/auth/customerAuthAPI';
import globalUtils from '../../utils/globalUtils';
import history from '../../history';

const Center = styled.div`
  margin-top: 140px;
`

const Container = styled.div`
  background-color: #f1f4f7;
  height: 245px;
  width: 700px;
  margin-top: 160px;
  border-radius: 11px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  padding: 20px 35px;
  position: relative;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 500px;
  }
  @media (max-width: 580px) {
    width: 330px;
  }
  @media (min-device-height: 668px) {
    margin-top: 200px;
  }
  @media screen and (device-aspect-ratio: 40/71) {
    /* iphone 5 only */
    /* height: 320px; */
    width: 300px;
  }  
`

const Text = styled.div`
  color: #656565;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
`

const Header = styled.div`
  font-size: 24px;
  color: #64ABF5;
  margin-top: 40px;
  text-align: center;
  font-weight: bold;
  @media (max-width: 580px) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  @media (max-width: 580px) {
    justify-content: center;
  }
`

class Congratulations extends Component {
  constructor(props){
    super(props);
    this.state = {};
    const values = globalUtils.getQueryString(history.location.search.slice(1))
    this.username = values.username;
  }

  // checkRegistration = () => {
  //   this.props.history.push('/customer/login')
  // }
  
  checkRegistration = async () => {
    let user_data = await customerAuthAPI.getUserActive({username: this.username, merchant_name: this.props.merchant_name})
    if (user_data && user_data.active === 'active'){
      this.props.history.push('/customer/login')
      return
    } else {
      if (user_data.is_business){
        this.props.history.push('/customer/signup/' + this.username + '?merchant_name=' + this.props.merchant_name + '&username=' + this.username + '&is_business=True')
      } else {
        this.props.history.push('/customer/signup/' + this.username + '?merchant_name=' + this.props.merchant_name + '&username=' + this.username)
      }
      return
    }
  }

  render() {

    return (
      <Background fixed show_logo>
        <Center>
          <Container>
            <Header>Congratulations!</Header>
            <Text>You've been opted in to receive messages through BlytzPay®</Text>
            <ButtonWrapper>
              <ActionButton data-test-id="congradulations-done" onClick={this.checkRegistration} red>Done</ActionButton>
            </ButtonWrapper>
          </Container>
        </Center>
      </Background>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    merchant_name: state.merchants.merchant_name
  };
};

export default connect(
  mapStateToProps
)(Congratulations)
