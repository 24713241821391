import React, {Component} from 'react';
import styled from 'styled-components';
import MobileBackground from '../../components/mobile/MobileBackground';
import MobileButton from '../../components/mobile/MobileButton';
import history from '../../history';
import { connect } from 'react-redux';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  .button {
    margin-top: 20px;
    width: 200px;
  }
  @media only screen and (max-device-height: 667px) {
    padding-top: 20px;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    @media (max-device-height: 667px) {
      padding-top: 3px;
    }
  }
  @media (min-height: 736px) {
    padding-top: 50px;
  }
  @media only screen and (min-width: 768px) {
    padding-top: 80px;
  }
`


class MobileLogin extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

routeToMerchant = () => {
  history.push('/login');
}

routeToCustomer = () => {
  history.push(`/customer/login?username=${this.props.username1}`);
}

  render() {
    return (
      <MobileBackground>
        <PageContainer>
          <MobileButton small className="button" data-test-id="mobile-login-merchant" onClick={this.routeToMerchant}>Merchant Login</MobileButton>
          <MobileButton small className="button" data-test-id="mobile-login-customer" onClick={this.routeToCustomer}>Customer Login</MobileButton>
        </PageContainer>
      </MobileBackground>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.auth.username
  };
};

export default connect(
  mapStateToProps
)(MobileLogin);
