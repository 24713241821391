import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'users';
let subscriptions = apiUtils.baseUrl + 'subscriptions/'
class userAPI {
  signup = (userID, data, auth_token, callback) => {
    // let user_pin= data.user_pin
    return apiUtils.patch(url+"/"+userID+"/signup", data, auth_token, callback);
  };
  resetPin = (userID, data, auth_token) => {
    // let user_pin = data.user_pin
    return apiUtils.patch(url+"/"+userID+"/reset_pin", data, auth_token);
  };
  getList = (auth_token, user_pin, callback) => {
    return apiUtils.get(apiUtils.baseUrl + 'subscriptions', auth_token, callback);
  };
  getUserId = (auth_token, callback) => {
    return apiUtils.get(url + "/get_id_by_token", auth_token, "", callback)
  };
  get = (sub_id, auth_token, user_pin, callback) => {
    return apiUtils.get(apiUtils.baseUrl + "subscriptions/" + sub_id, auth_token, callback)
  };
  update_notifications = (sub_id, data, auth_token, user_pin) => {
    return apiUtils.patch(subscriptions + sub_id + "/update_notifications", data, auth_token)
  };
  getAccountTotals = (sub_id, auth_token, user_pin) => {
    return apiUtils.get(apiUtils.baseUrl + "subscriptions/" + sub_id + "/get_account_totals", auth_token)
  };
  updateUser = (user_id, data, auth_token, user_pin, callback) => {
    return apiUtils.patch(url + "/" + user_id, data, auth_token, callback)
  };
  updateCustomer = (data, auth_token, user_pin, callback) => {
    return apiUtils.patch(url + "/update_customer", data, auth_token, callback)
  };
  createUser = (data, auth_token) => {
    return apiUtils.post(url, data, auth_token);
  }
  // createAsBusiness = (data, auth_token) => {
  //   return apiUtils.post(url + "/create_as_business", data, auth_token);
  // }
  inviteUser = (data, auth_token) => {
    return apiUtils.post(apiUtils.baseUrl + "/invite_user", data)
  }
  setAutopay = (data, auth_token) => {
    return apiUtils.patch(apiUtils.baseUrl + "user_invoices/set_autopay", data, auth_token)
  }
  deleteCustomer = (user_id, auth_token) => {
    return apiUtils.delete(`${subscriptions}${user_id}`, auth_token);
  }
}

export default new userAPI();
