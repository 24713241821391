import React, {Component} from 'react';
import styled from 'styled-components';

import ActionButton from './ActionButton';
import HideShow from '../function/HideShow';
import MobileActionButton from './ActionButtonMobile';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: ${props => props.noMargin ? '0 20px 0 20px;' : '20px 20px 0 20px;'};
  padding-bottom: 20px;
  @media (min-device-height: 813px) and (max-device-width: 580px) {
    margin: 20px 20px 0 20px;
  }
  .show-text {
    color: #FF715B;
    text-decoration: underline;
    font-size: 14px;
    margin-top: 8px;
    font-weight: bold;
    cursor: pointer;
  }
`
export default class ModalButtonCombo extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  renderPrimaryButton = () => {
    if (this.props.mobile) {
      return (
        <MobileActionButton primary="true" onClick={this.props.onClose}>{this.props.yesNo ? 'No' : 'Cancel'}</MobileActionButton>
      )
    } else if (this.props.custom) {
      return (
        <ActionButton primary="true" onClick={this.props.support ? this.props.startPrequal : this.props.onClose} small teal={this.props.teal}>{this.props.first}</ActionButton>
      )
    } else if (this.props.processing) {
      return
    }
    else {
      return (
        <ActionButton primary="true" onClick={this.props.support ? this.props.startPrequal : this.props.onClose} small>{this.props.yesNo ? 'No' : this.props.support ? 'PreQual' : 'Cancel'}</ActionButton>
      )
    }
  }

  renderSecondaryButton = () => {
    if (this.props.mobile) {
      return (
        <MobileActionButton secondary onClick={this.props.submit}>Yes</MobileActionButton>
      )
    } else if (this.props.custom) {
      return (
        <ActionButton secondary onClick={this.props.submit} small teal={this.props.teal}>{this.props.second}</ActionButton>
      )
    } else if (this.props.processing) {
      return (
        <ActionButton secondary small>Processing</ActionButton>
      )
    }
    else {
      return (
        <ActionButton className="pysubmit" secondary onClick={this.props.submit} small>{this.props.yesNo ? 'Yes' : this.props.support ? 'Full App' : 'Submit'}</ActionButton>
      )
    }
  }

  render() {
    return (
      <ButtonWrapper {...this.props}>
        <HideShow show={!this.props.hide_cancel}>
          {this.renderPrimaryButton()}
        </HideShow>
        <HideShow show={this.props.show_text}>
          <div primary onClick={this.props.text_click} className="show-text">{this.props.show_text}</div>
        </HideShow>
        {this.renderSecondaryButton()}
      </ButtonWrapper>
    )
  }
}
