import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import history from '../../../history';

//Components
import Table from '../../../components/tables/Table';
import Tab from '../../../components/display/Tab';
import HideShow from '../../../components/function/HideShow';
// import GroupsButton from '../../../components/buttons/GroupsButton';
import GroupModal from '../../../components/modals/groups/GroupsModal';
import GroupDetail from '../groups/groupDetail';
// import CustomerSelectToolbar from './CustomerSelectToolbar';
import UploadCustomerToolbar from './UploadCustomerToolbar';

//API & Utils
import userAPI from '../../../api/customer/userAPI';
import merchantAPI from '../../../api/merchant/merchantAPI';
import globalUtils from '../../../utils/globalUtils';
import groupsAPI from '../../../api/groups/groupsAPI';

//Assets
import grey_email from '../../../assets/icons/email_icon_gray.png';
import blue_email from '../../../assets/icons/blueEmail.png';
import blue_phone from '../../../assets/icons/phone_icon_blue.png';
import blue_sms from '../../../assets/icons/message_icon_blue.png';
import grey_sms from '../../../assets/icons/message.png';
import duplicate from '../../../assets/icons/duplicate.png';
import edit from '../../../assets/icons/edit_gray.png';
import group_message from '../../../assets/icons/group_message.png';

const PageWrapper = styled.div`
  min-width: 600px;
  width: 98%;
  height: 160px;
  background-color: #fff;
  position: relative;
  h6 {
    display: none;
  }
  .icon-container {
    position: relative;
    img {
      position: absolute;
      height: 45px;
      width: auto;
      top: -58px;
      left: 20px;
      cursor: pointer;
    }
    span {
      position: absolute;
      top: -19px;
      left: 26px;
      color: #74aaef;
      font-size: 12px;
    }
  }
  .page {
    font-size: 25px;
    color: #59637A;
    position: relative;
    padding: 10px 10px 10px 0;
    margin-bottom: 20px;
    margin-left: 20px;

  }
  .tab-container {
    display: flex;
    width: 50%;
    padding-bottom: 10px;
    border-bottom: 2px solid #ACB3C2;
    margin-left: 20px;
  }
  .button-container {
    position: absolute;
    top: 115px;
    left: 20px;
    z-index: 10;
  }
  .modal-container {
    margin-left: 20px;
  }
  .icon {
    height: 15px;
    width: auto;
    margin-right: 10px;
    cursor: pointer;
  }
`;

const groupColumns = [
  {
    name: "name",
    label: "Group Name",
    options: {
      filter: true,
      sort: true,
      sortDirection: 'asc',
    }
  },
  {
    name: "description",
    label: "Description",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "",
    label: "",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (tableMeta.rowData) {
          return (
            <>
              <img src={group_message} alt="group message" className="icon"/>
              <img src={duplicate} alt="duplicate group" className="icon"/>
              <img src={edit} alt="edit group" className="icon"/>
            </>
          )
        }
      },
    }
  },
  {
    name: "id",
    label: "ID",
    options: {
    filter: true,
    sort: false,
    display: false
    }
  },
  {
    name: "is_active",
    label: "Active?",
    options: {
    filter: false,
    sort: false,
    display: false
    }
  },
  {
    name: "conditions",
    label: "Conditions",
    options: {
    filter: false,
    sort: false,
    display: false
    }
  },
  {
    name: "members",
    label: "Members",
    options: {
    filter: false,
    sort: false,
    display: false
    }
  },
]

class CustomersIndexListWrapper extends Component {
  constructor(props){
    super(props);
    this.state = ({
      data: [],
      showAddCustomerModal: false,
      merchant: {
        merchants: []
      },
      showCustomersTable: true,
      showActiveGroup: false
    })

    globalUtils.loginRedirect(props);

    this.getData()
    this.myRefs = {
      first_name: React.createRef(),
      last_name: React.createRef(),
      merchant_id: React.createRef(),
      phone: React.createRef(),
      email: React.createRef(),
      date_of_birth: React.createRef(),
      external_id: React.createRef(),
      address: React.createRef(),
      is_business: React.createRef()
    }
  }

  options = {
    filterType: 'checkbox',
    responsive: 'stacked',
    selectableRows: false,
    onRowClick: (rowData, rowState) => {
      let _id = rowData[7]
      history.push({
        pathname: '/admin/customers/profile/' + _id,
        state: 'test'
      })
    },
    // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
    //   return(
    //     <CustomerSelectToolbar
    //       setSelectedRows={setSelectedRows}
    //       displayData={displayData} 
    //       selectedRows={selectedRows}
    //       listProps={this.props}
    //     />
    //   )
    // },
    customToolbar: () => {
      return(
        <UploadCustomerToolbar/>
      )
    }
  };

  groupOptions = {
    filterType: 'checkbox',
    responsive: 'stacked',
    selectableRows: false,
    viewColumns: false,
    onRowClick: (rowData, rowState) => {
      this.tableSetGroup(rowData)
    },
  };

  customerColumns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: false,
        sort: true,
        sortDirection: 'asc',
      }
    },
    {
      name: "formatted_phone",
      label: "Phone",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "merchant_name",
      label: "Location",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "state",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        setCellProps: (row) => {
          return {
            style: {textTransform: 'capitalize'}
          };
        }
      }
    },
    {
      name: "tags",
      label: "Tags",
      options: {
        filter: true,
        sort: false,
        filterType: 'multiselect',
        filterOptions: {
          names: this.props.merchant_tags.map( tag => tag[1]),
          logic(tags, filters) {
            let show = false;
            let count = 0;

            filters.forEach( filter => {
              if(tags.indexOf(filter) >= 0) {
                count++;
              }
            });

            if(count === filters.length) {
              show = true
            }
            return !show;
          }
        }
      },
    },
    {
      name: "user_external_id",
      label: "External ID",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    }
  ];

  tableSetGroup = (group) => {
    let activeGroup = {
      name: group[0],
      description: group[1],
      id: group[3],
      is_active: group[4],
      conditions: group[5],
      members: group[6]
    }

    this.setState({activeGroup, showActiveGroup: true})
  }

  setActiveGroup = (group) => {
    this.setState({activeGroup: group, showActiveGroup: true})
  }

  refreshGroupData = id => {
    if(this.state.groups && this.state.groups.length > 0) {
      let filteredGroups = this.state.groups.filter( group => group.id === id );
      this.setActiveGroup(filteredGroups[0])
    }
  }

  hideActiveGroup = () => {
    this.setState({showActiveGroup: false, activeGroup: null})
  }

  showGroupDetail = () => {
    if (this.state.showActiveGroup) {
      return (
        <GroupDetail activeGroup={this.state.activeGroup} getData={this.getGroupsData} hideActiveGroup={this.hideActiveGroup} refresh={this.refreshGroupData}/>
      )
    }
  }

  componentDidMount = () => {
    this.getData();
    this.getGroupsData();
    this.getSelf();
  }

  handleFrequencyChange = (event) => {
    if (event.target) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
        [name]: value
    });
    } else {
      this.setState({
        frequency: event.value
      })
    }
  };

  getGroupsData = async () => {
    let groups = await groupsAPI.getList(this.props.auth_token)
    if (groups.err && groups.err.response) {
      this.setState({errors: groups.err.response.data.detail})
      return
    }
    this.setState({
      groups, loaded: true
    })
  }


  getData = async () => {
    let data = await userAPI.getList(this.props.auth_token)
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }
    if (data && data.length > 0) {
      for (let i in data){
        if (data[i].sms_opt_in === 'true' || data[i].sms_opt_in === 'True') {
          data[i].sms_opt_in_icon = blue_sms;
        } else if (data[i].sms_opt_in === 'false' || data[i].sms_opt_in === 'False' ) {
          data[i].sms_opt_in_icon = grey_sms;
        } else {
          data[i].sms_opt_in_icon = grey_sms;
        }
        if (data[i].email_opt_in === 'true' || data[i].email_opt_in === 'True') {
          data[i].email_opt_in_icon = blue_email;
        } else if (data[i].email_opt_in === 'false' || data[i].email_opt_in === 'False') {
          data[i].email_opt_in_icon = grey_email;
        } else {
          data[i].email_opt_in_icon = grey_email;
        }
        data[i].phoneIcon = blue_phone;
      }
    } else {
      data = []
    }
    this.setState({
      data: data, loaded: true
    })
  }

  getSelf = async () => {
    let data = await merchantAPI.getSelf(this.props.auth_token)
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return;
    }
    if(data.merchants && data.merchants.length === 1) {
      this.setState({merchant: data, merchant_id: data.merchants[0].id})
    } else {
      this.setState({
        merchant: data
      })
    }
  }

  rerouteToUpload = () => {
    this.props.history.push('/admin/customers/upload')
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      this.addUser();
    } else {
      return;
    }
  }

  setActive = () => {
    this.setState(prevState => {
      return {
        showCustomersTable: !prevState.showCustomersTable,
        showGroupsTable: !prevState.showGroupsTable,
        showActiveGroup: false,
        activeGroup: undefined
      };
    });
  }

  toggleGroupModal = () => {
    this.setState( prevState => {
      return {
        showGroupModal: !prevState.showGroupModal
      }
    })
  }

  renderGroupModal = () => {
    if (this.state.showGroupModal) {
      return <GroupModal onClose={this.toggleGroupModal} getData={this.getGroupsData} merchant={this.state.merchant}/>
    }
  }

  render() {

    return (
      <PageWrapper>
        <div className="page">Customers</div>
        <div className="tab-container">
          <Tab name="Customers" data-test-id="index-list-customers" onClick={this.setActive} active={this.state.showCustomersTable}/>
          <Tab name="Groups" data-test-id="index-list-groups" onClick={this.setActive} active={this.state.showGroupsTable} width="95px"/>
        </div>
        <HideShow show={this.state.showCustomersTable}>
          <Table title="Customers"
                data={this.state.data}
                className="table"
                showModal={this.showModal}
                rerouteToUpload={this.rerouteToUpload}
                loaded={this.state.loaded}
                columns={this.customerColumns}
                options={this.options}>
          </Table>
        </HideShow>
        <HideShow show={this.state.showGroupsTable && !this.state.activeGroup}>
          <div className="button-container">
            {/* <GroupsButton onClick={this.toggleGroupModal} text="New Group"/> */}
          </div>
          <div className="modal-container">
            {this.renderGroupModal()}
          </div>
          <Table
            data={this.state.groups}
            className="table"
            loaded={this.state.loaded}
            columns={groupColumns}
            options={this.groupOptions}>
          </Table>
        </HideShow>
        {this.showGroupDetail()}


    </PageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    merchant_tags: state.merchants.merchant_tags
  };
};

export default connect(
  mapStateToProps
)(CustomersIndexListWrapper)
