import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//Components
import AdminLink from '../nav/AdminLink';
import AdminSidebarIcon from '../icons/AdminSidebarIcon';
import HandleOutsideClick from '../function/HandleOutsideClick';
import MerchantLogo from '../logos/MerchantLogo';
import HideShow from '../function/HideShow';
import Initials from './Initials';

//Assets
import home_icon from '../../assets/icons/home.png';
import customer_icon from '../../assets/icons/customers.png';
import messaging_icon from '../../assets/icons/messaging.png';
import payments_icon from '../../assets/icons/payments.png';
import invoices_icon from '../../assets/icons/invoices.png';
import automation_icon from '../../assets/icons/automation.png';
import sidebar_image from '../../assets/images/new-background.png';
import blytz_logo from '../../assets/images/logo-gradient.png';
import caliber_icon from '../../assets/images/caliber.png';
//Redux
import { logout } from '../../js/actions/auth';
import { toggleAddCustomer, toggleAddInvoice } from '../../js/actions/modal_actions';
import { storeMerchantName } from '../../js/actions/merchants';

//API
import merchantAPI from '../../api/merchant/merchantAPI';

const AdminHeader = styled.div`
  width: 100%;
  height: 53px;
  padding: 6px 15px;
  box-sizing: border-box;
  position: fixed;
  background-color: white;
  .flex {
    display: flex;
    justify-content: flex-end;
    .merchant-name {
      margin-top: 12px;
      margin-right: 10px;
      font-size: 22px;
      color: #9b9b9b;
    }
  }
`

const NotificationBadge = styled.div`
  position: relative;
  left: 6px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: #FF4B4C;
  color: white;
  font-size: 12px;
  text-align: center;
  padding-top: 6px;
`;

// const UserName = styled.div`
//   color: #DDDDDD;
//   font-size: 18px;
//   margin-top: 6px;
// `

// const MerchantName = styled.div`
//   line-height: 1.50;
//   margin-left: 275px;
//   font-size: 36px;
//   color: #64ABF5;
//   text-transform: uppercase;
//   letter-spacing: 2px;
// `

// const UserInitialsCircle = styled.div`
//   color: white;
//   height: 82px;
//   width: 82px;
//   font-size: 28px;
//   cursor: pointer;
//   background-color: #64ABF5;
//   border-radius: 50px;
//   position: relative;
//   margin-right: 40px;
//   margin-top: 5px;
// `

// const UserInitials = styled.div`
//   position: absolute;
//   top: 24px;
//   left: 25px;
// `

// const Logo = styled.img`
//   margin-top: 3px;
//   width: 150px;
//   max-width: 150px;
//   height: auto;
//   position: absolute;
//   left: 35px;
// `

const AdminSidebar = styled.div`
  height: 100%;
  width: 233px;
  position: fixed;
  left: 0;
  background: url(${sidebar_image}) no-repeat -691px -29px;
`

const InnerContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px;
  margin-top: 185px;
`

const AdminRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  margin-left: 15px;
  cursor: pointer;
  position: relative;
  .badge {
    height: 10px;
    width: 10px;
    border-radius: 15px;
    background-color: #FD7272;
    position: absolute;
    top: 5px;
    left: 5px;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 15px;
  cursor: pointer;
`

const LogoutLink = styled.a`
  color: red;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  &:visited {
    color: red;
  }
`

const ProfileMenu = styled.div`
  height: 108px;
  width: 203px;
  padding: 40px 20px;
  position: relative;
  background-color: white;
  float: right;
  box-shadow: 0px 2px 5px #A8A8A8;
  position: relative;
  top: 24px;
  left: 6px;
  background-color: #64ABF5;
  display: flex;
  flex-direction: column;
`

const LogoutContainer = styled.div`
  height: 40px;
  width: 203px;
  background-color: white;
  float: right;
  position: relative;
  top: 128px;
  left: 209px;
  background-color: white;
  box-shadow: 0px 2px 5px #A8A8A8;
`

const CssTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #64ABF5;
  position: absolute;
  top: -20px;
  right: 7px;
`

const BlytzLogo = styled.img`
  width: 125px;
  height: auto;
  position: absolute;
  top: 484px;
  @media (max-height: 700px){
    top: 422px;
  }
  left: 54px;
`

const BlytzLogoText = styled.div`
  color: white;
  font-size: 14px;
  position: absolute;
  top: 475px;
  cursor: default;
  @media (max-height: 700px){
    top: 410px
  }
  left: 40px;
`

const MenuWrapper = styled.div`
  position: relative;
`

class HeaderWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      showMenu: false,
      unreadMessages: 0,
      merchant: {
        logo: '',
      }
    }
  }

  componentDidMount = () => {
    this.getSelf();
    this.checkIntegrations();
  }

  getSelf = async () => {
    let merchant = await merchantAPI.getSelf(this.props.auth_token);
    if (merchant.err) {
      this.props.logout()
    } else {
      this.props.storeMerchantName(merchant.name)
      this.setState({ merchant: merchant })
    }
  }

  checkIntegrations = () => {
    let value = false;
    for (let i in this.props.integrations) {
      if (this.props.integrations[i] === 'Caliber') {
        value = true;
      }
    }
    return this.setState({caliber: value})
  }

  rerouteToPath = (path) => {
    if(this.props.showAddCustomer) {
      this.props.toggleAddCustomer(false)
    }
    if(this.props.showAddInvoice) {
      this.props.toggleAddInvoice(false)
    }

    this.props.history.push(path)
    this.setState({
      sidebarOpen: false
    })
  }

  reroute = (path) => {

  }

  setMenuVisible = () => {
    this.setState(prevState => {
      return {
        showMenu: !prevState.showMenu,
      };
    });
  }

  showMenu = () => {
    if (this.state.showMenu) {
      return (
        <MenuWrapper>
          <HandleOutsideClick show={this.setMenuVisible} context="admin-header">
            <ProfileMenu>
              <CssTriangle/>
              {/*<MenuLink>Profile</MenuLink>
              <MenuLink>My Reports</MenuLink>
              <MenuLink>Settings</MenuLink>
              <MenuLink>Help</MenuLink>*/}
            </ProfileMenu>
            <LogoutContainer>
              <LogoutLink onClick={this.props.logout}>Log Out</LogoutLink>
            </LogoutContainer>
          </HandleOutsideClick>
        </MenuWrapper>)
    }
  }

  showUnreadMessages = () => {
    let { unreadMessages } = this.state;
    if(unreadMessages) {
      return <NotificationBadge>{unreadMessages <= 10 ? unreadMessages : '10+'}</NotificationBadge>
    }
  }

  render() {
    return (
        <>
        <AdminHeader >
          <div className="flex">
            <div className="merchant-name">{this.state.merchant.name}</div>
            <Initials first_name={this.state.merchant.first_name} last_name={this.state.merchant.last_name} size="small" color="grey-3" onClick={this.setMenuVisible}/>
          </div>
          {this.showMenu()}
        </AdminHeader>
        <AdminSidebar>
          {/* <SidebarImage src={sidebar_image}/> */}
          <LogoWrapper onClick={ () => this.rerouteToPath("/admin/dashboard")}>
            <MerchantLogo showMerchantName={true} logo={this.state.merchant.logo_url} name={this.state.merchant.name} width="95%" height="90px" header/>
          </LogoWrapper>
          <InnerContent>
            <AdminRow onClick={ () => this.rerouteToPath("/admin/dashboard")}>
              <AdminSidebarIcon src={home_icon} alt="home"/>
              <AdminLink link_text="Home" />
            </AdminRow>
            <AdminRow onClick={ () => this.rerouteToPath("/admin/customers") }>
              <AdminSidebarIcon src={customer_icon} alt="customers"/>
              <AdminLink link_text="Customers" />
            </AdminRow>
            <AdminRow onClick={ () => this.rerouteToPath("/admin/conversations") }>
              <AdminSidebarIcon src={messaging_icon} alt="conversations"/>
              <AdminLink link_text="Inbox"  />
              <HideShow show={this.props.inbox_badge}>
                <div className="badge"></div>
              </HideShow>
            </AdminRow>
            {/*<AdminRow onClick={ () => this.rerouteToPath("/admin/message-templates") }>
              <AdminSidebarIcon src={template_icon} alt="message_templates" small/>
              <AdminLink link_text="Templates"/>
            </AdminRow>*/}
            <AdminRow onClick={ () => this.rerouteToPath("/admin/invoices") }>
              <AdminSidebarIcon src={invoices_icon} alt="invoices" small/>
              <AdminLink link_text="Invoices" />
            </AdminRow>
            <AdminRow onClick={ () => this.rerouteToPath("/admin/payments")}>
              <AdminSidebarIcon src={payments_icon} alt="transactions" style={{'marginTop': '5px'}}/>
              <AdminLink link_text="Transactions" />
            </AdminRow>
            {/*<AdminRow onClick={ () => this.rerouteToPath("/admin/reports") }>
              <AdminSidebarIcon src={reports_icon} alt="reports"/>
              <AdminLink link_text="Reports" />
            </AdminRow>*/}

            {/*<AdminRow onClick={ () => this.rerouteToPath("/admin/settings") }>
              <AdminSidebarIcon src={settings_icon} alt="settings"/>
              <AdminLink reroute={this.rerouteToPathToPath} path="/admin/settings" link_text="Settings" />
            </AdminRow>*/}
            <AdminRow onClick={ () => this.rerouteToPath("/admin/automation") }>
              <AdminSidebarIcon src={automation_icon} alt="automation" small/>
              <AdminLink link_text="Automation"  />
            </AdminRow>
            <HideShow show={this.state.caliber}>
              <AdminRow onClick={ () => this.rerouteToPath("/admin/caliber") }>
                <AdminSidebarIcon src={caliber_icon} alt="caliber" small/>
                <AdminLink link_text="Caliber"  />
              </AdminRow>
            </HideShow>
            <BlytzLogoText>powered by</BlytzLogoText>
            <BlytzLogo src={blytz_logo}/>
          </InnerContent>
        </AdminSidebar>
        </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    integrations: state.merchants.integrations,
    auth_token: state.auth.auth_token,
    inbox_badge: state.notifications.inbox_badge,
    showAddCustomer: state.modals.showAddCustomer,
    showAddInvoice: state.modals.showAddInvoice,
    user_info: state.auth.user_info
    };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: (auth_data) => {dispatch(logout(auth_data))},
    toggleAddInvoice: (bool) => {dispatch(toggleAddInvoice(bool))},
    toggleAddCustomer: (bool) => {dispatch(toggleAddCustomer(bool))},
    storeMerchantName: (name) => {dispatch(storeMerchantName(name))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderWrapper))
