import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import history from '../../../history';

//Components
import Table from '../../../components/tables/Table';
import PaymentPlanModal from '../../../components/modals/invoices/PaymentPlanModal';
import InvoicesToolbar from './InvoicesToolbar';

//API & Utils
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';
import merchantAPI from '../../../api/merchant/merchantAPI';
import userAPI from '../../../api/customer/userAPI';
import globalUtils from '../../../utils/globalUtils';


const PageWrapper = styled.div`
  max-width: 98%;
  .CustomUserComponent {
    margin-right: 10px;
  }
`;

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display: false
    }
  },
  {
    name: "full_name",
    label: "Name",
    options: {
    filter: false,
    sort: true
    }
  },
  {
    name: "created_at",
    label: "Created",
    options: {
    filter: false,
    sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      if (tableMeta.rowData) {
        return (
          globalUtils.formatDate(value)
        )
      }
    },
    }
  },
  {
    name: "state",
    label: "Status",
    options: {
    filter: true,
    sort: true,
    setCellProps: (row) => {
        return {
          style: {textTransform: 'capitalize'}
        };
      }
    }
  },
  {
    name: "minimum_amount_due",
    label: "Min Amount Due",
    options: {
    filter: false,
    sort: true,
    customBodyRender: (value, tableMeta, updateValue) => {
      if (tableMeta.rowData) {
        return (
          globalUtils.convertToDollars(value)
        )
      }
    },
    }
  },
  {
    name: "due_date",
    label: "Due Date",
    options: {
    filter: false,
    sort: true,
    customBodyRender: (value, tableMeta, updateValue) => {
      if (tableMeta.rowData) {
        return (
          globalUtils.formatDate(value)
        )
      }
    },
    }
  },
  {
    name: "paid_date",
    label: "Paid Date",
    options: {
    filter: false,
    sort: true,
    customBodyRender: (value, tableMeta, updateValue) => {
      if (tableMeta.rowData) {
        return (
          globalUtils.formatDate(value)
        )
      }
    }
    }
  },
  {
    name: "payment_plan",
    label: "Payment Plan",
    options: {
    filter: false,
    sort: true,
    customBodyRender: (value, tableMeta, updateValue) => {
      if (tableMeta.rowData && tableMeta.rowData[7]) {
        return (
          <a href={"/admin/invoices/payment-plan/" + tableMeta.rowData[7]} data-test-id="yes-to-pmt-plan">Yes</a>
        )
      }
    },
    }
  }
];

class InvoicesIndexListWrapper extends Component {
  constructor(props){
    super(props);
    this.state = ({
      data: [],
      customers: [],
      merchant: [],
      showAddInvoiceModal: false,
      showPlanModal: false
    })
    globalUtils.loginRedirect(props);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount = () => {
    this.getInvoices();
    this.getCustomers();
    this.getSelf();
  }

  options = {
    filterType: 'checkbox',
    responsive: 'stacked',
    selectableRows: false,
    onRowClick: (rowData, rowState) => {
      let _id = rowData[0]
      history.push({
        pathname: '/admin/invoices/detail/' + _id,
        state: 'test'
      })
    },
    onRowsDelete: (rowsDeleted) => {
      let data = this.state.data;
      let rows = rowsDeleted.data;
      rows.forEach((val, row) => {
        data.forEach(async (v,r) => {
          if(val.dataIndex === r) {
            await userInvoiceAPI.deleteInvoice(v.id, this.props.auth_token);
          }
        });
      });
      window.location.reload();
      return false;
    },
    customToolbar: () => {
      return(
        <InvoicesToolbar toggleModal={this.toggleModal}/>
      )
    }
  };

  getCustomers = async () => {
    let customers = await userAPI.getList(this.props.auth_token)
    if (customers.err) {
      this.setState({errors: customers.err.response.data.detail})
      return
    }
    this.setState({
      customers: customers
    })
  }

  getInvoices = async () => {
    let data = await userInvoiceAPI.getMerchantList(this.props.auth_token);
    console.log('invoices', data)
    if (data.invoices && !data.err) {
      this.setState({
        data: data.invoices,
        loaded: true
      })
    }
  }

  getSelf = async () => {
    let merchant = await merchantAPI.getSelf(this.props.auth_token)
    this.setState({
      merchant: merchant
    })
  }

  rerouteToUpload = () => {
    this.props.history.push('/admin/invoices/upload');
  }

  toggleModal = (modalname) => {
    this.setState( prevState => {
      return {
        [modalname]: !prevState[modalname]
      }
    })
  }

  showAddPlanModal = () => {
    if(this.state.showPlanModal) {
    return <PaymentPlanModal onClose={ this.toggleModal }/>
    }
  }

  render() {
    return (
      <PageWrapper>
        {this.showAddPlanModal()}
        <Table title="Invoices"
          data={this.state.data}
          tableClassName="table"
          showFilter={true}
          showSettings={false}
          upload
          rerouteToUpload={this.rerouteToUpload}
          toggleModal={this.toggleModal}
          addPlan
          loaded={this.state.loaded}
          columns={columns}
          options={this.options}
          >
        </Table>
      </PageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

export default connect(
  mapStateToProps
)(InvoicesIndexListWrapper)
