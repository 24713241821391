import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Modal from '../modals/NewModal';
import ToggleSwitch from '../inputs/ToggleSwitch';
import userAPI from '../../api/customer/userAPI';
import { setNotification } from '../../js/actions/notifications';

const OptInWrapper = styled.div`
  margin-left: 10px;
  text-align: left;
  color: #9095A0;
  font-size: 15px;

  input[type=checkbox]::-ms-check {
    display: none;
  }
  @media (max-height: 700px){
    left: -6px;
  }

  .opt-in {
    &__section {
      margin-right: 10px;
    }

    &__toggle {
      display: flex;
      margin-top: 5px;

      &-row {
        margin-bottom: 10px;
        & > * {
          float: left;
          margin-right: 10px;
        }
      }
    }
  }
`;

class OptInInfo extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.sub_id = this.props.match.params.id
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = async () => {
    let sms_opt_in = false;
    let email_opt_in = false;
    let marketing_opt_in = false;
    let customer = await userAPI.get(this.sub_id, this.props.auth_token)
    if (customer.err && customer.err.response) {
      this.props.setNotification({message: customer.err.response.customer.detail, type: 'error'})
      return
    }

    if (customer.sms_opt_in) {
      sms_opt_in = true;
    }
    if (customer.email_opt_in) {
      email_opt_in = true;
    }
    if (customer.marketing_opt_in) {
      marketing_opt_in = true;
    }

    this.setState({customer, sms_opt_in, email_opt_in, marketing_opt_in})
  };

  updateTCPA = async (modal) => {
    let data = {
      sms_opt_in: this.state.sms_opt_in,
      email_opt_in: this.state.email_opt_in,
      marketing_opt_in: this.state.marketing_opt_in
    }

    if (this.state.sms_opt_in === undefined) {
      data.sms_opt_in = this.state.customer.sms_opt_in
    }
    if (this.state.email_opt_in === undefined) {
      data.email_opt_in = this.state.customer.email_opt_in
    }
    if (this.state.marketing_opt_in === undefined) {
      data.marketing_opt_in = this.state.customer.marketing_opt_in
    }

    let response = await userAPI.update_notifications(this.sub_id, data, this.props.auth_token)

    if (response.err) {
      this.props.setNotification({message: response.err.response.data.detail, type: 'error'})
      return
    } else {
      this.props.setNotification({message: 'Successfully updated!'})
      this.closeModal(modal)
      this.getData();
      if (this.props.getUserInfo) {
        this.props.getUserInfo();
      }
    }
  }

  onChange = (event) => {
    if (event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }
  }


  toggleAlert = (modal, event) => {
    if (modal === 'sms' && this.state.sms_opt_in) {
      this.setState({showSmsOptOut: true})
    }
    if (modal === 'sms' && !this.state.sms_opt_in) {
      this.setState({showSmsOptIn: true})
    }
    if (modal === 'email' && this.state.email_opt_in) {
      this.setState({showEmailOptOut: true})
    }
    if (modal === 'email' && !this.state.email_opt_in) {
      this.setState({showEmailOptIn: true})
    }
    if (modal === 'marketing' && this.state.marketing_opt_in) {
      this.setState({showMarketingOptOut: true})
    }
    if (modal === 'marketing' && !this.state.marketing_opt_in) {
      this.setState({showMarketingOptIn: true})
    }
    this.onChange(event)
  }

  closeModal = (modal) => {
    if (modal === 'sms_opt_out') {
      this.setState({ showSmsOptOut: false})
    }
    if (modal === 'sms_opt_in') {
      this.setState({ showSmsOptIn: false})
    }
    if (modal === 'email_opt_out') {
      this.setState({ showEmailOptOut: false})
    }
    if (modal === 'email_opt_in') {
      this.setState({ showEmailOptIn: false})
    }
    if (modal === 'marketing_opt_out') {
      this.setState({ showMarketingOptOut: false})
    }
    if (modal === 'marketing_opt_in') {
      this.setState({ showMarketingOptIn: false})
    }
    this.getData();
  }

  showSmsModal = () => {
    if (this.state.showSmsOptOut) {
      return (
        <Modal context="detail-opt-1" title="Opt-out?" submit={() => this.updateTCPA('sms_opt_out')} modalname="sms_opt_out" onClose={() => this.closeModal('sms_opt_out')} tcpa text="Customer will no longer receive their bills through text messaging. Are you sure?"/>
      )
    }
    if (this.state.showSmsOptIn) {
      return (
        <Modal context="detail-opt-2" title="Opt-in?" submit={() => this.updateTCPA('sms_opt_in')} modalname="sms_opt_in" onClose={() => this.closeModal('sms_opt_in')} tcpa="true" text={`Customer agrees to be contacted by ${this.state.customer.merchant_name} via text message and/or phone call regarding services provided by ${this.state.customer.merchant_name}. Customer understands that they can revoke this permission at any time by contacting ${this.state.customer.merchant_name}.`}/>
      )
    }
  }

  showEmailModal = () => {
    if (this.state.showEmailOptOut) {
      return (
        <Modal context="detail-opt-3" title="Opt-out?" submit={() => this.updateTCPA('email_opt_out')} modalname="email_opt_out" onClose={() => this.closeModal('email_opt_out')} tcpa="true" text="Customer will no longer receive their bills through email. Are you sure?"/>
      )
    }
    if (this.state.showEmailOptIn) {
      return (
        <Modal context="detail-opt-4" title="Opt-in?" submit={() => this.updateTCPA('email_opt_in')} modalname="email_opt_in" onClose={() => this.closeModal('email_opt_in')} tcpa="true" text={`Customer agrees to be contacted by ${this.state.customer.merchant_name} via email regarding services provided by ${this.state.customer.merchant_name}. Customer understands that they can revoke this permission at any time by contacting ${this.state.customer.merchant_name}.`}/>
      )
    }
  }

  showMarketingModal = () => {
    if (this.state.showMarketingOptOut) {
      return (
        <Modal context="detail-opt-5" title="Opt-out?" submit={() => this.updateTCPA('marketing_opt_out')} modalname="marketing_opt_out" onClose={this.closeModal} tcpa="true" text="Customer will no longer receive marketing messages through text and email. Are you sure?"/>
      )
    }
    if (this.state.showMarketingOptIn) {
      return (
        <Modal context="detail-opt-6" title="Opt-in?" submit={() => this.updateTCPA('marketing_opt_in')} modalname="marketing_opt_in" onClose={this.closeModal} tcpa="true" text={`Customer agrees to be contacted by ${this.state.customer.merchant_name} via email and text regarding marketing offers provided by ${this.state.customer.merchant_name}. Customer understands that they can revoke this permission at any time by contacting ${this.state.customer.merchant_name}.`}/>
      )
    }
  }

  renderData = () => {
    if (this.state && this.state.customer) {
      return (
        <OptInWrapper>
            <div className="opt-in__section">
              <div className="opt-in__toggle">
                <div className="opt-in__toggle-row">
                  <span className="opt-in__heading">Invoicing: </span>
                  <span>Text</span>
                  <ToggleSwitch type="checkbox" defaultChecked={this.state.sms_opt_in} onChange={(event) => this.onChange(event)} onClick={(modal, event) => this.toggleAlert('sms', event)} name="sms_opt_in" small="true" checked={this.state.sms_opt_in}/>
                  <span>Email</span>
                  <ToggleSwitch type="checkbox" defaultChecked={this.state.email_opt_in} onChange={(event) => this.onChange(event)} onClick={(modal, event) => this.toggleAlert('email', event)} name="email_opt_in" small="true" checked={this.state.email_opt_in}/>
                </div>
              </div>
            </div>
            <div className="opt-in__section">
              <div className="opt-in__toggle">
                <div className="opt-in__toggle-row">
                  <span className="opt-in__heading">Marketing: </span>
                  <span>Text & Email</span>
                  <ToggleSwitch type="checkbox" defaultChecked={this.state.marketing_opt_in} onChange={(event) => this.onChange(event)} onClick={(modal, event) => this.toggleAlert('marketing', event)} name="marketing_opt_in" small="true" checked={this.state.marketing_opt_in}/>
                </div>
              </div>
            </div>
            {this.showSmsModal()}
            {this.showEmailModal()}
            {this.showMarketingModal()}
        </OptInWrapper>
      )
    } else {
      return (
        ''
      )
    }
  }

  render() {
    return (
      this.renderData()
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OptInInfo))
