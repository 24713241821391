import React, { Component } from 'react';
import styled from 'styled-components';
// import history from '../../history';
import { connect } from 'react-redux';

//Redux
import { togglePaymentTypes } from '../../js/actions/invoices';

const GrayModalContainer = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  z-index: 99;
  .top-box {
    padding-top: 14px;
    overflow-y: scroll;
    background-color: #F1F1F1;
    height: 340px;
    width: 90%;
    position: fixed;
    z-index: 99;
    box-shadow: 0 3px 7px 0 rgba(93,93,93,0.5);
    border-radius: 11px;
    bottom: 92px;
    left: 50%;
    transform: translateX(-50%);
    @media(min-width: 768px) {
      width: 400px;
    }
    .title {
      color: #676767;
      text-align: center;
      font-size: 20px;
    }
    .payment-option {
        height: 52px;
        width: 265px;
        position: relative;
        cursor: pointer;
        transition: border;
        margin: 0 auto;
        &.select-payment {
          background-color: inherit;
        }
        .card-icon {
          height: 49px;
          width: auto;
          position: absolute;
          left: 4px;
          top: 2px;
        }
        .card-no {
          position: absolute;
          font-size: 14px;
          color: #2D2D2D;
          left: 55px;
          top: 10px;
          font-weight: 600;
        }
        .card-type {
          color: #616161;
          font-size: 13px;
          position: absolute;
          left: 55px;
          bottom: 12px;
        }
      }
    .select-payment {
      padding-bottom: 10px;
      background-color: inherit;
      margin-left: 33px;
      text-align: center;
      .add-symbol {
        color: #4691E4;
        font-size: 16px;
        position: relative;
      }
      a {
        color: #4691E4;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        text-decoration: underline;
      }
    }
  }
  .cancel-button {
    height: 50px;
    text-align: center;
    position: fixed;
    border-radius: 11px;
    width: 90%;
    bottom: 25px;
    color: #1981FE;
    font-size: 18px;
    font-weight: 600;
    left: 50%;
    transform: translateX(-50%);
    line-height: 2.5;
    z-index: 99;
    background-color: #F1F1F1;
    box-shadow: 0 3px 7px 0 rgba(93,93,93,0.5);
    @media(min-width: 768px) {
      width: 400px;
    }
  }
`

class PaymentOptionsModal extends Component {
  toggle = () => {
    this.props.togglePaymentTypes(true)
    this.props.toggle()
  }

  render() {
    return (
        <GrayModalContainer>
          <div className="top-box">
            <div className="title">Choose Payment Method</div>
            {this.props.renderPaymentOptions()}
            <div className="select-payment">
              <div className="add-symbol" data-test-id={"pmt-options-add-" + this.props.context} onClick={this.toggle}>+ Add Payment Method</div>
            </div>
          </div>
          <div className="cancel-button" data-test-id={"pmt-options-cancel-" + this.props.context} onClick={this.props.toggle}>Cancel</div>
        </GrayModalContainer>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    togglePaymentTypes: (bool) => {dispatch(togglePaymentTypes(bool))}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(PaymentOptionsModal)