import React, {Component} from 'react';
import styled from 'styled-components';

const Button = styled.div`
  width: ${props => props.small ? '125px' : props.saveCancel ? '80px' : '160px'};
  height: ${props => props.small ? '35px' : props.saveCancel ? '30px' : '60px'};
  line-height: ${props => props.small ? '1' : props.saveCancel ? '1.5' : '2.5'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.teal && props.primary ? '#58D6D8' : props.primary ? '#64ABF5' : 'white'};
  background-color: ${props => props.primary ? 'white' : props.red ? '#fd7272' : props.teal ? '#58D6D8' : '#64ABF5'};
  padding: 8px;
  border: ${props => props.saveCancel ? '3px solid #64ABF5' : props.red ? '3px solid #fd7272' : props.teal ? '1px solid #58D6D8' : '2px solid #64AAF2'} ;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
`;

export default class ActionButton extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Button {...this.props} className={this.props.className} type={this.props.type}>{this.props.children}</Button>
    )
  }
}
