import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Div100vh from 'react-div-100vh/lib/Div100vh';

//Components
import ContentWrapper from '../../components/wrappers/ContentContainer';
import Dashboard from './Dashboard/Dashboard';
import Header from '../../components/nav/Header';
import SelectCard from './BlytzWallet/SelectCard';
import CustomerInvoice from '../customer/Invoice/CustomerInvoice';
import BillsDashboard from './Bills/BillsDashboard';
import Receipt from './Receipts/Receipt';
import globalUtils from '../../utils/globalUtils';
import CustomerProfile from './Profile/CustomerProfile';
import Settings from './Settings/NotificationPreferences';
import MerchantDetail from './Merchants/MerchantDetail';
import CashReceipt from './Receipts/CashReceipt';

//Redux
import { storeCustomerUsername } from '../../js/actions/auth';
import { storeAchInfo } from '../../js/actions/merchants';

//API
import customerAuthAPI from '../../api/auth/customerAuthAPI';
import withLoginRedirect from '../public/LoginRedirect/withLoginRedirect';

class CustomerIndex extends Component {
  constructor(props){
    super(props);
    this.state = {
      validated: false,
      headerVisible: false,
      merchant_name: ''
    }
  }

  checkAuth = async () => {
    const values = globalUtils.getQueryString(this.props.location.search.slice(1))
    if (values.username) {
      this.props.storeCustomerUsername(values.username)
    }
    if (!this.props.auth_token){
      if(this.props.location.hash !== '#registered') {
        let user_data = await customerAuthAPI.getUserActive({username: values.username || this.props.username, merchant_name: values.merchant_name || this.props.merchant_name})

        if (user_data && user_data.active === 'active'){
          this.props.history.push('/customer/login')
          return
        } else {
          if (user_data.is_business){
            this.props.history.push('/customer/signup/' + values.username + '?merchant_name=' + values.merchant_name + '&username=' + values.username + '&is_business=True')
          } else {
            this.props.history.push('/customer/signup/' + values.username + '?merchant_name=' + values.merchant_name + '&username=' + values.username)
          }
          return
        }
      }
    }
  }

  componentDidMount = () => {
    this.checkAuth()
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.auth_token) {
      this.setState({
        validated: true
      })
    }
  }

  render() {
    return (
      <Div100vh>
        <Header path={this.props.history.location.pathname}/>
        <ContentWrapper>
          <Switch onChange={this.checkAuth}>
            <Route
              key={1}
              path="/customer/blytzwallet/select-card"
              component={SelectCard}
            />
            <Route
              key={2}
              path="/customer/dashboard"
              component={Dashboard}
            />
            <Route
              key={3}
              path="/customer/blytzwallet/invoice/:id"
              component={CustomerInvoice}
            />
            <Route
              key={4}
              path="/customer/bills/:bill_state"
              component={BillsDashboard}
            />
            <Route
              key={5}
              exact
              path="/customer/receipts/:bill_state"
              component={BillsDashboard}
            />
            <Route
              key={6}
              exact
              path="/customer/receipts/paid/:id"
              component={Receipt}
            />
            <Route
              key={7}
              path="/customer/profile"
              exact
              component={CustomerProfile}
            />
            <Route
              key={8}
              exact
              path="/customer/profile/settings"
              component={Settings}
            />
            <Route
              key={9}
              exact
              path="/customer/merchant/:id"
              component={MerchantDetail}
            />
            <Route
              key={10}
              exact
              path="/customer/receipts/cash/:id"
              component={CashReceipt}
            />
          </Switch>
        </ContentWrapper>
      </Div100vh>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchant_name: state.merchants.merchant_name,
    from_invoice: state.invoices.from_invoice,
    auth_token: state.auth.auth_token,
    username: state.auth.username,
    user_pin: state.auth.user_pin,
    logged_in: state.auth.logged_in,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    storeCustomerUsername: (username) => {dispatch(storeCustomerUsername(username))},
    storeAchInfo: (ach_info) => {dispatch(storeAchInfo(ach_info))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLoginRedirect(CustomerIndex))
