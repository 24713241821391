import React from 'react';
import styled from 'styled-components';
import close_icon from '../../assets/icons/close_black_icon.png';
import { connect } from 'react-redux'

//Redux
import { togglePaymentTypes } from '../../js/actions/invoices';

const Container = styled.div`
  height: 420px;
  width: 335px;
  box-shadow: 3px 16px 18px 3px #082C65, 4px 6px 4px 0 rgba(0,0,0,0.5);
  border-radius: 18px;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  margin-bottom: 40px;
  margin-top: 25px;
  @media (min-device-height: 668px) {
    height: 470px;
    margin-top: 50px;
  }
  .title {
    text-align: center;
    color: #737373;
    font-size: 20px;
    padding-top: 50px;
    margin-bottom: 20px;
  }
  img.close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 30px;
    width: auto;
  }
`

class MobileModalRounded extends React.Component {

  renderCloseButton = () => {
    if(this.props.close) {
      return <img className="close-icon" src={close_icon} onClick={ this.props.close } alt="close"/>
    } else return <img className="close-icon" src={close_icon} onClick={ () => this.props.togglePaymentTypes(false) } alt="close"/>
  }

  render() {
    return (
      <Container>
        {this.renderCloseButton()}      
        <div className="title">{this.props.title}</div>
        <div className="content">{this.props.children}</div>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    togglePaymentTypes: (bool) => {dispatch(togglePaymentTypes(bool))}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(MobileModalRounded)