import React, {Component} from 'react';
import styled from 'styled-components';
import Label from '../display/Label';
import Input from './Input';
import States from '../dropdown/States';

const AddressContainer = styled.div`
  input {
    margin-bottom: 5px;
  }

  .u-margin-top-small {
    margin-top: 8px;
  }
`

const StateZip = styled.div`
  display: ${props => props.statehidden ? 'none' : 'flex'};
  justify-content: space-between;
  width: 305px;
  & .state {
    width: 90%;
    margin-right: 15px;
  }
  & .zip {
    width: 90%;
  }
  .modal-input.basic-single {
    width: 175px;
  }
`

export default class Address extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.myRefs = {
      service_address: React.createRef(),
      service_address2: React.createRef(),
      service_address_city: React.createRef(),
      service_address_state: React.createRef(),
      service_address_zip: React.createRef()
    }
  }

  handleChange = (event) => {
    this.props.onChange(event)
  };

  render() {
    return (
      <AddressContainer {...this.props}>
        <Label label="Address" blue={this.props.blue ? true : null}/>
          <Input className="modal-input no-border"
                placeholder="Street 1"
                name="service_address"
                data-test-id="service_address"
                onChange={this.handleChange.bind(this)}
                autoComplete="address-line1"
                required
                value={this.props.service_address ? this.props.service_address : null}
                ref={this.myRefs.service_address}/>
          <Input className="modal-input no-border"
                placeholder="Street 2"
                name="service_address2"
                data-test-id="service_address2"
                onChange={this.handleChange.bind(this)}
                autoComplete="address-line2"
                value={this.props.service_address2 ? this.props.service_address2 : null}
                ref={this.myRefs.service_address2}/>
          <Input className="modal-input no-border"
                placeholder="City"
                name="service_address_city"
                data-test-id="service_address_city"
                onChange={this.handleChange.bind(this)}
                autoComplete="address-level2"
                required
                value={this.props.service_address_city ? this.props.service_address_city : null}
                ref={this.myRefs.service_address_city}/>
          <StateZip {...this.props}>
            <States className="modal-input state no-border"
                  placeholder="State"
                  name="service_address_state"
                  data-test-id="service_address_state"
                  onChange={this.props.onStateChange}
                  autoComplete="address-level1"
                  required
                  value={this.props.service_address_state ? this.props.service_address_state : null}
                  ref={this.myRefs.service_address_state}/>
            <Input className="modal-input zip no-border u-margin-top-small"
                  placeholder="Zip Code"
                  name="service_address_zip"
                  data-test-id="service_address_zip"
                  onChange={this.handleChange.bind(this)}
                  autoComplete="postal-code"
                  maxLength="5"
                  type="zip"
                  required
                  value={this.props.service_address_zip ? this.props.service_address_zip : null}
                  ref={this.myRefs.service_address_zip}/>
          </StateZip>
      </AddressContainer>
    )
  }
}
