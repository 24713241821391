import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import qs from 'qs';
// const dollarsToCents = require('dollars-to-cents');
const move_decimal = require('move-decimal-point');

function globalUtils() {
  dayjs.extend(utc, customParseFormat)

  this.getCurrentDate = () => {
    return dayjs().format("MM/DD/YYYY");
  }

  this.formatDate = (date) => {
    if (!date) {
      return;
    }
    if (date) {
      let dateRegex = /[1-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]T[0-9][0-9]:[0-9][0-9]:[0-9][0-9]-[0-9][0-9][0-9][0-9]/gm;
      if(dateRegex.test(date)) {
        let dateArr = date.split('-')
        let timeArr = dateArr[2].split('T')
        let timeSplit = timeArr[1].split(':')
        return dayjs(new Date(dateArr[0], +dateArr[1]-1, timeArr[0], timeSplit[0], timeSplit[1], timeSplit[2])).format("MM/DD/YYYY");
      } else {
        return dayjs(date).format("MM/DD/YYYY");
      }
    }
  }

  this.sendDate = (date) => {
    if (date === undefined) {
      return;
    }
    if (date) {
      date = dayjs(date).utc()
      date = dayjs(date).local()
      return dayjs(date).format("MM/DD/YYYY");
    }
  }

  this.formatDateAndTime = (date) => {
    if (date === undefined) {
      return;
    }
    if (date) {
      date = dayjs(date).utc()
      date = dayjs(date).local()
      return dayjs(date).format("MM/DD/YYYY hh:mmA");
    }
  }

  this.formatLongDateAndTime = (date) => {
    if (date === undefined) {
      return;
    }
    if (date) {
      date = dayjs(date).utc()
      date = dayjs(date).local()
      return dayjs(date).format("hh:mm a MMMM D, YYYY");
    }
  }

  this.formatTimeOnly = (date) => {
    if (date === undefined) {
      return;
    }
    if (date) {
      date = dayjs(date).utc()
      date = dayjs(date).local()
      return dayjs((date)).format("hh:mmA");
    }
  }

  this.formatSimpleDate = (date) => {
    if (date === undefined) {
      return;
    }
    if (date) {
      date = dayjs(date).utc()
      return dayjs(date).format("MM/DD");
    }
  }

  this.returnMonth = (date) => {
    if(!date) {
      return;
    }
    if(date) {
      return dayjs(date).utc().format('MMM')
    }
  }

  this.returnDay = (date) => {
    if(!date) {
      return
    }
    if(date) {
      return dayjs(date).utc().format('D');
    }
  }

  this.formatDay = (day) => {
    switch (day) {
      case "1": day = "1st"; break;
      case "2": day = "2nd"; break;
      case "3": day = "3rd"; break;
      case "4": day = "4th"; break;
      case "5": day = "5th"; break;
      case "6": day = "6th"; break;
      case "7": day = "7th"; break;
      case "8": day = "8th"; break;
      case "9": day = "9th"; break;
      case "10": day = "10th"; break;
      case "11": day = "11th"; break;
      case "12": day = "12th"; break;
      case "13": day = "13th"; break;
      case "14": day = "14th"; break;
      case "15": day = "15th"; break;
      case "16": day = "16th"; break;
      case "17": day = "17th"; break;
      case "18": day = "18th"; break;
      case "19": day = "19th"; break;
      case "20": day = "20th"; break;
      case "21": day = "21st"; break;
      case "22": day = "22nd"; break;
      case "23": day = "23rd"; break;
      case "24": day = "24th"; break;
      case "25": day = "25th"; break;
      case "26": day = "26th"; break;
      case "27": day = "27th"; break;
      case "28": day = "28th"; break;
      case "29": day = "29th"; break;
      case "30": day = "30th"; break;
      case "31": day = "31st"; break;
      default: day = day + "th"; break;
    }
    return day;
  }

  this.getQueryString = (string) => {
    return qs.parse(string);
  }

  this.convertToDollars = (amount) => {
    if (amount === undefined) {
      return;
    }
    let dollars = amount / 100;
    return dollars.toLocaleString("en-US", {style: "currency", currency: "USD"})
  }

  this.convertToDecimal = (amount) => {
    if (amount === undefined) {
      return;
    }
    let decimal = amount / 100;
    return decimal
  }

  this.convertDollarsToCents = (value) => {
    if (!value) {
      return;
    } else {
      return value.replace(/\D/g,'');
    }
  }

  this.convertToCentsInt = value => {
    // if there is no value return 0
    return value ? parseInt(value.replace(/(\$0\.|\D)/g,'')) : 0
  }

  this.returnConvertedArray = (array) => {
    return array.map( item => {
      return {
        description: item.description,
        amount: this.convertDollarsToCents(item.amount)
      }
    });
  }

  this.getUserInitials = (firstName, lastName) => {
    if (firstName && lastName) {
      let firstInitial = firstName.charAt(0).toUpperCase();
      let lastInitial = lastName.charAt(0).toUpperCase();
      return firstInitial + lastInitial;
    } else {
      return;
    }
  }

  this.getFirstLetter = (string) => {
    if (string) {
      return string.charAt(0);
    } else {
      return;
    }
  }

  this.validate_credential = (credential) => {
    if (!credential || credential.length === 0){
      return false
    }
    return true
  }

  this.loginRedirect = (props) => {
    if (!props) {
      return
    }

    if (props.history.location.pathname === "/" || props.history.location.pathname === "/login" || props.history.location.pathname === "/customer/login"){
      return
    }

    if (!this.validate_credential(props.auth_token) && !this.validate_credential(props.user_pin)){
      props.history.push("/login");
      return
    }
    if (props.user_role === 3 || props.user_role === 2){
      return
    }
    if (!this.validate_credential(props.user_pin) && this.validate_credential(props.auth_token)){
        // props.history.push('/customer/login');
    }
  }

  this.replaceText = (string) => {
    if (string === undefined) {
      return;
    }
    let newString = string.replace(/[_]/g, " ");
    return newString;
  }

  this.trimWhitespace = (string) => {
    return string.replace(/\s+/g, '');
  }

  this.onlyLettersAndNumbers = (string) => {
    return string.replace(/[^A-Za-z0-9]/g, '');
  }

  this.moveOneDecimal = (number) => {
    return move_decimal(number, 1);
  }

  this.escapeRegexCharacters = (str) => {
    if (str) return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  this.generateRandomString = () => {
    let string = Math.random().toString(36).substring(7);
    return string;
  }

  this.removeUnderscore = (str) => {
    var newStr = str.replace(/_/g, " ");
    return newStr;
  }

  this.checkValues = (object) => {
    let valid = true;
    if(object.address) {
      for(let i in object.address.current.myRefs) {
        if(object.address.current.myRefs[i] && object.address.current.myRefs[i].current.state.error) {
          valid = false;
        }
      }
    }

    for (let i in object) {
      if (object[i].current && object[i].current.state.error) {
        valid = false;
      }
    }
    return valid;
  }

  this.checkCardType = (value, new_card) => {
    if (!new_card){
      new_card = {}
    }
    if (value === "4") {
      new_card.card_type = "visa"
    } else if (value === "2" || value === "5") {
      new_card.card_type = "mastercard"
    } else if ( value === "3") {
      new_card.card_type = "amex"
    } else if (value === "6") {
      new_card.card_type = "discover"
    } else if (value === '') {
      new_card.card_type = null
    }
    return new_card;
  }

  this.routeToLoginM = history => {
    return history.push(`/customer/login`)
  }

  this.routeToLoginD = history => {
    return history.push(`/login`)
  }
  // Experiment - Creating a global util that can be used in multiple places. You would just need to setState on the component you call this method in and use the updated state object returned.
  this.setToken = (data, compState) => {
    let values = JSON.parse(data)
    if (values && values.validationError) {
      return Object.assign({}, compState, {errors: values.validationError})
    } else {
      return Object.assign({}, compState, {
        token: values.token,
        card_type: values.cardType,
        last4: values.lastFour
      })
    }
  }
}

let utils = new globalUtils();

export default utils;
