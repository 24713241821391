import React, {Component} from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  height: auto;
  width: 100%;
margin-top: ${props => props.password ? '0': '20px'};
  @media (max-width: 580px) {
    overflow: hidden;
  }

`

export default class PageContainer extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <PageWrapper {...this.props}>{this.props.children}</PageWrapper>
    )
  }
}
