import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: flex-start;
    margin: 30px;
`

const Header = styled.h1`
    font-size: 17px;
    font-weight: bold;
`

const Card = styled.div`
    width: calc(100% - 40px);
    height: 200px;
    border-radius: 3px;
    border: 2px solid #5A9DEB;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5A9DEB;
    font-weight: bold;
    font-size: 17px;
`

function AutopaySelect(props) {
    return (
        <Container>
            <Header>What would you like to set up?</Header>
            <Card onClick={() => props.selectCard("autopay")}>Auto-pay</Card>
            <Card onClick={() => props.selectCard("recurring")}>Recurring Payments</Card>
        </Container>
    )
}

AutopaySelect.propTypes = {
    selectCard: PropTypes.func.isRequired
}

export default AutopaySelect

