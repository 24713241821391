import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardConnect from '../../../utils/CardConnect';
import styled from 'styled-components';
import Cleave from 'cleave.js/react';
import Input from '../../inputs/Input';
import HideShow from '../../function/HideShow';
import AutopayContent from './AutopayContent';
import AutopayDetail from '../../../views/customer/Autopay/AutopayDetail';




//Components
import Modal from '../NewModal';


//Assets
import 'react-day-picker/lib/style.css'

//Redux
import { setNotification } from '../../../js/actions/notifications';

//API & Utils
import userAPI from '../../../api/customer/userAPI';
import PaymentMethodAPI from '../../../api/blytzwallet/PaymentMethod';
import globalUtils from '../../../utils/globalUtils';


const CreditCard = styled.img`
  width: 35px;
  height: auto;
  position: absolute;
  right: 10px;
  top: 24px;
`

const AutopayForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
`

class AutopayModal extends Component {
  constructor(props){
    super(props);
    this.state = {
        showAutoPayModal: false,
        customized: false,
        showDayPicker: false,
        autopay_day: null,
        autopay_frequency: '',
        card: {},
        payment_method: null,
        autopay_amount: null,
        expiration: undefined,
        page: ''
    }

    this.myRefs = {
        name: React.createRef(),
        card_number: React.createRef(),
        expiration: React.createRef(),
        address_zip: React.createRef(),
        autopay_frequency: React.createRef(),
        autopay_amount: React.createRef(),
        autopay_day: React.createRef(),
        autopay_method: React.createRef(),
      }
}

  onChange = (event) => {
    const newData = {}
    if (event.target) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        newData[target.name] = value
    } else {
      newData[event.name] = event.value
    }
    this.setState(newData);
  };

  toggleNewMethod = () => {
      this.setState({
          showAutoPayModal: !this.state.showAutoPayModal,
      });
  }

  handleFrequencyChange = (event) => {
      if (event.target) {
          const target = event.target;
          const value = target.type === 'checkbox' ? target.checked : target.value;
          const name = target.name;
          this.setState({
              [name]: value,
              autopay_day: null
          });
      } else {
          this.setState({
          autopay_frequency: event.value,
          showDayPicker: event.value === 'monthly', 
          autopay_day: null
          })
      }
  };

  handleDayClick = (e, showDayPicker) => {
      this.setState({
          autopay_day: e.target.value,
          showDayPicker: showDayPicker,
      })
  };

  checkLastBill = () => {
      if (this.props.customer.last_invoice) {
          return (
          <>
              <div className="blue-text">Billed on: {globalUtils.formatDate(this.props.customer.last_invoice.due_date)}</div>
              <div className="blue-text">Amount: {globalUtils.convertToDollars(this.props.customer.last_invoice.amount)}</div>
          </>
          )
      } else {
          return (
          'None found'
          )
      }
  }

  checkNextBill = () => {
      if (this.props.customer.next_invoice) {
          return (
          <>
              <div className="blue-text">Bill on: {globalUtils.formatDate(this.props.customer.next_invoice.due_date)}</div>
              <div className="blue-text">Amount: {globalUtils.convertToDollars(this.props.customer.next_invoice.amount)}</div>
          </>
          )
      } else {
          return (
          'None found'
          )
      }
  }

  setToken = (token) => {
      if (token && token.validationError) {
          this.setState({ errors: token.validationError})
          return;
      } else {
          let newCard = globalUtils.checkCardType(token.substring(1,2), this.state.card);
          newCard.token = token;
          newCard.last4 = token.slice(-4);
          this.setState({
          card: newCard,
          errors: null
          })
      }
  }

  showForm = () => {
      if (this.state.showAutoPayModal) {
          return (
          <HideShow show={this.state.showAutoPayModal}>
              <div className="new-method-container">
              <div className="input-wrapper">
                  <div className="custom-label">Card Number</div>
                  <CardConnect
                  className="input"
                  card
                  noBorder
                  type="tel"
                  name="card_number"
                  data-test-id="card_number"
                  options={{creditCard: true}}
                  onChange={this.onChange}
                  autoComplete="cc-number"
                  required
                  ref={this.myRefs.card_number}
                  setToken={(token) => this.setToken(token)}/>
                  {this.renderIcon()}
              </div>
              <div className="input-wrapper">
                  <div className="custom-label">Name on Card</div>
                  <Input name="name" noBorder={true} onChange={this.onChange}/>
              </div>
              <div className="space-between">
                  <div className="expiration">
                  <div className="custom-label">Expiry Date</div>
                  <Cleave placeholder="mm/yy"
                          type="tel"
                          className="no-border"
                          name="expiration"
                          data-test-id="expiration"
                          options={{date: true, datePattern: ['m','y']}}
                          onChange={this.onChange}
                          value={this.state.card.expiration}/>
                  </div>
                  <div className="zip">
                  <div className="custom-label">Billing Zip Code</div>
                  <Input name="address_zip" noBorder={true} onChange={this.onChange}/>
                  </div>
              </div>
              </div>
          </HideShow>

          )
      }
  }

  onPaymentOptionChange = (event) => {
      if (event.value) {
          this.setState({
          payment_method: event.value
          });
      }
  }

  updateAutopayment = async () => {
      if(!this.state.payment_method && !this.state.card.token) {
          this.setState({errors: 'Please verify your payment method'})
          return;
      }
      
      if (this.state.customized && (!this.state.autopay_amount || !this.state.autopay_frequency || !this.state.autopay_day)) {
          this.setState({errors: 'Please complete amount and frequency fields.'})
          return;
      }
      let payment_info = Object.assign({}, this.state.card)
      payment_info['expiration'] = this.state.expiration;
      payment_info['sub_id'] = this.props.sub_id;

      if (this.state.payment_method) {
          payment_info.autopay_method = this.state.payment_method;
      } else {
          let method = await PaymentMethodAPI.addPaymentMethod(payment_info, this.props.auth_token)
          if (method && !method.err){
          payment_info.autopay_method = method.payment_method_id
          } else {
          this.setState({errors: method.err})
          }
      }
      payment_info.autopay_amount = globalUtils.convertDollarsToCents(this.state.autopay_amount);
      payment_info.autopay_frequency = this.state.autopay_frequency
      if (this.state.autopay_day instanceof Date) {
          payment_info.autopay_day = this.state.autopay_day.getDate()
      } else {
          payment_info.autopay_day = this.state.autopay_day
      }
      
      if (payment_info['autopay_day']) {
          payment_info['customized'] = true
      }
    
    payment_info.autopay_amount = globalUtils.convertDollarsToCents(this.state.autopay_amount);
    payment_info.autopay_frequency = this.state.autopay_frequency
    if (this.state.autopay_day instanceof Date) {
      payment_info.autopay_day = this.state.autopay_day.getDate()
    } else {
      payment_info.autopay_day = this.state.autopay_day
    }
    
    if (this.state.customized) {
      payment_info['customized'] = this.state.customized
    }

      if (!payment_info['sub_id']){
        return;
      }
      let response = await userAPI.setAutopay(payment_info, this.props.auth_token);
      if (response && !response.err) {
          this.props.setNotification({message: "Autopay updated."})
          this.props.refreshData();
          this.props.toggleModal("showAutoPayModal");
          return;
      } else {
          const errData = response.err.response.data
          this.setState({errors: errData.detail})
      }
  }

  renderIcon = () => {
      if (typeof this.state.card === "undefined"){
          return;
      }
      if (this.state.card.card_type === "visa") {
          return <CreditCard src="https://s3.amazonaws.com/blytz/assets/visa.svg"/>
      } else if (this.state.card.card_type === "mastercard") {
          return <CreditCard src="https://s3.amazonaws.com/blytz/assets/mastercard.svg"/>
      } else if (this.state.card.card_type === "amex") {
          return <CreditCard src="https://s3.amazonaws.com/blytz/assets/amexIcon.svg"/>
      } else if (this.state.card.card_type === "discover") {
          return <CreditCard src="https://s3.amazonaws.com/blytz/assets/discover.svg"/>
      }
      else {
          return;
      }
  }

  disableAutopay = async () => {
    let response = await userAPI.setAutopay({autopay: null, sub_id: this.props.sub_id, cancelled: true }, this.props.auth_token);
    if (response && !response.err) {
        this.props.setNotification({message: 'Autopay disabled.'})
        this.props.refreshData();
        this.props.toggleModal("showAutoPayModal");
    } else {
        this.setState({
            errors: response.err
        })
    }
  }

  returnAutopayText = () => {
    if (this.props.customer.autopay_method) {
      return 'Disable Autopay';
    }
  }

  selectCard = card => {
    this.setState({
        page: card
    })
  }

  render() {
    return (
      <Modal selectCard={this.state.page === ""} context="autopay" title={this.props.customer.autopay_enabled ? "Auto-pay Settings" : this.state.page === "autopay" ? "Auto-pay" : this.state.page === "recurring" ? "Recurring Payments" : "Auto-pay & Recurring"} modalname="showAutoPayModal" onClose={this.props.onClose} errors={this.state.errors} submit={this.updateAutopayment} hide_cancel="true" show_text={this.returnAutopayText()} text_click={this.disableAutopay} support={this.disableAutopay}>
        {this.props.customer.autopay.autopay_enabled ? 
            <AutopayForm>
                <AutopayDetail 
                    autopay={this.props.customer.autopay} 
                    payment_methods={this.props.payment_methods} 
                    disableAutopay={this.disableAutopay}
                />
            </AutopayForm> 
        :
            <AutopayContent 
            customer={this.props.customer}
            payment_methods={this.props.payment_methods}
            context={this.props.context}
            sub_id={this.props.sub_id}
            onPaymentOptionChange={this.onPaymentOptionChange}
            toggleNewMethod={this.toggleNewMethod}
            showForm={this.showForm}
            onChange={this.onChange}
            handleFrequencyChange={this.handleFrequencyChange}
            myRefs={this.myRefs}
            handleDayClick={this.handleDayClick}
            showAutoPayModal={this.state.showAutoPayModal}
            customized={this.state.customized}
            autopay_day={this.state.autopay_day}
            autopay_frequency={this.state.autopay_frequency}
            showDayPicker={this.state.showDayPicker}
            selectCard={this.selectCard}
            page={this.state.page}
            noPadding={true}
            />
        }
      </Modal>
    )
  }
  }

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    merchant_id: state.auth.merchant_id
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AutopayModal));
