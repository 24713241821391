import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

//Assets
import blytz_logo from '../../assets/images/logo-gradient.png';
import menu_icon from '../../assets/icons/menu_icon_white.png';

//API & Utils
import globalUtils from '../../utils/globalUtils';

//Components
import HandleOutsideClick from '../function/HandleOutsideClick';

//Redux
import { logout, setUserInfo } from '../../js/actions/auth';
import { clearNotifications } from '../../js/actions/notifications';

const CustomerHeader = styled.div`
  background: linear-gradient(315.36deg, #8D97AD 0%, #545E75 100%);
  position: ${props => props.fixed === 'true' ? 'fixed' : 'static'};
  z-index: 1;
  width: 100%;
  height: 70px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* opacity: .9; */
`;

const TransparentHeader = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 10.04vh;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Logo = styled.img`
  width: auto;
  height: 4.75vh;
  margin-bottom: 4px;
  position: relative;
  right: 5px;
`

const MenuIcon = styled.img`
  position: relative;
  bottom: 11px;
  left: 8px;
  cursor: pointer;
  height: 4.45vh;
  width: auto;
`;

const SidebarWrapper = styled.div`
  .sidebar {
    height: 100vh;
    width: 260px;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 100;
    transition: transform .25s ease-out;
    transform: translateX(-100%);
  }
  .open {
    transform: translateX(0);
    box-shadow: 2px 2px 5px 3px rgba(91,91,91,0.5);
  }
  .outside-area {
    height: 100vh;
    width: calc(100vh - 260px);
    z-index: 99;
    position: fixed;
    right: 0;
    top: 0;
  }
`

const TopContainer = styled.div`
  margin: 0 auto;
  padding-top: 30px;
  text-align: center;
  .initials-container {
    height: 80px;
    width: 80px;
    border: 1px solid #8D97AD;
    border-radius: 60px;
    margin: 0 auto;
    margin-bottom: 5px;
    position: relative;
    background: linear-gradient(315.36deg, #8D97AD 0%, #545E75 100%);
    .initials {
      color: #fff;
      font-size: 28px;
      margin-top: 22px;
    }
  }
  .customer-name {
    color: #8D97AD;
    font-size: 20px;
  }
`

const BottomContainer = styled.div`
  padding: 20px 40px;
  .link {
    color: #8D97AD;
    padding-bottom: 3px;
    border-bottom: 1px solid #DFDFDF;
    margin-bottom: 10px;
    font-size: 15px;
    cursor: pointer;
  }
`

const LogoutContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 25px;
  padding: 0px 15px;
  color: #545E75;
  font-weight: bold;
  .link {
    padding-bottom: 5px;
    cursor: pointer;
  }
`
const invoiceRegex = /\/customer\/blytzwallet\/invoice/;

class HeaderWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      sidebarOpen: false,
      animateSidebar: false,
      fixed: 'false'
      }
    };

  componentDidMount = () => {
    this.updateVisibility()
  }
  componentDidUpdate = (prevProps) => {
    if(this.props.path !== prevProps.path) {
      this.updateVisibility()
    }
  }

  updateVisibility = () => {
    let invoiceRegex = /\/customer\/blytzwallet\/invoice/;
    let receiptRegex = /\/customer\/receipts\/paid/;
    let { pathname } = this.props.history.location;
    if(pathname === '/customer/dashboard'
      || pathname === '/customer/profile'
      || receiptRegex.test(pathname)
      || invoiceRegex.test(pathname)
    ) {
      this.setState({fixed: 'true'})
    } else {
      this.setState({fixed: 'false'})
    }
  }

  toggleSidebar = () => {
    if(this.state.sidebarOpen) {
      this.setState({animateSidebar: false})
      setTimeout( () => this.setState({sidebarOpen: false}), 250)
    } else {
      this.setState({sidebarOpen: true})
      setTimeout( () => this.setState({animateSidebar: true}), 10)
    }
  }

  goBack = () => {
    window.history.back();
  }

  showBackArrow = () => {
    let url = window.location.href;
    if (url.indexOf("routing-lookup") > -1 || url.indexOf("pay-with-check") > -1 || url.indexOf("pay-with-card") > -1 || url.indexOf("confirmation") > -1) {
      return true;
    }
  }

  renderHeaderIcon = () => {
    if (this.showBackArrow()) {
      return (
        <MenuIcon src="https://s3.amazonaws.com/blytz/assets/arrow_back.svg" alt="menu" onClick={this.goBack}/>
      )
    } else {
      return (
        <MenuIcon src={menu_icon} alt="menu" onClick={this.toggleSidebar}/>
      )
    }
  }

  renderHeader = () => {
    let { pathname } = this.props.history.location
    if(invoiceRegex.test(pathname) || pathname === '/customer/dashboard' || pathname === '/customer/profile' || pathname === '/customer/blytzwallet/select-payment-type') {
      return(
        <TransparentHeader fixed={this.state.fixed}>
          {this.renderHeaderIcon()}
          <Logo src={blytz_logo} alt="Logo"/>
        </TransparentHeader>
      )
    } else {
      return(
        <CustomerHeader fixed={this.state.fixed}>
          {this.renderHeaderIcon()}
          <Logo src={blytz_logo} alt="Logo"/>
        </CustomerHeader>
      )
    }
  }

  rerouteToPath = (path) => {
    this.props.history.push(path);
    this.setState({
      sidebarOpen: false
    })
  }

  changeRoute = (path) => {
    this.props.clearNotifications('mobile')
    if (path === 'dashboard' || path === 'profile') {
      this.props.history.push('/customer/' + path);
    } else if (path === 'receipts') {
      this.props.history.push('/customer/'+ path + '/paid');
    } else if (path === 'blytzwallet') {
      this.props.history.push('/customer/blytzwallet/select-card');
    } else if (path === 'profile') {
      this.props.history.push('/customer/profile')
    } else if (path === 'notifications') {
      this.props.history.push('/customer/profile/settings')
    } 
    this.toggleSidebar();
  }

  logout = () => {
    this.props.logout();
    this.props.history.push("/customer/login")
  }

  getUserInitials = () => {
    if (this.props.user_info) {
      if (this.props.user_info.is_business) {
        return globalUtils.getFirstLetter(this.props.user_info.business_name);
      }
      return globalUtils.getUserInitials(this.props.user_info.first_name, this.props.user_info.last_name);
    }
  }

  getName = () => {
    if (this.props.user_info) {
      if (this.props.user_info.is_business) {
        return this.props.user_info.business_name;
      }
      return this.props.user_info.first_name + ' ' + this.props.user_info.last_name;
    }
  }

  showSidebar = () => {
    if(this.state.sidebarOpen) {
      return (
        <HandleOutsideClick show={this.toggleSidebar} context="header">
          <SidebarWrapper>
            <div className={classNames('sidebar', {'open': this.state.animateSidebar})}>
              <TopContainer {...this.props}>
                <div className="initials-container"><div className="initials" onClick={() => this.changeRoute('profile')}>{this.getUserInitials()}</div></div>
                <div className="customer-name" onClick={() => this.changeRoute('profile')}>{this.getName()}</div>
              </TopContainer>
              <BottomContainer>
                <div className="link" onClick={() => this.changeRoute('profile')}>Profile</div>
                <div className="link" onClick={() => this.changeRoute('dashboard')}>Dashboard</div>
                <div className="link" onClick={() => this.changeRoute('notifications')}>Notifications</div>
              </BottomContainer>
              <LogoutContainer onClick={this.logout.bind(this)}><div className="link">Logout</div></LogoutContainer>
            </div>
            <div className="outside-area" onClick={this.toggleSidebar}></div>
          </SidebarWrapper>
        </HandleOutsideClick>
      )
    }
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.showSidebar()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    payment_method: state.authpayment_method,
    user_id: state.auth.user_id,
    user_info: state.auth.user_info,
    from_invoice: state.invoices.from_invoice,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: (auth_data) => {dispatch(logout(auth_data))},
    setUserInfo: (userInfo) => {dispatch(setUserInfo(userInfo))},
    clearNotifications: (mobile) => {dispatch(clearNotifications(mobile))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(HeaderWrapper))
