import React from 'react';

export default class AdminDashboardWrapper extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <>
        
      </>
    )
  }
}
