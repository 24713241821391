import React, {Component} from 'react';
import styled from 'styled-components';

const AdminLink = styled.a`
  color: white;
  font-size: 20px;
  @media (max-height: 700px){
    font-size: 18px;
  }
  margin-top: 9px;
  text-decoration: none;
`

export default class InfoWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AdminLink>{this.props.link_text}</AdminLink>
    )
  }
}
