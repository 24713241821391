import React, {Component} from 'react';
import styled from 'styled-components';
import Dropzone from '../../../components/fields/Dropzone';
import WhiteContainer from '../../../components/wrappers/WhiteContainer';
import Breadcrumb from '../../../components/display/BreadcrumbTitle';

const DropzoneContainer = styled.div`
  padding: 50px;
  text-align: center;
  min-height: 580px;
  height: auto;
`

export default class UploadInvoice extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Breadcrumb page="Invoice Upload" path="/admin/invoices"/>
        <WhiteContainer custom>
          <DropzoneContainer>
            <Dropzone invoice="true"/>
          </DropzoneContainer>
        </WhiteContainer>
      </>
    )
  }
}
