import React, {Component} from 'react';
import styled from 'styled-components';
import Table from '../../../components/tables/Table';
import groupsAPI from '../../../api/groups/groupsAPI';
import Button from '../../../components/buttons/NewButton';
import MassMessageModal from '../../../components/modals/messaging/MassMessageModal';
import HideShow from '../../../components/function/HideShow';
import EditGroupModal from '../../../components/modals/groups/EditGroupModal';
import globalUtils from '../../../utils/globalUtils';
import Modal from '../../../components/modals/PlainModal';
import { thinScrollbar } from '../../../styles/mixins';
import { color_modal_heading } from '../../../styles/colors';

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 75px;
  div {
    margin-right: 5px;
  }
  .btn--primary {
    width: 180px;
  }
  .btn--secondary {
    width: 110px;
  }
`

const ScrollContainer = styled.section`
  max-height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .member {
    font-size: 17px;
    color: ${color_modal_heading};
  }
  
  ${thinScrollbar}

`;

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  .details {
    padding: 30px;
    width: 40%;
    height: auto;
    margin: 0px 50px;
    background-color: #fff;
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 30px;
      color: #6c768c;
    }
    .description,
    .labels {
      font-size: 18px;
      color: #59637A;
      margin-bottom: 5px;
    }
    .text {
      margin-bottom: 30px;
    }
    & .labels {
      margin-bottom: 30px;
      display: flex;
    }
    .link {
      text-align: center;
      color: #7DB6F2;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

const columns = [
  {
    name: "created_at",
    label: "Date Sent",
    options: {
      filter: true,
      sort: true,
      sortDirection: 'desc',
      customBodyRender: (value, tableMeta, updateValue) => {
        if (tableMeta.rowData) {
          return (
            globalUtils.formatDateAndTime(value)
          )
        }
      },
    }
  },
  {
    name: "body",
    label: "Content",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "id",
    label: "ID",
    options: {
    filter: true,
    sort: false,
    display: false
    }
  }
]

const options = {
  filterType: 'checkbox',
  responsive: 'stacked',
  selectableRows: false,
  search: false,
  download: false,
  print: false,
  filter: false,
  viewColumns: false

};

export default class GroupDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      detail: {}
    };
  }

  componentDidMount = () => {
    this.getDetail();
  }

  getDetail = async () => {
    let detail = await groupsAPI.getGroupDetail(this.props.auth_token, this.props.activeGroup.id);
    if (detail) return this.setState({ detail });
  }

  showMassMessageModal = () => {
    if (this.state.renderModal) {
      return this.setState({renderModal: false});
    }
    this.setState({ renderModal: true})
  }

  showEditModal = () => {
    if (this.state.renderEditModal) {
      return this.setState({renderEditModal: false});
    }
    this.setState({ renderEditModal: true})
  }

  toggleMemberModal = () => {
    this.setState(prevState => {
      return {
        showMemberModal: !prevState.showMemberModal
      }
    })
  }

  renderMembers = () => {
    let { activeGroup } = this.props;
    if(activeGroup.members && activeGroup.members.length > 0) {
      return activeGroup.members.map( member => {
        return <span key={`member-id-${member.id}`} className="member">{member.full_name}</span>
      })
    }
  }

  renderEditButton = () => {
    if(this.props.activeGroup.name !== 'All Customers') {
      return <Button gray type="secondary" data-test-id="group-detail-edit-group" onClick={this.showEditModal}>Edit Group</Button>
    }
  }

  renderDescription = () => {
    if(this.props.activeGroup.name !== 'All Customers') {
      return (
        <>
          <div className="description">DESCRIPTION</div>
          <div className="description text">{this.props.activeGroup.description}</div>
        </>
      )
    }
  }

  render() {
    return (
      <>
        <ButtonWrapper>
          <Button gray type="primary" data-test-id="group-detail-new-mass-msg" onClick={this.showMassMessageModal}>New Mass Message</Button>
          { this.renderEditButton() }
        </ButtonWrapper>
        <HideShow show={this.state.renderModal}>
          <MassMessageModal onClose={this.showMassMessageModal} data={this.props.activeGroup} getData={this.props.getData} getDetail={this.getDetail}/>
        </HideShow>
        <HideShow show={this.state.renderEditModal}>
          <EditGroupModal toggle_modal={this.showEditModal} data={this.props.activeGroup} getData={this.props.getData} hideActiveGroup={this.props.hideActiveGroup} refresh={this.props.refresh}/>
        </HideShow>
        <HideShow show={this.state.showMemberModal}>
          <Modal
              title="Members"
              close={this.toggleMemberModal}
              context="group-detail"
          >
            <ScrollContainer>
              {this.renderMembers()}
            </ScrollContainer>
          </Modal>
        </HideShow>
        <Container>
          <Table
            title="Message History"
            data={this.state.detail.messages}
            loaded={this.state.loaded}
            columns={columns}
            options={options}
            width='65%'>
          </Table>
          <div className="details">
            <div className="title">{this.props.activeGroup.name}</div>
            { this.renderDescription() }
            {/* <div className="labels">LABELS</div> */}
            <div className="link" data-test-id="group-detail-view-members" onClick={this.toggleMemberModal}>View Members</div>
          </div>
        </Container>
      </>
    )
  }
}
