import React, {Component} from 'react';
import styled from 'styled-components';

//Components
import Hideable from '../function/HideShow';
import Input from './Input';
import PhoneInput from './PhoneInput';
import Date from './Date';

const InlineEditContainer = styled.div`
  width: 100%;
  max-height: 60px;
`

const InputContainer = styled.div`
  margin-left: ${props => props.username ? '15px' : '0px'};
`

export default class InlineEdit  extends Component {
  constructor(props){
    super(props);
    this.state = {
      error: ''
    }
  }

  checkProps = () => {
    if (this.props.name === 'phone') {
      return (
        <PhoneInput
          placeholder={this.props.placeholder}
          className="merchant-input"
          name={this.props.name}
          data-test-id={this.props.name}
          onChange={this.props.onChange}
          value={this.props.value}
          submit={this.props.submit}
          onBlur={(e) => this.checkForErrors(e)}
          required/>
      )
    } else if (this.props.name === "date_of_birth"){
      return (
        <Date
          className="merchant-input"
          name={this.props.name}
          required
          onChange={this.props.onChange}
          data-test-id={this.props.name}
          defaultValue={this.props.value}
          submit={this.props.submit}
          />
      )
    } else {
      return (
        <>
          <Input className="merchant-input" maxLength={this.props.maxLength} data-test-id={this.props.name} name={this.props.name} value={this.props.value} onChange={this.props.onChange} placeholder={this.props.placeholder} submit={this.props.submit} type={this.props.type} onBlur={(e) => this.checkForErrors(e)}/>
          <div className="has-error">{this.state.error}</div>
        </>
      )
    }
  }

  checkForErrors = (e) => {
    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    }
    if (this.props.required && e.target.value) {
      this.setState({error: ''})
    }
  }

  render() {
    return (
      <InlineEditContainer {...this.props} {...this.state}>
        <Hideable show={this.props.showLabel}>
          {this.props.children}
        </Hideable>
        <Hideable show={!this.props.showLabel}>
          <InputContainer {...this.props}>
            {this.checkProps()}
          </InputContainer>
        </Hideable>
      </InlineEditContainer>
    )
  }
}
