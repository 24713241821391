import React from 'react';
import { connect } from 'react-redux';
import history from '../../../history';


// Components
import Modal from '../NewModal';
import { ModalContent } from '../generalModalStyling';
import InputWrapper from '../../wrappers/InputWrapper';
import Input from '../../inputs/Input';
import Label from '../../display/Label';
import Typeahead from '../../inputs/Typeahead';
import CurrencyInput from '../../inputs/CurrencyInput';
import CurrencyDateInput from '../../inputs/CurrencyDateInput';

// API & Utils
import userAPI from '../../../api/customer/userAPI';
import merchantAPI from '../../../api/merchant/merchantAPI';
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';
import globalUtils from '../../../utils/globalUtils';

const frequencies = [
	{ label: 'Monthly', value:'monthly'},
	{ label: 'Bi-Weekly', value:'bi-weekly'},
	{ label: 'Weekly', value:'weekly'}
];

class PaymentPlanModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customers: [],
			masterCustomerList: [],
			merchant: {},
			merchantList: {},
			selectedBiller: null,
			selectedCustomer: null
		}

		this.myRefs = {
			user_id: React.createRef(),
			merchant_id: React.createRef(),
			original_balance: React.createRef(),
			first_payment: React.createRef(),
			monthly_payment: React.createRef(),
			frequency: React.createRef(),
			description: React.createRef()
		}
	}

	componentDidMount() {
		this.getCustomers();
		this.getSelf();
		if(this.props.customer) {
			let { customer } = this.props;
			this.setState({
				user_id: customer.user,
				merchant_id: customer.merchant,
				selectedBiller: [{label: customer.merchant_name, value: customer.merchant}],
				selectedCustomer: [{label: customer.full_name, value: customer.user}]
			})
		}
	}

	getCustomers = async () => {
		let customers = await userAPI.getList(this.props.auth_token);
		this.setState({
			customers: customers,
			masterCustomerList: customers
		})
	}

	getSelf = async () => {
		let merchant = await merchantAPI.getSelf(this.props.auth_token)
		this.setState({
			merchant: merchant,
			merchantList: {merchants: merchant.merchants}
		})
	}

	handleChange = (event) => {
		if (event.target) {
			const target = event.target;
			const value = target.type === 'checkbox' ? target.checked : target.value;
			const name = target.name;
			this.setState({[name]: value});
		} else { // Logic for changing Biller Name
			let merchant = this.state.merchantList.merchants.filter( merchant => merchant.id === event.value )
			let newCustomerList = this.state.masterCustomerList.filter( customer => customer.merchant_id === event.value )
			this.setState({
				merchant_id: event.value,
				selectedBiller: [{label: merchant[0].name, value: event.value}],
				customers: newCustomerList
			})
		}
	};

	handleInvoiceChange = (event) => {
		if (event.target) {
			const target = event.target;
			const value = target.type === 'checkbox' ? target.checked : target.value;
			const name = target.name;
			this.setState({
			[name]: value
			});
		} else { // Logic for changing customer
			let customer = this.state.customers.filter( customer => customer.user === event.value );
			let newMerchantList = this.state.merchantList.merchants.filter( merchant => merchant.id === customer[0].merchant_id );
			this.setState({
				user_id: event.value,
				selectedCustomer: [{label: customer[0].full_name, value: customer[0].user}],
				merchant_id: customer[0].merchant_id,
				selectedBiller: [{label: customer[0].merchant_name, value: customer[0].merchant_id}],
				merchantList: {merchants: newMerchantList}
			})
		}
	};

	handleFrequencyChange = (event) => {
		if (event.target) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({
				[name]: value
		});
		} else {
			this.setState({
				frequency: event.value
			})
		}
	};

	handleEnter = (event) => {
    if (event.key === "Enter") {
      this.addPlan();
    } else {
      return;
    }
	}
	
	addPlan = async () => {
		if (globalUtils.checkValues(this.myRefs) === false) {
			return;
		}

		if (!this.state.clicked) {
			if (!this.state.user_id || !this.state.merchant_id || !this.state.original_balance || !this.state.first_payment || !this.state.monthly_payment || !this.state.payment_due_date || !this.state.frequency) {
				this.setState({errors: "Please complete the required fields."})
				return
			}

			let planDetails = Object.assign({}, this.state);
			delete planDetails.customers;
			delete planDetails.masterCustomerList;
			delete planDetails.selectedCustomer;
			delete planDetails.merchant;
			delete planDetails.merchantList;
			delete planDetails.selectedBiller;
			planDetails.original_balance = globalUtils.convertDollarsToCents(planDetails.original_balance);
			planDetails.first_payment = globalUtils.convertDollarsToCents(planDetails.first_payment);
			planDetails.monthly_payment = globalUtils.convertDollarsToCents(planDetails.monthly_payment);
			planDetails.sub_id = this.props.customer.id
			this.setState({clicked: true})
			let plan = await userInvoiceAPI.createPlan(planDetails, this.props.auth_token);

			if (plan.err && plan.err.response) {
					let errorText = String(plan.err.response.data.errors);
					this.setState({
						errors: errorText,
						clicked: false
					});
			} else if (plan.err) {
					// this.setState({error: errorText});
			} else {
					this.setState({success: true});
					history.push('/admin/invoices/payment-plan/' + plan.id)
			}
			this.getCustomers();
		}
	}

	render() {
		return(
			<Modal context="payment-plan" title="New Payment Plan" modalname="showPlanModal" onClose={ this.props.onClose } errors={this.state.errors} submit={this.state.clicked ? null : this.addPlan} addPlan>
				<ModalContent>
					<InputWrapper>
					<Label label="Customer Name"/>
					<Typeahead options={this.state.customers}
									placeholder="Search Customers"
									name="user_id"
									onChange={this.handleInvoiceChange}
									value={this.state.selectedCustomer}
									isDisabled={this.props.customer ? true : false}
									ref={this.myRefs.user_id}
									required/>
					</InputWrapper>
					<InputWrapper>
					<Label label="Biller Name"/>
					<Typeahead options={this.state.merchantList}
									placeholder="Search Billers"
									name="merchant_id"
									onChange={this.handleChange}
									value={this.state.selectedBiller}
									isDisabled={this.props.customer ? true : false}
									overrideValue={this.state.merchant_id ? true : false}
									required
									ref={this.myRefs.merchant_id}/>
					</InputWrapper>
					<InputWrapper>
					<Label label="Total Amount"/>
					<CurrencyInput
									noBorder={true}
									className="no-border full-width-input"
									placeholder="Total Bill Amount"
									name="original_balance"
									onChange={this.handleChange}
									required
									ref={this.myRefs.original_balance}/>
					</InputWrapper>
					<InputWrapper>
					<Label label="First Payment"/>
					<CurrencyDateInput
									name1="first_payment"
									className="no-border"
									options={{date: true, datePattern: ['m', 'd', 'Y']}}
									name2="first_payment_date"
									onChange={this.handleChange}
									ref={this.myRefs.first_payment}
									noBorder={true}
									required/>
					</InputWrapper>
					<InputWrapper>
					<Label label="Payment"/>
					<CurrencyDateInput
									name1="monthly_payment"
									className="no-border"
									options={{date: true, datePattern: ['m', 'd', 'Y']}}
									name2="payment_due_date"
									onChange={this.handleChange}
									required
									ref={this.myRefs.monthly_payment}
									noBorder={true}/>
					</InputWrapper>
					<InputWrapper>
					<Label label="Payment Frequency"/>
					<Typeahead
							options={frequencies}
							placeholder="Choose Frequency"
							name="frequency"
							onChange={this.handleFrequencyChange}
							required
							frequencies
							ref={this.myRefs.frequency}/>
					</InputWrapper>
					<InputWrapper>
					<Label label="Description" optional/>
					<Input className="no-border full-width-input"
									placeholder="Enter Description"
									name="description"
									onChange={this.handleChange}
									onKeyUp={this.handleEnter}
									ref={this.myRefs.description}/>
					</InputWrapper>
				</ModalContent>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

export default connect(mapStateToProps)(PaymentPlanModal);
