import React, {Component} from 'react';
import styled from 'styled-components';
import Cleave from 'cleave.js/react';

const Wrapper = styled.div`
  .has-error {
    color: #FD7272;
    text-align: left;
    margin-top: 5px;
  }
  input {
    border: ${state => state.error ? '1px solid #FD7272' : '1px solid #9E9E9E'};
    &:focus {
      border: 2px solid #64ABF5;
      outline: none;
    }
  }
`

export default class Date extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  checkForErrors = (e) => {
    if (!this.props.required){
      return
    }
    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    } else {
      if ( (this.props.required && e.target.value.length !== 10) || e.target.value.length !== 8 ) {
        this.setState({error: 'Not a valid date'})
      }
    }
    if ( ( this.props.required && e.target.value.length === 10 ) || e.target.value.length === 8 ) {
      this.setState({error: ''})
    }
  }

  handleEnter = (event) => {
    if (event.key === "Enter" && this.props.submit) {
      this.props.submit();
    } else {
      return;
    }
  }

  render() {
    return (
      <Wrapper {...this.state}>
        <Cleave
          placeholder={this.props.placeholder ? this.props.placeholder : 'mm/dd/yyyy'}
          className={this.props.className}
          options={{date: true, datePattern: ['m', 'd', 'Y']}}
          name={this.props.name}
          data-test-id={this.props.name}
          onChange={this.props.onChange}
          onBlur={(e) => this.checkForErrors(e)}
          onKeyUp={this.handleEnter}
          value={this.props.defaultValue}/>
        <div className="has-error">{this.state.error}</div>
      </Wrapper>
    )
  }
}
