import React, {Component} from 'react';
import styled from 'styled-components';
import Input from '../inputs/ControlledInput';
import classNames from 'classnames';

const Container = styled.div`
  .title-container {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 60px;
    @media (max-width: 320px) {
      margin-top: 10px;
    }
    @media (min-height: 736px) {
      margin-top: 100px;
    }
    .title {
      font-size: 28px;
      color: #fff;
      text-align: center;
      margin-bottom: 5px;
    }
    .sub-title {
      font-size: 18px;
      color: #fff;
      text-align: center;
      font-weight: 300;
      height: 80px;
    }
  }
  .pin-container {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    .pin-input {
      background-color: transparent;
      border: 1px solid rgba(255,255,255,.7);
      width: 22px;
      border-radius: 50%;
      margin-left: 10px;
      color: transparent;
      &.ios-only {
        @supports (-webkit-overflow-scrolling: touch) {
          width: 30px;
          color: transparent;
        }
      }
    }
    .backgroundOne {
      background-color: #5a9fde;
      border: none;
      color: #5a9fde;
    }
    .backgroundTwo {
      background-color: #5cb6d8;
      border: none;
      color: #5cb6d8;
    }
    .backgroundThree {
      background-color: #65d9cf;
      border: none;
      color: #65d9cf;
    }
    .backgroundFour {
      background-color: #70f3ca;
      border: none;
      color: #70f3ca;
    }
  }
  .number-pad {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px auto 0 auto;
    text-align: center;
    color: #fff;
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 */
      margin: 0px auto 0 auto;
    }
  }
  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(3,100px);
    grid-template-rows: repeat(4,65px);
    font-size: 40px;
    @media (max-width: 320px) {
      grid-template-rows: repeat(4,50px);
    }
    .delete {
      position: relative;
      .x {
        position: absolute;
        bottom: 20px;
        right: 40px;
        font-size: 25px;
        line-height: .8;
        background-color: #01D3D8;
        height: 23px;
        width: 26px;
        margin-top: 13px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        @media (max-width: 320px) {
          bottom: 13px;
        }
      }
      .css-triangle {
        position: absolute;
        bottom: 20px;
        right: 66px;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 11px solid transparent;
        border-right: 14px solid #01D3D8;
        @media (max-width: 320px) {
          bottom: 13px;
        }
      }
    }
    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 */
        font-size: 24px;
      }
    }
  }
`;

export default class PinCode extends Component {
  constructor(props){
    super(props);
    this.state = {
      one: '',
      two: '',
      three: '',
      four: ''
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ one: '', two: '', three: '', four: ''});
    }
  }

  update = (event) => {
    let pincode = '' + this.state.one + this.state.two + this.state.three + this.state.four;
    this.props.updateValue(pincode);
  }

  setValue = (num, event) => {
    if (!this.state.one && !this.state.two && !this.state.three && !this.state.four) {
      this.setState({ one: num});
    }
    if (this.state.one && !this.state.two && !this.state.three && !this.state.four) {
      this.setState({ two: num})
    }
    if (this.state.one && this.state.two && !this.state.three && !this.state.four) {
      this.setState({ three: num})
    }
    if (this.state.one && this.state.two && this.state.three && !this.state.four) {
      this.setState({ four: num}, () => {
        this.update();
        if (this.state.one === this.state.two && this.state.one === this.state.three && this.state.one === this.state.four) {
          if (this.props.login) {
            return;
          }
          this.props.onChange(event, 'duplicate')
          this.setState({ one: '', two: '', three: '', four: ''});
          window.scrollTo(0,0)
          return;
        } else {
          // let verifyPin = '' + this.state.one + this.state.two + this.state.three + this.state.four;
          setTimeout(() => {
            if (this.props.login) {
              return;
            }
            this.props.onChange(event, 'verify');
          }, 400)
          window.scrollTo(0,0)
        }
      })
    }
  }

  deleteValue = () => {
    if (this.state.one && this.state.two && this.state.three && this.state.four) {
      this.setState({ four: '' }, this.update);
    }
    if (this.state.one && this.state.two && this.state.three && !this.state.four) {
      this.setState({ three: '' }, this.update)
    }
    if (this.state.one && this.state.two && !this.state.three && !this.state.four) {
      this.setState({ two: '' }, this.update)
    }
    if (this.state.one && !this.state.two && !this.state.three && !this.state.four) {
      this.setState({ one: '' }, this.update)
    }
  }

  renderTitle = () => {
    if (!this.props.login) {
      return (
        <div className="title-container">
          <div className="title" key="1">Create Pin</div>
          <div className="sub-title" key="2">Create a 4-digit pin for your security. You will be asked for this pin when you make your payments.</div>
        </div>
      )
    } else {
      return;
    }
  }

  render() {
    return (
      <Container>
        {this.renderTitle()}
        <div className="pin-container">
          <Input className={classNames('pin-input ios-only', {backgroundOne: this.state.one})}
                onChange={this.props.updateValue}
                id="one"
                type="text"
                maxLength={1}
                name="one"
                value={this.state.one}
                disabled/>
          <Input className={classNames('pin-input ios-only', {backgroundTwo: this.state.two})}
                onChange={this.props.updateValue}
                id="two"
                type="text"
                maxLength={1}
                name="two"
                value={this.state.two}
                disabled/>
          <Input className={classNames('pin-input ios-only', {backgroundThree: this.state.three})}
                onChange={this.props.updateValue}
                id="three"
                type="text"
                maxLength={1}
                name="three"
                value={this.state.three}
                disabled/>
          <Input className={classNames('pin-input ios-only', {backgroundFour: this.state.four})}
                onChange={this.props.updateValue}
                id="four"
                type="text"
                maxLength={1}
                name="four"
                value={this.state.four}
                disabled/>
        </div>
        <div className="number-pad">
          <div className="grid-wrapper">
            <div className="number py-1" data-test-id={"pincode-1-" + this.props.context} onClick={(num) => this.setValue(1)}>1</div>
            <div className="number py-2" data-test-id={"pincode-2-" + this.props.context} onClick={(num) => this.setValue(2)}>2</div>
            <div className="number py-3" data-test-id={"pincode-3-" + this.props.context} onClick={(num) => this.setValue(3)}>3</div>
            <div className="number py-4" data-test-id={"pincode-4-" + this.props.context} onClick={(num) => this.setValue(4)}>4</div>
            <div className="number" data-test-id={"pincode-5-" + this.props.context} onClick={(num) => this.setValue(5)}>5</div>
            <div className="number" data-test-id={"pincode-6-" + this.props.context} onClick={(num) => this.setValue(6)}>6</div>
            <div className="number" data-test-id={"pincode-7-" + this.props.context} onClick={(num) => this.setValue(7)}>7</div>
            <div className="number" data-test-id={"pincode-8-" + this.props.context} onClick={(num) => this.setValue(8)}>8</div>
            <div className="number" data-test-id={"pincode-9-" + this.props.context} onClick={(num) => this.setValue(9)}>9</div>
            <div className="number"></div>
            <div className="number" data-test-id={"pincode-0-" + this.props.context} onClick={(num) => this.setValue('0')}>0</div>
            <div className="delete" data-test-id={"pincode-delete-" + this.props.context} onClick={(num) => this.deleteValue()}>
              <div className="x">x</div>
              <div className="css-triangle"></div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}
