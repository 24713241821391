import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setNotification } from "../../js/actions/notifications";

//Components
import Background from '../../components/display/BackgroundWithLogo';
import Input from '../../components/inputs/Input';

//Assets
import email_icon from '../../assets/icons/email_icon_gray.png';

//API & Utils
import passwordAPI from '../../api/password/passwordAPI';
import globalUtils from '../../utils/globalUtils';

const FlexContainer = styled.div`
  display: flex;
  float: right;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  text-align: center;
  background-color: white;
  margin-right: 50px;
  height: 410px;
  margin-top: 200px;
  width: 549px;
  margin-right: 150px;
  margin-bottom: 20px;
  @media (max-width: 1250px) {
    left: 140px;
    position: absolute;
    top: 70px;
  }
  @media (max-width: 580px) {
    left: 25px;
    top: 45px;
    width: 65%;
  }
  @media (max-width: 420px) {
    width: 88%;
  }
`

const Title = styled.div`
  color: #5D5D5D;
  font-size: 30px;
  font-weight: bold;
  padding-top: 60px;
  margin-bottom: 20px;
  @media (max-width: 580px) {
    font-size: 26px;
  }
`

const SubTitle = styled.div`
  color: #858585;
  font-size: 23px;
  font-weight: 300;
  @media (max-width: 580px) {
    font-size: 20px;
  }
`

const SubTitleContainer = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
`

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  .has-error {
    margin-left: 60px;
    font-size: 18px;
  }
  @media (max-width: 580px) {
    margin-bottom: 20px;
  }
`

const Icon = styled.img`
  height: auto;
  width: 40px;
  position: absolute;
  left: 80px;
  top: 10px;
  @media (max-width: 580px) {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const LoginButton = styled.div`
  height: 80px;
  width: 316px;
  border-radius: 4px;
  background-color: #fd7272;
  cursor: pointer;
  position: relative;
`

const LoginText = styled.div`
  color: white;
  font-size: 24px;
  padding-top: 25px;
  text-align: center;
`

class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.myRefs = {
      email: React.createRef(),
    }
  }

  resetPassword = async () => {
    if (globalUtils.checkValues(this.myRefs) === false) {
      return;
    }
    if (!this.state.email) return;
    let reset = await passwordAPI.forgotPassword(this.state.email.toLowerCase());
    if (reset.err && reset.err.response.data.detail) {
      this.props.setNotification({message: reset.err.response.data.detail, type: 'error'})
    } else if (reset.err && !reset.err.message) {
      this.props.setNotification({message: reset.err, type: 'error'})
    } else {
      this.props.setNotification({message: 'Email has been sent. Please check your email'})
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name
    this.setState({
      [name]: value
    }, this.update)
  };


  render() {
    return (
      <Background forgotPassword="true">
        <FlexContainer>
          <Title>Forgot your password?</Title>
          <SubTitleContainer>
            <SubTitle>Enter your email below and we'll send you a secure link to reset your password</SubTitle>
          </SubTitleContainer>
          <InputWrapper>
            <Input
              placeholder="Email or username"
              id="email"
              name="email"
              onChange={this.handleChange}
              autoFocus
              ref={this.myRefs.email}
              custom
              required
              type="email"/>
            <Icon src={email_icon}/>
          </InputWrapper>
          <ButtonWrapper>
            <LoginButton data-test-id="forgot-password-reset" onClick={this.resetPassword}><LoginText>Reset Password</LoginText></LoginButton>
          </ButtonWrapper>
        </FlexContainer>
      </Background>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ForgotPassword)
