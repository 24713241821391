import React from 'react';
import { connect } from 'react-redux';
import history from '../../../history';

import { ModalContent } from '../generalModalStyling';

import Modal from '../NewModal';
import InputWrapper from '../../wrappers/InputWrapper';
import Input from '../../inputs/Input';
import Label from '../../display/Label';
import Typeahead from '../../inputs/Typeahead';
import Checkbox from '../../inputs/Checkbox';
import HideShow from '../../function/HideShow';
import validationRules from '../../../utils/ValidationRules';
import PhoneInput from '../../inputs/PhoneInput';
import Date from '../../inputs/Date';
import Address from '../../inputs/Address'

// API & Utils
import userAPI from '../../../api/customer/userAPI';
import merchantAPI from '../../../api/merchant/merchantAPI';
import globalUtils from '../../../utils/globalUtils';

// Redux
import { toggleAddCustomer } from '../../../js/actions/modal_actions';

class AddCustomerModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			merchant: {
				merchants: []
			},
			billerDisabled: false
		}

		this.myRefs = {
      first_name: React.createRef(),
      last_name: React.createRef(),
      merchant_id: React.createRef(),
      phone: React.createRef(),
      email: React.createRef(),
      date_of_birth: React.createRef(),
      external_id: React.createRef(),
      address: React.createRef(),
      is_business: React.createRef()
    }
	}

	componentDidMount() {
		this.getSelf();
	}

  getSelf = async () => {
    let data = await merchantAPI.getSelf(this.props.auth_token)
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return;
    }
    if(data.merchants && data.merchants.length === 1) {
      this.setState({
				merchant: data,
				merchant_id: data.merchants[0].id,
				display_biller: [{value: data.merchants[0].id, label: data.merchants[0].name}],
				billerDisabled: true
			})
    } else {
      this.setState({
        merchant: data
      })
    }
  }

  handleChange = (event) => {
    if (event.target) {
      const target = event.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      if (name === 'email') {
        let formatted = value.toLowerCase();
        value = formatted;
      }
      this.setState({
        [name]: value
      });
    } else {
      this.setState({
				merchant_id: event.value,
				display_biller: event
      })
    }
  };

  closeModal = () => {
    this.props.toggleAddCustomer(false)
  }

	addUser = async () => {
    if (!this.state.clicked){
      let customer = {}
      if (!this.state.is_business && (!this.state.service_address_zip && !this.state.date_of_birth)){
        this.setState({ errors: 'zip code or birthday is needed to signup!'})
        return;
      }
      if (this.state.is_business) {
        if (!this.state.business_name || !this.state.merchant_id || !this.state.phone || !this.state.email || !this.state.service_address) {
          this.setState({ errors: 'Please complete all required fields'});
          document.getElementById('business').focus()
          return;
        }
      } else {
        if (!this.state.first_name || !this.state.last_name || !this.state.merchant_id || !this.state.phone || !this.state.email) {
          this.setState({ errors: 'Please complete all required fields'});
          document.getElementById('business').focus()
          return;
        }
      }

      if (globalUtils.checkValues(this.myRefs) === false) {
        return;
      }
      let userInfo = Object.assign({}, this.state)
      delete userInfo.data;
      delete userInfo.errors;
      delete userInfo.merchant;
      delete userInfo.showAddCustomerModal;
			delete userInfo.loaded;
			delete userInfo.display_biller;
			delete userInfo.billerDisabled;

      this.setState({clicked: true})
      customer = await userAPI.createUser(userInfo, this.props.auth_token);
      if (customer.err && customer.err.response) {
        //eslint-disable-next-line
        let duplicateRegex = /Error creating user: InvalidAPIQuery\(\'A user with this email is already in your system/
        if(duplicateRegex.test(customer.err.response.data.detail)) {
          this.setState({
            errors: 'User with that email already in system',
            clicked: false
          });
        } else {
          this.setState({
            errors: customer.err.response.data.detail,
            clicked: false
          });
        }
        document.getElementById('modal').focus()
        return
      }
      this.setState({success: true});
			history.push('/admin/customers/profile/'+ customer.subscription_id)
			this.closeModal();
    }
  }

	onStateChange = (event) => {
	  if (event.value) {
			this.setState({
				service_address_state: event.value,
			})
		}
	}

	render() {
		return (
      <Modal context="add-customer" title="New Customer" onClose={this.closeModal} errors={this.state.errors} submit={this.addUser}>
        <ModalContent>
          <InputWrapper>
            <Checkbox text="Add as Business"
                name="is_business"
                id="business"
                data-test-id="is_business"
                onChange={this.handleChange}
                checked={this.state.is_business}/>
          </InputWrapper>
          <HideShow show={this.state.is_business}>
            <InputWrapper>
              <Label label="Business Name"/>
              <Input className="modal-input no-border"
                  placeholder="Enter Business Name"
                  name="business_name"
                  data-test-id="business_name"
                  onChange={this.handleChange}
                  validations={[validationRules.required]}
                //  id="modal"
                  onKeyUp={this.handleEnter}
                  required
                  ref={this.myRefs.business_name}/>
            </InputWrapper>
          </HideShow>
          <HideShow show={!this.state.is_business}>
            <InputWrapper>
              <Label label="First Name"/>
              <Input className="modal-input no-border"
                  placeholder="Enter First Name"
                  name="first_name"
                  data-test-id="first_name"
                  onChange={this.handleChange}
                  validations={[validationRules.required]}
                  id="modal"
                  onKeyUp={this.handleEnter}
                  required
                  ref={this.myRefs.first_name}/>
            </InputWrapper>
            <InputWrapper>
              <Label label="Last Name"/>
              <Input className="modal-input no-border"
                  placeholder="Enter Last Name"
                  name="last_name"
                  data-test-id="last_name"
                  required
                  onChange={this.handleChange}
                  ref={this.myRefs.last_name}/>
            </InputWrapper>
          </HideShow>
            <InputWrapper>
              <Label label="Biller Name"/>
              <Typeahead options={this.state.merchant}
                  placeholder="Search Locations"
                  name="merchant_id"
                  data-test-id="merchant_id"
                  required
                  onChange={this.handleChange}
                  isDisabled={this.state.billerDisabled}
									value={this.state.display_biller}
										// merchants.length === 1 ? [{value: this.state.merchant.merchants[0].id, label: this.state.merchant.merchants[0].name}] : null}
                  ref={this.myRefs.merchant_id}/>
            </InputWrapper>
            <InputWrapper>
              <Label label="Phone"/>
              <PhoneInput className="modal-input no-border"
                  placeholder="Enter Phone Number"
                  name="phone"
                  data-test-id="phone"
                  required
                  onChange={this.handleChange}
                  ref={this.myRefs.phone}/>
            </InputWrapper>

            <InputWrapper>
              <Label label="Email"/>
              <Input className="modal-input no-border"
                  type="email"
                  placeholder="Enter Email Address"
                  name="email"
                  data-test-id="email"
                  required
                  onChange={this.handleChange}
                  ref={this.myRefs.email}/>
            </InputWrapper>
            <HideShow show={!this.state.is_business}>
              <InputWrapper>
                <Label label="Birthday" or/>
                <Date className="modal-input no-border"
                    name="date_of_birth"
                    data-test-id="date_of_birth"
                    onChange={this.handleChange}
                    ref={this.myRefs.date_of_birth}/>
              </InputWrapper>
            </HideShow>
            <InputWrapper>
              <Address
								onChange={this.handleChange}
								onStateChange={this.onStateChange}
								ref={this.myRefs.address}/>
            </InputWrapper>
            <InputWrapper>
              <Label label="External ID" optional/>
              <Input className="modal-input no-border"
                  name="external_ID"
                  placeholder="External ID"
                  data-test-id="External_ID"
                  onChange={this.handleChange}/>
            </InputWrapper>
            <InputWrapper>
              <Checkbox text="Invite to opt in for texting"
                  name="sms_opt_in"
                  data-test-id="sms_opt_in"
                  onChange={this.handleChange}
                  checked={this.state.sms_opt_in}/>
            </InputWrapper>
        </ModalContent>
      </Modal>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
		invoice_customer: state.modals.invoice_customer
  };
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		toggleAddCustomer: (bool) => {dispatch(toggleAddCustomer(bool))}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCustomerModal);
