import React, {Component} from 'react';
import styled from 'styled-components';
import Typeahead from '../inputs/Typeahead';

const Container = styled.div`
  margin-top: 10px;
  margin-left: 8px;
  .basic-single {
    width: 180px;
  }
  .css-1aya2g8 {
    min-height: 10px;
    height: 35px;
    border: none;
  }
`

const dateOptions = [
	{ label: 'Today', value:'today'},
	{ label: 'Week-to-date', value:'week'},
	{ label: 'Month-to-date', value:'month'},
	{ label: 'Last 30 Days', value:'30'},
	{ label: 'Year-to-date', value:'ytd'},
];

const dropdownStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E',
    '&:placeholder': {
      color: '#A1A1A1'
    },
    '&:hover': {
      border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E'
    }
  })
};

export default class DateDropdown extends Component {
  constructor(props){
    super(props);
    this.state = {
      defaultOption: null
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { default_report_range } = nextProps;

    if ( default_report_range && default_report_range === 'today' ) {
      return {defaultOption: dateOptions[0]}
    } else if (default_report_range && default_report_range === 'week' ) {
      return {defaultOption: dateOptions[1]}
    } else if (default_report_range && default_report_range === 'month' ) {
      return {defaultOption: dateOptions[2]}
    } else if (default_report_range && default_report_range === '30' ) {
      return {defaultOption: dateOptions[3]}
    } else if (default_report_range && default_report_range === 'ytd' ) {
      return {defaultOption: dateOptions[4]}
    } else return prevState
  }

  setDate = (selectedOption) => {
    this.props.getData(selectedOption.value)
  }

  render() {
    return (
      <Container>
        <Typeahead
          options={dateOptions}
          className="date-dropdown"
          range_dropdown
          styles={dropdownStyles}
          onChange={this.setDate}
          value={this.state.defaultOption}
          name="range-dropdown"
          />
      </Container>
    )
  }
}
