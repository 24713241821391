import React, {Component} from 'react';
import styled from 'styled-components';
import CurrencyInput from 'react-number-format';

const Wrapper = styled.div`
  .no-border {
    border: none;
    border-bottom: 1px solid #A4A4A4;
    &:focus {
      border: 2px solid #64ABF5;
      outline: none;
    }
  }
`
const ErrorContainer = styled.div`
  color: #FD7272;
  text-align: left;
  margin-top: 7px;
  margin-left: 10px;
  font-size: 15px;
`

export default class CurrencyInputWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  handleEnter = (event) => {
    if (event.key === "Enter" && this.props.submit) {
      this.props.submit();
    } else {
      return;
    }
  }

  checkForErrors = (e) => {
    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    }
    if (this.props.required && e.target.value) {
      this.setState({error: ''})
    }
  }

  render() {
    return (
      <Wrapper {...this.state}>
        <CurrencyInput
              className="no-border"
              name={this.props.name}
              onChange={this.props.onChange}
              data-test-id={this.props.name}
              onKeyUp={this.handleEnter}
              type="text"
              required
              onBlur={(e) => this.checkForErrors(e)}
              disabled={this.props.disabled}
              {...this.props}
              thousandSeparator={true}
              prefix={'$'}
              decimalSeparator='.'
              decimalScale={2}
              fixedDecimalScale={true}
              value={this.props.value ? this.props.value : undefined}
              maxLength={this.props.maxLength ? this.props.maxLength : undefined}
              placeholder={this.props.placeholder ? this.props.placeholder : undefined}/>
        <ErrorContainer>{this.state.error}</ErrorContainer>
      </Wrapper>
    )
  }
}
