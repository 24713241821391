import React, {Component} from 'react';
import styled from 'styled-components';
import Button from '../buttons/Button';

const BigCard = styled.div`
  height: 250px;
  width: 355px;
  box-shadow: 0px 2px 5px #A8A8A8;
  border-radius: 5px;
  background-color: white;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-size: 18px;
  letter-spacing: -0.8px;
  font-weight: 900;
  padding-top: 20px;
`

const BillDescription = styled.div`
  font-size: 18px;
  color: #2E2C30;
  letter-spacing: -0.8px;
  padding-top: 15px;
`

const Amount = styled.div`
  font-size: 36px;
  color: #FF932D;
  letter-spacing: -0.73p;
  padding-top: 15px;
  font-weight: 900;
`

const DueDate = styled.div`
  font-size: 14px;
  color: #2B3A43;
  padding-top: 15px;
`

export default class BigCardMobileWrapper extends Component {
  render() {
    return (
      <BigCard>
        <Title> {this.props.title} </Title>
        <BillDescription> {this.props.description} </BillDescription>
        <Amount> {this.props.amount} </Amount>
        <DueDate> {this.props.date_text + " " + this.props.formatted_date} </DueDate>
        <Button secondary onClick={this.props.onClick}>{this.props.view_text}</Button>
      </BigCard>
    )
  }
}
