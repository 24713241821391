import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import classNames from 'classnames'
// import {Link} from 'react-router-dom'

//Components
import WhiteContainer from '../../../components/wrappers/WhiteContainer';
import Breadcrumb from '../../../components/display/BreadcrumbTitle';
import Initials from '../../../components/display/Initials';
import OptInInfo from '../../../components/alerts/OptInInfo';
import HideShow from '../../../components/function/HideShow';
import globalUtils from '../../../utils/globalUtils';
import TextArea from '../../../components/fields/TextArea';
import Table from '../../../components/tables/Table';

//Assets
import grid_view from '../../../assets/icons/gridView.png';
import list_view from '../../../assets/icons/listView.png';
import send_icon from '../../../assets/icons/send.png';
import sms_icon from '../../../assets/icons/message_icon_gray.png';
import email_icon from '../../../assets/icons/email_icon_gray.png';

//API
import userAPI from '../../../api/customer/userAPI';
import messagesAPI from '../../../api/messages/messagesAPI';

//Redux
import { RowDefinition, ColumnDefinition} from 'griddle-react';

const rowDataSelector = (state, { griddleKey }) => {
  return state
    .get('data')
    .find(rowMap => rowMap.get('griddleKey') === griddleKey)
    .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
  return {
    rowData: rowDataSelector(state, props)
  };
});

// const sortProperties = [
//   { id: 'first_name', sortAscending: true }
// ];

function CustomDateComponent({rowData}) {
  return (
    <div className="CustomIconComponent">
      {globalUtils.formatDate(rowData.created_at)}
    </div>
  );
}

const ContentContainer = styled.div`
  padding: 10px;
  display: flex;
  .left-container {
    width: 66%;
    height: auto;
    border-right: 1px solid gainsboro;
    .opted-out {
      padding: 10px 30px;
    }
    .icon-container {
      display: flex;
      justify-content: flex-end;
      border-bottom: 2px solid #EEE;
      padding-bottom: 15px;
      margin: 0 auto;
      width: 93%;
      img.icon {
        height: 35px;
        width: 75px;
        cursor: pointer;
      }
    }
    .show-grid {
      height: 400px;
      border-bottom: 2px solid #EEE;
    }
    .message-container {
      display: flex;
      flex-direction: column-reverse;
      height: 420px;
      border-bottom: 0px;
      padding-bottom: 15px;
      margin: 0 auto;
      width: 93%;
      overflow: auto;
      padding-top: 20px;
    
      @supports (-moz-appearance:none) {
        overflow-y: scroll;
        transform: scaleY(1);
      }
    
      .messages {
        @supports (-moz-appearance:none) {
          overflow: auto;
          margin-top: auto;
        }
      }
      .griddle-filter {
        display: none;
      }
      .sc-gFaPwZ.icecFF {
        margin-bottom: 170px;
        .cFpHWP {
          padding: 0px;
        }
      }
      ::-webkit-scrollbar {
        width: 11px;
      }
      ::-webkit-scrollbar-thumb:vertical {
        margin: 11px;
        background-color: #6CA2DC;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: white;
        border: 1px solid #DDD;
        border-radius: 5px;
      }
      .sms-message-body {
        clear: both;
        min-height: 40px;
        float: right;
        color: #fff;
        padding: 11px;
        background-color: #1989FE;
        border-radius: 17px;
        max-width: 70%;
        position: relative;
        word-wrap: break-word;
        margin-bottom: 8px;
        margin-right: 43px;
        margin-top: 15px;
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: -3px;
        right: 0px;
        width: 10px;
        height: 11px;
        background: #1989FE;
        border-bottom-left-radius: 10px;
        transform: rotate(-40deg);
      }
        img.medium-icon {
          height: 27px;
          width: auto;
          position: absolute;
          right: -37px;
          bottom: 7px;
        }
        .sms-date {
          color: #8E8E93;
          font-size: 12px;
          position: absolute;
          bottom: -17px;
          right: -5px;
          width: 125px;
        }
      }
      .sms-message-received-body {
        clear: both;
        min-height: 20px;
        float: left;
        color: #000;
        padding: 11px;
        background-color: #E5E5EA;
        border-radius: 17px;
        max-width: 70%;
        position: relative;
        word-wrap: break-word;
        margin-bottom: 8px;
        margin-right: 33px;
        margin-left: 50px;
        margin-top: 15px;
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: -3px;
        left: 0px;
        width: 10px;
        height: 11px;
        background: #E5E5EA;
        border-bottom-right-radius: 10px;
        transform: rotate(40deg);
      }
        img.medium-icon {
          height: 27px;
          width: auto;
          position: absolute;
          left: -42px;
          bottom: 7px;
        }
        .sms-date {
          color: #8E8E93;
          font-size: 12px;
          position: absolute;
          bottom: -17px;
          left: 0px;
          width: 125px;
        }
      }
    }
    .text-email {
      padding-top: 15px;
      padding-bottom: 5px;
    }
    .text-area-container {
      display: flex;
      .text-area {
        padding: 5px;
        width: 90%;
        height: 100px;
        resize: none;
        border-radius: 10px;
        border: 1px solid gainsboro;
        margin-left: 15px;
      }
      img.send-icon {
        height: 40px;
        width: auto;
        margin-top: 27px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .right-container {
    height: auto;
    width: 34%;
    display: flex;
    flex-direction: column;
    .show-grid {
      border-bottom: 2px solid #EEE;
    }
    .top-box {
      padding: 10px;
      border-bottom: 0px;
      .top-row,
      .middle-row {
        display: flex;
        &.middle-row {
          margin-top: 90px;
          padding-left: 5px;
        }
      }
      .bottom-row {
        padding-bottom: 20px;
        padding-top: 10px;
        .title {
          padding-bottom: 5px;
        }

      }
      .name-container {
        padding-left: 15px;
        padding-top: 10px;
        width: 80%;
        .name,
        .phone,
        .email {
          padding-bottom: 5px;
          text-transform: capitalize;
          &.email {
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .link {
          text-decoration: none;
          color: black;
          &:hover {
            color: #9beed9;
          }
        }
      }
      .opt-in-container {
        position: relative;
        width: 100%;
      }
    }
    .bottom-box {
      margin: 0 auto;
      .title {
        color: #FF715B;
        text-align: center;
        margin-top: 40px;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .dropdown-container {
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
        border-radius: 8px;
        width: 314px;
        height: 256px;
        overflow: auto;
        ::-webkit-scrollbar {
          width: 7px;
        }
        ::-webkit-scrollbar-thumb:vertical {
          margin: 11px;
          background-color: #6CA2DC;
          border-radius: 5px;
          -webkit-border-radius: 5px;
        }
        ::-webkit-scrollbar-track {
          background-color: white;
          border: 1px solid #DDD;
          border-radius: 5px;
        }
        .blue-text {
          padding: 10px 10px;
          padding-top: 0px;
          text-align: center;
          color: #6CA2DC;
          font-size: 15px;
        }
        .toggle {
          display: flex;
          justify-content: center;
        }
        .draggable-container {
          overflow: auto;
          .draggable-items {
            cursor: pointer;
            text-align: center;
            color: #5D5D5D;
            font-size: 18px;
            margin-bottom: 8px;
            user-select: all;
            padding: 2px;
            &::selection {
              color: #000;
            }
            &:hover {
              background-color: #E5E5EA;
              color: black;
            }
          }
        }

      }
    }
  }
`

const ToggleSwitchContainer = styled.div`
  display: flex;
  color: #06d6a0;
  border-radius: 11px;
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  .text,
  .email {
    border: 1px solid #06d6a0;
    padding: 5px;
    width: 76px;
    text-align: center;
    cursor: pointer;
    &.text {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      margin-left: 30px;
    }
    &.email {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .disabled {
    pointer-events: none;
    cursor: default;
    background-color: #E5E5EA;
    color: white;
    border: 1px solid #E5E5E5;
  }
  .values,
  .templates {
    border: 1px solid #6CA2DC;
    width: 97px;
    text-align: center;
    padding: 5px;
    color: #6CA2DC;
    cursor: pointer;
    &.values {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.templates {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .active {
    background-color: #06d6a0;
    color: #fff;
  }
  .active-blue {
    background-color: #6CA2DC;
    color: #fff;
  }
`

const MessageContain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`

const ReceivedContain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`

class MessageCustomer extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: {
        first_name: '',
        last_name: '',
      },
      messages: {},
      templates: {},
      body: '',
      showGrid: true,
      text: false,
      email: false,
      marketing: false,
      values: true,
      template_active: false,
      showTextArea: true
    };
    this.sub_id = this.props.match.params.id
  }

  componentDidMount = () => {
    this.getUserInfo();
    this.getChatData().then( () => {
      this.scrollToMessage()
    });
    this.getTemplateData();
    window.setInterval(() => {
      this.getChatData();
    }, 5000)
  }

  getUserInfo = async () => {
    let user = await userAPI.get(this.sub_id, this.props.auth_token);
    if (user && user.err) {
      this.setState({errors: user.err.response.data.detail})
      return
    }
    this.setState({ user, loaded: true })
    let messageInfo = {
      text: false,
      email: false,
      showTextArea: false
    };
    
    if(user.sms_opt_in && user.email_opt_in) {
      messageInfo.text = true; messageInfo.email = false; messageInfo.showTextArea = true;
    } else if (!user.sms_opt_in && user.email_opt_in) {
      messageInfo.email = true; messageInfo.showTextArea = true;
    } else if(user.sms_opt_in && !user.email_opt_in) {
      messageInfo.text = true; messageInfo.showTextArea = true;
    } else if(user.email_opt_in) {
      messageInfo.email = true; messageInfo.showTextArea = true;
    }
    this.setState({email: messageInfo.email, text: messageInfo.text, showTextArea: messageInfo.showTextArea})
  }

  getChatData = async () => {
    let query_string = "";
    query_string = "?sub_id=" + this.sub_id;
    let messages = await messagesAPI.getChat(this.props.auth_token, query_string);
    if (messages.err && messages.err.response) {
      this.setState({errors: messages.err.response.data.detail})
      return
    }
    this.setState({ messages, loaded: true });
  }

  getTemplateData = async () => {
    let templates = await messagesAPI.getTemplates(this.props.auth_token)
    if (templates.err && templates.err.response) {
      this.setState({errors: templates.err.response.data.detail})
      return
    }
    this.setState({ templates });
  }

  toggleGrid = () => {
    this.setState(prevState => {
      return {
        showGrid: !prevState.showGrid,
      };
    });
  }

  setActiveToggle = () => {
    if(this.state.user.sms_opt_in && this.state.user.email_opt_in) {
      this.setState(prevState => {
        return {
          text: !prevState.text,
          email: !prevState.email,
        };
      });
    }
  }

  setActiveAutomationToggle = () => {
    this.setState(prevState => {
      return {
        values: !prevState.values,
        template_active: !prevState.template_active,
      };
    });
  }

  returnMessages = () => {
    let messages = this.state.messages;
    let newArray = [];
    if (this.state.messages && this.state.loaded) {
      for (let i in messages) {
        if (messages[i].type === 1) {
          newArray.push(
            <MessageContain>
              <div className="sms-message-body" key={messages[i].id} id={messages[i].id}>
                <img src={messages[i].medium === 1 ? sms_icon : email_icon} className="medium-icon" alt={messages[i].medium === 1 ? 'message type sms' : 'message type email'}/>
                {messages[i].body}
                <div className="sms-date">{globalUtils.formatDateAndTime(messages[i].created_at)}</div>
              </div>
            </MessageContain>
            )
        }
        if (messages[i].type === 2) {
          newArray.push(
            <ReceivedContain>
              <div className="sms-message-received-body" key={messages[i].id} id={messages[i].id}>
                <img src={messages[i].medium === 1 ? sms_icon : email_icon} className="medium-icon" alt={messages[i].medium === 1 ? 'message type sms' : 'message type email'}/>
                {messages[i].body}
                <div className="sms-date">{globalUtils.formatDateAndTime(messages[i].created_at)}</div>
              </div>
            </ReceivedContain>
            )
        }
      }
      return newArray;
    } else {
      return;
    }
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };
  
  sendMessage = async (event) => {
    if(this.state.body) {
      let medium = ''
      if (this.state.email) {
        medium = 'email';
      } else {
        medium = 'sms'
      }
      
      if (!event.detail || +event.detail === 1){
        let response = await messagesAPI.sendMessage({medium: medium, sub_id: this.sub_id, body: this.state.body}, this.props.auth_token)
        if (response.err && response.err.response) {
          this.setState({errors: response.err.response.data.detail})
          return
        }
        this.setState({ body: ''});
        this.getChatData();
      }
    }
  }
  
  handleEnter = (event) => {
    if(event.key === 'Enter') {
      this.sendMessage(event)
    } 
  }

  onDoubleClick = (event, value) => {
    let current_body = this.state.body;
    this.setState({ body: current_body + ' ' +  value + ' '});
  }

  showTextArea = () => {
    if (this.state.showTextArea) {
      return (
        <div className="text-area-container">
            <TextArea
              id="txtMessageFields"
              className="text-area"
              placeholder="create message"
              name="body"
              data-test-id="body"
              onChange={this.onChange}
              value={this.state.body}
              onKeyPress={this.handleEnter}>
            </TextArea>
          <img src={send_icon} className="send-icon" data-test-id="msg-customer-send" onClick={this.sendMessage} tabIndex="0" autoFocus alt="send"/>
        </div>
      )
    } else {
      return (<div className="opted-out">Customer has opted out of receiving text and email messages.</div>)
    }
  }

  scrollToMessage = () => {
    if(this.props.location.hash) {
      let hash = this.props.location.hash.slice(1);
      let currentMessage = document.getElementById(hash);
      currentMessage.scrollIntoView();
    }
  }

  returnTemplates = () => {
    let templatesArray = [];
    if(this.state.templates && this.state.templates.length > 0){
      for (let i in this.state.templates){
        templatesArray.push(<div key={`template-${i}`} className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, this.state.templates[i].body)}>{this.state.templates[i].name}</div>)
      }
    }
    return templatesArray
  }

  render() {
    return (
      <>
        <Breadcrumb page="Conversation"/>
        <WhiteContainer>
          <ContentContainer>
            <div className="left-container">
              <div className="icon-container">
                <HideShow show={this.state.showGrid}>
                  <img src={grid_view} className="icon" data-test-id="msg-customer-grid" onClick={this.toggleGrid} alt="grid view"/>
                </HideShow>
                <HideShow show={!this.state.showGrid}>
                  <img src={list_view} className="icon" data-test-id="msg-customer-list" onClick={this.toggleGrid} alt="list view"/>
                </HideShow>
              </div>
              <HideShow show={this.state.showGrid}>
                <div className={classNames('message-container ', {'show-grid': this.state.showGrid})}>
                  <div className="messages">{this.returnMessages()}</div>
                </div>
                <ToggleSwitchContainer>
                  <div className={classNames('text', {'disabled': !this.state.user.sms_opt_in}, {'active': this.state.text})} data-test-id="msg-customer-text" onClick={this.setActiveToggle}>Text</div>
                  <div className={classNames('email', {'disabled': !this.state.user.email_opt_in}, {'active': this.state.email})} data-test-id="msg-customer-email" onClick={this.setActiveToggle}>Email</div>
                </ToggleSwitchContainer>
                {this.showTextArea()}
              </HideShow>
              <HideShow show={!this.state.showGrid}>
                <div className="message-container">
                  <Table data={this.state.messages}
                        tableClassName="table"
                        showSettings={false}
                        page="Conversation"
                        loaded={this.state.loaded}>
                        <RowDefinition>
                          <ColumnDefinition id="body" title="Body" />
                          <ColumnDefinition id="created_at" title="Sent/Received" customComponent={enhancedWithRowData(CustomDateComponent)}/>
                          <ColumnDefinition id="status" title="Status" />
                        </RowDefinition>
                  </Table>
                </div>
              </HideShow>
            </div>
            <div className="right-container">
              <div className={classNames('top-box ', {'show-grid': this.state.showGrid})}>
              <div className="top-row">
                <HideShow show={!this.state.user.business_name}>
                  <Initials size="medium" color="blue" first_name={this.state.user.first_name || " "} last_name={this.state.user.last_name || " "}></Initials>
                </HideShow>
                <HideShow show={this.state.user.business_name}>
                  <Initials size="medium" color="blue" business_name={this.state.user.business_name || " "}></Initials>
                </HideShow>
                <div className="name-container">
                  <HideShow show={!this.state.user.business_name}>
                    <div className="name"><a data-test-id="link-to-profile-1" className="link" href={"/admin/customers/profile/" + this.state.user.id}>{this.state.user.first_name} {this.state.user.last_name}</a></div>
                  </HideShow>
                  <HideShow show={this.state.user.business_name}>
                    <div className="name"><a data-test-id="link-to-profile-2" className="link" href={"/admin/customers/profile/" + this.state.user.id}>{this.state.user.business_name}</a></div>
                  </HideShow>
                  <div className="email">{this.state.user.email}</div>
                  <div className="phone">To: {this.state.user.formatted_phone}</div>
                  <div className="phone">From: {this.state.user.twilio_number}</div>
                </div>
              </div>
              <div className="middle-row">
                <div className="opt-in-container">
                  <OptInInfo getUserInfo={this.getUserInfo}/>
                </div>
              </div>
              <div className="bottom-row">
                <div className="title">Notes:</div>
                <div className="notes">{this.state.user.notes || ''}</div>
              </div>

              </div>
              <HideShow show={this.state.showGrid}>
                <div className="bottom-box">
                  <div className="title">Message Automation</div>
                  <div className="dropdown-container">
                    <div className="toggle">
                      <ToggleSwitchContainer>
                        <div className={classNames('values', {'active-blue': this.state.values})} data-test-id="msg-customer-values" onClick={this.setActiveAutomationToggle}>Values</div>
                        <div className={classNames('templates', {'active-blue': this.state.template_active})} data-test-id="msg-customer-templates" onClick={this.setActiveAutomationToggle}>Templates</div>
                      </ToggleSwitchContainer>
                    </div>
                    <div className="blue-text">Double click to add field</div>
                    <div className="draggable-container">
                      <HideShow show={this.state.values && !this.state.user.business_name && this.state.loaded}>
                        <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{user_first_name}')}>{'{user_first_name}'} </div>
                        <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{user_last_name}')}>{'{user_last_name}'} </div>
                        <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{user_full_name}')}>{'{user_full_name}'} </div>
                        <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{merchant_name}')}>{'{merchant_name}'} </div>
                      </HideShow>
                      <HideShow show={this.state.values && this.state.user.business_name && this.state.loaded}>
                        <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{user_business_name}')}>{'{user_business_name}'} </div>
                        <div className="draggable-items" draggable="true" onDoubleClick={(event, string) => this.onDoubleClick(event, '{merchant_name}')}>{'{merchant_name}'} </div>
                      </HideShow>
                      <HideShow show={this.state.template_active && this.state.loaded}>
                        {this.returnTemplates()}
                      </HideShow>

                    </div>
                  </div>
                </div>
              </HideShow>
            </div>
          </ContentContainer>
        </WhiteContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

export default connect(
  mapStateToProps,
)(MessageCustomer)
