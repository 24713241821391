export const SET_FROM_INVOICE = "SET_FROM_INVOICE";
export const STORE_FROM_RECEIPT = "STORE_FROM_RECEIPT";
export const TOGGLE_PAYMENT_TYPES = "TOGGLE_PAYMENT_TYPES";
export const SET_PAYMENT_SLOT = "SET_PAYMENT_SLOT";
export const CLEAR_PAYMENT_METHODS = "CLEAR_PAYMENT_METHODS";
export const SET_SPLIT_METHOD = "SET_SPLIT_METHOD";

export const setFromInvoice = from_invoice => ({ type: SET_FROM_INVOICE, payload: from_invoice});
export const storeFromReceipt = from_receipt => ({ type: STORE_FROM_RECEIPT, payload: from_receipt});

export const togglePaymentTypes = (bool, target) => {
  if(target) {
    return { type: TOGGLE_PAYMENT_TYPES, target, payload: bool }
  } else {
    return { type: TOGGLE_PAYMENT_TYPES, payload: bool }
  }
};

export const setPaymentSlot = (slot) => ({ type: SET_PAYMENT_SLOT, payload: slot })

export const clearPaymentMethods = () => ({ type: CLEAR_PAYMENT_METHODS, payload: {method_one: {}, method_two: {}} })

export const setSplitMethod = (method) => ({ type: SET_SPLIT_METHOD, payload: method });