import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'payment_methods';

/*
function clientCallback(responseCode, responseMessage, proxyNumber){
  if(responseCode == "A01"){
    this.proxyNumber = proxyNumber;
  } else{
    return false;
  }
}
*/

class PaymentMethodAPI {
  getList = async (auth_token, user_pin, query_string) => {
    var final_url = url
    if (typeof query_string !== "undefined"){
      final_url += query_string
    }
    return apiUtils.get(final_url, auth_token, user_pin)
  }
  addPaymentMethod = (data, auth_token, user_pin, callback) => {
    return apiUtils.post(url, data, auth_token, user_pin, callback);
  };
  // for hosted payment
  getHPPaymentMethods = (auth_token) => {
    return apiUtils.getCustom(url + '/get_payment_methods', auth_token)
  }
}

export default new PaymentMethodAPI();
