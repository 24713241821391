import { 
  SET_NOTIFICATION,
  HIDE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  STORE_INBOX_BADGE 
} from '../actions/notifications';

const initialState = {
  notifications: [],
  mobileNotifications: [],
  inbox_badge: undefined
}

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case STORE_INBOX_BADGE:
      return Object.assign({}, state, { inbox_badge: action.payload });
    case SET_NOTIFICATION:
      let alreadySent = false;
      state.notifications.forEach(notification => {
        if(notification.message.includes(action.payload.message)) {
          alreadySent = true;
        }
      })
      if(action.payload.mobile) {
        state.mobileNotifications.forEach(notification => {
          if(notification.message.includes(action.payload.message)) {
            alreadySent = true;
          }
        })
        if(!alreadySent) {
          let notificationArray = state.mobileNotifications.slice();
          let newNotification = action.payload;
          newNotification.id = Math.random();
          notificationArray.push(newNotification);
          return Object.assign({}, state, { mobileNotifications: notificationArray });
        }
      } else {
        state.notifications.forEach(notification => {
          if(notification.message.includes(action.payload.message)) {
            alreadySent = true;
          }
        })
        if(!alreadySent) {
          let notificationArray = state.notifications.slice();
          let newNotification = action.payload;
          newNotification.id = Math.random();
          notificationArray.push(newNotification);
          return Object.assign({}, state, { notifications: notificationArray });
        }
      }
      
      return state;
      
    case HIDE_NOTIFICATION:
      if(action.payload.mobile) {
        let removeNotification = state.mobileNotifications.filter( notification => notification.id !== action.payload.id)
        return Object.assign({}, state, { mobileNotifications: removeNotification })
      } else {
        let removeNotification = state.notifications.filter( notification => notification.id !== action.payload.id)
        return Object.assign({}, state, { notifications: removeNotification })
      }
    case CLEAR_NOTIFICATIONS:
      if(action.payload.mobile) {
        return Object.assign({}, state, {mobileNotifications: action.payload.notifications})
      } else return Object.assign({}, state, {notifications: action.payload.notifications});
    default:
      return state;
  }
};

export default notifications;
