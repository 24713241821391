import React, {Component} from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
  margin-bottom: ${props => props.addCard ? '5px' : '12px'};
  padding: ${props => props.noPadding ? '0' : '0 25px'};
  position: relative;
  @media (max-width: 580px) {
    min-width: 50%;
  }

  /* @media (min-device-height: 813px) {
    margin-bottom: 20px;
  }
   @media (min-device-width: 1024px) {
    margin-bottom: 12px;
  }*/
`;

export default class InputContentWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <InputWrapper {...this.props}>{this.props.children}</InputWrapper>
    )
  }
}
