import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ContentWrapper from '../../components/wrappers/ContentContainer';
import AdminDashboard from './dashboard/AdminDashboard';
import CustomerIndexList from './customers/IndexList';
import PaymentsIndexList from './payments/IndexList';
import ReportsIndexList from './report/IndexList';
import InvoicesIndexList from './invoices/IndexList';
import MessagingIndexList from './messaging/indexList';
import OnboardingWrapper from './Onboarding';
import AdminSettings from './settings/AdminSettings';
import AdminHeader from '../../components/display/AdminHeader';
import CustomerDetail from './customers/NewDetail';
// import CustomerDetail from './customers/CustomerDetail';
import TemplatesIndexList from './templates/IndexList';
import TemplateDetail from './templates/TemplateDetail';
import UploadCustomer from './customers/UploadCustomer';
import UploadInvoice from './invoices/UploadInvoice';
import UploadPayment from './payments/UploadPayment';
import InvoiceDetail from './invoices/InvoiceDetail';
import MessageDetail from './messaging/MessageDetail';
import PaymentPlanDetail from './invoices/PaymentPlanDetail';
import MessageCustomer from './messaging/MessageCustomer';
import CreatePassword from './CreatePassword';
import Automation from './automation/Automation';
import GradientContainer from '../../components/wrappers/GradientContainer';
import Caliber from './integrations/caliberLockbox';
import withLoginRedirect from '../public/LoginRedirect/withLoginRedirect';

function AdminIndex() {
  return (
    <ContentWrapper>
      <AdminHeader/>
      <GradientContainer>
        <Switch>
          <Route
            key={0}
            exact
            path="/admin"
            component={AdminDashboard}
          />
          <Route
            key={1}
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          <Route
            key={2}
            exact
            path="/admin/customers"
            component={CustomerIndexList}
          />
          <Route
            key={3}
            exact
            path="/admin/customers/profile/:id"
            component={CustomerDetail}
          />
          <Route
            key={4}
            exact
            path="/admin/customers/upload"
            component={UploadCustomer}
          />
          <Route
            key={5}
            exact
            path="/admin/conversations"
            component={MessagingIndexList}
          />
          <Route
            key={5}
            exact
            path="/admin/conversations/detail/:id"
            component={MessageDetail}
          />
          <Route
            key={16}
            exact
            path="/admin/conversations/:id"
            component={MessageCustomer}
          />
          <Route
            key={6}
            exact
            path="/admin/payments"
            component={PaymentsIndexList}
          />
          <Route
            key={7}
            exact
            path="/admin/reports"
            component={ReportsIndexList}
          />
          <Route
            key={8}
            exact
            path="/admin/invoices"
            component={InvoicesIndexList}
          />
          <Route
            key={9}
            exact
            path="/admin/invoices/detail/:id"
            component={InvoiceDetail}
          />
          <Route
            key={10}
            exact
            path="/admin/invoices/payment-plan/:id"
            component={PaymentPlanDetail}
          />
          <Route
            key={11}
            exact
            path="/admin/invoices/upload"
            component={UploadInvoice}
          />
          <Route
            key={12}
            path="/admin/onboarding/:id"
            component={OnboardingWrapper}
          />
          <Route
            key={13}
            path="/admin/settings"
            component={AdminSettings}
          />
          <Route
            key={14}
            exact
            path="/admin/message-templates"
            component={TemplatesIndexList}
          />
          <Route
            key={15}
            exact
            path="/admin/message-templates/:id"
            component={TemplateDetail}
          />
          <Route
            key={16}
            exact
            path="/admin/create-password"
            component={CreatePassword}
          />
          <Route
            key={17}
            exact
            path="/admin/payments/upload"
            component={UploadPayment}
          />
          <Route
            key={18}
            exact
            path="/admin/automation"
            component={Automation}
          />
          <Route
            key={19}
            exact
            path="/admin/caliber"
            component={Caliber}
          />
        </Switch>
      </GradientContainer>
    </ContentWrapper>
  );
}

export default withLoginRedirect(AdminIndex)