import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//API & Utils
import templatesAPI from '../../../api/messages/templatesAPI';
import globalUtils from '../../../utils/globalUtils';

const DetailWrapper = styled.div`
  background: white;
  height: auto;
  width: 40%;
  margin-right: 35px;
`

const TemplateName = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 40px;
`

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TemplateText = styled.div`
  padding: 50px;
  text-align: start;
  line-height: 1.5;
  font-size: 22px;
  padding-top: 20px;
  width: 100%;
  word-wrap: break-word;
`

const TemplateDesc = styled.div`
  font-size: 26px;
  color: #8E8E93;
  text-align: center;
  margin-top: 15px;
`

class TemplateDetailWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      loaded: false
    };
    this.templateId = this.props.match.params.id;
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = async () => {
    let data = await templatesAPI.getMessageDetail(this.props.auth_token, undefined, undefined, {id: this.templateId})
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }
    this.setState({data: data});
  }

  render() {
    return (
      // <GradientContainer>
      <>
        <TemplateName>{globalUtils.replaceText(this.state.data.name)}</TemplateName>
          <PageContainer>
            <DetailWrapper>
              <TemplateDesc> Message Preview </TemplateDesc>
              <TemplateText>{this.state.data.preview_message}</TemplateText>
            </DetailWrapper>
            <DetailWrapper>
              <TemplateDesc> Raw Message </TemplateDesc>
              <TemplateText>{this.state.data.raw_message}</TemplateText>
            </DetailWrapper>
          </PageContainer>
      </>
      // </GradientContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

export default connect(
  mapStateToProps
)(TemplateDetailWrapper)
