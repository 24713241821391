import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components';
import { connect } from 'react-redux';
import CardConnect from '../../../utils/CardConnect';
import Input from '../../../components/inputs/Input';
import Cleave from 'cleave.js/react';
import HideShow from '../../../components/function/HideShow';
import PaymentMethodAPI from '../../../api/blytzwallet/PaymentMethod';
import userAPI from '../../../api/customer/userAPI';
import BottomButton from '../../../components/buttons/mobile/BottomButton';
import { setNotification } from '../../../js/actions/notifications';
import AutopayDetail from './AutopayDetail';
import AutopayContent from '../../../components/modals/payments/AutopayContent';


// import HideShow from '../../../components/function/HideShow'



//Redux
import { setPaymentMethod } from '../../../js/actions/auth';
import { setFromInvoice } from '../../../js/actions/invoices';

//API & Utils
import globalUtils from '../../../utils/globalUtils';


const Heading = styled.p`
    font-size: 19px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CreditCard = styled.img`
  width: 35px;
  height: auto;
  position: absolute;
  right: 10px;
  top: 24px;
`

const Container = styled.div`
    ${props => props.open ? "transform: translateY(0);" : "transform: translateY(100%);"}
    transition: transform .5s ease-out;
    height: 100%;
    width: 100vw;
    background-color: #FFFFFF;
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Cancel = styled.div`
    height: 70px;
    text-align: center;
    width: 100%;
    background-color: #545E75;
    padding: 20px;
    & > div {
      color: #fff;
      font-size: 24px;
      line-height: 2;
`

const AutopayForm = styled.div`
        height: calc(100vh - 130px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: auto;
`

class CustomerAutopay extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showAutoPayModal: false,
          customized: false,
          showDayPicker: false,
          autopay_day: null,
          autopay_frequency: '',
          card: {},
          payment_method: null,
          autopay_amount: null,
          expiration: undefined,
          open: false,
          page: ''
      }

        this.myRefs = {
          name: React.createRef(),
          card_number: React.createRef(),
          expiration: React.createRef(),
          address_zip: React.createRef(),
          autopay_frequency: React.createRef(),
          autopay_amount: React.createRef(),
          autopay_day: React.createRef(),
          autopay_method: React.createRef(),
        }
    }

    componentDidMount(){
        setTimeout( () => {
            this.setState({
                open: true
            })
        }, 10)
    }

    onChange = (event) => {
      const newData = {}
      if (event.target) {
          const target = event.target;
          const value = target.type === 'checkbox' ? target.checked : target.value;
          newData[target.name] = value
      } else {
          newData[event.name] = event.value
      }
      this.setState(newData);
    };
  
    toggleNewMethod = () => {
        this.setState({
            showAutoPayModal: !this.state.showAutoPayModal,
        });
    }
  
    handleFrequencyChange = (event) => {
        if (event.target) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            this.setState({
                [name]: value,
                autopay_day: null
            });
        } else {
            this.setState({
            autopay_frequency: event.value,
            showDayPicker: event.value === 'monthly', 
            autopay_day: null
            })
        }
    };
  
    handleDayClick = (e, showDayPicker) => {
        this.setState({
            autopay_day: e.target.value,
            showDayPicker: showDayPicker,
        })
    };
  
    checkLastBill = () => {
        if (this.props.customer.last_invoice) {
            return (
            <>
                <div className="blue-text">Billed on: {globalUtils.formatDate(this.props.customer.last_invoice.due_date)}</div>
                <div className="blue-text">Amount: {globalUtils.convertToDollars(this.props.customer.last_invoice.amount)}</div>
            </>
            )
        } else {
            return (
            'None found'
            )
        }
    }
  
    checkNextBill = () => {
        if (this.props.customer.next_invoice) {
            return (
            <>
                <div className="blue-text">Bill on: {globalUtils.formatDate(this.props.customer.next_invoice.due_date)}</div>
                <div className="blue-text">Amount: {globalUtils.convertToDollars(this.props.customer.next_invoice.amount)}</div>
            </>
            )
        } else {
            return (
            'None found'
            )
        }
    }
  
    setToken = (token) => {
        if (token && token.validationError) {
            this.setState({ errors: token.validationError})
            return;
        } else {
            let newCard = globalUtils.checkCardType(token.substring(1,2), this.state.card);
            newCard.token = token;
            newCard.last4 = token.slice(-4);
            this.setState({
            card: newCard,
            errors: null
            })
        }
    }
  
    showForm = () => {
        if (this.state.showAutoPayModal) {
            return (
            <HideShow show={this.state.showAutoPayModal}>
                <div className="new-method-container">
                <div className="input-wrapper">
                    <div className="custom-label">Card Number</div>
                    <CardConnect
                    className="input"
                    card
                    type="tel"
                    name="card_number"
                    data-test-id="card_number"
                    options={{creditCard: true}}
                    onChange={this.onChange}
                    autoComplete="cc-number"
                    required
                    ref={this.myRefs.card_number}
                    setToken={(token) => this.setToken(token)}/>
                    {this.renderIcon()}
                </div>
                <div className="input-wrapper">
                    <div className="custom-label">Name on Card</div>
                    <Input name="name" noBorder={true} onChange={this.onChange}/>
                </div>
                <div className="space-between">
                    <div className="expiration">
                    <div className="custom-label">Expiry Date</div>
                    <Cleave placeholder="mm/yy"
                            type="tel"
                            className="no-border"
                            name="expiration"
                            data-test-id="expiration"
                            options={{date: true, datePattern: ['m','y']}}
                            onChange={this.onChange}
                            value={this.state.card.expiration}/>
                    </div>
                    <div className="zip">
                    <div className="custom-label">Billing Zip Code</div>
                    <Input name="address_zip" noBorder={true} onChange={this.onChange}/>
                    </div>
                </div>
                </div>
            </HideShow>
  
            )
        }
    }
  
    onPaymentOptionChange = (event) => {
        if (event.value) {
            this.setState({
            payment_method: event.value
            });
        }
    }
  
    updateAutopayment = async () => {
        if(!this.state.payment_method && !this.state.card.token) {
            this.setState({errors: 'Please verify your payment method'})
            return;
        }
        
        if (this.state.customized && (!this.state.autopay_amount || !this.state.autopay_frequency || !this.state.autopay_day)) {
            this.setState({errors: 'Please complete amount and frequency fields.'})
            return;
        }
        let payment_info = Object.assign({}, this.state.card)
        payment_info['expiration'] = this.state.expiration;
        payment_info['sub_id'] = this.props.invoice.subscription.id;
  
        if (this.state.payment_method) {
            payment_info.autopay_method = this.state.payment_method;
        } else {
            let method = await PaymentMethodAPI.addPaymentMethod(payment_info, this.props.auth_token)
            if (method && !method.err){
            payment_info.autopay_method = method.payment_method_id
            } else {
            this.setState({errors: method.err})
            }
        }
        payment_info.autopay_amount = globalUtils.convertDollarsToCents(this.state.autopay_amount);
        payment_info.autopay_frequency = this.state.autopay_frequency
        if (this.state.autopay_day instanceof Date) {
            payment_info.autopay_day = this.state.autopay_day.getDate()
        } else {
            payment_info.autopay_day = this.state.autopay_day
        }
        if (payment_info['autopay_day']){
            payment_info['customized'] = true
        }
        if (!payment_info['sub_id']){
            return;
        }
        let response = await userAPI.setAutopay(payment_info, this.props.auth_token);
        if (response && !response.err) {
            this.props.setNotification({message: "Autopay updated."})
            this.props.onClose();
            this.props.getData()
            return;
        } else {
            const errData = response.err.response.data
            this.setState({errors: errData.detail})
        }
    }
  
    renderIcon = () => {
        if (typeof this.state.card === "undefined"){
            return;
        }
        if (this.state.card.card_type === "visa") {
            return <CreditCard src="https://s3.amazonaws.com/blytz/assets/visa.svg"/>
        } else if (this.state.card.card_type === "mastercard") {
            return <CreditCard src="https://s3.amazonaws.com/blytz/assets/mastercard.svg"/>
        } else if (this.state.card.card_type === "amex") {
            return <CreditCard src="https://s3.amazonaws.com/blytz/assets/amexIcon.svg"/>
        } else if (this.state.card.card_type === "discover") {
            return <CreditCard src="https://s3.amazonaws.com/blytz/assets/discover.svg"/>
        }
        else {
            return;
        }
    }
  
    disableAutopay = async () => {
      let response = await userAPI.setAutopay({autopay: null, sub_id: this.props.invoice.subscription.id, cancelled: true }, this.props.auth_token);
      if (response && !response.err) {
          this.props.setNotification({message: 'Autopay disabled.'})
          this.props.onClose()
          this.props.getData()
      } else {
          this.setState({
            errors: response.err
          })
      }
    }
  
    returnAutopayText = () => {
      if (this.props.customer.autopay_method) {
        return 'Disable Autopay';
      }
    }

    onClose = () => {
        this.setState({
            open: false
        })
        setTimeout( () => {
            this.props.onClose();
        }, 510)
    }

    selectCard = card => {
        this.setState({
            page: card
        })
    }

    render() {
        return (
            <Container open={this.state.open}>
                <Cancel className="cancel" data-test-id="split-pmt-close" onClick={this.onClose}>
                    <div>Cancel</div>
                </Cancel>
                {this.props.autopay.autopay_enabled ?
                    <AutopayForm>
                        <Heading>Auto-pay Settings</Heading>
                        <AutopayDetail 
                            autopay={this.props.autopay} 
                            payment_methods={this.props.payment_methods} 
                            invoice={this.props.invoice}
                            disableAutopay={this.disableAutopay}
                        />
                    </AutopayForm> 
                        : 
                    <AutopayForm>
                    <HideShow show={(this.state.page === "autopay")}>
                        <Heading>Auto-pay</Heading>
                    </HideShow>
                    <AutopayContent 
                        customer={this.props.customer}
                        payment_methods={this.props.payment_methods}
                        context='customer-autopay'
                        sub_id={this.props.invoice.subscription.id}
                        onPaymentOptionChange={this.onPaymentOptionChange}
                        toggleNewMethod={this.toggleNewMethod}
                        showForm={this.showForm}
                        onChange={this.onChange}
                        handleFrequencyChange={this.handleFrequencyChange}
                        myRefs={this.myRefs}
                        handleDayClick={this.handleDayClick}
                        showAutoPayModal={this.state.showAutoPayModal}
                        customized={this.state.customized}
                        autopay_day={this.state.autopay_day}
                        autopay_frequency={this.state.autopay_frequency}
                        showDayPicker={this.state.showDayPicker}
                        noPadding={true}
                        page={this.state.page}
                        width='305px'
                        selectCard={this.selectCard}
                    />
                    </AutopayForm>
                }
                {!this.props.autopay.autopay_enabled && this.state.page !== '' ? <BottomButton absolute={false} data-test-id="customer-autpay-save" onClick={this.updateAutopayment}>Save</BottomButton> : ""}
            </Container>    
        )  
    }
}

CustomerAutopay.propTypes = {

}

const mapStateToProps = (state) => {
    return {
      from_invoice: state.invoices.from_invoice,
      merchant_logo: state.merchants.merchant_logo,
      auth_token: state.auth.auth_token,
      user_pin: state.auth.user_pin,
      payment_method: state.auth.payment_method,
      user_id: state.auth.user_id,
      user_info: state.auth.user_info
    };
  };
  
  const mapDispatchToProps = (dispatch, props) => {
    return {
      setPaymentMethod: (payment_method) => {dispatch(setPaymentMethod(payment_method))},
      setFromInvoice: (from_invoice) => {dispatch(setFromInvoice(from_invoice))},
      setNotification: (notification) => {dispatch(setNotification(notification))}
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomerAutopay)
