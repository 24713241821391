import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import globalUtils from '../../utils/globalUtils';

//Components
import Background from '../../components/mobile/MobileBackground';
import PinCode from '../../components/signup/Pincode';
import MobileButton from '../../components/mobile/MobileButton';
import ErrorHandle from '../../components/function/ErrorHandle';
import MobileModal from '../../components/mobile/MobileModal';
import HideShow from '../../components/function/HideShow'
import Input from '../../components/inputs/Input';
import InputWrapper from '../../components/wrappers/InputWrapper';

//Assets
import blytz_logo from '../../assets/images/logo-gradient.png';

//Redux
import { setUserId, setUserInfo, loginCustomer, storeCustomerAuth, isMerchant, storeCustomerUsername } from '../../js/actions/auth';
import { setFromInvoice } from '../../js/actions/invoices';

//API & Utils
import userInfoAPI from '../../api/user/userInfoAPI';
import loginAPI from '../../api/login/loginAPI';
import userAPI from '../../api/customer/userAPI';
import customerAuthAPI from '../../api/auth/customerAuthAPI';
import withLoginRedirect from '../public/LoginRedirect/withLoginRedirect';

const ModalContainer = styled.div`
  .sub-title {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .label {
    margin-bottom: 10px;
    text-align: center;
  }
  input {
    border: 1px solid gainsboro;
    padding: 20px;
    width: 100%;
  }
  .errors {
    margin-top: 10px;
    color: #FD7272;
    text-align: center;
    margin-bottom: 10px;
  }
  .button-wrapper {
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    .submit {
      background-color: #03d368;
      color: #fff;
      width: 225px;
      height: 40px;
      margin: 0 auto;
      line-height: 2.5;
    }
  }
`

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .button {
    margin-top: 20px;
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 */
      margin-top: 0px;
    }
  }
  @media only screen and (max-device-height: 667px) {
    padding-top: 20px;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    @media (max-device-height: 667px) {
      padding-top: 3px;
    }
  }
  @media (min-height: 736px) {
    padding-top: 80px;
  }
  @media screen and (min-device-height: 736px) {
    padding-top: 40px;
  }
  @media screen and (min-device-height: 896px) {
    padding-top: 80px;
  }
  @media only screen and (min-width: 768px) {
    padding-top: 80px;
  }

`

const LogoContainer = styled.div`
  margin: 0 auto;
  margin-bottom: -30px;
  @media only screen and (min-width: 768px) {
    margin-bottom: 0px;
  }
`

const BlytzLogo = styled.img`
  width: 215px;
  height: auto;
`

const Text = styled.div`
  text-align: center;
  color: #fff;
  font-size: 16px;
  width: 65%;
  margin: 0 auto;
  margin-bottom: 10px;
`

const ErrorContainer = styled.div`
  text-align: center;
  margin-top: 15px;
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: white;
`

const Forgot = styled.div`
  font-size: 14px;
  margin-right: 5px;
  cursor: pointer;
`

const Link = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: white;
  cursor: pointer;
  &:visited {
    color: white;
  }
`;

class CustomerLogin extends Component {
  constructor(props){
    super(props);
    const values = globalUtils.getQueryString(this.props.location.search.slice(1))

    this.state = {
      showTooltip: false,
      invoice: this.invoice,
      user_pin: "",
      showEmailModal: false,
      username: values.username,
      code: values.code
    };
  }

  loadUser = async () => {
    let response = await userInfoAPI.getSelf(this.props.auth_token);
    if (response.err && response.err.response) {
      this.setState({errors: response.err.response.data.detail})
      return
    }
    this.props.setUserInfo(response)
  }

  login = async () => {
    let loginData = {customer_login: true, username: this.props.username, pin: this.state.user_pin}
    let should_login = await loginAPI.customerLogin(loginData);

    if (should_login.err) {
      return this.setState({ errors: should_login.err.response.data.detail})
    }

    if (should_login.id) {
      this.props.storeCustomerAuth(should_login.auth_token)
      this.props.setUserId(should_login.id)
      this.setState({attempt_login: true, user_info: should_login})
      this.props.loginCustomer(this.state.user_pin)
      this.props.isMerchant(false);
      this.loadUser();
    }
    if (!should_login.email) {
      return this.setState({showEmailModal: true});
    }
    if (should_login.status === 403) {
        this.setState({errors: 'Invalid Pin Code'})
    } else {
      if (this.props.from_invoice) {
        this.props.history.push('/customer/blytzwallet/invoice/' + this.props.from_invoice);
        return
      } else if (this.props.from_receipt) {
        this.props.history.push('/customer/receipts/paid/' + this.props.from_receipt);
        return
      } else {
        this.props.history.push('/customer/dashboard');
        return
      }
    }
    return
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      this.login();
    } else {
      return;
    }
  }

  updateValue = (user_pin) => {
    this.setState({user_pin: user_pin}, () => user_pin.length === 4 ? this.login() : null);
  }

  rerouteToReset = () => {
    this.props.history.push('/customer/reset');
  }

  componentDidUpdate = (nextProps) => {
    if (!nextProps.auth_token) {
      return
    }
    if (!nextProps.user_info) {
      return
    }
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
        [name]: value
    });
  }

  updateEmail = async () => {
    if (!this.state.email) {
      return this.setState({errors: 'Email is required'});
    }
    let response = await userAPI.updateUser(this.state.user_info.id, {email: this.state.email}, this.props.auth_token);
    if (response && response.err) {
      return this.setState({errors: response.err.response.data.detail});
    }
    this.props.history.push('/customer/dashboard');
    this.setState({showEmailModal: false})
  }

  renderText = () => {
    if (this.state.code) {
      return (
        <Text>Please choose a 4-digit pin</Text>
      )
    } else {
      return (
        <Text>Please enter your 4-digit pin to access your account</Text>
      )
    }
  }

  renderButton = () => {
    if (this.state.code) {
      return (
        <MobileButton small className="button" data-test-id="login-reset-pin" onClick={this.resetPin}>Reset PIN</MobileButton>
      )
    } else {
      return (
        <MobileButton small className="button" data-test-id="login-btn-2" onClick={this.login}>Login</MobileButton>
      )
    }
  }

  resetPin = async () => {
    const { username, user_pin, code } = this.state
    let resetData = await customerAuthAPI.resetCustomerPin({username: username, pin: user_pin, code: code});
    if (resetData.err) {
      return this.setState({errors: resetData.err.response.data.detail});
    }
    this.props.storeCustomerUsername(username)
    this.props.setFromInvoice(undefined);
    this.login();

  }

  renderForgotText = () => {
    if (this.state.code) {
      return;
    }
    return (
      <TextWrapper>
        <Forgot data-test-id="login-forgot-pin" onClick={this.rerouteToReset}>Forgot PIN?</Forgot>
        <Link data-test-id="login-reset-here" onClick={this.rerouteToReset}>Reset it here.</Link>
      </TextWrapper>
    )
  }

  render() {
    return (
      <>
        <HideShow show={!this.state.showEmailModal}>
          <Background fixed>
            <PageContainer>
              <LogoContainer><BlytzLogo src={blytz_logo}/></LogoContainer>
              <ErrorContainer>
                <ErrorHandle data={this.state.errors}/>
              </ErrorContainer>
              {this.renderText()}
              <PinCode context= "login" value={this.state.user_pin} updateValue={(user_pin) => this.updateValue(user_pin)} onKeyUp={this.handleEnter} login errors={this.state.errors}/>
              {/* {this.renderButton()} */}
              {this.renderForgotText()}
            </PageContainer>
          </Background>
        </HideShow>
        <HideShow show={this.state.showEmailModal}>
          <ModalContainer>
            <Background fixed>
              <MobileModal title="We're missing information" hideCloseIcon context="login">
                <div className="sub-title">Looks like we're missing your email address.</div>
                <div className="label">Please enter it below to continue</div>
                <div className="errors">{this.state.errors}</div>
                <InputWrapper>
                  <Input name="email" required placeholder="joesmith@gmail.com" type="email" onChange={this.onChange}/>
                </InputWrapper>
                <div className="button-wrapper" data-test-id="login-update-email" onClick={this.updateEmail}>
                  <div className="submit">Submit</div>
                </div>
              </MobileModal>
            </Background>
          </ModalContainer>
        </HideShow>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    merchant_name: state.merchants.merchant_name,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role,
    user_info: state.auth.user_info,
    username: state.auth.username
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setUserId: (user_id) => {dispatch(setUserId(user_id))},
    setUserInfo: (user_info) => {dispatch(setUserInfo(user_info))},
    loginCustomer: (user_pin) => {dispatch(loginCustomer(user_pin))},
    storeCustomerAuth: (auth_token) => {dispatch(storeCustomerAuth(auth_token))},
    isMerchant: (is_merchant) => {dispatch(isMerchant(is_merchant))},
    storeCustomerUsername: (username) => {dispatch(storeCustomerUsername(username))},
    setFromInvoice: (from_invoice) => {dispatch(setFromInvoice(from_invoice))},
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLoginRedirect(CustomerLogin))
