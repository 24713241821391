import React, { Component } from 'react';
import { connect } from "react-redux";
import { Router, Route, Switch } from 'react-router-dom';
import IdleTimer from 'react-idle-timer'

//Assets
import './index.css'
import './GlobalStyles';

//Components
import history from './history.js';
import CustomerIndex from './views/customer/CustomerIndex';
import AdminIndex from './views/admin/AdminIndex';
import Login from './views/admin/Login';
import PasswordForm from './views/admin/CreatePassword';
import ForgotPassword from './views/admin/ForgotPassword';
import WelcomeVerify from './views/tcpa/WelcomeVerify';
import VerifyCode from './views/tcpa/VerifyCode';
import Congratulations from './views/tcpa/Congratulations';
import CustomerSignup from './views/customer/CustomerSignup';
import CustomerLogin from './views/customer/Login';
import TermsAndConditions from './views/public/TermsAndConditions';
import PrivacyPolicy from './views/public/PrivacyPolicy';
import NotificationContainer from './components/alerts/NotificationContainer';
import HostedPayment from './views/hosted-payment/Login';
import MobileLogin from './views/public/MobileLogin';
import CustomerReset from './views/customer/CustomerReset';
import Donation from './views/donation/Donation';
import LostPage from './views/public/PageNotFound';
import PaymentIframe from './views/iframes/PaymentIframe'

//Redux
import { setPaymentMethod, logout } from "./js/actions/auth";
import { setFromInvoice } from "./js/actions/invoices";
import { setNotification, storeInboxBadge } from "./js/actions/notifications";

class App extends Component {
  constructor(props){
    super(props);
    const _this = this;
    const socket = new WebSocket('wss://noti-api.blytzpay.com/notifications');

    if (this.props.is_merchant) {
      socket.addEventListener('open', function (event) {
        console.log('open')
        if (_this.checkEnv() === undefined || !_this.props.username) {
          return;
        } else {
          socket.send(_this.checkEnv() + ':' + _this.props.username);
        }
      });

      socket.addEventListener('close', function (event) {
        console.log('close')
      });

      socket.addEventListener('message', function (event) {
          if (event.data) {
            let formatted = JSON.parse(event.data)
            if (formatted.type === 'message') {
              _this.props.storeInboxBadge(true);
              _this.props.setNotification({message: formatted.user + ': ' + formatted.message.substring(0, 20) + '...', path: '/admin/conversations/' + formatted.sub_id});
            }
            if (formatted.type === 'payment') {
              _this.props.setNotification({message: formatted.user + ' has made a payment.', path: '/admin/invoices/detail/' + formatted.id});
            }
          }
      });
    }

    this.state = {};
  }

  checkEnv = () => {
    switch(window.location.origin) {
      case 'https://dev.blytzpay.com':
        return 'dev';
      case 'https://qa.blytzpay.com':
        return 'qa';
      case 'https://staging.blytzpay.com':
        return 'staging'
      case 'https://app.blytzpay.com':
        return 'app'
      case 'https://demo.blytzpay.com':
        return 'demo'
      default:
        return undefined;
    }
  }


  onActive = (e) => {

  }

  onAction = (e) => {

  }

  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.props.logout}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 10}/>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Login}/>
            <Route exact path="/login" component={Login} />
            <Route path="/customer/login" component={CustomerLogin}/>
            <Route exact path="/create-password" component={PasswordForm}/>
            <Route exact path="/reset-password" component={PasswordForm}/>
            <Route exact path="/forgot-password" component={ForgotPassword}/>
            <Route path="/customer/signup/:username" component={CustomerSignup}/>
            <Route path="/customer/reset" component={CustomerReset}/>
            <Route exact path="/welcome" component={WelcomeVerify}/>
            <Route exact path="/welcome/2" component={VerifyCode}/>
            <Route exact path="/welcome/3" component={Congratulations}/>
            <Route exact path="/hosted-payment/:company/:id" component={HostedPayment}/>
            <Route path="/admin" component={AdminIndex}/>
            <Route path="/customer" component={CustomerIndex}/>
            <Route path="/terms-conditions" component={TermsAndConditions}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route exact path="/mobile-login" component={MobileLogin}/>
            <Route exact path="/donation" component={Donation}/>
            <Route path="/embedded-payment" component={PaymentIframe}/>
            <Route path="*" component={LostPage}/>
          </Switch>
        </Router>
        <NotificationContainer/>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    is_merchant: state.auth.is_merchant,
    user_pin: state.auth.user_pin,
    username: state.auth.username
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setFromInvoice: (from_invoice) => {dispatch(setFromInvoice(from_invoice))},
    setPaymentMethod: (payment_method) => {dispatch(setPaymentMethod(payment_method))},
    logout: (auth_data) => {dispatch(logout(auth_data))},
    setNotification: (notification) => {dispatch(setNotification(notification))},
    storeInboxBadge: (inbox_badge) => {dispatch(storeInboxBadge(inbox_badge))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
