import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Modal from '../PlainModal';
import Input from '../../inputs/Input';
import Textarea from '../../fields/TextArea';
import messagesAPI from '../../../api/messages/messagesAPI';
import { setNotification } from '../../../js/actions/notifications';

const MiddleContainer = styled.div`
padding: 10px;
.input-container {
  margin-bottom: 20px;
  .label {
    color: #9CAAB6;
    font-size: 21px;
    margin-bottom: 5px;
  }
}

`

class MassMessageModal extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  sendMassMessage = async () => {
    let data = {
      autogroup: this.props.data.name,
      medium: 'sms',
      body: this.state.body
    };
    let response = await messagesAPI.sendMessage(data, this.props.auth_token);
    if (response && !response.err) {
      this.props.onClose();
      this.props.getData();
      this.props.getDetail();
      this.props.setNotification({message: 'Messages successfully sent'});
    } else {
      this.props.setNotification({message: 'Error sending messages', type: 'error'});
    }

  }


  render() {
    return (
      <Modal context="mass-msg" title="Mass Message" toggle_modal={this.props.onClose} submit={this.sendMassMessage}>
        <MiddleContainer>
          <div className="input-container">
            <div className="label">To:</div>
            <Input value={this.props.data.name} className="modal-input no-border" disabled/>
          </div>
          <div className="input-container">
            <div className="label">From:</div>
            <Input value={this.props.merchant_name} className="modal-input no-border" disabled/>
          </div>
          <div className="input-container">
            <div className="label">Message:</div>
            <Textarea name="body" rows="7" cols="30" onChange={this.onChange} data-test-id="body"/>
          </div>
        </MiddleContainer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
		merchant_name: state.merchants.merchant_name
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
	mapStateToProps,
  mapDispatchToProps
)(MassMessageModal);
