import React from 'react';
import styled from 'styled-components';

const Button = styled.div`
  ${props => !props.absolute ? "" :`position: absolute;
  bottom: 0;`}
  height: 60px;
  width: 100vw;
  background-color: #5A9DEB;box-shadow: inset 0 1px 0 0 rgba(212,217,192,0.52), inset 0 0 10px 0 rgba(103,131,96,0.36), 2px 4px 5px 1px rgba(153,153,153,0.59);
  color: #FFFFFF;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  font-weight: 500;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;

export default function BottomButton(props) {
  return <Button {...props}>{props.children}</Button>
}
