import React from 'react';
import onboardingAPI from '../../api/onboarding/onboardingAPIs';

export default class OnboardingWrapper extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      renderContent: ""
    };
  }

  get = async () => {
    await onboardingAPI.getNoAuth(this.props.match.params.id, this.loadData);
  }

  loadData = (resp) => {
    if (resp.status === 200){
      this.setState({
      renderContent: resp.data
      })
    }
  }

  render() {
    return (
      this.state.renderContent
    )
  }
}
