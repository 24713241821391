import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

// Assets
import { color_white, color_teal } from '../../styles/colors';

const ButtonWrapper = styled.div`
  .btn {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    /* box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .4); */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s;
    .icon {
      width: 20px;
      margin-right: 5px;
    }
    /* &:hover {
      transform: translateY(-2px);
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .5);
    }
    &:active {
      transform: translate(0);
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .4);
    } */

    &--primary {
      width: 159px;
      height: 35px;
      background-color: ${props => props.gray ? '#6C768C' : '#14c6c8'};
      color: #FFFFFF;
    }

    &--secondary {
      width: 159px;
      height: 35px;
      border: ${props => props.gray ? '1px solid #6C768C' : '1px solid #56D4D7'};
      color: ${props => props.gray ? '#6C768C' : '#56d4d7'};
      background-color: #FFFFFF;
    }

    &--danger {
      width: 107px;
      height: 35px;
      border: 1px solid #E04A1B;
      color: #E04A1B;
      background-color: #FFFFFF;
    }

    &--modal {
      height: 30px;
      width: 102px;

      &-primary {
        background-color: #E6F9F9;
        color: ${color_teal};
      }

      &-secondary {
        border: 2px solid #58D6D8;
        background-color: ${color_white};
        color: #58D6D8;
      }
    }

    &--wizard {
      width: 136px;
      height: 35px;
    
      &-primary {
        background-color: #FFA77C;
        color: ${color_white};
      }

      &-secondary {
        background-color: ${color_white};
        color: #FFA77C;
        border: 1px solid #FFA77C;
      }
    }
  }
`

export default function NewButton(props) {
  return (
    <ButtonWrapper {...props}>
      <div className={classNames("btn",
          {"btn--primary": props.type === "primary" || !props.type},
          {"btn--secondary": props.type === "secondary"},
          {"btn--danger": props.type === "danger"},
          {"btn--wizard" : props.type.substring(0, 6) === 'wizard'},
          {"btn--wizard-primary": props.type === "wizard-primary"},
          {"btn--wizard-secondary": props.type === "wizard-secondary"},
          {"btn--modal" : props.type.substring(0, 5) === 'modal'},
          {"btn--modal-primary": props.type === 'modal-primary'},
          {"btn--modal-secondary": props.type === 'modal-secondary'},
        )}>{props.children}</div>
      {/* <div className={"btn btn--wizard btn--wizard-primary"} onClick={props.onClick}>{props.children}</div> */}
    </ButtonWrapper>
  )
}
