import React, {Component} from 'react';
import {connect } from 'react-redux'
import styled from 'styled-components';
import HideShow from '../../components/function/HideShow';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  width: ${props => props.fullSize ? '220px' : props.receipt ? '120px' : '160px'};
  // height: ${props => props.fullSize ? '200px' : '55px'};
  @media screen and (min-device-height: 668px) {
    width: ${props => props.receipt ? '120px' : props.header ? '233px' : '220px'};
    // height: ${props => props.receipt ? '55px' : props.header ? '133px' :  '115px'};
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

const Logo = styled.img`
  height: ${props => props.height ? props.height : '100px'};
  max-width: ${props => props.width ? props.width : '85%'};
  width: auto;
  margin-bottom: ${props => props.isReceipt ? '15px;' : '0px;'}
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const MerchantName = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
`

class MerchantLogo extends Component {
  render() {
    return(
      <Wrapper {...this.props} fullSize={this.props.fullSize}>
        <HideShow show={this.props.logo || this.props.merchant_logo}>
          <Logo {...this.props} src={this.props.logo ? this.props.logo : this.props.merchant_logo} alt="merchant logo" className={this.props.className}/>
        </HideShow>
        <HideShow show={!this.props.logo}>
          <MerchantName>{this.props.name}</MerchantName>
        </HideShow>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    merchant_logo: state.merchants.merchant_logo
  };
};

export default connect(mapStateToProps)(MerchantLogo);
