import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'users';
let send_opt_in = '/send_opt_in';
let confirm = apiUtils.baseUrl + 'auth/confirm_opt_in';

class SmsOptIn {
  sendInvite = (data, auth_token, callback) => {
    return apiUtils.post(url + send_opt_in, data, auth_token, callback);
  };
  verifyCode = (data, auth_token, callback) => {
    return apiUtils.post(confirm, data, callback);
  };
}

export default new SmsOptIn();
