import React from 'react'
import InputWrapper from '../../wrappers/InputWrapper';
import Label from '../../display/Label';
import Typeahead from '../../inputs/Typeahead';
import HideShow from '../../function/HideShow';
import CurrencyInput from '../../inputs/CurrencyInput';
import styled from 'styled-components';
import AutopaySelect from '../../../views/customer/Autopay/AutopaySelect';
import globalUtils from '../../../utils/globalUtils';

//Assets
import 'react-day-picker/lib/style.css'
import NumberInput from '../../inputs/NumberInput';


const ModalContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  .text {
    margin-top: 2px;
  }
  .no-border {
    border: none;
    border-bottom: 1px solid #A4A4A4;
  }
  .css-1aya2g8 {
    background-color: #fff;
    border-color: #9E9E9E;
    border: none;
    border-bottom: 1px solid #A4A4A4;
  }

  #tokenform {
    border-bottom: 1px solid rgb(164, 164, 164);
  }
  .custom-line {
    color: #82B4EB;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-left: 30px;
    margin-bottom: 10px;
    &.stored {
      margin-top: -10px;
      width: 50%;
    }
  }
  .input-wrapper {
    margin-bottom: 10px;
    position: relative;
  }
  .bill-info {
    font-size: 14px;
    margin: 15px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    color: #5D5A61;
    .left-box {
      padding: 10px;
      font-size: 14px;
      margin-right: 30px;
    }
    .right-box {
      padding: 10px;
      margin-right: 22px;
    }
    .title {
      font-size: 18px;
      color: #757575;
      margin-bottom: 10px;
    }
    .blue-text {
      color: #82B4EB;
      font-size: 14px;
    }
  }
  .new-method-container {
    height: auto;
    border: 1px solid #E4E4E4;
    padding: 5px 15px;
    width: ${props => props.width ? props.width : '330px'};
    margin-top: 10px;
    margin-left: ${props => props.width ? '0px' : '10px'};
    margin-bottom: 10px;
    .input-wrapper.cvv {
      width: 50%;
      input {
        width: 66%;
      }
    }
    .custom-label {
      font-size: 12px;
      padding-bottom: 5px;
      color: #7E7E7E;
      text-transform: uppercase;
    }
    .space-between {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      & .expiration {
        width: 40%;
        input {
          width: 100%;
        }
      }
      & .zip {
        text-align: right;
        margin-right: 20px;
        width: 72%;
        input {
          width: 62%;
        }
      }
    }
  }
  .day {
    z-index: 2;
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(calc(-50% - 175px),-50%);
    height: auto;
    width: 282px;
    border-radius: 3px;
    background-color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(204, 204, 204, 0.99);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      position: relative;
      background-color: #12FFCB;
      color: inherit;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: #12FFCB;
    }
    .DayPicker-Day {
      display: table-cell;
      padding: 0.5em;
      border-radius: 50%;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      outline: none;
    }
    .DayPicker-wrapper {
      position: relative;
      flex-direction: row;
      padding-bottom: 1em;
      user-select: none;
      outline: none
    }
    .DayPicker-Day--today {
      color: #5A9DEB;
      font-weight: 700;
    }

    @media (min-device-height: 668px) {
      top: 44%;
    }
  }
`

const Heading = styled.p`
  font-size: 19px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AutopayForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`

const NumInputContain = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: ${props => props.padding ? props.padding : '0px 25px'};
  margin-bottom: 5px;
`

const frequencies = [
	{ label: 'Monthly', value:'monthly'},
	{ label: 'Bi-Weekly', value:'bi-weekly'},
	{ label: 'Weekly', value:'weekly'}
];

const weekdays = [
  { label: 'Monday', value:'monday'},
  { label: 'Tuesday', value:'tuesday'},
  { label: 'Wednesday', value:'wednesday'},
  { label: 'Thursday', value:'thursday'},
  { label: 'Friday', value:'friday'}
];

const AutopayContent = props => {
    return (
        props.page === '' || !props.page ? 
          <AutopayForm>
              <AutopaySelect selectCard={props.selectCard}/>
          </AutopayForm> 
        :
          <ModalContent width={props.width}>
            <HideShow show={props.page === "autopay"}>
              <InputWrapper noPadding={props.noPadding}>
                <HideShow show={!props.showAutoPayModal}>
                  <Label label="Payment Options"></Label>
                  <Typeahead options={props.payment_methods}
                    onChange={props.onPaymentOptionChange}
                    payment_methods
                    name="id"
                  />
                </HideShow>
              </InputWrapper>
              <HideShow show={props.showAutoPayModal}>
                <div className="custom-line stored" data-test-id={"autopay-use-stored-" + props.context} onClick={props.toggleNewMethod}>Use Stored Method</div>
              </HideShow>
              <HideShow show={!props.showAutoPayModal}>
                <div className="custom-line" data-test-id={"autopay-use-another-method-" + props.context} onClick={props.toggleNewMethod}>Use Another Method</div>
              </HideShow>
              {props.showForm()}
              <div className="bill-info">
                *All invoices will be paid on the due date using the payment method selected.
              </div>
            </HideShow>
            <HideShow show={props.page === "recurring"}>
              <HideShow show={props.context !== "new-detail"}>
                <Heading>Custom Recurring Payment</Heading>
              </HideShow>
              <InputWrapper noPadding={props.noPadding}>
                <HideShow show={!props.showAutoPayModal}>
                  <Label label="Payment Options"></Label>
                  <Typeahead options={props.payment_methods}
                    onChange={props.onPaymentOptionChange}
                    payment_methods
                    name="id"
                  />
                </HideShow>
              </InputWrapper>
              <HideShow show={props.showAutoPayModal}>
                <div className="custom-line stored" data-test-id={"autopay-use-stored-" + props.context} onClick={props.toggleNewMethod}>Use Stored Method</div>
              </HideShow>
              <HideShow show={!props.showAutoPayModal}>
                <div className="custom-line" data-test-id={"autopay-use-another-method-" + props.context} onClick={props.toggleNewMethod}>Use Another Method</div>
              </HideShow>
              {props.showForm()}
                <InputWrapper noPadding={props.noPadding}>
                <Label label="Recurring Payment Frequency"/>
                <Typeahead
                    options={frequencies}
                    placeholder="Choose Frequency"
                    name="autopay_frequency"
                    onChange={props.handleFrequencyChange}
                    frequencies
                    ref={props.myRefs.autopay_frequency}/>
                </InputWrapper>
                <HideShow show={props.showDayPicker}>
                  <NumInputContain>
                    <NumberInput value={props.autopay_day ? props.autopay_day : ""} onChange={props.handleDayClick} width='40px' label="Pick a day (1-28)" placeholder="1" min={1} max={28}/>
                  </NumInputContain>
                </HideShow>
                {props.autopay_frequency === 'monthly' ?
                  (
                  <InputWrapper noPadding={props.noPadding}>
                    <Label label={`Selected payment day ${props.autopay_day ? globalUtils.formatDay(props.autopay_day) : '_'}`} />
                  </InputWrapper>
                  )
                  :
                  null
                }
                
                <HideShow show={props.autopay_frequency === 'weekly' || props.autopay_frequency === 'bi-weekly'}>
                  <InputWrapper>
                    <Label label="Choose Day of Week"/>
                    <Typeahead
                        options={weekdays}
                        placeholder="Weekday"
                        name="autopay_day"
                        onChange={event => props.onChange({name: 'autopay_day', ...event})}
                        frequencies
                        ref={props.myRefs.autopay_day}/>
                  </InputWrapper>
                </HideShow>
                <InputWrapper>
                  <Label label="Recurring Payment Amount"/>
                  <CurrencyInput
                          noBorder={true}
                          className="no-border full-width-input"
                          placeholder="Amount"
                          name="autopay_amount"
                          onChange={props.onChange}
                          required
                          ref={props.myRefs.autopay_amount}/>
                </InputWrapper>
                <div className="bill-info">
                *Recurring payments will continue until turned off even if there is no balance remaining.
                </div>
            </HideShow>
          </ModalContent>
    )
}

export default  AutopayContent