import React, {Component} from 'react';
import { connect } from 'react-redux';
import { RowDefinition, ColumnDefinition } from 'griddle-react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

//Components
import AdminPageWrapper from '../../../components/wrappers/AdminPageWrapper';
import Table from '../../../components/tables/Table';

//API & Utils
import templatesAPI from '../../../api/messages/templatesAPI';
import globalUtils from '../../../utils/globalUtils';

const AlignLeft = styled.div`
  text-align: left;
`

const rowDataSelector = (state, { griddleKey }) => {
  return state
    .get('data')
    .find(rowMap => rowMap.get('griddleKey') === griddleKey)
    .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
  return {
    rowData: rowDataSelector(state, props)
  };
});

const sortProperties = [
  { id: 'name', sortAscending: true }
];

function CustomNameComponent({rowData}) {
  return (
    <div className="CustomNameComponent">
      <AlignLeft><Link to={"/admin/message-templates/" + rowData.id}> {globalUtils.replaceText(rowData.name)}</Link></AlignLeft>
    </div>
  );
}

function CustomBodyComponent({rowData}) {
  return (
    <div className="CustomBodyComponent">
      <AlignLeft>{rowData.body}</AlignLeft>
    </div>
  );
}

const CustomHeading = ({title}) => <span style={{ 'float': 'left' }}>{title}</span>;

class TemplatesIndexListWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
    data: []
    };

    globalUtils.loginRedirect(props);

  }

  componentDidMount = () => {
    this.getData();
  }

  getData = async () => {
    let formatted_data = [];
    let data = await templatesAPI.getList(this.props.auth_token)
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }
    if (data) {
      formatted_data = data.map(function(row){
        return row
      })
    }
    this.setState({
      data: formatted_data, loaded: true
    })
  }


  render() {
    return (
      <AdminPageWrapper>
        <Table title="Templates"
              data={this.state.data}
              tableClassName="templates"
              showFilter={true}
              showSettings={false}
              filterPlaceholderText="Search"
              loaded={this.state.loaded}
              sortProperties={sortProperties}>
        <RowDefinition>
          <ColumnDefinition id="name" title="Name" customComponent={enhancedWithRowData(CustomNameComponent)} customHeadingComponent={CustomHeading}/>
          <ColumnDefinition id="body" title="Body" customComponent={enhancedWithRowData(CustomBodyComponent)} customHeadingComponent={CustomHeading}/>
        </RowDefinition>
        </Table>
      </AdminPageWrapper>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

export default connect(
  mapStateToProps
)(TemplatesIndexListWrapper)
