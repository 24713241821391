import React, { Component } from 'react';
import styled from 'styled-components';

import Input from '../inputs/ControlledInput';
import CurrencyInput from '../inputs/CurrencyInput';
import removeIcon from '../../assets/icons/close_gray_icon.png';
import globalUtils from '../../utils/globalUtils';

const LineItemsWrapper = styled.div`
  .add-item {
    position: relative;
    min-height: 1px;
    right: 14px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 290px;
  margin-bottom: -5px;
  margin-top: 5px;
  .remove-button {
    position: absolute;
    width: 16px;
    height: 16px;
    right: -29px;
    .remove-icon {
      width: 100%;
      position: relative;
      top: 6px;
      right: 5px;
      margin: 0;
    }
  }
  .currency-input, 
  .description-input {
    height: 28px;
    width: 155px;
    border-bottom: 1px solid #A4A4A4;
    font-size: 15px;
    text-indent: 10px;
    &:focus {
      border: 2px solid #64ABF5;
      outline: none;
    }
  }
  .gap {
    margin-left: 7px;
  }
`;

class LineItems extends Component {
  showRemoveButton = (index, id = null)=> {
    if( (this.props.type === 'line-items' && this.props.items.length > 1) || this.props.type === 'appends') {
      return(
        <img
            className="custom-line remove-icon"
            src={removeIcon} alt="remove"
            onClick={ !id ? () => this.props.removeItem(index) : () => this.props.deleteItem(id, this.props.type) }
        />
      )
    }
  }
  render() {
    const Items = this.props.items.map( (item, index) => {
      return(
        <InputWrapper key={index.toString()}>
          <div className="remove-button">
            { this.showRemoveButton(index, item.id || null) }
          </div>
          <Input
                className="description-input no-border"
                name="description"
                onChange={ e => this.props.onChange(e, index)}
                placeholder="*Enter Description"
                value={item.description || ''}
                data-test-id={"description-" + index}
                required
          />
          <CurrencyInput
                className="currency-input no-border gap"
                name="amount"
                onChange={ e => this.props.onChange(e, index)}
                data-test-id={"amount-" + index}
                placeholder="*Enter Amount"
                // maxLength={12}
                defaultValue={globalUtils.convertToDollars(item.amount)}
                required
          />
        </InputWrapper>
      )
    })

    return(
      <LineItemsWrapper>
        <div className="add-item">
          { Items }
        </div>
      </LineItemsWrapper>
    )
  }
}

export default LineItems;
