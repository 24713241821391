import apiUtils from '../apiUtils';
let refundUrl = apiUtils.baseUrl + "transactions/refund";

class RefundAPI {
  refundPayment = (data, auth_token, user_pin, callback) => {
    return apiUtils.post(refundUrl, data, auth_token, callback);
  };
  
  cancelTransaction = (data, auth_token, user_pin, callback) => {
    let cancelUrl = apiUtils.baseUrl + `transactions/${data.transaction_id}/cancel`;
    return apiUtils.post(cancelUrl, data, auth_token, callback);
  }
}

export default new RefundAPI();
