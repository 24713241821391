import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//Components
import BoldTitle from '../../../components/display/BoldTitle';
import Button from '../../../components/buttons/Button';
import PageWrapper from '../../../components/mobile/MobileWrapper';
import CardRow from '../../../components/cards/CardRow';

//Assets
import visa_icon from '../../../assets/icons/visa.svg';
import mastercard_icon from '../../../assets/icons/mastercard.svg';
import amex_icon from '../../../assets/icons/amex.svg';
import discover_icon from '../../../assets/icons/discover.svg';

//Redux
import { setPaymentMethod } from '../../../js/actions/auth';

//API
import PaymentMethodAPI from '../../../api/blytzwallet/PaymentMethod';

const Row = styled.div`
  height: 75px;
  width: 100%;
  position: relative;
  display: flex;
  line-height: 3.5;
  justify-content: center;
  &:nth-child(even) {
    background-color: #F7F7F7;
  }
`

const Container = styled.div`
  margin-top: 25px;
`;

const Plus = styled.img`
  height: 40px;
  width: auto;
  position: absolute;
  top: 15px;
  right: 115px;
`

const Heading = styled.div`
  font-size: 20px;
  font-weight: 900;
  position: absolute;
  left: 105px;
`

class SelectCardWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      active: null,
      cards: [],
      cardList: [],

    };

    var undef;
    this.props.setPaymentMethod(undef)

    /*
    Mapping from BE:
    VISA = 1
    MASTERCARD = 2
    DISCOVER = 3
    AMEX = 4
    */
    this.cardIcons = {}
    this.cardIcons[1] = visa_icon;
    this.cardIcons[2] = mastercard_icon;
    this.cardIcons[3] = discover_icon;
    this.cardIcons[4] = amex_icon;

    this.renderCards()

  }

  componentWillReceiveProps = (nextProps) => {
    if (typeof nextProps.payment_method !== 'undefined'){
      this.props.history.push('/customer/blytzwallet/invoice/' + this.props.from_invoice);
    }
  }

  renderPaymentButton = () => {
  }

  getCardTypeURL = (card_type) => {
    if (card_type===null){
      card_type="visa"
    }
    return this.cardIcons[card_type]
  }

  renderCards = async () => {
    let cards = await PaymentMethodAPI.getList(this.props.auth_token, this.props.user_pin);
    if (cards.err && cards.err.response) {
      this.setState({errors: cards.err.response.data.detail})
      window.scroll(0,0);
      return
    }

    this.setState({
      cards: cards
    },
    this.rerenderCards)
  }

  rerenderCards = () => {

    let newArray = [];
    let cards = this.state.cards;
    for (let i in cards) {
      // if (typeof cards[i].active === "undefined"){
      //   cards[i].active=false
      // }
      let card_number = "XXXX XXXX XXXX " + cards[i].card_last4
      let card_type_url = this.getCardTypeURL(cards[i].card_type)
      var active = false
      if (this.state.active === i){
        active = true
      }
      newArray.push(<CardRow setActive={() => this.setActiveCard(i)} cardNumber={card_number} cardType={card_type_url} active={active} />)
    }

    if (newArray.length > 0 && typeof this.props.from_invoice !== "undefined" && this.props.from_invoice !== "" && this.props.from_invoice !== null){
      newArray.push(<Button secondary full data-test-id="select-card-pay-now" onClick={this.payNow.bind(this)}>PAY NOW</Button>)
    }

    this.setState({
      cards: cards,
      cardList: newArray
    })
    // return newArray;
  }

  isActive = (id) => {
    return this.state.active === id;
  }

  setActiveCard = (id) => {
    this.setState({active: id},
      this.rerenderCards
    )
  }

  payNow = () => {
    this.props.setPaymentMethod(this.state.cards[this.state.active])
  }

  reroute_to_add_card = () => {
    this.props.history.push('/customer/blytzwallet/add-card');
  }

  render() {
    return (
      <PageWrapper>
        <Container>
          <BoldTitle>Select Card</BoldTitle>
          {this.state.cardList}
          <Row data-test-id="select-card-add-card" onClick={this.reroute_to_add_card} >
            <Heading>Add Card</Heading>
            <Plus src="https://s3.amazonaws.com/blytz/assets/green_plus.svg" />
          </Row>
        </Container>
      </PageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setPaymentMethod: (payment_method) => {dispatch(setPaymentMethod(payment_method))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCardWrapper)
