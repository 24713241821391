import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { bounceInRight, bounceOutRight } from 'react-animations';
import classNames from 'classnames';

//Redux
import { hideNotification, clearNotifications } from '../../js/actions/notifications';


const bounceInFromRight = keyframes`
  ${bounceInRight}
`;

const bounceOutToRight = keyframes`
  ${bounceOutRight}
`;

const NotificationWrapper = styled.div`
  position: relative;
  margin: 10px 0 10px 0;
  z-index: 10;
  cursor: default;
  height: auto;
  #notify:hover #close {
    color: white;
  }
  #close {
    position: absolute;
    top: 3px;
    right: 3px;
    transition: color .5s;
    cursor: pointer;
    color: transparent;
    &:hover {
      color: white;
    }
  }
	.animate {
		width: 100%;
	}
  .in-right {
    animation: ${bounceInFromRight} 1s linear;
  }
	.out-right {
		animation: ${bounceOutToRight} 1s linear;
	}
	.notification-text {
    position: relative;
		border: 1px solid lightgrey;
    color: white;
    height: 100%;
    width: 400px;
    padding: 15px;
    float: right;
    margin-right: 30px;
    margin-top: 5px;
    font-weight: 700;
    border-radius: 7px;
		display: flex;
		justify-content: center;
		align-items: center;
    transition: background-color .5s;
    &:hover + .close-button {
      color: white;
    }
		.message {
			font-size: 16px;
      word-wrap: normal;
		}
	}
  .success {
  background-color: #06d6a0e6;
    &:hover {
      background-color: #06D6A0;
    };
  }
  .success-mobile {
    background-color: #06D6A0;
    width: 260px;
    margin: 0;
    text-align: center;
  }
  .error {
    background-color: #fd7272e6;
    &:hover {
      background-color: #FD7272;
    }
  }
  @supports (-ms-ime-align: auto) {
    .success {
      background-color: #06D6A0;
      transition: none;
    }
  }
  .error-mobile {
    background-color: #FD7272;
    width: 260px;
    margin: 0;
    text-align: center;
  }
`;


class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideNotification: false,
    }
  }

  hideNotification = () => {
    if(this.props.position === 0) {
      setTimeout( () => {
        this.setState({
          hideNotification: true
        }, this.resetNotification)
      }, 5000)
    }
  }

	resetNotification = () => {
    setTimeout( () => {
      if(this.props.data.mobile) {
        this.props.hideNotification(this.props.data.id, true)
      } else {
        this.props.hideNotification(this.props.data.id)
      }
    }, 900)
  }

  showCloseIcon = () => {
    if (!this.props.data.path) {
      return <div id="close" onClick={ () => this.props.hide(this.props.data.id)}>X</div>
    }
  };

  render() {
    let { data } = this.props,
        { type, message } = data;
    if(data.path) {
      return(
        <NotificationWrapper>
          <Link to={data.path} onClick={ this.props.clearNotifications }>
            <div className={classNames('animate ', {'out-right' : this.state.hideNotification}, {'in-right': !this.state.hideNotification})}>
              <div id="notify" className={classNames(`notification-text ${type}`, {'-mobile': data.mobile})}>
                <div className="message">{message}</div>
              </div>
              { this.hideNotification() }
            </div>
          </Link>
        </NotificationWrapper>
      )
    } else {
      return(
        <NotificationWrapper>
          <div className={classNames('animate ', {'out-right': this.state.hideNotification}, {'in-right': !this.state.hideNotification})}>
            <div id="notify" className={classNames(`notification-text ${data.mobile ? `${type}-mobile` : type}`)}>
              <div id="close" onClick={ () => this.props.hideNotification(this.props.data.id)}>X</div>
                <div className="message">{message}</div>
            </div>
            { this.hideNotification() }
          </div>
        </NotificationWrapper>

      )
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    hideNotification: (id, mobile) => {dispatch(hideNotification(id, mobile))},
    clearNotifications: () => {dispatch(clearNotifications())}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Notification);
