import React, {Component} from 'react';
import styled from 'styled-components';
import history from '../../history';

const Container = styled.div`
  position: fixed;
  right: 0px;
  bottom: 0;
  height: 50px;
  text-align: center;
  background-color: #5a9deb;
  width: 100vw;
  line-height: 2;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  @media (min-device-width: 1024px) {
    width: 390px;
  }
`

export default class Footer extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  goBack = () => {
    history.goBack();
  }

  render() {
    return (
      <Container onClick={this.goBack}>{this.props.text}</Container>
    )
  }
}
