import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalContent } from '../generalModalStyling';
import Modal from '../NewModal';
import InputWrapper from '../../wrappers/InputWrapper';
import Label from '../../display/Label';
import { setNotification } from '../../../js/actions/notifications'
import paymentAPI from '../../../api/payments/paymentAPIs';

class UnscheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  unschedule = async () => {
    let data = {invoice: this.props.invoice_id}
    let resp = await paymentAPI.unschedule(data, this.props.auth_token, this.props.user_pin);
    if (resp.err && resp.err.response) {
      this.setState({errors: resp.err.response.data.detail});
      return
    }
    this.props.onClose('showUnscheduleModal')
    this.props.getInvoiceDetail()
    this.props.setNotification({message: 'Payment successfully unscheduled'})
  }

  render() {
    return(
      <Modal context="unschedule-pmt" title="Unschedule Payment" modalname="showUnscheduleModal" onClose={ () => this.props.onClose('showUnscheduleModal') } errors={this.state.errors} submit={this.unschedule} yesNo>
        <ModalContent>
          <InputWrapper>
            <Label label="Are you sure you want to unschedule this payment?"/>
          </InputWrapper>
        </ModalContent>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnscheduleModal);
