import React, {Component} from 'react';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 580px) {
    width: 100%;
    /* height: 100%; */
  }
`

export default class ContentContainer extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <ContentWrapper>{this.props.children}</ContentWrapper>
    )
  }
}
