import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import Cleave from 'cleave.js/react';

//Components
import Background from '../../components/mobile/MobileBackground';
import Input from '../../components/inputs/ControlledInput';
import PinCode from '../../components/signup/Pincode';
import Checkbox from '../../components/inputs/Checkbox';
import Button from '../../components/mobile/MobileButton';
import ErrorHandle from '../../components/function/ErrorHandle';
import MerchantLogo from '../../components/logos/MerchantLogo'
import TermsModal from '../../components/mobile/TermsAndConditions';
import PrivacyModal from '../../components/mobile/PrivacyPolicy';
import HideShow from '../../components/function/HideShow';
import AnimatedCheckmark from '../../components/mobile/AnimatedCheckmark';
import VerifyCode from '../../components/signup/VerifyCode';

//API & Utils
import userInfoAPI from '../../api/user/userInfoAPI';
import customerAuthAPI from '../../api/auth/customerAuthAPI';
import loginAPI from '../../api/login/loginAPI';
import globalUtils from '../../utils/globalUtils';

//Redux
import { setPaymentMethod, setUserId, setUserInfo, storeCustomerAuth, loginCustomer, isMerchant } from '../../js/actions/auth';
import { setFromInvoice } from '../../js/actions/invoices';
import { storeMerchantLogo } from '../../js/actions/merchants';
import withLoginRedirect from '../public/LoginRedirect/withLoginRedirect';

const PageContainer = styled.div`
  overflow-Y: scroll;
  height: 100vh;
  .input-container {
    height: 495px;
    width: 335px;
    border-radius: 17px;
    background-color: #F1F4F7;
    margin: 5px auto 0 auto;
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 only */
      height: 230px;
      width: 300px;
      overflow-y: scroll;
    }
    @media only screen and (min-width: 768px) {
      width: 550px;
    }
  }
`

const MobileLogoWrapper = styled.div`
  width: 100%;
  top: 0;
  padding: 0 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Header = styled.div`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #5A9FEC;
  margin-bottom: 25px;
  padding-top: 25px;
`

const Container = styled.div`
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  input {
    margin-bottom: 10px;
    width: 100%;
    height: 49px;
    background: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    color: #000000;
    font-size: 16px;
    text-align: left;
    text-indent: 10px;
    border-radius: 6px;
    ::placeholder {
      color: #B4B4B4;
      text-align: left;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  & input[type='checkbox'] {
    width: 15px;
    height: 20px;
  }
  .email {
    width: 300px;
  }
`

const CheckboxWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 580px) {
    width: 500px;
  }
`

const CheckboxText = styled.div`
  font-size: 11px;
  color: #000;
  position: absolute;
  left: 30px;
  top: -3px;
  @media only screen and (min-width: 580px) {
    font-size: 15px;
  }
  .clickable {
    color: #5A9FEC;
    cursor: pointer;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  .small-text {
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
`

const ErrorContainer = styled.div`
  background-color: #FD7272;
  text-align: center;
  margin-bottom: 5px;
  padding: 10px;
  div {
    color: #fff;
  }
`

class CustomerSignup extends Component {
  constructor(props){
    super(props);
    const values = globalUtils.getQueryString(this.props.location.search.slice(1))
    this.state = {
      showCode: false,
      merchant_name: values.merchant_name,
      subscription_id: this.props.sub_id,
      showSuccess: false,
      showPinVerify: false,
      showSignupForm: true,
      resetValues: false,
      isBusiness: (values.is_business === "True" || values.is_business === "true"),
      first_name: '',
      last_name: '',
      email: '',
      zip: '',
      business_name: '',
      date_of_birth: '',
      username: this.props.username,
    }

    if (typeof values.auth_token !== "undefined"){
      this.props.storeCustomerAuth(values.auth_token)
    }

    this.props.setUserId(this.props.match.params.id)

    this.props.setPaymentMethod(undefined)
    this.props.setUserInfo(undefined)
    this.props.loginCustomer(undefined)

    if (values.is_business){
      this.myRefs = {
        business_name: React.createRef(),
        email: React.createRef(),
        zip: React.createRef()
      }
    } else {
      this.myRefs = {
        first_name: React.createRef(),
        last_name: React.createRef(),
        email: React.createRef(),
        date_of_birth: React.createRef(),
        zip: React.createRef()
      }
    }
  }

  componentDidMount() {
    this.getLogo()
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.state.attempt_login) {
      return
    }
    if (!nextProps.user_pin) {
      return
    }
    if (!this.state.user_pin) {
      return
    }
    if (this.state.user_pin.length < 4) {
      return
    }
    if (!nextProps.auth_token) {
      return
    }
    if (!nextProps.user_info) {
      return
    }
    if (!nextProps.user_id) {
      return
    }
    if (!this.props.isMerchant) {
      if (this.props.from_invoice){
        this.props.history.push('/customer/blytzwallet/invoice/' + this.props.from_invoice);
      }
    }
  }

  getLogo = async () => {
    let response = await customerAuthAPI.getLogo(this.state.merchant_name);
    let logoPath = response.merchant_logo;
    this.props.storeMerchantLogo(logoPath);
  }

  checkValues = () => {
    let valid = true;
    if (!this.state.sms_opt_in) {
      this.setState({errors: "You must agree to terms"});
      valid = false;
    }
    if (this.state.isBusiness){
      if (!this.state.business_name || !this.state.zip || !this.state.email) {
        this.setState({errors: "Please fill all fields"});
        valid = false;
      }
    } else {
      if (!this.state.first_name || !this.state.last_name || !this.state.date_of_birth || !this.state.zip) {
        this.setState({errors: "All fields are required"});
        valid = false;
      }
    }
    return valid;
  }

  signup = async () => {
    let data = await customerAuthAPI.signup(this.props.username, this.state);
    if (data.err) {
      return this.setState({errors: data.err.response.data.detail})
    }
    let loginData = {customer_login: true, username: this.props.username, pin: this.state.user_pin}
    let response = await loginAPI.customerLogin(loginData);
    if (response.err && response.err.response) {
      this.setState({errors: response.err.response.data.detail})
      return
    } else {
      this.props.storeCustomerAuth(response.auth_token)
      this.props.setUserInfo(response)
      this.props.loginCustomer(this.state.user_pin)
      this.props.isMerchant(false);
      this.setState({showSuccess: true})
      setTimeout(() => {
        this.props.history.push('/customer/dashboard#registered');
      }, 2000)
    }
  }

  loadUser = async (auth_token) => {
    let response = await userInfoAPI.getSelf(auth_token);
    if (response.err && response.err.response) {
      this.setState({errors: response.err.response.data.detail})
      return
    }
    this.props.setUserInfo(response)
  }

  verifyInfo = async () => {
    if (!this.checkValues())  return;

    let loginData = await customerAuthAPI.verifyInfo(this.state);
    if (loginData.err && loginData.err.response) {
      this.setState({errors: loginData.err.response.data.detail, showSignupForm: true, showSuccess: false})
      return false;
    }
    else {
      this.setState({subscription_id: loginData.subscription_id})
      if (this.state.pinCreated) {
        let loginData = {customer_login: true, username: this.props.username, pin: this.state.user_pin}
        let user_data = await loginAPI.customerLogin(loginData);
        if (user_data.err && user_data.err.response) {
          this.setState({errors: loginData.err.response.data.detail, showSignupForm: true, showSuccess: false})
          return false;
        }
        if (user_data.id) {
          this.loadUser(user_data.auth_token)
          this.props.storeCustomerAuth(user_data.auth_token)
          this.setState({attempt_login: true, user_info: user_data})
          this.props.loginCustomer(this.state.user_pin)
          this.props.isMerchant(false);
          this.setState({ showSuccess: true})
          if (this.props.from_invoice){
            setTimeout(() => {
              this.props.history.push('/customer/blytzwallet/invoice/' + this.props.from_invoice);
            }, 2000)
          }
        }
      } else {
        this.setState({ showCode: true, showSignupForm: false, errors: undefined}, this.scrollToTop);
      }
    }
  };



  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  handleChange = (event, string) => {
    if (string === "verify") {
      this.setState({ showPinVerify: true, showCode: false, showSignupForm: false, errors: undefined, showSuccess: false})
      return;
    }
    if (string === 'success') {
      this.setState({ showPinVerify: false, showCode: false, showSignupForm: false, showSuccess: true, errors: undefined, pinCreated: true})
      return;
    }
    if (string === 'error') {
      this.setState({ showPinVerify: false, showCode: true, showSignupForm: false, showSuccess: false, errors: 'Pins do not match, try again.'})
      return;
    }
    if (string === 'duplicate') {
      this.setState({ showPinVerify: false, showCode: true, showSignupForm: false, showSuccess: false, errors: 'Pin may not be all the same digits'})
      return;
    }
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'email') {
      let formatted = value.toLowerCase();
      value = formatted;
    }

    this.setState({
      [name]: value
    });
  };

  updateValue = (user_pin) => {
    this.setState({user_pin: user_pin}, () => {
      this.props.loginCustomer(this.state.user_pin)
    });
  };

  toggleTerms = () => {
    this.setState(prevState => {
      return {
        showTerms: !prevState.showTerms,
      };
    });
  }

  showTermsModal = () => {
    if (this.state.showTerms) {
      return (
        <TermsModal closeModal={this.toggleTerms}/>
      )
    }
  }

  togglePrivacy = () => {
    this.setState(prevState => {
      return {
        showPrivacy: !prevState.showPrivacy,
      };
    });
  }

  showPrivacyModal = () => {
    if (this.state.showPrivacy) {
      return (
        <PrivacyModal closeModal={this.togglePrivacy}/>
      )
    }
  }

  showSignupTools = () => {
    return (
      <CheckboxWrapper>
        <Checkbox data-test-id="sms-opt-in-check" name="sms_opt_in" onChange={this.handleChange} checked={this.state.sms_opt_in}/>
          <CheckboxText>
            I agree to be contacted by {this.state.merchant_name || '{MERCHANT NAME}'} via text message and/or phone call at the phone number provided above regarding services provided by {this.state.merchant_name || '{MERCHANT NAME}'}. I understand that I can revoke this permission at any time by contacting {this.state.merchant_name || '{MERCHANT NAME}'}. See <span className="clickable" data-test-id="customer-signup-terms" onClick={this.toggleTerms}> terms and conditions</span> and <span className="clickable" data-test-id="customer-signup-privacy" onClick={this.togglePrivacy}>privacy policy</span>.
          </CheckboxText>
      </CheckboxWrapper>
    )
  }

  showLogoBox = () => {
    return (
      <MobileLogoWrapper>
        <MerchantLogo showMerchantName={true} fullSize/>
      </MobileLogoWrapper>
    )
  }

  renderForm = () => {
    if (!this.state.isBusiness){
      return (
      <>
        <Container>
          <Input name="first_name"
                placeholder="First Name"
                onChange={this.handleChange.bind(this)}
                ref={this.myRefs.first_name}
                value={this.state.first_name}/>
          <Input name="last_name"
                placeholder="Last Name"
                onChange={this.handleChange.bind(this)}
                ref={this.myRefs.last_name}
                value={this.state.last_name}/>
          <Input name="email"
                placeholder="Email"
                onChange={this.handleChange.bind(this)}
                ref={this.myRefs.email}
                value={this.state.email}
                required/>
        </Container>
        <Container>
        <Cleave placeholder="Birthdate"
                className="birthday-input"
                options={{date: true, datePattern: ['m', 'd', 'Y']}}
                name="date_of_birth"
                data-test-id="date_of_birth"
                type="tel"
                onChange={this.handleChange.bind(this)}
                ref={this.myRefs.date_of_birth}/>
          <Input name="zip"
                placeholder="Zip Code"
                type="tel"
                maxLength="5"
                onChange={this.handleChange.bind(this)}
                ref={this.myRefs.zip}
                value={this.state.zip}/>
          { this.showSignupTools() }
        </Container>
      </>)
    } else {
      return (
      <>
        <Container>
          <Input name="business_name"
                placeholder="Business Name"
                onChange={this.handleChange.bind(this)}
                ref={this.myRefs.business_name}
                value={this.state.business_name}/>
            <Input name="email"
                placeholder="Email"
                onChange={this.handleChange.bind(this)}
                ref={this.myRefs.email}
                value={this.state.email}/>
        </Container>
        <Container>
          <Input name="zip"
                placeholder="Zip Code"
                type="tel"
                maxLength="5"
                onChange={this.handleChange.bind(this)}
                ref={this.myRefs.zip}
                value={this.state.zip}/>
          { this.showSignupTools() }
        </Container>
      </>)
    }
  }

  renderButton = () => {
    return (
      <ButtonWrapper>
        <Button data-test-id="customer-signup-agree" onClick={this.verifyInfo}>Agree</Button>
        <div className="small-text">powered by BlytzPay®</div>
      </ButtonWrapper>
    )
  }

  render() {
    return (
      <Background>
        <PageContainer>
          <HideShow show={this.state.showSignupForm}>
            { this.showLogoBox() }
          </HideShow>
          <div>
            <HideShow show={this.state.errors}>
              <ErrorContainer>
                <ErrorHandle data={this.state.errors}></ErrorHandle>
              </ErrorContainer>
            </HideShow>
            {this.showTermsModal()}
            {this.showPrivacyModal()}
            <HideShow show={this.state.showSignupForm}>
              <div className="input-container">
                <Header>Let's get started!</Header>
                {this.renderForm()}
              </div>
            </HideShow>
            <HideShow show={this.state.showSignupForm}>
              {this.renderButton()}
            </HideShow>
            <HideShow show={this.state.showCode}>
              <PinCode context="customer-signup" create onChange={this.handleChange} updateValue={this.updateValue} resetValues={this.state.resetValues}/>
            </HideShow>
            <HideShow show={this.state.showPinVerify}>
              <VerifyCode verify onChange={this.handleChange} updateValue={this.updateValue} pincode={this.state.user_pin} signup={this.signup}/>
            </HideShow>
            <HideShow show={this.state.showSuccess && !this.state.showCode && !this.state.showPinVerify}>
              <AnimatedCheckmark/>
            </HideShow>
          </div>
        </PageContainer>
      </Background>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    merchant_logo: state.merchants.merchant_logo,
    merchant_name: state.merchants.merchant_name,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    user_id: state.auth.user_id,
    sub_id: state.auth.sub_id,
    user_info: state.auth.user_info,
    user_role: state.auth.user_role,
    username: state.auth.username
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setFromInvoice: (from_invoice) => {dispatch(setFromInvoice(from_invoice))},
    storeMerchantLogo: (merchant_logo) => {dispatch(storeMerchantLogo(merchant_logo))},
    setUserId: (user_id) => {dispatch(setUserId(user_id))},
    setPaymentMethod: (payment_method) => {dispatch(setPaymentMethod(payment_method))},
    loginCustomer: (user_pin) => {dispatch(loginCustomer(user_pin))},
    storeCustomerAuth: (auth_token) => {dispatch(storeCustomerAuth(auth_token))},
    setUserInfo: (user_info) => {dispatch(setUserInfo(user_info))},
    isMerchant: (is_merchant) => {dispatch(isMerchant(is_merchant))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLoginRedirect(CustomerSignup))
