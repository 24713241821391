import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Cleave from 'cleave.js/react';

//Components
import Modal from '../NewModal';
import Input from '../../inputs/Input';
import InputWrapper from '../../wrappers/InputWrapper';
import Label from '../../display/Label';
import Typeahead from '../../inputs/Typeahead';
import HideShow from '../../function/HideShow';
import CardConnect from '../../../utils/CardConnect';


//Redux
import { setNotification } from '../../../js/actions/notifications';

//Assets
import { ModalContent } from '../generalModalStyling';

//API & Utils
import paymentAPI from '../../../api/payments/paymentAPIs';
import globalUtils from '../../../utils/globalUtils';

const NewMethodContainer = styled.div`
  height: auto;
  border: 1px solid #E4E4E4;
  padding: 5px 15px;
  width: 330px;
  margin: 0 auto;
  margin-top: 10px;
  margin-left: 10px;
  .input-wrapper {
    margin-bottom: 10px;
    position: relative;
    height: 50px;
  }
  .input-wrapper.cvv {
    width: 50%;
    input {
      width: 66%;
    }
  }
  .cc-wrapper {
    border-bottom: 1px solid #A4A4A4;
    position: relative;
    height: 32px;
  }
  .custom-label {
    font-size: 12px;
    padding-bottom: 5px;
    color: #7E7E7E;
    text-transform: uppercase;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    & .expiration {
      width: 40%;
      input {
        width: 100%;
      }
    }
    & .zip {
      text-align: right;
      margin-right: 20px;
      width: 72%;
      input {
        width: 62%;
      }
    }
    .checkbox-wrapper {
      margin-top: 20px;
      width: 43%;
      .checkbox {
        bottom: 12px;
        left: 26px;
        color: #7E7E7E;
        font-size: 12px;
        font-weight: 500;
      }
      .text {
        color: #7E7E7E;
        font-weight: 500;
        margin-top: 0px;
      }
    }
  }
`;

const CreditCard = styled.img`
  width: 35px;
  height: auto;
  position: absolute;
  right: 11px;
  top: 4px;
`;

class TakePaymentModal extends Component {
  constructor(props){
    super(props);
    let payment_options = []

    for (let i in this.props.payment_methods) {
      payment_options.push({name: "payment_method", id: this.props.payment_methods[i].id, format_card: this.props.payment_methods[i].format_card})
    }

    this.state = {
      showUseNewMethod: false,
      card: {},
      payment_options: payment_options,
      processing: false
    };

    this.myRefs = {
      invoice: React.createRef(),
      amount_paid: React.createRef(),
      payment_method: React.createRef()
    };
  }

  componentDidMount() {
    if(this.props.preloaded) {
      let invoice = this.props.invoices[0]
      let defaultCard = {invoice: invoice.id}
      this.setState({
        card: defaultCard
      })
    }
  }

  takePayment = async (values) => {
    if(!this.state.payment_method) {
      if(!this.state.card.token || !this.state.card.name || !this.state.card.expiration || !this.state.card.last4) {
        this.props.setNotification({type: 'error', message: 'Please complete all fields'})
        return;
      }
    }

    if(!this.state.card.invoice) {
      this.props.setNotification({type: 'error', message: 'Please select an invoice'})
      return;
    }

    let payment_info = Object.assign({}, this.state.card)

    if (this.state.payment_method) {
      payment_info.payment_method = this.state.payment_method;
      payment_info.amount_paid = globalUtils.convertDollarsToCents(payment_info.amount_paid);
    }
    let payment = await paymentAPI.takePayment(payment_info, this.props.auth_token)
    if (payment && !payment.err) {
      this.props.onClose("showTakePaymentModal");
      this.props.setNotification({message: 'Payment received.'})
      this.props.getInvoiceDetail();
    }
    if (payment.err && payment.err.response) {
      this.setState({payment_errors: payment.err.response.data.detail})
      this.props.onClose("showTakePaymentModal");
      this.props.setNotification({message: payment.err.response.data.detail, type: 'error'})
      this.props.getInvoiceDetail();
      return
    }
  }


  toggleNewMethod = () => {
    if(this.state.showUseNewMethod) {
      this.setState({showUseNewMethod: false})
    } else this.setState({payment_method: null, showUseNewMethod: true})
  }

  setToken = (token) => {
    if (token && token.validationError) {
      this.setState({ errors: token.validationError})
      return;
    } else {
      let newCard = globalUtils.checkCardType(token.substring(1,2), this.state.card);
      newCard.token = token;
      newCard.last4 = token.slice(-4);
      this.setState({
        token: token,
        card: newCard,
        errors: null
      })
    }
  }

  showForm = () => {
    if (this.state.showUseNewMethod) {
      return (
        <HideShow show={this.state.showUseNewMethod}>
          <NewMethodContainer>
            <div className="input-wrapper">
              <div className="custom-label">Card Number</div>
              {/*<CardNumber noBorder={true} name="card_number" value={this.state.card.card_number} onChange={this.onChange}/>*/}
              <div className="cc-wrapper">
                <CardConnect
                  className="input"
                  type="tel"
                  name="card_number"
                  options={{creditCard: true}}
                  autoComplete="cc-number"
                  required
                  ref={this.myRefs.card_number}
                  setToken={(token) => this.setToken(token)}/>
                  {this.renderIcon()}
              </div>
            </div>
            <div className="input-wrapper">
              <div className="custom-label">Name on Card</div>
              <Input name="name" noBorder={true} onChange={this.onChange}/>
            </div>
            <div className="space-between">
              <div className="expiration">
                <div className="custom-label">Expiry Date</div>
                <Cleave placeholder="mm/yy"
                        type="tel"
                        className="no-border"
                        name="expiration"
                        data-test-id="expiration"
                        options={{date: true, datePattern: ['m','y']}}
                        onChange={this.onChange}
                        // autoComplete="cc-exp"
                        />
              </div>
              <div className="zip">
                <div className="custom-label">Billing Zip Code</div>
                <Input name="address_zip" noBorder={true} onChange={this.onChange} maxLength="5"/>
              </div>
            </div>
          </NewMethodContainer>
        </HideShow>
      )
    }
  }

  onPaymentOptionChange = (event) => {
    if (event.value) {
      this.setState({
        payment_method: event.value
      });
    }
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let newCard = Object.assign({}, this.state.card);
    newCard[name] = value;
    this.setState({card: newCard});
  }

  onInvoiceChange = (event) => {
    let newCard = Object.assign({}, this.state.card)
    newCard.invoice = event.value;
    this.setState({card: newCard});
  }

  renderIcon = () => {
    if (typeof this.state.card === "undefined"){
      return;
    }
    if (this.state.card.card_type === "visa") {
      return <CreditCard src="https://s3.amazonaws.com/blytz/assets/visa.svg"/>
    } else if (this.state.card.card_type === "mastercard") {
      return <CreditCard src="https://s3.amazonaws.com/blytz/assets/mastercard.svg"/>
    } else if (this.state.card.card_type === "amex") {
      return <CreditCard src="https://s3.amazonaws.com/blytz/assets/amexIcon.svg"/>
    } else if (this.state.card.card_type === "discover") {
      return <CreditCard src="https://s3.amazonaws.com/blytz/assets/discover.svg"/>
    }
    else {
      return;
    }
  }

  render() {
    const { onClose, invoices } = this.props

    let invoiceDescription;
    if (invoices && invoices[0]){
          invoices[0].description ? invoiceDescription = invoices[0].description : invoiceDescription = 'No description';
    }

    return (
      <Modal context="take-payment" title="Take Payment" modalname="showTakePaymentModal" onClose={onClose} submit={(values) => this.takePayment(this.myRefs)} errors={this.state.errors} processing={this.state.processing}>
        <ModalContent>
          <InputWrapper>
            <Label label="Invoice"></Label>
            <Typeahead
              options={invoices}
              defaultValue={this.props.preloaded ? [{label: '#' + invoices[0].invoice_number + ' ' + invoiceDescription + ' ' + globalUtils.convertToDollars(invoices[0].amount), value: invoices[0].id, invoice: true}] : null}
              isDisabled={this.props.preloaded ? true : false}
              onChange={this.onInvoiceChange}
              invoices
              name="invoice"
              required
              ref={this.myRefs.invoice}/>
          </InputWrapper>
          <InputWrapper>
            <Label label="Payment Method"></Label>
            <HideShow show={!this.state.showUseNewMethod}>
              <Typeahead
                name="payment_method"
                options={this.state.payment_options}
                onChange={this.onPaymentOptionChange}
                required
                ref={this.myRefs.payment_method}
                payment_methods/>
            </HideShow>
          </InputWrapper>
          <HideShow show={this.state.showUseNewMethod}>
            <div className="custom-line stored" data-test-id={"take-pmt-use-stored-" + this.props.context} onClick={this.toggleNewMethod}>Use Stored Method</div>
          </HideShow>
          <HideShow show={!this.state.showUseNewMethod}>
            <div className="custom-line" data-test-id={"take-pmt-use-another-" + this.props.context}  onClick={this.toggleNewMethod}>Use Another Method</div>
          </HideShow>
          {this.showForm()}
        </ModalContent>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    merchant_id: state.auth.merchant_id
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TakePaymentModal);
