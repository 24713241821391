import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalContent } from '../generalModalStyling';
import Modal from '../NewModal';
import InputWrapper from '../../wrappers/InputWrapper';
import Label from '../../display/Label';
import { setNotification } from '../../../js/actions/notifications';
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';

class CancelInvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  cancelInvoice = async () => {
    let canceledInvoice = await userInvoiceAPI.deleteInvoice(this.props.invoice.id, this.props.auth_token, this.props.user_pin);
    if (canceledInvoice.err && canceledInvoice.err.response) {
      let errorText = String(canceledInvoice.err.response.data.errors);
      this.setState({errors: errorText});
      return
    } else if (canceledInvoice.err) {
      let errorText = String(canceledInvoice.err);
      this.setState({errors: errorText});
      return
    } else {
      this.props.getInvoiceDetail()
      this.props.onClose("showCancelInvoiceModal");
      this.props.setNotification({message: 'Invoice successfully canceled'});

    }
  }

  render() {
    return(
      <Modal context="cancel-invoice" title="Cancel Invoice" modalname="showCancelInvoiceModal" onClose={this.props.onClose} errors={this.state.errors} submit={this.cancelInvoice} yesNo>
        <ModalContent>
          <InputWrapper>
            <Label label="Are you sure you want to cancel this invoice?"/>
          </InputWrapper>
        </ModalContent>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelInvoiceModal);
