import React from 'react';
import styled from 'styled-components';

//Components
import ModalBreadcrumb from '../../../components/modals/ModalBreadcrumb';
import BottomButton from '../../../components/buttons/mobile/BottomButton';
import MerchantLogo from '../../../components/logos/MerchantLogo';

//API & Utils
import globalUtils from '../../../utils/globalUtils';

const Container = styled.div`
  height: 100%;
  width: 100vw;
  background: linear-gradient(315.36deg, #8D97AD 0%, #545E75 100%);
  position: fixed;
  top: 0;
  z-index: 3;
  .top-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    height: 150px;
    @media screen and (device-aspect-ratio: 40/71) {
      height: 110px;
    }
    .due-text {
      color: #C4CEE3;
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
    }
  }
`

const WhiteBox = styled.div`
  position: absolute;
  bottom: 0;
  height: 63vh;
  width: 100vw;
  background-color: #FFFFFF;
  overflow-y: scroll;
  z-index: 1;
  padding-bottom: 60px;
  @supports (-webkit-overflow-scrolling: touch) {
    height: 61%;
  } 
  .invoice-details {
    padding: 10px;
    .minimum-due {
      margin-top: 5px;
      font-size: 15px;
      line-height: 19px;
    }
    .total-due{
      font-size: 23px;
      font-weight: 500;
      line-height: 28px;
    }
    .amount-due {
      color: #656F86;
      margin-left: auto;
      margin-right: auto;
      width: 324px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .total {
        color: #00D973;
        font-size: 28px;
        line-height: 34px;
      }
      .minimum-total {
        color: #656F86;
        font-size: 21px;
        line-height: 25px;
      }
    }
    .main-divider {
      width: 324px;
      border: 1px solid #616B81;
      margin: 1px auto 5px auto;
    }
    .description {
      display: flex;
      justify-content: center;
      color: #626C83;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      margin-bottom: 10px;
    }
    .table {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .row {
        display: flex;
        justify-content: space-between;
        width: 305px;
      }
      .header {
        color: #C4C4C4;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
      .item {
        color: #656F86;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }
      .line-item-divider {
      width: 305px;
      border: 1px solid #A5ADBC;
      }
    }
  }
  p {
    color: #A0A6B3;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
`;

export default function InvoiceDetails(props) {
  const { invoice, back, logo } = props;

  const lineItems = invoice.invoice_items.map( item => {
    return(
      <div className="row" key={item.id}>
        <span className="item">{item.description}</span>
        <span className="item">{globalUtils.convertToDollars(item.amount)}</span>
      </div>
    )
  })

  const appendItems = invoice.invoice_append_items.map( item => {
    return(
      <div className="row" key={item.id}>
        <span className="item">{item.description}</span>
        <span className="item">{globalUtils.convertToDollars(item.amount)}</span>
      </div>
    )
  })

  const subTotal = invoice.invoice_items.reduce( (prev, current) => {
    return prev += current.amount
  }, 0);

  return (
    <Container>
      <div className="top-box">
        <ModalBreadcrumb page="INVOICE" back={back}/>
        <MerchantLogo showMerchantName={true} logo={logo} name={props.name} fullSize/>
        <span className="due-text">Due {globalUtils.formatDate(invoice.due_date)}</span>
      </div>
      <WhiteBox>
        <div className="invoice-details">
          <div className="amount-due total-due">
            <span>Invoice Amount</span>
            <span className="total">{globalUtils.convertToDollars(invoice.amount)}</span>
          </div>
          <div className="amount-due minimum-due">
            <span>Minimum Amount Due</span>
            <span className="minimum-total">{globalUtils.convertToDollars(invoice.minimum_amount_due)}</span>
          </div>
          <hr className="main-divider"/>
          <span className="description">{invoice.description}</span>
          <div className="table">
            <div className="row">
              <span className="header">Item</span>
              <span className="header">Amount</span>
            </div>
            { lineItems }
            <hr className="line-item-divider"/>
            <div className="row" style={{marginBottom: '5px'}}>
              <span className="item">Subtotal</span>
              <span className="item">{globalUtils.convertToDollars(subTotal)}</span>
            </div>
            { appendItems }
            <hr className="line-item-divider"/>
            <div className="row">
              <span className="item">Total</span>
              <span className="item">{globalUtils.convertToDollars(invoice.amount)}</span>
            </div>
          </div>
        </div>
      </WhiteBox>
      <BottomButton absolute={true} data-test-id="invoice-detail-done" onClick={ back }>Done</BottomButton>
    </Container>
  )
}

// Needs updated Share Icon