import React, {Component} from 'react';
import styled from 'styled-components';

const Button = styled.div`
  width: 102px;
  height: 38px;
  line-height: 2.5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.primary ? '#64ABF5' : 'white'};
  background-color: ${props => props.primary ? 'white' : '#64ABF5'};
  padding: 8px;
  border: 2px solid #64AAF2;
  box-shadow: inset 0 1px 0 0 rgba(212,217,192,0.52);
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
`;

export default class ActionButtonMobile extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Button {...this.props} className={this.props.className} type={this.props.type}>{this.props.children}</Button>
    )
  }
}
