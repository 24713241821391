import { injectGlobal } from 'styled-components';

injectGlobal`
  * { box-sizing: border-box; }

  html {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  h1, h2, h3 {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .input {
    width: 250px;
    height: 40px;
    text-indent: 5px;
    border-radius: 5px;
    letter-spacing: normal;
  }
  .input:focus {
    border: 2px solid #64ABF5 !important;
    outline: none;
  }

.no-border {
  border-bottom: 1px solid #A4A4A4;
}

.modal-input {
  height: 30px;
  width: 290px;
  /* border: 1px solid #9E9E9E; */
  border-radius: 3px;
  text-indent: 5px;
  &:focus {
    border: 2px solid #64ABF5;
    outline: none;
  }
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      text-indent: 5px;
    }
}

  .merchant-input {
    width: 250px;
    height: 30px;
    text-indent: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    letter-spacing: normal;
    margin-top: 3px;
  }
  .merchant-input:focus {
    border: 2px solid #64ABF5 !important;
    outline: none;
  }

  @media only screen and (max-width: 580px) {
    .input {
      width: 100%;
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 581px) {
    .input {
      width: 100%;
      font-size: 20px;
    }
  }
`;
