export const STORE_MERCHANT_LOGO = "STORE_MERCHANT_LOGO";
export const STORE_MERCHANT_NAME = "STORE_MERCHANT_NAME";
export const STORE_MERCHANT_TAGS = "STORE_MERCHANT_TAGS";
export const STORE_ACH_INFO = "STORE_ACH_INFO";
export const STORE_INTEGRATIONS = "STORE_INTEGRATIONS";

export const storeMerchantLogo = merchant_logo => ({ type: STORE_MERCHANT_LOGO, payload: merchant_logo });
export const storeMerchantName = merchant_name => ({ type: STORE_MERCHANT_NAME, payload: merchant_name});
export const storeMerchantTags = merchant_tags => ({ type: STORE_MERCHANT_TAGS, payload: merchant_tags});
export const storeAchInfo = ach_info => ({ type: STORE_ACH_INFO, payload: ach_info});
export const storeIntegrations = integrations => ({ type: STORE_INTEGRATIONS, payload: integrations});
