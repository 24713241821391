import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Input from '../../inputs/Input';
import ModalButtonCombo from '../../buttons/ModalButtonCombo';
import groupsAPI from '../../../api/groups/groupsAPI';
import Typeahead from '../../inputs/Typeahead';

const Container = styled.div`
  width: 390px;
  height: 425px;
  box-shadow: 3px 7px 10px 1px rgba(113,113,113,0.5);
  z-index: 7;
  padding: 15px;
  margin-top: 65px;
  background-color: #fff;
  z-index: 300;
  position: fixed;
  .errors {
    color: #fd7272;
    text-align: center;
  }
  .css-triangle {
    position: relative;
    background: #fff;
    box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }
  .css-triangle::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    left: 55px;
    border: 1em solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(134deg);
    box-shadow: -3px 3px 2px -3px rgba(111,111,111,0.3);
    }
  .input-container {
    display: flex;
    margin-bottom: 20px;
    .input-wrapper {
      width: 100%;
    }
    input {
      width: 90%;
      height: 39px;
      border: 1px solid #9E9E9E;
      border-radius: 3px;
      text-indent: 5px;
    }
  }
  .title {
    color: #6C768C;
    font-size: 17px;
    margin-bottom: 10px;
  }
  .top-section {
    display: flex;
    margin-top: 10px;
    .name {
      width: 100%;
    }
  }
  .middle-section {

  }
  .bottom-section {
    padding-bottom: 10px;
    .css-va7pk8 {
      bottom: -4px;
      left: 1px;
    }
    span.text {
      margin-top: 12px;
      margin-right: 10px;
    }
  }
`

// const options = [
// 	{ label: 'Any', value:'any'},
// 	{ label: 'Paid', value:'paid'},
// 	{ label: 'Late', value:'late'}
// ];

const locationStyles = {
  control: (base, state) => ({
    ...base,
    width: 324,
    height: 39,
    backgroundColor: '#FFFFFF',
    border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E',
    '&:placeholder': {
      color: '#A1A1A1'
    },
    '&:hover': {
      border: state.isFocused ? '1px solid #64ABF5' : state.isDisabled ? '1px solid #9E9E9E' : '1px solid #9E9E9E'
    }
  }),
  menuList: base => ({
    ...base,
    height: 'auto',
    maxHeight: 100,
    width: 324
  }),
  menu: base => ({
    ...base,
    height: 'auto',
    maxHeight: 100,
    width: 324
  })
}

class GroupsModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      billerDisabled: false
    };

    // this.myRefs = {
    //   merchant_id: React.createRef()
    // }
  }

  componentDidMount() {
    let { merchant } = this.props;
    if(merchant.merchants && merchant.merchants.length < 2) {
      this.setState({billerDisabled: true})
    }
  }

  handleChange = (event) => {
    if (event.target) {
      const target = event.target;
      let value = target.value;
      const name = target.name;
      if (name === 'email') {
        let formatted = value.toLowerCase();
        value = formatted;
      }
      this.setState({
        [name]: value
      });
    } else {
      this.setState({
				merchant_id: event.value,
				display_biller: event
      })
    }
  };


  createGroup = async() => {
    if (!this.state.name || !this.state.merchant_id) return this.setState({errors: 'Name and location are required'});
    let data = {
      name: this.state.name,
      description: this.state.description,
      conditions: [{
        type: 'filter',
        field_name: 'merchant_id',
        operator: 'exact',
        value: this.state.merchant_id
      }]
    }
    let response = await groupsAPI.createGroup(this.props.auth_token, data)
    if (response && !response.err) {
      this.props.getData();
      this.props.onClose();
    } else {
      this.setState({errors: response.err.response.data.detail})
    }
  }

  render() {
    return (
      <Container className="groups-modal" {...this.props}>
        <div className="css-triangle"></div>
        <div className="errors">{this.state.errors}</div>
        <div className="top-section">
          <div className="name">
            <div className="title">Group Name</div>
            <div className="input-container">
              <Input name="name" data-test-id="group_name" placeholder="Name" required onChange={this.handleChange}/>
            </div>
          </div>

        </div>
        <div className="middle-section">
          <div className="title">Description</div>
          <div className="input-container">
            <Input name="description" data-test-id="group_description" placeholder="Description" required onChange={this.handleChange}/>
          </div>
        </div>
        <div className="bottom-section">
          <div className="title">Location</div>
              <Typeahead options={this.props.merchant}
                  placeholder="Search Locations"
                  name="merchant_id"
                  // required
                  styles={locationStyles}
                  onChange={this.handleChange}
                  isDisabled={this.state.billerDisabled}
									value={this.state.display_biller}
                  // ref={this.myRefs.merchant_id}
                  />
          {/*<div className="title">Payment Status</div>
          <div className="input-container">
            <Typeahead
              custom
              placeholder="Select..."
              onChange={this.onStatusChange}
              name="payment_status"
              options={options}
              styles={conditionDropdown}
              components={{DropdownIndicator}}/>
            <HideShow show={this.state.payment_status === 'late'}>
              <Input name="state" placeholder="0 days"/>
              <span className="text">TO</span>
              <Input name="state" placeholder="50 days"/>
            </HideShow>
          </div>*/}
        </div>
        <ModalButtonCombo custom teal secondary first="Cancel" second="Done" onClose={this.props.onClose} submit={this.createGroup}/>

      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token
  };
};

export default connect(
	mapStateToProps
)(GroupsModal);
