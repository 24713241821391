import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Table from '../../../components/tables/Table';
import caliberAPI from '../../../api/caliber/caliberAPI';
import globalUtils from '../../../utils/globalUtils';

const columns = [
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: true,
      sortDirection: 'desc',
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          globalUtils.formatDate(value)
        )
      },
    }
  },
  {
    name: "records",
    label: "Record Count",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "id",
    label: "Download",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Link data-test-id="download-file-lockbox" onClick={() => downloadFile(value)} download>Download</Link>
        )
      },
    }
  }
]

const Link = styled.a`
  text-decoration: none;
  color: blue;
  cursor: pointer;
`

let downloadFile = async (id) => {
  // var inflate = require('pako/lib/inflate').inflate;
  let file = await caliberAPI.downloadFile(id);
  // var text = inflate(file, {to: 'string'});
  //
  console.log(file)
  // console.log(text)
}

const options = {
  filterType: 'checkbox',
  responsive: 'stacked',
  selectableRows: true,
  search: false,
  download: false,
  filter: false,
  print: false,
  viewColumns: false
};

class CaliberLockbox extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount = () => {
    this.createFile();
    this.getData();
  }

  getData = async () => {
    let data = await caliberAPI.getList(this.props.auth_token);
    if (data) return this.setState({data});
    // console.log(data)
  }

  createFile = async () => {
    let data = {
      year: 2019,
      month: 5,
      day: 27
    }
    // console.log(data)
    let file = await caliberAPI.createFile(data, this.props.auth_token)
    console.log(file)
  }

  render() {
    return (
      <Table
        title="Caliber Lockbox Files"
        data={this.state.data}
        options={options}
        columns={columns}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token
  };
};

export default connect(
  mapStateToProps
)(CaliberLockbox)
