import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'caliber/';

class caliberAPI {

  getList = (auth_token) => {
    return apiUtils.get(url + "get_lockbox_files", auth_token);
  };

  createFile = (data, auth_token) => {
    return apiUtils.post(url + "create_lockbox_file", data, auth_token);
  };

  downloadFile = (id, auth_token) => {
    return apiUtils.get(url + id + '/download_lockbox_file');
  };

}

export default new caliberAPI();
