import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";

//assets
import blytz_logo from '../../assets/images/logo-gradient.png';

import { setNotification } from '../../js/actions/notifications';
import globalUtils from '../../utils/globalUtils';

//components
import CurrencyInput from '../../components/inputs/CurrencyInput';
import HideShow from '../../components/function/HideShow';
import Input from '../../components/inputs/Input';
import CardConnect from '../../utils/CardConnect';
import ExpirationDate from '../../components/inputs/ExpirationDate';
import DonationCard from '../../components/donations/DonationCard';
import DonationReceipt from '../../components/donations/DonationReceipt';

//api
import customerAuthAPI from '../../api/auth/customerAuthAPI';

const breakpoints = {
  largePhoneHeight: '(min-height: 667px)',
  ipadHeight: '(min-height: 1024px)',
}

const Container = styled.div`
  background: linear-gradient(314.64deg, #545E75 100%, #8D97AD 0%);
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
  align-items: center;

  .blytz-logo-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .logo {
      height: 40px;
      width: auto;
      margin-top: 3vh;
      margin-right: 25px;
    }
  }
  .merchant-logo-container {
    
  }
  .merchant-logo {
    height: 15vh;
    max-height: 125px;
    width: auto;
    display: block;
    margin: 2vh auto 4vh;
  }
  .donation-container {
    margin: 0 auto 4vh;
    text-align: center;

    .label {
      color: ${state => state.amount ? '#fff' : 'rgba(255, 255, 255, .5)'};
      font-size: 5vh;
      margin-bottom: 2vh;
      font-weight: 600;
    }
    .custom-input {
      border: none;
      width: 100%;
      margin: 0 auto;
      font-size: 10vh;
      color: ${state => state.amount ? '#fff' : 'rgba(255, 255, 255, .5)'};
      border-bottom: ${state => state.amount ? '2px solid #fff' : '2px solid rgba(255, 255, 255, .5)'};
      background: transparent;
      text-align: center;
      padding-bottom: 10px;
      outline: none;
      &::placeholder {
        color: rgba(255, 255, 255, .5);
      }
      @media ${breakpoints.ipadHeight}{
        font-size: 100px;
      }
    }
  }
  .set-amounts {
    display: flex;
    justify-content: space-between;
    text-align: center;
    .amount {
      width: 106px;
      height: 50px;
      border: 4px solid transparent;
      border-image-source: url('https://s3.amazonaws.com/blytz/assets/gradient-border.png');
      border-image-slice: 20;
      border-image-width: 20px;
      color: #A6F1E5;
      font-size: 25px;
      line-height: 1.75;
    }
  }
  .button {
    width: 100%;
    height: 10vh;
    max-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #2BC2D8, #41ABE7);
    bottom: 0;
    font-size: 6vh;
    text-align: center;
    color: #fff;
    font-weight: 600;

    @media ${breakpoints.largePhoneHeight} {
      font-size: 35px;
    }
  }
  .donate-button {
    height: 10vh;
    max-height: 70px;
    width: 100%;
    background: linear-gradient(to right, #22CED5, #74F9C9);
    position: fixed;
    bottom: 0;
    font-size: 6vh;
    text-align: center;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media ${breakpoints.largePhoneHeight} {
      font-size: 35px;
    }
  }
  .personal-info {
    
    margin: 0 auto;
    .cancel {
      text-decoration: underline;
      color: #FFB5B2;
      font-size: 19px;
      text-align: center;
      margin-top: 20px;

    }
    .header {
      text-align: center;
      color: #fff;
      font-size: 27px;
      margin-bottom: 10px;
      &.payment {
        margin-bottom: 40px;
        font-size: 22px;
      }
    }
    .sub-header {
      color: #D8DADF;
      font-size: 19px;
      text-align: left;
      margin-bottom: 20px;
    }
    .input-container {
      text-align: left;
      margin-bottom: 15px;
      #tokenFrame {
        height: 40px;
        width: 100%;
        border-bottom: 2px solid #DEE0E3;
      }
      .label {
        color: #AEB2BD;
        font-size: 18px;
        margin-bottom: 8px;
      }
      .custom-field {
        color: #DEE0E3;
        font-size: 23px;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #DEE0E3;
        padding-bottom: 5px;
        width: 100%;

        &::placeholder {
          color: #DEE0E3;
        }
      }
    }
  }
  .small-text {
    color: #AEB2BD;
    font-size: 16px;
    text-align: center;
  }
  .payment-info-container {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  .detail-container {
    text-align: center;
    color: #fff;
    border-bottom: .5px solid #fff;
    margin: 0 auto;
    width: 81%;
    &::after {
      content: "";
      clear: both;
      display: table;
    }
    & .key {
      margin-left: 15px;
    }
    .left-column {
      float: left;
      width: 38%;
      &.values {
        width: 53%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        margin-left: 15px;
      }
      .pay-detail {
        font-size: 16px;
        margin-bottom: 15px;
        span {
          margin-left: 10px;
        }
      }
    }
    .pay-title {
      color: #AEB2BD;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .button-container {
    width: 100vw;
    height: 10vh;
    max-height: 70px;
  }

  .content-container {
    flex: 1 1 auto;
    overflow: auto;
    margin: 3vh 0;
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
  }
`

const FeeContainer = styled.div`
  color: rgba(255,255,255,.5);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  height: 45px;
  margin-bottom: 4vh;
`

class Donation extends Component {
  constructor(props){
    super(props);
    const values = globalUtils.getQueryString(this.props.location.search.slice(1));
    this.state = {
      phone: values.phone,
      merchant_name: globalUtils.replaceText(values.merchant_name),
      showPersonalForm: false,
      showDonation: true,
      description: 'Donation',
      donationFee: 0.0,
      merchant_logo: null, 
    };

    this.myRefs = {
      first_name: React.createRef(),
      last_name: React.createRef(),
      email: React.createRef(),
      exp: React.createRef()
    }
  }

  componentDidMount = () => {
    this.getLogo();
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name
    this.setState({ [name]: value})
  };

  getLogo = async () => {
    let data = await customerAuthAPI.getLogo(this.state.merchant_name)
    if (data && data.merchant_logo) {
      this.setState({
        merchant_logo: data.merchant_logo,
        donationFee: data.donation_fee * .01 // convert percentage to decimal
      })
    }
  }

  setValue = (value) => {
    if (value === '25') {
      this.setState({ amount: '$25.00'});
    } else if (value === '75') {
      this.setState({ amount: '$75.00'});
    } else if (value === '150') {
      this.setState({ amount: '$150.00'})
    }
  }

  toggleForm = () => {
    if (this.state.amount) {
      this.setState( prevState => {
        return {
          showPersonalForm: !prevState.showPersonalForm,
          showDonation: false
        }
      })
    }
  }

  togglePaymentForm = () => {
    if (!globalUtils.checkValues(this.myRefs)) return;

    if (this.state.amount && this.state.first_name && this.state.last_name && this.state.email) {
      this.setState( prevState => {
        return {
          showPaymentForm: !prevState.showPaymentForm,
          showPersonalForm: false,
          showDonation: false
        }
      })
    }
  }

  changePaymentInfo = () => {
    this.setState({ showPaymentForm: true, showPersonalForm: false, showDonation: false, readyToDonate: false});
  }

  setToken = (token) => {
    if (token && token.validationError) {
      this.setState({ errors: token.validationError})
      return;
    } else {
      let newCard = globalUtils.checkCardType(token.substring(1,2), this.state.card);
      newCard.token = token;
      newCard.last4 = token.slice(-4);
      this.setState({
        card: newCard,
        errors: null
      })
    }
  }

  goToStart = () => {
    if (this.state.card && this.state.card.token && this.state.name_on_card && this.state.exp) {
      this.setState({ showDonation: true, showPaymentForm: false, readyToDonate: true})
    }
  }

  startOver = () => {
    this.setState({ showDonation: true, showPersonalForm: false, showPaymentForm: false, name: undefined, email: undefined, description: undefined});
  }

  goBack = () => {
    this.setState({ showPersonalForm: true, showPaymentForm: false});
  }

  makeDonation = async (event) => {
    let data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone: this.state.phone,
      merchant_name: this.state.merchant_name,
      token: this.state.card.token,
      exp: this.state.exp,
      amount: this.getGrandTotal(),
      description: this.state.description,
      donation: true
    }
    if (!event.detail || +event.detail === 1){
      let donation = await customerAuthAPI.oneOff(data);
      if (donation.err) {
        this.props.setNotification({message: donation.err.response.data.detail, type: 'error', mobile: true})
      }

      if (donation.success) {
        this.setState({showReceipt: true, readyToDonate: false, showDonation: false, showPersonalForm: false, showPaymentForm: false});
      }
    }
  }

  showAmount = () => {
    let amount = globalUtils.convertDollarsToCents(this.state.amount);
    return globalUtils.convertToDollars(amount);
  }

  showConvenienceFee = () => {
    return globalUtils.convertToDollars(this.getConvenienceFee())
  }

  showGrandTotal = () => {
    return globalUtils.convertToDollars(this.getGrandTotal())
  }

  getConvenienceFee = () => {
    let amount = globalUtils.convertToCentsInt(this.state.amount) || 0;
    return Math.ceil(amount * this.state.donationFee)
  }

  getGrandTotal = () => {
    let amount = globalUtils.convertToCentsInt(this.state.amount) || 0
    let fee = this.getConvenienceFee()
    return amount + fee
  }
  
  render() {
    const cardInputStyles = `
      color: rgba(222, 224, 227, 1);
      background: transparent;
      font-size: 23px;
      font-family: 'Montserrat',sans-serif;
      font-weight: lighter;
      letter-spacing: .5px;
    `
    return (
      <Container {...this.state}>
        <div className="blytz-logo-container">
          <img className="logo" src={blytz_logo} alt="blytzlogo"/>
        </div>
        <div className="content-container">
          <HideShow show={this.state.showReceipt}>
            <DonationReceipt data={this.state}/>
          </HideShow>
          <HideShow show={this.state.showDonation }>
            <div className="merchant-logo-container">
              <img className="merchant-logo" src={this.state.merchant_logo} alt="merchantlogo"/>
            </div>
            <div className="donation-container">
              <div className="label">Enter Donation</div>
              <CurrencyInput className="custom-input" name="amount" placeholder="$0.00" required value={this.state.amount} onChange={this.onChange}/>
            </div>
        
            <HideShow show={this.state.donationFee}>
              <FeeContainer>
                <div>
                  + {this.showConvenienceFee()} Convenience Fee
                </div>
                <div>
                  = {this.showGrandTotal()} Total
                </div>
              </FeeContainer>
            </HideShow>
          </HideShow>
          <HideShow show={this.state.readyToDonate}>
            <>
              <div className="detail-container">
                <div className="pay-title">Contributor</div>
                <div className="left-column">
                  <div className="pay-detail key">Name: </div>
                  <div className="pay-detail key">Email: </div>
                </div>
                <div className="left-column values">
                  <div className="pay-detail">{this.state.first_name + ' ' + this.state.last_name}</div>
                  <div className="pay-detail">{this.state.email}</div>
                </div>
              </div>
              <div className="payment-info-container">
                <DonationCard data={this.state} data-test-id="donation-change-pmt" onClick={this.changePaymentInfo}/>
              </div>
              <div className="small-text">On behalf of {this.state.description}</div>
            </>
          </HideShow>
          <HideShow show={this.state.showPersonalForm}>
            <div className="personal-info">
              <div className="header">About you</div>
              <div className="input-container">
                <div className="label">First Name</div>
                <Input className="custom-field"
                  name="first_name"
                  required
                  placeholder="First Name"
                  onChange={this.onChange}
                  autoFocus
                  value={this.state.first_name}
                  ref={this.myRefs.first_name}/>
              </div>
              <div className="input-container">
                <div className="label">Last Name</div>
                <Input className="custom-field"
                  name="last_name"
                  required
                  placeholder="Last Name"
                  onChange={this.onChange}
                  autoFocus
                  value={this.state.last_name}
                  ref={this.myRefs.last_name}/>
              </div>
              <div className="input-container">
                <div className="label">Email Address</div>
                <Input className="custom-field"
                  name="email"
                  type="email"
                  required
                  placeholder="Email"
                  onChange={this.onChange}
                  value={this.state.email}
                  ref={this.myRefs.email}/>
              </div>
              <div className="input-container">
                <div className="label">On behalf of (optional)</div>
                <Input className="custom-field" name="description" placeholder="Description" onChange={this.onChange} value={this.state.description}/>
              </div>
              <div className="cancel" data-test-id="donation-cancel-3" onClick={this.startOver}>cancel</div>
            </div>
          </HideShow>
          <HideShow show={this.state.showPaymentForm}>
            <div className="personal-info">
              <div className="header payment">Payment Info</div>
              <div className="sub-header">Card Details</div>
                <div className="input-container">
                  <div className="label">Card Number</div>
                  <CardConnect
                    className="custom-field card-field"
                    card={true}
                    donation={true}
                    type="tel"
                    name="card_number"
                    options={{creditCard: true}}
                    autoComplete="cc-number"
                    required
                    ref={this.myRefs.card_number}
                    inputStyles={cardInputStyles}
                    setToken={this.setToken}/>
                </div>
                <div className="input-container">
                  <div className="label">Name on Card</div>
                  <Input className="custom-field" name="name_on_card" required placeholder="Name" onChange={this.onChange}/>
                </div>
              <div className="input-container">
                <div className="label">Expiry Date</div>
                <ExpirationDate
                    options={{date: true, datePattern: ['m','y']}}
                    type="tel"
                    className="custom-field"
                    name="exp"
                    data-test-id="exp"
                    onChange={this.onChange}
                    autoComplete="cc-exp"
                    required
                    ref={this.myRefs.expiration}
                />
              </div>
              <div className="cancel" data-test-id="donation-cancel-4" onClick={this.goBack}>cancel</div>
            </div>
          </HideShow>
        </div>
        <div className="button-container">
          <HideShow show={this.state.showDonation && !this.state.readyToDonate}>
            <div className="button" data-test-id="donation-next-1" onClick={this.toggleForm}>Next</div>
          </HideShow>
          <HideShow show={this.state.showPersonalForm}>
            <div className="button" data-test-id="donation-next-2" onClick={this.togglePaymentForm}>Next</div>
          </HideShow>
          <HideShow show={this.state.showPaymentForm}>
            <div className="button" data-test-id="donation-next-3" onClick={this.goToStart}>Next</div>
          </HideShow>
          <HideShow show={this.state.readyToDonate}>
            <div className="donate-button" data-test-id="donation-donate-total" onClick={this.makeDonation}>Donate {this.showGrandTotal()}</div>
          </HideShow>
        </div>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Donation)
