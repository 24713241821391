import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

//Components
import WhiteContainer from '../../../components/wrappers/WhiteContainer';
import InlineEdit from '../../../components/inputs/InlineEdit';
import Breadcrumb from '../../../components/display/BreadcrumbTitle';
import HideShow from '../../../components/function/HideShow';
import ThreeDotButton from '../../../components/buttons/ThreeDotButton';
import EditInvoiceModal from '../../../components/modals/invoices/EditInvoiceModal';
import CancelInvoiceModal from '../../../components/modals/invoices/CancelInvoiceModal';
import TakePaymentModal from '../../../components/modals/payments/TakePaymentModal';
import RefundModal from '../../../components/modals/payments/RefundModal';
import CancelTransactionModal from '../../../components/modals/payments/CancelTransactionModal';
import UnscheduleModal from '../../../components/modals/payments/UnscheduleModal';

//Assets
import cancelIcon from '../../../assets/icons/cancel-red.png';
import refundIcon from '../../../assets/icons/refund_icon_blue.png'
import { blurOnLoad } from '../../../styles/mixins';

//Redux
import {setNotification} from '../../../js/actions/notifications';

//API & Utils
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';
import paymentMethodAPI from '../../../api/blytzwallet/PaymentMethod';
import globalUtils from '../../../utils/globalUtils';
import history from '../../../history';

const ContentContainer = styled.div`
  ${blurOnLoad}
  padding: 20px 10px;
  .invoice-details {
    .title {
      display: flex;
      justify-content: space-between;
      color: #787878;
      font-size: 26px;
      padding: 0 50px;
    }
    .status {
      color: #787878;
      font-size: 24px;
      padding-left: 10px;
      text-transform: capitalize;
    }
    .red-status {
      color: #FD7272;
    }
    .green-status {
      color: #06D6A0;
    }
    .blue-box {
      height: auto;
      padding-top: 10px;
      width: 93%;
      margin: 0 auto;
      .top-half {
        padding: 40px;
        height: 162px;
        width: 100%;
        background-color: #64ABF5;
        border-radius: 25px 25px 0 0;
        display: flex;
        justify-content: space-between;
        color: #fff;
        .customer-info {
          .name {
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 10px;
            a {
              color: #fff;
              cursor: pointer;
              &:hover {
                color: #9beed9;
              }
            }
          }
          .small {
            font-size: 18px;
          }
        }
        .invoice-detail {
          font-size: 19px;
        }
      }
      .bottom-half {
        height: auto;
        border: 1px solid #C2C2C2;
        width: 100%;
        box-shadow: 2px 3px 6px 0 rgba(143,143,143,0.5);
        display: flex;
        justify-content: space-between;
        .left-box {
          width: 53%;
          .title-section {
            width: 100%;
            margin-top: 40px;
            margin-left: 10px;
            display: flex;
            justify-content: space-between;
            border-bottom: 3px solid #858585;
            .company {
              margin-left: 10px;
              color: #787878;
              font-size: 24px;
              padding-bottom: 10px;
              width: 90%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .total {
              font-size: 24px;
              color: #06D6A0;
              padding-right: 5px;
              float: right;
            }
          }
          .line-item-section {
            padding-top: 20px;
            padding-left: 45px;
            margin-bottom: 30px;
            .details {
              color: #9B9B9B;
              margin-bottom: 30px;
              display: flex;
              justify-content: space-between;
            }
            .line-title {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .heading {
                font-size: 16px;
                color: #696969;
              }
            }
          }
          .due-date {
            color: #64ABF5;
            font-size: 22px;
            padding-left: 45px;
            padding-top: 30px;
            margin-bottom: 40px;
          }
        }
        .right-box {
          background-color: #EDEDED;
          width: 45%;
          height: auto;
          padding: 10px 20px;
          position: relative;
          .payments {
            color: #64ABF5;
            font-size: 22px;
            margin-bottom: 40px;
          }
          /* .refund-box {
            display: flex;
            justify-content: space-between;
          } */
          .balance {
            color: #A0A0A0;
            font-size: 18px;
            margin-bottom: 40px;
            .red {
              color: #FD7272;
              font-size: 19px;
            }
          }
          .cancel-invoice {
            height: 96px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            img {
              width: 20px;
            }
            .warning-container {
              position: absolute;
              bottom: 40px;
              right: 70px;
              background-color: white;
              padding: 20px;
              box-shadow: 3px 8px 9px 2px rgba(65,65,65,0.5);
              .warning-text {

              }
              .cancel-buttons {
                display: flex;
                justify-content: space-around;
              }
            }
            .cancel-btn {
              color: #64ABF5;
              border-bottom: 2px solid #64ABF5;
              font-size: 16px;
              padding-bottom: 2px;
              cursor: pointer;
              position: absolute;
              bottom: 18px;
              right: 45px;
            }
            .canceled-invoice {
              color: #858585;
              text-decoration: line-through;
              font-size: 16px;
              padding-bottom: 3px;
              margin-left: 10px;
            }
          }
        }
        .total-payments {
          color: #696969;
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          border-bottom: 2px solid #D5D5D5;
          padding-bottom: 3px;
          margin-top: 50px;
        }
      }
    }
  }
`;


const PaymentLine = styled.div`
  display: flex;
  margin-bottom: 20px;
  .item {
    flex-grow: .5;
    border-bottom: 2px solid #C1C1C1;
    &.date {
      text-align: left;
    }
    &.method {
      text-align: center;
    }
    &.amount {
      text-align: right;
      padding-right: 3px;
    }
    &.indent {
      margin-left: 20px;
    }
    &.refunded {
      font-size: 15px;
      color: #787878;
    }
  }
  .failed-transaction {
    color: #fd7272;
    padding-left: 2px
  }
  .scheduled-transaction {
    color: #64ABF5;
    margin-left: 5px;
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: 2px solid #64ABF5;
  }
  .refund {
    color: #9B9B9B;
    margin-left: 5px;
  }
  .external {
    padding-left: 3px;
  }
  .link {
    color: #64ABF5;
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: 2px solid #64ABF5;
  }
`

const TransactionControls = styled.div`
  width: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 5px;
  position: relative;
  bottom: 4px;
  .action-icon {
    width: auto;
    cursor: pointer;
  }
  .cancel {
    height: 19px;
  }
  .refund {
    height: 25px;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 9;
  font-size: 12px;
  text-align: center;
  top: -42px;
  right: -14px;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 0 3px;
`;


const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  color: #696969;
  font-size: 18px;
  margin-bottom: 20px;
  border-bottom: 2px solid #D5D5D5;
  padding-bottom: 5px;
  .name {
    font-size: 18px;
  }
`

const CreditCardInfo = styled.div`
  width: 100%;
  color: #787878;
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 5px;
`;

class InvoiceDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      invoice: {
        user: {},
        merchant: {},
        subscription: {},
      },
      showRefundModal: false,
      showEditInvoiceModal: false,
      showCancelInvoiceModal: false,
      showUnscheduleModal: false,
      showCancelToolTip: undefined,
      showRefundToolTip: undefined
    };
  }

  componentDidMount = () => {
    if(this.props.match.params.id === "undefined") {
      history.push('/admin/invoices');
    } else {
      this.getInvoiceDetail()
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.match.params.id !== prevProps.match.params.id) {
      this.getInvoiceDetail()
    }
  }

  showTooltip = (name, index) => {
    if(this.state[`show${name}ToolTip`] === index) {
      this.setState({[`show${name}ToolTip`]: undefined})
    } else {
      this.setState({[`show${name}ToolTip`]: index})
    }
  }

  getInvoiceDetail = async () => {
    let invoice = await userInvoiceAPI.getDetail(this.props.match.params.id, this.props.auth_token)
    if (invoice.err) {
      this.props.setNotification({message: invoice.err.response.data.detail, type: 'error'})
      return
    }
    this.getPaymentMethodData(invoice.subscription.id)
    this.setState({invoice, loaded: true});
  }

  getPaymentMethodData = async (subId) => {
    let query_string = "?user=" + subId
    let data = await paymentMethodAPI.getList(this.props.auth_token, "", query_string)
    if (data.err && data.err.response) {
      this.setNotification({message: data.err.response.data.detail, type: 'error'})
      return
    }

    if (data){
      let payment_methods = data;
      let prepared_payment_methods = [];
      if (payment_methods) {
        for (var i=0; i < payment_methods.length; i++){
          var card = payment_methods[i];
          prepared_payment_methods[i] = <CreditCardInfo key={card.card_last4}>{card.format_card + " " + card.card_exp_month+"-"+card.card_exp_year}</CreditCardInfo>
        }
      }
      this.setState({
        payment_methods: payment_methods,
        prepared_payment_methods: prepared_payment_methods
      })
    }
  }

  returnPaymentLines = () => {
    let { invoice } = this.state,
        { transactions } = invoice;
    let paymentArray = [];
    let modifiedMethod = '';
    let status = '';
    if (transactions && transactions.length > 0) {
      for (let i in transactions) {
        modifiedMethod = transactions[i].visible_method;
        if (transactions[i].visible_method && transactions[i].visible_method.includes('Mastercard')) {
          modifiedMethod = transactions[i].visible_method.replace('Mastercard', 'MC');
        }
        if (transactions[i].visible_method && this.state.invoice.transactions[i].visible_method.includes('Discover')) {
          modifiedMethod = transactions[i].visible_method.replace('Discover', 'DS');
        }
        if (transactions[i].status === 'failed' || transactions[i].status === 'declined' || transactions[i].status === 'cancelled') {
          let upperCaseStatus = transactions[i].status[0].toUpperCase() + transactions[i].status.slice(1);
          status = <span className="failed-transaction">{upperCaseStatus}</span>
        } else if (transactions[i].status === 'scheduled') {
          status = <span className="scheduled-transaction" data-test-id="invoice-detail-unschedule" onClick={ () => this.toggleModal('showUnscheduleModal')}>Unschedule</span>

        } else if(transactions[i].status === 'success' && transactions[i].type === 'charge') {
          let refundedArray = transactions.filter( transaction => transaction.refunded_transaction && transaction.refunded_transaction === transactions[i].id );
          let fullyRefunded = false;
          let anyRefund = false;
          if(refundedArray.length > 0) {
            anyRefund = true;
            if(refundedArray[0].amount_paid === transactions[i].amount_paid) {
              fullyRefunded = true;
            }
          }
          status = <TransactionControls>
            <HideShow show={!anyRefund}>
              <img className="action-icon cancel" src={cancelIcon} alt="Cancel Transaction" data-test-id="invoice-detail-cancel" onClick={() => this.cancelOrRefund(transactions[i], 'cancel')} onMouseOver={() => this.showTooltip('Cancel', i)} onMouseOut={() => this.showTooltip('Cancel', i)}/>
              <HideShow show={this.state.showCancelToolTip === i}>
                <Tooltip>Cancel Transaction</Tooltip>
              </HideShow>
            </HideShow>
            {!fullyRefunded ? <img className="action-icon refund" src={refundIcon} alt="Refund Transaction" data-test-id="invoice-detail-refund" onClick={ () => this.cancelOrRefund(transactions[i], 'refund')} onMouseOver={() => this.showTooltip('Refund', i)} onMouseOut={() => this.showTooltip('Refund', i)}/> : null}
            <HideShow show={this.state.showRefundToolTip === i}>
              <Tooltip>Refund Transaction</Tooltip>
            </HideShow>
          </TransactionControls>
        }
        let refundLines = []
        let refundedTransactions = transactions.filter( transaction => transaction.refunded_transaction === transactions[i].id )
        if(refundedTransactions.length > 0) {
          for(let j = 0; j < refundedTransactions.length; j++) {
            refundLines.push(
              <PaymentLine key={refundedTransactions[j].id}>
                <span className="item date indent refunded">{globalUtils.formatDate(refundedTransactions[j].created_at)}</span>
                <span className="item method refunded">{modifiedMethod}</span>
                <span className="item amount refunded">{globalUtils.convertToDollars(refundedTransactions[j].amount_paid)}</span>
                <span className="refund">Refund</span>
              </PaymentLine>
            )
          }
        }
        if(transactions[i].type !== 'refund') {
          paymentArray.push(
            <>
              <PaymentLine>
                <span className="item date">{globalUtils.formatDate(transactions[i].status === 'scheduled' ? invoice.scheduled_on : transactions[i].created_at)}</span>
                <span className="item method">{modifiedMethod}</span>
                <span className="item amount">{globalUtils.convertToDollars(transactions[i].amount_paid)}</span>
                {transactions[i].visible_method === "Other" ? <span className="item external">External Payment</span> : null}
                {transactions[i].visible_method !== "Other" ? status : null}
              </PaymentLine>
              { refundLines }
            </>
          );
        }
      }
      return paymentArray;
    } else {
      return "There are no payments"
    }
  }

  returnLineItems = () => {
    let lineItemsArray = [];
    if (this.state.invoice.invoice_items && this.state.invoice.invoice_items.length > 0) {
      for (let i in this.state.invoice.invoice_items) {
        lineItemsArray.push(<LineItem key={`line-item-${i}`}><span>{this.state.invoice.invoice_items[i].description}</span><span>{globalUtils.convertToDollars(this.state.invoice.invoice_items[i].amount)}</span></LineItem>)
      }
      return lineItemsArray;
    } else {
      return 'There are no line items'
    }
  }

  returnAppendItems = () => {
    let appendItemsArray = [];
    if (this.state.invoice.invoice_append_items && this.state.invoice.invoice_append_items.length > 0) {
      for (let i in this.state.invoice.invoice_append_items) {
        appendItemsArray.push(<LineItem><span>{this.state.invoice.invoice_append_items[i].description}</span><span>{globalUtils.convertToDollars(this.state.invoice.invoice_append_items[i].amount)}</span></LineItem>)
      }
      return appendItemsArray;
    }
  }

  returnPrePayItems = () => {
    let prepayItemsArray = [];
    if (this.state.invoice.invoice_prepay_items && this.state.invoice.invoice_prepay_items.length > 0) {
      for (let i in this.state.invoice.invoice_prepay_items) {
        prepayItemsArray.push(<LineItem><span>{this.state.invoice.invoice_prepay_items[i].description}</span><span>{globalUtils.convertToDollars(this.state.invoice.invoice_prepay_items[i].amount)}</span></LineItem>)
      }
      return prepayItemsArray;
    }
  }

  returnTotalPayments = () => {
    return globalUtils.convertToDollars(this.state.invoice.amount_paid);
  }

  returnBalance = () => {
    let { amount, amount_paid } = this.state.invoice;
    return globalUtils.convertToDollars(amount - amount_paid);
  }

  toggleModal = (modalname) => {
    this.setState( prevState => {
      return {
        [modalname]: !prevState[modalname]
      }
    })
  };

  cancelOrRefund = (transaction, type) => {
    if(type === 'cancel') {
      this.setState({selectedTransaction: transaction, showCancelTransactionModal: true})
    } else this.setState({selectedTransaction: transaction, showRefundModal: true})
  }

  showRefundModal = () => {
    if (this.state.showRefundModal) {
      return (
        <RefundModal
              onClose={this.toggleModal}
              getInvoiceDetail={this.getInvoiceDetail}
              modalname="showRefundModal"
              invoice_id={this.props.match.params.id}
              refund={this.state.selectedTransaction}
              amount_paid={this.state.invoice.amount_paid}
        />
      )
    }
  }

  showCancelTransactionModal = () => {
    if (this.state.showCancelTransactionModal) {
      return (
        <CancelTransactionModal
              onClose={this.toggleModal}
              refund={this.state.selectedTransaction}
              getInvoiceDetail={this.getInvoiceDetail}
              modalname="showCancelTransactionModal"
        />
      )
    }
  }

  showTakePaymentModal = () => {
    if (this.state.showTakePaymentModal) {
      return (
        <TakePaymentModal
              onClose={this.toggleModal}
              payment_methods={this.state.payment_methods}
              invoices={[this.state.invoice]}
              preloaded
              getInvoiceDetail={this.getInvoiceDetail}
              context="invoice-detail"
        />
      )
    }
  }

  showEditInvoiceModal = () => {
    if(this.state.showEditInvoiceModal) {
      return(
        <EditInvoiceModal
              invoice={this.state.invoice}
              onClose={ this.toggleModal }
              getInvoiceDetail={this.getInvoiceDetail}
        />
      )
    }
  }

  showCancelInvoiceModal = () => {
    if(this.state.showCancelInvoiceModal) {
      return <CancelInvoiceModal invoice={this.state.invoice} onClose={ this.toggleModal } getInvoiceDetail={this.getInvoiceDetail}/>
    }
  }

  showUnscheduleModal = () => {
    if(this.state.showUnscheduleModal) {
      return(
        <UnscheduleModal invoice={this.state.invoice} onClose={ this.toggleModal } getInvoiceDetail={this.getInvoiceDetail} invoice_id={this.props.match.params.id}/>
      )
    }
  }

  invoiceLink = async() => {
    let success = await userInvoiceAPI.sendInvoiceLink(this.props.match.params.id, this.props.auth_token);
    if (success.err) {
      this.props.setNotification({message: "Could not send invoice", type: 'error'})
      return
    }
    this.props.setNotification({message: 'Invoice Sent'})

  }

  showInvoiceControls = () => {
    if(this.state.invoice.state === 'upcoming' || this.state.invoice.state === 'due' || this.state.invoice.state === 'late') {
      return(
        <div className="action_buttons">
          <ThreeDotButton>
            <span data-test-id="invoice-detail-take-pmt" onClick={ () => this.toggleModal("showTakePaymentModal") }>Take Payment</span>
            <span data-test-id="invoice-detail-edit" onClick={ () => this.toggleModal("showEditInvoiceModal") }>Edit Invoice</span>
            <span data-test-id="invoice-detail-send-link-1" onClick={ () => this.invoiceLink() }>Send Invoice Link</span>
            {/*<span>Export Payment</span>
            <span>Export Invoice</span>*/}
          </ThreeDotButton>
        </div>
      )
    } else if(this.state.invoice.state === 'paid') {
      return(
        <div className="action_buttons">
          <ThreeDotButton>
            <span data-test-id="invoice-detail-send-link-2" onClick={ () => this.invoiceLink() }>Send Invoice Link</span>
            {/*<span>Export Payment</span>
            <span>Export Invoice</span>*/}
          </ThreeDotButton>
        </div>
      )
    }
  }

  showCancelButton = () => {
    let showButton = true;
    if(this.state.invoice.state === "paid" || this.state.invoice.state === "cancelled") {
      showButton = false;
    }

    if(this.state.invoice.transactions && this.state.invoice.transactions.length >= 1) {
      let successful = 0;
      let imported = 0;
      this.state.invoice.transactions.forEach( transaction => {
        if(transaction.status === "success" || transaction.status === "scheduled") {
          successful++;
        }
        if(transaction.status === "imported") {
          imported++;
        }
      });
      if(imported || successful) {
        showButton = false;
      }
    }

    if(showButton) {
      return <h3 className="cancel-btn" data-test-id="invoice-detail-cancel-2" onClick={ () => this.toggleModal('showCancelInvoiceModal') }>Cancel Invoice</h3>
    }
  }

  render() {
    return (
      <>
        <Breadcrumb page="Invoice Details"/>
        <WhiteContainer>
          <ContentContainer>
            {this.showTakePaymentModal()}
            {this.showEditInvoiceModal()}
            {this.showCancelTransactionModal()}
            {this.showRefundModal()}
            {this.showCancelInvoiceModal()}
            {this.showUnscheduleModal()}
            <div className={classNames("invoice-details", {'blurred': !this.state.loaded})}>
              <div className="title">
                <div className="status_box">
                  Status:<span className={classNames('status', {'red-status': this.state.invoice.state === 'late' || this.state.invoice.state === 'due', 'green-status': this.state.invoice.state === 'paid'})}>{this.state.invoice.state}</span>
                </div>
                { this.showInvoiceControls() }
              </div>
              <div className="blue-box">
                <div className="top-half">
                  <div className="customer-info">
                    <div className="name">
                      <Link to={"/admin/customers/profile/" + this.state.invoice.subscription.id}>{this.state.invoice.subscription.full_name}</Link>
                    </div>
                    <div className="small">{this.state.invoice.subscription.formatted_phone}</div>
                    <div className="small">{this.state.invoice.subscription.email}</div>
                    <div className="small">
                    {this.state.invoice.subscription.service_address} {this.state.invoice.subscription.service_address2 ? this.state.invoice.subscription.service_address2 + ' ' : ''}
                    {this.state.invoice.subscription.service_address_city ? this.state.invoice.subscription.service_address_city + ',' : ''} {this.state.invoice.subscription.service_address_state} <span>{this.state.invoice.subscription.service_address_zip}</span>
                    </div>
                  </div>
                  <div className="invoice-detail">
                    <div className="detail">INVOICE: #{this.state.invoice.external_invoice_id || this.state.invoice.invoice_number}</div>
                    <div className="detail">DATE: {globalUtils.formatDate(this.state.invoice.created_at)}</div>
                    <InlineEdit className="detail" showLabel={true} name="due_date"
                      value={globalUtils.formatDate(this.state.invoice.due_date)}
                      placeholder='MM/DD/YYYY' submit={this.UpdateInvoice}>
                      <div className="detail">DUE: {globalUtils.formatDate(this.state.invoice.due_date)}</div>
                    </InlineEdit>
                  </div>
                </div>
                <div className="bottom-half">
                  <div className="left-box">
                  <div className="title-section">
                      <div className="company">{this.state.invoice.merchant.name}</div>
                      <InlineEdit className="total" showLabel={true} name="amount"
                      value={globalUtils.convertToDollars(this.state.invoice.amount)} placeholder='0'>
                        <div className="total">Invoiced: <span className="green">{globalUtils.convertToDollars(this.state.invoice.amount)}</span></div>
                      </InlineEdit>
                    </div>
                    <div className="line-item-section">
                      <div className="details">
                        <span>{this.state.invoice.description}</span>
                        <span>Minimum Due: {globalUtils.convertToDollars(this.state.invoice.minimum_amount_due)}</span>
                      </div>
                      <div className="line-title">
                        <div className="heading">Item</div>
                        <div className="heading">Amount</div>
                      </div>
                      <div className="line-item-wrapper">
                        {this.returnLineItems()}
                        {this.returnAppendItems()}
                        {this.returnPrePayItems()}
                      </div>
                    </div>
                    <div className="due-date">DUE: {globalUtils.formatDate(this.state.invoice.due_date)}</div>
                    </div>
                  <div className="right-box">
                    <div className="payments">Payments</div>
                    {/* <div className="refund-box">
                      { this.renderRefundButton()
                    </div> */}
                    <div className="balance">Balance <span className="red">{this.returnBalance()}</span></div>
                    <div className="payment-line-container">
                      {this.returnPaymentLines()}
                    </div>
                    <div className="total-payments">Total Payments Made<span>{this.returnTotalPayments()}</span></div>
                    <div className="cancel-invoice">
                      { this.showCancelButton() }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
        </WhiteContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    user_role: state.auth.user_role
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDetail));
