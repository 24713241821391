import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';

//Components
import PageTitle from '../../../components/display/PageTitle';
import BigCard from '../../../components/cards/BigCardMobile';

//API & Utils
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';
import globalUtils from '../../../utils/globalUtils';

//Redux
import { setPaymentMethod, storeCustomerAuth } from '../../../js/actions/auth';

const Container = styled.div`
  margin-top: 90px;
`;
const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const Text = styled.div`
  font-size: 14px;
  margin-right: 5px;
`

class BillsDashboard extends Component {
  constructor(props){
    super(props);

    var undef;
    this.props.setPaymentMethod(undef)

    this.bill_state = ""
    this.title_text = "Upcoming Bills"
    if (this.props.match.params.bill_state === "" || typeof this.props.match.params.bill_state !== "undefined"){
        this.bill_state = this.props.match.params.bill_state
    }
    if (this.bill_state === "paid") {
      this.title_text = "Receipts"
    }
    this.state = {
      invoices: [],
      loaded: false
    };
    this.getData();
  }

  routeToBill = (id) => {
    this.props.history.push('/customer/blytzwallet/invoice/' + id)
  }

  getData = async () => {
    let invoices = await userInvoiceAPI.getList(this.bill_state, this.props.auth_token, this.props.user_pin);
    if (invoices.err && invoices.err.response) {
      this.setState({errors: invoices.err.response.data.detail})
      return
    }
    if (invoices) {
      this.setState({
          invoices: invoices,
          loaded: true
        }
      );
    }
    this.renderUpcomingBills()
  };

  renderUpcomingBills = () => {
    let invoicesArray = []
    if (this.state.loaded) {
      let invoices = this.state.invoices;
      for (let i in invoices) {
        var date_text = "Due:"
        var view_text = "VIEW BILL"
        var date_display = invoices[i].due_date
        if (invoices[i].state === "paid"){
          date_text = "Paid on:"
          view_text = "VIEW RECEIPT"
          date_display = invoices[i].paid_date
        }
        let formatted_date = globalUtils.formatDate(date_display)
        invoicesArray.push(
          <BigCard
            key={invoices[i].id}
            title={invoices[i].merchant.name}
            description={invoices[i].description}
            amount={globalUtils.convertToDollars(invoices[i].amount)}
            date_text={date_text}
            formatted_date={formatted_date}
            view_text={view_text}
            data-test-id="bills-dashboard-to-bill"
            onClick={(id) => this.routeToBill(invoices[i].id)}
            >
          </BigCard>)
      }
      if (invoicesArray.length === 0){
        if (this.props.match.params.bill_state === "unpaid"){
          return <TextWrapper>
            <Text>You're all caught up!</Text>
            </TextWrapper>
        } else {
            return <TextWrapper>
            <Text>You have no receipts.</Text>
            </TextWrapper>
        }
      }
      return invoicesArray;
    } else {
      return;
    }
  };

  render() {
    return (
      <Container>
        <PageTitle>{this.title_text}</PageTitle>
        {this.renderUpcomingBills()}
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    logged_in: state.auth.logged_in,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setPaymentMethod: (payment_method) => {dispatch(setPaymentMethod(payment_method))},
    storeCustomerAuth: (auth_token) => {dispatch(storeCustomerAuth(auth_token))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillsDashboard)
