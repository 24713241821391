import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

//Components
import MobileBackground from '../../../components/mobile/GreyBackground';
import PageWrapper from '../../../components/mobile/MobileWrapper';
import CustomerInitials from '../../../components/avatars/CustomerInitials';
import ActionButton from '../../../components/buttons/ActionButton';
import HideShow from '../../../components/function/HideShow';

//API & Utils
import userAPI from '../../../api/customer/userAPI';
import globalUtils from '../../../utils/globalUtils';

//Assets
import alert_icon from '../../../assets/icons/alert_icon.png';
import {hideScrollbar} from '../../../styles/mixins';

//Redux
import { setUserInfo } from '../../../js/actions/auth';
import {	setNotification } from '../../../js/actions/notifications';

const ProfileWrapper = styled.div`
margin-top: 70px;
position: relative;
height: auto;
width: 100vw;
overflow: hidden;
@media (min-width: 581px) {
	margin-top: -150px;
}
input {
	height: 21px;
	width: 100%;
	background: transparent;
	border: none;
	border-bottom: 1px solid #fff;
	font-size: 16px;
	text-align: left;
	@media screen and (min-device-height: 668px) {
		height: 24;
	}
	::placeholder {
		text-align: left;
		font-size: 14px;
	}
}

.avatar_box {
	width: 100%;
	height: 182px;
	@media screen and (min-device-height: 668px) {
		height: 220px;
	}
	margin-bottom: 20px;
	padding-top: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}
.contact_container {
	${hideScrollbar}
	overflow-y: scroll;
	padding: 8px 15px 0 15px;
	margin: 15px auto;
	background-color: #FFFFFF;
	height: 125px;
	width: 337px;
	border-radius: 13px;
	box-shadow: 0px 1px 3px lightgrey;
	@media screen and (device-aspect-ratio: 40/71) {
    /* iphone 5 only */
    width: 300px;
  }  
	@media screen and (min-device-height: 668px) {
		height: 133px;
	}
	@media screen and (max-device-height: 812px) {
		margin: -11px auto 0 auto;
	}
	.hideControls {
		display: none;
	}
	@media (min-width: 581px) {
		height: 322px;
		width: 340px;
	}
}
.cc_edit {
	height: 160px;
	@media screen and (min-device-height: 668px) {
		height: 200px;
	}
	@media(min-width: 581px) {
		height: 366px;
		width: 340px;
	}
}
`;

const AvatarContainer = styled.div`
	height: 115px;
	width: 115px;
	position: relative;
	@media screen and (min-device-height: 668px) {
		height: 141px;
		width: 141px;
	}
`;

const ProfileHeading = styled.h2`
	color: white;
	margin: 0;
`;

const ContactHeading = styled.h4`
	color: #5A9DEB;
	font-weight: 300;
	margin: 0 0 3px 0;
	@media screen and (min-device-height: 668px) {
		margin-bottom: 7px;
	}
`;

const FormControls = styled.div`
	padding: 0 18px;
	height: 60px;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	.hideControls {
		display: none;
	}
`;

const ContactBox = styled.div`
	position: relative;
	margin-bottom: 10px;
	.profile-input {
		padding: 0 0 5px 0;
	}
	input {
		border-bottom: 1px solid #D0D0D0;
		@media screen and (min-device-height: 668px) {
			height: 25px
		}
	}
	.alert {
		position: absolute;
		width: 15px;
		top: 0px;
		left: 60px;
	}
	.warning-text {
		margin: 0;
		font-size: 12px;
		color: #FD7272;
		width: 234px;
		position: absolute;
		top: -5px;
		left: 80px;
	}
`;

const ProfileData = styled.div`
	border-bottom: 1px solid #D0D0D0;
	padding-bottom: 5px;
`;

class CustomerProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			edit: false,
			formatted_phone: this.props.user_info.formatted_phone,
			email: this.props.user_info.email,
		}

		this.myRefs = {
			formatted_phone: React.createRef(),
			email: React.createRef(),
			address: React.createRef()
		}
	}

	cancelEdit = () => {
		this.setState({
			formatted_phone: this.props.user_info.formatted_phone,
			email: this.props.user_info.email,
			edit: false,
		}, this.scrollToTop)
	}

	openEdit = () => {
			this.setState({
				edit: true
			})
	};

	scrollToTop() {
		if(!this.state.edit) {
				window.scrollTo(0,0);
		}
	}
	handleInput = (evt) => {
		this.setState({
				[evt.target.name]: evt.target.value
		})
	}

	updateProfile = async () => {
		if (globalUtils.checkValues(this.myRefs) === false) {
			return;
		}
		let newData = Object.assign({}, this.state);
		delete newData.edit;
		let updatedCustomer = await userAPI.updateUser(this.props.user_info.id, newData, this.props.auth_token, this.props.user_pin);
    if (updatedCustomer.err && updatedCustomer.err.response) {
      this.setNotification({message: updatedCustomer.err.response.data.detail, type: 'error'})
      window.scroll(0,0);
      return
    }
		this.props.setUserInfo(updatedCustomer)
		this.props.setNotification({message: 'Changes saved.'})
    this.setState({
			edit: false
		})
	}

	getName = () => {
	  if (this.props.user_info.is_business) {
			return this.props.user_info.business_name;
		}
		return this.props.user_info.first_name + ' ' + this.props.user_info.last_name;
	}

	render() {
		return(
			<MobileBackground fixed>
				<PageWrapper>
					<ProfileWrapper>
						<div className="avatar_box">
							<AvatarContainer>
									<CustomerInitials business={this.props.user_info.business_name}/>
							</AvatarContainer>
							<ProfileHeading>{this.getName()}</ProfileHeading>
						</div>
						<div className={this.state.edit ? 'contact_container cc_edit' : 'contact_container'}>
							<ContactBox>
								<ContactHeading>Phone</ContactHeading>
								<HideShow show={this.state.edit}>
									<img className="alert" src={alert_icon} alt="warning"/>
									<p className="warning-text">Changing your number will require you to opt in for texting again.</p>
								</HideShow>
								<ProfileData>{this.state.formatted_phone}</ProfileData>
							</ContactBox>
							<ContactBox>
								<ContactHeading>Email</ContactHeading>
								<ProfileData>{this.state.email}</ProfileData>
							</ContactBox>
							<FormControls className={this.state.edit ? '' : 'hideControls'}>
								<ActionButton primary saveCancel data-test-id="customer-profile-cancel" onClick={ this.cancelEdit }>Cancel</ActionButton>
								<ActionButton saveCancel data-test-id="customer-profile-save" onClick={this.updateProfile}>Save</ActionButton>
							</FormControls>
						</div>
					</ProfileWrapper>
				</PageWrapper>
			</MobileBackground>
		)
	}
}

const mapStateToProps = state => {
	return {
		auth_token: state.auth.auth_token,
		user_id: state.auth.user_id,
		user_pin: state.auth.user_pin,
		user_info: state.auth.user_info
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		setUserInfo: (userInfo) => {dispatch(setUserInfo(userInfo))},
		setNotification: (notification) => {dispatch(setNotification(notification))}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProfile);
