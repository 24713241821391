import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  right: 5px;
  bottom: 1px;
  .triangle {
    position: relative;
    width: 13px;
    height: 9px;
    background-color: #D8D8D8;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
  }
  .flipped {
    transform: rotate(180deg);
    top: 3px;
  }
`;

function DropdownIndicator(props) {
  return(
    <Wrapper>
      <div className="triangle"></div>
      <div className="triangle flipped"></div>
    </Wrapper>
  )
}

export default DropdownIndicator;