import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';

//Assets
import { ErrorContainer } from '../../../components/mobile/MobileStyles';

//Components
import Breadcrumb from '../../../components/mobile/display/MobileBreadcrumb';
import ErrorHandle from '../../../components/function/ErrorHandle';
import MerchantCard from '../../../components/mobile/MerchantCard';

//API & Utils
import userAPI from '../../../api/customer/userAPI';
import history from '../../../history'

const Container = styled.div`
  background-color: #F1F4F7;
  .content-wrapper {
    padding: 23px;
    height: 100%;
    .sub-text {
      font-size: 14px;
      color: gray;
      margin-top: 34px;
      margin: 0 auto;
      width: 90%;
      margin-bottom: 30px;
    }
    .inner-content {
      overflow: auto;
    }
  }
`

const BreadcrumbWrapper = styled.div`
  position: fixed;
  top: 70px;
`;

class NotificationPreferences extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.getMerchants();
  }

  showMerchantInfo = (id) => {
    if (id) {
      this.props.history.push('/customer/merchant/'+ id)
    }
  }

  getMerchants = async () => {
    let merchants = await userAPI.getList(this.props.auth_token, this.props.user_pin);
    if (!merchants.err) {
      this.setState({ merchants });
    }
  }

  renderMerchants = () => {
    let merchantArray = [];
    if (this.state.merchants && this.state.merchants.length > 0) {
      for (let i in this.state.merchants) {
        merchantArray.push(<MerchantCard key={this.state.merchants[i].id} merchant={this.state.merchants[i]} data-test-id={"notification-preference-show-" + i} onClick={(id) => this.showMerchantInfo(this.state.merchants[i].id)}/>)
      }
      return merchantArray;
    } else {
      return 'There are no merchants'
    }
  }

  render() {
    return (
      <Container>
        <BreadcrumbWrapper>
          <Breadcrumb page="Opt-In Preferences" close={history.goBack} path="/customer/dashboard"/>
        </BreadcrumbWrapper>
        <ErrorContainer>
          <ErrorHandle data={this.state.errors}/>
        </ErrorContainer>
        <div className="content-wrapper">
          <div className="sub-text">You can change your opt-in preferences for each of your accounts.</div>
          <div className="inner-content">
            {this.renderMerchants()}
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    merchant_logo: state.merchants.merchant_logo,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id,
    user_info: state.auth.user_info
  };
};

export default connect(
  mapStateToProps,
)(NotificationPreferences)
