import React, {Component} from 'react';
import { connect } from 'react-redux';

//Components
import AdminPageWrapper from '../../../components/wrappers/AdminPageWrapper';
import Table from '../../../components/tables/Table';

//API & Utils
import merchantAPI from '../../../api/merchant/merchantAPI';

class ReportsIndexListWrapper extends Component {
  constructor(props){
    super(props);
    this.state = ({
      data: []
    })
    this.getData()
  }

  getData = async () => {
    let data = await merchantAPI.getReports(this.props.auth_token)
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }
    data = data["results"]
    this.setState({
      data: data
    })
  }

  render() {
    return (
      <AdminPageWrapper>
        <Table title="Reports"/>
      </AdminPageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

export default connect(
  mapStateToProps
)(ReportsIndexListWrapper)
