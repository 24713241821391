export const clearFix = () => `
&::after {
  content: "";
  clear: both;
  display: table;
}
`;

export const hideScrollbar = () => `
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const hoverZoom = () => `
  &:hover {
    transform: scale(1.08);
  }
`;

export const thinScrollbar = () => `
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #E2E2E2;
  }
`;

export const blurOnLoad = () =>`
  .blurred {
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
    -webkit-filter: url(#blur-filter);
    filter: url(#blur-filter);
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }
`;