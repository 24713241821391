import React, {Component} from 'react';
import styled from 'styled-components';
import '../../index.css';
import classNames from 'classnames';
import validationRules from '../../utils/ValidationRules';

const Wrapper = styled.div`
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #A1A1A1;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A1A1A1;
    text-indent: 5px;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #A1A1A1;
    text-indent: 5px;
  }
  input {
    &:focus {
      border: 2px solid #64ABF5;
      outline: none;
    }
  }
  .no-border {
    border: none;
    border-bottom: 1px solid #A4A4A4;
  }
  .has-error {
    color: #FD7272;
    text-align: left;
    margin-top: 7px;
    margin-left: 3px;
    font-size: 15px;
  }
  .red-border {
    border: 1px solid #FD7272;
  }
  .custom {
    height: 63px;
    width: 435px;
    border-radius: 4px;
    text-indent: 80px;
    font-size: 20px;
    caret-color: #64ABF5;
    border: 1px solid rgba(0,0,0,0.1);
    @media (max-width: 580px) {
      text-indent: 20px;
      width: 310px;
    }
  }
`

export default class ControlledInput extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  handleEnter = (event) => {
    if (event.key === "Enter" && this.props.submit) {
      this.props.submit();
    } else {
      return;
    }
  }

  checkForErrors = (e) => {
    if(this.props.handle_blur) {
      this.props.handle_blur()
    }

    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    }
    if (this.props.required && e.target.value) {
      this.setState({error: ''})
    }
    if (this.props.required && this.props.type === 'email') {
      if (!validationRules.email(e.target.value)) {
        this.setState({error: 'Not a valid email'});
      }
    }
    if (this.props.required && this.props.type === 'zip') {
      if (!validationRules.zip(e.target.value)) {
        this.setState({error: 'Not a valid zipcode'});
      }
    }
  }

  checkProps = () => {
    if (this.props.noBorder) {
      return (
        <Wrapper>
          <input
            onChange={this.props.onChange}
            name={this.props.name}
            className={classNames('no-border ' + this.props.className, {'red-border' : this.state.error})}
            onKeyUp={this.handleEnter}
            maxLength={this.props.maxLength}
            autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'}
            type={this.props.type ? this.props.type : 'text'}
            onBlur={(e) => this.checkForErrors(e)}
            required={this.props.required}
            error={this.state.error}
            value={this.props.value}
            placeholder={this.props.placeholder || undefined}
            data-test-id={this.props.name}
            />
          <div className="has-error">{this.state.error}</div>
        </Wrapper>
      )
    } else if (this.props.custom) {
      return (
        <Wrapper>
            <input
              onChange={this.props.onChange}
              name={this.props.name}
              className={classNames('custom ' + this.props.className, {'red-border' : this.state.error})}
              onKeyUp={this.handleEnter}
              maxLength={this.props.maxLength}
              autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'}
              type={this.props.type ? this.props.type : 'text'}
              onBlur={(e) => this.checkForErrors(e)}
              required={this.props.required}
              error={this.state.error}
              value={this.props.value}
              placeholder={this.props.placeholder || undefined}
              data-test-id={this.props.name}
              />
            <div className="has-error">{this.state.error}</div>
          </Wrapper>
        )
      } else {
        return (
          <Wrapper>
          <input
            onChange={this.props.onChange}
            name={this.props.name}
            className={classNames(this.props.className, {'red-border' : this.state.error})}
            onKeyUp={this.handleEnter}
            maxLength={this.props.maxLength}
            autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'}
            type={this.props.type ? this.props.type : 'text'}
            onBlur={(e) => this.checkForErrors(e)}
            required={this.props.required}
            error={this.state.error}
            value={this.props.value}
            placeholder={this.props.placeholder || undefined}
            data-test-id={this.props.name}
            />
          <div className="has-error">{this.state.error}</div>
        </Wrapper>
      )
    }
  }

  render() {
    return (
      this.checkProps()
    )
  }
}
