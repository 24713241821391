import React, {Component} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

//Components
import ErrorHandle from '../function/ErrorHandle';
import Button from '../buttons/NewButton';

//Assets
// import close_icon from '../../assets/icons/close_icon_white.png';
import ModalErrorWrapper from './styling/ModalErrorWrapper';
import {fadeIn, fadeOut} from '../../styles/animations';
import { color_modal_heading, color_white } from '../../styles/colors';
import { hideScrollbar } from '../../styles/mixins';

const ModalWrapper = styled.div`
  .container {
    height: 100%;
    width: 100%;
    background-color: rgba(59,59,59,.6);
    position: fixed;
    z-index: 150;
    left: 233px;
    top: 0px;

    .css-10nd86i {
      width: 290px;
    }
    
    @media (max-height: 700px){
      font-size: 14px;
    }
  }

  .open {
    animation:  ${fadeIn} .5s;
    animation-fill-mode: forwards;
  }

  .close {
    animation:  ${fadeOut} .5s;
    animation-fill-mode: forwards;
  }

  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  .modal {
    padding: 10px;
    height: auto;
    min-height: 330px;
    max-height: 500px;
    overflow-y: scroll;
    width: 511px;
    background-color: ${color_white};
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
    border-radius: 10px;
    margin-left: -125px;
    ${hideScrollbar}
    @media (max-height: 700px){
      top: 28%;
    }

    .errors {
      padding: 40px 0 0 4px;
    }

    .tcpa {
      text-align: center;
      padding: 20px;
    }

    .button-wrapper {
      display: flex;
      justify-content: space-evenly;
      margin-top: 40px;
    }

    &__title {
      position: relative;
      text-align: center;
      color: ${color_modal_heading};
      font-size: 25px;
      padding: 10px 0px;
      height: 60px;
    }

    &__content {
      max-height: 590px;
      @media (max-height: 700px){
        max-height: 450px;
      }
      overflow: auto;
      overflow-X: hidden;
        ::-webkit-scrollbar {
        width: 0;
      }
      height: auto;
      width: 100%;
      border-radius: 10px;
    }

    &__input-container {
      display: flex;
      justify-content: center;
      padding: 20px 0 0 4px;
      margin: 0 auto;
    }

    &__title {
      text-align: center;
      color: ${color_modal_heading};
      font-size: 25px;
      padding: 18px 0 0 0;
      height: 60px;
    }

    &__subtitle {
      margin: 0 auto;
      height: 42px;
      width: 378px;
      color: #9E9E9E;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
    }

    &__content {
      max-height: 590px;
      @media (max-height: 700px){
        max-height: 450px;
      }
      overflow: auto;
      overflow-X: hidden;
        ::-webkit-scrollbar {
        width: 0;
      }
      height: auto;
      width: 100%;
      border-radius: 10px;
    }

    &__buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      
      & > * {
        margin: 10px;
      }
    }
  }
`

export default class NewModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      showError: false,
      modalContent: null,
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({mounted: true, modalContent: document.getElementsByClassName('modal__content')[0]});
  }

  componentWillUnmount() {
    this.setState({mounted: false})
  }

  handleClose = (e) => {
    this.setState({mounted: false})
    setTimeout( () => {
      if(this.props.close) {
        this.props.close()
      } else {
        this.props.toggle_modal(e)
      }
    }, 500)
  }

  handleSubmit = () => {
    this.props.submit()
    this.setState({mounted: false})
    setTimeout( () => {
      this.props.toggle_modal()
    }, 500)
  }

  renderSubtitle = () => {
    if(this.props.subtitle) {
      return <div className="modal__subtitle">{this.props.subtitle}</div>
    }
  }

  renderButtons = () => {
    if(this.props.toggle_modal && this.props.submit) {
      return(
        <div className="modal__buttons">
          <Button type="modal-primary" onClick={this.handleClose}>{this.props.cancel || 'Cancel'}</Button>
          <Button type="modal-secondary" onClick={this.handleSubmit}>{this.props.confirm || 'Save'}</Button>
        </div>
      )
    }
  }

  renderX = () => {
    if(this.props.close) {
      return(
        <span className="close-btn" onClick={this.handleClose}>X</span>
      )
    }
  }

  render() {
    return (
      <ModalWrapper>
        <div className={classNames("container", {"open": this.state.mounted}, {"close": !this.state.mounted})}>
          <div className="modal">
            <div className="modal__title">{this.renderX()}{this.props.title}</div>
            {this.renderSubtitle()}
            <ModalErrorWrapper>
              <ErrorHandle data={this.props.errors}/>
            </ModalErrorWrapper>
            <div className="modal__content">
              <div className={classNames({"errors": this.props.errors}, {"tcpa": this.props.tcpa})}>
                {this.props.children || this.props.text}
              </div>
              { this.renderButtons() }
            </div>
          </div>
        </div>
      </ModalWrapper>
    )
  }
}
