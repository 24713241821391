import React, {Component} from 'react';
import styled from 'styled-components';
import Input from './Input';

const Text = styled.div`
  color: #656565;
  font-weight: bold;
  font-size: 15px;
  margin-left: 13px;
  margin-bottom: 3px;
  margin-top: 0px;
`

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  .checkbox {
    transform: scale(1.60);
  }
  @media (max-height: 700px){
    font-size: 14px;
  }
`

export default class CheckboxWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }

  checkifText = () => {
    if (this.props.text) {
      return (<Text className="text">{this.props.text}</Text>);
    } else {
      return;
    }
  }

  render() {
    return (
      <CheckboxContainer>
        <Input
          required={this.props.required}
          className="checkbox"
          type="checkbox"
          onChange={this.props.onChange}
          data-test-id={this.props.name}
          checked={this.props.checked}
          name={this.props.name}
          {...this.props}/>
        {this.checkifText()}
      </CheckboxContainer>
    )
  }
}
