// import React from 'react';
import styled from 'styled-components';


export const ModalContent = styled.div`
display: flex;
justify-content: flex-start;
flex-direction: column;
&.user-search {
  height: 240px;
}
.no-border {
  border: none;
  border-bottom: 1px solid #A4A4A4;
}
.full-width-input {
  width: 290px;
}
.css-1aya2g8 {
  background-color: #fff;
  border-color: #9E9E9E;
  border: none;
  border-bottom: 1px solid #A4A4A4;
}
.custom-line {
  color: #82B4EB;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  margin-left: 30px;
  &.stored {
    margin-top: -10px;
    width: 40%;
  }
}
.centered-header {
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
}
`;

export const ModalContainer = styled.div`
right: 423px;
top: 135px;
position: fixed;
z-index: 100;
`;

export const ErrorContainer = styled.div`
margin-bottom: 10px;
margin-top: -10px;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  & div {
    margin-top: 40px;
  }
`;
