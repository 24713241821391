import React from 'react';
import Loadable from 'react-loadable';

//Components
import Modal from '../mobile/MobileModal';
import HandleOutsideClick from '../function/HandleOutsideClick';

//Assets
import { ModalContent } from '../modals/generalModalStyling';

const PrivacyText = Loadable({
  loader: () => import('../../components/stateless/PrivacyPolicyTextMobile'),
  loading() {
    return <div></div>
  }
});

export default function PrivacyPolicy(props) {
	return(
		<HandleOutsideClick show={props.closeModal} context="privacy">
			<Modal context="privacy" title="Privacy Policy" onClose={props.closeModal} document>
				<ModalContent>
					<PrivacyText/>
				</ModalContent>
			</Modal>
		</HandleOutsideClick>
	)
}
