import apiUtils from '../apiUtils';
let triggerUrl = apiUtils.baseUrl + 'triggers';
class notificationAPI {
  getTriggers = (auth_token) => {
    return apiUtils.get(triggerUrl, auth_token)
  }

  createTrigger = (data, auth_token) => {
    return apiUtils.post(triggerUrl, data, auth_token)
  }

  toggleTrigger = (id, data, auth_token) => {
    let toggleUrl = `${triggerUrl}/${id}/toggle`;
    return apiUtils.post(toggleUrl, data, auth_token)
  }

  editTrigger = (data, id, auth_token) => {
    let editUrl = `${triggerUrl}/${id}`;
    return apiUtils.patch(editUrl, data, auth_token);
  } 

  deleteTrigger = (id, auth_token) => {
    let deleteUrl = `${triggerUrl}/${id}`;
    return apiUtils.delete(deleteUrl, auth_token)
  }
}

export default new notificationAPI();
