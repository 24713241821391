import React, {Component} from 'react';
import styled from 'styled-components';
import close_icon from '../../assets/icons/close_icon_dark_blue.png';
import bills_icon from '../../assets/icons/bills-icon.png';
import receipts_icon from '../../assets/icons/receipt-icon.png';

const Container = styled.div`
  box-sizing: border-box;
  padding: 10px;
  height: 80%;
  width: 90%;
  margin: 0 auto;
  position: absolute;
  background-color: #fff;
  z-index: 100;
  bottom: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-bottom-left-radius: ${props => props.document ? '15px' : null};
  border-bottom-right-radius: ${props => props.document ? '15px' : null};
  border-top-left-radius: ${props => props.document ? '15px' : null};
  border-top-right-radius: ${props => props.document ? '15px' : null};
  box-shadow: ${props => props.document ? '0px 2px 5px black' : ''};
  .top-container {
    margin-bottom: 20px;
    padding: 11px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    .title-icon {
      height: 40px;
      width: auto;
    }
    .title {
      color: #5A9DEB;;
      font-size: 24px;
      font-weight: bold;
      margin-left: 7px;
    }
    .icon-title {
      display: flex;
      align-items: center;
    }
    img.close-icon {
      height: ${props => props.document ? '16px' : '25px'};
      width: auto;
      cursor: pointer;
    }
  }
  .content {
    padding-top: 25px;
    height: 100%;
    overflow-y: scroll;
  }
`

export default class MobileModal extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  renderTitleIcon = () => {
    if (this.props.title && this.props.title === 'Bills') {
      return (
        <img className="title-icon" src={bills_icon} alt="bill"/>
      )
    } else if (this.props.title === 'Receipts') {
      return (
        <img className="title-icon" src={receipts_icon} alt="bill"/>
      )
    }
  }

  renderCloseIcon = () => {
    if (this.props.hideCloseIcon) {
      return;
    } else {
      return (
        <img className="close-icon" src={close_icon} onClick={this.props.onClose} alt="close"/>
      )
    }
  }


  render() {
    return (
        <Container {...this.props}>
          <div className="top-container">
            <span className="icon-title">
              {this.renderTitleIcon()}
              <div className="title">{this.props.title}</div>
            </span>
            {this.renderCloseIcon()}
          </div>
          <div className="content">{this.props.children}</div>
        </Container>
    )
  }
}
