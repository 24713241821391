import React, {Component} from 'react';
import styled from 'styled-components';
import Input from './Input';
import MessageLine from '../../views/admin/messaging/MessageLine';

const Container = styled.div`
  margin-top: 60px;
`

export default class SearchInput extends Component {
  constructor(props){
    super(props);
    this.state = {
      search: ''
    };
  }

  updateSearch = (event) => {
    this.setState({search: event.target.value.substr(0, 20)})
  }


  render() {
    let filteredMessages = []
    if (this.props.messages && this.props.messages.length > 0) {
      filteredMessages = this.props.messages.filter(
        (message) => {
          return message.user_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 || message.body.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        }
      )
    }

    return (
        <>
          <Input value={this.state.search} data-test-id="search" onChange={this.updateSearch} placeholder="Search" className={this.props.className}/>
          <Container className="filtered-messages">
            {filteredMessages.map((message, index) => {
              return (
                <MessageLine
                  key={`message-line-${message.id}`}
                  message={message}
                  data-test-id={`message-line-${message.id}`}
                  clearMessages={this.props.clearMessages}
                  getMessageDetail={this.props.getMessageDetail}
                  checked={this.props.checked}
                  onChange={(id, value) => this.props.onChange(message.sub_id, value)}
                  activeSub={this.props.activeSub}/>
              )
            })}
          </Container>
        </>
    )
  }
}
