import React, {Component} from 'react';
import styled from 'styled-components';

const Link = styled.a`
  color: #64ABF5 !important;
  &:visited {
    color: #64ABF5 !important;
  }
`

export default class Download extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <Link href={this.props.file} download>{this.props.children}</Link>
    )
  }
}
