import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

//Components
import WhiteContainer from '../../../components/wrappers/WhiteContainer';
import Breadcrumb from '../../../components/display/BreadcrumbTitle';

//API & Utils
import PaymentPlanAPI from '../../../api/payment_plan/PaymentPlanAPI';
import globalUtils from '../../../utils/globalUtils';

const ContentContainer = styled.div`
  padding: 20px 10px;
  .blue-box {
    height: auto;
    padding-top: 10px;
    width: 93%;
    margin: 0 auto;
    .top-half {
      padding: 15px 40px;
      height: 162px;
      width: 100%;
      background-color: #64ABF5;
      border-radius: 25px 25px 0 0;
      display: flex;
      justify-content: space-between;
      color: #fff;
      .customer-info {
        .small {
          font-size: 18px;
          &.plan-num {
            margin-bottom: 20px;
          }
        }
        a {
          color: #fff;
          cursor: pointer;
          &:hover {
            color: #9beed9;
          }
        }
      }
      .payment-plan-detail {
        .large {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
    .bottom-half {
      height: auto;
      border: 1px solid #C2C2C2;
      width: 100%;
      box-shadow: 2px 3px 6px 0 rgba(143,143,143,0.5);
      display: flex;
      justify-content: space-between;
      padding: 0px 40px;
      .content {
        width: 100%;
        .title-section {
          margin-top: 40px;
          margin-left: 10px;
          display: flex;
          justify-content: space-between;
          border-bottom: 3px solid #858585;
          margin-bottom: 10px;
          .plan-name {
            color: #787878;
            font-size: 26px;
            padding-bottom: 10px;
          }
          .total {
            font-size: 26px;
            color: #94D600;
            padding-right: 5px;
          }
        }
        .details {
          font-size: 18px;
          color: #9B9B9B;
          padding: 10px 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
`

const TableContainer = styled.div`
  margin-bottom: 20px;
  border-collapse: collapse;
  #table-like tr {
    margin-bottom: 20px;
  }
  #table-like tr td {
    width: 33%;
    cursor: pointer;
    // border-bottom: 1px solid #D5D5D5;
    padding-top: 10px;
    padding-bottom: 3px;
  }
`

const Count = styled.div`
  float: right;
  border-top: 1px solid gainsboro;
  width: 200px;
  font-size: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
  text-align: right;
`

class SubscriptionDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      payment_plan: {
        invoices: [],
        subscription: {},
      }
    };
    this.payment_plan_id = this.props.match.params.id;
  }

  componentDidMount = () => {
    this.getPaymentPlanDetail();
  }

  getPaymentPlanDetail = async () => {
    let payment_plan = await PaymentPlanAPI.getDetail(this.payment_plan_id, this.props.auth_token);
    if (payment_plan.err) {
      this.setState({errors: payment_plan.err.response.data.detail})
      return
    }
    this.setState({payment_plan});
    // this.getPaymentsTowardsPlan();
  }

  // getPaymentsTowardsPlan = () => {
  //   if (this.state.payment_plan && this.state.payment_plan.invoices) {
  //     let payments = this.state.payment_plan.invoices
  //     for (let i in payments) {

  //     }
  //   }
  // }

  returnInvoices = () => {
    let invoiceArray = [];
    if (this.state.payment_plan && this.state.payment_plan.invoices) {
      let invoices = this.state.payment_plan.invoices;
      for (let i in invoices) {
        invoiceArray.push([<tr><td data-test-id={"pmt-plan-detail-invoice-" + i} onClick={(id) => this.routeToInvoice(invoices[i].id)}>{invoices[i].description}</td><td>{globalUtils.formatDate(invoices[i].due_date)}</td><td>{globalUtils.formatDate(invoices[i].paid_date)}</td><td>{globalUtils.convertToDollars(invoices[i].amount)}</td></tr>])
      }
    }
    return invoiceArray;
  }

  routeToInvoice = (id) => {
    this.props.history.push('/admin/invoices/detail/'+id);
  }

  invoiceCount = () => {
    return this.state.payment_plan.invoices.length
  }

  returnId = () => {
    if (this.payment_plan_id) {
      return this.payment_plan_id.substring(0, 8);
    }
  }


  render() {
    return (
      <>
      <Breadcrumb page="Payment Plan Details" path="/admin/invoices"/>
        <WhiteContainer>
          <ContentContainer>
            <div className="blue-box">
              <div className="top-half">
                <div className="customer-info">
                  <div className="small plan-num">PAYMENT PLAN # {this.returnId()}</div>
                  <div className="small">
                    <Link to={'/admin/customers/profile/'+ this.state.payment_plan.subscription.id}>{this.state.payment_plan.subscription.full_name}</Link>
                  </div>
                  <div className="small">{this.state.payment_plan.subscription.formatted_phone}</div>
                  <div className="small">{}</div>
                  <div className="small">
                  {this.state.payment_plan.subscription.service_address} {this.state.payment_plan.subscription.service_address2}
                   {this.state.payment_plan.subscription.service_address_city}, {this.state.payment_plan.subscription.service_address_state} {this.state.payment_plan.subscription.service_address_zip}
                  </div>
                </div>
                <div className="payment-plan-detail">
                  <div className="large">BALANCE: {globalUtils.convertToDollars(this.state.payment_plan.remaining_balance)}</div>
                </div>
              </div>
              <div className="bottom-half">
                <div className="content">
                  <div className="title-section">
                    <div className="plan-name">{this.state.payment_plan.description}</div>
                    <div className="total">Total:
                      <span className="green"> {globalUtils.convertToDollars(this.state.payment_plan.original_balance)}</span>
                    </div>
                  </div>
                  {/*<div className="details">Details: Payment to be made on the 4th of every month for 12 months</div>*/}
                  <TableContainer>
                    <table id="table-like">
                      <tbody>
                        <tr>
                            <td>Item</td>
                            <td>Date Due</td>
                            <td>Date Received</td>
                            <td>Amount</td>
                        </tr>
                        {this.returnInvoices()}
                      </tbody>
                    </table>
                  </TableContainer>
                  <Count>Invoice Count: {this.invoiceCount()}</Count>

                </div>
              </div>
            </div>
          </ContentContainer>
        </WhiteContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_role: state.auth.user_role
  };
};

export default connect(
  mapStateToProps,
)(SubscriptionDetail)
