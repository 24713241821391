import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'onboarding?id=';

class onboardingAPI {
  getNoAuth = (id, callback) => {
    // let user_pin=""
    return apiUtils.get_no_auth(url + id, callback);
  };
}

export default new onboardingAPI();
