import React, {Component} from 'react';
// import styled from 'styled-components';
import messagesAPI from '../../../api/messages/messagesAPI';
import { connect } from 'react-redux';
import globalUtils from '../../../utils/globalUtils';
import Inbox from './Inbox';
// import AdminPageWrapper from '../../../components/AdminPageWrapper';
// import Table from '../../../components/Table';
// import { RowDefinition, ColumnDefinition } from 'griddle-react';
// import { Link } from 'react-router-dom';

// const Container = styled.div`
//   min-width: 600px;
//   .CustomBodyComponent {
//     width: 100%;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// `

// const rowDataSelector = (state, { griddleKey }) => {
//   return state
//     .get('data')
//     .find(rowMap => rowMap.get('griddleKey') === griddleKey)
//     .toJSON();
// };

// const enhancedWithRowData = connect((state, props) => {
//   return {
//     rowData: rowDataSelector(state, props)
//   };
// });

// function CustomNameComponent({rowData}) {
//   if (rowData.sub_id !== undefined){
//     return (
//       <div className="CustomNameComponent">
//         <Link to={"/admin/customers/profile/" + rowData.sub_id}>{rowData.user_name}</Link>
//       </div>
//     );
//   }
// }

// function CustomSentComponent({rowData}) {
//     return (
//       <div className="CustomSentComponent">
//         {globalUtils.formatDateAndTime(rowData.created_at)}
//       </div>
//     );
// }

// function CustomBodyComponent({rowData}) {
//   return (
//     <div className="CustomBodyComponent">
//       <Link to={"/admin/conversations/detail/" + rowData.id}>{rowData.truncated_body}</Link>
//     </div>
//   );
// }

class MessagingIndexListWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
    data: []
    };
    globalUtils.loginRedirect(props);
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = async () => {
    let formatted_data = [];
    let data = await messagesAPI.getList(this.props.auth_token)
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }
    if (data && data.length > 0) {
      data.reverse()
      formatted_data = data.map(function(row){
        //row["created_at"] = globalUtils.formatDateAndTime(row["created_at"])
        row["medium"] = ((row["medium"] === 1) ? "SMS" : "Email")
        return row
      })
    }

    this.setState({
      data: formatted_data, loaded: true
    })
  }


  render() {
    return (
      /*<AdminPageWrapper>
        <Container>
          <Table title="Messages" data={this.state.data} tableClassName="table" showFilter={true}
  showSettings={false} filterPlaceholderText="Search" loaded={this.state.loaded}>
            <RowDefinition>
              <ColumnDefinition id="user_name" title="User" customComponent={enhancedWithRowData(CustomNameComponent)}/>
              <ColumnDefinition id="formatted_to_number" title="To" />
              <ColumnDefinition id="status" title="Status" />
              <ColumnDefinition id="created_at" title="Sent" customComponent={enhancedWithRowData(CustomSentComponent)}/>
              <ColumnDefinition id="medium" title="Medium" />
              <ColumnDefinition id="truncated_body" title="Body" customComponent={enhancedWithRowData(CustomBodyComponent)}/>
            </RowDefinition>
          </Table>
        </Container>
      </AdminPageWrapper>*/
      <Inbox/>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

export default connect(
  mapStateToProps
)(MessagingIndexListWrapper)
