import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'sms';
class messagesAPI {
  getList = (auth_token, user_pin, query_string) => {
    var final_url = url
    if (typeof query_string !== "undefined" && query_string !== ""){
      final_url += query_string
    }
    return apiUtils.get(final_url, auth_token, user_pin);
  };
  getDetail = (id , auth_token, callback) => {
    return apiUtils.get(url + '/' + id, auth_token, callback);
  };
  getChat = (auth_token, query_string) => {
    var final_url = url + '/chat'
    if (typeof query_string !== "undefined" && query_string !== ""){
      final_url += query_string
    }
    return apiUtils.get(final_url, auth_token)
  }
  getChatData = async (auth_token, query_string) => {
    // Async always returns a promise
    let data = await this.getChat(auth_token, query_string);
    
    if (data.err && data.err.response) {
      return {
        errorMessage: data.err.response.data.message,
        subscriptionId: data.err.response.data.subscription_id,
      }
    }
    
    return { 
      messages: data.messages, 
      loaded: true, 
      subscriptionId: data.subscription_id
    }
  }
  sendMessage = (data, auth_token) => {
    return apiUtils.post(url + '/send', data, auth_token);
  }
  closeChat = (data, auth_token) => {
    return apiUtils.post(url + '/close', data, auth_token)
  }
  getTemplates = (auth_token) => {
    var final_url = apiUtils.baseUrl + 'sms_templates'
    return apiUtils.get(final_url, auth_token)
  }
  markRead = (data, token) => {
    let unread = url + '/mark_read';
    return apiUtils.post(unread, data, token)
  }
}

export default new messagesAPI();
