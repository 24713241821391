import React, {Component} from 'react';
import styled from 'styled-components';
import blytz_cash_receipt from '../../../assets/images/blytzcash_receipt.png';

const Container = styled.div`
  position: fixed;
  background-color: #515a71;
  height: 100%;
  width: 100%;
  text-align: center;
`

const Icon = styled.img`
  height: 400px;
  width: auto;
  margin: 0 auto;
  margin-top: 40px;
`

export default class CashReceipt extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <Container>
        <Icon src={blytz_cash_receipt}/>
      </Container>
    )
  }
}
