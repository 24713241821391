import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//Components
import ChartCard from '../../../components/cards/ChartCard';
import Loading from '../../../components/display/Loading';
import RangeDropdown from '../../../components/dropdown/RangeDropdown';
import HideShow from '../../../components/function/HideShow';
import CardTypePieChart from '../../../js/charts/CardTypePie';
import PaymentTypePieChart from '../../../js/charts/PaymentTypePie';
import MessagesSentTrendChart from '../../../js/charts/MessagesSentTrendChart';
import MessagesReceivedTrendChart from '../../../js/charts/MessagesReceivedTrendChart';
import AmountInvoicedChart from '../../../js/charts/AmountInvoicedChart';
import AmountCollectedChart from '../../../js/charts/AmountCollectedChart';

//API & Utils
import merchantAPI from '../../../api/merchant/merchantAPI';
import tagsAPI from '../../../api/tags/tagsAPI';
import globalUtils from '../../../utils/globalUtils';

//Redux
import { storeMerchantId, setUserInfo } from '../../../js/actions/auth';
import { storeMerchantTags } from '../../../js/actions/merchants';

const ContentWrapper = styled.div`
  margin-left: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  grid-row-gap: 20px;
  grid-column-gap: 15px;
  position: relative;
  .line-chart {
    grid-column: 1/2;
  }
  .pie-chart {
    grid-column: 1;
  }
  .pie-chart.two {
    grid-column: auto;
  }
  .recharts-legend-wrapper {
    width: 50% !important;
    .recharts-default-legend {
      text-align: left !important;
      margin-left: 25px !important;
    }
  }
`

const DatepickerWrapper = styled.div`
  position: absolute;
  left: 308px;
  top: 60px;
  display: flex;
  .title {
    font-size: 20px;
    margin-top: 15px;
    color: #5cb6d8;
  }
  .DayPickerInput {
    margin-top: 20px;
    margin-left: 10px;
    input {
      border-radius: 5px;
      border: none;
    }
  }
`

class AdminDashboardWrapper extends Component {
  constructor(props){
    super(props);
    this.state = ({
      data: {},
      loaded: false
    })

    globalUtils.loginRedirect(props);
  }

  componentDidMount = () => {
    this.getSelf();
  }

  getSelf = async () => {
    let merchant = await merchantAPI.getSelf(this.props.auth_token);
    this.setState({ merchant: merchant });
    this.getData(merchant.default_report_range);
    this.props.storeMerchantId(merchant.id);
    let merchant_tags = await tagsAPI.getMerchantTags(this.props.auth_token, merchant.id);
    if (merchant_tags.err && merchant_tags.err.response) {
      this.setState({errors: merchant_tags.err.response.merchant_tags.detail})
      return
    }
    this.props.storeMerchantTags(merchant_tags);
  }

  getData = async (range) => {
    this.setState({ loaded: false });
    let data = await merchantAPI.getReports(this.props.auth_token, range)
    if (data.err && data.err.response) {
      this.setState({errors: data.err.response.data.detail})
      return
    }
    this.setState({data, loaded: true, merchant: {default_report_range: data.default_report_range}});
  }

  render() {
    return (
      <>
        <Loading loaded={this.state.loaded} noBackground="true" text="Generating Reports"/>
        <DatepickerWrapper>
          <div className="title">Date Range:</div>
          <RangeDropdown getData={(date_range) => this.getData(date_range)} default_report_range={this.state.merchant ? this.state.merchant.default_report_range : null}/>
        </DatepickerWrapper>
        <ContentWrapper>
          <HideShow show={this.state.loaded}>
            <ChartCard title="Amount Invoiced" data={this.state.data.invoice_amount} currency>
              <AmountInvoicedChart data={this.state.data.invoices}/>
            </ChartCard>
            <ChartCard title="Amount Collected" data={this.state.data.transaction_amount} currency>
              <AmountCollectedChart data={this.state.data.transactions}/>
            </ChartCard>
            <ChartCard title="Messages Sent" data={this.state.data.sent_messages_count}>
              <MessagesSentTrendChart data={this.state.data.sent_messages}/>
            </ChartCard>
            <ChartCard title="Messages Received" data={this.state.data.received_messages_count}>
              <MessagesReceivedTrendChart data={this.state.data.received_messages}/>
            </ChartCard>
            <ChartCard title="Total Customers" data={this.state.data.total_customers}/>
            <ChartCard title="Active Customers" data={this.state.data.active_customers}/>
            <ChartCard title="Card Type" chartCard className="pie-chart">
              <CardTypePieChart data={this.state.data.card_types} />
            </ChartCard>
            <ChartCard title="Payment Type" chartCard className="pie-chart two">
              <PaymentTypePieChart data={this.state.data.method_types} />
            </ChartCard>
          </HideShow>
        </ContentWrapper>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role,
    merchant: state.auth.merchant_id
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    storeMerchantId: (id) => {dispatch(storeMerchantId(id))},
    storeMerchantTags: tags => {dispatch(storeMerchantTags(tags))},
    setUserInfo: (info) => {dispatch(setUserInfo(info))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardWrapper)
