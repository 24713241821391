import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Components
import Modal from '../PlainModal';
import TagSearch from '../../inputs/TagSearchbar';
import Tag from '../../display/Tag';

// API & Utils
import tagsAPI from '../../../api/tags/tagsAPI';

// Redux 
import { setNotification } from '../../../js/actions/notifications';
import { thinScrollbar } from '../../../styles/mixins';

const TagModalWrapper = styled.div`
  position: relative;
  height: 130px;
  .tag {

    &__container {
      height: 90px;
      width: 75%;
      margin: 0 auto;
      
      ${thinScrollbar}
    }
  }
`;

class TagModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_tags: [],
      added_tags: [],
      removed_tags: []
    }
  }

  componentDidMount() {
    this.setState({current_tags: this.props.user_tags})
  }

  renderTags = () => {
    if(this.state.current_tags && this.state.current_tags.length > 0) {
      return this.state.current_tags.map( (tag, index) => <Tag key={`modal-tag-${tag[0]}`} name={tag[1]} removeTag={() => this.removeTag(tag, index)}/>)
    }
  }

  confirmTags = async () => {
    
    if(this.state.added_tags.length > 0) {
      let tags = [];
      let lookups = [];

      this.state.added_tags.forEach( tag => {
        if(tag.hasOwnProperty('parent')) {
          lookups.push(tag)
        } else {
          tags.push(tag)
        }
      })
      if(tags.length > 0) {
        let field_ids = await tagsAPI.addTags(this.props.auth_token, tags);
        if (field_ids.err) {
          this.props.setNotification({message: field_ids.err.response.data.detail, type: 'error'});
          return
        } else if(field_ids.length && field_ids.length > 0){
          field_ids.forEach( id => {
            lookups.push({parent: '', custom_field_id: id})
          })
        }
      }

      if(lookups.length > 0) {
        let newLookups = await tagsAPI.addLookups(this.props.auth_token, this.props.sub_id, lookups);
        if(newLookups.err) {
          this.props.setNotification({message: 'Could not add', type: 'error'});
          return;
        }
      }
    }
    
    if(this.state.removed_tags.length > 0) {
      // API call to remove any tags in list
      let removeTags = await tagsAPI.deleteTagLookup(this.props.auth_token, this.props.sub_id, {field_ids: this.state.removed_tags});

      if(removeTags.err) {
        this.props.setNotification({message: 'Could not remove', type: 'error'});
        return;
      }
    }
    this.props.refresh();
  }

  addTag = async (id, name) => {
    let added_tags = this.state.added_tags.slice();
    let current_tags = this.state.current_tags.slice();
    if(id) {
      added_tags.push({parent: '', custom_field_id: id})
      current_tags.push([id, name, 'new'])
    } else {
      added_tags.push({fieldname: 'tag', constraint: 'string', value: name})
      current_tags.push([null, name, 'new'])
    }
    
    this.setState({added_tags, current_tags});
  }

  removeTag = (tag, index) => {
    let removed_tags = this.state.removed_tags.slice();
    let current_tags;
    if(tag[2] !== 'new') {
      removed_tags.push({field_id: tag[0]})
    }
    current_tags = this.state.current_tags.filter( (currentTag, tagIndex) => index !== tagIndex )

    this.setState({removed_tags, current_tags})
  }

  render() {
    return(
      <Modal context="tag" title="Manage Tags" subtitle="Search current tags to add to customer, make new tags, or remove them." toggle_modal={this.props.toggle_modal} submit={this.confirmTags}>
        <TagModalWrapper>
          <TagSearch handle_tag={this.addTag} current_tags={this.state.current_tags} name="tag"/>
          <div className="tag__container">
            {this.renderTags()}
          </div>
        </TagModalWrapper>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    merchant_tags: state.merchants.merchant_tags
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: notification => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagModal);