export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const STORE_INBOX_BADGE = "STORE_INBOX_BADGE";

export const setNotification = notification => {
  let newNotification = Object.assign({}, notification);
  if(!notification.type) {
    newNotification.type = 'success';
  }
  if(notification.path) {
    newNotification.path = notification.path;
  }
  return {
    type: SET_NOTIFICATION, payload: newNotification
  }
};

export const hideNotification = (id, mobile) => {
  if(!mobile) {
    return {
      type: HIDE_NOTIFICATION,
      payload: {id: id}
    }
  } else {
    return {
      type: HIDE_NOTIFICATION,
      payload: {id: id, mobile: true}
    }
  }
};

export const clearNotifications = (mobile) => {
  if(!mobile) {
    return {
      type: CLEAR_NOTIFICATIONS,
      payload: {notifications: []}
    };
  } else return {
      type: CLEAR_NOTIFICATIONS,
      payload: {notifications: [], mobile: true}
    };
}

export const storeInboxBadge = inbox_badge => ({ type: STORE_INBOX_BADGE, payload: inbox_badge});