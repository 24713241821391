import React, {Component} from 'react';
import styled from 'styled-components';

const PageTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  margin-top: 25px;
  font-weight: 900;
  letter-spacing: -0.8px;
`

export default class PageTitleWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <PageTitle>{this.props.children}</PageTitle>
    )
  }
}
