import React, {Component} from 'react';

export default class HandleOutsideClick extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = (e) => {
    if(!this.node.contains(e.target)) {
      if ( (e.target.attributes.role && e.target.attributes.role.value === 'option') || e.target.classList[0] === 'custom-line' ){
        return;
      } else {
        this.props.show();
      }
    }
  }

  render() {
    return (
      <div data-test-id={"handle-outside-click-" + this.props.context} onClick={this.handleClick} ref={node => this.node = node}>{this.props.children}</div>
    )
  }
}
