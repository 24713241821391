let validationRules = new function() {

  this.email = (value) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(value);
  }

  this.zip = (value) => {
    let re = /^\d{5}(?:[-\s]\d{4})?$/;
    return re.test(value);
  }

}();
export default validationRules;
