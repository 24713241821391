import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'auth';
class customerAuthAPI {
  signup = (userID, data) => {
    return apiUtils.patch(url+'/signup', data);
  };
  getUserActive = (data) => {
    return apiUtils.get(url + '/get_user_active?username='+ data.username + '&merchant_name=' + data.merchant_name);
  }
  verifyInfo = (data) => {
    data.formatted_email = data.email.replace('+', '%2B');
    return apiUtils.get(url + '/verify_info?email='+ data.formatted_email + '&merchant_name=' + data.merchant_name + '&username=' + data.username + '&zip=' + data.zip + '&date_of_birth=' + data.date_of_birth +'&sub_id=' + data.sub_id)
  }
  optIn = (data) => {
    return apiUtils.post(url + '/opt_in', data);
  };
  createPassword = (data, auth_token) => {
    return apiUtils.post(url + '/create_password', data, auth_token);
  }
  getLogo = (merchant_name) => {
    return apiUtils.get(url + '/get_logo?merchant_name=' + merchant_name);
  }
  sendResetEmail = (email) => {
    return apiUtils.post(url + '/send_reset_email', email);
  }
  resetCustomerPin = (data) => {
    return apiUtils.post(url + '/user_password_reset', data);
  }
  resetMerchantPassword = (data) => {
    return apiUtils.post(url + '/reset_password', data);
  }
  oneOff = (data) => {
    return apiUtils.postCustom(url + '/one_off', data);
  }
}

export default new customerAuthAPI();
