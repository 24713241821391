import React, {Component} from 'react';
import styled from 'styled-components';
import CurrencyInput from 'react-number-format';

const Wrapper = styled.div`
  text-align: center;
  clear: both;
  .dollar-sign {
    float: center;
    color: #FFFFFF;
    font-size: 4.5vh;
  }
  .no-border {
    float: center;
    border: none;
    border-bottom: 1px solid rgb(255, 255, 255) !important;
    width: auto;
    color: #ffffff;
    margin: 0px 0px 5px 0px;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
`
const ErrorContainer = styled.div`
  color: #FD7272;
  text-align: left;
  margin-top: 5px;
`

export default class CurrencyInputWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  handleEnter = (event) => {
    if (event.key === "Enter" && this.props.submit) {
      this.props.submit();
    } else {
      return;
    }
  }

  checkForErrors = (e) => {
    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    }
    if (this.props.required && e.target.value) {
      this.setState({error: ''})
    }
  }

  render() {
    return (
      <>
        <Wrapper {...this.state}>
          <CurrencyInput placeholder={this.props.placeholder}
                className="no-border"
                name={this.props.name}
                data-test-id={this.props.name}
                onChange={this.props.onChange}
                onKeyUp={this.handleEnter}
                type="text"
                required
                onBlur={(e) => this.checkForErrors(e)}
                disabled={this.props.disabled}
                thousandSeparator={true}
                prefix={'$'}
                decimalSeparator='.'
                decimalScale={2}
                fixedDecimalScale={true}
                />
        </Wrapper>
        <ErrorContainer>{this.state.error}</ErrorContainer>
      </>

    )
  }
}
