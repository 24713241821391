import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + "user_invoices";
let chaliceURL = apiUtils.chaliceAPP + "invoices"
let plan_url = apiUtils.baseUrl + "payment_plans"
let items_url = apiUtils.baseUrl + "invoice_items";
let appends_url = apiUtils.baseUrl + "invoice_appends";
let unpaid_invoices = '/get_subscription_unpaid_invoices';

class UserInvoiceAPI {
  getCustomerInvoice = (userInvoiceID, auth_token, user_pin, callback) => {
    return apiUtils.get(url + "/" + userInvoiceID, auth_token, user_pin, callback);
  }
  getList = (bill_state, auth_token, user_pin, query_string) => {
    var final_url = url
    if (bill_state !== "" && typeof bill_state !== "undefined"){
      final_url += "?bill_state="+bill_state
    }
    if (query_string !== "" && typeof query_string !== "undefined"){
      final_url += query_string
    }
    return apiUtils.get(final_url, auth_token, user_pin);
  }
  getMerchantList = (auth_token) => {
    return apiUtils.chaliceGet(chaliceURL, auth_token);
  };
  setAutopay = (invoice_id, data, auth_token, user_pin) => {
    return apiUtils.patch(url + "/" + invoice_id + "/set_autopay", data, auth_token, user_pin);
  }
  hostedPaymentUnpaidInvoice = (merchant_name, diff, auth_token) => {
    return apiUtils.getCustom(url + unpaid_invoices + '?merchant_name=' + merchant_name + '&diff=' + diff, auth_token, '');

  }
  createInvoice = (data, auth_token, user_pin, callback) => {
    return apiUtils.post(url, data, auth_token, user_pin, callback);
  }
  updateInvoice = (id, data, auth_token) => {
    let final_url = url + '/' + id
    return apiUtils.patch(final_url, data, auth_token)
  }
  deleteInvoice = (id, auth_token, user_pin) => {
    return apiUtils.delete(url + '/' + id, auth_token, user_pin)
  }
  createPlan = (data, auth_token, user_pin, callback) => {
    return apiUtils.post(plan_url, data, auth_token, user_pin, callback);
  }
  getDetail = (id , auth_token, callback) => {
    return apiUtils.get(url + '/' + id, auth_token, callback);
  }
  getDetailForCustomer = (id , auth_token, user_pin, callback) => {
    return apiUtils.get(url + '/' + id, auth_token, user_pin, callback);
  }
  getAllInvoices = (auth_token, callback) => {
    return apiUtils.get(url, auth_token, callback);
  }
  getPaidInvoices = (auth_token, callback) => {
    return apiUtils.get(url + '?state=paid', auth_token, callback);
  }
  getUnpaidInvoices = (auth_token, callback) => {
    return apiUtils.get(url + '?state=unpaid', auth_token, callback);
  }
  deleteLineItem = (id, auth_token) => {
    let deleteItemUrl = items_url + '/' + id;
    return apiUtils.delete(deleteItemUrl, auth_token);
  }
  deleteAppend = (id, auth_token) => {
    let deleteAppendUrl = appends_url + '/' + id;
    return apiUtils.delete(deleteAppendUrl, auth_token);
  }
  sendInvoiceLink = (id, auth_token) => {
    let invoice_url = url + '/' + id +'/invoice_link'
    return apiUtils.post(invoice_url, {'data': null}, auth_token)
  }
}

export default new UserInvoiceAPI();
