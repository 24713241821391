import React, {Component} from 'react';
import styled from 'styled-components';
import Checkmark from '../stateless/Checkmark';
import globalUtils from '../../utils/globalUtils';

const Container = styled.div`
  text-align: center;

  .title {
    font-size: 39px;
    color: #fff;
    margin-bottom: 40px;
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 */
      font-size: 25px;
      margin-bottom: 15px;
    }
    @media only screen and (min-width: 375px) and (max-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      /* iphone 6 */
      font-size: 29px;
      margin-bottom: 25px;
    }
  }
  .checkmark-container {
    margin-bottom: 40px;
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 */
      margin-bottom: 15px;
    }
    @media only screen and (min-width: 375px) and (max-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      /* iphone 6 */
      margin-bottom: 25px;
    }
  }
  .receipt-detail {
    text-align: left;
    color: #fff;
    margin: 0 auto;
    width: 81%;
    font-size: 14px;
    &::after {
      content: "";
      clear: both;
      display: table;
    }
    & .key {
      text-transform: uppercase;
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 */
        font-size: 12px;
      }
      @media only screen and (min-width: 375px) and (max-height: 667px) and (-webkit-device-pixel-ratio: 2) {
        /* iphone 6 */
        font-size: 12px;
      }
    }
    .left-column {
      margin-left: 8px;
      float: left;
      width: 38%;
      &.values {
        width: 53%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
      .pay-detail {
        font-size: 16px;
        margin-bottom: 15px;
        @media screen and (device-aspect-ratio: 40/71) {
          /* iphone 5 */
          font-size: 12px;
        }
        @media only screen and (min-width: 375px) and (max-height: 667px) and (-webkit-device-pixel-ratio: 2) {
          /* iphone 6 */
          font-size: 12px;
        }
      }
    }
  }
`

export default class  extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  returnAmount = () => {
    let amount = globalUtils.convertDollarsToCents(this.props.data.amount);
    return globalUtils.convertToDollars(amount);
  }

  render() {
    const {
      first_name,
      last_name,
      email,
      description
    } = this.props.data
    const { card_type, last4 } = this.props.data.card
    return (
      <Container>
        <div className="title">Thank you!</div>
        <div className="checkmark-container">
          <Checkmark/>
        </div>
        <div className="receipt-detail">
          <div className="left-column">
            <div className="pay-detail key">Name: </div>
            <div className="pay-detail key">Payment: </div>
            <div className="pay-detail key">Email: </div>
            <div className="pay-detail key">Donation: </div>
            <div className="pay-detail key">Amount: </div>
            <div className="pay-detail key">On Behalf Of: </div>
          </div>
          <div className="left-column values">
            <div className="pay-detail">{first_name + ' ' + last_name}</div>
            <div className="pay-detail">{card_type + ' ***' + last4}</div>
            <div className="pay-detail">{email}</div>
            <div className="pay-detail">{description}</div>
            <div className="pay-detail">{this.returnAmount()}</div>
            <div className="pay-detail">{description}</div>
          </div>
        </div>
      </Container>
    )
  }
}
