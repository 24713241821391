import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import classNames from 'classnames';

//Assets
import new_message from '../../../assets/icons/new_message.png';
import message_close from '../../../assets/icons/close_message.png';
import unread_icon from '../../../assets/icons/unread.png';

//Components
// import MessageLine from './MessageLine';
import ChatBox from './ChatBox';
import history from '../../../history';
import HideShow from '../../../components/function/HideShow';
import UserSearchModal from '../../../components/modals/messaging/UserSearchModal';
import Modal from '../../../components/modals/NewModal'
import Checkbox from '../../../components/inputs/Checkbox';
import SearchInput from '../../../components/inputs/SearchInput';

//API
import messagesAPI from '../../../api/messages/messagesAPI';

//Redux
import { storeInboxBadge } from "../../../js/actions/notifications";

const Container = styled.div`
  display: flex;
  height: 90%;
  width: 98%;
  .left-column {
    background-color: white;
    margin-right: 5px;
    width: 13%;
    min-width: 225px;
    /* display: flex;
    justify-content: flex-start; */
    @media (max-height: 700px) {
      min-width: 180px;
    }
    .new-message {
      cursor: pointer;
      background-color: #fd7272;
      display: flex;
      padding: 11px 8px;
      color: white;
      font-size: 21px;
      margin-bottom: 20px;
      img.icon {
        height: 30px;
        width: auto;
      }
      span {
        margin-left: 5px;
        margin-top: 3px;
        @media (max-height: 700px){
          font-size: 18px;
        }
      }
    }
    .title {
      padding: 10px 23px;
      cursor: pointer;
      color: #fff;
      font-size: 23px;
      display: flex;
      .count {
        font-size: 12px;
        background-color: #fd7272;
        border-radius: 41px;
        width: 24px;
        height: 23px;
        color: white;
        text-align: center;
        padding-bottom: 14px;
        line-height: 2;
        margin-top: 3px;
        margin-left: 8px;
      }
    }
    .active {
      background-color: #C7CED5;
    }
  }
  .middle-column {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-right: 5px;
    width: 33%;
    min-width: 370px;
    background-color: #F1F4F7;
    @media (max-height: 700px) {
      min-width: 310px;
    }
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb:vertical {
      margin: 11px;
      background-color: #6CA2DC;
      border-radius: 5px;
      -webkit-border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
       background-color: white;
       border: 1px solid #DDD;
       border-radius: 5px;
    }
    .icon-close {
      height: 22px;
      width: auto;
      position: absolute;
      top: 70px;
      right: 23px;
      cursor: pointer;
      z-index: 8;
    }
    .icon-unread {
      height: 22px;
      width: auto;
      position: absolute;
      top: 69px;
      right: 58px;
      cursor: pointer;
      z-index: 8;
    }
    .hover {
      position: absolute;
      z-index: 9;
      font-size: 12px;
      top: 40px;
      right: 5px;
      background-color: #fff;
      padding: 5px;
      border-radius: 10px;
      box-shadow: 0 0 3px
    }
    .middle-checkbox {
      position: absolute;
      top: 72px;
      left: 9px;
      transform: scale(1.3);
      z-index: 8;
    }
    .no-messages {
      text-align: center;
    }
    .search-container {
      position: relative;
      height: 100px;
      padding: 10px 20px;
      margin-left: 15px;
      .search-input {
        background-color: #fff;
        border-radius: 10px;
        border: none;
        height: 30px;
        width: 100%;
        text-indent: 8px;
        &::placeholder {
          color: #AFAFAF;
        }
      }
    }
  }
  .right-column {
    background-color: #fff;
    width: 52%;
    min-width: 530px;
    @media (max-height: 700px) {
      min-width: 515px;
    }
    .header {
      border-bottom: 2px solid gainsboro;
      height: 50px;
      margin-bottom: 10px;
      width: 93%;
      margin-left: 20px;
      .active-name {
        font-size: 22px;
        cursor: pointer;
        padding-top: 12px;
        float: left;
        margin-left: 5px;
        & :hover {
          color: #06d6a0;
        }
      }
      .text {
        float: right;
        text-align: center;
        background-color: #06d6a0;
        padding: 6px 10px;
        color: #fff;
        width: 170px;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 2px;
        cursor: pointer;
        }
    }
  }
`

class Inbox extends Component {
  constructor(props){
    super(props);
    this.state = {
      inbox: true,
      unread: false,
      drafts: false,
      close: true,
      showCloseToolTip: false,
      showUnreadToolTip: false,
      allChecked: false,  
      activeSub: null
    };
    this.checkedArray = [];
    this.cleanedArray = [];
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = async (string) => {
    // Get's conversation data for the inbox
    if (string){
      // A string being present means the conversation has been closed or is inactive.
      this.setState({
        activeSub: null
      })
    }
    let messages = await messagesAPI.getList(this.props.auth_token)
    if (messages.err && messages.err.response) {
      this.setState({errors: messages.err.response.messages.detail})
      return
    }
    this.setState({
      messages, loaded: true
    })
  }

  addToArray = (sub_id, value) => {
    if (sub_id && value === true) {
      this.checkedArray.push(sub_id);
      this.cleanedArray = [...new Set(this.checkedArray)];
      return;
    } else if (sub_id && value === false) {
      this.checkedArray.splice(0, sub_id);
      this.cleanedArray = [...new Set(this.checkedArray)];
      return;
    }
  }

  clearMessages = () => {
    console.log('Clearing message on uncheck')
    this.setState({
      activeSub: null,
      activeUser: null,
      activeMessage: []
    })
  }


  handleCustomerClick = (activeSub) => {
    this.setState(
      {activeSub},
      () => {
        this.getMessageDetail()
      })
  }

  getMessageDetail = async () => {
    // Gets chat history for a single conversation.
    let query_string = "?sub_id=" + this.state.activeSub;
    const data = await messagesAPI.getChat(this.props.auth_token, query_string)
    if (data.err && data.err.response ) {
      this.setState({
        activeMessage: [],
        activeSub: data.err.response.data.subscription_id,
        errors: data.err.response.data.message
      })
    } else {
      this.setState({
        activeMessage: data.messages,
        activeSub: data.subscription_id
      }, this.getData)
    }
  }

  routeToMessage = () => {
    history.push('/admin/customers/profile/' + this.state.activeSub)
  }

  setActive = (tab) => {
    if (tab === 'inbox') {
      this.setState({inbox: true, unread: false, drafts: false});
      this.getData();
    } else if (tab === 'unread') {
      this.setState({inbox: false, unread: true, drafts: false})
    } else if (tab === 'drafts') {
      this.setState({ inbox: false, unread: false, drafts: true});
    }
  }

  showNewMessageModal = () => {
    if (this.state.showNewMessageModal) {
      return (
        <UserSearchModal onClose={this.toggleModal}/>
      )
    }
  }

  toggleModal = () => {
    this.setState(prevState => {
      return {
        showNewMessageModal: !prevState.showNewMessageModal,
      };
    });
  }

  showCloseChatModal = () => {
    if (this.state.showCloseChatModal) {
      return (
        <Modal context="inbox-1" onClose={this.toggleCloseChatModal} title='Are you sure?' submit={this.closeMessages} yesNo errors={this.state.errors}/>
      )
    }
  }

  showBulkCloseChatModal = () => {
    if (this.state.showBulkCloseChatModal) {
      return (
        <Modal context="inbox-2" onClose={this.toggleBulkCloseModal} title='Close Conversation(s)' submit={this.bulkDelete} yesNo errors={this.state.errors}/>
      )
    }
  }

  showBulkUnreadModal = () => {
    if (this.state.showBulkUnreadModal) {
      return (
        <Modal context="inbox-3" onClose={this.toggleBulkUnreadModal} title='Mark as Unread' submit={this.bulkMarkUnread} yesNo errors={this.state.errors}/>
      )
    }
  }

  toggleCloseChatModal = () => {
    this.setState(prevState => {
      return {
        showCloseChatModal: !prevState.showCloseChatModal,
        errors: undefined
      };
    });
  }

  toggleBulkCloseModal = () => {
    this.setState(prevState => {
      return {
        showBulkCloseChatModal: !prevState.showBulkCloseChatModal,
        errors: undefined
      };
    });
  }

  toggleBulkUnreadModal = () => {
    this.setState(prevState => {
      return {
        showBulkUnreadModal: !prevState.showBulkUnreadModal,
        errors: undefined
      };
    });
  }

  closeMessages = async () => {
    let closed = await messagesAPI.closeChat({sub_id: this.state.activeSub}, this.props.auth_token)
    if(closed.err && closed.err.response) {
      this.setState({
        errors: closed.err && closed.err.response.data.detail
      })
      return;
    }
    this.setState({
      activeMessage: false
    })
    this.getData('closed')
    this.toggleCloseChatModal()
  }

  returnLength = () => {
    let countArray = [];
    if (this.state.messages && this.state.messages.length > 0) {
      for (let i in this.state.messages) {
        if (this.state.messages[i].read === false) {
          countArray.push(this.state.messages[i])
        }
      }
      if (countArray.length > 0) {
        this.props.storeInboxBadge(true);
        return (<div className="count">{countArray.length}</div>)
      }
    } else {
      this.props.storeInboxBadge(false);
      return;
    }
  }

  checkAll = (event) => {
    this.setState(prevState => {
      return {
        allChecked: !prevState.allChecked,
        activeSub: null,
        activeMessage: [],
        activeUser: null
      };
    });
  }

  bulkDelete = async () => {
    let subIdArray = []
    if (this.state.allChecked) {
      for (let i in this.state.messages) {
        subIdArray.push(this.state.messages[i].sub_id);
      }
      let closed = await messagesAPI.closeChat(subIdArray, this.props.auth_token)
      if (closed.err && closed.err.response) {
        return this.setState({ errors: closed.err.response.data.detail })
      }
      this.setState({ activeMessage: false });
      this.getData('closed')
      this.toggleBulkCloseModal()
      return;
    }
    if (this.cleanedArray.length === 0) {
      return this.setState({errors: 'Please select conversation to close'})
    }
    if (this.cleanedArray && this.cleanedArray.length > 0) {
      let closed = await messagesAPI.closeChat(this.cleanedArray, this.props.auth_token)
      if (closed.err && closed.err.response) {
        return this.setState({ errors: closed.err.response.data.detail });
      }
      this.setState({ activeMessage: false });
      this.checkedArray = [];
      this.getData('closed')
      this.toggleBulkCloseModal()
      return;
    }
  }

  renderCheckbox = () => {
    if (this.state.messages && this.state.messages.length > 0) {
      return (
        <>
          <Checkbox data-test-id="middle-checkbox" className="middle-checkbox" onChange={this.checkAll} value={this.state.allChecked}/>
          <img src={message_close} alt="close" className="icon-close" data-test-id="inbox-bulk-close" onClick={this.toggleBulkCloseModal} onMouseOver={this.showCloseToolTip} onMouseOut={this.showCloseToolTip}/>
          <img src={unread_icon} alt="mark_unread" className="icon-unread" data-test-id="inbox-bulk-unread" onClick={this.toggleBulkUnreadModal} onMouseOver={this.showUnreadToolTip} onMouseOut={this.showUnreadToolTip}/>
        </>

      )
    }
  }

  bulkMarkUnread = async () => {
    let messageArray = []

    if (this.state.allChecked) {
      for (let i in this.state.messages) {
        messageArray.push(this.state.messages[i].sub_id);
      }
      let marked_unread = await messagesAPI.markRead(messageArray, this.props.auth_token)
      if(marked_unread.err && marked_unread.err.response) {
        this.setState({
          errors: marked_unread.err && marked_unread.err.response.data.detail
        })
        return;
      }
      this.setState({
        activeMessage: false
      })
      this.getData('marked_unread')
      this.toggleBulkUnreadModal()
      return;
    }
    if (this.cleanedArray.length === 0) {
      return this.setState({errors: 'Please select message to mark as unread'})
    }
    if (this.cleanedArray && this.cleanedArray.length > 0) {
      let marked_unread = await messagesAPI.markRead(this.cleanedArray, this.props.auth_token);
      if(marked_unread.err && marked_unread.err.response) {
        this.setState({
          errors: marked_unread.err.response.data.detail
        })
        return;
      }
      this.setState({
        activeMessage: false
      })
      this.checkedArray = [];
      this.getData('marked_unread')
      this.toggleBulkUnreadModal()
      return;
    }
  }

  showCloseToolTip = () => {
    this.setState(prevState => {
      return {
        showCloseToolTip: !prevState.showCloseToolTip
      }
    })
  }

  showUnreadToolTip = () => {
    this.setState(prevState => {
      return {
        showUnreadToolTip: !prevState.showUnreadToolTip
      }
    })
  }

  // checkForMatch = (event) => {
  //   let match = false;
  //   for (let i in this.state.messages) {
  //     if (event.target.value && event.target.value.length > 0 && this.state.messages.filter(function(message){
  //       return message.first_name.toLowerCase().search(event.target.value.toLowerCase() !== -1)})) {
  //       match = true;
  //     }
  //     if (match) {
  //       this.getMessages(event.target.value);
  //     }
  //   }
  // }

  render() {
    return (
      <Container className="inbox-container">
        <div className="left-column">
          <div className="new-message" data-test-id="inbox-new-message" onClick={this.toggleModal}>
            <img src={new_message} className="icon" alt="new"/>
            <span>New Message</span>
          </div>
          {this.showNewMessageModal()}
          {this.showCloseChatModal()}
          {this.showBulkCloseChatModal()}
          {this.showBulkUnreadModal()}
          <div className={classNames("title", {'active': this.state.inbox})} data-test-id="inbox-active" onClick={() => this.setActive('inbox')}>Inbox {this.returnLength()}</div>
          {/*<div className={classNames("title", {'active': this.state.unread})} onClick={() => this.setActive('unread')}>Unread</div>*/}
          {/*<div className={classNames("title", {'active': this.state.drafts})} onClick={() => this.setActive('drafts')}>Drafts</div>*/}
        </div>
        <div className="middle-column">
          {this.renderCheckbox()}
          <HideShow show={this.state.showCloseToolTip}>
            <span className="hover">Close Conversation</span>
          </HideShow>
          <HideShow show={this.state.showUnreadToolTip}>
            <span className="hover">Mark as Unread</span>
          </HideShow>

          <div className="search-container">
            {/*<Input placeholder="Search" className="search-input" onKeyDown={(event) => this.checkForMatch(event)}/>*/}
            <SearchInput
              clearMessages={this.clearMessages}
              messages={this.state.messages}
              className="search-input"
              getMessageDetail={this.handleCustomerClick}
              data-test-id="search-input"
              onChange={(id, value) => this.addToArray(id, value)}
              checked={this.state.allChecked}
              activeSub={this.state.activeSub}/>
          </div>
          {/*{this.getMessages()}*/}
        </div>
        <div className="right-column">
          <div className="header">
            <HideShow show={this.state.activeSub}>
              <div className="active-name"><Link to={"/admin/customers/profile/"+ this.state.activeSub}>{this.state.activeUser}</Link></div>
              <span className="text" data-test-id="inbox-view-customer" onClick={this.routeToMessage}>View Customer</span>
            </HideShow>
          </div>
          {
            this.state.activeSub ?
            <ChatBox context="inbox" message={this.state.activeMessage} loaded={this.state.loaded} toggleModal={this.toggleCloseChatModal} close={this.state.close} getData={this.getMessageDetail} activeSub={this.state.activeSub} auth_token={this.props.auth_token}/>
            :
            null
          }
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    user_role: state.auth.user_role
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    storeInboxBadge: (inbox_badge) => {dispatch(storeInboxBadge(inbox_badge))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inbox)
