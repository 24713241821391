import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Assets
import search_icon from '../../assets/icons/search_icon.png';

// Components
import HideShow from '../function/HideShow';

const TagSearchbarWrapper = styled.div`
  .search {

    &__wrapper {
      position: relative;
      width: fit-content;
      margin: 0 auto;
    }

    &__icon {
      width: 14px;
      height: auto;
      position: absolute;
      left: 5px;
      top: 36%;
      transform: translateY(-50%);
    }
  }

  #searchbar {
    margin-bottom: 10px;
    display: inline-block;
    height: 30px;
    width: 391px;
    opacity: 0.52;
    border-radius: 10px;
    background-color: #E2E2E2;
    font-size: 17px;
    font-weight: 500;
    color: #969696;
    line-height: 22px;
    text-indent: 20px;
    outline: none;
    &:focus {
      border: 2px solid #64ABF5;
      outline: none;
    }
  }

  .results {
    
    &__container {
      height: 97px;
      width: 370px;
      border: 1px solid #AFD5D5;
      border-radius: 3px;
      background-color: #FFFFFF;
      margin: 0 auto;
      position: absolute;
      top: 28px;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      z-index: 1;
      overflow-y: scroll;
    }

    &__line {
      text-indent: 29px;
      height: 18px;
      color: #969696;
      font-size: 14px;
      letter-spacing: 0.3px;
      line-height: 18px;
      transition: all .2s ease-out;
      cursor: pointer;

      &:hover {
        background-color: #F4F4F4;
        color: #646464;
      }   
    }
  }
`;

class TagSearchbar extends Component {
  constructor(props){
    super(props);
    this.state = {
      input: '',
      results: [],
      equalToInput: false,
      alreadyTagged: false
    };
  }

  handleInput = (e) => {
    this.setState({input: e.target.value}, this.updateSearch)
  }
  updateSearch = () => {
    let equalToInput = false;
    let alreadyTagged = false;
    let results = this.props.merchant_tags.filter( merchantTag => {
      let included = false;
      this.props.current_tags.forEach( userTag => {
        if(userTag[1] === merchantTag[1]) {
          included = true
        }
      })
      if(merchantTag[1].toLowerCase() === this.state.input.toLowerCase()) {
        equalToInput = true
      }
      return merchantTag[1].toLowerCase().includes(this.state.input.toLowerCase()) && !included
    })
    if(results.length === 0) {
      this.props.current_tags.forEach(userTag => {
        if(userTag[1].toLowerCase() === this.state.input.toLowerCase()) {
          alreadyTagged = true;
        }
      })   
    }
    this.setState({results, equalToInput, alreadyTagged})
  }

  addTag = (id, name) => {
    if(id) {
      this.props.handle_tag(id, name)
    } else {
      this.props.handle_tag(null, this.state.input)
    }
      
    this.setState({input: ''})
  }

  handleBlur = () => {
    setTimeout( () => {
      this.setState({input: ''})

    }, 600)
  }

  showResults = () => {
    if(this.state.results.length > 0) {
      let Results = this.state.results.map( result => {
        return <div className="results__line" onClick={ () => this.addTag(result[0], result[1]) } key={`result-${result[0]}`}>{result[1]}</div>
      })
      if(!this.state.equalToInput) {
        return(
          <>
            {Results}
            <div className="results__line" onClick={ () => this.addTag(null) }>{this.state.input} (create tag)</div>
          </>
        )
      } else return Results
    } else if(!this.state.alreadyTagged) {
      return <div className="results__line" onClick={() => this.addTag(null) }>{this.state.input} (create tag)</div>
    }
  }


  render() {

    return (
        <TagSearchbarWrapper>
          <div className="search__wrapper">
            <input data-test-id={this.props.name} id="searchbar" autoComplete="off" onBlur={this.handleBlur} value={this.state.input} onChange={this.handleInput} placeholder="Search"/>
            <img src={search_icon} alt="search" className="search__icon"/>
          </div>
          <HideShow show={this.state.input}>
            <div className="results__container">
              {this.showResults()}  
            </div> 
          </HideShow>
        </TagSearchbarWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    merchant_tags: state.merchants.merchant_tags
  }
}

export default connect(
  mapStateToProps
)(TagSearchbar)
