import React, {Component} from 'react';
import styled from 'styled-components';

const BzLabelWrapper = styled.div`
  position: relative;
  width: ${props => props.addCard ? '50px' : '315px'};
`

const Label = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
  color: ${props => props.blue ? '#5A9DEB' : props.disabled ? '#E4E4E4' : '#5D5A61'};
  @media (max-width: 580px) {
    font-size: 16px
  }
  @media (max-height: 700px){
    font-size: 15px;
  }
`

const OptionalText = styled.div`
  font-size: 14px;
  color: #5D5A61;
  position: absolute;
  right: 26px;
  top: 0px;
`

export default class LabelWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  renderHtml = () => {
    if (this.props.or) {
      return (
        <>
        <Label {...this.props} key={this.props.label}>{this.props.label}</Label>
        <OptionalText onClick={this.props.onClick} key={this.props.label + 1}>{this.props.registration ? this.props.registration : '(birthday or zip required)'}</OptionalText>
        </>
      )
    }
    if (this.props.optional) {
      return (
        <>
        <Label {...this.props} key={this.props.label}>{this.props.label}</Label>
        <OptionalText onClick={this.props.onClick} key={this.props.label + 1}>{this.props.registration ? this.props.registration : '(optional)'}</OptionalText>
        </>
      )
    } else {
      return (
        <Label {...this.props} key={this.props.label}>{this.props.label}</Label>
      )
    }
  }


  render() {
    return (
      <BzLabelWrapper {...this.props} className="label">
        {this.renderHtml()}
      </BzLabelWrapper>
    )
  }
}
