import React, {Component} from 'react';
import styled from 'styled-components';
import chevron_icon from '../../assets/icons/chevron_left_blue.png';
import history from '../../history';

const ChevronIcon = styled.img`
  height: 25px;
  width: auto;
  position: absolute;
  top: 78px;
  left: 260px;
  cursor: pointer;
`

const Page = styled.div`
  position: absolute;
  color: #64ABF5;
  font-size: 26px;
  top: 78px;
  left: 285px;
`

export default class BreadcrumbTitle extends Component {

  changeRoutes = () => {
    if (this.props.path) {
      history.push(this.props.path);
    } else {
      history.goBack();
    }
  }

  render() {
    return (
      <div className="breadcrumb-title-wrapper" >
        <ChevronIcon src={chevron_icon} onClick={this.changeRoutes}/>
        <Page>{this.props.page}</Page>
      </div>
    )
  }
}
