import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';

//Components
import Input from '../../components/inputs/Input';
import ModalButtonCombo from '../../components/buttons/ModalButtonCombo';
import Label from '../../components/display/Label';
import HideShow from '../../components/function/HideShow';
import PhoneInput from '../../components/inputs/PhoneInput';
import Date from '../../components/inputs/Date';
import AnimatedCheckmark from '../../components/mobile/AnimatedCheckmark';
import Typeahead from '../../components/inputs/Typeahead';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import CardConnect from '../../utils/CardConnect';

//API & Utils
import hostedPaymentAPI from '../../api/hosted_payment/hosted_paymentAPI';
import loginAPI from '../../api/login/loginAPI';
import userInvoiceAPI from '../../api/invoices/userInvoiceAPI';
import paymentMethodAPI from '../../api/blytzwallet/PaymentMethod';
import globalUtils from '../../utils/globalUtils';
import paymentAPI from '../../api/payments/paymentAPIs';

//Redux
import { logout, isMerchant, storeCustomerUsername } from '../../js/actions/auth';

//Assets
import logo from '../../assets/images/logo-gradient.png'

const Background = styled.div`
  background-color: #b0b0b0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .partner-logo {
    margin-bottom: 10px;
    img {
      height: 75px;
      width: auto;
    }
  }
  .errors {
    color: #FD7272;
    text-align: center;
    margin-bottom: 5px;
  }
  .success {
    color: #06D6A0;
    text-align: center;
    margin-bottom: 5px;
  }
  .tabs {
    width: 840px;
    display: flex;
    flex-grow: .1;
    border: 1px solid #979797;
    height: 48px;
    min-height: 48px;
    cursor: default;
    @media (max-width: 768px) {
      width: 600px;
    }
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 210px;
      padding: 10px;
      background-color: #DFE3EC;
      color: #797e88;
      :nth-child(-n+3) {
        border-right: 1px solid #979797;
      }
    }
    .active {
      background-color: #4691E4;
      color: #fff;
    }
  }
  .middle-container {
    background: white;
    height: 500px;
    width: 840px;
    margin: 0 auto;
    padding: 20px;
    @media (max-height: 700px){
      height: 400px;
    }
    @media (max-width: 768px) {
      width: 600px;
    }
    .agreement {
      text-align: center;
      font-size: 13px;
      color: #4691e4;
    }
    .input-section {
      text-align: center;
      margin-top: 20px;
      width: 30%;
      margin: 0 auto;
      .other-text {
        text-align: left;
        font-size: 17px;
        margin-bottom: 10px;
        width: 300px;
        margin-left: 10px;
        &.pointer {
          cursor: pointer;
        }
      }
      .selected-option {
        text-align: left;
        width: 504px;
        margin-bottom: 8px;
        word-break: break-word;
        margin-left: -40px;
        color: #4691e4;
      }
      .input-container {
        margin-bottom: 10px;
        font-weight: 600;
        .no-border {
          text-indent: 8px;
        }
        .label {
          text-align: left;
          margin-bottom: 5px;
        }
        .css-13wk3o7 {
          background-color: #fff;
          border: 1px solid black;
        }
      }
      .modal-input {
        margin-top: 13px;
        margin-bottom: 30px;
        width: 238px;
      }
    }
    .margin-top {
      margin-top: 40px;
    }
    .button-wrapper {
      width: 375px;
      margin: 0 auto;
      z-index: 9;
    }
  }
  .no-border {
    border: 1px solid black;
    height: 45px;
    width: 235px;
    font-size: 20px;
  }
  .powered {
    display: flex;
    color: #fff;
    .text {
      margin-top: 17px;
      margin-right: 5px;
      cursor: default;
    }
    .logo {
      height: 45px;
      width: auto;
      margin-top: 5px
    }
  }
  .header-container {
    width: 60%;
    margin: 0 auto;
    color: #6C768C;
    text-align: center;
    .header {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .sub-header {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
  .animated-checkmark {
    margin-top: 0px;
    margin-bottom: 20px;
  }

`

class HostedPaymentLogin extends Component {
  constructor(props){
    super(props);
    this.state = {
      login: true,
      hideLogin: false,
      card: {},
      showCCInput: true,
      processing: false,
      selectedInvoice: ''
    };

    // const values = globalUtils.getQueryString(this.props.location.search.slice(1))
    // const diff = values.diff;
    const pathArray = window.location.pathname.split('/');
    this.merchant_name = pathArray[2]
    this.diff = pathArray[3]
    this.myRefs = {
      code: React.createRef(),
      phone: React.createRef(),
      email: React.createRef(),
      birthdate: React.createRef(),
      pin: React.createRef(),

    };
  }

  componentDidMount = () => {
    this.getLogo();
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  getLogo = async () => {
    let logo = await hostedPaymentAPI.getLogo(this.merchant_name, this.diff)
    if (logo && logo.err && logo.err.response && logo.err.response.data.detail) {
      this.setState({companyError: logo.err.response.data.detail})
    }
    this.setState({partner_logo: logo.logo_url})

  }

  resendCode = async () => {
    let code = await hostedPaymentAPI.getCode({phone: this.formatted_phone, merchant_name: this.merchant_name, diff: this.diff})
    if (code && !code.err) {
      this.setState({ success: 'Code resent'});
    }
  }

  sendCode = async () => {
    if (globalUtils.checkValues(this.myRefs) === false) {
      return;
    }
    this.formatted_phone = this.state.phone.replace(/[- )(]/g,'');
    let code = await hostedPaymentAPI.getCode({phone: this.formatted_phone, merchant_name: this.merchant_name, diff: this.diff})
    if (code && code.success === false) {
      this.setState({errors: "Phone number not found"})
      return;
    }
    this.setState({welcome: false, confirm: true, welcomeTab: true, errors: undefined})

  }

  startOver = () => {
    this.setState({login: true, confirmTab: false, registration: false, register: false, welcomeTab: false, payment: false, cardInfo: false})
  }

  register = () => {
    this.setState({register: true, login: false, welcome: true})
  }

  goBack = () => {
    this.setState({ register: false, login: true, welcome: false})
  }

  verifyCode = async () => {
    if (globalUtils.checkValues(this.myRefs) === false) {
      return;
    }
    let verify = await hostedPaymentAPI.verifyCode(this.merchant_name, this.diff, globalUtils.onlyLettersAndNumbers(this.state.code), this.formatted_phone)
    if (verify && verify.success === false) {
      this.setState({errors: 'Invalid code', success: undefined})
      return;
    }
    this.setState({confirm: false, confirmTab: true, registration: true, errors: undefined, success: undefined})
  }

  finalizeRegistration = async () => {
    if (this.state.pin !== this.state.confirm_pin) {
      this.setState({ errors: 'PINs do not match'});
      return;
    }
    if (!this.state.email || !this.state.birthdate || !this.state.pin || !this.state.confirm_pin) {
      this.setState({ errors: 'All fields are required'});
      return;
    }
    if (globalUtils.checkValues(this.myRefs) === false) {
      return;
    }
    let registerObj = {
      code: this.state.code,
      phone: this.formatted_phone,
      email: this.state.email,
      birthdate: this.state.birthdate,
      pin: this.state.pin,
      merchant_name: this.merchant_name,
      diff: this.diff
    }
    let register = await hostedPaymentAPI.register(registerObj)
    if (register.success === false) {
      this.setState({errors: register.reason})
    }
    this.setState({done: true, registrationTab: true, registration: false, errors: undefined})
  }

  routeToLogin = () => {
    this.props.logout();
    this.setState({login: true, register: false, done: false, confirm: false, confirmTab: false, registration: false, registrationTab: false, complete: false, cardTab: false, hideLogin: false, paymentTab: false})
  }

  signIn = async () => {
    if (globalUtils.checkValues(this.myRefs) === false) {
      return;
    }
    if (!this.state.phone || !this.state.pin) {
      this.setState({errors: 'All fields are required'})
      return;
    }
    this.formatted_phone = this.state.phone.replace(/[- )(]/g,'');
    let login = await loginAPI.customerLogin({phone: this.formatted_phone, pin: this.state.pin, customer_login: true});
    this.props.isMerchant(false);
    this.props.storeCustomerUsername(login.username);
    if (login.err && login.err.response) {
      this.setState({ errors: login.err.response.data.detail + ' incorrect phone and/or PIN'})
      return;
    }
    this.setState({ auth_token: login.auth_token}, () => {
      this.getUnpaidInvoices();
      this.getPaymentMethods();
      this.setState({payment: true, paymentTab: true, hideLogin: true, errors: undefined})
    })
  }

  payAmount = () => {
    if (!this.state.card.invoice || !this.state.amount_paid) {
      this.setState({errors: 'All fields are required'})
      return;
    }
    this.setState({cardInfo: true, paymentTab: true, payment: false, errors: undefined})

  }

  handleEnter = (event, name) => {
    if (event.key === "Enter") {
      if(name === 'sign-in') {
        this.signIn()
        return;
      }
      if(name === 'pay') {
        this.payAmount()
        return;
      }
    }
  }

  submitPayment = async () => {
    let card = Object.assign({}, this.state.card);
    card.expiration = this.state.expiration;
    card.amount_paid = globalUtils.convertDollarsToCents(this.state.amount_paid);
    card.token = this.state.token;
    if (this.state.token) {
      if (!this.state.expiration) {
        this.setState({errors: 'Expiration is required', processing: false});
        return;
      }
      this.setState({ processing: true});
      let response = await paymentMethodAPI.addPaymentMethod(card, this.state.auth_token)
      card.id = response.payment_method_id
      if (response && !response.err) {
        let payment = await paymentAPI.payOnceWithCard(card, this.state.auth_token)
        if (payment && !payment.err) {
          this.setState({cardInfo: false, complete: true, cardTab: true, errors: undefined})
          return;
        } else {
          this.setState({ errors: payment.err.response.data.detail, processing: false})
        }
      } else {
        this.setState({errors: response.err.response.data.detail, processing: false})
        return;
      }
    } else {
      if (!this.state.card.id) {
        this.setState({errors: 'Please select payment option', processing: false});
        return;
      }
      let payment = await paymentAPI.payOnceWithCard(card, this.state.auth_token)
      if (payment && !payment.err) {
        this.setState({cardInfo: false, complete: true, cardTab: true, errors: undefined})
      } else {
        this.setState({ errors: payment.err.response.data.detail, processing: false})
      }
    }



  }

  setToken = (token) => {
    if (token && token.validationError) {
      this.setState({ errors: token.validationError})
      return;
    } else {
      let newCard = globalUtils.checkCardType(token.substring(1,2), this.state.card);
      newCard.token = token;
      newCard.last4 = token.slice(-4);
      this.setState({
        token: token,
        card: newCard,
        errors: null,
        selectedInvoice: {}
      })
    }
  }

  getUnpaidInvoices = async () => {
    let invoices = await userInvoiceAPI.hostedPaymentUnpaidInvoice(this.merchant_name, this.diff, this.state.auth_token);
    if (invoices && invoices.err) {
      this.setState({ errors: "Couldn't retrieve invoices"});
    }
    this.setState({invoices: invoices})
  }

  getPaymentMethods = async () => {
    let payment_methods = await paymentMethodAPI.getHPPaymentMethods(this.state.auth_token);
    if (payment_methods) {
      this.setState({payment_methods})
    }

  }

  onInvoiceChange = (event) => {
    let card = {}
    card.invoice = event.value;
    this.setState({card, selectedInvoice: event});
  }

  onPaymentOptionChange = (event) => {
    let card = Object.assign({}, this.state.card)
    card.id = event.value;
    this.setState({card, showCCInput: false});
  }

  showCardConnect = () => {
    this.setState({
      showCCInput: true,
      card: {
        payment_method: undefined
      }
    });
  }

  goBackToInvoice = () => {
    this.setState({cardInfo: false, payment: true, paymentTab: true, showCCInput: true, selectedInvoice: undefined, errors: false});
  }

  showSelectedInvoice = () => {
    if (this.state.selectedInvoice) {
      return (
        <div className="selected-option">{this.state.selectedInvoice.label}</div>
      )
    }
  }

  render() {
    return (
      <Background>
        <div className="partner-logo">
          <img src={this.state.partner_logo} alt="business logo"/>
        </div>
        <div className="errors">{this.state.companyError}</div>

        <div className="tabs">
          <HideShow show={this.state.login}>
            <div className={classNames({'active': this.state.login})}>Login/Register</div>
            <div className={classNames({'active': this.state.payment || this.state.paymentTab})}>Payment Confirmation</div>
            <div className={classNames({'active': this.state.cardInfo || this.state.cardTab})}>Payment Options</div>
            <div className={classNames({'active': this.state.complete})}>Transaction Complete</div>
          </HideShow>
          <HideShow show={this.state.register}>
            <div className={classNames({'active': this.state.welcome || this.state.welcomeTab})}>Welcome</div>
            <div className={classNames({'active': this.state.confirm || this.state.confirmTab})}>Confirm Code</div>
            <div className={classNames({'active': this.state.registration || this.state.registrationTab})}>Register</div>
            <div className={classNames({'active': this.state.done})}>Done!</div>
          </HideShow>
        </div>
        <div className="middle-container">
          <div className="errors">{this.state.errors}</div>
          <HideShow show={this.state.success}>
            <div className="success">{this.state.success}</div>
          </HideShow>
          <HideShow show={this.state.login && !this.state.hideLogin}>
            <div className="input-section margin-top">
              <div className="input-container">
              <Label label="Phone Number"/>
                <PhoneInput data-test-id="phone" className="merchant-input" name="phone" ref={this.myRefs.phone} required type="phone" onChange={this.onChange}/>
              </div>
              <div className="input-container">
              <Label label="PIN"/>
                <Input className="merchant-input" name="pin" maxLength="4" type="password" ref={this.myRefs.pin} onChange={this.onChange} submit={ (e) => this.handleEnter(e, 'sign-in') } required/>
              </div>
            </div>
            <div className="button-wrapper">
              <ModalButtonCombo custom first="Register" second="Sign In" onClose={this.register} submit={this.signIn}/>
            </div>
          </HideShow>
          <HideShow show={this.state.payment}>
            <div className="input-section margin-top">
              <div className="input-container">
              <Label label="Select Invoice"/>
                <Typeahead name="invoice" options={this.state.invoices} onChange={this.onInvoiceChange} hosted_payment_invoices required/>
              </div>
              {this.showSelectedInvoice()}
              <div className="input-container">
              <Label label="Enter Amount to Pay"/>
                <CurrencyInput name="amount_paid" onChange={this.onChange} placeholder="$0.00" submit={ (e) => this.handleEnter(e, 'pay') } required/>
              </div>
            </div>
            <div className="button-wrapper">
              <ModalButtonCombo custom hide_cancel second="Pay" submit={this.payAmount}/>
            </div>
          </HideShow>
          <HideShow show={this.state.cardInfo}>
            <div className="input-section margin-top">
              <div className="input-container">
                <Label label="Select Payment Option"/>
                <Typeahead name="payment_method" options={this.state.payment_methods} onChange={this.onPaymentOptionChange} payment_methods/>
              </div>
              <HideShow show={this.state.card.payment_method && !this.state.showCCInput}>
                <div className="other-text pointer" data-test-id="login-cancel-and-use-new" onClick={this.showCardConnect}>Cancel and use new card instead</div>
              </HideShow>
              <HideShow show={this.state.showCCInput}>
                <div className="other-text">Or </div>
                <div className="input-container">
                <Label label="Credit Card Number"/>
                <CardConnect
                  className="input"
                  card
                  noBorder
                  type="tel"
                  name="card_number"
                  options={{creditCard: true}}
                  // onChange={this.onChange.bind(this)}
                  autoComplete="cc-number"
                  required
                  ref={this.myRefs.card_number}
                  setToken={(token) => this.setToken(token)}/>
                </div>
                <div className="input-container">
                  <Label label="Expiration Date"/>
                  <Cleave placeholder="mm/yy"
                          type="tel"
                          className="no-border"
                          name="expiration"
                          data-test-id="expiration"
                          ref={this.myRefs.expiration}
                          options={{date: true, datePattern: ['m','y']}}
                          onChange={this.onChange}
                          // autoComplete="cc-exp"
                          />
                </div>
              </HideShow>
            </div>
            <div className="button-wrapper">
              <HideShow show={this.state.processing}>
                <ModalButtonCombo custom hide_cancel second="Processing.."/>
              </HideShow>
              <HideShow show={!this.state.processing}>
                <ModalButtonCombo custom first="Go Back" onClose={this.goBackToInvoice} second="Submit" submit={this.submitPayment}/>
              </HideShow>
            </div>
          </HideShow>
          <HideShow show={this.state.complete}>
            <div className="input-section margin-top">
              <div className="input-container">
                <AnimatedCheckmark/>
              </div>
            </div>
            <div className="button-wrapper">
              <ModalButtonCombo custom hide_cancel second="Done!" submit={this.routeToLogin}/>
            </div>
          </HideShow>
          <HideShow show={this.state.welcome}>
            <div className="header-container">
              <div className="header">Welcome</div>
              <div className="sub-header">We need to verify your information. Please enter your phone number below to receive a one time code.</div>
            </div>
            <div className="input-section">
              <div className="input-container">
              <Label label="Phone Number"/>
                <PhoneInput data-test-id="phone-2" className="merchant-input" name="phone" autoFocus onChange={this.onChange} required type="phone" ref={this.myRefs.phone}/>
              </div>
            </div>
            <div className="button-wrapper">
              <ModalButtonCombo custom first="Cancel" second="Send Code" onClose={this.goBack} submit={this.sendCode}/>
            </div>
            <div className="agreement">By pressing Send Code, you agree to receive text messages from {globalUtils.removeUnderscore(this.merchant_name)}</div>
          </HideShow>
          <HideShow show={this.state.confirm}>
            <div className="header-container">
              <div className="header">Check your texts</div>
              <div className="sub-header">We just sent a text message to {this.state.phone || '801-358-5542'} with the code to enter below</div>
            </div>
            <div className="input-section">
              <div className="input-container">
              <Label label="Verification Code"/>
                <Input className="merchant-input" name="code" autoFocus placeholder="code" onChange={this.onChange} ref={this.myRefs.code} required/>
              </div>
            </div>
            <div className="button-wrapper">
              <ModalButtonCombo custom first="Resend" second="Submit" onClose={this.resendCode} submit={this.verifyCode}/>
            </div>
          </HideShow>
          <HideShow show={this.state.registration}>
            <div className="header-container">
              <div className="header">Registration</div>
            </div>
            <div className="input-section">
              <div className="input-container">
              <Label label="Email"/>
                <Input className="merchant-input" name="email" autoFocus placeholder="johnsmith@gmail.com" type="email" onChange={this.onChange} required ref={this.myRefs.email}/>
              </div>
            </div>
            <div className="input-section">
              <div className="input-container">
              <Label label="Birthdate"/>
                <Date className="merchant-input"
                    name="birthdate"
                    data-test-id="birthdate"
                    placeholder="01/01/1970"
                    onChange={this.onChange}
                    required
                    ref={this.myRefs.birthdate}/>
              </div>
            </div>
            <div className="input-section">
              <div className="input-container">
              <Label label="Create your PIN"/>
                <Input className="merchant-input" name="pin" maxLength="4" type="password" onChange={this.onChange} required ref={this.myRefs.pin}/>
              </div>
            </div>
            <div className="input-section">
              <div className="input-container">
                <Label label="Enter PIN again"/>
                <Input className="merchant-input" name="confirm_pin" maxLength="4" type="password" onChange={this.onChange} required/>
              </div>
            </div>
            <div className="button-wrapper">
              <ModalButtonCombo custom first="Cancel" second="Register" onClose={this.startOver} submit={this.finalizeRegistration}/>
            </div>
          </HideShow>
          <HideShow show={this.state.done}>
            <div className="input-section margin-top">
              <div className="input-container">
                <AnimatedCheckmark/>
              </div>
            </div>
            <div className="button-wrapper">
              <ModalButtonCombo custom second="Sign In" onClose={this.startOver} submit={this.routeToLogin} hide_cancel/>
            </div>
          </HideShow>
        </div>
        <div className="powered">
          <div className="text">Powered by</div>
          <img className="logo" src={logo} alt="blytzpay logo"/>
        </div>
      </Background>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: (auth_data) => {dispatch(logout(auth_data))},
    isMerchant: (is_merchant) => {dispatch(isMerchant(is_merchant))},
    storeCustomerUsername: (username) => {dispatch(storeCustomerUsername(username))}
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(HostedPaymentLogin)
