import React from "react";
import { connect } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PublishIcon from "@material-ui/icons/Publish";
import { withStyles } from "@material-ui/core/styles";

// Redux
import {toggleTakePayment} from '../../../js/actions/modal_actions';

// API & Utils
import history from '../../../history';

// Assets
import { color_icon_btn_hover } from '../../../styles/colors';

const uploadStyles = {
  iconButton: {
  },
  uploadIcon: {
    '&:hover': {
      fill: color_icon_btn_hover
    }

  }
};

class UploadToolbar extends React.Component { 

  rerouteToUpload = () => {
    history.push('/admin/customers/upload');
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Tooltip title={"Upload"}>
          <IconButton className={classes.iconButton} data-test-id="upload-customer-publish" onClick={this.rerouteToUpload}>
            <PublishIcon className={classes.uploadIcon} />
          </IconButton>
        </Tooltip>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggleTakePayment: (bool) => {dispatch(toggleTakePayment(bool))}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(uploadStyles, { name: "UploadToolbar" })(UploadToolbar));