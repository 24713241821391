import React, {Component} from 'react';
import styled from 'styled-components';

//Components
import MerchantLogo from '../logos/MerchantLogo';
import HideShow from '../function/HideShow';

//Assets
import background from '../../assets/images/mobile_background.png';

const Background = styled.div`
  background: url(${background}) no-repeat center fixed;
  position: ${props => props.fixed ? 'fixed' : 'static'};
  background-size: cover;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 580px) {
    display: flex;
    justify-content: center;
  }
  @media only screen and (min-width: 768px) {
    flex-direction: column;
  }
`

const MobileLogoWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  padding: 0 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: ${props => props.show_logo ? '20px' : '50px'};
  @media (min-width: 581px) {
    display: none;
  }
`;

export default class MobileBackground extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <Background {...this.props} className="mobile-background">
        <HideShow show={this.props.show_logo}>
          <MobileLogoWrapper className="logo-wrapper" {...this.props}>
            <MerchantLogo showMerchantName={true} fullSize={this.props.fullSize ? true : null} logo={this.props.merchant_logo}/>
          </MobileLogoWrapper>
        </HideShow>
        {this.props.children}
      </Background>
    )
  }
}
