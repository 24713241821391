import React, {Component} from 'react';
import styled from 'styled-components';

const ToggleSwitchContainer = styled.div`
input.toggle-switch {
 position: relative;
 appearance: none;
 outline: none;
 width: ${props => props.small ? '35px' : '50px'};
 height: ${props => props.small ? '15px' : '25px'};
 background-color: #fff;
 border: 1px solid #D9DADC;
 border-radius: 50px;
 box-shadow: inset -20px 0 0 0 #fff;
 transition-duration: 200ms;
 margin-right: 3px;
 cursor: pointer;
}

input.toggle-switch:after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: ${props => props.small ? '14px' : '26px'};
 height: ${props => props.small ? '13px' : '23px'};
 background-color:
 transparent;
 border-radius: 50%;
 box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.2);
}

input.toggle-switch:checked {
 border-color: #06D6A0;
 box-shadow: inset 20px 0 0 0 #06D6A0;
}

input.toggle-switch:checked:after {
 left: 20px;
 box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
}
`;

export default class ToggleSwitch extends Component {
  constructor(props){
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }

  static getDerivedStateFromProps(props, state) {
    if(props.checked) {
      return {checked: true}
    } else {
      return {checked: false};
    }
  }

  render() {
    return (
      <ToggleSwitchContainer onChange={this.props.onChange} {...this.props}>
        <input data-test-id={this.props.name} className="toggle-switch" type="checkbox" name={this.props.name} id="toggle" checked={this.state.checked} small={this.props.small}/>
      </ToggleSwitchContainer>
    )
  }
}
