import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';

//Assets
import email_icon from '../../assets/icons/email_icon_gray.png';
import lock_icon from '../../assets/icons/lock_icon_gray.png';

//API
import loginAPI from '../../api/login/loginAPI';
// import userInfoAPI from '../../api/user/userInfoAPI';
import merchantAPI from '../../api/merchant/merchantAPI';

//Redux
import { loginMerchant, setUserId, logout, storeCustomerUsername, isMerchant, setUserInfo } from '../../js/actions/auth';
import { storeMerchantLogo, storeIntegrations } from '../../js/actions/merchants';
import { storeInboxBadge } from '../../js/actions/notifications';

//Components
import ErrorHandle from '../../components/function/ErrorHandle';
import Background from '../../components/display/BackgroundWithLogo';

const LoginButton = styled.div`
  height: 80px;
  width: 433px;
  border-radius: 4px;
  background-color: #fd7272;
  cursor: pointer;
  position: relative;
  @media (max-width: 1250px) {
    height: 50px;
    padding-top: 0px;
    font-size: 20px;
  }
  @media (max-width: 580px) {
    height: 50px;
    width: 250px;
    margin-left: 60px;
    margin-top: 40px;
  }
`

const LoginText = styled.div`
  color: white;
  font-size: 24px;
  padding-top: 25px;
  text-align: center;
  @media (max-width: 1250px) {
    font-size: 20px;
    line-height: 2.5;
    padding-top: 0px;
  }
  @media (max-width: 580px) {
    line-height: 2.3;
    padding-top: 0px;
    font-size: 18px;
  }
`

const CustomInput = styled.input`
  height: 63px;
  width: 435px;
  border-radius: 4px;
  text-indent: 80px;
  font-size: 20px;
  caret-color: #64ABF5;
  @media (max-width: 1250px) {
    height: 50px;
  }
`

const FlexContainer = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 5px;
  height: 410px;
  top: 255px;
  @media (max-width: 1400px) {
    padding-top: 13px;
  }
  @media (max-width: 1250px) {
    left: 140px;
    padding-top: 0px;
  }
  @media (max-height: 640px) {
    top: 125px;
  }
  @media (max-width: 580px) {
    left: 0px !important;
    top: 215px;
  }
`

const Icon = styled.img`
  height: auto;
  width: 40px;
  position: absolute;
  left: 25px;
  top: 10px;
  @media (max-width: 1250px) {
    top: 3px;
  }
  @media (max-width: 580px) {
    left: 10px;
    width: 35px;
    top: 13px;
  }
`

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
  @media (max-width: 580px) {
    input {
      width: 335px;
      text-indent: 55px;
      font-size: 18px;
    }
    input[type="password"].admin-login {
      text-indent: 55px;
    }
    margin-left: 20px;
  }
`

const ErrorContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`

const ForgotPassword = styled.div`
  color: white;
  font-size: 18px;
  position: absolute;
  right: 0px;
  margin-top: 5px;
  cursor: pointer;
  @media (max-width: 580px) {
    font-size: 16px;
  }
`

class LoginWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      error: [],
      login_attempted: false,
      password: '',
      merchant_login: true
    };
  }

  componentDidMount() {
    this.getUsername()
  }

  login = async () => {
    localStorage.setItem('username', this.state.username)
    let auth = await loginAPI.adminLogin({email: this.state.username.trim(), password: this.state.password, merchant_login: this.state.merchant_login})
    if (auth.err || +auth.status === 403) {
      this.setState({errors: "The username and/or password are not correct."})
      return
    }
    this.props.storeCustomerUsername(auth.username)
    if(auth.token) {
      let merchant = await merchantAPI.getSelf(auth.token);
      this.props.storeIntegrations(merchant.integrations);
      this.props.storeMerchantLogo(merchant.logo_url)
      this.props.isMerchant(true)
      this.props.storeInboxBadge(merchant.inbox_badge);
    }

    //
    // let self = await userInfoAPI.getSelf(auth.token);
    //
    // if (self.err && self.err.response) {
    //   this.setState({errors: self.err.response.data.detail})
    //   return
    // }
    // this.props.setUserId(self.user_id);
    let auth_info = {};
    auth_info.auth_token = auth.token;

    this.props.loginMerchant(auth_info);
    this.props.history.push('/admin/dashboard');
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      attempted_login: false,
    },
    this.update);
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      this.login();
    }
  }

  getUsername = () => {
    let username = localStorage.getItem('username');
    if (username) {
      this.setState({username})
    }
  }

  reRoute = () => {
    this.props.history.push('/forgot-password');
  }

  render() {
    return (
      <Background>
        <FlexContainer>
          <form>
            <ErrorContainer><ErrorHandle data={this.state.errors}/></ErrorContainer>
            <InputWrapper>
              <CustomInput placeholder="Email or username" id="username" name="username" value={this.state.username.trim()} onChange={this.handleChange} autoFocus data-test-id="login-email-usrname" onClick={this.clearStorage} autoComplete="username"/>
              <Icon src={email_icon}/>
            </InputWrapper>
            <InputWrapper>
              <CustomInput className="admin-login" placeholder="Password" type="password" data-test-id="password" name="password" value={this.state.password} onChange={this.handleChange} onKeyUp={this.handleEnter} autoComplete="current-password"/>
              <Icon src={lock_icon}/>
              <ForgotPassword data-test-id="login-forgot-pssword" onClick={this.reRoute}>Forgot Password?</ForgotPassword>
            </InputWrapper>
            <LoginButton className="py-login-button" data-test-id="login-btn" onClick={this.login} role="button" tabIndex="0"><LoginText>Log in</LoginText></LoginButton>
          </form>
        </FlexContainer>
      </Background>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
    merchant_id: state.auth.merchant_id,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loginMerchant: (auth_data) => {dispatch(loginMerchant(auth_data))},
    logout: () => {dispatch(logout())},
    setUserId: (user_id) => {dispatch(setUserId(user_id))},
    storeCustomerUsername: (username) => {dispatch(storeCustomerUsername(username))},
    setUserInfo: (info) => {dispatch(setUserInfo(info))},
    storeIntegrations: (integrations) => {dispatch(storeIntegrations(integrations))},
    storeMerchantLogo: (logo) => {dispatch(storeMerchantLogo(logo))},
    storeInboxBadge: (inbox_badge) => {dispatch(storeInboxBadge(inbox_badge))},
    isMerchant: (is_merchant) => {dispatch(isMerchant(is_merchant))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginWrapper)
