import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'hosted_payment/';
let logo = 'get_logo';
let code = 'get_code';
let verify = 'verify_code/';
let register = 'register';

class hostedPaymentAPI {
  getLogo = (merchant_name, diff) => {
    return apiUtils.getCustom(url + logo + '/' + merchant_name + '/' + diff);
  };
  getCode = (data) => {
    return apiUtils.postCustom(url + code, data);
  };
  verifyCode = (merchant_name, diff, code, phone) => {
    return apiUtils.getCustom(url + verify + merchant_name + '/' + diff + '/' + code + '/' + phone);
  }
  register = (data) => {
    return apiUtils.postCustom(url + register, data);
  };
}

export default new hostedPaymentAPI();
