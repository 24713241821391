import React from "react";
import { connect } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PublishIcon from "@material-ui/icons/Publish";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { withStyles } from "@material-ui/core/styles";

// Redux
import {toggleTakePayment} from '../../../js/actions/modal_actions';

// API & Utils
import history from '../../../history';
import { color_icon_btn_hover } from "../../../styles/colors";

const uploadStyles = {
  toolbarIcon: {
    '&:hover': {
      fill: color_icon_btn_hover
    }
  },
};

class UploadToolbar extends React.Component { 

  rerouteToUpload = () => {
    history.push('/admin/invoices/upload');
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Tooltip title={"Upload"}>
          <IconButton data-test-id="invoice-toolbar-upload" onClick={this.rerouteToUpload}>
            <PublishIcon className={classes.toolbarIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Payment Plan"}>
          <IconButton data-test-id="invoice-toolbar-pmt-plan" onClick={ () => this.props.toggleModal('showPlanModal') }>
            <EventNoteIcon className={classes.toolbarIcon} />
          </IconButton>
        </Tooltip>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggleTakePayment: (bool) => {dispatch(toggleTakePayment(bool))}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(uploadStyles, { name: "UploadToolbar" })(UploadToolbar));