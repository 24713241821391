import React, {Component} from 'react';
import styled from 'styled-components';
import chevron_right from '../../assets/icons/left_arrow_icon_gray.png';
import merchant_icon from '../../assets/icons/merchant_icon_gradient.png';

const Container = styled.div`
  width: 338px;
  height: 57px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #ADADAD;
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  .icon {
    height: 30px;
    width: auto;
    position: absolute;
    top: 12px;
    left: 13px;
  }
  .logo {
    height: 30px;
    width: auto;
    margin-bottom: 5px;
  }
  .merchant-name {
    color: #292929;
    font-size: 14px;
    margin-left: 33px;
  }
  .right-arrow {
    transform: rotate(180deg);
    height: 18px;
    width: auto;
    position: absolute;
    right: 10px;
  }
`

export default class MerchantCard extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  renderLogo = () => {
    if (this.props.merchant && this.props.merchant.merchant_logo) {
      return this.props.merchant.merchant_logo;
    } else {
      return '';
    }
  }

  renderName = () => {
    if (this.props.merchant && this.props.merchant.merchant_name) {
      return this.props.merchant.merchant_name
    } else {
      return '';
    }
  }

  renderIcon = () => {
    if (this.props.merchant && this.props.merchant.merchant_name) {
      return (
        <img className="icon" src={merchant_icon} alt="merchant"/>
      )
    } else {
      return '';
    }
  }


  render() {
    return (
      <Container data-test-id={"merchant-card-" + this.props.key} onClick={this.props.onClick} tabIndex="0">
        {this.renderIcon()}
        <div className="merchant-name">{this.renderName()}</div>
        <img className="right-arrow" src={chevron_right} alt="select merchant"/>
      </Container>
    )
  }
}
