import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'subscriptions';
let customField = '_subscription_custom_field';

class tagsAPI {
  addTags = (auth_token, tags) => {
    return apiUtils.post(`${url}/add${customField}`, {tags}, auth_token)
  };

  addLookups = (auth_token, sub_id, lookups) => {
    // Requires data to have parent and custom_field_id properties
    return apiUtils.post(`${url}/${sub_id}/add_subscription_custom_lookup`, {lookups}, auth_token)
  };

  deleteTag = (auth_token, sub_id, data) => {
    // Requires data to have field_id property
    return apiUtils.post(`${url}/${sub_id}/delete${customField}`, data, auth_token)
  };

  deleteTagLookup = (auth_token, sub_id, data) => {
    // Requires data to have field_lookup_id property
    return apiUtils.post(`${url}/${sub_id}/delete_subscription_custom_field_lookup`, data, auth_token)
  };

  getMerchantTags = (auth_token, merchant_id) => {
    return apiUtils.get(`${apiUtils.baseUrl}merchants/${merchant_id}/tags`, auth_token)
  }

  getSubTags = (auth_token, sub_id) => {
    return apiUtils.get(`${url}/${sub_id}/get${customField}s`, auth_token);
  };
}

export default new tagsAPI();
