import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'payment_plans';

class SmsOptIn {
  getDetail = (id , auth_token, callback) => {
    return apiUtils.get(url + '/' + id, auth_token, callback);
  }
}

export default new SmsOptIn();
