import React, {Component} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  /* position: fixed; */
  margin-top: ${props => props.fixed ? '70px' : null};
  height: auto;
  width: 100%;
  @media (max-width: 580px) {
  overflow: hidden;
  }
  
`

export default class MobileWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <Wrapper {...this.props}>{this.props.children}</Wrapper>
    )
  }
}
