import React from 'react';
import {AreaChart, Area} from 'recharts';

class AmountCollectedChart extends React.Component{
  constructor(props) {
    super(props);
		this.state = {}

  }

  render () {
  	return (
    	<AreaChart width={200} height={60} data={this.props.data}
            margin={{top: 10, right: 0, left: 15, bottom: 5}}>
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%"   stopColor="#f5af19"/>
            <stop offset="100%" stopColor="#f12711"/>
          </linearGradient>
        </defs>
        <Area type='monotone' dataKey='amt' stroke='url(#linear)' fill='url(#linear)' />
      </AreaChart>
    );
  }
};

export default AmountCollectedChart;
