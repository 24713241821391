import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import background_image from '../../assets/images/new-background.png';
import blytz_logo from '../../assets/images/logo-white.png';

const BackgroundContainer = styled.div`
  background: url(${background_image}) no-repeat center center fixed;
  position: fixed;
  background-size: cover;
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    background-position: inherit !important;
  }
  @media (max-width: 580px) {
    background-position: inherit !important;
    position: ${props => props.mobileScroll ? 'static' : 'fixed'};
  }
`;


const BlytzLogo = styled.img`
  position: absolute;
  width: 218px;
  height: auto;
  top: 15px;
  @media (min-height: 736px) {
    top: 45px;
  }
  @media (min-width: 768px) {
    width: 300px;
    top: 30px;
  }
`;

class Background extends Component {

  render() {
    return (
      <BackgroundContainer {...this.props}>
        {this.props.children}
        <BlytzLogo src={blytz_logo}/>
      </BackgroundContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return { merchant_logo: state.merchants.merchant_logo };
};

export default connect(mapStateToProps)(Background);
