import {
  SET_FROM_INVOICE,
  STORE_FROM_RECEIPT,
  TOGGLE_PAYMENT_TYPES,
  SET_PAYMENT_SLOT,
  CLEAR_PAYMENT_METHODS,
  SET_SPLIT_METHOD
} from '../actions/invoices';

const initialState = {
  from_invoice: undefined,
  from_receipt: undefined,
  show_payment_types: false,
  split_payment: false,
  payment_slot: undefined,
  payment_methods: { method_one: {}, method_two: {} }
}

const invoices = (state = initialState, action) => {
  switch (action.type) {
    case SET_FROM_INVOICE:
      let object = Object.assign({}, state, { from_invoice: action.payload });
      return object
    case STORE_FROM_RECEIPT:
      return Object.assign({}, state, { from_receipt: action.payload });
    case TOGGLE_PAYMENT_TYPES:
      if(action.target === 'split-payment') {
        return Object.assign({}, state, { show_payment_types: action.payload, split_payment: !state.split_payment})
      } else return Object.assign({}, state, { show_payment_types: action.payload });
    case SET_PAYMENT_SLOT:
      return Object.assign({}, state, { payment_slot: action.payload })
    case SET_SPLIT_METHOD:
      let updatedPaymentMethods = Object.assign({}, state.payment_methods);
      updatedPaymentMethods[state.payment_slot] = action.payload;
      return Object.assign({}, state, {payment_methods: updatedPaymentMethods})
    case CLEAR_PAYMENT_METHODS:
      return Object.assign({}, state, { payment_methods: action.payload, payment_slot: undefined })
      default:
      return state;
  }
};

export default invoices;
