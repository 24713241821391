import React from "react";
import { connect } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PaymentIcon from "@material-ui/icons/Payment";
import { withStyles } from "@material-ui/core/styles";

// Redux
import {toggleTakePayment} from '../../../js/actions/modal_actions';
// Assets
import { color_icon_btn_hover } from '../../../styles/colors';

const defaultToolbarStyles = {
  toolbarIcon: {
    '&:hover': {
      fill: color_icon_btn_hover
    }
  },
};

class PaymentsToolbar extends React.Component {
  
  handleClick = () => {
    this.props.toggleTakePayment(true)
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Tooltip title={"Take Payment"}>
          <IconButton id="icon-btn" data-test-id="payments-take-payment" onClick={this.handleClick}>
            <PaymentIcon id="icon" className={classes.toolbarIcon} />
          </IconButton>
        </Tooltip>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggleTakePayment: (bool) => {dispatch(toggleTakePayment(bool))}
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(defaultToolbarStyles, { name: "PaymentsToolbar" })(PaymentsToolbar));