import React from 'react';
import styled from 'styled-components';

import spinner from '../../../assets/images/blytz-spinner.gif';

const GradientBG = styled.div`
  background: linear-gradient(315.36deg, #8D97AD 0%, #545E75 100%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.img`
  height: 250px;
  width: auto;
`;

function LoadingScreen(props) {
  return(
    <GradientBG>
      <Loader src={spinner} alt="loading..."/>
    </GradientBG>
  )
}

export default LoadingScreen;