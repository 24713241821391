import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import HideShow from '../../components/function/HideShow';

const Input = styled.input`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.heigth : 'auto'};
    border: 2px solid transparent;
    margin-bottom: 5px;
    border-bottom: 1px solid #a4a4a4;
    :focus {
        border: 2px solid #64abf5;
        outline: none;
    }
`

const Label = styled.label`
    font-size: 15px;
    color: #5D5A61;
    margin-bottom: 5px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

function NumberInput(props) {

    const enforceMinMax = e => {
        let {value} = e.target
        if (isNaN(parseInt(value))){
            e.target.value = ""
        } else if (props.min && parseInt(value) < parseInt(props.min)){
            e.target.value = props.min.toString()
        } else if (props.max && parseInt(value) > parseInt(props.max)){
            e.target.value = props.max.toString() 
        } 
        props.onChange(e, true)
    }

    return (
        <Container>
            <HideShow show={props.label}>
                <Label for={props.name}>{props.label}</Label>
            </HideShow>
            <Input width={props.width} height={props.height} name={props.name} type="number" value={props.value} onChange={e => props.onChange(e, true)} placeholder={props.placeholder} min={props.min} max={props.max} onBlur={e => enforceMinMax(e)}/>
        </Container>
        
    )
}

NumberInput.propTypes = {
    onChange: PropTypes.func.isRequired
}

export default NumberInput

