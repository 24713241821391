import React, {Component} from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/logo-gradient.png';
import background_image from '../../assets/images/new-background.png'

const ContentWrapper = styled.div`
  background: url(${background_image}) no-repeat center center fixed;
  position: fixed;
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
  .left {
    width: 50%;
    display: flex;
    height: 100%;
    float: left;
  }
  .right {
    float: right;
    width: 50%;
    height: 100%;
    @media (max-width: 1250px) {
      float: left;
    }
  }
  @media (max-width: 580px) {
    background-position: inherit !important;
  }
`

// const BackgroundImage = styled.img`
//   height: 100%;
//   width: 100%;
//   position: fixed;
//   z-index: 0;
// `

const BlytzLogo = styled.img`
  height: auto;
  width: 400px;
  position: absolute;
  top: 320px;
  left: 220px;
  @media (max-width: 1400px) {
    top: 250px;
  }
  @media (max-width: 1250px) {
    top: 120px;
    left: 168px;
    width: 325px;
  }
  @media (max-height: 640px) {
    top: 25px;
  }

  @media (max-width: 580px) {
    left: 37px;
    width: 300px;
  }
`

const LogoWrapper = styled.div`
  @media (max-width: 580px) {
    margin-top: 0px;
    margin-right: 0px;
  }
`

export default class BackgroundWithLogo extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <ContentWrapper>
        <div className="left">
          <LogoWrapper>
            <BlytzLogo src={logo}/>
          </LogoWrapper>
        </div>
        {/*<BackgroundImage src={background_image}/>*/}
        <div className="right">
          {this.props.children}
        </div>
      </ContentWrapper>
    )
  }
}
