import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + 'auth/';
let reset = 'reset_password';
let forgot = 'forgot_password'

class passwordAPI {
  forgotPassword = (email, auth_token, user_pin, callback) => {
    if (email === undefined){
      return {err: 'Email is required'};
    }
    return apiUtils.post(url + forgot, {email:email}, callback);
  };
  resetPassword = (data, auth_token, user_pin, callback) => {
    return apiUtils.post(url + reset, data, callback);
  };
  changePassword = (data, user_id, auth_token, user_pin, callback) => {
    let changeUrl = apiUtils.baseUrl + 'users/change_password'
    return apiUtils.post(changeUrl, data, auth_token, user_pin, callback);
  };
}

export default new passwordAPI();

// http://localhost:8000/api/v1/users/change_password
