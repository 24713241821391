import React, {Component} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import ModalBreadcrumb from '../../../components/modals/ModalBreadcrumb';
import BottomButton from '../../../components/buttons/mobile/BottomButton';
import globalUtils from '../../../utils/globalUtils';

const Container = styled.div`
  .open {
    transform: translateY(0);
  }
  .closed {
    transform: translateY(100%);
  }
  .container {
    transition: transform .5s ease-out;
    height: 100%;
    width: 100vw;
    background: linear-gradient(315.36deg, #8D97AD 0%, #545E75 100%);
    position: fixed;
    top: 0;
    z-index: 3;
    .modal {
      z-index: 2;
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%,-50%);
      height: auto;
      width: 282px;
      border-radius: 3px;
      background-color: #FFFFFF;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px 0 rgba(204, 204, 204, 0.99);
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        position: relative;
        background-color: #12FFCB;
        color: #F0F8FF;
      }
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background-color: #12FFCB;
      }
      .DayPicker-Day {
        display: table-cell;
        padding: 0.5em;
        border-radius: 50%;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        outline: none;
      }
      .DayPicker-wrapper {
        position: relative;
        flex-direction: row;
        padding-bottom: 1em;
        user-select: none;
        outline: none
      }
      .DayPicker-Day--today {
        color: #5A9DEB;
        font-weight: 700;
      }

      @media (min-device-height: 668px) {
        top: 44%;
      }
    }

  }
`

const WhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  height: 53vh;
  width: 100vw;
  background-color: #FFFFFF;
  z-index: 1;
  @supports (-webkit-overflow-scrolling: touch) {
    padding-top: 210px;
    height: 52vh;
  }
  p {
    color: #5A9DEB;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    position: relative;
    bottom: 90px;
    @media (min-device-height: 668px) {
      bottom: 140px;
    }
    @media (min-width: 768px) {
      bottom: 200px;
      font-size: 25px;
    }
  }
  @media (min-device-height: 668px) {
    padding-top: 140px;
  }
`;

const Today = new Date();


export default class SchedulePayment extends Component {
  constructor(props){
    super(props);
    this.state = {
      selected: this.props.scheduled_date ? new Date(this.props.scheduled_date) : Today,
      open: false
    };
  }

  componentDidMount() {
    setTimeout( () => {
      this.setState({open: true})
    }, 10)
  }

  handleDayClick = (day) => {
    this.setState({
      selected: day
    })
  }

  goBack = () => {
    this.setState({open: false})
    setTimeout( () => {
      this.props.back()
    }, 510)
  }

  selectDay = () => {
    this.setState({open: false})
    this.props.selectDay(this.state.selected)
    setTimeout( () => {
      this.props.back()
    }, 510)
  }


  render() {
    return (
      <Container>
        <div className={classnames("container", {"open": this.state.open}, {"closed": !this.state.open})}>
          <ModalBreadcrumb page="SELECT A DATE" back={this.goBack}/>
          <div className="modal">
            <DayPicker
                onDayClick={this.handleDayClick}
                selectedDays={this.state.selected}
                disabledDays={{ before: Today }}
                />
          </div>
          <WhiteBox>
            <p>Your payment will be submitted on {globalUtils.formatDate(this.state.selected)}</p>
          </WhiteBox>
          <BottomButton absolute={true} data-test-id="schedule-pmt-select" onClick={this.selectDay}>Select</BottomButton>
        </div>
      </Container>
    )
  }
}
