import React, {Component} from 'react';
import styled from 'styled-components';
import globalUtils from '../../utils/globalUtils';

const Bubble = styled.div`
  .sms-message-body {
    float: right;
    color: #fff;
    padding: 20px;
    background-color: #1989FE;
    border-radius: 17px;
    width: 70%;
    position: relative;
    word-wrap: break-word;
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: -3px;
      right: 0px;
      width: 10px;
      height: 11px;
      background: #1989FE;
      border-bottom-left-radius: 10px;
      transform: rotate(-40deg);
    }
    .sms-date {
      color: #8E8E93;
      font-size: 14px;
      position: absolute;
      bottom: -17px;
      right: 5px;
    }
  }
`


export default class MessageSentBubble extends Component {
  render() {
    return (
      <Bubble>
        <div className="sms-message-body">
          {this.props.body}
          <div className="sms-date">{globalUtils.formatDateAndTime(this.props.created_at)}</div>
        </div>
      </Bubble>
    )
  }
}
