import React, {Component} from 'react';
import styled from 'styled-components';

const CardIframe = styled.iframe`
  max-height: 34px;
  display: flex;
  align-items: center; 
  justify-content: center;
`

const TokenForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  transform: translateY(-6px);
`

export default class CardConnect extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let token = ''
    window.addEventListener('message', (event) => {
      token = event.data;
      if (token && typeof token === 'string') {
        token = JSON.parse(event.data)
        if (token.validationError) {
          this.props.setToken(token);
        }
        this.props.setToken(token.message);
      }
    }, false);
  }
  
  getCss = () => {
    const baseCss = {
      input: `
        border: 0px solid white;
        width: 100%;
        font-size: 20px;
        outline: none;
        z-index: 3;
        margin-left: -8px;
        margin-top: -2px;
        background: transparent;
        height: 32px;
        ${this.props.inputStyles || ''}
      `,
      '.error': `
        color: red;
        ${this.props.errorStyles || ''}
      `
    }

    const rules = Object.entries(baseCss).map(entry => entry[0] + `{${entry[1]}}`)
    const queryString = rules.join('')
      .replace(/(\r\n|\r|\n)/g, '')
      .replace(/[ ]{2,}/g, ' ')

    return encodeURI(queryString)
  }


  renderIframe = () => {
    let ccUrl = window.location.origin === 'https://app.blytzpay.com' ? "" : ":6443";
    // let color;
    // if (this.props.donation) {
    //   color = 'white';
    // } else {
    //   color = 'black';
    // }
    this.getCss()
    // const ugh = 'input{border:0px solid white; width: 100%25; font-size: 20px; outline: none; z-index: 3; margin-left: -8px; margin-top: -2px;}%2Eerror{color: red;}'
    let final_url = `https://fts.cardconnect.com${ccUrl}/itoke/ajax-tokenizer.html?css=${this.getCss()}&formatinput=true&invalidinputevent=true&autofocus=true&tokenizewheninactive=true&inactivityto=1700`
    // let final_url = `https://fts.cardconnect.com${ccUrl}/itoke/ajax-tokenizer.html?css=input{color: ${color}; background: inherit; width: 100%25; height: 40px; border: 2px solid ${color}; border-radius: 5px; font-size: 18px; outline: none; padding-bottom: 3px;}%2Eerror{color: red;}&formatinput=true&invalidinputevent=true&autofocus=true&tokenizewheninactive=true&inactivityto=1700`

    return (
      <CardIframe title="CardConnect" id="tokenFrame" name="tokenFrame" src={final_url} frameBorder="0" scrolling="no"/>
    )

  }

  render() {
    return (
      <TokenForm name="tokenform" id="tokenform">
        {this.renderIframe()}
      </TokenForm>
    )
  }
}
