import React, {Component} from 'react';
import { connect } from "react-redux";
import styled, { keyframes } from 'styled-components';
import classnames from 'classnames';
// import { Link } from 'react-router-dom';

//Redux
import { setUserId, setPaymentMethod, logout, storeCustomerUsername } from '../../../js/actions/auth';
import { setFromInvoice, togglePaymentTypes } from '../../../js/actions/invoices';
import { storeMerchantName, storeAchInfo } from '../../../js/actions/merchants';
import { setNotification } from '../../../js/actions/notifications';

//API
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';
import paymentAPI from '../../../api/payments/paymentAPIs';
import PaymentMethodAPI from '../../../api/blytzwallet/PaymentMethod';
import globalUtils from '../../../utils/globalUtils';
import merchantAPI from '../../../api/merchant/merchantAPI';
import userAPI from '../../../api/customer/userAPI';

//Components
import HideShow from '../../../components/function/HideShow';
import LoadingScreen from '../Invoice/LoadingScreen';
import MerchantLogo from '../../../components/logos/MerchantLogo';
import Button from '../../../components/buttons/mobile/PayButton';
import CurrencyInput from '../../../components/mobile/inputs/MobileCurrencyInput';
import ErrorHandle from '../../../components/function/ErrorHandle';
import SchedulePayment from '../Invoice/SchedulePayment';
import InvoiceDetails from '../Invoice/InvoiceDetails';
import SplitPayment from '../Invoice/SplitPayment';
import CustomerAutopay from '../Autopay/CustomerAutopay';
import PaymentOptionsModal from '../../../components/mobile/PaymentOptionsModal';
import SelectPaymentType from '../BlytzWallet/SelectPaymentType';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedoAlt, faCheck} from '@fortawesome/free-solid-svg-icons'
// import Modal from '../../../components/mobile/NewMobileModal'

//Assets
import chevron from '../../../assets/icons/chevron_left_blue.png';
import card_icon from '../../../assets/icons/card_gradient.png';
import check_icon from '../../../assets/icons/check_gradient.png';
import calendar from '../../../assets/icons/calendar.dca9feac.png';
import split_payment from '../../../assets/icons/split_payment.1481e531.png';
import { color_invoice_grey_text } from '../../../styles/colors';

var innerHeight = require('ios-inner-height');

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* height: ${innerHeight}px; */

  .center {
    margin: 0 auto;
  }
  .lower-half {
    background-color: transparent !imporant;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 10vh;
    position: fixed;
    bottom: 9vh;
    .icon-wrapper {
      position: relative;
      height: 100%;
      .button-text {
        position: absolute;
        min-width: 100px;
        bottom: 4px;
        color: #ffffff;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        height: 15px;
      }
      .icon {
        height: 6.36vh;
        width: auto;
        cursor: ponter;
      }
      .auto-pay {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        box-sizing: border-box;
      }
      .font-awe-icon {
        font-size: 4vh;
        color: #ffffff;
        margin-top: 1.25vh;
      }
      .font-awe-check {
        color: #00D973;
        position: absolute;
        top: 9px;
        left: -14px;
      }
      .schedule-method {
        .schedule {
          cursor: pointer;
          position: relative;
          border-radius: 3px 0 0 3px;
          color: #4B98EA;
          font-size: 11px;
          line-height: 12px;
          .selected-date {
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #63A1EB;
            position: relative;
            border: 1px solid #4B98EA;
            height: 50px;
            width: 50px;
            margin-top: 3px;
            .month {
              font-size: 15px;
              color: #fff;
              background-color: #63A1EB;
              width: 100%;
              text-align: center;
              border-top-left-radius: 3px;
              margin-bottom: 9px;
              padding: 4px;
            }
            .day {
              height: 26px;
              width: 32px;
              font-size: 30px;
              font-weight: 500;
              text-align: center;
            }
            .scheduled-text {
              position: absolute;
              top: 52px;
              width: 142px;
              text-align: center;
              font-size: 12px;
            }
          }
          img {
            height: 5.8vh;
          }
        }
      }
      span {
        color: #66a6ee;
        position: absolute;
        font-size: 1.56vh;
        top: 6.53vh;
        left: -2px;
        text-align: center;
      }
    }
  }
  .button-wrapper {
    height: 9vh;
    background: rgba(1,217,210,1);
    background: -moz-linear-gradient(left, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(1,217,210,1)), color-stop(100%, rgba(0,255,200,1)));
    background: -webkit-linear-gradient(left, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
    background: -o-linear-gradient(left, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
    background: -ms-linear-gradient(left, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
    background: linear-gradient(to right, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#01d9d2', endColorstr='#00ffc8', GradientType=1 );
    position: fixed;
    bottom: 0;
    width: 100%;
    #payButton {
      font-size: 3.4vh;
      font-weight: bold;
      background: rgba(1,217,210,1);
      background: -moz-linear-gradient(left, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(1,217,210,1)), color-stop(100%, rgba(0,255,200,1)));
      background: -webkit-linear-gradient(left, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
      background: -o-linear-gradient(left, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
      background: -ms-linear-gradient(left, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
      background: linear-gradient(to right, rgba(1,217,210,1) 0%, rgba(0,255,200,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#01d9d2', endColorstr='#00ffc8', GradientType=1 );
    }
  }
`;

const GradientBackground = styled.div`
  background: linear-gradient(315.36deg, #8D97AD 0%, #545E75 100%);
  width: 100vw;
  height: 91vh;

  .grey {
    color: ${color_invoice_grey_text}
  }
  .white {
    color: #FFFFFF;
  }
  .cancel-payment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

  }
  .payment-scheduled {
    color: #FFFFFF;
    font-size: 31px;
    letter-spacing: -0.69px;
    line-height: 52px;
  }
`;

const InvoiceDetailContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .invoice-link {
    transform: translate(0, 50%);
    color: #FFFFFF;
    text-decoration: underline;
    font-size: 1.8vh;
    line-height: 15px;
    text-align: center;
    text-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    cursor: pointer;
    margin: 5px;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    @media screen and (max-device-height: 847px) {
      margin-top: 0px;
    }
  }
`;

const PayButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.85vh;
  width: 100vw;
`;

const AmountText = styled.span`
  text-align: center;
  font-size: 2.3vh;
  font-weight: 500;
  text-transform: uppercase;
`;

const ErrorContainer = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  top: 5px;
  @supports (-webkit-overflow-scrolling: touch) {
    @media (max-device-height: 667px) {
      top: 2px;
    }
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .partner-logo {
    display: flex;
    align-items: center;
    margin-top: 3vh;
  }
`

const MerchantBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2vh;
  .due-container {
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    @media (min-device-height: 736px) {
      margin-top: 20px;
    }
    .amount-due {
      color: #12FFCB;
      font-size: 40px;
      letter-spacing: -0.51px;
      line-height: 49px;
      text-align: center;
    }
  }
`

// const BoxSeparator = styled.hr` width: 95%; border: 1px solid #A3A6AF; `;

const blink = keyframes`
  0% {
    background-color: #FFFFFF;
  }

  50% {
    background-color: #12FFCB;
    color: white;
  }

  100% {
    background-color; #FFFFFF;
  }
`;

const PaymentBox = styled.div`
  input::placeholder {
    color: ${color_invoice_grey_text};
  }
  margin-top: 1.23vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  clear: both;
  @media (min-device-height: 736px) {
    margin-top: 3.08vh;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    @media (max-device-height: 667px) {
      margin-top: 0.62vh;
    }
  }
  input {
    color: #FFFFFF;
    background-color: transparent;
    font-size: 4.7vh;
    letter-spacing: -0.69px;
    line-height: 52px;
    border: none;
    width: auto;
    max-width: 300px;
    @media screen and (device-aspect-ratio: 40/71) {
      line-height: 1.2;
    }
  }
  .separator {
    height: 2px;
    width: 183px;
    border: 3px solid #FFFFFF;
  }
  .due-text {
    color: #FFFFFF;
    font-size: 2vh;
    font-weight: 600;
    letter-spacing: 0.93px;
    line-height: 6px;
    text-align: center;
    margin-top: 0.62vh;
    margin-bottom: 3vh;
  }
  .greyed-out {
    opacity: .6;
  }
  .blink {
    animation: ${blink} 1s ease-in-out 3;
  }
  .payment-option {
      height: 6.78vh;
      min-height: 50px;
      max-height: 55px;
      width: 325px;
      max-width: calc(100vw - 40px);
      position: relative;
      cursor: pointer;
      border-radius: 3px;
      background-color: #FFFFFF;
      transition: border;
      .chevron {
        height: 18px;
        width: auto;
        position: absolute;
        transform: rotate(180deg);
        right: 13px;
        top: 18px;
        cursor: pointer;
      }
      .card-icon {
        height: 49px;
        width: auto;
        position: absolute;
        left: 4px;
        top: 2px;
      }
      .card-no {
        position: absolute;
        font-size: 14px;
        color: #2D2D2D;
        left: 55px;
        top: 10px;
        font-weight: 600;
      }
      .card-type {
        color: #616161;
        font-size: 13px;
        position: absolute;
        left: 55px;
        bottom: 12px;
      }
      .add-card {
        color: #545E75;
        font-size: 14px;
        line-height: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 60px;
      }
    }
`;

const AmountDueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AmountDueText = styled.div`
    font-size: 4.7vh;
    color: #7ff6f5;
`

const PageBreak = styled.hr`
    background-color: #A0A6B3;
    max-width: calc(100vw - 40px);
    border-bottom: 0.3px;
    margin-top: 20px;
`

class CustomerInvoice extends Component {
  constructor(props){
    super(props);
    this.state = {
      invoice: {
        merchant: {}
      },
      display_date: "",
      showPaymentOptionsModal: false,
      selected_method: {},
      ready_status: true,
      showSchedulePayment: false,
      showInvoiceDetails: false,
      animateBox: false,
      loaded: false,
      showSplitPayment: false,
      showAutoPay: false,
      ach_creds: false,
      scheduled_date: null,
      autopay: {}
    }
    const values = globalUtils.getQueryString(this.props.location.search.slice(1))

    this.props.setFromInvoice(this.props.match.params.id)
    if (values.username){
      this.props.storeCustomerUsername(values.username)
    }
    if (values.merchant_name){
      this.props.storeMerchantName(values.merchant_name)
    }
  }

  componentDidMount() {
    if (this.props.auth_token){
      this.getData();
    }
  }

  getData = async () => {
    this.getPaymentOptions()
    var invoice = await userInvoiceAPI.getCustomerInvoice(this.props.match.params.id, this.props.auth_token)
    if (invoice.err && invoice.err.response) {
      // this.props.logout()
      this.props.setNotification({type: 'error', message: 'Unable to find invoice'})
      this.props.history.push('/customer/dashboard')
      return;
    } else if (invoice["errors"]){
      let errorText = String(invoice.err);
      this.setState({errors: errorText});
      window.scroll(0,0);
      return;
    }
    else {
      this.setState({
        display_date: invoice.due_date,
      })
    }
    this.props.setUserId(invoice.user)
    this.setState({
      invoice: invoice
    }, this.hideLoader)
    if (invoice.ach_on) {
      let ach_creds = await merchantAPI.getAchCreds(this.props.auth_token, this.props.match.params.id);
      if (ach_creds.errors) {
        return;
      }
      this.setState({ ach_creds })
      this.props.storeAchInfo({
        ach_id: ach_creds.ach_id,
        ach_signature: ach_creds.ach_signature});
    }
    let subscriptions = await userAPI.getList(this.props.auth_token);
    if (subscriptions.err && subscriptions.err.response){
      this.props.setNotification({type: 'error', message: 'Unable to get subscriptions'})
      return
    } else {
      let currentSubscription = subscriptions.filter(sub => sub.id === invoice.subscription.id)[0]
      let autopay = {
        autopay_enabled: currentSubscription.autopay_enabled,
        autopay_amount: currentSubscription.autopay_amount,
        autopay_day: currentSubscription.autopay_day, 
        autopay_frequency: currentSubscription.autopay_frequency,
        autopay_method: currentSubscription.autopay_method
      }
      this.setState({autopay})
    }
  }

  hideLoader = () => {
    setTimeout(() => {
      if (this.state.invoice.state==="paid"){
        this.props.history.push('/customer/receipts/paid/' + this.props.match.params.id)
      } else {
        this.setState({
          loaded: true
        })
      }
    }, 1000)
  }

  updatePaid = (resp) => {
    if (resp.err) {
      this.setState({errors: resp.err.response.data.detail});
      window.scroll(0,0);
      return
    }
    if (resp.data && !resp.err && +resp.data.status_code === 200){
      var undef;
      this.props.setPaymentMethod(undef)
      this.props.setFromInvoice(undef)
      this.getData()
    }
  }

  resetAnimation = () => {
    setTimeout( () => {
      this.setState({animateBox: false})
    }, 3100)
  }

  pay = async () => {
    if (!this.state.selected_method.id) {
      this.setState({animateBox: true}, this.resetAnimation)
      return
    }
    this.setState({ready_status: false});
    let data = {};
    let { invoice } = this.state;
    data.id = this.state.selected_method.id;
    data.invoice = this.props.match.params.id;
    data.amount_paid = this.state.amount_paid ? this.state.amount_paid : invoice.amount_paid ? invoice.amount - +invoice.amount_paid : invoice.amount;
    if(this.state.scheduled_date) {
      data.scheduled_date = this.state.scheduled_date;
    }
    let resp = await paymentAPI.payOnceWithCard(data, this.props.auth_token, this.props.user_pin);
    if (resp.err) {
      if(resp.err.response) {
        this.setState({errors: resp.err.response.data.detail, ready_status: true})
        window.scroll(0,0);
        return
      } else if(resp.err.message) {
        this.setState({errors: resp.err.message, ready_status: true})
        window.scroll(0,0);
        return
      }
    }
    if (resp && !resp.err && resp.paid_in_full) {
      this.props.history.push('/customer/receipts/paid/' + this.props.match.params.id);
      return;
    }
    window.scroll(0,0);
    this.props.setNotification({message: 'Payment successful', mobile: true})
    this.getData();
    this.updatePaid(resp);
    this.setState({ready_status: true, errors: undefined});
  }

  unschedule = async () => {
    let data = {invoice: this.props.match.params.id}
    let resp = await paymentAPI.unschedule(data, this.props.auth_token, this.props.user_pin);
    if (resp.err && resp.err.response) {
      this.setState({errors: resp.err.response.data.detail});
      return
    }
    this.setState({scheduled_date: null})
    this.getData()
    this.props.setNotification({message: 'Payment successfully unscheduled', mobile: true});
  }

  reroute = (url) => {
    if (url === 'check') {
      this.props.history.push('/customer/blytzwallet/pay-with-check');
    } else if (url === 'card') {
      this.props.history.push('/customer/blytzwallet/select-card');
    } else if (url === 'cash') {
      this.props.history.push('/customer/blytzwallet/pay-with-cash');
    } else if (url === 'recurring') {
      this.props.history.push('/customer/blytzwallet/schedule-payment');
    } else {
      return;
    }
  };

  togglePaymentOptions = () => {
    this.setState(prevState => {
      return {
        showPaymentOptionsModal: !prevState.showPaymentOptionsModal,
      };
    });
  };

  showModal = () => {
    if (this.state.showPaymentOptionsModal) {
      return (
        <PaymentOptionsModal context="customer-invoice" renderPaymentOptions={this.renderPaymentOptions} toggle={this.togglePaymentOptions}/>
      )
    } else {
      return;
    }
  }

  renderPaymentOptions = () => {
    let methods = this.state.payment_methods
    let array = [];
    if (this.state.payment_methods && this.state.payment_methods.length > 0) {
      for (let i in methods) {
        array.push( <div key={`method-${i}`} className="payment-option select-payment" data-test-id="customer-invoice-slct-pmt" onClick={() => this.selectPayment({format_card: methods[i].format_card, id: methods[i].id, type: methods[i].type})}>
          <img className="card-icon" src={methods[i].type === 3 ? check_icon : card_icon} alt="payment type"/>
          <span className="card-no">{methods[i].format_card}</span>
          <span className="card-type">{methods[i].type === 3 ? 'Bank Account' : 'Credit/Debit Card'}</span>
        </div>)
      }
      return array;
    }
  }

  getPaymentOptions = async () => {
    let payment_methods = await PaymentMethodAPI.getList(this.props.auth_token, this.props.user_pin);
    if (payment_methods.err && payment_methods.err.response) {
      this.setState({errors: payment_methods.err.response.data.detail})
      window.scroll(0,0);
      return
    }

    if (payment_methods.errors !== undefined) {
      return
    }
    if (payment_methods.length === 0) {
      return
    }
    if (payment_methods === undefined) {
      return
    }
    this.setState({ payment_methods }, this.showPreferredPayment);
  }

  selectPayment = (payment) => {
    this.setState({
      selected_method: {
        format_card: payment.format_card,
        id: payment.id,
        type: payment.type,
        nickname: payment.nickname
      }
    })
    this.togglePaymentOptions();
  }

  showPreferredPayment = () => {
    let { payment_methods } = this.state;
    let preferredMethod = payment_methods.reduce( (newMethod, method) => {
      if(method.preferred) {
        newMethod.format_card = method.format_card;
        newMethod.id = method.id;
        newMethod.type = method.type;
        newMethod.nickname = method.nickname;
      }
      return newMethod
    });
    if (preferredMethod) {
      this.setState({selected_method: preferredMethod})
    } else if(payment_methods){
      let selected_method = {}
      selected_method.format_card = payment_methods[0].format_card;
      selected_method.id = payment_methods[0].id;
      selected_method.type = payment_methods[0].type;
      this.setState({ selected_method })
      }
      return;
    }

    returnPaymentLine = () => {
      let { invoice, payment_methods, selected_method, animateBox } = this.state;
      if(this.state.invoice.scheduled_on) {
        let methodRegex = /Mastercard|Visa|Discover|Amex/;
        let { visible_method } = invoice.transactions[0];
        return(
          <div className="payment-option greyed-out">
        <img className="card-icon" src={card_icon} alt="card"/>
        <span className="card-no">{visible_method}</span>
        <span className="card-type">{ methodRegex.test(visible_method) ? 'Credit/Debit Card' : 'Bank Account'}</span>
        <img className="chevron" src={chevron} alt="select payment method - disabled"/>
      </div>
      )
    } else if(!payment_methods) {
      return(
        <>
          <div className={classnames('payment-option', {'blink': animateBox})} data-test-id="customer-invoice-pmt-types" onClick={ () => this.props.togglePaymentTypes(true)}>
            <img className="card-icon" src={card_icon} alt="card"/>
            <span className="add-card">Add payment method</span>
            <img className="chevron" src={chevron} alt="add payment method"/>
          </div>
        </>
      )
    } else {
      return(
        <div className={ this.state.animateBox ? 'payment-option blink' : 'payment-option'} data-test-id="customer-invoice-pmt-options" onClick={this.togglePaymentOptions}>
          <img className="card-icon" src={selected_method.type === 3 ? check_icon : card_icon} alt="payment"/>
          <span className="card-no">{selected_method ? selected_method.format_card : 'Select payment method'}</span>
          <span className="card-type">{selected_method ? selected_method.type === 3 ? 'Bank Account' : 'Credit/Debit Card' : null}</span>
          <img className="chevron" src={chevron} alt="select payment method"/>
        </div>
      )
    }
  };

  toggleInvoiceDetails = () => {
    this.setState(prevState => {
      return {
        showInvoiceDetails: !prevState.showInvoiceDetails
      }
    })
  }

  toggleSchedulePayment = () => {
    this.setState(prevState => {
      return {
        showSchedulePayment: !prevState.showSchedulePayment
      }
    })
  }

  renderScheduleBox = () => {
    let { invoice } = this.state;
    let scheduled_date;
    invoice.scheduled_on ? scheduled_date = invoice.scheduled_on : scheduled_date = this.state.scheduled_date;
    let month = globalUtils.returnMonth(scheduled_date);
    let day = globalUtils.returnDay(scheduled_date);
    if(!invoice.scheduled_on && !this.state.scheduled_date) {
      return (
        <>
          <img className="icon" src={calendar} alt="Schedule Payment" key="schedule_img"/>
          <span className="button-text">Schedule</span>
        </>
      )
    } else {
      return(
        <div className="selected-date">
          <div className="month">{month}</div>
          <div className="day">{day}</div>
          <div className="scheduled-text">Payment scheduled for {this.state.scheduled_date || globalUtils.formatDate(invoice.scheduled_on)}</div>
        </div>
      )
    }
  }

  selectDay = (day) => {
    let scheduledDay = globalUtils.formatDate(day)
    if(scheduledDay === globalUtils.getCurrentDate()) {
      this.setState({
        scheduled_date: null,
      })
    } else {
      this.setState({
        scheduled_date: scheduledDay,
      })
    }
  }

  handleChange = (event) => {
    if (event.target) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
        [name]: globalUtils.convertDollarsToCents(value)
      });
    }
  };

  checkForZero = () => {
    let { amount_paid, invoice } = this.state;
    if(amount_paid === "0" || amount_paid === "00" || amount_paid === "000" || amount_paid === "0000") {
      this.setState({
        amount_paid: invoice.amount_paid ? invoice.amount - +invoice.amount_paid : invoice.amount
      })
    }
  }

  showPayAmount = () => {
    let { invoice } = this.state;
    if(this.state.amount_paid) {
      return globalUtils.convertToDollars(this.state.amount_paid);
    } else return globalUtils.convertToDollars(invoice.amount_paid ? invoice.amount - +invoice.amount_paid : invoice.amount)
  }

  renderPayButton = () => {
    if(!this.state.invoice.scheduled_on) {
        if(this.state.scheduled_date) {
          return (
            <>
              <HideShow show={this.state.ready_status}>
                <Button invoice secondary data-test-id="customer-invoice-schedule" onClick={this.pay} fullWidth>Schedule</Button>
              </HideShow>
              <HideShow show={!this.state.ready_status}>
                <Button invoice secondary fullWidth>Processing...</Button>
              </HideShow>
            </>
          )
        } else {
          return(
            <>
              <HideShow show={this.state.ready_status}>
                <Button invoice disabled={!this.state.selected_method} data-test-id="customer-invoice-pay" onClick={this.pay} id="payButton" fullWidth>Pay { this.showPayAmount() }</Button>
              </HideShow>
              <HideShow show={!this.state.ready_status}>
                <Button invoice secondary fullWidth>Processing...</Button>
              </HideShow>
            </>
        )
      }
    } else {
      return(
        <Button data-test-id="customer-invoice-unschedule" onClick={this.unschedule} fullWidth>Unschedule</Button>
      )
    }
  }

  focusButton = () => {
    document.getElementById("payButton").focus();
  }

  renderPaymentBox = () => {
    let { invoice } = this.state;
    if(!invoice.scheduled_on) {
      return(
        <>
          <AmountText className="white">Edit amount to pay</AmountText>
          <CurrencyInput
            name="amount_paid" onChange={this.handleChange}
            placeholder={globalUtils.convertToDollars(invoice.amount_paid ? invoice.amount - +invoice.amount_paid : invoice.amount)}
            onBlur={this.checkForZero}
            submit={this.focusButton}/>
        </>
      )
    } else {
      return(
        <div className="cancel-payment">
          <span className="payment-scheduled">Payment Scheduled</span>
        </div>
      )
    }
  }

  toggleSplitPayments = () => {
    this.setState(prevState => {
      return {
        showSplitPayment: !prevState.showSplitPayment,
      };
    });
  }

  toggleAutoPay = () => {
    this.setState(prevState => {
      return {
        showAutoPay: !prevState.showAutoPay
      }
    })
  }

  showSelectPaymentType = () => {
    if(this.props.show_payment_types && !this.state.showPaymentOptionsModal) {
      return(
        <SelectPaymentType context="customer-invoice" getPaymentOptions={this.getPaymentOptions} ach_creds={this.state.ach_creds} invoice={this.state.invoice}/>
      )
    }
  }

  render() {
    let { invoice } = this.state;
    return (
      <Wrapper>
        <HideShow show={this.state.showSplitPayment}>
          <SplitPayment invoice={this.state.invoice}
              onClose={this.toggleSplitPayments}
              data-test-id="customer-invoice-split-toggle-pmts"
              onClick={this.togglePaymentOptions}
              toggle={this.togglePaymentOptions}
              methods={this.state.payment_methods}
              auth_token={this.props.auth_token}
              getPaymentOptions={this.getPaymentOptions}/>
        </HideShow>
        <HideShow show={this.state.showAutoPay}>
          <CustomerAutopay invoice={this.state.invoice}
              customer={this.state.customer}
              onClose={this.toggleAutoPay}
              data-test-id="customer-invoice-auto-pay-view"
              autopay={this.state.autopay}
              getData={this.getData}
              payment_methods={this.state.payment_methods}
              // sub_id={this.state.invoice.subscription.id}
              />
        </HideShow>
        <GradientBackground>
          <HideShow show={!this.state.loaded}>
            <LoadingScreen/>
          </HideShow>
          {/* <HideShow show={ }>
            <Modal onClose={this.toggleModal} submit={this.pay}>I authorize {invoice.merchant.name} to debit my account in the amount of {globalUtils.convertToDollars(invoice.amount)} on {globalUtils.formatDate(new Date())}.</Modal>
          </HideShow> */}
          <HideShow show={this.state.showSchedulePayment}>
            <SchedulePayment selectDay={this.selectDay} back={this.toggleSchedulePayment} scheduled_date={this.state.scheduled_date}/>
          </HideShow>
          <HideShow show={this.state.showInvoiceDetails}>
            <InvoiceDetails back={this.toggleInvoiceDetails} logo={invoice.merchant.logo_url} name={invoice.merchant.name} invoice={invoice}/>
          </HideShow>
          <MerchantBox>
            <LogoContainer>
              <MerchantLogo showMerchantName={true} height="14vh" width="calc(100vw - 40px)" fullSize className="partner-logo" logo={invoice.merchant.logo_url} name={invoice.merchant.name}/>
            </LogoContainer>
          </MerchantBox>
          {this.showSelectPaymentType()}
          <AmountDueContainer>
            <AmountText className="white">Amount Due</AmountText>
            <AmountDueText>{globalUtils.convertToDollars(invoice.amount_paid ? invoice.amount - +invoice.amount_paid : invoice.amount)}</AmountDueText>
            <InvoiceDetailContainer>
              <span className="invoice-link" data-test-id="customer-invoice-view" onClick={this.toggleInvoiceDetails}>view invoice</span>
            </InvoiceDetailContainer>
          </AmountDueContainer>
          <PageBreak></PageBreak>
          <PaymentBox>
            {this.showModal()}
            {this.renderPaymentBox()}
            <span className="due-text">Due {globalUtils.formatDate(this.state.display_date)}</span>
            <div className="center">
              {this.returnPaymentLine()}
            </div>
          </PaymentBox>
          <br/>
          <PayButtonContainer>
            <ErrorContainer>
              <ErrorHandle data={this.state.errors}/>
            </ErrorContainer>
            <div className="lower-half">
              <div className="icon-wrapper">
                <div className="schedule-method">
                  <div className="schedule" data-test-id="customer-invoice-schedule-pmt" onClick={ invoice.scheduled_on ? null : this.toggleSchedulePayment }>
                    {this.renderScheduleBox()}
                  </div>
                </div>
              </div>
              <div className="icon-wrapper auto-pay" data-test-id="customer-invoice-auto-pay" onClick={this.toggleAutoPay}>
                <FontAwesomeIcon className="font-awe-icon" icon={faRedoAlt}/>
                {this.state.autopay.autopay_enabled ? <FontAwesomeIcon className="font-awe-check" icon={faCheck}/> : ""}
                <span className="button-text">Auto-Pay</span>
              </div>
              <HideShow show={!this.state.scheduled_date && !this.state.invoice.scheduled_on}>
                <div className="icon-wrapper" data-test-id="customer-invoice-toggle-split" onClick={this.toggleSplitPayments}>
                  <img className="icon" src={split_payment} alt="split payment"/>
                  <span className="button-text">Multi-Card</span>
                </div>
              </HideShow>
            </div>
          </PayButtonContainer>
        </GradientBackground>
        <div className="button-wrapper">
          {this.renderPayButton()}
        </div>
      </Wrapper>
    )
  }
}
  const mapStateToProps = (state) => {
    return {
      from_invoice: state.invoices.from_invoice,
      auth_token: state.auth.auth_token,
      user_pin: state.auth.user_pin,
      payment_method: state.auth.payment_method,
      user_id: state.auth.user_id,
      user_role: state.auth.user_role,
      show_payment_types: state.invoices.show_payment_types
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setFromInvoice: (from_invoice) => {dispatch(setFromInvoice(from_invoice))},
    togglePaymentTypes: (bool) => {dispatch(togglePaymentTypes(bool))},
    storeMerchantName: (merchant_name) => {dispatch(storeMerchantName(merchant_name))},
    setPaymentMethod: (payment_method) => {dispatch(setPaymentMethod(payment_method))},
    setNotification: (notification) => {dispatch(setNotification(notification))},
    setUserId: (user_id) => {dispatch(setUserId(user_id))},
    logout: (authInfo) => {dispatch(logout(authInfo))},
    storeCustomerUsername: (username) => {dispatch(storeCustomerUsername(username))},
    storeAchInfo: (ach_info) => {dispatch(storeAchInfo(ach_info))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerInvoice)
