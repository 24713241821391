import React, {Component} from 'react';
import styled from 'styled-components';

const Button = styled.button`
  height: 100%;
  width:  ${ props => props.fullWidth ? '100%' : '228px'};
  border: none;
  border-radius: 4px;
  background-color: ${ props => props.disabled ? '#FFFFFF' : '#00D973' };
  color: ${ props => props.disabled ? '#DFE1E5' : '#FFFFFF' };
  text-align: center;
  line-height: 29px;
  cursor: pointer;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default class PayButton extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return <Button onClick={this.props.onClick} {...this.props}>{this.props.children}</Button>
  }
}
