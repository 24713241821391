import React, {Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import Modal from '../PlainModal';
import Input from '../../inputs/ControlledInput';
import Textarea from '../../fields/TextArea';
import Button from '../../buttons/NewButton';

// API & Utils
import groupsAPI from '../../../api/groups/groupsAPI';

// Redux
import { setNotification } from '../../../js/actions/notifications';

const MiddleContainer = styled.div`
padding: 0 10px;
.input-container {
  margin-bottom: 20px;
  .label {
    color: #9CAAB6;
    font-size: 21px;
    margin-bottom: 5px;
  }
}
`

const ButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

class EditGroupModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      description: ''
    }
  }

  componentDidMount() {
    if(this.props.data) {
      this.setState({
        name: this.props.data.name,
        description: this.props.data.description
      })
    }
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  editGroup = async () => {
    if (!this.state.name || !this.state.description) return this.setState({errors: 'Name and description required'});
    let data = {
      name: this.state.name,
      description: this.state.description
    }

    let response = await groupsAPI.editGroup(this.props.auth_token, this.props.data.id, data)
    if (response && !response.err) {
      this.props.getData();
    } else {
      this.setState({errors: response.err.response.data.detail})
    }
    this.props.getData().then( () => {
      this.props.refresh(this.props.data.id);
    });
    // this.props.hideActiveGroup();
  }

  deleteGroup = async () => {
    let response = await groupsAPI.deleteGroup(this.props.auth_token, this.props.data[3]);

    if (response.err) {
      this.props.setNotification({message: response.err.response.data.detail, type: 'error'});
      return
    }

    this.props.getData()
    this.props.hideActiveGroup()
  }


  render() {
    return (
      <Modal context="edit-group" title="Edit Group" toggle_modal={this.props.toggle_modal} submit={this.editGroup}>
        <ButtonWrapper>
          <Button type="danger" onClick={this.deleteGroup}>Delete</Button>
        </ButtonWrapper>
        <MiddleContainer>
          <div className="input-container">
            <div className="label">Group Name:</div>
            <Input value={this.state.name} data-test-id="group_name" onChange={this.onChange} className="modal-input no-border" name="name"/>
          </div>
          <div className="input-container">
            <div className="label">Description:</div>
            <Textarea data-test-id="description" name="description" rows="7" cols="30" onChange={this.onChange} value={this.state.description}/>
          </div>
          {/* <div className="input-container">
            <div className="label">Labels:</div>
          </div> */}
        </MiddleContainer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
		merchant_name: state.merchants.merchant_name
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: notification => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGroupModal);
