import React, {Component} from 'react';
import styled from 'styled-components';

const Error = styled.div`
  color: #FD7272;
`

export default class ErrorHandle extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  renderErrors= () => {
    let errorArray = []
    if (this.props.data) {
      if (Array.isArray(this.props.data) || typeof this.props.data === "object"){
        for (let i in this.props.data){
          errorArray.push(<Error key={i}>{this.props.data[i]}</Error>)
        }
      } else {
        return <Error key={Math.random()}>{this.props.data}</Error>
      }
    }
    return errorArray
  }


  render() {
    return (
      this.renderErrors()
    )
  }
}
