import { createStore, applyMiddleware, compose, combineReducers} from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { reduxTimeout } from 'redux-timeout';

// import rootReducer from "../reducers/index";
import notifications from '../reducers/notifications';
import invoices from '../reducers/invoices';
import auth from '../reducers/auth';
import merchants from '../reducers/merchants';
import modals from '../reducers/modal_reducer';

import { LOGOUT } from '../constants/index';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const combined = combineReducers({
   notifications,
   invoices,
   auth,
   merchants,
   modals
})

const rootReducer = (state, action) => {
   if(action.type === LOGOUT) {
      const { auth } = state;
      state = { auth };
   }
   return combined(state, action)
}

const persistConfig = {
   key: 'root',
   storage: storage,
   stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(pReducer, composeEnhancers(applyMiddleware(reduxTimeout())));
export const persistor = persistStore(store);

// If redux state gives you old code that isn't even in our code base anymore, uncomment line 43, restart evertyhing, then comment it out again.
// persistor.purge()
//

export default { store, persistor }
