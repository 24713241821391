import React, { Component } from 'react'
import CardConnectIframe from './CardConnectIframe'
import styled from 'styled-components'
import CustomCheckbox from '../../components/inputs/CustomCheckbox'
import globalUtils from '../../utils/globalUtils'
import _ from 'lodash'
import axios from 'axios'
import HideShow from '../../components/function/HideShow'

const PmtIframeContainer = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #F3F4F5 0% 0% no-repeat padding-box;
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
`

const PmtAmtSection = styled.div`
  padding-bottom: calc(var(--vh, 1vh) * 5.5);
  padding-top: calc(var(--vh, 1vh) * 5.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const PmtTypesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  bottom: 0px;
  left: 10px;
`

const PmtType = styled.p`
  margin: 0px calc(var(--vh, 1vh) * 0.96);
  padding: calc(var(--vh, 1vh) * 0.96) calc(var(--vh, 1vh) * 1.45);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${props => props.isSelected ? '#34B7AE' : 'dimgrey'};
  opacity: ${props => props.isSelected ? '1' : '0.6'};
  border-bottom: ${props => props.isSelected ? 'calc(var(--vh, 1vh) * 0.36) solid #34B7AE' : 'calc(var(--vh, 1vh) * 0.36) solid transparent'};
  cursor: pointer;
`

const PmtInfoSection = styled.form`
  height: calc(var(--vh, 1vh) * 81.33);
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.spaceAround ? 'space-around' : 'center'};
  padding-bottom: 10px;
  padding-top: 10px;
`

const PmtAmountTitle = styled.p`
  margin: 0px;
  font-size: calc(var(--vh, 1vh) * 2.47);
  text-align: center;
  color: #24272F;
  letter-spacing: 0;
  font: Roboto;
  font-weight: 200;
  margin-bottom: calc(var(--vh, 1vh) * 1.33);
`

const PmtAmount = styled.p`
  margin: 0px;
  text-align: center;
  font: Roboto;
  letter-spacing: 0;
  color: #34B7AE;
  opacity: 1;
  font-size: calc(var(--vh, 1vh) * 5.56);
  font-weight: 500;
`

const FullNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 449px;
  max-width: 95%;
  padding: 0px 3px;
  margin-bottom: 10px;
`

const ZipAndExpiryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 449px;
  max-width: 95%;
  padding: 0px 3px;
`
const NameInput = styled.input`
  width: 90%;
  border: 1px solid silver;
  font-size: 1rem;
  border-radius: 0.25rem;
  min-height: ${props => props.inputSize ? props.inputSize : '33px'};
  display: block;
  padding: 0.3rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: dimgrey;
  box-sizing: border-box;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  &:focus {
    border-color: steelblue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(46, 81, 157, 0.25);
  }
`

const ZipInput = styled.input`
  width: 100px;
  border: 1px solid silver;
  font-size: 1rem;
  border-radius: 0.25rem;
  min-height: ${props => props.inputSize ? props.inputSize : '33px'};
  display: block;
  padding: 0.3rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.validZip ? 'dimgrey' : '#b22222'};
  box-sizing: border-box;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  &:focus {
    border-color: steelblue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(46, 81, 157, 0.25);
  }
`

const AccountInput = styled.input`
  width: 100%;
  border: 1px solid silver;
  font-size: 1rem;
  border-radius: 0.25rem;
  min-height: ${props => props.inputSize ? props.inputSize : '33px'};
  display: block;
  padding: 0.3rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.validInput ? 'dimgrey' : '#b22222'};
  box-sizing: border-box;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  &:focus {
    border-color: steelblue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(46, 81, 157, 0.25);
  }
`

const MonthInput = styled.input`
  width: 60px;
  margin-right: 10px;
  border: 1px solid silver;
  font-size: 1rem;
  border-radius: 0.25rem;
  min-height: ${props => props.inputSize ? props.inputSize : '33px'};
  display: inline-block;
  padding: 0.3rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.validMonth ? 'dimgrey' : '#b22222'};
  box-sizing: border-box;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  &:focus {
    border-color: steelblue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(46, 81, 157, 0.25);
  }
`

const YearInput = styled.input`
  width: 70px;
  margin-left: 10px;
  display: inline-block;
  border: 1px solid silver;
  font-size: 1rem;
  border-radius: 0.25rem;
  min-height: ${props => props.inputSize ? props.inputSize : '33px'};
  padding: 0.3rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.validYear ? 'dimgrey' : '#b22222'};
  box-sizing: border-box;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  &:focus {
    border-color: steelblue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(46, 81, 157, 0.25);
  }
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
`

const ExpiryInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const ZipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 449px;
  max-width: 95%;
  padding: 0px 3px;
`

const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
`

const FormContainer = styled.div`
  width: 449px;
  max-width: 95%;
  padding 0px 3px;
`

const ExpirationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 449px;
  max-width: 95%;
  padding: 0px 3px;
`

const ExpirationInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const NameLabel = styled.label`
  display: inline-block;
  margin: 0px;
  padding: 0px;
  padding-top: 0.25rem;
  border: 0px;
  font-family: Roboto, sans-serif;
  font-weight: 200;
  vertical-align: top;
  ${props => props.marginLeft ? 'margin-left: ' + props.marginLeft + ';' : ''}
`

const SaveToAcctContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 449px;
  max-width: 95%;
  margin-top: ${props => props.topMargin ? props.topMargin : 'calc(var(--vh, 1vh) * 2.78)'};
  padding: 0px 3px;
`

const SaveToAcctText = styled.p`
  margin: 0px;
  font-size: calc(var(--vh, 1vh) * 2.22);
  color: #242424;
  margin-left: 15px;
  font-stretch: condensed;
`

const ButtonsContainer = styled.div`
  width: 449px;
  max-width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(var(--vh, 1vh) * 6.67);
  padding: 0px 3px;
`

const Button = styled.button`
  width: 26.56vw;
  max-width: 204px;
  height: calc(var(--vh, 1vh) * 4.56);
  max-height: 40px;
  text-align: center;
  color: white;
  border-radius: 32px;
  background-color: ${props => props.payNow ? '#3BBFB7' : '#BCBCBC'};
  border: 1px solid #7C7C7C;
  font-size: calc(var(--vh, 1vh) * 2.45);
  cursor: pointer;
  font-stretch: condensed;
`

const ResultTopSection = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 26);
`

const ResultMiddleSection = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 27.56);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ResultBottomSection = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 46.44);
`

const SuccessHeader = styled.p`
  margin: 0px;
  font-size: calc(var(--vh, 1vh) * 5.56);
  color: #3BBFB7;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: calc(var(--vh, 1vh) * 2.78);
  font-weight: 500;
`

const ErrorHeader = styled.p`
  margin: 0px;
  font-size: calc(var(--vh, 1vh) * 5.56);
  color: crimson;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: calc(var(--vh, 1vh) * 2.78);
  font-weight: 500;
`

const ResultText = styled.p`
  margin: 0px;
  font-size: calc(var(--vh, 1vh) * 2.67);
  color: #24272F;
  text-align: center;
  letter-spacing: 0;
  max-width: 60%;
  line-height: calc(var(--vh, 1vh) * 4.78);
  font-weight: 200;
`

const CashAmountInputContain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 3px;
`

const DollarSign = styled.span`
  color: dimgrey;
  opacity: 0.6;
  margin-right: 5px;
`

export default class PaymentIframe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      zip: '',
      saveToAcct: true,
      hasToken: false,
      showResultPage: false,
      pmtSuccessful: false,
      cancelled: false,
      blytzpay_account_id: null,
      invoice_id: null,
      description: '',
      blytzpay_invoice_id: null,
      company_token: null,
      api_key: null,
      amount: null,
      isAuthenticated: true,
      lastFour: null,
      errorMessage: null,
      errorHeader: null,
      callback_url: null,
      disablePay: false,
      card_month: '',
      card_year: '',
      currentYear: 2019,
      currentMonth: 11,
      selected_payment_method: 'credit',
      selected_payment_options: [], 
      routing_number: '',
      account_number: '',
      cash_amount: '',
      encryptedBankAcct: ''
    }
  }

  componentDidMount = () => {
    window.addEventListener('message', this.cardConnectListener, false)

    // Get query parameters and current year and month and assign them to state
    // Either blytzpay_invoice_id or invoice_id can be given via the url query params
    let {blytzpay_account_id, invoice_id, company_token, amount, api_key, first_name, last_name, description, blytzpay_invoice_id, callback_url, selected_payment_method, selected_payment_options} = globalUtils.getQueryString(this.props.location.search.slice(1))

    let date = new Date()
    let currentYear = date.getFullYear()
    let currentMonth = date.getMonth() + 1

    selected_payment_options = selected_payment_options ? selected_payment_options.split(',') : []
    let isSelectedPmtInOptions = selected_payment_options && selected_payment_method ? selected_payment_options.includes(selected_payment_method.toLowerCase()) : true

    // Logic to choose the initial selected_payment_method based off of provided query parameters
    let new_selected_payment_method = selected_payment_method && isSelectedPmtInOptions ? selected_payment_method : selected_payment_options && selected_payment_options.length > 0 && (!selected_payment_method || !isSelectedPmtInOptions) ? selected_payment_options[0] : 'credit'

    this.setState({
      blytzpay_account_id,
      blytzpay_invoice_id,
      invoice_id,
      company_token,
      amount,
      api_key,
      firstName: _.startCase(first_name),
      lastName: _.startCase(last_name),
      description,
      callback_url,
      currentYear,
      currentMonth,
      selected_payment_method: new_selected_payment_method,
      selected_payment_options: selected_payment_options && selected_payment_options.length > 0 ? selected_payment_options : ['credit', 'ach', 'cash']
    }, _ => {

      // Focus on first input after the selected_payment_type is set
      if (document.getElementById(new_selected_payment_method + 'FirstInput')) {
        document.getElementById(new_selected_payment_method + 'FirstInput').focus()
      }
    })
  }

  componentWillUnmount = () => {

    // Clean up to remove card connect event listener
    window.removeEventListener('message', this.cardConnectListener, false)
  }

  cardConnectListener = (event) => {
    // Using hasToken and storing token in hidden input rather than storing token on state to be as secure as possible.
    if (event.origin.startsWith('https://fts.cardconnect')) {
        let token = JSON.parse(event.data)
        let mytoken = document.getElementById('mytoken')
        mytoken.value = token.message
        let lastFour = token.message.slice(-4)
        if (!token.message) {
          this.setState({
            hasToken: false,
            lastFour: null
          })
        } else {
          this.setState({
            hasToken: true,
            lastFour
          })
        }
      }
  }

  onCheck = () => {
    // Toggles save to account check box
    this.setState({
      saveToAcct: !this.state.saveToAcct
    })
  }

  getSize = (sizeVariable, maxSize) => {
    // Dynamic sizing based on screen size to help make the iframe and form mobile friendly
    let oneVH = getComputedStyle(document.documentElement).getPropertyValue('--vh')
    let pixels = oneVH.slice(0, -2)
    let newPixels = parseFloat(pixels) * sizeVariable
    let final = maxSize >= newPixels ? newPixels.toString() + 'px' : maxSize.toString() + 'px'
    return final
  }

  cancel = (event) => {
    // Cancel payment and take to cancel page
    this.setState({
      showResultPage: true,
      cancelled: true
    })
  }

  getExternalApiUrl = () => {
    // Get API url based on browser environment
    switch(window.location.origin) {
      case 'https://dev.blytzpay.com':
        return 'boba';
      case 'https://qa.blytzpay.com':
        return 'phteve';
      case 'https://staging.blytzpay.com':
        return 'sandbox'
      case 'https://app.blytzpay.com':
        return 'connect'
      default:
        return 'boba';
    }
  }

  payNow = (event) => {
    // Blur all inputs on the page to prevent the user from typing while the payment is submitted
    if (document.activeElement) {
      document.activeElement.blur()
    }

    // Handle payment by payment type
    if (this.state.selected_payment_method === 'credit') {
      this.payWithCredit()
    } else if (this.state.selected_payment_method === 'ach') {
      this.payWithAch()
    } else if (this.state.selected_payment_method === 'cash') {
      this.payWithCash()
    }
  }

  payWithCredit = () => {
    let {blytzpay_account_id, invoice_id, blytzpay_invoice_id, description, amount, callback_url, company_token, api_key, card_month, card_year, zip, selected_payment_method} = this.state

    let token = document.getElementById('mytoken').value

    // Request body based on the type of invoice id passed in the url parameters
    let body = invoice_id ? {
      blytzpay_account_id,
      invoice_id,
      token,
      description,
      amount,
      callback_url,
      card_month,
      card_year,
      zip,
      payment_type: selected_payment_method
    } : {
      blytzpay_account_id,
      blytzpay_invoice_id,
      token,
      description,
      amount,
      callback_url,
      card_month,
      card_year,
      zip,
      payment_type: selected_payment_method
    }

    let config = {
      headers: {
        'merchant-token': company_token,
        'x-api-key': api_key
      }
    }
    // Disabled pay button when it is clicked on until the request comes back
    this.setState({
      disablePay: true
    })

    axios.post(`https://${this.getExternalApiUrl()}.blytzpay.com/v1/payment/process`, body, config).then(res => {

      // Card connect returns either A, B, or C in the respstat key value pair which translate to approved, retry or declined respectively
      let responseStatusObj = {
        a: 'APPROVED',
        b: 'RETRY',
        c: 'DECLINED'
      }

      // If successful payment
      if (res.data.success && res.data.payment.payment_status === 'success') {
        this.setState({
          showResultPage: true,
          pmtSuccessful: true,
          disablePay: false
        })

      // If request was successful but card connect says they should retry or it was declined
      } else if (res.data.success && res.data.payment.payment_status !== 'success') {
        this.setState({
          showResultPage: true,
          errorHeader: responseStatusObj[res.data.payment.processor_response.respstat.toLowerCase()],
          disablePay: false
        })

      // If the request wasn't sucessful and it has errors handled by the API
      } else {
        console.error(res.data.message ? res.data.message : res.data.errors)
        this.setState({
          showResultPage: true,
          errorMessage: 'Failed to make payment, please try again later',
          errorHeader: 'FAILED',
          disablePay: false
        })
      }

      // If the request wasn't successful and the errors are not handled by the API
    }).catch(err => {
      console.error(err)
      this.setState({
        showResultPage: true,
        errorMessage: 'Failed to make payment, please try again later',
        errorHeader: 'FAILED',
        disablePay: false
      })
    })
  }

  payWithAch = () => {
    let {account_number, routing_number, selected_payment_method, blytzpay_account_id, invoice_id, blytzpay_invoice_id, description, amount, callback_url, api_key, company_token} = this.state

    // Request body based on the type of invoice id passed in the url parameters
    let body = invoice_id ? {
      blytzpay_account_id,
      invoice_id,
      description,
      amount,
      callback_url,
      payment_type: selected_payment_method,
      routing_number,
      account_number
    } : {
      blytzpay_account_id,
      blytzpay_invoice_id,
      description,
      amount,
      callback_url,
      payment_type: selected_payment_method,
      routing_number,
      account_number
    }

    let config = {
      headers: {
        'merchant-token': company_token,
        'x-api-key': api_key
      }
    }

    // Disabled pay button when it is clicked on until the request comes back
    this.setState({
      disablePay: true
    })

    axios.post(`https://${this.getExternalApiUrl()}.blytzpay.com/v1/payment/process`, body, config).then(res => {

      // If successful payment
      if (res.data.success && res.data.payment.payment_status === 'success') {
        let encryptedBankAcct = res.data.payment.processor_response.bank_account ? res.data.payment.processor_response.bank_account.split(':')[res.data.payment.processor_response.bank_account.split(':').length - 1] : ''
        this.setState({
          showResultPage: true,
          pmtSuccessful: true,
          disablePay: false,
          encryptedBankAcct
        })
      
      // If unsuccessful and processor has a message
      } else if (res.data.payment.payment_status !== 'success') {
        this.setState({
          showResultPage: true,
          errorMessage: res.data.payment.processor_response.message ? _.startCase(res.data.payment.processor_response.message.toLowerCase()) : 'Failed to make payment, please try again later',
          errorHeader: 'FAILED',
          disablePay: false
        })

      // If unsuccessful and not handled by api
      } else {
        console.error(res.data.message ? res.data.message : res.data.errors)
        this.setState({
          showResultPage: true,
          errorMessage: 'Failed to make payment, please try again later',
          errorHeader: 'FAILED',
          disablePay: false
        })
      }
    // If unsuccessful and not handled by api
    }).catch(err => {
      console.error(err)
      this.setState({
        showResultPage: true,
        errorMessage: 'Failed to make payment, please try again later',
        errorHeader: 'FAILED',
        disablePay: false
      })
    })
  }

  payWithCash = () => {
    let {cash_amount, selected_payment_method, blytzpay_account_id, invoice_id, blytzpay_invoice_id, description, callback_url, api_key, company_token} = this.state

    // Request body based on the type of invoice id passed in the url parameters
    let body = invoice_id ? {
      blytzpay_account_id,
      invoice_id,
      description,
      amount: cash_amount,
      callback_url,
      payment_type: selected_payment_method
    } : {
      blytzpay_account_id,
      blytzpay_invoice_id,
      description,
      amount: cash_amount,
      callback_url,
      payment_type: selected_payment_method
    }

    let config = {
      headers: {
        'merchant-token': company_token,
        'x-api-key': api_key
      }
    }

    // Disabled pay button when it is clicked on until the request comes back
    this.setState({
      disablePay: true
    })

    axios.post(`https://${this.getExternalApiUrl()}.blytzpay.com/v1/payment/process`, body, config).then(res => {

      // If successful payment
      if (res.data.success && res.data.payment.payment_status === 'success') {
        this.setState({
          showResultPage: true,
          pmtSuccessful: true,
          disablePay: false
        })
      
      // If unsuccessful and not handled by api
      } else {
        console.error(res.data.message ? res.data.message : res.data.errors)
        this.setState({
          showResultPage: true,
          errorMessage: 'Failed to make payment, please try again later',
          errorHeader: 'FAILED',
          disablePay: false
        })
      }

    // If unsuccessful and not handled by api
    }).catch(err => {
      console.error(err)
      this.setState({
        showResultPage: true,
        errorMessage: 'Failed to make payment, please try again later',
        errorHeader: 'FAILED',
        disablePay: false
      })
    })
  }

  iframeCss = () => {
    // Iframe CSS with dynamic input sizing and margins
    let css = `%2Eerror{color:%20firebrick;}; %23cccvvfield{width: 100px;}; label{display: inline-block; margin: 0px; padding: 0px; padding-top: 0.25rem; border: 0px; margin-top: ${this.getSize(1.47, 10)}; margin-bottom: 10px; font-family: Roboto, sans-serif; font-weight: 200;}; body{min-height: 22px; box-sizing: border-box; margin: 0px; padding: 3px;}; input:focus{border-color: steelblue; outline: 0; box-shadow: 0 0 0 0.2rem rgba(46, 81, 157, 0.25);}; input{border: 1px solid silver; font-size: 1rem; border-radius: 0.25rem; width: 95%25; min-height: ${this.getSize(3.67, 33)}; display: block; padding: 0.3rem 0.75rem; font-weight: 400; line-height: 1.5; color: dimgrey; box-sizing: border-box; background-color: whitesmoke; border-radius: 0.25rem;};`
    return css
  }

  disablePayButton = () => {
    let disable = false

    // Credit Disable Logic
    if (this.state.selected_payment_method === 'credit') {
      disable = !this.state.firstName || !this.state.lastName || !this.validZipCode() || !this.state.hasToken || !this.state.blytzpay_account_id || !(this.state.invoice_id || this.state.blytzpay_invoice_id) || !this.state.isAuthenticated || !this.state.amount || this.state.disablePay || !this.validMonth() || !this.validYear()

    // ACH Disable Logic
    } else if (this.state.selected_payment_method === 'ach') {
      disable = !this.validAccountNumber() || !this.validRoutingNumber() || !this.state.blytzpay_account_id || !(this.state.invoice_id || this.state.blytzpay_invoice_id) || !this.state.isAuthenticated || this.state.disablePay

    // Cash Disable Logic
    } else if (this.state.selected_payment_method === 'cash') {
      disable = this.state.cash_amount !== this.state.amount || !this.state.blytzpay_account_id || !(this.state.invoice_id || this.state.blytzpay_invoice_id) || !this.state.isAuthenticated || this.state.disablePay

    // Other Disable Logic
    } else {
      disable = !this.state.firstName || !this.state.lastName || !this.validZipCode() || !this.state.hasToken || !this.state.blytzpay_account_id || !(this.state.invoice_id || this.state.blytzpay_invoice_id) || !this.state.isAuthenticated || !this.state.amount || this.state.disablePay || !this.validMonth() || !this.validYear()
    }

    return disable
  }

  validZipCode = () => {
    // Format Expected: 84020 or 84020-1234
    let zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
    return zipCodePattern.test(this.state.zip);
  }

  validAccountNumber = () => {
    // Expects non-empty digit only
    let accountNumberPattern = /^[0-9]*$/
    let isValid = accountNumberPattern.test(this.state.account_number) && this.state.account_number.length > 0
    return isValid
  }

  accountNumberInput = event => {
    let temp_account_number = event.target.value

    // Check if last input was a number or not and only add to value if was a digit
    let lastIsInt = temp_account_number.length > 0 ? !isNaN(parseInt(temp_account_number.charAt(temp_account_number.length - 1))) : true

    if (lastIsInt) {
      this.setState({account_number: event.target.value})
    }
  }

  routingNumberInput = event => {
    let temp_routing_number = event.target.value

    // Check if last input was a number or not and only add to value if was a digit
    let lastIsInt = temp_routing_number.length > 0 ? !isNaN(parseInt(temp_routing_number.charAt(temp_routing_number.length - 1))) : true

    if (temp_routing_number.length <= 9 && lastIsInt) {
      this.setState({routing_number: event.target.value})
    }
  }

  validRoutingNumber = () => {
    // Expects only 9 digits
    let routingNumberPattern = /^[0-9]*$/
    let isValid = routingNumberPattern.test(this.state.routing_number) && this.state.routing_number.length === 9
    return isValid
  }

  validCashAmount = () => {
    // Expects 0.00 format
    let cashAmtPattern = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/
    let isValid = (cashAmtPattern.test(this.state.cash_amount) && this.state.cash_amount === this.state.amount) || this.state.cash_amount === '0'

    return isValid
  }

  cashAmountInput = event => {
    // Only allows one period input and two digits after the decimal
    let temp_cash_amount = event.target.value
    let lastCharacter = temp_cash_amount.charAt(temp_cash_amount.length - 1)
    let splitOnPeriod = temp_cash_amount.split('.')
    let centsLength = 0
    let numberOfPeriods = 0

    // Increment the number of periods in the number
    for (let i = 0; i < temp_cash_amount.split('').length; i++) {
      if (temp_cash_amount.split('')[i] === '.') {
        numberOfPeriods++
      }
    }

    if (splitOnPeriod.length === 2) {
      centsLength = splitOnPeriod[splitOnPeriod.length - 1].length
    }

    // Checks if last character was a number or period
    let isNumOrPeriod = temp_cash_amount.length === 0 ? true : !isNaN(parseInt(lastCharacter)) || lastCharacter === '.' 

    if (isNumOrPeriod && numberOfPeriods <= 1 && centsLength <= 2) {
      this.setState({cash_amount: event.target.value})
    }
  }

  validMonth = () => {
    // Format Expected: MM and if the year expiration is this year then the month must be equal to or later than this month
    var monthPattern = /^(0?[1-9]|1[012])$/;
    let isValid = parseInt(this.state.card_year) === this.state.currentYear ? monthPattern.test(this.state.card_month) && parseInt(this.state.card_month) >= this.state.currentMonth : monthPattern.test(this.state.card_month);
     return isValid
  }

  validYear = () => {
    // Format Expected: YYYY and the year has to be equal to or greater than this year
    let yearPattern = /^[0-9]{4}$/;
    let isValid = yearPattern.test(this.state.card_year) && parseInt(this.state.card_year) >= this.state.currentYear;
     return isValid
  }

  monthInput = event => {
    let temp_card_month = event.target.value

    // Check if last input was a number or not and only add to value if was a digit
    let lastIsInt = temp_card_month.length > 0 ? !isNaN(parseInt(temp_card_month.charAt(temp_card_month.length - 1))) : true

    if (temp_card_month.length <= 2 && lastIsInt) {
      this.setState({card_month: event.target.value})
    }
  }

  yearInput = event => {
    let temp_card_year = event.target.value

    // Check if last input was a number or not and only add to value if was a digit
    let lastIsInt = temp_card_year.length > 0 ? !isNaN(parseInt(temp_card_year.charAt(temp_card_year.length - 1))) : true

    if (temp_card_year.length <= 4 && lastIsInt) {
      this.setState({card_year: event.target.value})
    }
  }

  zipInput = event => {
    // Max zip code length is 5 and prevents any input after that
    let temp_zip = event.target.value
    if (temp_zip.length <= 5) {
      this.setState({zip: event.target.value})
    }
  }

  paymentTypeForm = () => {
    // Handles which form to render based off selected_payment_method
    if (this.state.selected_payment_method === 'credit') {
      return this.creditForm()
    } else if (this.state.selected_payment_method === 'ach') {
      return this.achForm()
    } else if (this.state.selected_payment_method === 'cash') {
      return this.cashForm()
    } else {
      return this.creditForm()
    }
  }

  handleSubmit = event => {
    // Handles both button submit and onEnter submit. Also checks if all validators for payment_method pass
    event.preventDefault()

    if (!this.disablePayButton()) {
      this.payNow()
    }
  }

  successResultText = () => {
    // Handles what the success message is by the payment_method
    if (this.state.selected_payment_method === 'credit') {
      return `Payment of $${this.state.amount ? this.state.amount : '0.00'} was made with the Credit Card ending in ${this.state.lastFour}.`
    } else if (this.state.selected_payment_method === 'ach') {
      return `Payment of $${this.state.amount ? this.state.amount : '0.00'} was made with the provided bank account ${this.state.encryptedBankAcct}.`
    } else if (this.state.selected_payment_method === 'cash') {
      return `Payment of $${this.state.cash_amount ? this.state.cash_amount : '0.00'} was made with cash.`
    } else {
      return `Payment of $${this.state.amount ? this.state.amount : '0.00'} was made with the Credit Card ending in ${this.state.lastFour}.`
    }
  }

  focusFirstInput = (type) => {
    // Focus the first input on selected_payment_method change
    this.setState({selected_payment_method: type}, _ => {
      if (document.getElementById(type + 'FirstInput')) {
        document.getElementById(type + 'FirstInput').focus()
      }
    })
  }

  creditForm = () => {
    return (
      <PmtInfoSection id="creditForm" onSubmit={event => this.handleSubmit(event)}>
        <FullNameContainer>
          <NameContainer>
            <NameLabel
              label-for="fName"
            >First Name</NameLabel>
            <br/>
            <NameInput
              onChange={event => this.setState({firstName: event.target.value})}
              value={this.state.firstName}
              id="creditFirstInput"
              name="fName"
              inputSize={this.getSize(3.67, 33)}
              disabled={this.disablePay}
            />
          </NameContainer>
          <NameContainer>
            <NameLabel
              label-for="lName"
            >Last Name</NameLabel>
            <br/>
            <NameInput
              onChange={event => this.setState({lastName: event.target.value})}
              value={this.state.lastName}
              id="lName"
              name="lName"
              inputSize={this.getSize(3.67, 33)}
              disabled={this.disablePay}
            />
          </NameContainer>
        </FullNameContainer>
        <CardConnectIframe iframeCss={this.iframeCss()}/>
        <ZipAndExpiryContainer>
          <ExpirationContainer>
              <NameLabel
                label-for="expiration"
              >Expiration</NameLabel>
              <br/>
              <ExpirationInnerContainer name="expiration" id="expiration">
                <ExpiryInputContainer>
                  <MonthInput
                    onChange={event => this.monthInput(event)}
                    value={this.state.card_month}
                    id="month"
                    name="month"
                    inputSize={this.getSize(3.67, 33)}
                    validMonth={this.state.card_month.length > 0 ? this.validMonth() : true}
                    placeholder="MM"
                    disabled={this.disablePay}
                  />
                </ExpiryInputContainer>
                <span>/</span>
                <ExpiryInputContainer>
                  <YearInput
                    onChange={event => this.yearInput(event)}
                    value={this.state.card_year}
                    id="year"
                    name="year"
                    inputSize={this.getSize(3.67, 33)}
                    validYear={this.state.card_year.length > 0 ? this.validYear() : true}
                    placeholder="YYYY"
                    disabled={this.disablePay}
                  />
                </ExpiryInputContainer>
              </ExpirationInnerContainer>
            </ExpirationContainer>
          <ZipContainer>
            <NameLabel
              label-for="zip"
            >Zip</NameLabel>
            <br/>
            <ZipInput
              onChange={event => this.zipInput(event)}
              value={this.state.zip}
              id="zip"
              name="zip"
              inputSize={this.getSize(3.67, 33)}
              validZip={this.state.zip.length > 0 ? this.validZipCode() : true}
              disabled={this.disablePay}
            />
          </ZipContainer>
        </ZipAndExpiryContainer>
        <SaveToAcctContainer topMargin={this.getSize(4.3, 35)}>
          <CustomCheckbox
            checkboxsize={this.getSize(3.34, 30)}
            onCheck={this.onCheck}
            checked={this.state.saveToAcct}
            disabled={this.disablePay}
          />
          <SaveToAcctText>
            Save to Account
          </SaveToAcctText>
        </SaveToAcctContainer>
        <ButtonsContainer>
          <Button
            payNow={false}
            onClick={this.cancel}
            type="button"
          >Cancel</Button>
          <Button
            payNow={true}
            type="submit"
            disabled={this.disablePayButton()}
            style={this.disablePayButton() ? {opacity: 0.5, cursor: 'default'} : {opacity: 1}}
          >Pay Now</Button>
        </ButtonsContainer>
        <input
          type="hidden"
          name="mytoken"
          id="mytoken"
        />
      </PmtInfoSection>
    )
  }

  achForm = () => {
    return (
      <PmtInfoSection id="achForm" spaceAround={true} onSubmit={event => this.handleSubmit(event)}>
        <FormContainer>
          <AccountContainer>
            <NameLabel
              label-for="account"
            >Account Number</NameLabel>
            <br/>
            <AccountInput
              onChange={event => this.accountNumberInput(event)}
              value={this.state.account_number}
              id="achFirstInput"
              name="account"
              inputSize={this.getSize(3.67, 33)}
              validInput={this.state.account_number.length > 0 ? this.validAccountNumber() : true}
              disabled={this.disablePay}
            />
          </AccountContainer>
          <AccountContainer>
            <NameLabel
              label-for="routing"
            >Routing Number</NameLabel>
            <br/>
            <AccountInput
              onChange={event => this.routingNumberInput(event)}
              value={this.state.routing_number}
              id="routing"
              name="routing"
              inputSize={this.getSize(3.67, 33)}
              validInput={this.state.routing_number.length > 0 ? this.validRoutingNumber() : true}
              disabled={this.disablePay}
            />
          </AccountContainer>
          <SaveToAcctContainer topMargin={this.getSize(4.3, 35)}>
            <CustomCheckbox
              checkboxsize={this.getSize(3.34, 30)}
              onCheck={this.onCheck}
              checked={this.state.saveToAcct}
            />
            <SaveToAcctText>
              Save to Account
            </SaveToAcctText>
          </SaveToAcctContainer>
        </FormContainer>
        <ButtonsContainer>
          <Button
            payNow={false}
            onClick={this.cancel}
            type="button"
          >Cancel</Button>
          <Button
            payNow={true}
            type="submit"
            disabled={this.disablePayButton()}
            style={this.disablePayButton() ? {opacity: 0.5, cursor: 'default'} : {opacity: 1}}
          >Pay Now</Button>
        </ButtonsContainer>
      </PmtInfoSection>
    )
  }

  cashForm = () => {
    return (
      <PmtInfoSection id="cashForm" spaceAround={true} onSubmit={event => this.handleSubmit(event)}>
        <FormContainer>
          <AccountContainer>
            <NameLabel
              label-for="cash_amount"
              marginLeft="15px"
            >Verify Amount</NameLabel>
            <br/>
            <CashAmountInputContain>
              <DollarSign>$</DollarSign>
              <AccountInput
                onChange={event => this.cashAmountInput(event)}
                value={this.state.cash_amount}
                id="cashFirstInput"
                name="cash_amount"
                inputSize={this.getSize(3.67, 33)}
                validInput={this.state.cash_amount.length > 0 ? this.validCashAmount() : true}
                placeholder=""
                onBlur={event => this.setState({cash_amount: this.state.cash_amount ? parseFloat(this.state.cash_amount).toFixed(2) : ''})}
                disabled={this.disablePay}
              />
            </CashAmountInputContain>
          </AccountContainer>
        </FormContainer>
        <ButtonsContainer>
          <Button
            payNow={false}
            onClick={this.cancel}
            type="button"
          >Cancel</Button>
          <Button
            payNow={true}
            type="submit"
            disabled={this.disablePayButton()}
            style={this.disablePayButton() ? {opacity: 0.5, cursor: 'default'} : {opacity: 1}}
          >Pay Now</Button>
        </ButtonsContainer>
      </PmtInfoSection>
    )
  }

  render() {
    return (
      <PmtIframeContainer>
        {this.state.showResultPage ? 
          <>
            <ResultTopSection></ResultTopSection>
            <ResultMiddleSection>
              {this.state.pmtSuccessful ?
                <>
                  <SuccessHeader>
                    SUCCESS!
                  </SuccessHeader>
                  <ResultText>
                    {this.successResultText()}
                  </ResultText>
                </>
                : this.state.cancelled ?
                <>
                  <SuccessHeader>
                    CANCELLED
                  </SuccessHeader>
                  <ResultText>
                    Transaction of ${this.state.selected_payment_method === 'cash' ? this.state.cash_amount : this.state.amount ? this.state.amount : '0.00'} was cancelled.
                  </ResultText>
                </>
                :
                <>
                  <ErrorHeader>
                    {this.state.errorHeader ? this.state.errorHeader : 'DECLINED'}
                  </ErrorHeader>
                  <ResultText>
                    {this.state.errorMessage ? this.state.errorMessage : `Payment of $${this.state.amount ? this.state.amount : '0.00'} was declined with the Credit Card ending in ${this.state.lastFour}.`}
                  </ResultText>
                </>
              }
            </ResultMiddleSection>
            <ResultBottomSection></ResultBottomSection>
          </>
          :
          <>
            <PmtAmtSection>
              <PmtAmountTitle>
                Payment Amount
              </PmtAmountTitle>
              <PmtAmount>
                ${this.state.amount ? this.state.amount : '0.00'}
              </PmtAmount>
              <HideShow show={this.state.selected_payment_options.length > 1}>
                <PmtTypesContainer>
                  <HideShow show={this.state.selected_payment_options.includes('credit')}>
                    <PmtType isSelected={this.state.selected_payment_method === 'credit'} onClick={event => this.focusFirstInput('credit')}>Card</PmtType>
                  </HideShow>
                  <HideShow show={this.state.selected_payment_options.includes('ach')}>
                    <PmtType isSelected={this.state.selected_payment_method === 'ach'} onClick={event => this.focusFirstInput('ach')}>ACH</PmtType>
                  </HideShow>
                  <HideShow show={this.state.selected_payment_options.includes('cash')}>
                    <PmtType isSelected={this.state.selected_payment_method === 'cash'} onClick={event => this.focusFirstInput('cash')}>Cash</PmtType>
                  </HideShow>
                </PmtTypesContainer>
              </HideShow>
            </PmtAmtSection>
            {this.paymentTypeForm()}
          </> 
        }
      </PmtIframeContainer>
    )
  }
}
