import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import Button from '../../../components/mobile/MobileButton';
import Background from '../../../components/display/Background';
import { hardLogout } from '../../../js/actions/auth';


const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ContentCard = styled.div`
  max-width: 400px;
  width: 85vw;
  background: #F1F4F7;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
`

const Header = styled.div`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #5A9FEC;
  margin-bottom: 20px;
`

const Copy = styled.p`
  font-size: 15px;
  margin-top: 0;
`

const Prompt = styled.p`
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
  border-top: #e0e0e0 solid 1px;
  padding-top: 10px;
  margin-bottom: 0;
`

class LoginRedirect extends React.PureComponent {

  handleLogout = () => {
    const location = this.props.history.location
    const redirect = location.pathname + location.search
    this.props.logout(redirect)
  }

  render () {
    return (
      <Background>
        <PageContainer>
          <ContentCard>
            <Header>Attention</Header>
            <Copy>
              {this.props.message}
            </Copy>
            <Prompt>
              Please logout to continue. 
            </Prompt>
          </ContentCard>
          <Button onClick={this.handleLogout}>Logout</Button>
        </PageContainer>
      </Background>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      isMerchantUser: state.auth.is_merchant
    };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: redirectUrl => dispatch(hardLogout(redirectUrl))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginRedirect))