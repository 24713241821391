export const color_white = '#FFFFFF';

// Headings
export const color_heading_grey1 = '#6C768C';
export const color_heading_grey2 = '#59637A;'
export const color_modal_heading = '#757575;'

// Initials
export const color_initials_grey1 = '#B9C2CB';

// Redesign
export const color_orange_bg = '#FFE6D3';
export const color_orange_text = '#FFB285';

// Tabs
export const color_nav_border = '#ACB3C2';

//Blues
export const color_blue_main = '#64ABF5';
export const color_icon_btn_hover = '#3f51b5';

//Greys
export const color_invoice_grey_text = '#A0A6B3';

export const color_teal = '#58D6D8';

//Toggles
export const color_toggle_disabled = '#E5E5EA';