import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import history from '../../../history';

import { ModalContent } from '../generalModalStyling';

import Modal from '../NewModal';
import InputWrapper from '../../wrappers/InputWrapper';
import Input from '../../inputs/Input';
import Label from '../../display/Label';
import Typeahead from '../../inputs/Typeahead';
import DateInput from '../../inputs/Date';
import LineItems from '../../fields/LineItems';
import CurrencyInput from '../../inputs/CurrencyInput';

// API & Utils
import userAPI from '../../../api/customer/userAPI';
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';
import merchantAPI from '../../../api/merchant/merchantAPI';
import globalUtils from '../../../utils/globalUtils';

// Redux
import { setInvoiceCustomer, toggleAddInvoice } from '../../../js/actions/modal_actions';

//Assets
import addIcon from '../../../assets/icons/add_icon.png';

const Icon = styled.img`
  position: absolute;
	top: 3px;
	left: ${props => props.left ? props.left : '125px'};
	width: 14px;
`;

class AddInvoiceModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customers: [],
			masterCustomerList: [],
			merchant: {},
			merchantList: {},
			invoice_items: [{description: null, amount: null}],
			invoice_append_items: [],
			selectedBiller: null,
			selectedCustomer: null
		}

		this.myRefs = {
			user_id: React.createRef(),
			merchant_id: React.createRef(),
			invoice_external_id: React.createRef(),
			//   minimum_amount_due: React.createRef(),
			due_date: React.createRef(),
			description: React.createRef(),
		}
	}

	componentDidMount() {
		this.getCustomers();
		this.getSelf();
		if(this.props.invoice_customer) {
			let { invoice_customer } = this.props;
			this.setState({
				user_id: invoice_customer.user,
				merchant_id: invoice_customer.merchant,
				selectedBiller: [{label: invoice_customer.merchant_name, value: invoice_customer.merchant}],
				selectedCustomer: [{label: invoice_customer.full_name, value: invoice_customer.user}]
			})
		}
	}

	getSelf = async () => {
		let merchant = await merchantAPI.getSelf(this.props.auth_token)
		this.setState({
		merchant: merchant,
		merchantList: {merchants: merchant.merchants}
		})
	}

	getCustomers = async () => {
		let customers = await userAPI.getList(this.props.auth_token);
		this.setState({
		customers: customers,
		masterCustomerList: customers
		})
	}

	handleChange = (event) => {
		if (event.target) {
			const target = event.target;
			const value = target.value;
			const name = target.name;
			this.setState({[name]: value});
		} else { // Logic for changing Biller Name
			let merchant = this.state.merchantList.merchants.filter( merchant => merchant.id === event.value )
			let newCustomerList = this.state.masterCustomerList.filter( customer => customer.merchant_id === event.value );
			this.setState({
				merchant_id: event.value,
				selectedBiller: [{label: merchant[0].name, value: event.value}],
				customers: newCustomerList
			})
		}
	};

	handleInvoiceChange = (event) => {
		if (event.target) {
			const target = event.target;
			const value = target.value
			const name = target.name;
			this.setState({
			[name]: value
			});
		} else { // Logic for changing customer
			let customer = this.state.masterCustomerList.filter( customer => customer.user === event.value );
			let newMerchantList = this.state.merchantList.merchants.filter( merchant => merchant.id === customer[0].merchant_id );
			this.setState({
				user_id: event.value,
				selectedCustomer: [{label: customer[0].full_name, value: customer[0].user}],
				merchant_id: customer[0].merchant_id,
				selectedBiller: [{label: customer[0].merchant_name, value: customer[0].merchant_id}],
				merchantList: {merchants: newMerchantList}
			})
		}
	};

	handleLineItemsChange = (event, index) => {
		if (event.target) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      let newItems = this.state.invoice_items.slice();
      newItems[index][name] = value;
      this.setState({
        invoice_items: newItems
      });
		}
	};

	handleAppendsChange = (event, index) => {
		if (event.target) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      let newAppends = this.state.invoice_append_items.slice();
      newAppends[index][name] = value;

      this.setState({
        invoice_append_items: newAppends
      });
		}
	};

	addLineItem = () => {
    let newItems = this.state.invoice_items.slice();
    newItems.push({description: '', amount: null});
    this.setState({
      invoice_items: newItems,
    })
	}

	removeLineItem = (index) => {
    let rmvItems = this.state.invoice_items.slice();
    rmvItems.splice(index, 1);
    this.setState({
      invoice_items: rmvItems,
    })
	}

	addAppend = () => {
		let newAppends = this.state.invoice_append_items.slice();
    newAppends.push({description: '', amount: null});
    this.setState({
			invoice_append_items: newAppends,
    })
	}

	removeAppend = (index) => {
		let rmvAppends = this.state.invoice_append_items.slice();
		rmvAppends.splice(index, 1);
		this.setState({
			invoice_append_items: rmvAppends,
		})
	}

	addInvoice = async () => {
    if (!this.state.clicked){
			if (this.state.invoice_items.length > 0 || this.state.invoice_append_items.length > 0) {
				let bothFields = true;
				this.state.invoice_items.forEach( item => {
					if(!item.description || !item.amount) {
						bothFields = false;
					};
				});
				if(this.state.invoice_append_items.length > 0) {
					this.state.invoice_append_items.forEach( item => {
						if(!item.description || !item.amount) {
							bothFields = false;
						};
					});

				}
				if(!bothFields) {
					this.setState({errors: 'Please enter a description and amount.'})
					return;
				}
			}
			if (globalUtils.checkValues(this.myRefs) === false) {
				return;
			}
			if(!this.state.user_id || !this.state.merchant_id || !this.state.due_date)  {
				this.setState({errors: "Please complete the required fields."})
				return;
			}
			let invoiceData = Object.assign({}, this.state)
			delete invoiceData.customers;
			delete invoiceData.masterCustomerList;
			delete invoiceData.selectedCustomer;
			delete invoiceData.merchant;
			delete invoiceData.merchantList;
			delete invoiceData.selectedBiller;
			let convertedItems = globalUtils.returnConvertedArray(invoiceData.invoice_items.slice());
			let convertedAppends = globalUtils.returnConvertedArray(invoiceData.invoice_append_items.slice());
			invoiceData.invoice_items = convertedItems;
			invoiceData.invoice_append_items = convertedAppends;
			if(invoiceData.minimum_amount_due) {
				invoiceData.minimum_amount_due = globalUtils.convertDollarsToCents(invoiceData.minimum_amount_due)
			} else {
				delete invoiceData.minimum_amount_due;
			}
			this.setState({clicked: true})
			let invoice = await userInvoiceAPI.createInvoice(invoiceData, this.props.auth_token);
			if (invoice.err && invoice.err.response) {
				let errorText = invoice.err.response.data.detail;
				this.setState({
					errors: errorText,
					clicked: false
				});
				return;
			} else if (invoice["errors"]){
				let errorText = String(invoice.err);
				this.setState({
					errors: errorText,
					clicked: false
				});
				return;
			} else {
				this.props.setInvoiceCustomer(undefined);
				this.props.toggleAddInvoice(false);
				history.push(`/admin/invoices/detail/${invoice.id}`)
			}
		}
	}

	render() {
		return (
			<Modal context="add-invoice" title="New Invoice" modalname="showAddInvoiceModal" onClose={ () => this.props.toggleAddInvoice(false) } errors={this.state.errors} hideError={this.hideError} addInvoice submit={this.addInvoice} noMargin>
				<ModalContent>
					<InputWrapper>
							<Label label="Customer Name"/>
							<Typeahead options={this.state.customers}
											placeholder="Search Customers"
											name="user_id"
											onChange={this.handleInvoiceChange}
											value={this.state.selectedCustomer}
											required
											overrideValue={this.state.user_id ? true : false}
											isDisabled={this.props.invoice_customer ? true : false}
											ref={this.myRefs.user_id}/>
					</InputWrapper>
					<InputWrapper>
							<Label label="Biller Name"/>
							<Typeahead options={this.state.merchantList}
											placeholder="Search Billers"
											name="merchant_id"
											onChange={this.handleChange}
											required
											overrideValue={this.state.merchant_id ? true : false}
											value={this.state.selectedBiller}
											isDisabled={this.props.invoice_customer ? true : false}
											ref={this.myRefs.merchant_id}/>
					</InputWrapper>
					<InputWrapper>
							<Label label="External Bill ID" optional/>
							<Input className="no-border full-width-input"
											placeholder="Enter External Bill ID"
											name="invoice_external_id"
											data-test-id="invoice_external_id"
											onChange={this.handleChange}
											ref={this.myRefs.invoice_external_id}/>
					</InputWrapper>
					<InputWrapper>
						<Label label="Line Items"/>
						<Icon src={addIcon} alt="add item" onClick={this.addLineItem}/>
							<LineItems onChange={this.handleLineItemsChange} addItem={this.addLineItem} items={this.state.invoice_items} removeItem={this.removeLineItem} type={'line-items'}/>
					</InputWrapper>
					<InputWrapper>
						<Label label="Taxes / Fees" optional/>
						<Icon src={addIcon} alt="add item" onClick={this.addAppend} left="137px"/>
							<LineItems onChange={this.handleAppendsChange} addItem={this.addAppend} items={this.state.invoice_append_items} removeItem={this.removeAppend} type={'appends'}/>
					</InputWrapper>
					<InputWrapper>
							<Label label="Minimum Due" optional/>
							<CurrencyInput className="no-border full-width-input"
											placeholder="Enter Amount"
											name="minimum_amount_due"
											data-test-id="min-amt-due"
											onChange={this.handleChange}
											// required
											maxLength={12}
											ref={this.myRefs.minimum_amount_due}/>
					</InputWrapper>
					<InputWrapper>
							<Label label="Bill Due Date"/>
							<DateInput className="no-border full-width-input"
											name="due_date"
											onChange={this.handleChange}
											data-test-id="due_date"
											required
											ref={this.myRefs.due_date}/>
					</InputWrapper>
					<InputWrapper>
							<Label label="Description" optional/>
							<Input className="no-border full-width-input"
											placeholder="Enter Description"
											name="description"
											data-test-id="description-2"
											onChange={this.handleChange}
											ref={this.myRefs.description}/>
					</InputWrapper>
				</ModalContent>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    user_id: state.auth.user_id,
		user_role: state.auth.user_role,
		invoice_customer: state.modals.invoice_customer
  };
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		setInvoiceCustomer: (customer) => {dispatch(setInvoiceCustomer(customer))},
		toggleAddInvoice: (bool) => {dispatch(toggleAddInvoice(bool))}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddInvoiceModal);
