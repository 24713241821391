import React, {Component} from 'react';
import styled from 'styled-components';

const AdminPage = styled.div`
  background: linear-gradient(to bottom, #F5F5F5, #EAEAEA);
  height: 100vh;
  width: 100%;
  padding-left: 250px;
  padding-top: 135px;
  position: fixed;
  z-index: -1;
  overflow: auto;
`

export default class AdminPageWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <AdminPage>{this.props.children}</AdminPage>
    )
  }
}
