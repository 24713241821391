const allDateValues = [
  {value: 0, label: '0 Days'},
  {value: 1, label: '1 Day'},
  {value: 2, label: '2 Days'},
  {value: 3, label: '3 Days'},
  {value: 4, label: '4 Days'},
  {value: 5, label: '5 Days'},
  {value: 6, label: '6 Days'},
  {value: 7, label: '7 Days'},
];

const booleans = [
  {value: true, label: 'Yes'},
  {value: false, label: 'No'}
];

// const yesOrNo = [
//   {value: 'is_null', label: 'Yes or No'}
// ]

const dateOperators = [
  // {value: 'range', label: 'Range'},
  // {value: 'year', label: 'Yeah'},
  {value: 'date', label: 'Date'},
  // {value: 'before', label: 'Before'},
  // {value: 'after', label: 'After'},
  // {value: 'is_null', label: 'No Value?'}
];

const amountOperators = [
  {value: 'gt', label: 'Greater Than', display: 'greater than'},
  {value: 'gte', label: 'Greater Than or Equal to', display: 'greater than or equal to'},
  {value: 'exact', label: 'Equal to', display: 'equal to'},
  {value: 'lt', label: 'Less Than', display: 'less than'},
  {value: 'lte', label: 'Less Than or Equal to', display: 'less than or equal to'},
  // {value: '!=', label: 'Not Equal To', display: null},
  // {value: 'is_null', label: 'No Value?', display: null}
];

const notification_options ={
  objects: [{value: 'UserInvoice', label: 'Invoice'}],
  
  booleans: [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'}
  ],
  send_time: [
    {value: 8, label: '8:00AM'},
    {value: 9, label: '9:00AM'},
    {value: 10, label: '10:00AM'},
    {value: 11, label: '11:00AM'},
    {value: 12, label: '12:00PM'},
    {value: 13, label: '1:00PM'},
    {value: 14, label: '2:00PM'},
    {value: 15, label: '3:00PM'},
    {value: 16, label: '4:00PM'},
    {value: 17, label: '5:00PM'},
    {value: 18, label: '6:00PM'},
    {value: 19, label: '7:00PM'},
    {value: 20, label: '8:00PM'},
  ],

  type: [
    {value: 'filter', label: 'Filter'},
    {value: 'exclude', label: 'Exclude'}
  ],

  values: {
    // 'is_deleted': booleans,
    
    'due_date': allDateValues,
    'paid_date': allDateValues,
    'days_late': allDateValues,
    'scheduled_on': allDateValues,
    'payment_plan': booleans,
    'state': [
      {value: 'upcoming', label: 'Upcoming'},
      {value: 'paid', label: 'Paid'},
      {value: 'cancelled', label: 'Canceled'},
      {value: 'late', label: 'Late'},
      {value: 'due', label: 'Due'},
      {value: 'overwritten', label: 'Overwritten'},

    ]
  },

  operators: {
    'description': [
      {value: 'icontains', label: 'Contains', display: 'that contains'},
      {value: 'istartswith', label: 'Starts With', display: 'that starts with'},
      {value: 'iendswith', label: 'Ends With', display: 'that ends with'},
      {value: 'iexact', label: 'Exact', display: 'equal to'},
      {value: 'iregex', label: 'Regular Expression Match', display: 'that matches'},
      // {value: 'exact', label: 'Exact (case-sensitive)', display: 'equal to'},
      // {value: 'contains', label: 'Contains (case-sensitive)', display: 'that contains'},
      // {value: 'startswith', label: 'Starts With (case-sensitive)', display: 'that starts with'},
      // {value: 'endswith', label: 'Ends With (case-sensitive)', display: 'that ends with'},
      // {value: 'regex', label: 'Regular Expression Match (case-sensitive)', display: 'that matches'},
    ],

    'payment_plan': [
      {value: 'yes', label: 'Yes', display: 'is'},
      {value: 'no', label: 'No', display: 'not'},
    ],

    // 'is_deleted': yesOrNo,

    'date': dateOperators,
    'due_date': [
      {value: 'upcoming', label: 'Upcoming', display: 'in'},
      {value: 'past_due', label: 'Past Due', display: 'by'}
    ],
    'paid_date': dateOperators,
    'days_late': amountOperators,

    'amount': amountOperators,
    'amount_paid': amountOperators,
    'subtotal': amountOperators,
    'minimum_amount_due': amountOperators,
    'balance': amountOperators,

    'state': [
      {value: 'exact', label: 'Equals', display: 'equal to'},
    ]
  },

  types: [
    {value: 'filter', label: 'Filter'},
    {value: 'exclude', label: 'Exclude'}
  ],

  fields: {
    'UserInvoice': [
      {value: 'due_date', label: 'Due Date'},
      {value: 'description', label: 'Description'},
      {value: 'state', label: 'Status'},
      // {value: 'paid_date', label: 'Date Paid'},
      // {value: 'days_late', label: 'Days Late'},
      // {value: 'user', label: 'User'},
      // {value: 'scheduled_on', label: 'Scheduled On'},
      // {value: 'amount_total', label: 'Amount Total'},
      // {value: 'amount_paid', label: 'Amount Paid'},
      // {value: 'amount_subtotal', label: 'Subtotal'},
      // {value: 'balance', label: 'Balance'},
      // {value: 'minimum_amount_due', label: 'Min. Amount Due'},
      // {value: 'payment_plan', label: 'Payment Plan'},
      // {value: 'is_deleted', label: 'Deleted?'}
    ]
  },

  actions: [
    {value: 'message', label: 'Send a Message'},
    // {value: 'forward', label: 'Forward to Email'}
  ],
  action_display: {
    'message': 'Send a Message'
  },

  recipients: [
    {value: 'customers', label: 'Customers'},
    {value: 'email', label: 'Email Address'}
  ],

  days: [
    {label: 'M', value: 'mon', full: 'Monday', short: 'Mon', val: 0},
    {label: 'T', value: 'tue', full: 'Tuesday', short: 'Tues', val: 1},
    {label: 'W', value: 'wed', full: 'Wednesday', short: 'Wed', val: 2},
    {label: 'T', value: 'thu', full: 'Thursday', short: 'Thurs', val: 3},
    {label: 'F', value: 'fri', full: 'Friday', short: 'Fri', val: 4},
    {label: 'S', value: 'sat', full: 'Saturday', short: 'Sat', val: 5},
    {label: 'S', value: 'sun', full: 'Sunday', short: 'Sun', val: 6},
  ],
}

export default notification_options;