import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  background-color: white;
  padding: 10px 6px;
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  line-height: 1;
`;

export default function ModalErrorWrapper(props) {
  return(
    <ErrorContainer>
      {props.children}
    </ErrorContainer>
  )
}
