import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//Data
import options from './automation_options';

//Components
import Wizard from './Wizard';
import ToggleSwitch from '../../../components/inputs/ToggleVariant';
import Modal from '../../../components/modals/NewModal';
import InputWrapper from '../../../components/wrappers/InputWrapper';
import Label from '../../../components/display/Label';
import HideShow from '../../../components/function/HideShow';

//API
import messagesAPI from '../../../api/messages/messagesAPI';
import automationAPI from '../../../api/automation/automationAPI';

// Assets
import deleteIcon from '../../../assets/icons/delete-blue.png';
import editIcon from '../../../assets/icons/pencil-blue.png';
import { ModalContent } from '../../../components/modals/generalModalStyling';

//Redux
import { setNotification } from '../../../js/actions/notifications';

const WhiteContainer = styled.div`
  background-color: white;
  height: auto;
  width: 98%;
  min-height: 680px;
  padding: 20px 30px;
  position: relative;
`;

const TriggerList = styled.div`
  margin-top: 55px;
  padding: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CreateButton = styled.div`
  transition: all .2s;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .4);
  border-radius: 4px;
  width: auto;
  text-align: center;
  padding: 7px;
  color: #FFFFFF;
  background-color: #14C6C8;
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .5);
  }
`;

const TriggerButton = styled.div`
  border-radius: 4px;
  width: auto;
  text-align: center;
  padding: 7px;
  color: #4D8ADB;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  cursor: pointer;
`;

const DisplayOnly = styled.div`
   /* text-indent: 15px; */
  border: 1px solid #9E9E9E;
  width: 164px;
  height: 38px;
  border-radius: 4px;
  background-color: #FFFFFF;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0 5px;
  text-align: center;
  .overflow {
    margin: 0;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #4C5760;
    font-weight: 500;
  }
`;

const TriggerContainer = styled.div`
  .separator {
    width: 90%;
    color: #9E9E9E;
  }
  .trigger_input {
    text-indent: 15px;
    border: 1px solid #9E9E9E;
    width: 164px;
    height: 38px;
    border-radius: 4px;
    background-color: #FFFFFF;
    margin-right: 15px;
    &:focus {
      border: 2px solid #64ABF5;
      outline: none;
    }
  }
  background-color: #F1F4F7;
  height: auto;
  min-height: 200px;
  width: 750px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  padding: 15px 7px 37px 25px;
  .title {
    height: 39px;
    color: #9CAAB6;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.09px;
    margin: 0 0 -10px 0;
  }
  .add-conditions {
    border-radius: 4px;
    padding: 7px;
    color: #FFFFFF;
    background-color: #5A9DEB;
    font-size: 18px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
  }
  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* position: relative;
    left: 50%;
    transform: translateX(-50%); */
    .item-wrapper {
      position: relative;
      p {
        color: #9CAAB6;
        font-size: 18px;
        font-weight: 500;
        margin: 10px 5px 5px 5px;
      }
    }
    .centered {
      text-align: center;
      width: 100%;
      height: 22px;
    }
  }
  .conditions {
    padding: 10px 0;
    width: fit-content;
    position: relative;
  }
  .css-10nd86i {
    width: 164px;
    margin-right: 15px;
  }
`;

const ToggleWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -95px;
`;

const TriggerControls = styled.div`
  position: absolute;
  bottom: 2px;
  right: 15px;
  display: flex;
  justify-content: space-between;
  width: 180px;
  .icon {
    width: auto;
    position: relative;
    right: 5px;
  }
  .edit {
    height: 17px;
    top: 1px;
  }
  .delete {
    height: 20px
  }
`;

class Automation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      triggers: []
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    let templates = await messagesAPI.getTemplates(this.props.auth_token);
    if (templates.err && templates.err.response) {
      this.setState({errors: templates.err.response.data.detail})
      return
    }
    this.setState({
      templates
    }, this.getTriggers)
  }

  getTriggers = async () => {
    let triggers = await automationAPI.getTriggers(this.props.auth_token);
    this.setState({
      triggers,
      showWizard: false,
      editTrigger: false,
      trigger: undefined
    })
  }

  toggleWizard = (event, trigger) => {
    if(trigger) {
      this.setState({showWizard: true, trigger})
    } else if(!this.state.showWizard){
      this.setState({showWizard: true})
    } else {
      this.setState({showWizard: false, trigger: undefined})
    }
  }

  toggleTrigger = async (id, index) => {
    let { triggers } = this.state;
    if(triggers[index].is_active) {
      let turnOff = automationAPI.toggleTrigger(id, {is_active: false}, this.props.auth_token);
      if (turnOff.err && turnOff.err.response) {
        this.props.setNotification({message: turnOff.err.response.data.errors, type: 'error'})
        return;
      }
    } else if(!triggers[index].is_active) {
      let turnOn = automationAPI.toggleTrigger(id, {is_active: true}, this.props.auth_token);
      if (turnOn.err && turnOn.err.response) {
        this.props.setNotification({message: turnOn.err.response.data.errors, type: 'error'})
        return;
      }
    }
    this.getTriggers()
  }

  renderTime = time => {
    let formattedTime = '';
    let split = time.split(':');
    if(+split[0] > 12) {
      formattedTime += +split[0] - 12 + ':' + split[1];
    } else {
      formattedTime = time;
    }
    +split[0] < 12 ? formattedTime += 'AM' : formattedTime += 'PM';
    return formattedTime;
  }

  renderDays = days => {
    if(!days) {
      return 'None selected'
    }
    let dayArr;
    let send_days = [];
    if(days) {
      dayArr = JSON.parse(days)
      if(Array.isArray(dayArr) && dayArr.length > 0) {
        for (let i = 0; i < options.days.length; i++) {
          for(let j = 0; j < dayArr.length; j++) {
            if(dayArr.includes(options.days[i].val) && !dayArr.includes(options.days[i].short)) {
              send_days.push(options.days[i].short + ', ')
              break;
            }
          }
        }
        if(send_days.length) {
          send_days[send_days.length -1] = send_days[send_days.length - 1].slice(0, send_days[send_days.length - 1].length - 2);
        }
        return send_days.join('');
      }
    }
  }

  renderConditions = (conditions, object_name) => {
    return conditions.map( (condition, index) => {
      let currentField = options.fields[object_name].filter(field => field.value === condition.field_name);
      if(currentField && currentField.length > 0) {
        return(
          <div className="item-wrapper" key={condition.id || index}>
            <p>Condition:</p>
            <DisplayOnly><span className="overflow">{currentField[0].label || ''}</span></DisplayOnly>
          </div>
        )
      } else return <div key={index}></div>
    })
  }

  renderContent = () => {
    if(this.state.triggers.length > 0 && !this.state.showWizard) {
      let Triggers = this.state.triggers.map( (trigger, index) => {
        let currentObject = options.objects.filter( object => object.value === trigger.object_name );
        return (
          <TriggerContainer key={index}>
            <ToggleWrapper>
              <ToggleSwitch type="checkbox" onChange={ () => this.toggleTrigger(trigger.id, index)} name="active" checked={trigger.is_active}/>
            </ToggleWrapper>
            <h2 className="title">{trigger.label}</h2>
            <div className="options">
              <div className="item-wrapper">
                <p>Based on:</p>
                <DisplayOnly><span className="overflow">{currentObject[0].label}</span></DisplayOnly>
              </div>
              <div className="item-wrapper">
                <p>Action:</p>
                <DisplayOnly><span className="overflow">{options.action_display[trigger.action_type]}</span></DisplayOnly>
              </div>
              <HideShow show={trigger.action_type === 'message'}>
                <div className="item-wrapper">
                  <p>Template:</p>
                  <DisplayOnly><span className="overflow">{trigger.template.name.replace('Template', '')}</span></DisplayOnly>
                </div>
              </HideShow>
              <HideShow show={trigger.to_email}>
                <div className="item-wrapper">
                  <p>Email:</p>
                  <DisplayOnly><span className="overflow">{trigger.to_email}</span></DisplayOnly>
                </div>
              </HideShow>
              <div className="item-wrapper">
                <p>Days:</p>
                <DisplayOnly>{this.renderDays(trigger.scheduled_days)}</DisplayOnly>
              </div>
              <div className="item-wrapper">
                <p>Time:</p>
                <DisplayOnly><span className="overflow">{this.renderTime(trigger.send_time)}</span></DisplayOnly>
              </div>
              {this.renderConditions(trigger.conditions, trigger.object_name)}
            </div>
            <TriggerControls>
              <TriggerButton onClick={ e => this.toggleWizard(e, trigger) }><img className="icon edit" src={editIcon} alt="edit event"/>Edit</TriggerButton>
              <TriggerButton onClick={ () => this.toggleDeleteModal(trigger.id) }><img className="icon delete" src={deleteIcon} alt="delete event"/>Delete</TriggerButton>
            </TriggerControls>
          </TriggerContainer>
        )
      })
      return(
        <TriggerList>{Triggers}</TriggerList>
      );
    }
  }



  deleteTrigger = async () => {
    let deletedTrigger = await automationAPI.deleteTrigger(this.state.deleteId, this.props.auth_token);
    if (deletedTrigger.err && deletedTrigger.err.response) {
      this.props.setNotification({message: deletedTrigger.err.response.data.errors, type: 'error'})
      return;
    } else {
      this.toggleDeleteModal()
      this.getTriggers()
    }
  }

  toggleDeleteModal = (id) => {
    if(!this.state.showDeleteModal) {
      this.setState({deleteId: id, showDeleteModal: true})
    } else {
      this.setState({deleteId: undefined, showDeleteModal: false})
    }
  }

  showDeleteModal = (id) => {
    if(this.state.showDeleteModal) {
      return(
        <Modal context="automation" title="Delete Event" modalname="showDeleteModal" onClose={this.toggleDeleteModal} errors={this.state.errors} submit={this.deleteTrigger} yesNo>
          <ModalContent>
            <InputWrapper>
              <Label label="Are you sure you want to delete this automated event?"/>
            </InputWrapper>
          </ModalContent>
        </Modal>
      )
    }
  }

  render() {
    return(
      <>
        <WhiteContainer>
          <HideShow show={this.state.showWizard}>
            <Wizard templates={this.state.templates} getTriggers={this.getTriggers} trigger={this.state.trigger} toggleWizard={this.toggleWizard}/>
          </HideShow>
          <HideShow show={!this.state.showWizard}>
            <CreateButton onClick={this.toggleWizard}>Create Automated Event</CreateButton>
          </HideShow>
          {this.renderContent()}
          {this.showDeleteModal()}
        </WhiteContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.auth.auth_token,
    merchant_id: state.auth.merchant_id
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (notification) => {dispatch(setNotification(notification))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Automation)
