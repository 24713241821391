import React, {Component} from 'react';
import styled from 'styled-components';

const Button = styled.div`
  height: 70px;
  width: 200px;
  @media (min-width: 768px) {
    height: 80px;
    width: 302px;
  }
  border-radius: 4px;
  background-color: ${props => props.disabled ? '#E4E4E4' : '#00B9E5'};
  box-shadow: inset 0 1px 0 0 rgba(212,217,192,0.52), inset 0 0 10px 0 rgba(103,131,96,0.36), 0 1px 0 0 #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 600;
  line-height: 37px;
  cursor: pointer;
`;

export default class LargeButton extends Component {
  render() {
    return (
      <Button {...this.props}  className={this.props.className}>{this.props.children}</Button>
    )
  }
}