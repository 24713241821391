import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from "react-redux"; 
import Background from '../../components/display/Background';


const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const LargeHeader = styled.div`
  font-size: 250px;
  margin-bottom: 60px;
  font-weight: bold;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: 768px) {
    font-size: 25vw;
  }
  @media (max-height: 750px) {
    padding-top: 130px;
  }
`
const Header = styled.div`
  border-top: 2px solid white;
  padding-top: 60px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  @media(max-width: 425px) {
    font-size: 7vw;
  }
`
const Copy = styled.p`
  font-size: 15px;
  margin-top: 0;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
const Button = styled.div`
  color: #fff;
  background-color: #FD7272;
  box-shadow: inset 0 1px 0 0 rgba(212,217,192,0.52), inset 0 0 10px 0 rgba(103,131,96,0.36), 2px 3px 4px 1px #487EBC;
  height: 49px;
  width: 300px;
  text-align: center;
  line-height: 2;
  font-size: 22px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  @media(max-width: 375px) {
      max-width: 78%
      font-size: 5vw;
      
      vertical-align: center;
  }
  @media(max-width: 320px) {
      font-size: 6vw;
  }
  @media(max-width: 375px) {
      padding-top: 1.5vw;
  }
  @media(min-width: 768px) {
      padding-top: .2vw;
  }
`

class LostPage extends Component{
  redirectHandler = () => {
    if (this.props.is_merchant === true) {
      window.location.href = '/admin/dashboard'
    } else if (this.props.is_merchant === false){
      window.location.href = '/customer/dashboard'
    } else {
      window.location.href = 'https://www.blytzpay.com'
    }
  }

    render () {
        return (
            <Background>
                <PageContainer>
                    <LargeHeader>404</LargeHeader>
                    <Header>PAGE NOT FOUND</Header>
                    <Copy>
                        {this.props.message}
                    </Copy>
                    <ButtonWrapper>
                        <Button onClick={this.redirectHandler}>Return to dashboard</Button>
                    </ButtonWrapper>
                </PageContainer>
            </Background>
        )}
    }

    const mapStateToProps = (state) => {
        return {
        is_merchant: state.auth.is_merchant
        };
    };

export default connect(mapStateToProps)(LostPage); 