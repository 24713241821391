import React from 'react'
import { withRouter } from 'react-router-dom'
import LoginRedirect from './LoginRedirect'

/*********************
In order to fix the double log in bugs this wrapper can be added to any component
it will check the local storage for the user type and then reference the url to
determine if the user should be able to view the current page with their current
authetnication. 

We could ellemininate the reliance on the path if we wanted to wrap every route
with this element but this is a bandaid fix, unless we have to do that this will
suffice.
*********************/
function withLoginRedirect(WrappedComponent) {
  class AuthRedirect extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        userType: this.getUserType()
      }
    }

    getUserType = () => {
      // get is_merchant from local storage instead of State so it can be 
      // shared across tabs
      let persistState = window.localStorage.getItem('persist:root')
      if (persistState) {
        persistState = JSON.parse(persistState)
        const auth = JSON.parse(persistState.auth)
        
        // Set these in differnt conditionals so we
        // don't accidentally fall back to one or the other.
        if (auth && auth.is_merchant === true) return 'merchant'
        if (auth && auth.is_merchant === false) return 'customer'
      }
      return 'unknown'
    }

    componentWillUpdate(nextProps) {
      // We want to get the user type every time the route attempts to change.
      if (this.props.location.key !== nextProps.location.key) {
        this.setState({userType: this.getUserType()})
      }
    }

    render () {
      const pathname = this.props.history.location.pathname
      if (pathname.startsWith('/customer') && this.state.userType === 'merchant') {
        return <LoginRedirect message={'You are currently trying to access the customer portal while logged in as a merchant.'} />
      }
      if (pathname.startsWith('/admin') && this.state.userType === 'customer') {
        return <LoginRedirect message={'You are currently trying to access the merchant portal while logged in as a customer.'} />
      }

      return <WrappedComponent {...this.props}/>
    }
  }

  return withRouter(AuthRedirect)
}

export default withLoginRedirect