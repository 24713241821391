import React, {Component} from 'react';
import styled from 'styled-components';

import dots from '../../assets/icons/dots.png';

const ButtonContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
		/* .button-field {
			background-color: magenta;
			width: 100px;
			height: 100px;
			position: absolute;
			right: 0;
			top: 0;
		} */
		.visible {
			display: inherit; 
		}
		.hidden {
			display: none;
		}
`;

const Cover = styled.div`
	width: 100vw;
	height: 100vh;
	/* background-color: magenta; */
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1;
`;

const Button = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;


const Arrow = styled.div`
	height: 0;
	width: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 20px solid #64ABF5;
	position: absolute;
	top: 34px;
	right: -1px;
	z-index: 3;
`;

const Dropdown = styled.div`
	padding: 20px 0;
	width: 299px;
	height: auto;
	background-color: #64ABF5;
	position: absolute;
	z-index: 2;
	right: -25px;
	top: 54px;
	display: flex;
	flex-direction: column;
	box-shadow: 3px 8px 9px 2px rgba(65,65,65,0.5);
	span {
		display: flex;
    justify-content: flex-start;
    align-items: center;
		color: white;
		font-size: 17px;
		width: 100%;
		height: 100%;
		padding-left: 20px;
	}
	.action_box {
		display: flex;
		align-items: center;
		width: 299px;
		height: 30px;
		margin-bottom: 5px;
		&:hover {
			span {
					color: #64ABF5;
			}
			color: #64ABF5;
			background-color: #FFFFFF;
		}
	}
`;

const DotsIcon = styled.img`
	width: 100%;
`;

export default class ThreeDotButton extends Component {
	constructor(props) {
			super(props);
			this.state = {
					dropDownOpen: false
			}
	}

	openDropDown = () => {
		this.setState({dropDownOpen: true})
	}

	closeDropDown = () => {
		this.setState({dropDownOpen: false})
	}

	toggleDropDown = () => {
		this.setState( prevState => {
			return {
				dropDownOpen: !prevState.dropDownOpen
			}
		})
	}

	showDropDown = () => {
		let childrenLinks;
		if(Array.isArray(this.props.children)) {
			childrenLinks = this.props.children.map( (link, i) => {
				return(
					<div className="action_box" key={i}>
						{link}
					</div>
				) 
			})
		} else {
			childrenLinks = <div className="action_box">{this.props.children}</div>
		}
		return (
			<div className={this.state.dropDownOpen ? 'button-field visible' : 'button-field hidden'}>
					<Arrow/>
					<Dropdown>
						{childrenLinks}
					</Dropdown>
			</div>
		)
	}

	handleClose = () => {
		if(this.state.dropDownOpen) {
			return(
				<Cover onClick={this.closeDropDown}/>
			)
		}
	}

	render() {
		return(
			<ButtonContainer className={this.props.className}>

					<Button onClick={this.toggleDropDown}>
						<DotsIcon src={dots} alt="action button"/>
						{this.showDropDown()}
					</Button>
				{this.handleClose()}
			</ButtonContainer>
		)
	}
}
