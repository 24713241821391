import apiUtils from '../apiUtils';
let url = apiUtils.baseUrl + "merchants/";

class importAPI {
  importCustomers = (file, auth_token) => {
    return apiUtils.post(url + 'import_customers', file, auth_token);
  };
  importInvoices = (file, auth_token) => {
    return apiUtils.post(url + 'import_invoices', file, auth_token);
  };
  importPayments = (file, auth_token) => {
    return apiUtils.post(url + 'import_payments', file, auth_token);
  };
}

export default new importAPI();
