import React from 'react';
import Loadable from 'react-loadable';

//Components
import Modal from '../mobile/MobileModal';
import HandleOutsideClick from '../function/HandleOutsideClick'

//Assets
import { ModalContent } from '../modals/generalModalStyling';

const TandCText = Loadable({
  loader: () => import('../../components/stateless/TandCTextMobile'),
  loading() {
    return <div></div>
  }
});

export default function TermsAndConditions(props) {
	return(
		<HandleOutsideClick show={props.closeModal} context="terms">
			<Modal context="terms" title="Terms and Conditions" onClose={props.closeModal} document>
				<ModalContent>
					<TandCText/>
				</ModalContent>
			</Modal>
		</HandleOutsideClick>
	)
}
