import {
  TOGGLE_ADD_INVOICE_MODAL,
  TOGGLE_ADD_CUSTOMER_MODAL,
  TOGGLE_TAKE_PAYMENT_MODAL,
  SET_INVOICE_CUSTOMER
} from '../actions/modal_actions';


const initialState = {
  showAddInvoice: false,
  showAddCustomer: false,
  showTakePayment: false,
  invoice_customer: undefined
}

const modals = (state = initialState, action) => {
  switch(action.type) {
    case TOGGLE_ADD_INVOICE_MODAL:
      return Object.assign({}, state, {showAddInvoice: action.payload});

    case SET_INVOICE_CUSTOMER:
      return Object.assign({}, state, {invoice_customer: action.payload});

    case TOGGLE_ADD_CUSTOMER_MODAL: 
      return Object.assign({}, state, {showAddCustomer: action.payload});

    case TOGGLE_TAKE_PAYMENT_MODAL: 
      return Object.assign({}, state, {showTakePayment: action.payload});

    default:
      return state;
  }
}

export default modals;