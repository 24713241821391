import React, {Component} from 'react';
import styled from 'styled-components';
import chevron_icon from '../../assets/icons/chevron_left_grey.png';

const BreadcrumbWrapper = styled.div`
  width: 100vw;
  height: 30px;
  position: absolute;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChevronIcon = styled.img`
  height: 25px;
  width: auto;
  position: relative;
  bottom: 1px;
  right: 83px;
  cursor: pointer;
  @media screen and (device-aspect-ratio: 40/71) {
    right: 60px;
  }
`

const Page = styled.div`
  color: #D6DCE9;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
`

export default class ModalBreadcrumb extends Component {
  render() {
    return (
      <BreadcrumbWrapper {...this.props}>
        <ChevronIcon src={chevron_icon} data-test-id={"modal-breadcrumb-back-" + this.props.page} onClick={this.props.back} {...this.props}/>
        <Page {...this.props}>{this.props.page}</Page>
      </BreadcrumbWrapper>
    )
  }
}
