import React, {Component} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { connect } from 'react-redux';

//Components
import CurrencyInput from '../../../components/inputs/CurrencyInput';
import PaymentOptionsModal from '../../../components/mobile/PaymentOptionsModal';
import HideShow from '../../../components/function/HideShow'
import SelectPaymentType from '../BlytzWallet/SelectPaymentType'

//API & Utils
import paymentAPI from '../../../api/payments/paymentAPIs';
import history from '../../../history';
import globalUtils from '../../../utils/globalUtils';

//Assets
import split_payment_icon from '../../../assets/icons/split_payment.png';
import card_icon from '../../../assets/icons/card_gradient.png';

//Redux
import { togglePaymentTypes, setPaymentSlot, setSplitMethod, clearPaymentMethods } from '../../../js/actions/invoices';

const Container = styled.div`
  .open {
    transform: translateY(0);
  }

  .closed {
    transform: translateY(100%);
  }

  .container {
    transition: transform .5s ease-out;
    height: 100%;
    width: 100vw;
    background-color: #FFFFFF;
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    .cancel {
      height: 70px;
      text-align: center;
      width: 100%;
      background-color: #545E75;
      padding: 20px;
      & > div {
        color: #fff;
        font-size: 24px;
        line-height: 2;
      }
    }
    .middle-container {
      height: 160px;
      @media screen and (min-device-height: 736px) {
        height: 230px;
      }
      text-align: center;
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 */
        height: 115px;
        margin-bottom: 10px;
      }
      .header-container {
        width: 200px;
        margin: 20px auto 25px auto;
        text-align: center;
        @media screen and (device-aspect-ratio: 40/71) {
          margin-top: 0px;
          margin-bottom: 5px;
        }
        .icon {
          height: 50px;
          width: auto;
        }
        .text {
          color: #71AFF0;
          font-size: 22px;
        }
      }
      .invoice-detail {
        color: #545E75;
        font-size: 21px;
        margin-bottom: 30px;
        @media screen and (device-aspect-ratio: 40/71) {
          /* iphone 5 only */
          font-size: 18px;
        }
        div {
          margin-bottom: 5px;
        }
      }
      .pay-detail {
        border-bottom: 1px solid #A3A6AF;
        width: 90%;
        margin: 0 auto;
        height: 100px;
        @media screen and (device-aspect-ratio: 40/71) {
          /* iphone 5 */
          border-bottom: none;
        }
        div {
          color: #71AFF0;
          font-size: 25px;
          margin-bottom: 10px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .custom-input {
          border: 3px solid #71AFF0;
          height: 50px;
          width: 160px;
          color: #71AFF0;
          text-align: center;
          margin-bottom: 30px;
        }
      }
    }
    .bottom-container {
      margin-top: 20px;
      .padded {
        padding: 20px;
        border-top: 1px solid #A3A6AF;
        @media screen and (device-aspect-ratio: 40/71) {
          padding: 10px;
        }
        .header {
          color: #545E75;
          font-size: 20px;
          font-weight: 600;
        }
        .payment-row {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          .card-icon {
            height: 40px;
            width: auto;
          }
          .small-input {
            border: 1px solid #71AFF0;
            height: 44px;
            width: 108px;
            color: #71AFF0;
            text-align: center;
            border-radius: 3px;
            font-size: 20px;
            @media screen and (device-aspect-ratio: 40/71) {
              /* iphone 5 */
              font-size: 15px;
            }
          }
          .method {
            width: 220px;
            height: 44px;
            border: 1px solid #71AFF0;
            text-align: center;
            border-radius: 3px;
            cursor: pointer;
            @media screen and (min-device-width: 414px)
              and (min-device-height: 736px) {
                width: 250px;
            }
            @media screen and (device-aspect-ratio: 40/71) {
              width: 180px;
            }
            .add-symbol {
              color: #4691E4;
              font-size: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              @media screen and (device-aspect-ratio: 40/71) {
                font-size: 13px;
              }
              & .orange {
                color: #ffa77c;
                margin-right: 4px;
              }
            }
            .selected-option {
              display: flex;
              font-size: 15px;
              position: relative;
              @media screen and (device-aspect-ratio: 40/71) {
                /* iphone 5 */
                font-size: 12px;
              }
              .card-icon {
                height: 44px;
                width: auto;
                margin-left: 7px;
                @media screen and (device-aspect-ratio: 40/71) {
                  /* iphone 5 */
                  height: 30px;
                  margin-top: 5px;
                }
              }
              .card-no {
                margin-top: 5px;
                margin-left: 6px;
              }
              .card-type {
                position: absolute;
                top: 21px;
                left: 55px;
                @media screen and (device-aspect-ratio: 40/71) {
                  /* iphone 5 */
                  left: 43px
                }
              }
            }
          }
        }
      }
      .errors {
        text-align: center;
        font-size: 18px;
        color: #FD7272;
        @media screen and (device-aspect-ratio: 40/71) {
          font-size: 14px;
          padding: 2px;
          margin-bottom: 5px;
        }
      }
      .pay-container {
        position: fixed;
        bottom: 0;
        background-color: #F1F1F1;
        color: #D9D9D9;
        text-align: center;
        font-size: 35px;
        line-height: 2.3;
        height: 83px;
        width: 100%;
        @media screen and (device-aspect-ratio: 40/71) {
          /* iphone 5 */
          height: 40px;
          font-size: 24px;
          line-height: 1.5;
        }
        &.green-ready {
          background-color: #00D973;
          color: #fff;
        }
      }
    }
  }
`
class SplitPayment extends Component {
  constructor(props){
    super(props);
    this.state = {
      errors: undefined,
      processing: false,
      open: false
    };
  }

  componentDidMount() {
    setTimeout( () => {
      this.setState({open: true})
    }, 10)
  }

  onChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value.replace(/\$/g, '')
    });
  };

  showModal = () => {
    if (this.state.showPaymentOptionsModal) {
      return (
        <PaymentOptionsModal context="split-1" split={1} renderPaymentOptions={this.renderPaymentOptions} toggle={this.toggleModal}/>
      )
    } else {
      return;
    }
  }

  showSecondModal = () => {
    if (this.state.showPaymentOptionsModalTwo) {
      return (
        <PaymentOptionsModal context="split-2" split={2} renderPaymentOptions={this.renderPaymentOptionsTwo} toggle={this.toggleSecondModal}/>
      )
    } else {
      return;
    }
  }

  togglePaymentTypes = () => {
    if(this.state.showPaymentOptionsModal) {
      this.setState({showPaymentOptionsModal: false})
      this.props.togglePaymentTypes(true, 'method_one')
    } else if(this.state.showPaymentOptionsModalTwo) {
      this.setState({showPaymentOptionsModalTwo: false})
      this.props.togglePaymentTypes(true, 'method_two')
    }
  }

  showSelectPaymentType = () => {
    if(this.props.show_payment_types) {
      return(
        <SelectPaymentType context="split-payment" split getPaymentOptions={this.props.getPaymentOptions}/>
      )
    }
  }

  renderPaymentOptions = () => {
    let methods = this.props.methods
    let array = [];
    if (this.props.methods && this.props.methods.length > 0) {
      for (let i in methods) {
        if (methods[i].type === 1 && methods[i].id !== this.props.payment_methods.method_two.id){
          array.push(
            <div key={methods[i].id} className="payment-option select-payment"
            data-test-id={"split-pmt-select-pmt-" + i}
            onClick={() => this.selectPayment({format_card: methods[i].format_card, id: methods[i].id, type: methods[i].type})}>
            <img className="card-icon" src={card_icon} alt="payment type"/>
            <span className="card-no">{methods[i].format_card}</span>
            <span className="card-type">Credit/Debit Card</span>
          </div>)
        }
      }
      return array;
    }
  }

  renderPaymentOptionsTwo = () => {
    let methods = this.props.methods
    let array = [];
    if (this.props.methods && this.props.methods.length > 0) {
      for (let i in methods) {
        if (methods[i].type === 1 && methods[i].id !== this.props.payment_methods.method_one.id){
          array.push(
            <div key={`2-${methods[i].id}`} className="payment-option select-payment"
            data-test-id={"split-pmt-selct-pmt-2" + i}
            onClick={() => this.selectPaymentTwo({format_card: methods[i].format_card, id: methods[i].id, type: methods[i].type})}>
            <img className="card-icon" src={card_icon} alt="payment type"/>
            <span className="card-no">{methods[i].format_card}</span>
            <span className="card-type">Credit/Debit Card</span>
          </div>)
        }
      }
      return array;
    }
  }

  toggleModal = () => {
    this.props.setPaymentSlot('method_one')
    this.setState( prevState => {
      return {
        showPaymentOptionsModal: !prevState.showPaymentOptionsModal
      }
    })
  }
  
  toggleSecondModal = () => {
    this.props.setPaymentSlot('method_two')
    this.setState( prevState => {
      return {
        showPaymentOptionsModalTwo: !prevState.showPaymentOptionsModalTwo
      }
    })
  }

  selectPayment = (payment) => {
    this.props.setSplitMethod({
      format_card: payment.format_card,
      id: payment.id,
      type: payment.type,
      nickname: payment.nickname
    })
    this.toggleModal();
  }

  selectPaymentTwo = (payment) => {
    this.props.setSplitMethod({
      format_card: payment.format_card,
      id: payment.id,
      type: payment.type,
      nickname: payment.nickname
    })
    this.toggleSecondModal();
  }

  pay = async () => {
    let payment_data = {};
    if (!this.props.payment_methods.method_one || !this.props.payment_methods.method_two || !this.state.amount_one || !this.state.amount_two) {
      return this.setState({errors: 'Please add both payment methods and payment amounts'});
    }

    payment_data.invoice = this.props.invoice.id;
    payment_data.payment_one_id = this.props.payment_methods.method_one.id;
    payment_data.amount_one = globalUtils.convertDollarsToCents(this.state.amount_one);
    payment_data.payment_two_id = this.props.payment_methods.method_two.id;
    payment_data.amount_two = globalUtils.convertDollarsToCents(this.state.amount_two);

    if (parseFloat(payment_data.amount_one) + parseFloat(payment_data.amount_two) < this.props.invoice.minimum_amount_due) {
      return this.setState({errors: 'Payment total must be equal to or greater than the minimum amount due.'})
    }
    this.setState({errors: undefined, processing: true});
    let response = await paymentAPI.makeSplitPayment(payment_data, this.props.auth_token);
    if (response.err) {
      this.setState({errors: response.err.response.data.detail, processing: false});
      return;
    } else {
      this.props.clearPaymentMethods()
      history.push('/customer/receipts/paid/'+ this.props.invoice.id)
    }
  }

  returnPayAmount = () => {
    if (this.state.amount_one && this.state.amount_two) {
      let converted = parseFloat(this.state.amount_one.replace(/\$/g, ''));
      let converted_two = parseFloat(this.state.amount_two.replace(/\$/g, ''));
      let total = parseFloat(converted + converted_two).toFixed(2);
      return '$' + total;
    }
  }

  closeSplitPayment = () => {
    this.props.clearPaymentMethods()
    this.setState({open: false})
    setTimeout( () => {
      this.props.onClose()
    }, 510)
  }

  render() {
    return (
      <Container>
        <div className={classNames("container", {"open": this.state.open}, {"closed": !this.state.open})}>
          {this.showModal()}
          {this.showSecondModal()}
          {this.showSelectPaymentType()}
          <div className="cancel" data-test-id="split-pmt-close" onClick={this.closeSplitPayment}>
            <div>Cancel</div>
          </div>
          <div className="middle-container">
            <div className="header-container">
              <img className="icon" src={split_payment_icon} alt="split payment"/>
              <div className="text">Multiple Cards</div>
            </div>
            <div className="invoice-detail">
              <div>Current Balance: {globalUtils.convertToDollars(this.props.invoice.amount_due)}</div>
              <div>Minimum Payment Due: {globalUtils.convertToDollars(this.props.invoice.minimum_amount_due)}</div>
            </div>
            {/* <div className="pay-detail">
              <div>Enter amount to pay</div>
              <CurrencyInput name="amount" className="custom-input" placeholder={globalUtils.convertToDollars(this.props.invoice.minimum_amount_due)} onChange={this.onChange}/>
            </div> */}
          </div>
          <div className="bottom-container">
            <div className="padded">
              <div className="header">Select cards</div>
              <div className="payment-row">
                <div className="method" data-test-id="split-pmt-toggle-modal" onClick={this.toggleModal}>
                  <HideShow show={this.props.payment_methods.method_one && Object.keys(this.props.payment_methods.method_one).length === 0}>
                    <span className="add-symbol"><span className="orange">+</span> Select Payment Method</span>
                  </HideShow>
                  <HideShow show={this.props.payment_methods.method_one && Object.keys(this.props.payment_methods.method_one).length > 0}>
                    <div className="selected-option">
                      <img className="card-icon" src={card_icon} alt="payment type"/>
                      <span className="card-no">{this.props.payment_methods.method_one.format_card}</span>
                      <span className="card-type">{'Credit/Debit Card'}</span>
                    </div>
                  </HideShow>
                </div>
                <CurrencyInput name="amount_one" className="small-input" placeholder="$0.00" onChange={this.onChange}/>
              </div>
              <div className="payment-row">
                <div className="method" data-test-id="split-pmt-toggle-second" onClick={this.toggleSecondModal}>
                  <HideShow show={this.props.payment_methods.method_two && Object.keys(this.props.payment_methods.method_two).length === 0}>
                    <span className="add-symbol"><span className="orange">+</span> Select Payment Method</span>
                  </HideShow>
                  <HideShow show={this.props.payment_methods.method_two && Object.keys(this.props.payment_methods.method_two).length > 0}>
                    <div className="selected-option">
                        <img className="card-icon" src={card_icon} alt="payment type"/>
                        <span className="card-no">{this.props.payment_methods.method_two.format_card}</span>
                        <span className="card-type">{'Credit/Debit Card'}</span>
                    </div>
                  </HideShow>
                </div>
                <CurrencyInput name="amount_two" className="small-input" placeholder="$0.00" onChange={this.onChange}/>
              </div>
            </div>
            <div className="errors">{this.state.errors}</div>
            <HideShow show={!this.state.processing}>
              <div className={classNames("pay-container", {'green-ready': this.state.amount_one && this.state.amount_two})} data-test-id="split-pmt-pay" onClick={this.pay}>Pay {this.returnPayAmount()}</div>
            </HideShow>
            <HideShow show={this.state.processing}>
              <div className={classNames("pay-container")}>Processing..</div>
            </HideShow>
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    show_payment_types: state.invoices.show_payment_types,
    payment_methods: state.invoices.payment_methods
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    togglePaymentTypes: (bool) => {dispatch(togglePaymentTypes(bool))},
    setPaymentSlot: (bool) => {dispatch(setPaymentSlot(bool))},
    setSplitMethod: (method) => {dispatch(setSplitMethod(method))},
    clearPaymentMethods: () => {dispatch(clearPaymentMethods())}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitPayment)