import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//Components
import ErrorHandle from '../../components/function/ErrorHandle';
import PageWrapper from '../../components/wrappers/PageWrapper'
import Input from '../../components/inputs/Input';
import LargeButton from '../../components/buttons/LargeButton';

//API & Utils
import customerAuthAPI from '../../api/auth/customerAuthAPI';
import loginAPI from '../../api/login/loginAPI';
import userInfoAPI from '../../api/user/userInfoAPI';
import globalUtils from '../../utils/globalUtils';

//Assets
import background_image from '../../assets/images/new-background.png'
import logo from '../../assets/images/logo-gradiant.png';

//Redux
import { loginMerchant, logout, storeCustomerUsername, storeCustomerAuth, setUserId, isMerchant } from '../../js/actions/auth';
import { storeInboxBadge } from '../../js/actions/notifications';

const PasswordWrapper = styled.main`
  display: grid;
  height: 100vh;
  background: url(${background_image}) no-repeat center center fixed;
  background-size: cover;
  grid-template-rows: 1fr 2fr;
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: initial;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    @media (min-width: 1100px) {
      padding: 20px;
    }
  }

  .logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 1500px) {
      img {
        margin-left: 100px;
      }
    }
  }
  .white-box {
    background-color: #FFFFFF;
    border-radius: 10px;
    height: auto;
    height: 70vh;
    width: 84vw;
    padding: 15px;
    margin-bottom: 100px;
    text-align: center;
    @media (min-width: 414px) {
      padding: 26px;
      height: 72vh;
    }
    @media (min-width: 768px) {
      padding: 34px 24px;
    }
    @media (min-width: 1100px) {
      text-align: left;
      padding: 44px;
      height: 670px;
      width: 536px;
      margin-bottom: 0;
    }
    .dialogue-heading {
      color: #64ABF5;
      font-weight: 300;
      font-size: 25px;
      line-height: 20px;
      @media (min-width: 768px) {
        font-size: 42px;
      }
      @media (min-width: 1100px) {
        line-height: 49px;
        font-size: 40px;
      }
    }
    .create {
      @media(min-width: 768px) {
        font-size: 35px;
      }
    }
    .bold-heading {
      font-weight: bold;
      letter-spacing: 0.26px;
      color: #A3A3A4;
      line-height: 17px;
      font-size: 20px;
      @media (min-width: 768px) {
        margin-top: 20px;
        font-size: 28px;
        line-height: 29px
      }
    }
    .disabled {
      color: #E4E4E4;
    }
    .wb-text {
      height: 400px;
      @media (min-width: 1100px) {
        margin-left: 9px;
      }
      p {
        margin: 0;
        font-size: 15px;
        line-height: 24px;
        font-weight: 300;
        color: #6D6D6D;
        @media (min-height: 736px) {
          margin-top: 5px;
        }
        @media (min-width: 768px) {
          font-size: 22px;
          margin-top: 15px;
        }
      }
      .password-box {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 5px auto;
        height: 55px;
        @media (min-width: 768px) {
          width: 60%;
          height: 80px;
        }
        @media (min-width: 1100px) {
          margin: 0px 0 17px 0;
          width: 388px;
        }
        input {
          width: 100%;
          height: 38px;
          font-size: 25px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
          caret-color: #64ABF5;
          border-bottom: 1px solid #A3A3A4;
          outline: none;
          padding-top: 7px;
          padding-bottom: 7px;
          padding-left: 9px;
          &:focus {
            border-bottom: 2px solid #64ABF5;
          }
          :disabled {
            border-bottom: none;
          }
          @media (min-width: 1100px) {
            letter-spacing: 3px;
            font-weight: bold;
            padding-top: 7px;
            font-size: 40px;
            height: 55px;
          }
        }
      }
      .password-requirements {
        p {
          font-size: 19px;
          color: #9E9E9E;
          letter-spacing: 0.26px;
          line-height: 28px;
          margin-bottom: 2px;
          @media (min-width: 768px) {
            margin-top: 35px;
          }
          @media (min-width: 1100px) {
            font-size: 22px;
          }
        }
        .reqs-wrapper {
          display: flex;
          justify-content: center;
          height: 100px;
          @media (min-width: 1100px) {
            height: 70px;
          }
        }
        ul {
          @media (max-width: 1100px) {
            margin: auto;
            text-align: left;
            margin: 0 auto 5px auto;
          }
          margin: 0;
          list-style-type: none;
          margin-bottom: 47px;
        }
      }
      .button-box {
        height: 125px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-height: 812px) {
          height: 190px;
        }
        @media (min-width: 768px) {
          height: 127px;
        }
        @media (min-width: 1100px) {
          height: 200px;
        }
      }
    }
  }
`;

const StyledLi = styled.li`
  position: relative;
  width: 200px;
  @media (min-height: 736px) {
    width: 210px;
  }
  @media (min-width: 1100px) {
    width: 270px;
  }
`;

const RequirementSpan = styled.span`
  color: #9E9E9E;
  font-size: 13px;
  @media (min-width: 768px) {
    font-size: 17px;
  }
  .circle {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: -22px;
  }
  .grey {
    background: linear-gradient(180deg, #EEEEEE 0%, #D8D8D8 100%);
  }
  .green {
    background: linear-gradient(180deg, #05F8C7 0%, #D8D8D8 100%)
  }
`;

const BlytzLogo = styled.img`
  max-width: 200px;
  @media (min-height: 736px) {
    max-width: 235px;
  }
  @media (min-width: 768px) {
    max-width: 340px;
  }
  @media screen and (min-width: 1500px) {
      max-width: 580px;
    }
`;

const upperTest = /[A-Z]/;
const numberTest = /[0-9]/;
const specialCharTest = /[^A-Za-z0-9]/;


class CreatePassword extends Component {
  constructor(props) {
    super(props);
    const values = globalUtils.getQueryString(this.props.location.search.slice(1))
    this.state = {
      password: '',
      password_confirmation: '',
      username: values.username,
      reset: values.reset,
      code: values.code,
      login_attempted: false,
      requirements: {
        eightChars: false,
        upperCase: false,
        number: false,
        specialChar: false
      }
    }
  }

  componentDidMount = () => {
    if(this.state.username){
      this.props.storeCustomerUsername(this.state.username)
    }
    if(this.state.reset){
      return
    }
  }

  handlePass = (evt) => {
    this.setState({
      password: evt.target.value
    }, this.checkReqs)
  }

  handleConf = (evt) => {
    this.setState({
      password_confirmation: evt.target.value
    })
  }

  showRequirement = (met, name) => {
    return(
      <StyledLi>
        <RequirementSpan>
          <div className={`circle ${met ? 'green' : 'grey'}`}></div>
          {name}
        </RequirementSpan>
      </StyledLi>
    )
  }

  checkReqs = () => {
    let pass = this.state.password;
    let requirements = Object.assign({}, this.state.requirements);

    if(pass.length >= 8) {
      requirements.eightChars = true;
    } else requirements.eightChars = false;
    if(upperTest.test(pass)) {
      requirements.upperCase = true;
    } else requirements.upperCase = false;
    if(numberTest.test(pass)) {
      requirements.number = true;
    } else requirements.number = false;
    if(specialCharTest.test(pass)) {
      requirements.specialChar = true;
    } else requirements.specialChar = false;
    this.setState({requirements});
  }

  login = async (user_email) => {
    let auth = await loginAPI.adminLogin({'merchant_login': true, 'email': user_email ? user_email : this.state.username, 'password': this.state.password})
    if (auth.errors) {
      this.setState({errors: ["The username and/or password are not correct."]})
      return
    }
    this.props.storeCustomerAuth(auth.token)
    let self = await userInfoAPI.getSelf(auth.token);
    this.props.storeCustomerUsername(this.state.username)
    this.props.setUserId(self.user_id);
    this.props.isMerchant(true)
    this.props.storeInboxBadge(self.inbox_badge);

    let auth_info = {};
    auth_info.auth_token = auth.token;

    this.props.loginMerchant(auth_info);
    this.props.history.push('/admin/dashboard');
  }


  savePassword = async () => {
    let passwordObj = {};
    passwordObj.new_password = this.state.password_confirmation;
    passwordObj.user_id = this.state.user_id;
    passwordObj.username = this.state.username;
    if(passwordObj.password === passwordObj.password_confirmation) {
      let newPass = await customerAuthAPI.createPassword(passwordObj, this.state.auth_token);
      if (newPass.err && newPass.err.response) {
        this.setState({errors: newPass.err.response.data.detail});
        return;
      }
      let auth = await loginAPI.adminLogin({'merchant_login': true, 'email': newPass.email, 'password': this.state.password_confirmation})
      if (auth.errors) {
        this.setState({errors: ["The username and/or password are not correct."]})
        return
      }
      this.props.storeCustomerAuth(auth.token)
      let self = await userInfoAPI.getSelf(auth.token);
      this.props.storeCustomerUsername(this.state.username)
      this.props.setUserId(self.user_id);
      this.props.isMerchant(true)
      this.props.storeInboxBadge(self.inbox_badge);

      let auth_info = {};
      auth_info.auth_token = auth.token;

      this.props.loginMerchant(auth_info);
      this.props.history.push('/admin/dashboard');
    }
  }

  renderHeading = () => {
    if(this.props.location.pathname === '/reset-password') {
      return <h1 className="dialogue-heading create">Create a New Password</h1>
    } else return <h1 className="dialogue-heading">Welcome to BlytzPay</h1>
  }

  renderSubHeading = () => {
    if(this.props.location.pathname === '/reset-password') {
      return
    } else return 'Create a password to get started'
  }

  resetPassword = async () => {
    const { password, code, username } = this.state;
    let values = {
      username: username,
      code: code,
      password: password
    }
    let response = await customerAuthAPI.resetMerchantPassword(values);
    if (response.err) {
      return this.setState({errors: response.err.response.data.detail});
    }
    this.login(response.email);
  }

  renderButton = () => {
    const { password, password_confirmation } = this.state;

    if (this.state.code) {
      return (
        <LargeButton disabled={password !== password_confirmation || password.length === 0 } onClick={this.resetPassword}>Continue</LargeButton>
      )
    } else {
      return (
        <LargeButton disabled={password !== password_confirmation || password.length === 0 } onClick={this.savePassword}>Continue</LargeButton>
      )
    }
  }

  render() {
    const { eightChars, upperCase, number, specialChar } = this.state.requirements;

    return(
      <PageWrapper password>
        <PasswordWrapper>
          <div className="logo-box">
          <BlytzLogo src={logo} alt="BlytzPay logo"/>
          </div>
          <div className="content">
            <div className="white-box">
              <ErrorHandle data={this.state.errors}/>
              <h1 className="dialogue-heading">{this.renderHeading()}</h1>
              <div className="wb-text">
                <p>{this.renderSubHeading()}</p>
                <div className="password-box">
                  <span className="bold-heading">Password</span>
                  <Input noBorder type="password" name="password" onChange={this.handlePass}/>
                </div>
                <div className="password-requirements">
                  <p>Password must include:</p>
                  <div className="reqs-wrapper">
                    <ul className="reqs">
                      { this.showRequirement(eightChars, 'At least 8 characters')}
                      { this.showRequirement(upperCase, 'Upper case letter')}
                      { this.showRequirement(number, 'One number')}
                      { this.showRequirement(specialChar, 'Special character ($,#,%,!, etc.)')}
                    </ul>
                  </div>
                </div>
                <div className="password-box">
                  <span className={eightChars && upperCase && number && specialChar ? 'bold-heading' : 'bold-heading disabled'}>Confirm Password</span>
                  <Input noBorder disabled={!eightChars || !upperCase || !number || !specialChar} type="password" name="password_confirmation" onChange={this.handleConf}/>
                </div>
                <div className="button-box">
                  {this.renderButton()}
                </div>
              </div>
            </div>
          </div>
        </PasswordWrapper>
      </PageWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth_token: state.auth.auth_token
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    loginMerchant: (auth_data) => {dispatch(loginMerchant(auth_data))},
    logout: (auth_data) => {dispatch(logout(auth_data))},
    storeCustomerUsername: (username) => {dispatch(storeCustomerUsername(username))},
    storeCustomerAuth: (auth_token) => {dispatch(storeCustomerAuth(auth_token))},
    setUserId: (user_id) => {dispatch(setUserId(user_id))},
    isMerchant: (is_merchant) => {dispatch(isMerchant(is_merchant))},
    storeInboxBadge: (inbox_badge) => {dispatch(storeInboxBadge(inbox_badge))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePassword);
