import React, {Component} from 'react';
import styled from 'styled-components';
import classNames from 'classnames'
import globalUtils from '../../utils/globalUtils';

// Assets 
import {color_white, color_initials_grey1} from '../../styles/colors';

const InitialsWrapper = styled.div`
.initials-container {
    background-color: ${color_initials_grey1};
    color: ${color_white};
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 2px slategrey;
  }
  .mini {
    height: 35px;
    width: 35px;
    font-size: 12px;
  }

  .small {
    height: 41px;
    width: 41px;
    font-size: 15px;
  }

  .medium {
    height: 80px;
    width: 80px;
    font-size: 32px;
  }

  .large {
    height: 117px;
    width: 117px;
    font-size: 44px;
  }

  .white {
    background-color: ${color_white};
    color: ${color_initials_grey1};
    text-shadow: none;
  }

  .grey-1 {
    background-color: ${color_initials_grey1};
    color: ${color_white};
  }

  .grey-2 {
    background-color: #C7C7C7;
  }

  .grey-3 {
    background-color: #D1D3D9;
  }

  .blue {
    background-color: #64ABF5;
  }

`

export default class Initials extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  returnInitials = () => {
    if (this.props.first_name && this.props.last_name) {
      return globalUtils.getUserInitials(this.props.first_name, this.props.last_name);
    } else if (this.props.business_name) {
      return globalUtils.getFirstLetter(this.props.business_name);
    } else {
      return;
    }
  }

  render() {
    return (
      <InitialsWrapper {...this.props}>
        <div className={classNames("initials-container",
          {"white": !this.props.color && this.props.activeSub && this.props.activeSub === this.props.sub_id},
          // {"grey-1": !this.props.color && this.props.activeSub && this.props.activeSub !== this.props.message.sub_id},
          {"grey-2": this.props.color === "grey-2"},
          {"grey-3": this.props.color === "grey-3"},
          {"blue": this.props.color === "blue"},
          {"mini": this.props.size === "mini"},
          {"small": this.props.size === "small"},
          {"medium": this.props.size === "medium"},
          {"large": this.props.size === "large"})}>
          <div className="initials">{this.returnInitials()}</div>
        </div>
      </InitialsWrapper>
    )
  }
}
