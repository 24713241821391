import React, {Component} from 'react';
import styled from 'styled-components';

const PaymentInfoContainer = styled.div`
  width: auto;
  max-width: 247px;
  height: 68px;
  @media only screen and (min-width: 1024px) {
    position: absolute;
    bottom: -1px;
    left: 28px;
    width: 370px;
    height: 102px;
  }


  // @media only screen and (min-width: 1024px) {
  //   bottom: -77px;
  // }
  .payment-option {
    height: 100%;
    width: 100%;
    padding: 12px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (min-width: 1024px) {
      padding: 33px;
      width: 300px;
    }

    .card-icon {
      height: 40px;
      width: auto;
      @media screen and (min-width: 1024px) {
        height: 60px;
        left: 20px;
        top: 12px;
      }
    }
    .card-info {
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 11px;
      @media screen and (min-width: 1024px) {
        margin-top: 44px;
      }
      .card-no {
        font-size: 14px;
        color: #616161;
        font-weight: 600;
        @media screen and (min-width: 1024px) {
          font-size: 17px;
        }
      }
      .card-type {
        color: #2D2D2D;
        font-size: 12px;
        @media screen and (min-width: 1024px) {
          font-size: 16px;
        }
      }
    }
  }
`

export default class CreditCardInfo extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render() {
    return (
      <PaymentInfoContainer {...this.props} className="pay-container">
        <div className="payment-option">
          {/*<img className="card-icon" src={card_icon} alt="card"/>*/}
          <div className="card-info">
            <span className="card-no">Paid with: {this.props.payment}</span>
            <br/>
          </div>
        </div>
      </PaymentInfoContainer>
    )
  }
}
