import React, {Component} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

// Components
import validationRules from '../../utils/ValidationRules';

// Assets
import '../../index.css';

const Wrapper = styled.div`
  margin: 20px 10px 0 0;
  height: 39px;
  position: relative;
  input {
    color: #4C5760;
    height: 39px;
    width: 108px;
    border: 1px solid #9E9E9E;
    border-radius: 3px;
    background-color: #FFFFFF;
    text-align: right;
    padding-right: 60px;
    &:focus {
      border: 2px solid #64ABF5;
      outline: none;
    }
  }
  .has-error {
    color: #FD7272;
    text-align: left;
    margin-top: 7px;
    margin-left: 3px;
    font-size: 15px;
  }
  .red-border {
    border: 1px solid #FD7272;
  }
  .day-text {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
    color: #9CAAB6;
  }
  .value {
    color: #4C5760;
  }
`

export default class DayInput extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  handleEnter = (event) => {
    if (event.key === "Enter" && this.props.submit) {
      this.props.submit();
    } else {
      return;
    }
  }

  checkForErrors = (e) => {
    if (this.props.required && e.target.value === '') {
      this.setState({error: 'This field is required'})
    }
    if (this.props.required && e.target.value) {
      this.setState({error: ''})
    }
    if (this.props.required && this.props.type === 'email') {
      if (!validationRules.email(e.target.value)) {
        this.setState({error: 'Not a valid email'});
      }
    }
    if (this.props.required && this.props.type === 'zip') {
      if (!validationRules.zip(e.target.value)) {
        this.setState({error: 'Not a valid zipcode'});
      }
    }
  }

  renderWord = () => {
    if(+this.props.value === 1) {
      return 'day'
    } else  return 'days'
  }

  checkProps = () => {
    return (
      <Wrapper>
        <input {...this.props}
          className={classNames(this.props.className, {'red-border' : this.state.error})}
          onKeyUp={this.handleEnter}
          maxLength="3"
          autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'}
          type="tel"
          onBlur={(e) => this.checkForErrors(e)}
          required={this.props.required}
          error={this.state.error}
          value={this.props.value}
          data-test-id={this.props.name}
          />
        <div className="has-error">{this.state.error}</div>
        <div className={classNames('day-text ', {'value': this.props.value || this.props.value === 0})}>{this.renderWord()}</div>
      </Wrapper>
    )
  }

  render() {
    return (
      this.checkProps()
    )
  }
}
