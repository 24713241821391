import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import { slideOutDown } from 'react-animations';

//Components
import PageWrapper from '../../../components/mobile/MobileWrapper';
import Card from '../../../components/cards/MiniCard';
import MobileBackground from '../../../components/mobile/GreyBackground';
import MobileModal from '../../../components/mobile/MobileModal';
import HideShow from '../../../components/function/HideShow';
import CreditCardInfo from '../../../components/cards/CreditCardInfo';
import MerchantLogo from '../../../components/logos/MerchantLogo';

//Assets
import bill_icon from '../../../assets/icons/bills-icon.png';
import receipt_icon from '../../../assets/icons/receipt-icon.png';

//Redux
import { setPaymentMethod } from '../../../js/actions/auth';
import { setFromInvoice } from '../../../js/actions/invoices';

//API & Utils
import userInvoiceAPI from '../../../api/invoices/userInvoiceAPI';
import globalUtils from '../../../utils/globalUtils';

const InnerContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 200px;
  margin: auto;
  padding-top: 50px;
  @media (min-width: 768px) {
    align-items: flex-start;
    height: 500px;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    @media screen and (device-aspect-ratio: 40/71) {
      /* iphone 5 only */
      padding-top: 200px;
    }
  }
  .name{
    color: #ffffff;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    @media (max-width: 320px)
    and (min-width: 320px) {
      height: 350px;
      width: 310px;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 only */
        font-size: 38px;
      }
    }
  }

`

const IconBottom = styled.div`
  display: flex;
    justify-content: space-around;
    background-color: #F1F4F7;
    align-items: center;
    position: absolute;
    bottom: 0;
    /* transform: translateY(-70px); */
    padding: 15px;
    width: 100%;
    height: 300px;
    margin: auto;
    @media (max-width: 320px)
    and (min-width: 320px) {
      height: 350px;
      width: 310px;
      padding-top: 40px;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      @media screen and (device-aspect-ratio: 40/71) {
        /* iphone 5 only */
        height: 220px;
        width: 100vw;
      }
    }
`

const Icon = styled.img`
  width: auto;
  height: 80px;
  cursor: pointer;
`
const CardTitle = styled.div`
  font-size: 24px;
  margin-top: 12px;
  color: #5A9DEB;
`
const CardContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.top ? '20px' : '0px'};
`

const ModalTitle = styled.div`
  color: #737373;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 20px;
`

const BillLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  cursor: pointer;
  div {
    padding: 5px;
    color: #424b58;
    font-weight: 600;
    font-size: 18px;
    &.merchant-name {
      width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

const ReceiptLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #737373;
  height: 35px;
  padding-top: 3px;
  cursor: pointer;
  div {
    padding: 5px;
    color: #737373;
    font-weight: 600px;
    font-size: 16px;
    &.merchant-name {
      width: 45%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

const TitleContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  .title {
    color: #737373;
    font-weight: 600;
    font-size: 16px;
  }
`

const ReceiptContainer = styled.div`
  background-color: #f1f4f7;
  height: 275px;
  width: 100%;
  box-shadow: inset 0 1px 3px 0 rgba(172,172,172,0.5), 1px 2px 7px 0 rgba(96,96,96,0.5);
  border-radius: 2px;
  // animation: ${slideOutDown} 1s linear;
  .line-items-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #737373;
    padding: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    .line-items {
      color: #737373;
      font-size: 16px;
      .description {
        margin-right: 5px;
      }
    }
  }
  .total {
    height: 42%;
    color: #737373;
    font-weight: 600;
    justify-content: left;
    margin-left: 15px;
    margin-top: 20px;
  }
  .image-container {
    width: 100%;
    border-radius: 2px;
    margin-right: 5px;
    opacity: 0.5;
    background: linear-gradient(to right, #4996e7 , #00ffc5);
    position: fixed;
    bottom: 0;
  }
  .payment-container {
    position: relative;
  }
`

class CustomerDashboardWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      showBillsModal: false,
      showReceiptsModal: false,
      showReceiptDetail: false,
      user_info: {}
    };

    var undef;
    this.props.setPaymentMethod(undef)
    this.props.setFromInvoice(undef)

  }

  componentDidMount = () => {
    this.getBills();
  }


  componentWillReceiveProps = (nextProps) => {
    if (nextProps.user_info !== undefined && nextProps.user_info !== this.state.user_info) {
      this.setState({user_info: nextProps.user_info});
    }
  }

  reroute = (url) => {
    if (url === 'bills') {
      this.props.history.push('/customer/bills/unpaid');
    } else if (url === 'receipts') {
      this.props.history.push('/customer/receipts/paid');
    } else if (url === 'blytzwallet') {
      this.props.history.push('/customer/blytzwallet/select-card');
    } else if (url === 'blytzcash') {
      this.props.history.push('/customer/blytzcash');
    } else {
      return;
    }
  };

  toggleBillsModal = () => {
    this.setState(prevState => {
      return {
        showBillsModal: !prevState.showBillsModal,
      };
    });
  }

  showBillsModal = () => {
    if (this.state.showBillsModal) {
      return (
        <MobileModal onClose={this.toggleBillsModal} title="Bills" context="dashboard-1">
          <ModalTitle>Due</ModalTitle>
          {this.displayBills()}
          <ModalTitle>Late</ModalTitle>
          {this.displayLateBills()}
        </MobileModal>
      )
    }
  }

  displayBills = () => {
    let array = [];
    if (this.state.invoices) {
      for (let i in this.state.invoices ) {
        if (this.state.invoices[i].state !== 'paid' && this.state.invoices[i].state !== 'late' && this.state.invoices[i].merchant) {
          array.push(
            <BillLine key={`bill-${i}`}>
              <div className="merchant-name" data-test-id={"dashboard-merchant-name-1-" + i} onClick={(id) => this.routeToInvoice(this.state.invoices[i].id)}>
                {this.state.invoices[i].merchant.name}
              </div>
              <div>{globalUtils.convertToDollars(this.state.invoices[i].amount_due)}</div>
            </BillLine>
          )
        }
      }
      return array;
    }
  }

  displayLateBills = () => {
    let array = [];
    if (this.state.invoices) {
      for (let i in this.state.invoices ) {
        if (this.state.invoices[i].state === 'late' && this.state.invoices[i].merchant) {
          array.push(
            <BillLine key={`late-${this.state.invoices[i].id}`} data-test-id={"dashboard-merchant-name-2-" + i} onClick={(id) => this.routeToInvoice(this.state.invoices[i].id)}>
              <div className="merchant-name">
                {this.state.invoices[i].merchant.name}
              </div>
              <div>{globalUtils.convertToDollars(this.state.invoices[i].amount_due)}</div>
            </BillLine>
          )
        }
      }
      return array;
    }
  }

  routeToInvoice = (id) => {
    this.props.history.push('/customer/blytzwallet/invoice/' + id + '?auth_token=' + this.props.auth_token)
  }

  toggleReceiptsModal = () => {
    if(this.state.showReceiptsModal) {
      let resetIds = Object.assign({}, this.state.paidInvoices);
      for(let i in resetIds) {
        resetIds[i] = false;
      }
      this.setState({paidInvoices: resetIds, showReceiptsModal: false})
    } else {
      this.setState({showReceiptsModal: true})
    }
  }

  getBills = async () => {
    let invoices = await userInvoiceAPI.getAllInvoices(this.props.auth_token, this.props.user_pin);
    if (invoices.err && invoices.err.response) {
      this.setState({errors: invoices.err.response.data.detail})
      window.scroll(0,0);
      return
    }

    let paidInvoices = {};
    for(let i in invoices) {
      if (invoices[i].state === 'paid') {
        paidInvoices[invoices[i].id] = false;
      }
    }
    this.setState({ invoices, paidInvoices });
  }

  showReceiptsModal = () => {
    if (this.state.showReceiptsModal) {
      return (
        <MobileModal onClose={this.toggleReceiptsModal} title="Receipts" context="dashboard-2">
          <TitleContainer>
            <div className="title">Merchant</div>
            <div className="title">Amount</div>
            <div className="title">Date</div>
          </TitleContainer>
          {this.displayReceipts()}
        </MobileModal>
      )
    }
  }

  showLineItems = (invoice) => {
    let items = []
    if (invoice && invoice.length > 0) {
      for (let i in invoice) {
        items.push(
          <>
            <span className="description">{invoice[i].description}: </span>
            <span>{globalUtils.convertToDollars(invoice[i].amount)}</span><br/>
          </>
        )
      }
    }
    return items;
  }

  showAppendItems = (invoice) => {
    let items = []
    if (invoice && invoice.length > 0) {
      for (let i in invoice) {
        items.push(
          <>
            <span className="description">{invoice[i].description}:</span>
            <span> {globalUtils.convertToDollars(invoice[i].amount)}</span><br/>
          </>
        )
      }
    }
    return items;
  }

  displayReceipts = () => {
    let receiptsArray = [];
    if (this.state.invoices) {
      for (let i in this.state.invoices ) {
        if (this.state.invoices[i].state === 'paid') {
          receiptsArray.push(
            <div key={this.state.invoices[i].id}>
              <HideShow show={!this.state.paidInvoices[this.state.invoices[i].id]}>
                  <ReceiptLine data-test-id={"dashboard-receipt-toggle-1-" + i} onClick={ () => this.toggleReceiptDetail(this.state.invoices[i].id) }>
                  <div className="merchant-name">
                    {this.state.invoices[i].merchant.name}
                  </div>
                  <div>{globalUtils.convertToDollars(this.state.invoices[i].amount)}</div>
                  <div>{globalUtils.formatDate(this.state.invoices[i].paid_date)}</div>
                </ReceiptLine>
              </HideShow>
              <HideShow show={this.state.paidInvoices[this.state.invoices[i].id]}>
                <ReceiptContainer data-test-id={"dashboard-receipt-toggle-2-" + i} onClick={ () => this.toggleReceiptDetail(this.state.invoices[i].id) }>
                  <ReceiptLine>
                    <div className="merchant-name">
                      {this.state.invoices[i].merchant.name}
                    </div>
                    <div>{globalUtils.convertToDollars(this.state.invoices[i].amount)}</div>
                    <div>{globalUtils.formatDate(this.state.invoices[i].paid_date)}</div>
                  </ReceiptLine>
                  <div className="line-items-container">
                    <div className="line-items">Line Items: <br/>
                      {this.showLineItems(this.state.invoices[i].invoice_items)}<br/>
                      {this.showAppendItems(this.state.invoices[i].invoice_append_items)}
                    </div>
                  </div>
                  <div className="total" data-test-id={"dashboard-total-" + i} onClick={ () => this.props.history.push(`/customer/receipts/paid/${this.state.invoices[i].id}`)}>Total {globalUtils.convertToDollars(this.state.invoices[i].amount)}</div>
                  <div className="payment-container">
                    <div className="payment-option">
                      {this.returnPaymentInfo(this.state.invoices[i])}
                    </div>
                  </div>
                  <div className="image-container"><MerchantLogo receipt logo={this.state.invoices[i].merchant.logo_url} name={this.state.invoices[i].merchant.name}/></div>
                </ReceiptContainer>
              </HideShow>
            </div>
          )
        }
      }
      return receiptsArray;
    }
  }

  returnPaymentInfo = (invoices) => {
    let array = [];
    let transactions = invoices.transactions;
    if (transactions) {
      for (let i in transactions) {
        array.push(<CreditCardInfo payment={transactions[i].visible_method}></CreditCardInfo>)
      }
      return array;
    }
  }

  toggleReceiptDetail = async (id) => {
    let invoice_detail = await userInvoiceAPI.getDetailForCustomer(id, this.props.auth_token, this.props.user_pin);
    if (!invoice_detail.err) {
      this.setState({ invoice_detail })
    }

    if(this.state.paidInvoices[id] === false) {
      let paidTrue = Object.assign({}, this.state.paidInvoices);
      paidTrue[id] = true;
      this.setState({paidInvoices: paidTrue})
    } else {
      let paidFalse = Object.assign({}, this.state.paidInvoices);
      paidFalse[id] = false;
      this.setState({paidInvoices: paidFalse})
    }
  }

  displayName = () => {
    if (this.props.user_info && this.props.user_info.first_name) {
      return 'Hi, ' + this.props.user_info.first_name + '!';
    } else {
      return;
    }
  }

  render() {
    return (
      <MobileBackground fixed>
        <PageWrapper fixed>
        {this.showBillsModal()}
        {this.showReceiptsModal()}
          <InnerContent>
            <div className="name">{this.displayName()}</div>
            <IconBottom>
              <Card data-test-id="dashboard-toggle-bills" onClick={this.toggleBillsModal}>
                <CardContent top><Icon src={bill_icon} alt="bills"/></CardContent>
                <CardContent><CardTitle>Bills</CardTitle></CardContent>
              </Card>
              <Card data-test-id="dashboard-toggle-receipt" onClick={this.toggleReceiptsModal}>
                <CardContent top><Icon src={receipt_icon} alt="receipts"/></CardContent>
                <CardContent><CardTitle>Receipts</CardTitle></CardContent>
              </Card>
            </IconBottom>
          </InnerContent>
        </PageWrapper>
      </MobileBackground>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    from_invoice: state.invoices.from_invoice,
    merchant_logo: state.merchants.merchant_logo,
    auth_token: state.auth.auth_token,
    user_pin: state.auth.user_pin,
    payment_method: state.auth.payment_method,
    user_id: state.auth.user_id,
    user_info: state.auth.user_info
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setPaymentMethod: (payment_method) => {dispatch(setPaymentMethod(payment_method))},
    setFromInvoice: (from_invoice) => {dispatch(setFromInvoice(from_invoice))}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDashboardWrapper)
