import apiUtils from '../apiUtils';
let merchant_login = apiUtils.baseUrl + 'auth/merchant_login';
let url = apiUtils.baseUrl + 'auth/login';

class loginAPI {
  adminLogin = (data) => {
    return apiUtils.post(merchant_login, data, "", "");
  };
  customerLogin = (data, callback) => {
    return apiUtils.post(url, data, "", "", callback);
  };
}

export default new loginAPI();
